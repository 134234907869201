export function getCarBookingUID(state) {
    return state.carBookingDetails['uid'];
}

export function getCarBookingDetails(state) {
    return state.carBookingDetails;
}

export function getCarBookingData(state) {
    return getCarBookingDetails(state)['data'];
}

export function getCarCancelledInfo(state) {
    return state.carCancelAtModifyFlow;
}
