export function filteredForm(form) {
    return Object.entries(form)
        .reduce((acc, [key, value])=> {
            return {
                ...acc,
                [key]: !key.startsWith('credit_card_')
                    ? value
                    : key === 'credit_card_phone' ? {} : null,
            };
        }, {});

}
