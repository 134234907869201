/* global IS_PROD */
import * as Sentry from '@sentry/react';
import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import {applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import {makePersistent} from 'airborne/persist';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {sentryState} from 'midoffice/helpers/format';

import {createAfterMiddleware} from './middlewares';

const tap = {
    actions: null,
};
const logger = store => next => action => {// eslint-disable-line no-unused-vars
    tap.actions && tap.actions.push(action);
    return next(action);
};

export function installTap(actions) {
    tap.actions = actions; // eslint-disable-line immutable/no-mutation
}

function getStreamServiceMiddleware() {
    const createStreamService = require('airborne/store/config/middlewares/stream').default;
    const streamHandlers = require('airborne/store/config/rootStreamHandlers').default;

    return createStreamService(streamHandlers);
}

// window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ - global enhancer generated by Redux DevTools browser plugin for connecting store
// trace turns on "Trace" tab in devtools, there is info about from where actions has been dispatched
// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Features/Trace.md
const composeEnhancers = IS_PROD
    ? compose
    : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true})) ||
      compose;
const ravenMiddleware = createRavenMiddleware(Raven, {stateTransformer: sentryState});
let sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
    stateTransformer: sentryState,
});

const NEW_SENTRY_ENABLED = getFeatureFlag(null, 'NEW_SENTRY_FE_ENABLED');

function composeWithMiddleware({handlers} = {}) {
    const middlewares = [createAfterMiddleware(handlers), thunk, getStreamServiceMiddleware(), logger];
    if (!NEW_SENTRY_ENABLED) {
        middlewares.push(ravenMiddleware);
        sentryReduxEnhancer = noop => noop;
    }
    return composeEnhancers(applyMiddleware(...middlewares), makePersistent, sentryReduxEnhancer);
}

export default composeWithMiddleware;
