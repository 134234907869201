import session from 'airborne/session';
import settings from 'airborne/settings';

const getSidBasedKey = () => `${settings.sid}:as_case_number`;

export const saveAsCaseNumber = (caseNumber) => {
    const key = getSidBasedKey();
    session.set(key, caseNumber);
}

export const getAsCaseNumber = () => {
    const key = getSidBasedKey();
    return session.get(key);
}
