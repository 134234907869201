import {combineReducers} from 'redux';
import isNil from 'lodash/isNil';
import * as actionTypes from '../actionTypes';

const initialData = {};
const data = (state = initialData, {type, data}) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
            return initialData;
        case actionTypes.AIR_FARE_FAMILIES_SET_DATA:
            return {...state, ...data};
        default:
            return state;
    }
};

const initialSelected = {
    fareGroupKey: null,
    flightOptionKeys: null,
    fareFamilyKey: null,
    separatedTickets: {}
};
const selected = (
    state = initialSelected,
    {type, fareGroupKey, flightOptionKeys, fareFamilyKey, ODIndex}
) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
            return initialSelected;
        case actionTypes.AIR_FARE_FAMILIES_SELECT_FAMILY:
            return {
                ...state,
                ...(isNil(ODIndex) ? {
                    fareGroupKey,
                    flightOptionKeys,
                } : {
                    separatedTickets: {
                        ...state.separatedTickets,
                        [ODIndex]: {
                            ...state.separatedTickets[ODIndex],
                            fareGroupKey,
                            flightOptionKeys,
                        }
                    }
                })
            };
        case actionTypes.AIR_FARE_FAMILIES_SELECT_FARE:
            return {
                ...state,
                ...(isNil(ODIndex) ? {
                    fareFamilyKey,
                } : {
                    separatedTickets: {
                        ...state.separatedTickets,
                        [ODIndex]: {
                            ...state.separatedTickets[ODIndex],
                            fareFamilyKey,
                        }
                    }
                })
            };
        case actionTypes.AIR_FARE_FAMILIES_RESET_SEPARATED_TICKETS:
            return {
                ...state,
                separatedTickets: {}
            };
        default:
            return state;
    }
};

const defaultLoading = false;
const loading = (state = defaultLoading, {type, loading}) => {
    switch (type) {
        case actionTypes.AIR_FARE_FAMILIES_SET_LOADING:
            return loading;
        default:
            return state;
    }
};

const defaultErrorMessage = null;
const errors = (state = defaultErrorMessage, {type, errors}) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
        case actionTypes.AIR_FARE_FAMILIES_SELECT_FAMILY:
            return defaultErrorMessage;
        case actionTypes.AIR_FARE_FAMILIES_SET_ERRORS:
            return errors;
        default:
            return state;
    }
};

const fareFamilies = combineReducers({
    data,
    selected,
    loading,
    errors,
});

export default fareFamilies;
