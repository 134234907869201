import moment from 'moment';

export function parse({start, end, ...data}) {
    return {
        ...data,
        created: moment(data.created),
        live: {
            min: start ? moment(start) : null,
            max: end ? moment(end) : null,
        },
    };
}

export function serialize({live={}, ...data}) {
    return {
        ...data,
        start: live.min,
        end: live.max,
    };
}
