import 'airborne/homepage2/data/type';
import settings from 'airborne/settings';
import {parse} from 'airborne/types';

import api from 'midoffice/helpers/api';
import {formatUrl} from 'midoffice/helpers/url';
import {parsePnrInput} from 'airborne/homepage2/helpers/pnrInput';

import {loadFake, fakeOptions} from './fakepnr';
import {suggestProfiles, loadConfigurationIds} from './tspm';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getStep, getCompany, getOptionsParams} from 'airborne/store/modules/homepage/selectors/homepage';
import {hasPnrReducer, getPnrProfile, getPnrForm, getPnrLoading, isMultiPnr, isModification, getProfilesCount, getAllPnrs, getPnrIndex} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import capitalize from 'lodash/capitalize';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import groupBy from 'lodash/groupBy';
import {currentCompany} from 'airborne/homepage2/helpers/search';
import {getSelectedConfiguration} from '../selectors/tspm';
import {getLang} from '../../header/selectors/i18n';
import {parseHotelsValue, parseCarsValue} from '../../login/helpers/login';

export function loadCompanySettings() {
    return function loadCompanySettingsD(dispatch, getState) {
        const {i18n: {lang}} = getState();
        const configurationId = getSelectedConfiguration(getState()) ||  currentCompany(getState());
        const previousOptionsParams = getOptionsParams(getState());

        if (previousOptionsParams['configuration_id'] === configurationId
            && previousOptionsParams['lang'] === lang
        ) {
            return Promise.resolve(false);
        }

        const url = formatUrl('/hotels/api/get_additional_options_data/', {
            'configuration_id': configurationId,
            'lang_code': lang,
        });

        dispatch({type: 'LOADING_DESTINATION_OPTIONS', index: 'all'});

        return api('GET', url)
            .then((value)=> {
                dispatch({
                    type: 'DESTINATION_OPTIONS',
                    value: parse('company-settings', value),
                    params: {
                        lang,
                        'configuration_id': configurationId,
                    },
                    index: 'all',
                });
                return Promise.resolve(true);
            }, ({body})=> {
                const message = (body && body.message) ? body.message : body;
                dispatch({
                    type: 'FAIL_DESTINATION_OPTIONS',
                    errors: {form: [{message}], misconf: true},
                    index: 'all',
                });
                return Promise.resolve(false);
            });
    };
}


// Some company-specific settings, like advertising,
// are depend on language. We have to refetch them
// when language changes
export function syncCompanySettings() {
    return function syncCompanySettingsD(dispatch, getState) {
        const profile = hasPnrReducer(getState()) && getPnrProfile(getState(), 0);
        const {company} = profile || {};
        if (company) { return dispatch(loadCompanySettings()); }
        return Promise.resolve();
    };
};

export function selectCompany(company) {
    return function(dispatch) {
        dispatch({type: 'SELECT_COMPANY', company});
        return dispatch(loadCompanySettings());
    };
}

export function switchCompany(company) {
    return function(dispatch, getState) {
        const original = getCompany(getState());
        const {
            id: value,
            title: label,
        } = company;
        dispatch({type: 'SWITCH_COMPANY', company: {value, label}, original});
        return dispatch(loadCompanySettings());
    };
}

function fixTravelerSalutation(traveler) {
    return !traveler.salutation
        ? traveler
        : {...traveler, salutation: capitalize(traveler.salutation)};
}

const TRAVELER_PROP_MAP = {
    'email': 'tspm_email',
    'traveler_id': 'tspm_traveler_id',
    'salutation': 'tspm_salutation',
    'first_name': 'tspm_first_name',
    'last_name': 'tspm_last_name',
    'original_first_name': 'tspm_original_first_name',
    'original_last_name': 'tspm_original_last_name',
    'cars_id_numbers': 'tspm_cars_id_numbers',
    'ft_numbers': 'tspm_ft_numbers',
};

function injectTraveler(traveler) {
    return Object.entries(TRAVELER_PROP_MAP).reduce((acc, [key, nKey])=> ({
        ...acc,
        [nKey]: traveler && traveler[key] || null,
    }), {});
}

export function parseProfile(data, pnr) {
    const {
        company,
        emails,
        'first_name': nameFirst,
        'last_name': nameLast,
        'traveler_profiles': travelers,
        'company_profiles': companies,
        ...rest
    } = data;
    const traveler = travelers && travelers.length === 1 && travelers[0];
    const tspmCompany = traveler && companies && companies[0];

    return {
        ...rest,
        pnr,
        'company': company.id,
        'company_name': company.title,
        'email': emails[0] || null,
        'pnr_name_first': nameFirst,
        'pnr_name_last': nameLast,
        'tspm_company_id': tspmCompany && tspmCompany['entity_id'] || null,
        'tspm_company_name': tspmCompany && tspmCompany.title || null,
        'pnrTravelerProfiles': travelers || [],
        ...injectTraveler(fixTravelerSalutation(traveler)),
    };
}
function markAs (pnr, isOk) {
    return function (response) {
        return {isOk, response, pnr};
    };
}

function retrieveSinglePnr(data) {
    const URL = '/internal/traveler_pnr/';
    const {pnr} = data;
    return api('GET', URL, {
        data: {...data, locale: settings.USER_LOCALE}
    }).then(
        markAs(pnr, true),
        markAs(pnr, false)
    );
}

function groupRetrieves(responses) {
    const rez = groupBy(responses, ({isOk}) => isOk);
    const {[true]: successes=[], [false]: failures=[]} = rez;
    return {successes, failures};
}

function getCompanyToSwitch(currentCompanyId, successes) {
    if (successes.length === 0) {
        return null;
    }

    const {response: {company}} = successes[0];
    if (company.id === currentCompanyId) {
        return null;
    }
    return company;
}

function parseError({response, pnr}) {
    const {body} = response;
    if (body.errors && body.errors[0].developer_message) {
        return {pnr, error: {form: body.errors[0].developer_message}};
    }
    if (!(body && body.message)) {
        return {pnr, error: body};
    }
    return {pnr, error: {form: body.message}};
}


export function retrievePNR(
    rethrow=false,
    update=false,
    bookingId=null,
    provider = null,
    shouldLoadCongutaion=true,
) {
    return function (dispatch, getState) {
        const state = getState();
        const {pnr: pnrInput} = getPnrForm(state);
        const pnrs = update ? getAllPnrs(state) : parsePnrInput(pnrInput);
        const form = getPnrForm(state);
        const {
            company: {value: companyId},
        } = form;

        dispatch({type: 'LOADING_PNR'});

        const fakePnrs = pnrs.filter(pnr => pnr.startsWith('FAKEPNR'));
        if (fakePnrs.length > 0) {
            fakePnrs.forEach(pnr => {
                const index = getProfilesCount(getState());
                dispatch(loadFake(companyId, pnr, index));
                const lang = getLang(state);
                const configId = getCompany(state)?.value || null;
                dispatch(fakeOptions(pnr, index, {lang, 'configuration_id': configId}));
            });
            return Promise.resolve();
        }

        return Promise.all(
            pnrs.map((pnr, idx) => {
                return retrieveSinglePnr(dropEmpty({
                    'configuration_id': companyId,
                    'session_id': settings.sid,
                    'system': 'aft',
                    'previous_booking_uid': bookingId,
                    pnr,
                    'allow_company_override': !isMultiPnr(state) && !isModification(state) && idx === 0 && pnrs.length === 1 && getStep(state) === 'pnr',
                    provider,
                }));
            })
        )
            .then(groupRetrieves)
            .then(({successes, failures})=> {
                successes.forEach(({response: data, pnr}) => {
                    const profile = parseProfile(data, pnr);
                    const index = update ? getPnrIndex(state, pnr) : getProfilesCount(getState());
                    dispatch({type: 'LOADED_PNR', profile, index, update});

                    !update && dispatch(
                        suggestProfiles(
                            data['traveler_profiles'],
                            data['company_profiles'],
                            index,
                            !shouldLoadCongutaion || successes.length > 1,
                        )
                    );
                });

                const companyToSwitch = getCompanyToSwitch(companyId, successes);
                if (companyToSwitch && !update) {
                    dispatch(switchCompany(companyToSwitch));
                }

                if (failures.length > 0) {
                    dispatch({
                        type: 'FAIL_PNR',
                        errors: failures.map(parseError),
                    });

                    if (rethrow) throw failures[0].response;
                }
            });
    };
}

export function noPNR({value, label}, index) {
    return function(dispatch, getState) {
        const loading = getPnrLoading(getState(), index);
        if (!loading) {
            dispatch({
                type: 'NO_PNR',
                profile: {
                    'pnr': null,
                    'company': value,
                    'company_name': label,
                },
                index,
            });
        }

        return Promise.resolve();
    };
}

export function reuseSearch(profile, previousBookingId, value) {
    return {
        type: 'REUSE_SEARCH',
        profile,
        value,
        previousBookingId,
        index: 0,
    };
}

export function discardPNR() {
    const data = settings['search_form_params'];
    const value = {
        hotels: parseHotelsValue(data),
        cars: parseCarsValue(data.cars),
    };
    return {
        type: 'DISCARD_PNR',
        value,
        index: 'all'
    };
}

export function formChangePNR({value, errors}) {
    return {type: 'CHANGE_FORM_PNR', value, errors};
}

export function openAddPnr(isModal=false) {
    if (isModal) {
        return {type: 'OPEN_ADD_PNR_ON_SEARCH'};
    }

    return {type: 'OPEN_ADD_PNR'};
}

export function closeAddPnr(isModal=false) {
    if (isModal) {
        return {type: 'CLOSE_ADD_PNR_ON_SEARCH'};
    }
    return {type: 'CLOSE_ADD_PNR'};
}

export function deletePnr(index) {
    return function (dispatch) {
        dispatch({type: 'DELETE_PNR', index});
        return dispatch(loadConfigurationIds());
    };
}

export function editPnr(index) {
    return {type: 'EDIT_ADD_PNR', index};
}

export function toPnrList() {
    return {type: 'TO_PNR_LIST'};
}

export function openPnrModal() {
    return showModal('pnrSearch');
}

export function discardPnrForm() {
    return {type: 'DISCARD_PNR_FORM'};
}
