
export default function confermaFaxes(state, {type, data}) {
    if (!state || type === 'DISCARD') {
        return {data: null, loading: false};
    }

    if (type === 'LOADING') {
        return {...state, loading: true};
    }

    if (type === 'LOADED' && data) {
        return {loading: false, data};
    }

    if (type === 'ADDED') {
        return {loading: false, data: [...state.data, data]};
    }

    if (type === 'NET_FAIL') {
        return {...state, loading: false};
    }

    return state;
}
