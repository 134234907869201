import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {FEATURE_FLAGS_SET, VALUE_SWITCHES_SET} from './actionTypes';

const initialState = {
    flags: {},
    canOverride: false,
    valueSwitches: {},
};

export default function featureFlags(state=initialState, {type, payload={}}) {
    const {flags, canOverride=state.canOverride, valueSwitches} = payload;
    if (type === FEATURE_FLAGS_SET) {
        return {
            ...state,
            ...omitBy({
                flags,
                valueSwitches,
                canOverride,
            }, isUndefined),
        };
    }

    if (type === VALUE_SWITCHES_SET) {
        return {
            ...state,
            valueSwitches,
        };
    }

    return state;
}
