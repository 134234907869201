import omit from 'lodash/omit';
import {ADDED, CHANGED, CHECK, FILTER, LOADING, LOADED, LOADED_PAGINATED, SORT, SEARCH, REMOVED, REMOVE_FILTER, PAGINATE, NET_FAIL} from '../actions/types';


function replace(hash, id, data) {
    return {
        ...hash,
        [id]: data,
    };
}

function edit(hash, id, data) {
    const oldData = hash[id];
    return {
        ...hash,
        [id]: {...oldData, ...data},
    };
}

function remove({hash, paged, ...state}, removeId) {
    return {
        ...state,
        loading: false,
        paged: paged.filter((id)=> id !== removeId),
        hash: omit(hash, String(removeId)),
    };
}

function checkAll(ids, flag) {
    return ids.reduce((acc, id)=> ({...acc, [id]: flag}), {});
};

function index(list) {
    return list.reduce((acc, obj)=> {
        acc[obj.id] = obj;
        return acc;
    }, {});
}

function serverPaginated(state, data, pagination, query, warnings) {
    return {
        ...state,
        pageBy: pagination,
        sortBy: pagination.order || pagination.ordering,
        filters: query || state.filters,
        paged: data.map((el)=> el.id),
        hash: index(data),
        warnings,
    };
}

function removeFilter(state, query) {
    if (!query) {
        return {...state, filters: [], searchBy: null};
    }

    if (query === 'search') {
        return {...state, searchBy: null};
    }

    const newFilters = state.filters.filter((filter)=> {
        return filter.field !== query;
    });
    return {...state, filters: newFilters};
}

export default function (state, {type, id, data, pagination, flag, field, query, errors, warnings}) {
    if (!state) {
        return {
            checked: {},
            loading: false,
            error: false,
            hash: {},
            paged: [],
            pageBy: {
                'total': 0,
                'total_pages': 0,
                'start_index': 0,
                'end_index': 0,
                'page_size': 5,
                'page': 1
            },
            filters: [],
            sortBy: null,
            searchBy: null,
        };
    }

    if (type === SORT) {
        return {...state, sortBy: field};
    }

    if (type === SEARCH) {
        return {...state, searchBy: query};
    }

    if (type === FILTER) {
        return {...state, filters: query};
    }

    if (type === REMOVE_FILTER) {
        return removeFilter(state, query);
    }

    if (type === CHECK && Array.isArray(id)) {
        return {...state, checked: checkAll(id, flag)};
    }

    if (type === CHECK) {
        return {...state, checked: {...state.checked, [id]: flag}};
    }

    if (type === PAGINATE) {
        return {
            ...state,
            pageBy: {
                ...state.pageBy,
                ...query
            }
        };
    }

    if (type === LOADING) {
        return {...state, error: false, loading: true};
    }

    if (type === NET_FAIL) {
        return {...state, error: true, loading: false, errors};
    }

    if (type === ADDED) {
        return {
            ...state,
            loading: false,
            paged: [data.id, ...state.paged],
            hash: replace(state.hash, data.id, data)
        };
    }
    if (type === LOADED) {
        if (id) {
            return {
                ...state,
                loading: false,
                error: false,
                hash: replace(state.hash, id, data)
            };
        }

        return {
            ...state,
            loading: false,
            error: false,
            hash: index(data),
        };
    }
    if (type === LOADED_PAGINATED) {
        return serverPaginated(
            {...state, loading: false},
            data, pagination, query, warnings
        );
    }
    if (type === CHANGED && id) {
        return {...state, hash: edit(state.hash, id, data)};
    }

    if (type === REMOVED) {
        return remove(state, id);
    }

    return state;
}
