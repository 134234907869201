import {combineReducers} from 'redux';
import {booleanReducer} from 'airborne/utils/createReducer';

export const REQUIRED_INDEX_ACTIONS = [
    'TRAVELER_PROFILES_DISCARD',
    'COMPANY_PROFILES_DISCARD',
    'COMPANY_PROFILES_LOADING',
    'COMPANY_PROFILES_LOADED',
    'COMPANY_PROFILES_FAIL',
    'COMPANY_PROFILE_CHANGED',
    'TSPM_PROFILES_SUGGESTED',
    'TRAVELER_PROFILES_LOADING',
    'TRAVELER_PROFILES_LOADED',
    'TRAVELER_PROFILES_FAIL',
    'COMPANY_SUGGESTED_ADDED',
    'TRAVELER_PROFILE_CHANGED',
    'TRAVELER_PROFILE_IDS_CHANGED',
    'COMPANY_SUGGESTED_LOADING',
    'COMPANY_SUGGESTED_LOADED',
    'COMPANY_SUGGESTED_FAIL',
    'NO_PNR',
    'SELECT_CONFIGURATION',
    'DISCARD_CONFIGUTATION',
    'TSPM_MODAL_LOADED',
];

const travelers = combineReducers({
    loading: booleanReducer(false,
        ['TRAVELER_PROFILES_LOADING'],
        ['NO_PNR', 'TRAVELER_PROFILES_FAIL', 'TRAVELER_PROFILES_LOADED', 'TRAVELER_PROFILES_DISCARD']
    ),

    results: booleanReducer(false,
        ['TRAVELER_PROFILES_LOADED'],
        ['NO_PNR', 'TRAVELER_PROFILES_DISCARD', 'TRAVELER_PROFILES_DISCARD']
    ),

    data: function(state=[], {type, data}) {
        if (type === 'TRAVELER_PROFILES_LOADED') {
            return data;
        }
        if (type === 'TRAVELER_PROFILES_DISCARD'
            || type === 'TRAVELER_PROFILES_FAIL'
            || type === 'NO_PNR'
        ) {
            return [];
        }
        return state;
    },

    error: function(state=null, {type, error=null}) {
        if (type === 'TRAVELER_PROFILES_DISCARD'
            || type === 'TRAVELER_PROFILES_LOADED'
            || type === 'NO_PNR'
        ) {
            return null;
        }
        if (type === 'TRAVELER_PROFILES_FAIL') {
            return error;
        }
        return state;
    },

    suggested: function (state=[], {type, travelers: suggested}) {
        if (type === 'TSPM_PROFILES_SUGGESTED') {return suggested;}
        if (type === 'NO_PNR') {return [];}
        return state;
    },
});

const companies = combineReducers({
    loading: booleanReducer(false,
        ['COMPANY_PROFILES_LOADING'],
        ['NO_PNR', 'COMPANY_PROFILES_FAIL', 'COMPANY_PROFILES_DISCARD', 'COMPANY_PROFILES_LOADED', 'TSPM_MODAL_LOADED']
    ),

    results: booleanReducer(false,
        ['COMPANY_PROFILES_LOADED'],
        ['NO_PNR', 'COMPANY_PROFILES_FAIL', 'COMPANY_PROFILES_DISCARD']
    ),

    data: function(state=[], {type, data}) {
        if (type === 'NO_PNR'
            || type === 'COMPANY_PROFILES_DISCARD'
            || type === 'COMPANY_PROFILES_FAIL'
        ) {
            return [];
        }
        if (type === 'COMPANY_PROFILES_LOADED') {return data;}
        return state;
    },

    error: function(state=null, {type, error=null}) {
        if (type === 'COMPANY_PROFILES_DISCARD'
            || type === 'COMPANY_PROFILES_LOADED'
            || type === 'NO_PNR'
        ) {
            return null;
        }
        if (type === 'COMPANY_PROFILES_FAIL') {
            return error;
        }
        return state;
    },
});


const suggestedCompanies = combineReducers({
    loading: booleanReducer(false,
        ['COMPANY_SUGGESTED_LOADING'],
        ['NO_PNR', 'TSPM_PROFILES_SUGGESTED', 'COMPANY_SUGGESTED_LOADED', 'COMPANY_SUGGESTED_FAIL']
    ),

    data: function(state=[], {type, data, companies: suggested}) {
        if (type === 'NO_PNR') {return [];}
        if (type === 'COMPANY_SUGGESTED_LOADED') {return data;}
        if (type === 'TSPM_PROFILES_SUGGESTED') {return suggested;}
        if (type === 'COMPANY_SUGGESTED_ADDED') {
            const isNewCompany = (newCompany => (state.every(company => (company.id !== newCompany.id))));
            return [...data.filter(isNewCompany), ...state];
        }
        return state;
    },

    error: function(state=null, {type}) {
        if (type === 'TSPM_PROFILES_SUGGESTED'
            || type === 'COMPANY_SUGGESTED_LOADED'
            || type === 'NO_PNR'
        ) {
            return null;
        }
        return state;
    },
});

const configurationParamsInitial = {
    'tspm_company_id': null,
    'tspm_traveler_id': null
};

const configurations = combineReducers({
    loading: (state = configurationParamsInitial, {type, params}) => {
        if (type === 'CONFIGURATIONS_LOADING') {
            return params;
        }
        if (type === 'CONFIGURATIONS_LOADED' || type === 'CONFIGURATIONS_ERROR') {
            return configurationParamsInitial;
        }
        return state;
    },
    selected: (state=null, {type, id}) => {
        if (type === 'SELECT_CONFIGURATION') {
            return id;
        }
        if (type === 'DISCARD_CONFIGUTATION') {
            return null;
        }
        return state;
    },
    params: (state=configurationParamsInitial, {type, params}) => {
        if (type === 'CONFIGURATIONS_LOADED') {
            return params;
        }
        if (type === 'CONFIGURATIONS_ERROR' || type === 'DISCARD_CONFIGUTATION') {
            return configurationParamsInitial;
        }
        return state;
    },
    data: (state=[], {type, configurations}) => {
        if (type === 'CONFIGURATIONS_LOADED') {
            return configurations;
        }
        if (type === 'DISCARD_CONFIGUTATION') {
            return [];
        }
        return state;
    },
    errors: function(state=null, {type, errors}) {
        if (type === 'CONFIGURATIONS_ERROR') {
            return errors;
        }
        return state;
    },
});

const reducer = combineReducers({
    travelers,
    companies,
    suggestedCompanies,
    configurations,
});
export default reducer;
