import React, {Component} from 'react';
import {connect} from 'react-redux';

import classnames from 'classnames';
import PT from 'prop-types';
import noop from 'lodash/noop';

import {isOptionKeyActive} from 'airborne/store/modules/navigation_shortcuts/selectors';
import {bindShortcut} from 'midoffice/components/shortcut/helper';
import {isModalOpen} from 'airborne/store/modules/header/selectors/modal';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

@connect((state) => ({
    isOptionKeyActive: isOptionKeyActive(state),
    isModalOpen: isModalOpen(state),
    keyboardShortcutsEnabled: getFeatureFlag(state, 'KEYBOARD_SHORTCUTS'),
}))
export default class Shortcut extends Component {
    static propTypes = {
        label: PT.oneOfType([
            PT.string,
            PT.element,
        ]),
        shortcut: PT.string,
        action: PT.func.isRequired,
        isOptionKeyActive: PT.bool,
        isTab: PT.bool,
        isInherit: PT.bool,
        hideShortcut: PT.bool,
        isModalOpen: PT.bool,
        isModalShortcut: PT.bool,
        keyboardShortcutsEnabled: PT.bool,
    };

    static defaultProps = {
        isTab: false,
        hideShortcut: false,
    };

    componentDidMount() {
        const {shortcut, action, hideShortcut} = this.props;

        if (!hideShortcut && shortcut && action) {
            bindShortcut(shortcut, action);
        }
    }

    componentWillUnmount() {
        const {hideShortcut, shortcut, action} = this.props;
        if (!hideShortcut && shortcut && action) {
            bindShortcut(shortcut, noop);
        }
    }

    render() {
        const {isTab, isOptionKeyActive, label, shortcut, hideShortcut, isModalOpen, isModalShortcut, isInherit, keyboardShortcutsEnabled} = this.props;
        if (!keyboardShortcutsEnabled || hideShortcut || (isModalOpen && !isModalShortcut)) {
            return (
                <>{label}</>
            );
        }

        const className = classnames('kb-hint', {
            'kb-tabs-hint': isTab,
            'inherit': isInherit,
        });

        return (
            <>
                {label} {isOptionKeyActive && shortcut && <span className={className}>{shortcut.toUpperCase()}</span>}
            </>
        );
    }
};
