import {listen} from 'airborne/history';
import {navigate} from 'airborne/store/modules/search_hotels/actions/navigate';
import {getCurrent, getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';
import {getBookingUID} from 'airborne/store/modules/confirm_hotels/selectors/details';
import {getStepLogin} from 'airborne/login/authSelectors';
import {getTimeout, getSource} from 'airborne/login/authSelectors';

function pageParams(state) {
    const {
        homepage: {step, destination},
        dest: {view, product},
    } = state;

    const checkoutSlot = getCurrent(state);
    const stepLogin = getStepLogin(state);
    const source = getSource(state);
    const timeout = getTimeout(state);
    const {rateKey, hotelId, step: checkoutStep} = getBooking(state, checkoutSlot);
    const uid = getBookingUID(state, checkoutSlot);
    const {uid: loginUid, token} = state.auth;

    return {stepLogin, source, step, timeout, destination, product, map: view === 'map', hotelId, rateKey, checkoutStep, uid, checkoutSlot, loginUid, token};
}

function watchHistory(store, initialParams) {
    let prevParams = initialParams;
    listen(function(params) {
        store.dispatch(navigate(params, prevParams));
        prevParams = params;
    });
}

export default function init(store, autoNavigate=true) {
    const params = pageParams(store.getState());
    watchHistory(store, params);

    return autoNavigate
        ? store.dispatch(navigate(params, null, true))
        : Promise.resolve();
}
