/* global global */
export function replaceState(obj, url) {
    // Hello IE9
    if (global.history && global.history.replaceState) {
        global.history.replaceState(obj, '', url);
    }
}

export function pushState(obj, url) {
    if (global.history && global.history.pushState) {
        global.history.pushState(obj, '', url);
    }
}

export function listen(fn) {
    global.onpopstate = function(event) {
        fn(event.state);
    };
}
