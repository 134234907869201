import React from 'react';
import PropTypes from 'prop-types';
import {EditorState} from 'draft-js';
import {default as Button, ButtonGroup} from './Button';


export class Undo extends React.Component {
    static propTypes = {
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onChange(EditorState.undo(this.props.editorState));
    }

    render() {
        const canUndo = this.props.editorState.getUndoStack().size !== 0;
        return (
            <Button {...this.props}
                    icon="undo"
                    onClick={this.handleClick}
                    disabled={this.props.disabled || !canUndo} />
        );
    }
}


export class Redo extends React.Component {
    static propTypes = {
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onChange(EditorState.redo(this.props.editorState));
    }

    render() {
        const canRedo = this.props.editorState.getRedoStack().size !== 0;
        return (
            <Button {...this.props}
                    icon="redo"
                    onClick={this.handleClick}
                    disabled={this.props.disabled || !canRedo} />
        );
    }
}


export default class HistoryGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const {className='', ...props} = this.props;

        return (
            <ButtonGroup {...props} className={className}>
                <Undo {...props} />
                <Redo {...props} />
            </ButtonGroup>
        );
    }
}
