/*
 * Widget to build fields like
 *
 * <label>: [ ] <placeholder>
 *
 * General Checkbox widget doesn't allow build checkbox fields with general label like on other fields.
 */
import React from 'react';
import PropTypes from 'prop-types';
import BaseCheckbox from 'midoffice/newforms/widgets/Checkbox';
import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class Checkbox extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string,
    };

    render() {
        return (<BaseCheckbox {...this.props} label={this.props.placeholder} placeholder={null}/>);
    }
}
