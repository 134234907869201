import {dropEmpty} from 'midoffice/helpers/urlParams';
import {searchForm, serializeSSO, serializeTSPM} from 'airborne/homepage2/helpers/search';
import {getAllBookings} from 'airborne/store/modules/checkout_hotels/selectors/booking';

import capitalize from 'lodash/capitalize';
import air, {handleApiError} from './air';
import {isModification, isPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getPrevBookingId} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

function fixSalutation(settings) {
    return settings.salutation
        ? {...settings, salutation: capitalize(settings.salutation)}
        : {...settings, isEmptySalutationByDefault: true};
}

function loadSettingsOne(index) {
    return function loadSettingsD(dispatch, getState) {
        const {
            profile,
            dest: {traveler},
            sso,
        } = searchForm(getState(), index);

        const prevBookingUid = isModification(getState()) ?
            {'previous_booking_uid': getPrevBookingId(getState(), index)}
            : {};

        return air('GET', '/hotels/get_checkout_data/', dropEmpty({
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            ...(traveler ? {'internal_traveler_id': traveler.value} : {}),
            ...serializeTSPM(profile),
            ...serializeSSO(sso),
            ...prevBookingUid,
        })).then((settings)=> {
            dispatch({
                type: 'COMPANY_SETTINGS_LOADED',
                settings: {...fixSalutation(settings), isPnr: isPnr(getState())},
                index,
            });
        })
            .catch(handleApiError)
            .catch(({body: {errors}})=> {
                dispatch({
                    type: 'COMPANY_SETTINGS_FAILED',
                    errors,
                    index,
                });
            });
    };
}

export function loadSettings() {
    return function loadSettingsD(dispatch, getState) {
        return Promise.all(
            getAllBookings(getState())
                .map((booking, index)=> (booking.settings === null)
                    ? dispatch(loadSettingsOne(index))
                    : Promise.resolve()
                )
        );
    };
}
