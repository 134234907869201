import round from 'lodash/round';

/**
 * Replaces prefix in given hash with given replaces
 *
 * @param {Object} data
 * @param {Array} replacements
 *
 * @returns {Object}
 */
export function replacePrefix(data, ...replacements) {
    return Object.keys(data).reduce((acc, key)=> {
        const value = data[key];

        for (const [prefix, newPrefix] of replacements) {
            key = key.replace(prefix, newPrefix);
        }

        return {...acc, [key]: value};
    }, {});
}

/**
 * Finds replacement for given key in supplied map
 *
 * @param {Array[]} map
 * @param {String} lookup
 *
 * @returns {String|null}
 */
function getReplace(map, lookup) {
    for (const [key, replace] of map) {
        if (key === lookup) {
            return replace;
        }
    }

    return null;
}

/**
 * Maps data according to provided map. Is needed to map data, that is passed
 * between server and client store. This one is internal and is exposed by
 * {@link mapDataDirect} and {@link mapDataReverse}
 *
 * @param {Object} data
 * @param {Array[]} map
 *
 * @returns {Object}
 */
function mapData(data, map) {
    return Object.keys(data).reduce((acc, key)=> {
        const newKey = getReplace(map, key);

        if (newKey) {
            return {...acc, [newKey]: data[key]};
        }

        return {...acc, [key]: data[key]};
    }, {});
}

/**
 * This helper function reverses provided map to be used by {@link mapDataReverse}
 *
 * @param {Array[]} map
 *
 * @returns {Array[]}
 */
function reverseMap(map) {
    return map.map(([key,replace])=> [replace, key]);
}

/**
 * Performs direct data mapping. See {@link mapData}
 *
 * @param {Object} data
 * @param {Array[]} map
 *
 * @returns {Object}
 */
export function mapDataDirect(data, map) {
    return mapData(data, map);
}

/**
 * Performs reverse data mapping. See {@link mapData}
 *
 * @param {Object} data
 * @param {Array[]} map
 *
 * @returns {Object}
 */
export function mapDataReverse(data, map) {
    return mapData(data, reverseMap(map));
}

/**
 * Parses string into float with precision if passed
 *
 * @param {String} string
 * @param {Number} precision
 *
 * @returns {float}
 */
export function stringToFloat(string, precision) {
    let value = parseFloat(string);
    if (precision && value) {
        value = round(value, precision);
    }

    return value;
}

/**
 * Parses string into int
 *
 * @param {String} string
 *
 * @returns {int}
 */
export function stringToInt(string) {
    return parseInt(string);
}

/**
 * Assign objects to the target object and preserve getters/setters original behavior
 *
 * We need this one because object spread {...someObject} or Object.assign() -
 * Create data property and copy accessor property value at the moment of new object creation.
 * In this way, the output object will NOT have getters/setters of the source object -
 * instead values of the corresponding getters will be written in the data property with the same name.
 * @param {Object} target target object
 * @param {...Object} sources source objects
 * @returns {Object} New object
 */
export function shallowAssign(target, ...sources) {
    sources.forEach(source => {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    });

    return target;
}
