import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import cx from 'classnames';


function detectPlaceholder () {
    var testNode = document.createElement('input');
    return 'placeholder' in testNode;
}


class WrappedInput extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        value: PropTypes.any,
        'data-testid': PropTypes.string,
    };

    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props, nextProps);
    }

    focus() {
        ReactDOM.findDOMNode(this.refs.input).focus();
    }

    handleChange = (event)=> {
        if (event.target.value === this.props.value) {
            return;
        }
        this.props.onChange(event);
    };

    render() {
        const {value='', ...rest} = this.props;
        return <input ref="input" {...rest} value={value}  onChange={this.handleChange}  />;
    }
}


class IEInput extends WrappedInput {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.any,
        'data-testid': PropTypes.string,
    };

    static defaultProps = {
        onBlur: noop,
        onFocus: noop
    };

    handleFocus = (event)=> {
        this.setState({focused: true});
        this.props.onFocus(event);
    };

    handleBlur = (event)=> {
        this.setState({focused: false});
        this.props.onBlur(event);
    };

    focus = ()=> {
        ReactDOM.findDOMNode(this.refs.input).focus();
    };

    render() {
        let {disabled, className} = this.props;

        className = cx(className, {
            focused: this.state.focused,
            disabled
        });

        return (
            <div className="placeholder-wrap">
                {!this.props.value &&
                    <span className="placeholder"
                        onClick={this.focus}>
                        {this.props.placeholder}
                    </span>
                }
                <input ref="input" {...this.props}
                    className={className}
                    placeholder={null}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }
}


class IETextArea extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        onBlur: noop,
        onFocus: noop
    };

    state = {focused: false};

    handleFocus = (event)=> {
        this.setState({focused: true});
        this.props.onFocus(event);
    };

    handleBlur = (event)=> {
        this.setState({focused: false});
        this.props.onBlur(event);
    };

    render() {
        let {className, value} = this.props;
        let {focused} = this.state;
        className = cx(className, {
            placeholder: !focused && !value,
        });

        return (<textarea {...this.props}
            placeholder={null}
            className={className}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.props.onChange}
            value={value || (focused ? '' : this.props.placeholder)} />);
    }
}

export let PlainInput = detectPlaceholder() ? WrappedInput : IEInput;
export let TextArea = detectPlaceholder() ? 'textarea' : IETextArea;
