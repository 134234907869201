import gettext from 'airborne/gettext';
import {createSchema} from 'midoffice/newforms/helpers';
import {BooleanField} from 'midoffice/newforms/fields-stateless';

const TermsSchema = createSchema({
    fields: {
        'accept_terms': BooleanField,
    },
    validate(value) {
        const {'accept_terms': acceptTerms} = value;
        if (acceptTerms) {
            return {'accept_terms': null};
        }
        return {'accept_terms': gettext('Please agree to updated terms of service.')};
    }
});

export default TermsSchema;
