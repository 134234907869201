import {showModal} from 'airborne/store/modules/header/actions/modal';

export function showNewRoute() {
    return {type: 'SHOW_WITH_NEW_ROUTER'};
}

export function showSettings() {
    return {type: 'SHOW_PROFILE_SETTINGS'};
}

export function showBookingRecords(search=null) {
    return {type: 'SHOW_BOOKING_RECORDS', value: search};
}
export function showBookingRecordsCars(search=null) {
    return {type: 'SHOW_BOOKING_RECORDS_CARS', value: search};
}
export function showBookingRecordsAir(search=null) {
    return {type: 'SHOW_BOOKING_RECORDS_AIR', value: search};
}
export function showExternalBookingRecordsAir() {
    return {type: 'SHOW_EXTERNAL_BOOKING_RECORDS_AIR'};
}

export function showEmailOffers(pnr) {
    return {type: 'SHOW_EMAIL_OFFERS', pnr};
}

export function resetBookingUid() {
    return {type: 'BOOKING_UID', uid: null, index: 0};
}

export function setPnrReady() {
    return {type: 'PNR_READY'};
}
