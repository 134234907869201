import gettext from 'airborne/gettext';

export const PROGRESS_STATUSES = {
    IDLE: 'IDLE',
    IN_PROGRESS: 'IN_PROGRESS',
    SKIPPED: 'SKIPPED',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
};

const STEPS = {
    'ticket_retrieve': gettext('Retrieving original ticket'),
    'fare_replace': gettext('Replacing original fare with new fare'),
    'fare_delete': gettext('Removing original fare from PNR'),
    'fare_create': gettext('Storing new fare in PNR'),
    'fare_tweak': gettext('Fare adjustments'),
    'ticket_exchange': gettext('Requesting exchange'),
    'ticket_issue': gettext('Issuing new ticket'),
    'emds_process': gettext('Processing EMDs'),
};

const GENERAL_STATUSES = {
    'start': PROGRESS_STATUSES.IN_PROGRESS,
    'success': PROGRESS_STATUSES.SUCCESS,
    'failed': PROGRESS_STATUSES.ERROR,
    'skipped': PROGRESS_STATUSES.SKIPPED,
    'warning': PROGRESS_STATUSES.WARNING,
};

export const getStepText = (step) => STEPS[step];
export const getStepStatus = (status) => status ? GENERAL_STATUSES[status] : PROGRESS_STATUSES.IDLE;

