import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {Editor, EditorState} from 'draft-js';
import {filterWarnings} from './utils';

import Toolbar from './toolbar/Toolbar';

export default class DraftEditor extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        toolbarProps: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        children: PropTypes.any,
        disabled: PropTypes.bool,
        filterWarnings: PropTypes.bool,
        language: PropTypes.string.isRequired,
        errors: PropTypes.object.isRequired,
        languages: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.focus = this.focus.bind(this);
        this.handleReference = this.handleReference.bind(this);
    }

    componentDidMount() {
        if (this.props.filterWarnings) {
            this.restoreWarnings = filterWarnings();
        }
    }

    componentWillUnmount() {
        this.restoreWarnings && this.restoreWarnings();
    }

    focus() {
        this.editorRef.focus();
    }

    handleReference(ref) {
        this.editorRef = ref;
    }

    renderToolbar() {
        const {children, editorState, toolbarProps, onChange} = this.props;
        const props = {...toolbarProps, editorState, onChange};

        if (children) {
            return React.Children.map(this.props.children, (child=> (
                React.cloneElement(child, props)
            )));
        }

        return (
            <Toolbar {...props} />
        );
    }

    render() {
        const {errors, languages, language: activeTabLanguage, className, editorState, disabled, ...props} = this.props;
        const isDefaultLanguageContentError = languages.some((obj) => (obj.isDefault && obj.id === activeTabLanguage)) && errors && Object.keys(errors).some(key => key === activeTabLanguage);

        return (
            <div className={cx('draft-editor', className)}>
                {this.renderToolbar()}
                <div className={cx('draft-content', {'draft-content--error': isDefaultLanguageContentError})}
                    onClick={this.focus}
                >
                    <Editor {...props}
                        ref={this.handleReference}
                        editorState={editorState}
                        readOnly={disabled} />
                </div>
            </div>
        );
    }
}
