/* eslint-disable immutable/no-mutation */
import find from 'lodash/find';
import template from 'lodash/template';
import extend from 'lodash/extend';
import moment from 'moment';

import settings from 'airborne/settings';  // eslint-disable-line no-unused-vars
import systemData from 'airborne/systemData';

import {ValidationError, SkipField} from './exceptions';
import {MaxValueValidator,
    MinValueValidator,
    ExactLengthValidator,
    MaxLengthValidator,
    MinLengthValidator,
    ColorValidator,
    DefaultLanguageValidator,
    IataCodeValidator} from './validators';
import {BoundField} from './boundFields';

import Checkbox from './widgets/Checkbox';
import CompaniesTree from './widgets/CompaniesTree';
import DateInput from './widgets/DateInput';
import File from './widgets/File';
import Input from './widgets/Input';
import MultiCheckbox from './widgets/MultiCheckbox';
import TreeCheckbox from 'midoffice/newforms/widgets/GranularPermissions';
import RichText from './widgets/RichText';
import Select from './widgets/Select';
import TagList from './widgets/Tags';
import TextArea from './widgets/Textarea.js';

const formatStr = (string, context={})=> template(string)(context);

let __creationCounter = 0;
const count = ()=> __creationCounter++;

/**
 * Base class for a schema field.
 * @class {Field}
 */
export class Field {

    /**
     * @param {object} [options]
     * @param {boolean} [options.required=true] - field is required
     * @param {boolean} [options.allowEmpty=false] - null is accepted as value
     * @param {boolean} [options.readOnly=false] - field is read only
     * @param {boolean} [options.disabled=false] - field is disabled
     * @param {*} [options.defaultValue] - value to use when field is empty
     * @param {*} [options.initial=null] - value to use when creating new form
     * @param {string} [options.label=''] - field's label on the form
     * @param {string} [options.hint=''] - additional tooltip hint for a label
     * @param {string} [options.placeholder=''] - additional tooltip hint for a label
     * @param {string} [options.helpText=''] - help text under the field
     * @param {object} [options.messages] - custom error messages map
     * @param {Function[]} [options.validators] - a list of additional validators
     * @param {object} [options.props] - extra props for component
     * @param {React.Component} [options.widget] - component that renders the input
     */
    constructor(options={}) {
        this.creationCounter = count();

        this.required = options.required;
        this.defaultValue = options.defaultValue;

        if (typeof this.required === 'undefined') {
            this.required = typeof this.defaultValue === 'undefined';
        }

        let {
            allowEmpty=false, readOnly=false, disabled=false, initial=null,
            label='', hint='', placeholder='', helpText='', widget=Input
        } = options;

        this.readOnly = readOnly;
        this.disabled = disabled;
        this.allowEmpty = allowEmpty;
        this.initial = initial;

        this.label = label;
        this.placeholder = placeholder;
        this.hint = hint;
        this.helpText = helpText;

        this.widget = widget;

        // Make the following properties readonly and non enumerable
        // so they don't get automatically passed to the widget while rendering
        let {messages={}, validators=[], props={}} = options;

        Object.defineProperty(this, 'props', {
            value: extend(this.getDefaultProps(), props)
        });

        Object.defineProperty(this, 'messages', {
            value: extend(this.getDefaultMessages(), messages)
        });

        Object.defineProperty(this, 'validators', {
            value: this.getDefaultValidators().concat(validators)
        });
    }

    /**
     * Return an array of field's default validators
     * @returns {Function[]}
     */
    getDefaultValidators() { return []; }

    /**
     * Return a map of field's default error messages
     * @returns {object}
     */
    getDefaultMessages() {
        return {required: 'This field is required.'};
    }

    /**
     * Bind this field to a form instance
     * @param {string} fieldName - field's name in that form
     * @param {(Form|BoundField)} parent - form that holds the field
     * @returns {BoundField}
     */
    getBoundField(fieldName, parent) {
        return new BoundField(this, fieldName, parent);
    }

    /**
     * Given the incoming value, return the value for this field or
     * throw SkipField if it is empty.
     * @param {*} value
     * @throws {SkipField}
     * @returns {*}
     */
    getIncomingValue(value) {
        return this.getValue(value);
    }

    /** @private */
    getValue(value) {
        if (this.readOnly || this.disabled) {
            if (typeof value !== 'undefined') {
                return value;
            }
            if (typeof this.defaultValue === 'undefined') {
                throw new SkipField();
            }
            return this.defaultValue;
        }
        if (typeof value === 'undefined') {
            if (typeof this.defaultValue === 'undefined') {
                throw new SkipField();
            }
            return this.defaultValue;
        }
        if (value === null && !this.allowEmpty) {
            throw new SkipField();
        }
        return value;
    }

    /**
     * Return initial value to use when creating a new form
     * @returns {*}
     */
    getInitial() { return this.initial; }

    /**
     * Check if a value is empty and return or throw appropriate exception
     * @param {*} value
     * @throws {ValidationError} when field is required
     * @throws {SkipField} when field is empty and not required
     * @returns {*}
     */
    validateEmptyValue(value) {
        try {
            return this.getValue(value);
        }
        catch (e) {
            if (e instanceof SkipField && this.required) {
                this.fail('required');
            }
            else {
                throw e;
            }
        }
    }

    /**
     * Validate data and return the validated internal value.
     * @param {*} data
     * @throws {ValidationError} if the field is invalid
     * @throws {SkipField} if the field is empty and not required
     * @returns {*}
     */
    runValidation(data) {
        if (this.readOnly || this.disabled) { return data; }

        let value = this.validateEmptyValue(data);
        if (value != null) {
            value = this.toInternalValue(value);
            this.runValidators(value);
        }
        return value;
    }

    /**
     * Test the given value against all the validators on the field.
     * @param {*} value
     * @throws {ValidationError} if value is invalid
     * @throws {SkipField} if the field is empty and not required
     */
    runValidators(value) {
        let errors = [];
        for (let validator of this.validators) {
            try {
                if (typeof validator === 'object') {
                    validator.validate(value);
                }
                else {
                    validator.call(this, value);
                }
            }
            catch (e) {
                if (e instanceof ValidationError) {
                    if (typeof e.detail === 'object') {
                        // Error already contains a mapping of
                        // fields to errors
                        throw e;
                    }
                    if (e.code in this.messages) {
                        errors.push(this.messages[e.code]);
                    }
                    else {
                        errors.push(e.detail);
                    }
                }
                else {
                    throw e;
                }
            }
        }
        if (errors.length) {
            throw new ValidationError(errors);
        }
    }

    /**
     * Transform the *incoming* primitive data into a native value.
     * @param {*} data - incoming data
     * @returns {*}
     */
    toInternalValue(data) { return data; }

    /**
     * Transform the *outgoing* native value into primitive data.
     * @param {*} value
     * @returns {*}
     */
    toRepresentation(value) {
        if (value == null && !this.allowEmpty) { throw new SkipField(); }
        return value;
    }

    /**
     * Returns properties for form field rendering.
     * Override to provide extra properties for field component.
     * @returns {object}
     */
    getDefaultProps() { return {}; }

    /**
     * A helper method that simply raises a validation error.
     * @param {string} errorKey - a message key from `this.messages`
     * @param {object} [context] - optional context to format the message with
     * @throws {ValidationError}
     */
    fail(errorKey, context) {
        let message = this.messages[errorKey];
        if (typeof message === 'function') { message = message(context); }
        else if (message) { message = formatStr(message, context); }
        throw new ValidationError(message);
    }
}


/**
 * Represents Boolean type and can optionally be null.
 *
 * @class {BooleanField}
 * @extends {Field}
 */
export class BooleanField extends Field {

    TRUE_VALUES = [true, 1, 't', 'true', 'True'];
    FALSE_VALUES = [false, 0, 'f', 'false', 'False'];

    constructor(options={}) {
        let {initial=false, widget=Checkbox, ...rest} = options;
        super({initial, widget, ...rest});
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'invalid': template('<%= data %> is not a valid boolean.')
        });
    }

    toInternalValue(data) {
        if (this.TRUE_VALUES.includes(data)) { return true; }
        if (this.FALSE_VALUES.includes(data)) { return false; }
        this.fail('invalid', {data});
    }

    toRepresentation(value) {
        if (this.TRUE_VALUES.includes(value)) { return true; }
        if (this.FALSE_VALUES.includes(value)) { return false; }
        return this.initial;
    }
}


/**
 * @class {CharField}
 * @extends {Field}
 */
export class CharField extends Field {

    constructor(options={}) {
        let {initial='', stripValue=false, ...rest} = options;
        super({initial, ...rest});

        this.stripValue = stripValue;

        let {maxLength, minLength} = options;
        if (maxLength && minLength && (maxLength === minLength)) {
            this.validators.push(new ExactLengthValidator(maxLength));
            this.messages.exactLength =
                formatStr(this.messages.exactLength, {maxLength});
        }
        else {
            if (maxLength) {
                this.validators.push(new MaxLengthValidator(maxLength));
                this.messages.maxLength =
                    formatStr(this.messages.maxLength, {maxLength});
            }
            if (minLength) {
                this.validators.push(new MinLengthValidator(minLength));
                this.messages.minLength =
                    formatStr(this.messages.minLength, {minLength});
            }
        }
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'blank': 'This field may not be blank.',
            'exactLength': 'Field must have <%= maxLength %> characters.',
            'maxLength': 'Ensure this field has no more than <%= maxLength %> characters.',
            'minLength': 'Ensure this field has at least <%= minLength %> characters.',
        });
    }

    toInternalValue(data) {
        let value = String(data);
        value = this.stripValue ? value.trim() : value;
        if (value === '' && !this.allowEmpty) {
            this.fail('blank');
        }
        return value;
    }

    toRepresentation(value) { return value && String(value); }

    getDefaultProps() {
        return {type: 'text'};
    }
}


/**
 * @class {TextField}
 * @extends {CharField}
 */
export class TextField extends CharField {

    constructor(options={}) {
        let {widget=TextArea, ...rest} = options;
        super({widget, ...rest});
    }
}


/**
 * @class {TextField}
 * @extends {CharField}
 */
export class JsonField extends TextField {

    toInternalValue(data) {
        if (typeof data === 'string') {
            if (data !== '') {
                try {
                    JSON.parse(data);
                }
                catch (e) {
                    this.fail('invalid');
                }
            }
            else if (!this.allowEmpty) {
                this.fail('blank');
            }
            return data;
        }
        return data && JSON.stringify(data, null, 2);
    }

    toRepresentation(value) {
        try {
            return value && JSON.parse(value);
        }
        catch (e) {
            return null;
        }
    }

    getDefaultMessages() {
        let messages = super.getDefaultMessages();
        return {
            ...messages,
            'invalid': 'This field must contain valid JSON.'
        };
    }
}


/**
 * @class {TranslatedField}
 * @extends {Field}
 */
export class TranslatedField extends Field {

    runValidators(values) {
        let errors = {};
        let fail = false;
        for (const id in values) {
            try {
                super.runValidators(values[id]);
            }
            catch (e) {
                errors[id] = e.detail;
                fail = true;
            }
        }
        if (fail) {
            throw new ValidationError(errors);
        }
    }
}

/**
 * @class {IntegerField}
 * @extends {Field}
 */
export class IntegerField extends Field {

    constructor(options={}) {
        let {maxValue, minValue, ...rest} = options;

        super(rest);

        if (typeof maxValue != 'undefined') {
            this.validators.push(new MaxValueValidator(maxValue));
            this.messages.maxValue =
                formatStr(this.messages.maxValue, {maxValue});
        }
        if (typeof minValue != 'undefined') {
            this.validators.push(new MinValueValidator(minValue));
            this.messages.minValue =
                formatStr(this.messages.minValue, {minValue});
        }
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'invalid': 'This field may not be blank.',
            'maxValue': 'Ensure this value is less than or equal to <%= maxValue %>.',
            'minValue': 'Ensure this value is greater than or equal to <%= minValue %>.'

        });
    }

    getDefaultProps() {
        return {type: 'number'};
    }

    toInternalValue(data) {
        let value = parseInt(data, 10);
        if (isNaN(value)) {
            if (this.required) { this.fail('invalid', {data}); }
            else { throw new SkipField(); }
        }
        return value;
    }

    toRepresentation(value) {
        return (isNaN(value) || value === null) ? null : parseInt(value, 10);
    }
}


/**
 * @class {FloatField}
 * @extends {IntegerField}
 */
export class FloatField extends IntegerField {

    toInternalValue(data) {
        let value = parseFloat(data, 10);
        if (isNaN(value)) {
            if (this.required) { this.fail('invalid', {data}); }
            else { throw new SkipField(); }
        }
        return value;

    }
}


/**
 * @class {ChoiceField}
 * @extends {Field}
 */
export class ChoiceField extends Field {

    constructor(options={}) {
        let {choices=[], widget=Select, ...rest} = options;
        super({widget, ...rest});

        this.choices = choices;
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'invalidChoice': template('"<%= data %>" is not a valid choice.')
        });
    }

    toInternalValue(data) {
        var choice = this.getChoice(data);
        if ((typeof choice) === 'undefined') {
            if (this.allowEmpty) { return null; }
            else { this.fail('invalidChoice', {data}); }
        }
        return data;
    }

    getChoice(byValue) {
        return find(this.choices, ([value])=> value === byValue);
    }
}


/**
 * @class {MultipleChoiceField}
 * @extends {ChoiceField}
 */
export class MultipleChoiceField extends ChoiceField {

    constructor(options={}) {
        let {initial=[], widget=MultiCheckbox,...rest} = options;
        super({initial, widget, ...rest});

    }

    toInternalValue(values) {
        if (!Array.isArray(values) || values.length === 0) {
            if (this.required) { this.fail('required'); }
            return [];
        }
        return values.map((value)=> super.toInternalValue(value));
    }

}

const flattenTree = (nodes) => nodes.reduce(
    (acc, {children, ...rest}) => {
        const newItems = children ? flattenTree(children) : [rest];
        return [...acc, ...newItems];
    },
    [],
);
/**
 * @class {TreeChoiceField}
 * @extends {MultipleChoiceField}
 */
export class TreeChoiceField extends MultipleChoiceField {
    constructor(options={}) {
        let {initial=[], widget=TreeCheckbox, tree, disabledChoises, ...rest} = options;
        const choices = flattenTree(systemData.common.ALL_PERMISSIONS).map(({code, title}) => [code, title]);
        super({initial, widget, choices, ...rest});
        this.tree = tree;
        this.disabledChoises = disabledChoises;
    }

    toInternalValue(values) {
        return super.toInternalValue(values);
    }

}

/**
 * @class {DateField}
 * @extends {Field}
 */
export class DateField extends Field {

    constructor(options={}) {
        let {format=settings.DATE_FORMAT, inputFormat=settings.USER.date_format_str,
            widget=DateInput, minDate, maxDate, ...rest} = options;
        super({widget, ...rest});

        this.format = format;
        this.inputFormat = inputFormat;

        this.minDate = minDate ? moment(minDate) : null;
        this.maxDate = maxDate ? moment(maxDate) : null;
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'invalid': 'Please enter a valid date',
            'maxDate': 'Please select a date earlier than <%= date %>',
            'minDate': 'Please select a date later than <%= date %>'
        });
    }

    getIncomingValue(value) {
        value = super.getIncomingValue(value);
        let date = moment(value, [this.format, this.inputFormat, moment.ISO_8601]);
        if (date.isValid()) { return date; }
        throw new SkipField();
    }

    runValidation(value) {
        value = value && moment(super.runValidation(value), this.inputFormat);
        if (!value && this.allowEmpty) { return null; }
        if (!value || !value.isValid()) { this.fail('invalid'); }
        if (this.maxDate && this.maxDate.isBefore(value)) {
            this.fail('maxDate', {date: this.maxDate.format(this.inputFormat)});
        }
        if (this.minDate && this.minDate.isAfter(value)) {
            this.fail('minDate', {date: this.minDate.format(this.inputFormat)});
        }
        return value;
    }

    /**
     * Serialize to a string for sending to server
     * @param {Date|moment|string} value
     * @returns {string}
     */
    toRepresentation(value) {
        if (value && !moment.isMoment(value)) {
            value = moment(value, this.inputFormat);
        }

        if (value && value.isValid()) {
            return value.format(this.format);
        }
        throw new SkipField();
    }
}


/**
 * Accepts an Array of elements and validates each of them
 * against child field.
 *
 * @class {ArrayField}
 * @extends {Field}
 */
export class ArrayField extends Field {

    constructor(options={}) {
        let {child, initial=[], ...rest} = options;
        super({initial, ...rest});
        this.child = child;
    }

    getDefaultMessages() {
        let messages = super.getDefaultMessages();
        return {
            ...messages,
            invalid: '"<%= data %> is not an Array"'
        };
    }

    getIncomingValue(value) {
        value = super.getIncomingValue(value);
        if (Array.isArray(value)) {
            return value.map((item)=> this.child.getIncomingValue(item));
        }
        throw new SkipField();
    }

    /**
     * Apply child's validation to each of the elements of the array.
     * @param {Array} data
     * @returns {Array}
     */
    toInternalValue(data) {
        if (!Array.isArray(data)) { this.fail('invalid', {data}); }
        if (data.length === 0 && this.required) { this.fail('required'); }

        let result = [];
        let errors = [];

        for (let item of data) {
            try {
                result.push(this.child.runValidation(item));
                errors.push(null);
            }
            catch (e) {
                if (e instanceof ValidationError) { errors.push(e.detail); }
                else { throw e; }
            }
        }

        if (errors.some(Boolean)) {
            throw new ValidationError(errors);
        }

        return result;
    }

    toRepresentation(value) {
        if ((value == null || value.length === 0)) {
            if (this.allowEmpty) { return []; }
            throw new SkipField();
        }

        return value.map((item)=> this.child.toRepresentation(item));
    }

}


/**
 * Handles files
 *
 * @class {FileField}
 * @extends {Field}
 */
export class FileField extends Field {

    constructor(options={}) {
        let {
            widget=File,
            label=false,
            preview=false,
            props={},
            accept,
            ...rest
        } = options;

        props = {accept, ...props};

        super({...rest, widget, label, props});
        this.preview = preview;
    }
}


/**
 * Handles colors
 *
 * @class {FileField}
 * @extends {Field}
 */
export class ColorField extends Field {
    constructor(...rest) {
        super(...rest);
        this.validators.push(new ColorValidator());
        this.messages.color = formatStr(this.messages.color);
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'color': 'Please, enter a valid hex color.',
        });
    }
}


export class MultilanguageField extends Field {
    constructor(options) {
        const {
            languages=[],
            required=false,
            widget=RichText,
            label=false,
            ...rest
        } = options;

        const props = {...options.props, languages};
        super({...rest, label, widget, required, props});

        const defaultLanguage = find(languages, {isDefault: true});
        if (defaultLanguage) {
            this.validators.push(new DefaultLanguageValidator({
                defaultLanguage: defaultLanguage.id,
            }));
        }
    }

    getDefaultProps() {
        return {languages: this.languages};
    }
}


/**
 * TagsField
 *
 * @class {TagsField}
 * @extends {Field}
 */
export class TagsField extends Field {
    constructor(options={}) {
        const {widget=TagList, ...rest} = options;
        super({widget, ...rest});
    }
}


export class CompanySelectField extends Field {
    constructor(props) {
        let widget = CompaniesTree;
        super({...props, widget});
    }
}

export class IATACodeField extends CharField {
    constructor(...rest) {
        super(...rest);
        this.validators.push(new IataCodeValidator());
    }
}
