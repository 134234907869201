import {priceOrder, priceFilter, pricePreference, toUSD} from './price';
import {paginateHotels, mergeAvail} from './hotels';


export function needAllHotels(order, filters, preference, view) {
    return view === 'map' || priceOrder(order) || priceFilter(filters) || pricePreference(preference);
};

function filterFetched(avail) {
    return function(hotel) {
        return !avail[hotel.id];
    };
}

export function selectHotels(hotels, avail, filters, order, pagination, needAll) {
    hotels = hotels.data;
    avail = avail.data;

    let selectedHotels = hotels;
    if (!needAll) {
        const {paged: page} = paginateHotels(
            mergeAvail(hotels, avail), filters,
            order, pagination
        );
        selectedHotels = page;
    }

    return selectedHotels.filter(filterFetched(avail)).map(({id})=> id);
};

export function isLoading(loading) {
    return Boolean(Object.keys(loading).length);
};


function findMinimal(list, start, field, inUSD) {
    return list.reduce((acc, rate)=> {
        const amount = inUSD
            ? toUSD(rate[field], rate.currency)
            : rate[field];
        return Math.min(acc || amount, amount || acc);
    }, start || null) || null;
}


function findPolicy(list) {
    return list.reduce(([outOfPolicy, reasons], rate)=> (
        outOfPolicy
            ? [rate['out_of_policy'], rate['out_of_policy_reason']]
            : [outOfPolicy, reasons]
    ), [true, []]);
}

function findMinimalBreakFast(list) {
    return list.reduce(([oldFlag, oldAmount], rate)=> {
        const amount = toUSD(rate.total, rate.currency);
        const flag = (rate['rate_value_adds'] || []).includes('FBFST');
        if (amount === oldAmount) {
            return [flag || oldFlag, amount];
        }
        return (amount < oldAmount)
            ? [flag, amount]
            : [oldFlag, oldAmount];
    }, [null, Infinity])[0];
}


function findAvail(hotelId, data, oldPrice, isLoading, currency) {
    const isCompanyCurrency = currency === '...';
    const [firstRate] = data || [];
    if (!firstRate) {
        return oldPrice;
    }
    const [outOfPolicy, reasons] = findPolicy(data);
    return {
        'id': hotelId,
        'min_avg_rate': findMinimal(data, isLoading && oldPrice['min_avg_rate'], 'average', !isCompanyCurrency),
        'min_total': findMinimal(data, isLoading && oldPrice['min_total'], 'total', !isCompanyCurrency),
        'min_avg_rate_local_currency': findMinimal(data, isLoading && oldPrice['min_avg_rate_local_currency'], 'local_average'),
        'min_total_local_currency': findMinimal(data, isLoading && oldPrice['min_total_local_currency'], 'local_total'),
        'min_rate_breakfast_included': isLoading
            ? oldPrice['min_rate_breakfast_included'] || findMinimalBreakFast(data)
            : findMinimalBreakFast(data),
        'out_of_policy': outOfPolicy,
        'out_of_policy_reason': reasons,
        'currency': isCompanyCurrency ? firstRate['currency'] : 'USD',
        'local_currency': firstRate['local_currency'],
        'is_available': true,
    };
}

export function getAvail(availData, rates, hotelId, currency) {
    return findAvail(
        hotelId,
        rates.data[hotelId],
        availData[hotelId],
        availData[hotelId] && rates.loading[hotelId],
        currency
    );
}

export function selectAvail(state, hotelId) {
    return getAvail(state.hotels.avail.data, state.hotels.rates, hotelId, state.i18n.currency);
}
