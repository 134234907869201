import omit from 'lodash/omit';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import {draftifyHTML} from 'midoffice/components/draft/utils';
import settings from 'airborne/settings';

const AGENCY_KEYS = {
    'terms-and-conditions': [
        'agency:legal:terms_and_conditions',
        'agency:legal:enforce_terms_and_conditions',
        'agency:regional:language',
    ],
};

const KEYS = {
    'agencies': AGENCY_KEYS,
};


export function parse(data, {section, treeName}) {
    const [textKey, reacceptanceKey,
        languageKey] = KEYS[treeName][section];

    const defaultText = settings.LANGUAGES.reduce(
        (defaultText, [lang])=> ({...defaultText, [lang]: ''}),
        {}
    );
    const text = {...defaultText, ...data[textKey]};
    return {
        text: mapValues(text, draftifyHTML),
        enforceReacceptance: data[reacceptanceKey],
        defaultLanguage: data[languageKey],
        languages: Object.keys(data[textKey] || {}).map((lang)=> ({
            id: lang
        })),
    };
}


export function serialize({text, languages, enforceReacceptance, updateDate=false}, {section, treeName}) {
    const [textKey, reacceptanceKey] = KEYS[treeName][section];
    const langKeys = languages.map(language => language?.id);
    return {
        [reacceptanceKey]: enforceReacceptance,
        [textKey]: pick(text, langKeys),
        'date_update_needed': updateDate,
    };
}


export function fromParent(data) {
    return omit(data, 'languages', 'defaultLanguage');
}

export function serializeInheritance(inherits, params) {
    return inherits
        ? {'date_update_needed': params['date_update_needed'], inherits}
        : {...params, inherits};
}
