/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import document from 'airborne/document';

export default class PageTitle extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };

    componentDidMount() {
        document.title = this.props.children; // eslint-disable-line immutable/no-mutation
    }

    render() {
        return null;
    }
}
