'process i18n';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import {PlainInput} from 'midoffice/components/IE9';
import gettext from 'airborne/gettext';


class FilterMenu extends React.Component {

    static propTypes = {
        actions: PropTypes.object,
        narrow: PropTypes.bool,
        wide: PropTypes.bool,
        search: PropTypes.string,
        placeholder: PropTypes.string.isRequired,
        children: PropTypes.any,
        onChange: PropTypes.func,
        onSearch: PropTypes.func,
    };

    state = {open: false};

    toggleMenu = ()=> {
        this.setState({open: !this.state.open});
    };

    searchBy = (event)=> {
        if (this.props.actions) {
            this.props.actions.search(event.target.value);
        }
        if (this.props.onChange) {
            this.props.onChange(event.target.value, 'search');
        }
    };

    apply = ()=> {
        this.toggleMenu();
        if (this.props.actions) {
            this.props.actions.apply();
        }
        if (this.props.onSearch) {
            this.props.onSearch();
        }
    };

    applySearch = ()=> {
        if (this.props.actions) {
            this.props.actions.apply();
        }
        if (this.props.onSearch) {
            this.props.onSearch();
        }
    };

    handleKeyDown = (event)=> {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.applySearch();
        }
    };

    renderMenu() {
        const {narrow, wide} = this.props;
        const cls = classnames('complex-dropdown', {
            'complex-dropdown--narrow': narrow,
            'complex-dropdown--wide': wide,
        });
        return (<div className={cls} style={{display: 'block'}}>
            <div className="complex-dropdown__header">
                <h4 className="modal-title">{gettext('Filter by')}</h4>
            </div>

            <div className="complex-dropdown__content">
                {this.props.children}
            </div>

            <div className="complex-dropdown__footer">
                <Button variant="light" onClick={this.toggleMenu}>{gettext('Cancel')}</Button>
                <button onClick={this.apply} className="btn-info btn">{gettext('Apply')}</button>
            </div>
        </div>);
    }

    render() {
        return (<div>
            {this.props.children && (
                <div className="dropdown col-narrow">
                    <Button variant="light" title={gettext('Filter by')} onClick={this.toggleMenu}>
                        {gettext('Filter by')} <span className="caret" />
                    </Button>
                    {this.state.open && this.renderMenu()}
                </div>
            )}

            <div className="search-widget col-narrow search-widget--large" data-test="filters-widget">
                <div className="input-group">
                    <PlainInput type="text"
                        className="form-control"
                        onChange={this.searchBy}
                        onKeyDown={this.handleKeyDown}
                        id="mainInputOnPage"
                        value={this.props.search || ''}
                        placeholder={this.props.placeholder}
                    />

                    <div className="input-group-btn pull-left">
                        <Button variant="light" onClick={this.applySearch}>{gettext('Search')}</Button>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default FilterMenu;
