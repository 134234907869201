import flatten from 'lodash/flatten';
import {createSelector} from 'reselect';
import {memoizeMap} from 'airborne/helpers/memoizeMap';

import {filteredForm} from './helpers';


function allKeys(hotelId, rateKey, recommend) {
    return [
        hotelId,
        [rateKey, recommend && recommend.lcard, recommend && recommend.nolcard]
    ];
}

const allRateKeys = memoizeMap(
    [
        (booking)=> booking.hotelId,
        (booking)=> booking.rateKey,
        (booking)=> booking.recommend,
    ],
    allKeys,
);

const restoreRate = createSelector(
    [
        (state)=> allRateKeys(state.multiBooking.items),
        ({hotels})=> hotels.rates.data,
    ],
    function restoreRate(query, rates) {
        const byHotel = query.reduce((acc, [hotelId, keys])=> hotelId ? ({
            ...acc,
            [hotelId]: [...(acc[hotelId] || []), ...keys],
        }) : acc, {});
        const filteredRates = Object.entries(byHotel).reduce(
            (acc, [hotelId, keys])=> {
                if (!rates[hotelId]) return acc;
                const list = acc[hotelId] || [];
                return {
                    ...acc,
                    [hotelId]: [
                        ...list,
                        ...rates[hotelId].filter(
                            ({'rate_key': rateKey})=> (
                                keys.includes(rateKey)
                            )
                        )
                    ]
                };
            },
            {}
        );
        return Object.entries(filteredRates)
            .map(([hotelId, hotelRates])=> ([
                'set',
                `hotels.rates.data.${hotelId}`,
                hotelRates,
            ]));
    }
);

const restoreHotel = createSelector(
    [
        (state)=> allRateKeys(state.multiBooking.items),
        ({hotels})=> hotels.hotels.data,
        ({hotels})=> hotels.avail.data,
    ],
    function(multi, hotels, avail) {
        const query = multi.map(([hotelId])=> hotelId);
        return [
            [
                'set',
                'hotels.hotels.data',
                hotels.filter(({id}) => hotels.length === 1 || query.includes(id)),
            ],
            ...query.map((id)=> ([
                'set',
                `hotels.avail.data.${id}`,
                avail[id] || null,
            ])).filter(([,, data])=> data)
        ];
    }
);

const restoreMultiBookingForm = memoizeMap(
    (booking)=> booking.value,
    (value, idx)=> (['set', `multiBooking.items.${idx}.value`, filteredForm(value)])
);

const restoreMultiBookingStep = memoizeMap(
    (details)=> details.uid,
    (uid, idx)=> ([
        ['set', `multiBooking.items.${idx}.step`, uid ? 'book' : 'general'],
    ]),
);
const BOOKING_FOR_SAVE = [
    'hotelId',
    'rateKey',
    'uuid',
    'retry',
    'checkoutData.email',
    'recommend.show',
    'recommend.lcard',
    'recommend.nolcard',
    'recommend.result',
    'recommend.originalRateKey',
    'recommend.rejectedRateKey',
    'recommend.excuse',
];

export default [
    ['multiBookingDetails', ['uid', 'data']],
    'hotels.ratesSid',
    'hotels.availSid',
    'hotels.pinnedId',
    'hotels.hotels.total',
    ['multiBooking.items', BOOKING_FOR_SAVE],
    (state)=> restoreMultiBookingForm(state.multiBooking.items),
    (state)=> flatten(restoreMultiBookingStep(state.multiBookingDetails)),
    restoreRate,
    restoreHotel,
    'emailOffers.pnr',
];
