import PT from 'prop-types';

export const BaggageAllowanceShape = PT.shape({
    maxPieces: PT.number,
    maxWeight: PT.number,
    weightUnit: PT.string,
});

export const FareFamilyGroupShape = PT.shape({
    fareGroupKey: PT.string,
    total: PT.number,
    ticketRefundable: PT.bool,
    currency: PT.string,
    priceClassName: PT.string,
    priceClassDescription: PT.string,
    baggageAllowances: PT.arrayOf(PT.shape({
        travelerId: PT.string,
        baggageAllowance: BaggageAllowanceShape,
    })),
});
