'process i18n';
import partial from 'lodash/partial';
import 'midoffice/air-bookings/types';
import {clearTags, removeTag, changeForm, serverPaginateCollection, serverSearchCollection} from 'midoffice/data/actions/serverFiltered';
import {paginateCollection} from 'midoffice/data/actions/common';
import {freshBookingsActions} from 'midoffice/store/modules/freshBookings';
import {getAirBookingsIds} from 'midoffice/air-bookings/selectors';
import aio from 'midoffice/helpers/aio';

const endpoint = 'airBookings';
const filtersEndpoint = 'airBookingFilters';

export const defaultPaginateBookings = partial(paginateCollection, endpoint);
export const paginateBookings = partial(serverPaginateCollection, endpoint, filtersEndpoint);
export const searchBookings = partial(serverSearchCollection, endpoint, filtersEndpoint);
export const refreshBookings = function() {
    return async function refreshBookingsD(dispatch, getState) {
        const prevBookingsIds = getAirBookingsIds(getState());
        await dispatch(searchBookings());
        const newBookingsIds = getAirBookingsIds(getState());
        const freshBookings = newBookingsIds.filter(
            bookingId => !prevBookingsIds.includes(bookingId)
        );
        dispatch(freshBookingsActions.setFreshBookings(freshBookings));
        await aio.sleep(3.5);
        dispatch(freshBookingsActions.clearFreshBookings());
    };
};
export const changeFiltersForm = partial(changeForm, filtersEndpoint);
export const clearBookingsTags = partial(clearTags, searchBookings, filtersEndpoint);
export const clearBookingsTag = partial(removeTag, searchBookings, filtersEndpoint);
