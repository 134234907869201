import {createSelector} from 'reselect';
import {matchConditions} from 'airborne/checkout2/helpers/crd';
import {getSelectedRate} from 'airborne/search2/helpers/rates';
import {checkoutForm} from 'airborne/checkout2/helpers/checkoutForm';
import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';

function getSettings(state, index) {
    return getBooking(state, index).settings;
}

function getCheckoutForm(state, index) {
    return checkoutForm(state, index).value;
}

export const getCrd = createSelector(
    getCheckoutForm,
    getSelectedRate,
    getSettings,
    (form, rate, settings) => {
        const fullCrd = settings && settings.crd || [];
        return fullCrd.filter(
            matchConditions.bind(null, rate, form['paid_by'], form['evoucher'])
        );
    }
);

