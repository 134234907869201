import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';

import DateRange from 'midoffice/newforms/widgets/DateRange';
import {injectField} from 'midoffice/newforms/decorators';
import {toDayStart} from 'midoffice/helpers/date';
import {getDateLimits} from 'airborne/homepage2/helpers/dateLimits';

/* Give text extraction utility a chance to find format strings */
gettext('dd/mm/yyyy');
gettext('mm/dd/yyyy');
gettext('Checkin date can\'t be in the past');
gettext('Checkin date can\'t be that far in the future');

function dateLimits(checkin, withTime) {
    if (!checkin) {
        return {};
    }

    if (withTime) {
        const checkinDay = toDayStart(checkin);
        return {from: checkinDay};
    }

    return {
        from: moment(checkin).add(1, 'days'),
    };
}

function outOfLimits({min, max}, withTime) {
    if (!min || !max) {
        return false;
    }

    const diff = max.diff(min, 'days');
    const limit = withTime ? 0 : 1;
    return diff < limit;
}

@injectField
export default class CheckinRange extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            min: PropTypes.any,
            max: PropTypes.any,
        }),
        onChange: PropTypes.func.isRequired,
        withTime: PropTypes.bool,
        is12hTime: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.today = getDateLimits().min; // eslint-disable-line immutable/no-mutation
        this.tomorrow = this.today.clone().add(1, 'days'); // eslint-disable-line immutable/no-mutation
        this.yearFromNow = getDateLimits().max; // eslint-disable-line immutable/no-mutation
    }

    handleChange = (value, name)=> {
        const {withTime} = this.props;

        if (outOfLimits(value, withTime)) {
            this.props.onChange({min: value.min, max: null}, name);
        }
        else {
            this.props.onChange(value, name);
        }
    };

    render() {
        const {value, withTime} = this.props;
        const maxLimits = dateLimits(value && value.min, withTime);

        return (
            <DateRange
                {...this.props}
                onChange={this.handleChange}
                inputFormat={settings.SHORT_DATE_FORMAT}
                placeholder={gettext(settings.SHORT_DATE_FORMAT.toLowerCase())}
                minFrom={this.today}
                minTo={this.yearFromNow}
                maxFrom={maxLimits.from || this.tomorrow}
                maxTo={maxLimits.to || this.yearFromNow} />
        );
    }
}
