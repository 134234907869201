import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import {getTimeFromMinutes} from 'airborne/search_air/helpers/duration';
import gettext from 'airborne/gettext';
import union from 'lodash/union';
import {
    isDirectFareGroup,
    isOneStopFareGroup,
    isTwoOrMoreStopsFareGroup,
} from 'airborne/search_air/helpers';
import systemData from 'airborne/systemData';

export const includesPreference = (entities, key) => entities?.length && entities.includes(key);
export const PREFERRED_AIRLINES_CHOICES = {
    BCD_PREFERRED: 'bcdPreferred',
    COMPANY_PREFERRED: 'companyPreferred',
};

export const getODFilters = ({
    time,
    duration,
    flightNumber,
    pinnedFlight,
    cabin,
    excludeLongFlight,
    preference,
}, ODIndex) => {
    // common filters for all Flight Options
    let filters = [
        {field: 'durationMinutes', op: 'lte', value: duration || null},
        {field: 'durationMinutes', op: 'lte', value: excludeLongFlight || null},
        {field: 'cabins', op: 'intersect', value: cabin || null},
        {
            field: PREFERRED_AIRLINES_CHOICES.BCD_PREFERRED,
            op: 'eq',
            value: includesPreference(preference, PREFERRED_AIRLINES_CHOICES.BCD_PREFERRED) || null
        },
        {
            field: PREFERRED_AIRLINES_CHOICES.COMPANY_PREFERRED,
            op: 'eq',
            value: includesPreference(preference, PREFERRED_AIRLINES_CHOICES.COMPANY_PREFERRED) || null
        },
    ];

    // Filters  appliable only for specific ODIndex. (i.e return flight only)
    if (time && time[ODIndex]) {
        const {departure = {}, arrival = {}} = time[ODIndex];
        if (departure.min) {
            filters = [
                ...filters,
                {field: 'departureMinutes', op: 'gte', value: departure.min},
            ];
        }
        if (departure.max) {
            filters = [
                ...filters,
                {field: 'departureMinutes', op: 'lte', value: departure.max},
            ];
        }
        if (arrival.min) {
            filters = [
                ...filters,
                {field: 'arrivalMinutes', op: 'gte', value: arrival.min},
            ];
        }
        if (arrival.max) {
            filters = [
                ...filters,
                {field: 'arrivalMinutes', op: 'lte', value: arrival.max},
            ];
        }
    }
    if (flightNumber && flightNumber.ODIndex === ODIndex) {
        filters = [
            ...filters,
            {field: 'flightNumbers', op: 'intersect', value: [flightNumber.number]},
        ];
    }

    if (pinnedFlight && pinnedFlight[ODIndex]) {
        filters = [
            ...filters,
            {
                field: 'flightNumbers',
                op: 'intersectAnd',
                value: pinnedFlight[ODIndex],
            },
        ];
    }

    return filters;
};

export const getGroupFilters = ({
    price: {min, max} = {},
    fareType,
    baggage,
    flightNumber,
    pinnedFlight,
    excludeLongFlight,
    providers,
    airlines,
    alliances,
}) => ([
    {field: 'total', op: 'lte', value: max || null},
    {field: 'total', op: 'gte', value: min || null},
    {field: 'fareTypes', op: 'intersect', value: fareType || []},
    {field: 'maxDurationMinutes', op: 'lte', value: excludeLongFlight || null},
    {
        field: 'withBaggage',
        op: 'eq',
        value: (baggage && baggage.length === 1) ? baggage[0] : null
    },
    {
        field: 'flightNumbers',
        op: 'intersect',
        value: (flightNumber && flightNumber.number) ? [flightNumber.number] : null
    },
    {
        field: 'airlines',
        op: 'intersect',
        value: airlines
    },
    {
        field: 'alliances',
        op: 'intersect',
        value: alliances
    },
    {
        field: 'flightNumbers',
        op: 'intersectAnd',
        value: pinnedFlight ? flatten(values(pinnedFlight)) : null,
    },
    {field: 'providerType', op: 'in', value: providers?.length ? providers : null},
]);

// uniq in operatingCarrier only
export const getOperatingCarriers = segments =>
    uniqBy(
        segments
            .map(({operatingCarrier}) => operatingCarrier)
            .filter(Boolean)
            .map(({code, name}) => ({code, name})),
        'code'
    );

export const getCabins = segments =>
    uniq(segments.map(({cabin}) => cabin));

export const getDurationRangeFromFOs = flightOptions => flightOptions.reduce(
    ({min, max}, {durationMinutes})=> ({
        min: Math.min(durationMinutes, min),
        max: Math.max(durationMinutes, max),
    }),
    {min: Infinity, max: 0}
);

export const getLongFlightDurationByRange = ({min}) => Math.floor(1.3 * min + 120);

export const getFlightOptionsByODIndex = (fareGroups) => {
    return fareGroups.reduce((acc, group) => {
        const {originDestinations} = group;

        return originDestinations.map((OD, index) => {
            const {flightOptions} = OD;

            return [...acc[index] || [], ...flightOptions];
        });
    }, []);
};

export const getFOfromGroups = groups => groups.reduce((acc, {originDestinations}) =>
    originDestinations.reduce(
        (acc, {flightOptions}) => [...acc, ...flightOptions],
        acc,
    ), []);

export const countTotalFlights = groups => groups.reduce(
    (acc, {originDestinations}) => acc + originDestinations.reduce(
        (acc, {flightOptions}) => acc + flightOptions.length, 0
    ), 0);

export const getFiltersWithInfo = (filters, choices, key) => {
    const filter = get(filters, key);
    return choices.filter(([choice]) => Array.isArray(filter) ? filter.includes(choice) : filter === choice);
};

export const getTimeRangeString = (min, max) =>
    `${getTimeFromMinutes(min)} – ${getTimeFromMinutes(max)}`;

export const calcPriceRange = fareGroups => fareGroups.reduce(({min, max}, {total}) => ({
    min: Math.floor(Math.min(min, total)),
    max: Math.ceil(Math.max(max, total)),
}), {min: Infinity, max: 0});

export const createStopsChoices = (fareGroups) => {
    const stops = fareGroups.reduce(({direct, one, two}, fareGroup) => ({
        direct: direct + Number(isDirectFareGroup(fareGroup)),
        one: one + Number(isOneStopFareGroup(fareGroup)),
        two: two + Number(isTwoOrMoreStopsFareGroup(fareGroup)),
    }), {direct: 0, one: 0, two: 0});

    return [
        [1, gettext('Direct'), stops.direct],
        [2, gettext('1 Stop'), stops.one],
        [3, gettext('2+ Stops'), stops.two],
    ];
};

export const createAirlineChoices = (allFlightOptions, filteredFlightOptions) => {
    return union.apply(null, allFlightOptions.map(({airlines}) => airlines)).sort().map(airline => [
        airline,
        airline,
        filteredFlightOptions.reduce(
            (acc, {airlines}) => airlines.includes(airline) ? acc + 1 : acc,
            0
        )
    ]);
};

export const createAirlineChoicesForFares = (allFlightOptions, fareGroups) => {
    return union.apply(null,
        allFlightOptions
            .filter(({operatingCarriers}) => !operatingCarriers.some(carrier => carrier.code === '**'))
            .map(({airlines}) => airlines)
    )
        .sort()
        .map(airline => [
            airline,
            airline,
            fareGroups.reduce(
                (acc, {airlines}) => {
                    return airlines.some((fareAirline) => fareAirline.includes(airline)) ? acc + 1 : acc;
                }, 0)
        ]);
};

export const createAllianceChoices = (flightOptions) => {
    const airAlliances = Object.entries(systemData.air.AIR_ALLIANCES);

    return airAlliances.map(([alliance]) => [
        alliance,
        alliance,
        flightOptions.reduce(
            (acc, {alliances = []}) => {
                return alliances.some((fareAlliance) => fareAlliance === alliance) ? acc + 1 : acc;
            }, 0)
    ]);
};
