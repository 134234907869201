import axiosApiProvider from 'airborne/helpers/axiosApiProvider';

import {FEATURE_FLAGS_SET, VALUE_SWITCHES_SET} from './actionTypes';

const FEATURE_FLAGS_URL = '/internal/accounts/flags';
export const FEATURE_FLAGS_PREFIX = 'flag_';

const parseFeatureFlags = (flags) => {
    return flags?.reduce((acc, flag) => ({
        ...acc,
        [flag.name]: {
            defaultValue: Boolean(flag['is_on_default']),
            description: flag['description'],
            value: Boolean(flag['is_on'])
        },
    }), {});
};

function replaceValueIfIsDefault(item) {
    if (item['value'] !== item['value_default']) {
        return item;
    }
    return {
        ...item,
        value: null,
    };
}

const parseValueSwitches = valueSwitches => {
    return valueSwitches.map(replaceValueIfIsDefault).reduce((acc, item) => ({...acc, [item.name]: item}), {});
};

export const setFeatureFlags = ({flags, canOverride, valueSwitches}) => {
    return {
        type: FEATURE_FLAGS_SET,
        payload: {flags, canOverride, valueSwitches},
    };
};

export const setValueSwitches = (valueSwitches) => {
    return {
        type: VALUE_SWITCHES_SET,
        payload: {valueSwitches},
    };
};

export function requestFeatureFlags() {
    return function requestFeatureFlagsD(dispatch) {
        return axiosApiProvider.request({url: FEATURE_FLAGS_URL})
            .then(({data: {flags, 'can_override': canOverride=false, 'value_switches': valueSwitches=[]}}) => {
                const parsedFlags = parseFeatureFlags(flags) || {};
                const parsedValueSwitches = parseValueSwitches(valueSwitches) || {};
                window.FEATURE_FLAGS = parsedFlags;
                dispatch(setFeatureFlags({
                    flags: parsedFlags,
                    valueSwitches: parsedValueSwitches,
                    canOverride,
                }));
            });
    };
}
