import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import gettext from 'airborne/gettext';
import settings from 'airborne/settings';
import Button from 'react-bootstrap/Button';
import orderBy from 'lodash/orderBy';

import {loadInbox, markRead} from 'airborne/store/modules/header/actions/inbox';
import {getMessages} from 'airborne/store/modules/header/selectors/inbox';

class Message extends React.Component {
    static propTypes = {
        lang: PropTypes.string.isRequired,
        text: PropTypes.object.isRequired,
        fromDate: PropTypes.object.isRequired,
        'new': PropTypes.bool,
    };

    render() {
        const {lang, text, fromDate, 'new': unread} = this.props;
        return (<div className="nav-popover__dropdown__item">
            {text[lang] || text.en}
            <footer className="nav-popover__dropdown__footer">
                {fromDate.format(settings.USER.date_format_str)}
            </footer>
            {unread && (<aside className="nav-popover__dropdown__label">
                {gettext('new')}
            </aside>)}
        </div>);
    }
}

function mapStateProps(state) {
    return {
        lang: get(state, 'i18n.lang') || 'en',
        messages: orderBy(getMessages(state, {type: 'inbox'}), 'fromDate', 'desc'),
    };
}

@connect(mapStateProps, {loadInbox, markRead})
export default class Inbox extends React.Component {
    static propTypes = {
        lang: PropTypes.string.isRequired,
        messages: PropTypes.array,
        loadInbox: PropTypes.func.isRequired,
        markRead: PropTypes.func.isRequired,
    }

    state = {
        open: false,
    };

    componentDidMount() {
        this.load();
    }

    load() {
        this.props.loadInbox();
    }

    handleOpen = (event)=> {
        event && event.preventDefault();
        const {open} = this.state;
        this.setState({open: !open});
        if (open) this.handleRead();
    };

    handleRead = ()=> {
        this.props.markRead({type: 'inbox'});
    };

    render() {
        const {lang, messages} = this.props;
        const {open} = this.state;
        if (!messages || !messages.length) {
            return null;
        }
        const unread = messages.reduce((acc, {'new': unread})=> unread ? acc + 1 : acc, 0);
        const cls = classnames('inbox-lnk', {'inbox-lnk--new': unread});
        return (<div className="nav-popover">
            <a href="#" className={cls} onClick={this.handleOpen}>
                <span className="glyphicons glyphicons-envelope" />
                {unread > 0 && (
                    <span className="inbox-lnk__counter">
                        {unread}
                    </span>
                )}
            </a>
            <div className={classnames('nav-popover__dropdown', {show: open})}>
                <div className="nav-popover__dropdown__header">
                    <h4>{gettext('Messages')}</h4>
                    <Button variant="link" onClick={this.handleOpen}>
                        <span className="glyphicon glyphicon-remove" />
                    </Button>
                </div>
                <div className="nav-popover__dropdown__content">
                    {messages.map((el, idx)=> (<Message {...el} lang={lang} key={idx} />))}
                </div>
                <div className="nav-popover__dropdown__control">
                    <Button className="btn-block" variant="link" onClick={this.handleOpen}>
                        {gettext('Close')}
                    </Button>
                </div>
            </div>
        </div>);
    }
}
