import omit from 'lodash/omit';

const BILLING_PREFIX = 'contact_info:billing_address:';
const BILLING_KEYS = [
    'address',
    'city',
    'state_code',
    'postal_code',
    'country_code',
    'phone'
];

export function parse(obj) {
    const {'contact_info:billing_address': billing, ...rest} = obj;

    return {
        ...rest,
        ...Object.fromEntries(
            BILLING_KEYS.map(item => [
                BILLING_PREFIX + item,
                (billing || {})[item] || null
            ])
        )
    };
}

export function serialize(obj) {
    const billingKeys = Object.keys(obj).filter(
        (key)=> key.startsWith(BILLING_PREFIX)
    );
    const billing = billingKeys.reduce((acc, key)=> (
        obj[key] ? {
            ...(acc || {}),
            [key.substr(BILLING_PREFIX.length)]: obj[key]
        } : acc
    ), null);

    return {
        ...omit(obj, billingKeys),
        'contact_info:billing_address': billing
    };
}
