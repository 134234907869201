import React from 'react';
import Input from 'midoffice/newforms/widgets/Input';

import Modal from 'react-bootstrap/Modal';

import Button from 'midoffice/components/Button';


export default class SentryTest extends React.Component {
    state = {
        data: '',
    };

    handleChange = (value)=> {
        this.setState({data: value});
    }

    handleSend = ()=> {
        const key = this.state.data;
        this[key].callUnexisitingFunc();
    }

    render() {
        const show = window.location.search === '?testSentry';

        return (
            <Modal show={show}>
                <Modal.Body>
                    <Input onChange={this.handleChange} value={this.state.data} />
                    <br/>
                    <Button bsStyle="secondary" onClick={this.handleSend}> Send Report </Button>
                </Modal.Body>
            </Modal>
        );
    }
}
