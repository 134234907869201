import 'midoffice/data/types/pnrTool';
import isEqual from 'lodash/isEqual';
import {parse} from 'airborne/types';

import {LOADED, CHANGED} from './types';
import {fetchEntry, removeEntry, addEntry} from './collection';


const endpoint = 'pnr-tool';


export function retrieve(pnr, entityId, system) {
    return fetchEntry(endpoint, pnr, {entityId, system});
}


export function cancel(pnr, entityId, system) {
    return function(dispatch) {
        return dispatch(removeEntry(endpoint, pnr, {entityId, system}, true)).then(
            (data)=> {
                data = {...parse(endpoint, data), canceled: true};
                dispatch({type: LOADED, endpoint, id: pnr, data});
            },
            ()=> {},
        );
    };
}


function fillEntityDependentKeys(value={}, oldValue={}) {
    const oldEntity = oldValue.entity;
    const newEntity = value.entity;

    if (isEqual(oldEntity, newEntity)) { return value; }

    const entity = value.entity || {};
    const picked = {
        'office_id': entity.gdsOid,
        'smid': entity.smid,
        'tspm_entity_id': entity.tspmId,
    };

    return {
        ...value,
        ...picked,
    };
}


export function changeForm(value, errors, form) {
    return function(dispatch, getState) {
        const oldValue = getState()[endpoint][form].value;
        value = fillEntityDependentKeys(value, oldValue);

        dispatch({type: CHANGED, endpoint, value, errors, form});
    };
}


export function clearForms() {
    return function(dispatch) {
        dispatch(changeForm({}, {}, 'createForm'));
        dispatch(changeForm({}, {}, 'retrieveForm'));
    };
}


export function create(data) {
    return function(dispatch) {
        return dispatch(addEntry({endpoint, ...data}, true)).then(
            (data)=> parse(endpoint, data)
        );
    };
}
