export function loaded(state, data) {
    return {...state, loading: false, data};
}

function loading(state) {
    return {...state, loading: true};
}

export function fail(state) {
    return {...state, loading: false};
}

export function loadAble(fn, loadingType, failType, abortType) {
    return function loadAbleR(state, action) {
        if (!state) {
            return fn(state, action);
        }
        if (action.type === loadingType) {
            return loading(state);
        }
        if (action.type === failType || action.type === abortType) {
            return fail(state);
        }
        return fn(state, action);
    };
}
