import find from 'lodash/find';
import settings from 'airborne/settings';

export default function fixCurrency(hotel, currency) {
    if (!currency || !hotel || !hotel.available || !hotel.available.currency) {
        return hotel;
    }
    const localSrcCurrency = hotel.available['local_currency'];
    const srcCompanyCurrency = hotel.available['currency'];
    const isLocal = currency === '___';
    const isCompanyCurrency = currency === '...';
    const destCur = isLocal ? localSrcCurrency : currency;
    const calculatedCurrency = isCompanyCurrency ? srcCompanyCurrency : destCur;
    const [fxRate] = find(settings.FX_RATES, (([,code]) => code === calculatedCurrency));
    const [srcFxRate] = find(settings.FX_RATES, ([, code]) => code === hotel.available.currency);
    const {
        available: {
            'min_avg_rate': rate,
            'min_total': total,
            ...avail
        }
    } = hotel;
    return {
        ...hotel,
        available: {
            ...avail,
            currency: calculatedCurrency,
            'min_total': total * fxRate / srcFxRate,
            'min_avg_rate': rate * fxRate / srcFxRate,
            'min_avg_rate_usd': rate / srcFxRate
        }
    };
}

