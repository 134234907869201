import React from 'react';
import PropTypes from 'prop-types';

import Select from 'midoffice/newforms/widgets/Select';
import {convert12to24h, convert24to12h} from 'midoffice/helpers/time';

const HOURS = [
    [0, '00'],
    [1, '01'],
    [2, '02'],
    [3, '03'],
    [4, '04'],
    [5, '05'],
    [6, '06'],
    [7, '07'],
    [8, '08'],
    [9, '09'],
    [10, '10'],
    [11, '11'],
    [12, '12'],
    [13, '13'],
    [14, '14'],
    [15, '15'],
    [16, '16'],
    [17, '17'],
    [18, '18'],
    [19, '19'],
    [20, '20'],
    [21, '21'],
    [22, '22'],
    [23, '23'],
];

const AMERICAN_HOURS = HOURS.slice(1, 13);

const MINUTES = [
    [0, '00'],
    [15, '15'],
    [30, '30'],
    [45, '45'],
];

const PERIOD = [
    ['AM', 'AM'],
    ['PM', 'PM'],
];


function expand(time, is12hTime) {
    if (!time) {
        return {hours: 0, minutes: 0};
    }

    const [hours, minutes] = time.split(':').map((s)=> parseInt(s, 10));

    if (is12hTime) {
        return convert24to12h({hours, minutes});
    }
    return {hours, minutes};
}

function collapse({hours, minutes}) {
    return `${hours}:${minutes}`;
}

function change(time, update, is12hTime) {
    let newTime = {...expand(time), ...update};
    if (is12hTime) {
        newTime = convert12to24h(newTime);
    }
    return collapse(newTime);
}


export default class Time extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        defaultValue: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        is12hTime: PropTypes.bool,
    };

    handleChange = (partValue, param)=> {
        const {value, defaultValue, onChange, name, is12hTime} = this.props;
        onChange(change(value || defaultValue, {[param]: partValue}, is12hTime), name);
    };

    render() {
        const {disabled, value, defaultValue, label, onBlur, is12hTime} = this.props;
        const {hours, minutes, period} = expand(value || defaultValue, is12hTime);

        return (
            <div className="datepicker__time__col">
                {label && <div className="datepicker__time__label">{label}</div>}

                <div className="datepicker__time__col__wrapper">
                    <div className="datepicker__time__value">
                        <div className="form-group">
                            <Select name="hours" inputSize="max" value={hours} choices={is12hTime ? AMERICAN_HOURS : HOURS} onChange={this.handleChange} onBlur={onBlur} disabled={disabled} />
                        </div>
                        <div className="form-group">
                            <Select name="minutes" inputSize="max" value={minutes} choices={MINUTES} onChange={this.handleChange} onBlur={onBlur} disabled={disabled} />
                        </div>
                    </div>

                    {is12hTime && <div className="datepicker__time__divider">
                        <div className="form-group">
                            <Select name="period" inputSize="max" value={period} choices={PERIOD} onChange={this.handleChange} onBlur={onBlur} disabled={disabled} />
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}
