import air from 'airborne/store/modules/search_hotels/actions/air';
import {retry, RETRY, IMMEDIATELY_RETRY} from 'airborne/checkout2/helpers/retry';
import {getBookingUID} from 'airborne/store/modules/confirm_hotels/selectors/details';

import StreamService, {MESSAGE_TYPES, ERRORS} from 'airborne/services/Stream';

const RETRY_TIMEOUT = 5000;
const FALLBACK_TIMEOUT = 30 * 1000;
const RETRY_TRIES = 10;
const RETRY_PATIENCE = 5;

function getMessages(error) {
    if (!error) {
        return null;
    }
    return {
        internal: error['internal_error_text'],
        external: error['external_error_text'],
    };
}

function transformError(error) {
    return {body: {message: getMessages(error), recoverable: error && error['is_recoverable']}};
}

function transformCancelError(error) {
    return {body: {message: error && error['external_error_text']}};
}

async function waitForStatuses(id) {

    function action() {
        throw IMMEDIATELY_RETRY;
    }

    function conditionCheck(event) {
        const {type, message} = event;
        const {bookingStatus, cancellationStatus, bookingId, product} = message;
        const isBookingComplete = !cancellationStatus && bookingStatus !== 'in-progress';
        const isCancellationComplete = cancellationStatus && cancellationStatus !== 'in-progress';
        if (type === MESSAGE_TYPES.BOOKING_STATUS && bookingId === id && product === 'hotels' && (isBookingComplete || isCancellationComplete)) {
            return  true;
        }
    }


    try {
        await StreamService.reactOnEvent(
            action,
            conditionCheck,
            FALLBACK_TIMEOUT,
        );
    }
    catch (error) {
        if (error === ERRORS.PUSH_STREAM_INIT_ERROR) {
            throw RETRY;
        }
        throw error;
    }
}

export const getReservationDetals = retry(function getReservationDetals(index, noretry=false, isCancel = true) {
    return async function getReservationDetalsD(dispatch, getState) {
        const uid = getBookingUID(getState(), index);
        const response = await air('GET', '/hotels/get_reservation_details/', {
            'booking_uid': uid,
        });
        dispatch({type: 'BOOKING_DETAILS', data: response, index});

        const status = response['booking_status'];
        const cancelStatus = response['cancellation_status'];

        if (!noretry && (status === 'in-progress' || cancelStatus === 'in-progress')) {
            await waitForStatuses(uid);
        }

        if (status === 'failed') {
            throw transformError(response['booking_error']);
        };

        if (cancelStatus === 'failed' && isCancel) {
            throw transformCancelError(response['cancellation_error']);
        };

        return uid;
    };
}, RETRY_TIMEOUT, RETRY_TRIES, RETRY_PATIENCE);
