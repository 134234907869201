/* global global */
import 'url-search-params-polyfill';
import {start} from 'midoffice/app';
import settings from 'airborne/settings';
import 'midoffice/helpers/react-dnd-compat';
import 'midoffice/styles';
import {store} from 'midoffice/store';

global.midoffice = {// eslint-disable-line immutable/no-mutation
    start,
    settings,
    store,
};
