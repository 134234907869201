import getCompanyLocations from './companyLocations';
import {showModal} from 'airborne/store/modules/header/actions/modal';

export function toMap() {
    return function toMapD(dispatch) {
        dispatch({type: 'SEARCH_MAP'});
        return dispatch(getCompanyLocations());
    };
}

export function toList() {
    return {type: 'SEARCH_LIST'};
}

export function showError(message=null, kind='error') {
    return showModal(kind, {message});
};
