import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

export const CREDENTIALS_SETTINGS_BY_TREE = {
    companies: 'air:lcc_content:carriers:credentials',
    agencies: 'agency:air:lcc_corporate:credentials',
};

export function parse(raw, {treeName}) {
    const credentialsSetting = CREDENTIALS_SETTINGS_BY_TREE[treeName];
    return {...raw, [credentialsSetting]: raw[credentialsSetting].credentials};
}

export function serialize(data, {treeName}) {
    const credentialsSetting = CREDENTIALS_SETTINGS_BY_TREE[treeName];
    const credentials = data[credentialsSetting] ?? [];

    return {
        ...data,
        [credentialsSetting]: {
            credentials: credentials.map(item => omitBy(item, isNil))
        }
    };
}
