import omit from 'lodash/omit';
import {LOADED, SCHEDULE, REMOVED} from 'midoffice/data/actions';

function replace(data, id, value) {
    return {
        ...data,
        [id]: value,
    };
}

function initial() {
    return {
        hash: {},
        timers: {},
    };
}

function discard(state, id) {
    return {
        hash: omit(state.hash, id),
        timers: omit(state.timers, id),
    };
}

export default function progress (state, {type, id, data, timer}) {
    if (!state) {
        return initial();
    }

    if (type === LOADED) {
        return {
            ...state,
            hash: replace(state.hash, id, data)
        };
    }

    if (type === SCHEDULE) {
        return {
            ...state,
            timers: replace(state.timers, id, timer),
        };
    }

    if (type === REMOVED) {
        return discard(state, id);
    }

    return state;
}
