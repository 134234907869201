import React, {useState} from 'react';
import {RichUtils, EditorState, Entity} from 'draft-js';
import {default as Button, ButtonGroup} from './Button';
import {getSelectedEntityBoundaries, createSelection} from '../utils';
import {getLinkAtCursor, getLinkUrl} from './LinkButtons';

type Props = {
    editorState: EditorState;
    onChange: (value: object) => void;
    disabled: boolean;
};

const AddLinkButton = ({editorState, onChange, disabled, ...restProps}: Props) => {
    const [popover, setPopover] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');

    const handleCancel = () => {
        setPopover(false);
    };

    const handleClick = () => {
        if (popover) {
            return handleCancel();
        }
        setPopover(true);
        const linkAtCursor = getLinkAtCursor(editorState);
        setUrl(getLinkUrl(linkAtCursor) || '');
    };

    const handleOk = () => {
        let selection = editorState.getSelection();
        if (getLinkAtCursor(editorState)) {
            const {start, end} = getSelectedEntityBoundaries(editorState);
            selection = createSelection(start, end);
        }

        const entityKey = Entity.create('LINK', 'MUTABLE', {url});
        onChange(RichUtils.toggleLink(editorState, selection, entityKey));

        setPopover(false);
    };

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleOk();
        }
    };

    const renderPopover = () => {
        if (!popover) {
            return null;
        }

        return (
            <div className="draft-popover">
                <input
                    type="text"
                    name="url"
                    className="draft-url-input"
                    value={url}
                    onBlur={handleCancel}
                    onChange={handleUrlChange}
                    onKeyPress={handleKeyPress}
                    autoFocus
                />

                <ButtonGroup>
                    <Button icon="ok" onClick={handleOk} />
                    <Button icon="cancel" onClick={handleCancel} />
                </ButtonGroup>
            </div>
        );
    };

    const hasSelection = !editorState.getSelection().isCollapsed();
    const hasLink = Boolean(getLinkAtCursor(editorState));

    return (
        <span>
            <Button
                icon="link"
                {...restProps}
                onClick={handleClick}
                active={popover}
                disabled={disabled || (!hasSelection && !hasLink)}
            />
            {renderPopover()}
        </span>
    );
};

export default AddLinkButton;
