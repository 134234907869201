/* eslint-disable immutable/no-mutation */
import api from 'midoffice/helpers/api';
import StreamService, {MESSAGE_TYPES} from 'airborne/services/Stream';

const CONNECTION_ERROR = {
    message: 'Internet connection was interrupted',
    title: 'The file could not be scanned.',
};
const FILESIZE_ERROR = {
    message: 'The file is too large. Allowed maximum size is 10mb.',
    title: 'The file could not be scanned.',
};
const INFECTED_ERROR = {
    title: 'File virus check',
    message: 'File is infected',
};

const TIMEOUT = 10 * 1000;

const POLLING_INTERVAL = 1000;

class Baymont {
    constructor() {
        this._token = null;
    }

    async _getToken() {
        try {
            const {access_token: _token} = await api('GET', '/access_token?service=baymont');
            this._token = _token;
        }
        catch (err) {
            this._errorHandler(err);
        }
    }

    _getHeaders() {
        return {
            Authorization: `Bearer ${this._token}`,
        };
    }

    async _uploadFile(file) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            return api('POST', '/baymont/upload', {
                headers: this._getHeaders(),
                data: formData,
            });
        }
        catch (e) {
            this._errorHandler(e);
        }
    }

    async _checkStatuses(state, filename) {
        switch (state) {
            case 'infected':
                return Promise.reject('File infected');
            case 'failed':
                return Promise.reject('Server Error');
            case 'scanned successfully':
                return Promise.resolve(filename);
            default:
                return Promise.reject();
        }
    }

    async _pollingAPI(filename) {
        let checkStatus = null;

        try {
            await StreamService.subscribeOnEventOrPolling({
                eventType: MESSAGE_TYPES.FILE_UPLOAD_STATE,
                eventDeadline: TIMEOUT,
                getProcessId: () => null,
                pollingInterval: POLLING_INTERVAL,
                pollingExecutor: async eventProcessor => {
                    const url = `/baymont/check?filename=${filename}`;
                    const {state} = await api('GET', url, {
                        headers: this._getHeaders(),
                    });
                    return eventProcessor({
                        message: {
                            type: MESSAGE_TYPES.FILE_UPLOAD_STATE,
                            state,
                            filename,
                        },
                    });
                },
                eventProcessor: event => {
                    const {message} = event;
                    const {filename: uploadedFile, state} = message;
                    if (filename === uploadedFile) {
                        checkStatus = state;
                        return state !== 'in progress';
                    }
                },
            });
            return this._checkStatuses(checkStatus, filename);
        }
        catch (e) {
            return this._errorHandler(e);
        }
    }

    _sendFile = async file => {
        return await this._uploadFile(file);
    };

    _errorHandler = error => {
        if (typeof error === 'string') {
            return Promise.reject(INFECTED_ERROR);
        }
        if (error && error.status === 413) {
            return Promise.reject(FILESIZE_ERROR);
        }
        if (error && !Boolean(error.status)) {
            return Promise.reject(CONNECTION_ERROR);
        }
        return Promise.reject({message: 'Something go wrong', title: 'Oops'});
    };

    upload = async file => {
        try {
            await this._getToken();
            const {filename} = await this._sendFile(file);
            return await this._pollingAPI(filename);
        }
        catch (e) {
            return this._errorHandler(e);
        }
    };
}

const BaymontService = new Baymont();

export default BaymontService;
