import PT from 'prop-types';

export const CarrierShape = PT.shape({
    code: PT.string,
    flightNumber: PT.string,
    name: PT.string,
});

export const BaggageShape = PT.shape({
    maxPieces: PT.number,
    maxWeight: PT.number,
    weightUnit: PT.string,
});

export const AirportShape = PT.shape({
    code: PT.string.isRequired,
    title: PT.string.isRequired,
    name: PT.string,
    location: PT.string,
});

export const ArrivalDepartureShape = PT.shape({
    airport: AirportShape.isRequired,
    datetime: PT.string.isRequired,
    terminal: PT.string,
});

export const SegmentShape = PT.shape({
    airplane: PT.string,
    airportChange: PT.bool.isRequired,
    arrival: ArrivalDepartureShape.isRequired,
    cabin: PT.string.isRequired,
    carrier: CarrierShape.isRequired,
    classOfService: PT.string,
    departure: ArrivalDepartureShape.isRequired,
    operatingCarrier: CarrierShape,
    segmentIdRef: PT.oneOfType([
        PT.number.isRequired, PT.string.isRequired
    ]),
    baggageAllowance: BaggageShape,
    emissions: PT.number,
});

export const FlightOptionShape = PT.shape({
    duration: PT.shape({
        hours: PT.number,
        minutes: PT.number,
    }).isRequired,
    flightOptionKey: PT.string,
    segments: PT.arrayOf(SegmentShape).isRequired,
    airlines: PT.arrayOf(PT.string),
    operatingCarriers: PT.arrayOf(PT.shape({
        code: PT.string,
        name: PT.string,
    })),
    flightNumbers: PT.arrayOf(PT.string),
    unbookableRules: PT.arrayOf(PT.string),
});

export const OriginDestinationShape = PT.shape({
    flightOptions: PT.arrayOf(FlightOptionShape).isRequired,
});

export const FareComponentShape = PT.shape({
    airlineCode: PT.string.isRequired,
    fareBasisCode: PT.string.isRequired,
    ticketDesignatorCode: PT.string,
    contractCode: PT.string,
    segmentIds: PT.arrayOf(
        PT.oneOfType([PT.number, PT.string])
    ).isRequired,
});

const PenaltiesFeeShape = PT.shape({
    application: PT.string,
    guaranteed: PT.bool,
    total: PT.number,
});

export const PenaltiesShape = PT.shape({
    changeItineraryFeeApplies: PT.bool,
    changeItineraryFees: PT.arrayOf(PenaltiesFeeShape),
    ticketChangeable: PT.bool,
    currency: PT.string,
});

export const CancellationShape = PT.shape({
    amount: PT.shape({
        amount: PT.number,
        currency: PT.string,
        originalAmount: PT.number,
        originalCurrency: PT.string,
    }),
    description: PT.string,
    fee: PT.bool,
    refundable: PT.bool,
});

export const AdvanceChangeShape = PT.shape({
    allowed: PT.bool,
    amount: PT.shape({
        amount: PT.number,
        currency: PT.string,
        originalAmount: PT.number,
        originalCurrency: PT.string,
    }),
    description: PT.string,
    fee: PT.bool,
    percentage: PT.number,
});

export const BaggageAttrShape = PT.shape({
    maxPieces: PT.number,
    maxWeight: PT.number,
    weightUnit: PT.string,
    included: PT.bool,
    description: PT.string,
});

export const SeatFeeShape = PT.shape({
    amountMax: PT.number,
    amountMin: PT.number,
    currency: PT.string,
});

export const SeatsAttrShape = PT.shape({
    assessment: PT.oneOf(['benefit', 'fee']),
    description: PT.string,
    fee: SeatFeeShape,
});

export const TicketAttributesShape = PT.shape({
    cancellation: CancellationShape,
    advanceChange: AdvanceChangeShape,
    baggage: BaggageAttrShape,
});

export const FareGroupShape = PT.shape({
    currency: PT.string.isRequired,
    fareComponents: PT.arrayOf(FareComponentShape).isRequired,
    fareType: PT.string.isRequired,
    fareGroupKey: PT.string.isRequired,
    originDestinations: PT.arrayOf(OriginDestinationShape).isRequired,
    provider: PT.string.isRequired,
    total: PT.number.isRequired,
    baggageAllowance: BaggageShape,
    validatingCarrierCodes: PT.arrayOf(PT.string),
    penalties: PenaltiesShape,
    ticketAttributes: TicketAttributesShape,
    unbookableRules: PT.arrayOf(PT.string),
});
