import omit from 'lodash/omit';

export function parse(data) {
    const {'security_vetted_hotels': securityVettedHotels, ...rest} = data;
    return {
        ...rest,
        'security_vetted_hotels': securityVettedHotels.map((hotel, idx) => ({...hotel, orderIndex: idx + 1})),
    };
}

export function serialize(data) {
    const hotels = data['security_vetted_hotels'].map(hotel => omit(hotel, 'orderIndex'));

    return {
        'security_vetted_hotels': hotels,
    }
}

export function parseSecurityVettedHotels(hotels) {
    return Array.isArray(hotels) ? hotels.map((hotel, index)=> ({...hotel, orderIndex: index})) : [];
}

export function serializeHotelForm(data) {
    const {hotelChain, provider} = data;
    const {label, value} = hotelChain;

    return {
        'hotel_name': label,
        'provider_id': value,
        provider,
    };
}


export function parseHotelForm(data) {
    const {
        'provider_id': providerId,
        'hotel_name': hotelName,
        'chain_name': chainName,
        ...hotel
    } = data;

    return {
        ...hotel,
        hotelChain: {
            value: providerId,
            category: chainName ? 'chain' : 'hotel',
            label: chainName ? chainName : hotelName,
        },
    };
}
