import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import identity from 'lodash/identity';

function invert(list, choices) {
    choices = choices.map(([value,])=> value);
    list = list || [];
    return choices.filter((el)=> !list.includes(el)).sort();
}

export function simplifyLabeledValues(choices=[], processor=identity) {
    return choices.map(({label, value}) => processor([value, label]));
}


function invertKeys(data, constants) {
    return {
        ...data,
        'star_rating': invert(data['star_rating'], simplifyLabeledValues(constants['star_rating_choices'])),
        'property_types': invert(data['property_types'], simplifyLabeledValues(constants['property_type_choices'])),
    };
}

function chainDefined(chain) {
    if (!chain) {
        return false;
    }

    return isObject(chain.value) && chain.label;
}

function hotelDefined(chain) {
    if (!chain) {
        return false;
    }

    return chain.value && isString(chain.value) && chain.label;
}

function prepareChainsToSave(chains, primaryGds) {
    return chains
        .filter(chainDefined)
        .map(({value, label, gds})=> ({
            name: label,
            code: value[gds || primaryGds],
            gds: gds || primaryGds,
        }));
}

function prepareHotelsToSave(chains) {
    return chains
        .filter(hotelDefined)
        .map(({value, label})=> [value, label]);
}

export function parse(data, {treeName, section, constants={}}) {
    if (section === 'branch-hotel-exclusions') {
        const {'agency:branch_hotel_exclusions': exclusions} = data;
        let {hotels=[]} = exclusions;

        hotels = hotels.map(([id, name])=> ({value: id, label: name}));
        return {hotels: hotels && hotels.length ? hotels : [{}]};
    }
    if (treeName === 'agencies') {
        const {'agency:hotel_exclusions_new': exclusions} = data;
        let {hotels=[], ...rest} = exclusions;

        hotels = hotels.map(([id, name])=> ({value: id, label: name}));
        return {hotels: hotels && hotels.length ? hotels : [{}], ...rest};
    }

    const {'hotel_exclusions_new': exclusions, 'primary_gds': primaryGds} = data;
    let {chains=[], hotels=[], titles=[],
        'non_preferred_adult': nonPreferredAdult,
        'is_on_request_hotel': isOnRequestHotel,
        'destinations_non_company_preferred': destinationsNonCompanyPreferred,
        'non_traditional_accommodation': nonTraditionalAccommodation,
        ...rest} = exclusions;

    chains = chains.map(({name, code, gds})=> ({
        value: {[gds]: code},
        label: name,
        gds,
    }));
    hotels = hotels.map(([id, name])=> ({value: id, label: name}));

    const mergedHotelChains = [...chains, ...hotels];
    const excludeNonClPreferred = destinationsNonCompanyPreferred === null;

    return {
        'chains': mergedHotelChains.length
            ? mergedHotelChains
            : [{}],
        'destinations_non_company_preferred': destinationsNonCompanyPreferred,
        'primary_gds': primaryGds,
        'exclude_non_cl_preferred': excludeNonClPreferred,
        // This variables are reverted because UI labels says include, hyatt values says exclude
        'non_preferred_adult': !nonPreferredAdult,
        'is_on_request_hotel': !isOnRequestHotel,
        'non_traditional_accommodation': !nonTraditionalAccommodation,
        titles,
        ...invertKeys(rest, constants),
    };
}

export function serialize(data, {treeName, section, constants={}}) {
    if (section === 'branch-hotel-exclusions') {
        const {hotels} = data;
        return {
            'agency:branch_hotel_exclusions': {
                hotels: prepareHotelsToSave(hotels),
            }
        };
    }
    if (treeName === 'agencies') {
        const {hotels, ...rest} = data;
        return {'agency:hotel_exclusions_new': {
            hotels: prepareHotelsToSave(hotels),
            ...rest,
        }};
    }

    let {chains, 'primary_gds': primaryGds,
        'non_preferred_adult': nonPreferredAdult,
        'is_on_request_hotel': isOnRequestHotel,
        'exclude_non_cl_preferred': excludeNonClPreferred,
        'destinations_non_company_preferred': destinations=[],
        'non_traditional_accommodation': nonTraditionalAccommodation,
        ...params} = data;
    let hotels = prepareHotelsToSave(chains);
    chains = prepareChainsToSave(chains, primaryGds);
    const destinationsNonCompanyPreferred = excludeNonClPreferred ? null : destinations || [];
    return {
        'hotel_exclusions_new': {...invertKeys(params, constants),
            'non_preferred_adult': !nonPreferredAdult,
            'is_on_request_hotel': !isOnRequestHotel,
            'destinations_non_company_preferred': destinationsNonCompanyPreferred,
            'non_traditional_accommodation': !nonTraditionalAccommodation,
            chains, hotels}
    };
}
