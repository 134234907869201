/* global global, IS_PROD */
import session from 'airborne/session';
import isPlainObject from 'lodash/isPlainObject';

const AIRBORNE_DEFAULTS = {
    // Auth settings
    CHECK_AUTH_LOCK_TIMEOUT: 60000,  // milliseconds

    // Datetime formats for moment.js. Depend on the selected language
    // and is set in initLanguageSpecifics.
    DATE_FORMAT: 'YYYY-MM-DD', // default value for AIRAPI
    SHORT_DATE_FORMAT: '',
    WEEKDAY_DATE_FORMAT: '',
    TIME_FORMAT: '',
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD', // default value for booking records
    // Map settings
    GOOGLE_STATIC_MAP_URL: '//maps.googleapis.com/maps/api/staticmap',

    HOTEL_MAP_MARKER_ICON_URL: 'img/pin_hotel_map.png',

    HOTEL_MAP_URL_PARAMS: [
        'map_center',
        'map_filters',
        'map_mode',
        'map_zoom',
        'view_mode'
    ],

    // Hotels search settings
    HOTEL_DETAILS_URL_EXCLUDE: ['force'],
    HOTEL_RATES_URL_EXCLUDE: [
        'chains', 'distance', 'filters', 'force',
        'is_specific_hotel_search', 'limit', 'offset', 'order', 'page',
        'per_page', 'task_id'
    ],

    SESSION_CHECK_INTERVAL: 60000, // ms

    // Currency settings
    RATE_BAND_CURRENCY: null,
    DEFAULT_RATE_BAND_CURRENCY: 'EUR',  // It need if hotels not found and rate range currency not detected
};

const AIRAPI_DEFAULTS = {
    AJAX_ENDPOINT: global.location ? global.location.href : '',
    ALLOWED_DISTANCE_UNITS: ['KM', 'MI'],
    ALLOWED_CURRENCIES: [],
    ALLOWED_GDS: [],
    GDS_LIKE_PROVIDERS: [],
    ALLOWED_LOCALES: ['en_US'],
    API_SERVERS: [],
    API_VERSIONS: [],
    API_METHODS: [],
    DEFAULT_DISTANCE: 0,
    DEFAULT_DISTANCE_UNITS: 'KM',
    DEFAULT_LOCALE: 'en_US',
    DEFAULT_LIMIT: 100,
    LIMIT_MAX: 2500,
    DEFAULT_PAGE: 1,
    METHODS: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS', 'HEAD'],
};

const MIDOFFICE_DEFAULTS = {
    ENDPOINTS: {},
    LANGUAGE_CODE: 'en',
    SUB_PARTNER: 'common',
};

const DEFAULTS = {...AIRBORNE_DEFAULTS, ...AIRAPI_DEFAULTS, ...MIDOFFICE_DEFAULTS};

const settings = global.SETTINGS = global.SETTINGS || {};   //eslint-disable-line immutable/no-mutation


settings.defaults = function(obj) {     //eslint-disable-line immutable/no-mutation
    Object.keys(obj).forEach((key)=> {
        if (!settings.hasOwnProperty(key)) {
            settings[key] = obj[key];   //eslint-disable-line immutable/no-mutation
        }
    });
};

if (IS_PROD) {
    settings.set = function(obj) {      //eslint-disable-line immutable/no-mutation
        Object.assign(settings, obj);
    };
}
else {
    function getOverrides() {
        return session.get(`savedFlags:${settings.sid}`) || {};
    }

    function setOverrides(flags) {
        session.set(`savedFlags:${settings.sid}`, flags);
    }

    function filterFlags(input) {
        return Object.entries(input)
            .filter(([, value])=> typeof value === 'boolean')
            .reduce((acc, [key, value])=> ({...acc, [key]: value}), {});
    }

    settings.set = function(obj) { //eslint-disable-line immutable/no-mutation
        Object.assign(settings, obj);

        if (isPlainObject(obj)) {
            //Save all manualy changed flags to session storage.
            //If you need to prevent saving some flags, improve filterFlags with black list
            const flagsToOverride = filterFlags(obj);
            const overrides = getOverrides();
            setOverrides({...overrides, ...flagsToOverride});
        }
    };

    settings.set(getOverrides());
}


settings.defaults(DEFAULTS);

export default settings;
