import gettext from 'airborne/gettext';
import get from 'lodash/get';
import compact from 'lodash/compact';
import capitalize from 'lodash/capitalize';
import union from 'lodash/union';
import format from 'midoffice/helpers/format';
import moment from 'moment';


function simple(fn, fieldName=null) {
    return function (key, value, ctx) {
        const label = fn(key, value, ctx, fieldName=(fieldName || capitalize(key)));
        return label && {key, label};
    };
}

function labelTag(field, value) {
    if (!value) {
        return null;
    }

    return `${capitalize(field)}: ${value}`;
}
const simpleLabel = simple(labelTag);

function onlyValue(key, value) {
    return value || null;
}
export const simpleValue = simple(onlyValue);

function labelDate(field, value, ctx, fieldName) {
    if (!value) {
        return null;
    }

    if (value.min && value.max) {
        return `${fieldName}: ${format.date(value.min)} - ${format.date(value.max)}`;
    }

    if (value.min) {
        return gettext('{name}: after {min}',
            {name: fieldName, min: format.date(value.min)}
        );
    }

    if (value.max) {
        return gettext('{name}: before {max}',
            {name: fieldName, max: format.date(value.max)}
        );
    }

    if (moment(value, moment.ISO_8601).isValid()) {
        return `${fieldName}: ${format.date(value, 'long')}`;
    }

    return null;
}
export const simpleDate = simple(labelDate);
export function namedDate(fieldName) {
    return simple(labelDate, fieldName);
}

function labelDatetime(field, value, ctx, fieldName) {
    if (!value) {
        return null;
    }

    if (value.min && value.max) {
        return `${fieldName}: from ${format.date(value.min, 'long')} to ${format.date(value.max, 'long')}`;
    }

    if (value.min) {
        return gettext('{name}: after {min}',
            {name: fieldName, min: format.date(value.min, 'long')}
        );
    }

    if (value.max) {
        return gettext('{name}: before {max}',
            {name: fieldName, max: format.date(value.max, 'long')}
        );
    }

    if (moment(value, moment.ISO_8601).isValid()) {
        return `${fieldName}: ${format.date(value, 'long')}`;
    }

    return null;
}
export function namedDatetime(fieldName) {
    return simple(labelDatetime, fieldName);
}

function treeLabels(key, value, label, list) {
    if (!value) {
        return null;
    }

    return value.map((id, idx)=> ({
        key,
        idx,
        label: `${label}: ${get(list, [id, 'title'])}`,
    }));
}

export function companyName(key, value, {companies}) {
    return treeLabels(key, value, gettext('Company'), companies);
}

export function agencyName(key, value, {agencies}) {
    return treeLabels(key, value, gettext('Agency'), agencies);
}

export function singleLabeled(labelTitle, labelValue) {
    return (key) => ({
        key,
        label: `${labelTitle}: ${labelValue}`,
    });
}
export function onlyLabel(labelTitle) {
    return (key) => ({
        key,
        label: `${labelTitle}`,
    });
}

export function labels(field, values) {
    return (values || []).map(({label}, idx)=> ({
        key: field,
        idx,
        label: `${capitalize(field)}: ${label}`,
    }));
}

function identity(value) {
    return value;
}
export function named(fieldName, fn=identity) {
    return function (key, values) {
        return Array.isArray(values) && values
            .map((value, idx)=> ({
                key,
                idx,
                label: `${fieldName}: ${fn(value)}`,
            }));
    };
}

export function namedLabels(fieldName) {
    return named(fieldName, (part)=> part.label);
}

export function keyedLabels(fieldName, context) {
    return function (key, values) {
        return values
            .map((el)=> context[el])
            .filter(Boolean)
            .map((label, idx)=> ({
                key,
                idx,
                label: `${fieldName}: ${label}`,
            }));
    };
}

export function system(key, value, {systems}) {
    return keyedLabels('Channel', systems)(key, value);
}

export default function formatTags(filters, labels, ctx) {
    return compact(filters.reduce((acc, [key, value])=> {
        const fn = labels[key] || simpleLabel;
        const tag = fn(key, value, ctx);
        if (!tag || !value) {
            return acc;
        }
        return union(acc, Array.isArray(tag) ? tag : [tag]);
    }, []));
}
