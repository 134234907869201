export const CHANGE_ASSIGNEE_REQUEST = 'CHANGE_ASSIGNEE_REQUEST';
export const CHANGE_ASSIGNEE_REQUEST_SUCCESS = 'CHANGE_ASSIGNEE_REQUEST_SUCCESS';
export const CHANGE_ASSIGNEE_REQUEST_FAILURE = 'CHANGE_ASSIGNEE_REQUEST_FAILURE';

export const CHANGE_RESOLUTION_REQUEST = 'CHANGE_RESOLUTION_REQUEST';
export const CHANGE_RESOLUTION_REQUEST_SUCCESS = 'CHANGE_RESOLUTION_REQUEST_SUCCESS';
export const CHANGE_RESOLUTION_REQUEST_FAILURE = 'CHANGE_RESOLUTION_REQUEST_FAILURE';

export const UPDATE_RESOLUTION_FORM = 'UPDATE_RESOLUTION_FORM';
export const CLEANUP_RESOLUTION_FORM = 'CLEANUP_RESOLUTION_FORM';

export const CHANGE_ACTIONABLE_COUNTER = 'CHANGE_ACTIONABLE_COUNTER';
