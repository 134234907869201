
export function loadFake(company, pnr, index) {
    const fakepnr = {
        'pnr_name_first': 'John',
        'pnr_name_last': 'Robo',
        'profile_name': 'Mr. John Robo',
        'gds_profile': 175,
        'company': company,
        'pnr': pnr,
        'office_id': 'FAKE11',
        'mode': 'guest',
        'date_created': '2016-09-21T13:22:00',
        'email': 'robo.fake@getgoing.com',
        'company_name': 'GG Internal',
        'tspm_traveler_id': '24.100000112',
    };
    const fake = {
        'FAKEPNR': {
            ...fakepnr,
            company,
        },
        'FAKEPNR2': {
            ...fakepnr,
            warning: 'TSPM traveler profile database is currently unavailable.',
        },
        'FAKEPNR3': fakepnr,
        'FAKEPNR4': {
            ...fakepnr,
            warning: 'Unable to retrieve PNR because it contains more than one TSPM Company EntityID (TCP#). Please modify the PNR and try again.',
        },
        'FAKEPNR7': {
            ...fakepnr,
            mode: 'pnrmod',
        },
        'FAKEPNR8': {
            ...fakepnr,
            'tspm_traveler_id': null,
        },
    };

    return {type: 'LOADED_PNR', profile: fake[pnr] || fakepnr, index};
}

export function fakeOptions(pnr, index, params) {
    return (pnr === 'FAKEPNR9')
        ? {
            type: 'FAIL_DESTINATION_OPTIONS',
            errors: {
                misconf: true,
            },
            index
        }
        : {
            type: 'DESTINATION_OPTIONS',
            value: {
                'gds_oid': 'FAKESFO',
                'chains': {},
                'special_rates': [],
                'search_limit': 100,
                'filters': [],
                'hotel_labeling': {},
                'cars_labeling': {},
                'rate_labeling': {},
                'rate_caps': [],
                'ads': {},
                'is_pnr_enabled': true,
                'is_guest_traveler_enabled': true,
                'per_diems_enabled': true,
            },
            index,
            params,
        };
}
