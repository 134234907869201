/* global global */
import partial from 'lodash/partial';
import {changeFormValue} from 'airborne/store/modules/user_settings/actions/userSettings';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';
import {default as airborneStore} from 'airborne/store';
import {store as midofficeStore} from 'midoffice/store';
import {getLocale} from 'airborne/store/modules/login/helpers/login';
import {setLang} from 'airborne/store/modules/header/actions/i18n';
import history from 'airborne/browserHistory';
import systemData from 'airborne/systemData';

export const changeResetPassword = partial(changeFormValue, 'resetPassword');

export function sendResetPassword() {
    return function onSubmitD(dispatch, getState) {
        const resetPasswordData =  getState()['resetPassword'].value;
        const {token, uid, flow} = getState().auth;
        const locale = getLocale();
        const data = {
            ...resetPasswordData,
            ...locale,
            'password_token': token,
            'user': uid,
            flow,
        };
        dispatch({type: 'LOADING', endpoint: 'resetPassword'});
        return api('POST', '/internal/accounts/set_password/', {data: {...data, ...locale}})
            .then((response) => {
                // for fixing hack GG-25370
                global.location.href = '/accounts/login'; // eslint-disable-line immutable/no-mutation
                return response;
            }, (response)=> {
                const errors = response.body;
                dispatch({type: 'FAILED', errors: errors || '', endpoint:  'resetPassword'});
            });
    };
}


export function setResetPassData(uid, token, flow) {
    return {
        type: 'SET_RESET_PASS_DATA',
        uid,
        token,
        flow,
    };
}

export function authLoading() {
    return {type: 'LOADING', endpoint:  'resetPassword'};
}
export function authLoaded() {
    return {type: 'LOADED', endpoint:  'resetPassword'};
}

export function identifyUser(uid, token, flow) {

    const store = systemData.common.SITE_SUBDOMAIN === 'admin' ? midofficeStore : airborneStore;
    store.dispatch(setResetPassData(uid, token, flow));
    const locale = getLocale();
    const options = {
        'password_token': token,
        'user': uid,
        'flow': flow,
        ...locale,
    };

    return function sendIdentifyUSerD(dispatch) {

        return api('GET','/internal/accounts/set_password/', {data: options})
            .then((response) => {
                settings.set(response);
                return response;
            })
            .then((response) => {
                const lang = response.LANGUAGE_CODE;
                return dispatch(setLang(lang))
                    .then(() => {
                        store.dispatch(authLoaded());
                        return response;
                    });
            })
            .catch(() => {
                store.dispatch(authLoaded());
                history.push('/ui/reset-error/');
            });
    };
}
