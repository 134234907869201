import mapValues from 'lodash/mapValues';


export function trimSpacesFromNames(data) {
    return mapValues(data, (value, key)=> {
        if (key.endsWith('name') && value) {
            return value.trim();
        }
        return value;
    });
};
