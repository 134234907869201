import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

const FIELDS = {
    AIR_PNR_FORMATTING: 'air-pnr-formatting',
    PNR_TEMPLATES: 'pnr-templates',
    PNR_REMARKS: 'pnr-remarks',
    PNR_FORMATTING: 'pnr-formatting',
    PASSIVE_SEGMENTS:'passive-segments',
    AIR_PASSIVE_SEGMENTS:'air-passive-segments',
    CARS_PNR_TEMPLATES: 'cars-pnr-templates',
    CARS_PNR_REMARKS: 'cars-pnr-remarks',
    CARS_PNR_FORMATTING: 'cars-pnr-formatting',
    AIR_PNR_REMARKS :'air-pnr-remarks',
    GENERAL_PNR_FORMATTING: 'general-pnr-formatting',
    GENERAL_PNR_REMARKS: 'general-pnr-remarks',
};

export function parseError({col, line}={}) {
    col = col ? parseInt(col[0]) - 1 : null;
    line = line ? parseInt(line[0]) - 1 : null;

    return {col, line};
}

export const prefix = (section, gds)=> (
    {
        [FIELDS.AIR_PNR_FORMATTING]: `air:pnr_formatting:${gds}`,
        [FIELDS.PNR_TEMPLATES]: `agency:pnr_templates:${gds}`,
        [FIELDS.PNR_REMARKS]: `agency:pnr_remarks:${gds}`,
        [FIELDS.PNR_FORMATTING]: `pnr_formatting:${gds}`,
        [FIELDS.PASSIVE_SEGMENTS]: `agency:passive_segments:${gds}`,
        [FIELDS.AIR_PASSIVE_SEGMENTS]: `agency:air-passive_segments:${gds}`,
        [FIELDS.CARS_PNR_TEMPLATES]: `agency:cars:pnr_templates:${gds}`,
        [FIELDS.CARS_PNR_REMARKS]: `agency:cars:pnr_remarks:${gds}`,
        [FIELDS.CARS_PNR_FORMATTING]: `cars:pnr_formatting:${gds}`,
        [FIELDS.AIR_PNR_REMARKS]: `agency:air:pnr_remarks:${gds}`,
        [FIELDS.GENERAL_PNR_FORMATTING]: `general:pnr_formatting:${gds}`,
        [FIELDS.GENERAL_PNR_REMARKS]: `agency:general:pnr_remarks:${gds}`,
    }[section]
);

const addComments = (text, comments)=> (
    (text && comments)
        ? [comments.head, text, comments.tail].join('\n')
        : text
);

const REMARK_COMMENTS = {
    head: '# ****START OF NON-EDITABLE CUSTOM COUNTRY FORMATS***',
    tail: '# ****END OF NON-EDITABLE CUSTOM COUNTRY FORMATS***',
};

const getComments = (section)=> (
    {
        [FIELDS.AIR_PNR_FORMATTING]: REMARK_COMMENTS,
        [FIELDS.PNR_FORMATTING]: REMARK_COMMENTS,
        [FIELDS.CARS_PNR_FORMATTING]: REMARK_COMMENTS,
        [FIELDS.GENERAL_PNR_FORMATTING]: REMARK_COMMENTS,
    }[section]
);

const prefixReadonly = (section, gds)=> (
    {
        [FIELDS.AIR_PNR_FORMATTING]: `agency:air:pnr_remarks:${gds}`,
        [FIELDS.CARS_PNR_FORMATTING]: `agency:cars:pnr_remarks:${gds}`,
        [FIELDS.PNR_FORMATTING]: `agency:pnr_remarks:${gds}`,
        [FIELDS.GENERAL_PNR_FORMATTING]: `agency:general:pnr_remarks:${gds}`,
    }[section]
);

export function getValue(settings, section, gds) {
    return settings[prefix(section, gds)] || '';
}

export function getReadonlyPrefix(settings, section, gds) {
    const key = prefixReadonly(section, gds);
    return addComments(key && settings[key] || '', getComments(section));
}

const ROOT_AGENCY_COUNTRY_CODE = 'BCD';
const BCD = 'BCD';
const ROOT = 'root';

export function parse(data, {section}) {
    let {'country_code': countryCode} = data;
    countryCode = countryCode || ROOT_AGENCY_COUNTRY_CODE;
    return mapValues(data, (param, key)=> (
        key.startsWith(prefix(section, ''))
            ? mapKeys(param, (value, key)=> (
                [countryCode, BCD].includes(key) ? ROOT : key))
            : param
    ));
}

export function serialize(data, {section}) {
    let {'country_code': countryCode} = data;
    countryCode = countryCode || ROOT_AGENCY_COUNTRY_CODE;
    return mapValues(data, (param, key)=> (
        key.startsWith(prefix(section, ''))
            ? mapKeys(omitBy(param, isNull), (value, key)=> (
                key === ROOT ? countryCode : key))
            : param
    ));
}

export function parseAir(data) {
    let {'country_code': countryCode, 'agency:custom_country_codes': countryCodes, 'agency:passive_segments:amadeus': aux, ...restData} = data;
    countryCode = countryCode || ROOT_AGENCY_COUNTRY_CODE;
    const checkboxes =  mapValues(restData, (param)=> {
        return mapKeys(param, (value, key) => (
            [countryCode, BCD].includes(key) ? ROOT : key));
    });
    return {
        'agency:custom_country_codes': countryCodes,
        'agency:passive_segments:amadeus': aux,
        'country_code': countryCode,
        ...checkboxes
    };
}

export function serializeAir(data) {
    let {'country_code': countryCode, 'agency:custom_country_codes': countryCodes, 'agency:passive_segments:amadeus': aux, ...restData} = data;
    countryCode = countryCode || ROOT_AGENCY_COUNTRY_CODE;
    const checkboxes = mapValues(restData, (param)=> {
        return [...countryCodes, {code: countryCode}].reduce((acc, {code}) => {
            const segmentCode = code === countryCode ? 'root' : code;
            return {...acc, [code]: param[segmentCode] || false};
        }, {});
    });
    return {
        'agency:custom_country_codes': countryCodes,
        'agency:passive_segments:amadeus': aux,
        'country_code': countryCode,
        ...checkboxes
    };
}

export function parseAirGdsTicketing(data) {
    const {
        'country_code': countryCode = ROOT_AGENCY_COUNTRY_CODE,
        'agency:air:gds_ticketing_config': ticketingConfig,
        ...rest
    } = data;

    const parsedConfig = Object.entries(ticketingConfig).reduce((acc, [key, value]) => {
        const isRootCode = key === countryCode;
        const fieldName = isRootCode ? ROOT : key;
        return {
            ...acc,
            [fieldName]: value
        };
    }, {});

    return {
        ...rest,
        'agency:air:gds_ticketing_config': parsedConfig,
        'country_code': countryCode,
    };
}

export function serializeAirGdsTicketing(data) {
    const {
        'country_code': countryCode = ROOT_AGENCY_COUNTRY_CODE,
        'agency:air:gds_ticketing_config': ticketingConfig,
        ...rest
    } = data;

    const serializedConfig = Object.entries(ticketingConfig).reduce((acc, [key, value]) => {
        const isRootCode = key === ROOT;
        const fieldName = isRootCode ? countryCode : key;
        return {
            ...acc,
            [fieldName]: value
        };
    }, {});

    return {
        ...rest,
        'agency:air:gds_ticketing_config': serializedConfig,
        'country_code': countryCode,
    };
}

export function parsePassiveSegments(data, query) {
    const {'agency:aux_segments:amadeus': aux, ...rest} = data;
    return parse(
        {...rest, 'agency:passive_segments:amadeus': aux},
        query
    );
}

export function serializePassiveSegments(data, query) {
    const {'agency:passive_segments:amadeus': aux, ...rest} = serialize(data, query);
    return {...rest, 'agency:aux_segments:amadeus': aux};
}
