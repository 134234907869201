import {mapValues, keyBy} from 'lodash';

export function addPrefix(object, prefix) {
    return Object.entries(object).reduce((acc, [key, value]) => ({...acc, [prefix + key]: value}), {});
}

export function removePrefix(object, prefix) {
    return Object.entries(object).reduce((acc, [key, value]) => {
        return key.startsWith(prefix) ? {...acc, [key.slice(prefix.length)]: value} : {...acc};
    }, {});
}

export function getRateCodes(obj) {
    const rateCodes = obj['rate_optimization:metric_weights:rate_codes'].filter((rateCode) => rateCode.name);
    return mapValues(keyBy(rateCodes, 'name'), (rateCode) => rateCode.value || 0);
}

export function parse(obj) {
    const {'agency:rate_optimization:metric_weights': metrics} = obj;

    const rateCodes = Object.entries(metrics['rate_codes'])
        .map(([name, value])=> ({name, value}));

    const {'commission_bias': commissionBias} = metrics['commission'] || {};

    const newFields = {
        ...addPrefix(metrics.amenities, 'rate_optimization:metric_weights:amenities:'),
        ...addPrefix(metrics.suppliers, 'rate_optimization:metric_weights:suppliers:'),
        'rate_optimization:metric_weights:rate_codes': !rateCodes.length ? [{value:0}] : rateCodes,
        'rate_optimization:metric_weights:deposit_rates': metrics['deposit_rates'],
        'rate_optimization:enabled': obj['agency:rate_optimization:enabled'],
        'rate_optimization:enabled_search': obj['agency:rate_optimization:enabled_search'],
        'agency:rate_optimization:metric_weights:commission:commission_bias': commissionBias,
    };
    return {...newFields};
}


export function serialize(obj) {
    const rateCodes = getRateCodes(obj);
    return {
        'agency:rate_optimization:enabled': obj['rate_optimization:enabled'],
        'agency:rate_optimization:enabled_search': obj['rate_optimization:enabled_search'],
        'agency:rate_optimization:metric_weights': {
            'amenities': removePrefix(obj, 'rate_optimization:metric_weights:amenities:'),
            'rate_codes': rateCodes,
            'deposit_rates': obj['rate_optimization:metric_weights:deposit_rates'],
            'suppliers': removePrefix(obj, 'rate_optimization:metric_weights:suppliers:'),
            'commission': {
                'commission_bias': obj['agency:rate_optimization:metric_weights:commission:commission_bias'],
            },
        },
    };
}
