import {combineReducers} from 'redux';
import form from './form';
import params from './params';
import manualExchangeForm from './manualExchangeForm';

const airExchange = combineReducers({
    form,
    manualExchangeForm,
    params,
});

export default airExchange;
