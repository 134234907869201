/* eslint-disable immutable/no-mutation */
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import api from './api';


export default function ajaxCompat(options) {
    if (options.defaultError !== false) {
        const originalError = options.error || noop;
        options.error = function () {
            originalError.apply(this, arguments);
        };
    }

    /*
    Below Backbone-provided ajax options are converted
    to be accepted by xhr wrapper.
    Particularly, Backbone provides it's own wrappers
    over success and error methods
    */
    const method = options.type || options.method || 'GET';
    const url = options.url;

    let opts = pick(options, [
        'headers',
        'data',
        'timeout',
        'credentials',
        'onUploadProgress',
        'onLoadProgress'
    ]);
    opts.responseType = options.dataType;
    //todo remove with GG-8048 (all success/failure handling is done via promise)
    opts.onLoad = options.success || noop;
    opts.onError = opts.onAbort = opts.onTimeout = options.error || noop;
    opts.onDone = options.complete || noop;

    return api(method, url, opts);
}
