import React from 'react';
import Login from './Login';
import {connect} from 'react-redux';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Terms from './Terms';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import ResetPasswordError from './ResetPasswordError';
import PropTypes from 'prop-types';
import LocaleDropdown from 'airborne/header2/LocaleDropdown';
import {getAuthLoader} from 'airborne/login/authSelectors';
import Footer from 'airborne/header2/Footer';
import settings from 'airborne/settings';
import Loader from './Loader';
import UATWarning from 'midoffice/components/UATWarning';
import ProviderTestEnvsWarning from 'midoffice/components/ProviderTestEnvsWarning';
import TermsConditions from 'airborne/termsConditions/TermsConditions';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {Switch} from 'react-router-dom';
import {Redirect, Route} from 'react-router';
import {authLoading} from 'airborne/store/modules/login/actions/resetPassword';
import FeatureFlagModal from 'airborne/store/modules/featureFlags/Modal';
import history from 'airborne/browserHistory';
import {shouldShowTerms} from 'airborne/store/modules/login/actions/login';
import systemData from 'airborne/systemData';

const FEATURE_FLAG_URL = '/internal/accounts/flags';

function mapStateProps(state) {
    return {
        loading: getAuthLoader(state),
    };
}

@connect(mapStateProps, {authLoading})
class Auth extends React.Component {
    static propTypes = {
        step: PropTypes.string,
        loading: PropTypes.bool,
        shouldShowTerms: PropTypes.bool,
    };

    getContent() {
        const {loading} = this.props;

        const pathname = history.location.pathname;
        const isLoggedIn = Boolean(settings.USER.id);

        const needRedirectToLogin = (!isLoggedIn && pathname.includes('ui/new-terms')) ||
            (!shouldShowTerms() && pathname.includes('ui/new-terms')) ;

        if (loading) {
            return <Loader/>;
        }

        return (<Switch>
            <Route path="/ui/forgot-password/"><ForgotPassword history={history}/></Route>
            <Route path="/accounts/reset-password/"><ResetPassword history={history}/></Route>
            <Route path="/accounts/activate/"><ResetPassword history={history}/></Route>
            <Route path="/ui/reset-success/"><ForgotPasswordSuccess history={history} /></Route>
            <Route path="/ui/reset-error/"><ResetPasswordError/></Route>

            {needRedirectToLogin && <>
                <Route path="/accounts/login/"><Login /></Route>
                <Redirect to="/accounts/login/" /></>}
            {(shouldShowTerms() && isLoggedIn) && <>
                <Route path="/ui/new-terms/"><Terms/></Route>
                <Redirect to="/ui/new-terms/" /></>}
            <Route path="/accounts/login/"><Login /></Route>
            <Route path="/ui/new-terms/"><Terms/></Route>
            <Route><Redirect to="/accounts/login/" /></Route>

        </Switch>);
    };

    renderContent() {
        return (<div>
            <div className="floated-form">
                <header
                    className="floated-form__header">
                    <a href="/" className="logo pull-left"/>
                    {systemData.common.SITE_SUBDOMAIN !== 'admin' && <ul className="pull-right nav navbar-nav">
                        <LocaleDropdown />
                    </ul>}
                </header>
                <div className="floated-form__body">
                    {this.getContent()}
                </div>
                <Footer/>
            </div>
        </div>);
    }

    renderTerms() {
        return (<div>
            <Navbar bg="light" className="navbar-default">
                <Container>
                    <div className="navbar-header">
                        <Navbar.Brand href="/" className="logo" />
                    </div>
                </Container>
            </Navbar>
            <TermsConditions/>
        </div>);
    }

    render() {

        return (<div>
            <div className="floated-form__header-message">
                <UATWarning/>
                <ProviderTestEnvsWarning />
            </div>
            <div className="ag-homepage-bg" />
            <Switch>
                <Route path="/ui/terms-conditions/">{this.renderTerms()}</Route>
                <Route path="/">{this.renderContent()}</Route>
            </Switch>
            <FeatureFlagModal url={FEATURE_FLAG_URL} shortcut={['ctrl+alt+q', 'ctrl+alt+a']}/>
        </div>
        );

    }
}

export default Auth;
