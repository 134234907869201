import pick from 'lodash/pick';
import {STEPS, compareSteps} from 'airborne/checkout2/helpers/steps';

function getNextStep(currentStep, skipDetails=false) {
    const idx = STEPS.indexOf(currentStep);
    const step = STEPS[idx + 1] || 'book';
    return (step === 'details' && skipDetails) ? getNextStep(step) : step;
}

function stepAfter(state, skipDetails, skipRecommendation) {
    const {step, recommend, value} = state;
    const rkeyIdx = (value['loyalty_card'] && value['loyalty_card'] !== 'first') ? 'lcard' : 'nolcard';
    if (recommend.loading[rkeyIdx] && !skipRecommendation) {
        return {...state, recommend: {...recommend, waiting: true}};
    }
    if (step === 'general' && recommend[rkeyIdx] && !recommend.result && !skipRecommendation) {
        return {...state, recommend: {...recommend, waiting: false, show: rkeyIdx}};
    }
    return {...state, step: getNextStep(step, skipDetails), recommend: {...recommend, waiting: false}};

}

function getPrevStep(currentStep, skipDetails) {
    const idx = STEPS.indexOf(currentStep);
    const step = STEPS[idx - 1] || 'general';
    return step === 'details' && skipDetails ? getPrevStep(step) : step;
}

function stepBefore({step, ...state}, skipDetails) {
    return {...state, step: getPrevStep(step, skipDetails)};
}

const TSPM_FALLBACK_FIELDS = [
    'salutation',
    'isEmptySalutationByDefault',
    'first_name',
    'last_name',
];

const KEEP_BETWEEN_CHECKOUTS_FIELDS = [
    ...TSPM_FALLBACK_FIELDS,
    'email',
];

function prefill(state, data) {
    const hasName = TSPM_FALLBACK_FIELDS.reduce((acc, key)=> (acc || state[key]), false);
    return {
        ...state,
        email: data.email || state.email || null,
        ...pick(data, TSPM_FALLBACK_FIELDS.filter((field) => (
            data.isPnr ? (data[field] && !hasName) : data[field]
        ))),
    };
}

function prefillPNR(state, data) {
    return {
        ...state,
        'email': data['email'],
        'salutation': data['pnr_name_title'],
        'first_name': data['pnr_name_first'],
        'last_name': data['pnr_name_last'],
    };
}

function initialValue() {
    return {
        'paid_by': 'card',
        'card_index': 'first',
        'loyalty_card': 'first',
        'special_requests': null,
        'special_requests_auto': true,
    };
}


function changeForm(state, value, errors) {
    const clearLoyalty = (value['loyalty_program'] !== state.value['loyalty_program']);
    return {
        ...state,
        value: clearLoyalty
            ? {...value, 'loyalty_card': 'first'}
            : value,
        errors
    };
}

function indexLCards({'loyalty_cards': lcards, ...rest}) {
    return {
        ...rest,
        'loyalty_cards': lcards && lcards.map((card)=> ({
            ...card,
            'card_index': [card['card_number'], card['chain_code']].join('\u001E'),
        }))
    };
}

function parseSettings(settings) {
    return indexLCards(pick(settings, ['crd', 'credit_cards', 'default_billing_address', 'loyalty_cards', 'booking_support_email']));
}

function recommendLoaded(state, lcard) {
    return {
        ...state,
        loading: {
            ...state.loading,
            [lcard ? 'lcard' : 'nolcard']: false,
        },
    };
}

function waitNext(state) {
    return state.recommend.waiting
        ? stepAfter(state)
        : state;
}

function saveRecommend(state, rateKey, lcard, LCardChosen) {
    return waitNext({
        ...state,
        value: {
            ...state.value,
            ...(LCardChosen ? {'loyalty_card': LCardChosen} : {})
        },
        recommend: {
            ...recommendLoaded(state.recommend, lcard),
            [lcard ? 'lcard' : 'nolcard']: rateKey,
        },
    });
}

function recommendedKey({recommend}) {
    return recommend[recommend.show];
}

function recommendReject(state) {
    return {...state, recommend: {...state.recommend, result: 'reject', excuse: null, rejectedRateKey: recommendedKey(state)}};
}

function initialRecommend() {
    return {lcard: null, nolcard: null, show: null, result: null, originalRateKey: null, rejectedRateKey: null, excuse: null, loading: {lcard: false, nolcard: false}, waiting: false};
}

function resetValue({value}) {
    return {
        ...initialValue(),
        ...pick(value, KEEP_BETWEEN_CHECKOUTS_FIELDS),
    };
}

function resetErrors({errors}) {
    return pick(errors, KEEP_BETWEEN_CHECKOUTS_FIELDS);
}

function getRateForm(state, rateKey) {
    return {
        value: rateKey !== state.rateKey ? resetValue(state) : state.value,
        errors: rateKey !== state.rateKey ? resetErrors(state) : state.errors,
    };
}

export const BOOKING_ACTIONS = [
    'BOOKING_LOADING2',
    'BOOKING_FAIL2',
    'BOOKING_CANCEL',
    'TO_CONFIRM',
    'SELECT_RATE_BOOK',
    'SELECT_REMAINING_RATE_BOOK',
    'TO_CHECKOUT',
    'CHECKOUT_SWITCH',
    'TO_MULTI_CHECKOUT',
    'BOOKING_FAIL',
    'CLEAR_RATE_BOOK',
    'START_SEARCH',
    'TO_PREV_CHECKOUT_STEP',
    'TO_NEXT_CHECKOUT_STEP',
    'TO_CHECKOUT_STEP',
    'CHANGE_BOOKING_FIELDS',
    'COMPANY_SETTINGS_LOADED',
    'COMPANY_SETTINGS_FAILED',
    'COMPANY_PROFILE_CHANGED',
    'TRAVELER_PROFILE_CHANGED',
    'SET_SABRE_SSO',
    'CHANGE_DEST',
    'LOADED_PNR',
    'DISCARD_PNR',
    'RATE_RECOMMENDATION_LOADING',
    'RATE_RECOMMENDATION_LOADED',
    'RATE_RECOMMENDATION_FAILED',
    'RECOMMENDED_ACCEPT',
    'RECOMMENDED_REJECT',
    'RECOMMENDED_AGAIN',
    'RECOMMENDED_EXCUSE',
    'FAKE_RECOMMEND',
    'SET_CURRENCY',
    'SET_LANG',
];

export default function booking(state, {type, hotelId, rateKey, value, profile, errors, settings, uuid, lcard, message, recoverable, step, skipDetails, skipRecommendation, LCardChosen}) {
    if (!state) {
        return {
            loading: false,
            hotelId: null,
            rateKey: null,
            step: 'general',
            value: initialValue(),
            errors: null,
            settings: null,
            uuid: null,
            retry: 0,
            message: null,
            recommend: initialRecommend(),
            checkoutData: {},
            noTspmAccess: false,
        };
    }

    if (type === 'BOOKING_LOADING2') {
        return {...state, loading: true, message: null};
    }

    if (type === 'BOOKING_FAIL2') {
        return {...state, loading: false, message, recoverable, retry: state.retry + 1};
    }

    if (type === 'TO_CONFIRM') {
        return {...state, loading: false};
    }

    if (type === 'SET_CURRENCY' || type === 'SET_LANG') {
        return {...state, hotelId: null, rateKey: null};
    }

    if (type === 'SELECT_RATE_BOOK') {
        return {...state, hotelId, rateKey, recommend: initialRecommend()};
    }

    if (type === 'SELECT_REMAINING_RATE_BOOK') {
        return (state.rateKey || state.hotelId)
            ? state
            : {...state, hotelId, rateKey, recommend: initialRecommend()};
    }

    if (type === 'TO_CHECKOUT' || type === 'CHECKOUT_SWITCH') {
        return {
            ...state,
            loading: false,
            hotelId,
            rateKey,
            uuid,
            retry: 0,
            message: null,
            recoverable: null,
            step: 'general',
            recommend: initialRecommend(),
            ...getRateForm(state, rateKey),
        };
    }
    if (type === 'TO_MULTI_CHECKOUT') {
        return {
            ...state,
            loading: false,
            uuid,
            retry: 0,
            message: null,
            recoverable: null,
            step: 'general',
        };
    }

    if (type === 'CLEAR_RATE_BOOK') {
        return {
            ...state,
            loading: false,
            hotelId: null,
            rateKey: null,
            ...getRateForm(state, rateKey),
        };
    }

    if (type === 'BOOKING_FAIL' || type === 'START_SEARCH') {
        return {...state, loading: false, hotelId: null, rateKey: null};
    }
    if (type === 'BOOKING_CANCEL') {
        return {...state, loading: false};
    }

    if (type === 'TO_PREV_CHECKOUT_STEP') {
        return stepBefore(state, skipDetails);
    }

    if (type === 'TO_NEXT_CHECKOUT_STEP') {
        return stepAfter(state, skipDetails, skipRecommendation);
    }

    if (type === 'TO_CHECKOUT_STEP') {
        if (compareSteps(state.step, step)) {
            return {...state, step};
        }
        return state;
    }

    if (type === 'CHANGE_BOOKING_FIELDS') {
        return changeForm(state, value, errors);
    }

    if (type === 'COMPANY_SETTINGS_LOADED') {
        return {...state, settings: parseSettings(settings), checkoutData: settings, value: prefill(state.value, settings), noTspmAccess: false};
    }

    if (type === 'COMPANY_SETTINGS_FAILED') {
        return {...state, noTspmAccess: true};
    }
    if (type === 'COMPANY_PROFILE_CHANGED' || type === 'TRAVELER_PROFILE_CHANGED' || type === 'SET_SABRE_SSO' || type === 'CHANGE_DEST') {
        return {...state, settings: null};
    }
    if (type === 'LOADED_PNR') {
        return {...state, settings: null, value: prefillPNR(state.value, profile)};
    }

    if (type === 'DISCARD_PNR') {
        return {...state, value: initialValue()};
    }

    if (type === 'RATE_RECOMMENDATION_LOADING') {
        return {...state, recommend: {...state.recommend, loading: {lcard: true, nolcard: true}}};
    }

    if (type === 'RATE_RECOMMENDATION_LOADED') {
        return saveRecommend(state, rateKey, lcard, LCardChosen);
    }

    if (type === 'RATE_RECOMMENDATION_FAILED') {
        return saveRecommend(state, null, lcard);
    }

    if (type === 'RECOMMENDED_ACCEPT') {
        return stepAfter({...state, rateKey: recommendedKey(state), recommend: {...state.recommend, show: null, result: 'accept', originalRateKey: state.rateKey}});
    }

    if (type === 'RECOMMENDED_REJECT') {
        return recommendReject(state);
    }

    if (type === 'RECOMMENDED_AGAIN') {
        return {...state, recommend: {...state.recommend, result: null, rejectedRateKey: null}};
    }

    if (type === 'RECOMMENDED_EXCUSE') {
        return stepAfter({...state, recommend: {...state.recommend, excuse: value, show: null}});
    }

    if (type === 'FAKE_RECOMMEND') {
        return {...state, recommend: {show: 'nolcard', nolcard: state.rateKey}};
    }

    return state;
}
