import compact from 'lodash/compact';
import {apiError as hotelsApiError} from 'airborne/search2/helpers/apiError';


const PROVIDER_PROCESSING_ERROR_CODE = 5006000;

function isProviderProcessingError(error) {
    return error.error_code === PROVIDER_PROCESSING_ERROR_CODE;
}

export function getErrorMessage(error) {
    return isProviderProcessingError(error) ? error.developer_message : error.message;
}

export function apiError(response) {
    try {
        const errors = response.body.errors;
        const messages = compact(errors.map(getErrorMessage));
        return messages.length === 0 ? null : messages;
    }
    catch (e) {
        return hotelsApiError(response);
    }
}
