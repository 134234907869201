'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Glyphicons from 'midoffice/components/Glyphicons';
import gettext from 'airborne/gettext';

export const MAX_NUMBER = 10000;
function limit(num, accurate = false, useAccuracyCheck = false) {
    if (num <= MAX_NUMBER && !useAccuracyCheck || accurate || num === 0) {
        return num;
    }

    if (useAccuracyCheck && !accurate) {
        return num + '+';
    }

    return MAX_NUMBER + '+';
}

function limitPage(page, size, total, useAccuracyCheck = false) {
    const maxNumber = useAccuracyCheck ? total : MAX_NUMBER;

    if ((page * size) >= maxNumber) {
        return Math.ceil(maxNumber / size);
    }

    return page;
}

class Pager extends React.Component {
    static propTypes = {
        'page': PropTypes.number.isRequired,
        'page_size': PropTypes.number.isRequired,
        'total': PropTypes.number.isRequired,
        'total_pages': PropTypes.number.isRequired,
        'start_index': PropTypes.number.isRequired,
        'end_index': PropTypes.number.isRequired,
        'onChange': PropTypes.func.isRequired,
        useAccuracyCheck: PropTypes.bool,
        pageLimit: PropTypes.number,
        noPagination: PropTypes.bool,
    };

    static defaultProps = {
        pageLimit: 500,
        noPagination: false,
    };

    state = {
        page: 1,
        pageProp: null,
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.pageProp || state.pageProp !== props.page) {
            return {
                pageProp: props.page,
                page: props.page,
            };
        }
        return null;
    }

    paginateRel(cmd) {
        let {page} = this.state;
        let {
            'total_pages': totalPages,
            'page_size': pageSize,
            total,
            useAccuracyCheck,
            'total_count_accurate': totalCountAccurate,
        } = this.props;

        totalPages = limitPage(totalPages, pageSize, total, useAccuracyCheck);

        if (cmd === 'first') {
            page = 1;
        }
        else if (cmd === 'last') {
            page = totalPages;
        }
        else if (cmd === 'next') {
            page++;
        }
        else if (cmd === 'prev') {
            page--;
        }

        const isApproximateNumberOfPages = cmd === 'next' && useAccuracyCheck && !totalCountAccurate;

        page = Math.max(page, 1);

        page = isApproximateNumberOfPages
            ? page
            : Math.min(page, totalPages);

        this.props.onChange({'page': page, 'page_size': pageSize});
    }

    handlePageSize = (key)=> {
        this.props.onChange({'page_size': Number(key), 'page': 1});
    };

    handleInputChange = (evt)=> {
        const page = Number(evt.target.value);
        if (!Object.is(page, NaN)) {
            this.setState({page: page});
        }
    };

    handleKeyPress = (evt)=> {
        let {page} = this.state;
        let {'total_pages': totalPages, 'page_size': pageSize, total, useAccuracyCheck} = this.props;

        totalPages = limitPage(totalPages, pageSize, total, useAccuracyCheck);

        if (evt.key === 'Enter') {
            this.props.onChange({
                'page': Math.min(page, totalPages),
                'page_size': pageSize,
            });
        }
    };

    handlePaginateFirst = ()=> this.paginateRel('first');
    handlePaginateLast = ()=> this.paginateRel('last');
    handlePaginatePrev = ()=> this.paginateRel('prev');
    handlePaginateNext = ()=> this.paginateRel('next');

    renderInput() {
        let {
            'total_pages': totalPages,
            'page_size': pageSize,
            total,
            useAccuracyCheck,
        } = this.props;

        totalPages = limitPage(totalPages, pageSize, total, useAccuracyCheck);

        return (<div className="pagination__page-selector">
            {gettext('Go to page:')}
            &nbsp;
            <input
                type="text"
                className="form-control pagination__page-input"
                value={this.state.page || ''}
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress} />
            &nbsp;
            {gettext('of {n}', {n: totalPages})}
        </div>);
    }

    renderButtons() {
        return (<div className="pagination__control">
            <Glyphicons bsClass="glyphicon" glyph="fast-backward" onClick={this.handlePaginateFirst} />
            <Glyphicons bsClass="glyphicon" glyph="backward" onClick={this.handlePaginatePrev} />
            <Glyphicons bsClass="glyphicon" glyph="forward" onClick={this.handlePaginateNext} />
            <Glyphicons bsClass="glyphicon" glyph="fast-forward" onClick={this.handlePaginateLast} />
        </div>);
    }

    render() {
        const {
            'start_index': startIndex,
            'end_index': endIndex,
            'page_size': pageSize,
            'total_count_accurate': totalCountAccurate,
            total,
            pageLimit,
            noPagination,
            useAccuracyCheck,
        } = this.props;

        if (noPagination) {
            return null;
        }

        const full = total > pageSize || useAccuracyCheck && totalCountAccurate === false;
        const pageSizes = [5, 20, 50, 100, 250, 500].filter((pageSize) => pageSize <= pageLimit);

        return (<div className="table-common-footer--pagination">
            {full && this.renderInput()}
            <span>{gettext('Show rows:')}</span>
            <DropdownButton title={pageSize} onSelect={this.handlePageSize} align="end" className="dropdown__unstyled dropdown" id="pager" variant="light">
                {pageSizes.map((pageSize) => (
                    <Dropdown.Item eventKey={String(pageSize)} key={pageSize}>{pageSize}</Dropdown.Item>
                ))}
            </DropdownButton>
            <span className="pagination--pages">
                {gettext(
                    'Records {startIndex} – {endIndex} of {total}', {startIndex, endIndex, total: limit(total, totalCountAccurate, useAccuracyCheck)})}
            </span>
            {full && this.renderButtons()}
        </div>);
    }
}

export default Pager;
