import {errorMessage} from '../helpers';

'process i18n';
import gettext from 'airborne/gettext';
import {Field} from '../fields-stateless';
import settings from 'airborne/settings';
import {passwordValidation, PASSWORD_MAX_LENGTH} from 'airborne/user_settings2/helpers/passwordValidation';

export const SPECIAL_SYMBOLS_REGEX = /[\s$&+,:;=?@#|'<>."*\\\()\/\%!^_`\[\]\{}~-]/;

export const PasswordField = {
    ...Field,
    isRequired: true,
    errors: '',
    maxLength: PASSWORD_MAX_LENGTH,
    minLength: settings.PASSWORD_MIN_LENGTH,
    useComplexity: settings.PASSWORD_USE_COMPLEXITY,
    minCharsNumberComplexity: settings.PASSWORD_COMPLEXITY_MIN_CHARS_NUMBER || 5,
    maxLengthMessage: ()=> { const maxLength = PASSWORD_MAX_LENGTH; return gettext('Ensure password has no more than {maxLength} characters', {maxLength});},
    minLengthMessage: ()=> { const minLength = settings.PASSWORD_MIN_LENGTH; return gettext('Ensure this field has at least {minLength} characters', {minLength});},
    toInternalValue(value) {
        return value;
    },

    toRepresentation(value, params) {
        return {
            value,
            errors: this.passwordValidate(value, params),
        };
    },
    checkLowerCaseLetters(value) {
        return value !== value.toUpperCase();
    },
    checkUpperCaseLetters(value) {
        return value !== value.toLowerCase();
    },
    checkNumbers(value) {
        return /[0-9]/.test(value);
    },
    checkSpecSymbols(value) {
        return SPECIAL_SYMBOLS_REGEX.test(value);
    },
    checkUserInfo(value) {
        const errors = passwordValidation.validate(value);
        return !Object.values(errors).some(Boolean);
    },
    passwordValidate(value) {
        const errorMessageText = gettext('Please make sure password meets all the requirements highlighted below');
        const minLength = settings.PASSWORD_MIN_LENGTH;
        const maxLength = PASSWORD_MAX_LENGTH;
        const useComplexity = settings.PASSWORD_USE_COMPLEXITY;
        const minCharsNumberComplexity = settings.PASSWORD_COMPLEXITY_MIN_CHARS_NUMBER || 5;
        let threshold = useComplexity ? minCharsNumberComplexity : 0;

        if (this.isRequired && !value) {
            return errorMessage(errorMessageText, this);
        }
        if (maxLength && value && value.length > maxLength) {
            return errorMessage(this.maxLengthMessage, this);
        }
        if (minLength && value && value.length < minLength) {
            return errorMessage(errorMessageText, this);
        }

        const complexityLevel = [this.checkLowerCaseLetters, this.checkUpperCaseLetters, this.checkNumbers, this.checkSpecSymbols, this.checkUserInfo]
            .filter((checkFn)=> checkFn(value))
            .length;
        return (complexityLevel >= threshold) ? null : errorMessageText;
    },

    validate(value) {
        return this.passwordValidate(value);
    }
};


export const RepeatPassword = {
    isRequired: true,
    errors: '',
    toInternalValue(value) {
        return value;
    },

    toRepresentation(value, params) {
        return {
            value,
            errors: this.passwordValidate(value, params),
        };
    },
    passwordValidate(value, repeatedValue) {
        let errors = null;
        if (!value) {
            return gettext('This field is required');
        }
        if (repeatedValue && value !== repeatedValue) {
            return {'password2': gettext('Passwords should be the same')};
        }
        if (repeatedValue !== '' && value === repeatedValue) {
            return {'password2': null};
        }
        return errors;
    },

    validate(value, repeatedValue) {
        return this.passwordValidate(value, repeatedValue);
    }
};
