function initialState() {
    return {
        stepLogin: null,
        source: null,
        timeout: null,
        midoffice: false,
        isShowTermsModal: false,
        uid: null,
        token: null,
        flow: null,
    };
}

export default function auth (state, {type, source, timeout, uid, token, flow}) {
    if (!state) return initialState();
    if (type === 'SHOW_FORGOT_PASSWORD') {
        return {...state, stepLogin: 'forgot'};
    }
    if (type === 'SHOW_RESET_PASSWORD') {
        return {...state, stepLogin: 'reset'};
    }
    if (type === 'SHOW_RESET_PASS_SUCCESS') {
        return {...state, stepLogin: 'resetSuccess'};
    }
    if (type === 'SHOW_RESET_ERROR') {
        return {...state, stepLogin: 'resetError'};
    }
    if (type === 'SHOW_MIDOFFICE') {
        return {...state, midoffice: true};
    }
    if (type === 'SHOW_HOMEPAGE') {
        return {...state, stepLogin: 'new_router'};
    }
    if (type === 'SET_URL_PARAMS') {
        return {...state, source, timeout};
    }
    if (type === 'SHOW_TERMS') {
        return {...state, stepLogin: 'terms'};
    }
    if (type === 'SHOW_TERMS_AND_CONDITIONS_PAGE') {
        return {...state, stepLogin: 'terms_page_before_login'};
    }
    if (type === 'SHOW_TERMS_MODAL') {
        return {...state, isShowTermsModal: true};
    }
    if (type === 'HIDE_TERMS_MODAL') {
        return {...state, isShowTermsModal: false};
    }
    if (type === 'SET_RESET_PASS_DATA') {
        return {...state, uid, token, flow};
    }
    return state;
}
