/* eslint-disable react/jsx-no-bind */
import Backbone from 'backbone';

import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import settings from 'airborne/settings';
import SentryAdapter from 'airborne/sentryAdapter';
import setPublicPath from './publicPath.js';
import ajaxCompat from 'midoffice/helpers/ajaxBackboneCompat';
import ApplicationRoot from './components/ApplicationRoot';
import {getSystemData, getUserData} from 'airborne/store/modules/login/actions/login';
import {store} from 'midoffice/store';
import setFavicon from 'airborne/utils/setFavicon';
import browserHistory from 'airborne/browserHistory';
import {
    getMaintenance,
    startListenMaintenances
} from 'airborne/store/modules/maintenance/actions';
import {listenTimeSession} from "midoffice/data/actions";

/** Override default AJAX requests adding default error
 * handler if it wasn't specified in request settings
 * @param {object} options - request settings */
Backbone.ajax = ajaxCompat; // eslint-disable-line immutable/no-mutation


export async function start(sub) {
    await getSystemData();
    if (settings.USER && settings.USER.id) {
        await store.dispatch(getUserData());
    }
    SentryAdapter.init(settings);
    setPublicPath(settings);
    setFavicon(settings);

    startListenMaintenances(store.dispatch);
    getMaintenance(store.dispatch);

    listenTimeSession(store.dispatch);

    const MidofficeRouter = 'pushState' in window.history ? Router : HashRouter;
    ReactDOM.render(
        <div>
            <Provider store={store}>
                <MidofficeRouter history={browserHistory}>
                    <Route  path="/" render={(props)=>
                        <ApplicationRoot {...props} sub={sub} />} />
                </MidofficeRouter>
            </Provider>
        </div>,
        document.getElementById('js-app-root')
    );
}
