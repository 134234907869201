const pending = [];
function purge(out) {
    pending.splice(pending.indexOf(out), 1);
}
export default function timeout(delay, id) {
    let saveReject = null;
    const ret = new Promise((resolve, reject)=> {
        saveReject = reject;
        setTimeout(resolve, delay);
    });
    const save = {reject: saveReject, id};
    pending.push(save);
    return ret.then((response)=> (purge(save), response));
};

function rejectPending(list, reason) {
    list.forEach((save)=> (purge(save), save.reject(reason)));
}

function findMatching(list, id) {
    return pending.filter((save)=> save.id === id);

}

export function abortAllTimers(reason) {
    rejectPending(pending, reason);
}

export function abortMatching(id, reason) {
    rejectPending(findMatching(pending, id), reason);
}

export function hasPending() {
    return pending.length > 0;
}
