import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {startHotelSearch} from './lazySearch';
import getCompanyLocations from './companyLocations';
import {waitConfirmation} from 'airborne/store/modules/checkout_hotels/actions/book';
import {abortAll} from 'midoffice/helpers/xhr';
import {abortAllTimers} from 'airborne/search2/helpers/timeout';
import browserHistory from 'airborne/browserHistory';
import {changeProduct, cleanupPrevProduct} from 'airborne/store/modules/homepage/actions';
import {getPrevProduct, getProduct} from 'airborne/store/modules/homepage/selectors/product';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';

export function abortAllRequests() {
    abortAll();
    abortAllTimers({request: {isAborted: true}});
}

export const abortRequests = (function() {
    let executed = Date.now();
    return function() {
        if (Date.now() - executed > 2000) {
            executed = Date.now();
            abortAllRequests();
        }
    };
})();

export function toHomepage() {
    if (!browserHistory || !browserHistory.location.pathname.includes('/ui/email_offers/')) {
        abortRequests();
    }
    if (browserHistory) {
        browserHistory.push('/ui/homepage/');
    }
    return {type: 'GO_TO_HOMEPAGE'};
}


export function navigate(params, prevParams, initial=false) {
    return function navigateD(dispatch, getState) {

        const product = getProduct(getState());
        const prevProduct = getPrevProduct(getState());
        if (params?.step === 'checkout' && prevProduct) {
            dispatch(changeProduct(prevProduct));
            dispatch(cleanupPrevProduct());
        }
        if (params?.step === 'search' || (location.pathname.includes('search') && product === 'hotels')) {
            const profile = getPnrProfile(getState(), 0) || {};
            const destination = getDestination(getState(), 0) || {};
            const differentDestinations = prevParams && !isEqual(
                prevParams.destination,
                params.destination
            );
            !initial && differentDestinations && abortRequests();
            return Promise.all([
                ((initial && profile.company && !isEmpty(destination)) || differentDestinations) && dispatch(startHotelSearch()),
                params.map && dispatch(getCompanyLocations()),
            ]);
        }
        else {
            !initial && abortRequests();
        }
        if (params?.step === 'checkout') {
            return dispatch(waitConfirmation());
        }

        return Promise.resolve();
    };
}
