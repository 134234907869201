import defaultHandlers from 'airborne/store/modules/stream/handlers';

const composeHandlers = handlers => handlers.reduce((acc, handler) => {
    const [[key, func]] = Object.entries(handler);
    return {
        ...acc,
        [key]: [...acc[key] || [], func]
    };
}, {});

export default composeHandlers([
    ...defaultHandlers,
]);
