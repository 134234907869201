import {compare2Levels} from 'airborne/helpers/compareList';

export function memoizeMap(inputFns, outFn) {
    let inputCache = null;
    let outCache = null;
    const inputFn = Array.isArray(inputFns)
        ? function inputFn(part, index) {
            return inputFns.map((fn)=> fn(part, index));
        }
        : function inputFn(part, index) {
            return [inputFns(part, index)];
        };
    return function (list) {
        let inputValues = list.map(inputFn);
        if (compare2Levels(inputCache, inputValues)) {
            return outCache;
        }
        inputCache = inputValues;
        outCache = inputValues.map((part, index)=> outFn(...part, index));
        return outCache;
    };
}

