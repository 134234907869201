export function convert12to24h({hours, minutes, period}) {
    const fakeHours = hours === 12 ? hours - 12 : hours;
    return {
        hours: period === 'PM'
            ? fakeHours + 12
            : fakeHours,
        minutes,
    };
}

export function convert24to12h({hours, minutes}) {
    const period = hours >= 12 ? 'PM' : 'AM';

    return {
        hours: hours % 12 === 0 ? 12 : hours % 12,
        minutes,
        period,
    };
}
