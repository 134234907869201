import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cookie from 'js-cookie';
import BootstrapModal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';

import Mousetrap from 'mousetrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import takeRight from 'lodash/takeRight';
import isEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';
import compact from 'lodash/compact';
import every from 'lodash/every';
import mapValues from 'lodash/mapValues';


import Glyphicons from 'midoffice/components/Glyphicons';
import {isFeatureFlagsLoaded, getAllFeatureFlags, canOverrideFeatureFlags, getValueSwitches} from './selector';
import {setFeatureFlags, setValueSwitches, FEATURE_FLAGS_PREFIX} from './actions';
const ROLLOUT_PREFIX = 'love_';

const LABEL_STYLE = {
    marginLeft: '10px',
    marginRight: '30px',
    fontSize: '13px',
};

const TITLES = {
    'feature_flags': 'Feature flags',
    'value_switches': 'Value sWitcher 🐺',
};

function toBoolean(value) {
    if (typeof value === 'boolean') {
        return value;
    }

    if (typeof value !== 'string') {
        return value;
    }
    return ({
        'true': true,
        'false': false,
    })[value.toLowerCase()];
}


@connect(
    (state) => ({
        isLoaded: isFeatureFlagsLoaded(state),
        flags: getAllFeatureFlags(state),
        valueSwitches: getValueSwitches(state),
        canOverride: canOverrideFeatureFlags(state),
    }),
    {setFeatureFlags, setValueSwitches},
)
export default class FeatureFlagModal extends React.Component {
    static propTypes = {
        prefix: PropTypes.string.isRequired,
        switcherPrefix: PropTypes.string.isRequired,
        shortcut: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.arrayOf(PropTypes.string).isRequired,
        ]),
        shortcutDelay: PropTypes.number,
        isLoaded: PropTypes.bool.isRequired,
        flags: PropTypes.object.isRequired,
        valueSwitches: PropTypes.object.isRequired,
        canOverride: PropTypes.bool.isRequired,
        setFeatureFlags: PropTypes.func.isRequired,
        setValueSwitches: PropTypes.func.isRequired,
    };

    static defaultProps = {
        prefix: FEATURE_FLAGS_PREFIX,
        switcherPrefix: ROLLOUT_PREFIX,
        shortcut: 'ctrl+alt+f',
        shortcutDelay: 1000,
    };

    state = {
        open: false,
        search: {
            'feature_flags': '',
            'value_switches': '',
        },
        mode: 'feature_flags',
    };

    componentDidMount() {
        this.bindShortcut();
        const {initialFeatureFlags} = this.state;

        if (!initialFeatureFlags && Object.keys(this.props.flags).length > 0) {
            this.setState({                             //eslint-disable-line react/no-did-mount-set-state
                initialFeatureFlags: this.getFlagInnerValueHash(),
                initialValueSwitches: mapValues(this.props.valueSwitches, 'value'),
            });
        }
        Mousetrap.bind(['space', 'enter'], this.checkFocusedElement);
    }

    componentWillUnmount() {
        Mousetrap.unbind(['space', 'enter']);
    }

    checkFocusedElement = () => {
        const focusedElement = document.activeElement;
        const hasFocusedChildElement = focusedElement && [...focusedElement.children].length > 0;
        if (hasFocusedChildElement && [...focusedElement.children][0].tagName === 'INPUT') {
            [...focusedElement.children][0].click();
        }
    }

    cache = {};

    getCookiesName(name) {
        return this.props.prefix + name;
    }

    getCookiesNameForSwitcher(name) {
        return this.props.switcherPrefix + name;
    }

    getFlagInnerValueHash = () =>  fromPairs(
        Object.keys(this.props.flags).map(key => [key, this.cookieToInnerValue(key)])
    );

    cookieToInnerValue = name => {
        const value = cookie.get(this.getCookiesName(name));
        return ({
            [true]: 'on',
            [false]: 'off',
        })[toBoolean(value)] || 'as_is';
    };

    bindShortcut() {
        const {shortcut} = this.props;
        if (shortcut && !Array.isArray(shortcut)) {
            Mousetrap.bind(shortcut,  () => this.onOpenClose());
        }

        if (shortcut && Array.isArray(shortcut)) {
            const shortcuts = shortcut;
            let shortcutQueue = [];
            const delayQueue = [];
            shortcuts.forEach((shortcut) => {
                Mousetrap.bind(shortcut,  () => {
                    shortcutQueue.push(shortcut);

                    delayQueue.push(
                        setTimeout(()=> {
                            shortcutQueue.shift();
                        }, this.props.shortcutDelay)
                    );

                    if (isEqual(takeRight(shortcutQueue, shortcuts.length), shortcuts)
                    ) {
                        this.onOpenClose();
                        shortcutQueue = [];
                        for (let i = 0; i < shortcuts.length; i++) {
                            clearTimeout(delayQueue.shift());
                        }
                    }
                });
            });
        }
    }

    getCurrentValue = (value, defaultValue) => {
        if (value === 'on') {
            return true;
        }

        if (value === 'off') {
            return false;
        }

        return defaultValue;
    }

    filterFlag = ([flagName]) => {
        const {mode, search} = this.state;
        if (search[mode].length < 2) {
            return true;
        }

        const splittedSearch = search[mode].toLowerCase().split(' ');
        return every(compact(splittedSearch),
            searchPart => flagName.toLowerCase().indexOf(searchPart) > -1,
        );
    }


    shouldReload = () => this.state.mode === 'feature_flags'
        ? !isEqual(this.getFlagInnerValueHash(), this.state.initialFeatureFlags)
        : !isEqual(mapValues(this.props.valueSwitches, 'value'), this.state.initialValueSwitches);

    onChange = (name, value) => {
        let flag = this.props.flags[name];
        if (value === 'as_is') {
            cookie.remove(this.getCookiesName(name));
            flag = {...flag, value: flag.defaultValue};
        }
        else {
            cookie.set(this.getCookiesName(name), value === 'on' ? true : false);
            flag = {...flag, value: value === 'on' ? true : false};
        }

        this.props.setFeatureFlags({
            ...this.props.flags,
            [name]: flag,
        });

        //We need to update it because we take flags value from cookies
        this.forceUpdate();
    };

    onOpenClose = () => this.setState({open: !this.state.open});

    onModeChange = mode => this.setState({mode});

    onGroupChange = name => {
        if (this.cache[name]) {
            return this.cache[name];
        }
        return (value) => {
            this.onChange(name, value);
        };
    }

    onReloadOpenClick = () => {
        this.shouldReload()
            ? window.location.reload()
            : this.setState({open: false});
    };

    onSearchChange = (event) => {
        const {target: {value}} = event;
        this.setState(({search, mode}) => ({
            search: {
                ...search,
                [mode]: value === null ? '' : value,
            },
        }));
    };

    onSwitcherValueInputChange = (event) => {
        const {target: {value, name}} = event;
        this.onSwitcherChange(name, value);
    };

    onSwitcherChange = (name, value) => {
        const flag = this.props.valueSwitches[name];
        if (value === null) {
            cookie.remove(this.getCookiesNameForSwitcher(name, false));
        }
        else {
            cookie.set(this.getCookiesNameForSwitcher(name, false), value);
        }

        this.props.setValueSwitches({
            ...this.props.valueSwitches,
            [name]: {
                ...flag,
                value,
            },
        });
    };

    onSwitcherGroupChange = (name) => (value) => {
        this.onSwitcherChange(name, value === 'default' ? null : '');
    };

    renderFlagItem = ([name, {description, defaultValue}]) => {
        const value = this.cookieToInnerValue(name);

        return (<Row key={name} name={name}>
            <h4><OverlayTrigger placement="top" overlay={<Tooltip id="help">{description}</Tooltip>} >
                <span className="inputlabel"><Glyphicons bsClass="glyphicon" glyph="info-sign" /></span>
            </OverlayTrigger> {name}</h4> Current state:

            {this.getCurrentValue(value, defaultValue)
                ? (<Badge bg="success" style={LABEL_STYLE}>On</Badge>)
                : (<Badge bg="danger" style={LABEL_STYLE}>Off</Badge>)}

            <ToggleButtonGroup type="radio" name={name} value={value} onChange={this.onGroupChange(name)} >
                <ToggleButton key="as_is" size="sm"
                    id={`${name}-as_is`}
                    value={'as_is'}
                    tabIndex="0"
                    variant={ value === 'as_is' ? 'secondary' : 'default'}
                    disabled={!this.props.canOverride}
                >
                    Default ({defaultValue ? 'On' : 'Off'})
                </ToggleButton>

                <ToggleButton key="on" size="sm"
                    id={`${name}-on`}
                    value={'on'}
                    tabIndex="0"
                    variant={value === 'on' ? 'secondary' : 'default'}
                    disabled={!this.props.canOverride}
                >
                    On
                </ToggleButton>

                <ToggleButton key="off" size="sm"
                    id={`${name}-off`}
                    value={'off'}
                    tabIndex="0"
                    variant={value === 'off' ? 'secondary' : 'default'}
                    disabled={!this.props.canOverride}
                >
                        Off
                </ToggleButton>
            </ToggleButtonGroup>
        </Row>);
    }

    renderSwitcherItem = ([name, {description, value_default: valueDefault, value}]) => {
        return (<Row key={name}>
            <h4>
                {description && (<OverlayTrigger placement="top" overlay={<Tooltip id="help">{description}</Tooltip>} >
                    <span className="inputlabel"><Glyphicons bsClass="glyphicon" glyph="info-sign" /></span>
                </OverlayTrigger>)}
                {name}
            </h4>
            <FormGroup style={{marginBottom: '10px'}}>
                <ToggleButtonGroup type="radio" name={name} value={value} onChange={this.onSwitcherGroupChange(name)} >
                    <ToggleButton key="as_is" size="sm"
                        id={`${name}-default`}
                        value={'default'}
                        tabIndex="0"
                        variant={ value === null ? 'secondary' : 'default'}
                        disabled={!this.props.canOverride}
                    >
                        Default {!valueDefault && '(NO_VALUE)'}
                    </ToggleButton>

                    <ToggleButton key="on" size="sm"
                        id={`${name}-own`}
                        value={'own'}
                        tabIndex="0"
                        variant={value !== null ? 'secondary' : 'default'}
                        disabled={!this.props.canOverride}
                    >
                        Own value
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormGroup>
            <FormControl
                name={name}
                value={(value === null ? valueDefault : value) || ''}
                onChange={this.onSwitcherValueInputChange}
                placeholder="Value..."
                disabled={value === null}
            />
        </Row>);
    };

    renderValueSwitcher() {
        return (
            <div className="modal-body" style={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: '600px',
            }}>
                <Alert variant="warning">
                    It is recommended to reload the page after changes! Relogin if reload doesn't help.
                </Alert>
                <Alert variant="info">
                    Changes from this window are applied only to this particular browser
                    instance and only for this particular domain name. These changes will be applied
                    indefinitely until you clean the ‘love_*’ cookies or switch all flags in this window to ‘default’
                    state.
                </Alert>
                <Container style={{width: '560px', minWidth: '560px'}}>
                    {Object.entries(this.props.valueSwitches).filter(this.filterFlag).map(this.renderSwitcherItem)}
                </Container>

            </div>
        );
    }

    renderFeatureFlags() {
        return (
            <div className="modal-body" style={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: '600px',
            }}>
                {!this.props.canOverride && (
                    <Alert variant="danger" closeLabel="Close alert">
                        You don’t have permission to override flag values.
                        It is dangerous to manipulate the flags for regular users.
                        If you actually need to use the flags, send a support request to get
                        the permission granted.
                    </Alert>
                )}
                <Alert variant="warning" closeLabel="Close alert">
                    It is recommended to reload the page after changes! Relogin if reload doesn't help.
                </Alert>
                <Alert variant="info" closeLabel="Close alert">
                    Changes from this window are applied only to this particular browser
                    instance and only for this particular domain name. These changes will be applied
                    indefinitely until you clean the ‘flag_*’ cookies or switch all flags in this window to ‘default’
                    state.
                </Alert>

                <Container style={{width: '560px', minWidth: '560px'}}>
                    {Object.entries(this.props.flags).filter(this.filterFlag).map(this.renderFlagItem)}
                </Container>
            </div>
        );
    }

    render() {
        const {open, search, mode} = this.state;
        if (!open || !this.props.isLoaded) {
            return null;
        }

        return (<BootstrapModal
            show
            className="featureFlagModal"
            onHide={this.onOpenClose}
        >
            <BootstrapModal.Header closeButton>
                <Row style={{width: "93%"}}>
                    <Col xs={4}>
                        <BootstrapModal.Title>{TITLES[this.state.mode]}</BootstrapModal.Title>
                    </Col>
                    <Col xs={7}>
                        <FormControl
                            name="search"
                            value={search[mode]}
                            onChange={this.onSearchChange}
                            placeholder="Search..."
                            autoComplete="off"
                        />
                    </Col>
                </Row>

            </BootstrapModal.Header>

            {this.state.mode === 'feature_flags'
                ? this.renderFeatureFlags()
                : this.renderValueSwitcher()
            }

            <BootstrapModal.Footer>
                <ToggleButtonGroup
                    type="radio"
                    name="mode"
                    value={this.state.mode}
                    onChange={this.onModeChange}
                    style={{float: 'left'}}
                >
                    <ToggleButton key="as_is" size="sm"
                        id="feature_flags"
                        value="feature_flags"
                        tabIndex="0"
                        variant={ this.state.mode === 'feature_flags' ? 'secondary' : 'default'}
                    >
                        Feature Flags
                    </ToggleButton>

                    <ToggleButton key="on" size="sm"
                        id="value_switches"
                        value="value_switches"
                        tabIndex="0"
                        variant={this.state.mode === 'value_switches' ? 'secondary' : 'default'}
                    >
                        Value sWitcher
                    </ToggleButton>
                </ToggleButtonGroup>

                { this.shouldReload() ? (
                    <Button name="reload" variant="info" onClick={this.onReloadOpenClick}>Reload</Button>
                ) : (
                    <Button name="close" variant="light" onClick={this.onReloadOpenClick}>Close</Button>
                )}
            </BootstrapModal.Footer>

        </BootstrapModal>);
    }
}
