import {getSearchParams} from 'airborne/search2/helpers/offers';
import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';

export function addOffer(hotel, rate) {
    return {type: 'OFFER_ADD', hotel, rate};
};

export function removeOffer(hotel, rate) {
    return {type: 'OFFER_REMOVE', hotel, rate};
};

export function clearOffers() {
    return {type: 'CLEAR_OFFERS'};
};

export function sendOffers(data) {
    const URL = '/hotels/email_offers/';
    const {
        to, sender, cc, bcc,
        'custom_text': customText,
        'traveler_name': travelerName
    } = data;

    return function sendOffersD(dispatch, getState) {
        const {ratesSid} = getState().hotels;
        const offers = getState().hotels.offers.map(({hotel, rate})=> ({
            'rate_key': rate,
            'search_id': ratesSid[hotel],
        }));
        const searchParams = getSearchParams(getState());

        const data = {
            locale: settings.USER_LOCALE,
            rates: offers,
            sender, to, bcc, cc,
            'custom_text': customText,
            'search_params': searchParams,
            'traveler_name': travelerName,
        };
        return api('POST', URL, {data});
    };
};
