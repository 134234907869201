import {register} from 'airborne/types';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import normalize from 'airborne/search2/helpers/normalize';

function fetchCollection() {
    return ['POST', '/latest/air/retrieve_reservations_list/'];
}

function serializeValue(value) {
    return value.map((part)=> part.value);
}

function optional(fn, value, ...args) {
    return value ? fn(value, ...args) : null;
}

export function serializeSearchDateRange({min, max} = {}) {
    if (!min && !max) {
        return null;
    }

    return dropEmpty({min, max});
}

function serialize(data) {
    const {page, 'page_size': pageSize, ordering=null, ...query} = data;
    const offset = ((page - 1) * pageSize);
    const {
        search=null,
        'departure_iatas': originIata,
        'arrival_iatas': destinationIata,
        'created_at_min': createdMin,
        'created_at_max': createdMax,
        'departure_datetime': departure,
        'arrival_datetime': arrival,
        'entity': entity,
        'entity_aft': entityAft,
        ...filters
    } = query;
    return dropEmpty({
        limit: pageSize,
        offset,
        ordering,
        filters: dropEmpty({
            'arrival_iatas': destinationIata,
            'departure_iatas': originIata,
            'company': entity || optional(serializeValue, entityAft),
            'created_at': (createdMin || createdMax) ? dropEmpty({min: createdMin, max: createdMax}) : null,
            'departure_datetime': serializeSearchDateRange(departure),
            'arrival_datetime': serializeSearchDateRange(arrival),
            ...filters,
            term: search,
        }),
    });
}

function parse(booking) {
    // TODO: update this to return ONLY the data needed by Air Booking Records Table (GG-23402)

    booking = normalize(booking);

    const originDestinations = booking?.fareGroup.originDestinations.map((destination) => {
        const {departure} = destination.segments[0];
        const {arrival} = destination.segments[destination.segments.length - 1];

        return {
            ...destination,
            arrival,
            departure
        };
    });
    const segments = booking?.segments?.map(segment => ({
        ...segment,
        arrival: {
            ...segment.arrival,
            airport: {
                name: segment.arrival.airportName,
                code: segment.arrival.iataCode,
            }
        },
        departure: {
            ...segment.departure,
            airport: {
                name: segment.departure.airportName,
                code: segment.departure.iataCode
            }
        }
    }));

    return {
        ...booking,
        segments,
        originDestinations,
        id: booking?.uid,
        traveler: booking?.travelers[0]
    };
}

register('airBookings', {parse, fetchCollection, serialize});
