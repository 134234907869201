import noop from 'lodash/noop';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from 'midoffice/components/Loader';

import {injectField} from 'midoffice/newforms/decorators';

import {fileCheckStart, fileCheckEnd, fileCheckFail, fileInputClose} from 'midoffice/data/actions';

import BaymontService from 'airborne/services/Baymont';

const FILE_CLASS = window.File;

function isImage(file) {
    return file && file.type.startsWith('image/');
}

const mapStateToProps = (state) => {
    return {
        isChecking: state.fileCheckProcessing.isChecking,
        inputIsOpen: state.fileCheckProcessing.inputIsOpen,
    };
};

const mapDispatchToProps = {
    fileCheckStart,
    fileCheckEnd,
    fileCheckFail,
    fileInputClose
};

class FileProccesing extends React.Component {
    static propTypes = {
        isChecking: PropTypes.bool,
    }

    constructor(props) {
        super(props);
    }

    render() {
        const {isChecking} = this.props;
        return (
            <div>
                <Loader loading={isChecking} className="loader" />
            </div>
        );
    }
}

@injectField
@connect(mapStateToProps, mapDispatchToProps)
export default class File extends React.Component {

    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(FILE_CLASS),
            PropTypes.oneOf([null]),
        ]),
        className: PropTypes.string,
        minimal: PropTypes.bool,
        disabled: PropTypes.bool,
        multiple: PropTypes.bool,
        preview: PropTypes.bool,
        readOnly: PropTypes.bool,
        accept: PropTypes.string,

        helpText: PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        fileCheckStart: PropTypes.func.isRequired,
        fileCheckEnd: PropTypes.func.isRequired,
        fileCheckFail: PropTypes.func.isRequired,
        fileInputClose: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        isChecking: PropTypes.bool,
        inputIsOpen: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        disabled: false,
        preview: false,
        minimal: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleReference = this.handleReference.bind(this);
    }

    state = {
        file: '',
        imagePreviewUrl: '',
        isChecking: false
    }

    componentDidUpdate(prevProps) {
        const {inputIsOpen} = this.props;
        const {inputIsOpen: wasOpened} = prevProps;

        if (!wasOpened && inputIsOpen) {
            this.open();
        }
    }


    open() {
        this.inputRef.click();
    }

    focus() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    _handleImageChange(file) {
        const {preview} = this.props;
        if (!preview || !isImage(file)) return null;
        let reader = new FileReader();


        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };

        reader.readAsDataURL(file);
    }

    toggleChecking() {
        this.setState(state => ({
            isChecking: !state.isChecking
        }));
    }


    handleChange() {
        const {fileCheckStart, fileCheckFail, fileCheckEnd, fileInputClose, multiple} = this.props;
        fileInputClose();
        const input = this.inputRef;
        if (input.value) {
            fileCheckStart();
            this.toggleChecking();
            if (multiple) {
                const filesToCheck = [...input.files];
                Promise.all(filesToCheck.map(BaymontService.upload)).then(files => {
                    fileCheckEnd();
                    this.toggleChecking();
                    this.props.onChange(files.map((file, iterator)=>({
                        file: file,
                        name: filesToCheck[iterator].name
                    })));
                })
                    .catch((error) => {
                        this.toggleChecking();
                        fileCheckFail(error);
                    });
            }
            else {
                BaymontService.upload(input.files[0]).then((checkedFile) => {
                    fileCheckEnd();
                    this.toggleChecking();
                    this._handleImageChange(input.files[0]);
                    this.props.onChange({file: checkedFile, name: input.files[0].name, preview: input.files[0]});
                })
                    .catch((error) => {
                        this.toggleChecking();
                        fileCheckFail(error);
                    });
            }
        }
    }

    handleClear(event) {
        event.preventDefault();
        const input = this.inputRef;
        input.value = null;
        this.props.onChange(null);
    }

    handleReference(input) {
        this.inputRef = input;
    }

    renderHelpText() {
        const {helpText} = this.props;

        if (!helpText) {
            return null;
        }

        return (<p className="help-block small">{helpText}</p>);
    }

    /**
     * Renders current component value
     *
     * 3 variants are possible:
     * - value is an HTMLInputElement; is rendered with selected file name
     * - value is empty
     * - value is a link to attached file
     *
     * if widget is configured to be previewed:
     * - given value is suggested to be an image and is displayed as image link with image preview
     * - missing value is displayed as specific `nothing selected` box
     *
     * if widget is configured to be not previewed:
     * - given value is displayed as a link to attached file
     * - missing value is not rendered at all
     */
    renderValue() {
        const {value, preview: isPreviewed} = this.props;

        if (!value) {
            if (isPreviewed) {
                return (<div className="none">Nothing selected.</div>);
            }
            else {
                return null;
            }
        }

        if (value instanceof FILE_CLASS || value instanceof Object) {
            return [
                <span className="fileinput__value">{value.name}</span>,
                ' ',
            ];
        }

        if (typeof value === 'string' && !isPreviewed) {
            return [
                <span className="fileinput__value">{value}</span>,
                ' ',
            ];
        }

        if (typeof value === 'string' && isPreviewed) {
            return (<a href={value} target="_blank" download><img src={value}/></a>);
        }

        if (isPreviewed) {
            return (<a href={value} target="_blank" download><img src={value}/></a>);
        }


        return [
            <a href={value} target="_blank" download>Download</a>,
            ' file or choose a new one - ',
        ];
    }

    renderSelectButton() {
        const {accept, disabled, onFocus, onBlur, multiple} = this.props;

        return (
            <span className="btn btn-default btn-fileinput" disabled={disabled}>
                <span>Select file…</span>
                <span>
                    <input type="file"
                        ref={this.handleReference}
                        accept={accept}
                        multiple={multiple}
                        onChange={this.handleChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        disabled={disabled} />
                </span>
            </span>
        );
    }

    renderClearLink() {
        const {disabled} = this.props;
        const handleClick = disabled ? noop : this.handleClear;

        return (<a href="#" className="clear" disabled={disabled} onClick={handleClick}>Clear</a>);
    }

    render() {
        const {value, disabled, className, minimal, preview: isPreviewed} = this.props;
        const {isChecking} = this.state;
        const fullClassName = cx(className, {
            'form-control': !minimal,
            'form-control--fileinput': !minimal,
            'form-control--fileinput--preview': isPreviewed,
            disabled,
        });

        return (
            <div>
                <div className={fullClassName}>
                    {this.renderValue()}
                    {this.renderSelectButton()}
                    {value && this.renderClearLink()}
                    <FileProccesing isChecking={isChecking}/>
                </div>
                {this.renderHelpText()}
            </div>
        );
    }
}
