export const ADDED = 'ADDED';
export const BULK_ADDED = 'BULK_ADDED';
export const BULK_FAILED = 'BULK_FAILED';
export const BULK_LOADING = 'BULK_LOADING';
export const FILEINPUT_OPEN = 'FILEINPUT_OPEN';
export const FILEINPUT_CLOSE = 'FILEINPUT_CLOSE';
export const FILECHECK_LOADING = 'FILECHECK_LOADING';
export const FILECHECK_DONE = 'FILECHECK_DONE';
export const FILECHECK_FAILED = 'FILECHECK_FAILED';
export const CHANGED = 'CHANGED';
export const FAILED = 'FAILED';
export const REMOVED = 'REMOVED';
export const LOADED = 'LOADED';
export const LOADED_PAGINATED = 'LOADED_PAGINATED';
export const LOADED_PARTIAL = 'LOADED_PARTIAL';
export const CANCEL = 'CANCEL';
export const NET_FAIL = 'NET_FAIL';
export const DISCARD = 'DISCARD';
export const LOADING = 'LOADING';
export const UPLOADING = 'UPLOADING';
export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';
export const LOADER_SHOW = 'LOADER_SHOW';
export const LOADER_HIDE = 'LOADER_HIDE';
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_UPDATE = 'MODAL_UPDATE';
export const MODAL_HIDE = 'MODAL_HIDE';
export const ALERT_SHOW = 'ALERT_SHOW';
export const ALERT_HIDE = 'ALERT_HIDE';
export const CONFIRM_SHOW = 'CONFIRM_SHOW';
export const CONFIRM_HIDE = 'CONFIRM_HIDE';
export const CONFIRM_ACCEPT = 'CONFIRM_ACCEPT';
export const CONFIRM_CANCEL = 'CONFIRM_CANCEL';
export const SORT = 'SORT';
export const SEARCH = 'SEARCH';
export const FILTER = 'FILTER';
export const CHECK = 'CHECK';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const PAGINATE = 'PAGINATE';
export const SCHEDULE = 'SCHEDULE';
export const LOGOUT = 'LOGOUT';
export const REDIRECT_OUT = 'REDIRECT_OUT';
