import React from 'react';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import {passwordValidation} from './helpers/passwordValidation';

class ErrorsBlock extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        wrapper: PropTypes.string,
        fullSize: PropTypes.bool,
    };
    render() {
        const errors = passwordValidation.validate(this.props.value);
        const minLength = settings['PASSWORD_MIN_LENGTH'];
        const useComplexity = settings['PASSWORD_USE_COMPLEXITY'];
        const size = this.props.fullSize ? 'col-xs-12' : 'col-xs-6';
        const wrapperClass = this.props.wrapper ? this.props.wrapper : 'form-group__label-offset';

        return (
            <div className={wrapperClass}>
                <div className={size}>
                    <div className="form-group__well">
                        <h5>
                            <strong>{gettext('In order to protect your account please ensure your password:')}</strong>
                        </h5>

                        <ul className="list-check">
                            <li className={errors.minLength ? 'highlight-red' :  'highlight-green'}>
                                {gettext('Is longer than {minLength} characters', {minLength})}
                            </li>
                            <li className={errors.repeatSymbols ? 'highlight-red' :  'highlight-green'}>
                                {gettext('Does not contain repetitive or sequential characters e.g., AAA111, ABC123, CBA321')}
                            </li>
                            <li className={errors.hasUserInfo ? 'highlight-red' :  'highlight-green'}>
                                {gettext('Does not match or significantly resemble your name, middle name, second name, email address or current website domains')}
                            </li>
                        </ul>

                        {useComplexity && <>
                            <h5>
                                <strong>{gettext('Contains:')}</strong>
                            </h5>
                            <ul className="list-check">
                                <li className={errors.isLowerCase ? 'highlight-red' :  'highlight-green'}>
                                    {gettext('At least one lower case letter')}
                                </li>
                                <li className={errors.isUpperCase ? 'highlight-red' :  'highlight-green'}>
                                    {gettext('At least one upper case letter')}
                                </li>
                                <li className={errors.isHasNumber ? 'highlight-red' :  'highlight-green'}>
                                    {gettext('At least one number')}
                                </li>
                                <li className={errors.isHasSymbol ? 'highlight-red' :  'highlight-green'}>
                                    {gettext('At least one special character')}
                                </li>
                            </ul>
                        </>}
                    </div>
                </div>
            </div>

        );
    }
}

export default ErrorsBlock;
