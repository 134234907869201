import capitalize from 'lodash/capitalize';

export function capitalizeEveryWord(str) {
    return str
        .split(' ')
        .map(word => capitalize(word))
        .join(' ');
}

export function removeExceptFirst(string, characterToRemove) {
    if (string.length <= 1) {
        return string;
    }

    return string[0] + string.slice(1).replace(characterToRemove, '');
}
