import PushStream, {ERRORS, MESSAGE_TYPES} from '@getgoing/mercure-fe';
import settings from 'airborne/settings';
import SentryAdapter from 'airborne/sentryAdapter';


const subscriberUrl = settings.MERCURE_SUBSCRIBER_URL;
const channels = settings.MERCURE_CHANNELS;

const StreamService = new PushStream(subscriberUrl, channels);

StreamService.setPushStreamErrorsHandler(error => {
    if (error && error.message) {
        SentryAdapter.captureException(new Error(error.message), {
            extra: {
                mercureConnected: StreamService.connected,
                mercureProcesses: StreamService.processes,
            },
        });
    }
});

// For service testing
window.PushStreamService = StreamService;

export {MESSAGE_TYPES, ERRORS};
export default StreamService;
