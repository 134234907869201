
function initial() {
    return {currentMaintenance: null, isEmpty: true};
}

export default function maintenances(state, {type, currentMaintenance, maintenances}) {

    if (!state || type === 'RESET') {
        return initial();
    }

    if (type === 'SET_MAINTENANCE_LIST') {
        return {
            ...state,
            maintenances,
        };
    }

    if (type === 'SET_MESSAGE') {
        return {
            ...state,
            currentMaintenance,
        };
    }

    return state;
}
