import searchHotels from 'airborne/store/modules/search_hotels/actions/lazySearch';

export function changeTo(value, label, type) {
    return function searchForD(dispatch) {
        dispatch({
            type: 'REUSE_DEST',
            value: {
                destination: {value, label, type},
            },
        });
        dispatch({
            type: 'SEARCH_LIST',
        });
        return dispatch(searchHotels());
    };
}

