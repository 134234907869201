import {register} from 'airborne/types';
import {dropEmpty, serializeDateRange} from 'midoffice/helpers/urlParams';
import moment from 'moment/moment';

function dropLabels(list=null) {
    if (!list) {
        return null;
    }
    return list.map((el)=> (el.value && el.label) ? el.value : el);
}

function serializeFilters(filters) {
    const {checkin, checkout, 'ts_creation': tsCreate, iata, 'gds_office_id': oids, provider, status, system, ...rest} = filters;
    return dropEmpty({
        'report_format': '.csv',
        'checkin': serializeDateRange(checkin),
        'checkout': serializeDateRange(checkout),
        'created_date': serializeDateRange(tsCreate),
        'iata': dropLabels(iata),
        'gds_office_id': dropLabels(oids),
        'providers': provider || null,
        'status': status ? status.join(',') : null,
        'system': system ? system.join(',') : null,
        ...rest,
    });
}

export function serialize({filters, ts_creation_min, ts_creation_max, ...data}={}) {  // eslint-disable-line camelcase
    return filters ? serializeFilters(dropEmpty({
        ...data,
        ts_creation_min: ts_creation_min  // eslint-disable-line camelcase
            ? moment(ts_creation_min).toISOString()  // eslint-disable-line camelcase
            : null,
        ts_creation_max: ts_creation_max  // eslint-disable-line camelcase
            ? moment(ts_creation_max).toISOString()  // eslint-disable-line camelcase
            : null,
    })) : data;
}

register('reports', {serialize});
