export function emptyCompany(id) {
    return {
        id,
        'is_folder': false,
        'obt_client_id': null,
        'parent': null,
        'sap_account_number': null,
        'smid': null,
        title: `<failed to load title> | ID: ${id}`,
        'tspm_entity_id': null,
        'updated_by_id': null,
        'updated_by_email': null,
        'is_deprecated': null
    };
}

export function parseList(list, params={}) {
    if (list.length === 0 && (params.subtreeFor || params.siblingsFor)) {
        const id = params.subtreeFor || params.siblingsFor;
        return [emptyCompany(id)];
    }
    return list;
}
