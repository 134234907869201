/* global global,module */

import makeLazyAction from 'airborne/search2/helpers/lazyAction';

function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> resolve(require('./search')));
    });
}

export default makeLazyAction(load);
export const startHotelSearch = makeLazyAction(load, 'startHotelSearch');

const injectEnsure = global.IS_TEST && function injectEnsure(mock) {
    module.require.ensure = mock; // eslint-disable-line immutable/no-mutation
};
export {injectEnsure as injectEnsure};
