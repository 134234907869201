import compact from 'lodash/compact';
import get from 'lodash/get';

import {register} from 'airborne/types';
import normalize from 'airborne/search2/helpers/normalize';
import gettext from 'airborne/gettext';

const getCarsI18N = () => ({
    daily: gettext('daily'),
    weekend: gettext('weekend'),
    weekly: gettext('weekly'),
    monthly: gettext('monthly'),
    bundled: gettext('bundled'),
});

export const getRatePlanI18N = (ratePlan = {}) => ({
    ...ratePlan,
    type: getCarsI18N()[ratePlan?.type?.toLowerCase()] || gettext('N/A'),
});

export function getRateType(parsed) {
    return compact([
        parsed.isIdRate && 'id',
        parsed.isItRate && 'it',
        parsed.isCdRate && !parsed.isBcdCdRate && 'cd',
        parsed.isBcdCdRate && 'bcdCd',
        !parsed.isIdRate && !parsed.isCdRate && !parsed.isItRate && 'pub',
    ]);
}

export function parse(rate) {
    const parsed = normalize(rate);
    const preferredBy = compact([
        parsed.preferredVendor && 'vendor',
        parsed.bcdPreferredVendor && 'bcdVendor',
        parsed.preferredCarType && 'carType',
        parsed.bcdPreferredCarType && 'bcdCarType',
    ]);
    const preferenceTiers = {
        vendor: parsed.preferenceTier || null,
        bcdVendor: parsed.bcdPreferenceTier || null
    };
    const rateType = getRateType(parsed);
    const mileageType = get(parsed, 'ratePlan.distanceIncluded.unlimited', false) ?
        'unlimited' :
        'limited';
    const vendorLocationCode = parsed?.pickup?.locationCode + parsed?.vendor?.name;

    return {
        ...parsed,
        preferenceTiers,
        preferredBy,
        rateType,
        mileageType,
        vendorLocationCode,
    };
}

export function parseList(rates) {
    return rates.map((rate, index)=>{
        return {
            ...rate,
            rateId: index,
        };
    });
}

register('carsRateDetails', {parse});
register('cars', {parse, parseList});
