import {createSelector} from 'reselect';
import {getItemByIndex} from 'airborne/homepage2/helpers/multipnr';
import {getAllBookings, getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';

export function isEvoucher(state, index) {
    return state.multiBooking.items[index]?.value?.evoucher;
}

export function getAllBookingDetails(state) {
    return state.multiBookingDetails;
}

export function getBookingDetails(state, index) {
    return getItemByIndex(state.multiBookingDetails, index);
}

export function getBookingDetailsCount(state) {
    return state.multiBookingDetails.length;
}

function detailsStatus(details) {
    return details ? details['booking_status'] : null;
}

function bookingStatus({message, loading}, details) {
    if (loading) return 'in-progress';
    return detailsStatus(details) || ((message === null)
        ? null
        : 'failed');
}

export const getBookingStatus = createSelector(
    getBookingDetails,
    getBooking,
    (details, booking)=> bookingStatus(booking, details.data),
);

export function getCancelStatus(state, index) {
    const {data} = getBookingDetails(state, index);
    return data['cancellation_status'] || null;
}

export const getAllBookingStatuses = createSelector(
    (state)=> state.multiBookingDetails,
    getAllBookings,
    (list, bookings)=> list && list.map((details, index)=>
        bookingStatus(bookings[index], details.data)
    ),
);

export const getAllCancelStatuses = createSelector(
    (state)=> state.multiBookingDetails,
    (list)=> list && list.map((details)=> (
        details.data ? details.data['cancellation_status'] : null
    )),
);

export function getBookingUID(state, index) {
    const bookingDetails = getBookingDetails(state, index);
    return bookingDetails.uid || bookingDetails?.data?.['booking_uid'] || null;
}

function getBookingPNR(state, index) {
    const {data} = getBookingDetails(state, index);
    return data ? data['pnr_id'] : null;
}


export function getAllBookingUIDs(state) {
    return state.multiBookingDetails.map(({uid})=> uid);
}

function isFailed(status) {
    return status === 'failed';
}

export function getAllFailed(state) {
    return getAllBookingStatuses(state)
        .map((status, index)=> ({
            status,
            index,
            pnr: getBookingPNR(state, index)
        }))
        .filter(({status, index})=> isFailed(status, index));
}
