import {CANCEL, CHANGED, LOADED, LOADING, NET_FAIL, DISCARD} from './types';
import {fetchRegions, saveRegions} from './regions';

import api from 'midoffice/helpers/api';
import {parse, serialize, getUrl} from 'midoffice/data/types';
import omit from 'lodash/omit';
import {shouldInherit, inherits, getSettings, keyForInheritance, getConstants} from 'midoffice/helpers/settings';

import {hideModal} from 'midoffice/data/actions/modals';
import {getURLParameter} from 'airborne/utils/params';

const OMIT_FIELDS_BY_SECTION = {
    'rate-codes': ['primary_gds'],
};

const OMIT_FIELDS_BY_SYSTEM_AND_SECTION = {
    'rate-optimization:all': ['rate_optimization:top_rate_limit'],
    'rate-optimization:aft': ['rate_optimization:top_rate_limit'],
};

function urlFor(query) {
    return getUrl('settings', 'fetchScreen', query);
}

function urlForInh(query) {
    return getUrl('settings', 'fetchMatrix', query);
}

function getResponseErrorMessages({body: {message}={}}) {
    if (Array.isArray(message)) {
        return message;
    }

    return message ? [message] : [];
}

export function fetchInheritance(dispatch, getState, {treeName, section}) {
    const {inheritance, ...state} = getState();

    const tree = state[treeName];
    const matrix = inheritance.hash[keyForInheritance({treeName, section})];
    const matrixSize = inheritance.sizeHash[keyForInheritance({treeName, section})];

    if (matrixSize && matrixSize === tree.total) {
        return Promise.resolve(matrix);
    }

    dispatch({type: LOADING, endpoint: 'inheritance', treeName, section});

    return api('GET', urlForInh({treeName, section})).then(
        ({inheritance, size}) => {
            dispatch({
                type: LOADED,
                endpoint: 'inheritance',
                treeName,
                section,
                data: inheritance,
                size,
                toMatrix: true,
            });
        },
        response => {
            dispatch({
                type: NET_FAIL,
                endpoint: 'inheritance',
                treeName,
                section,
                response,
            });
        }
    );
}

export function fetchParams(dispatch, query) {
    dispatch({type: LOADING, endpoint: 'settings', ...query});
    return api('GET', urlFor(query)).then(
        ({
            params,
            tasks,
            inherits: flag,
            constants,
            company_info: companyInfo,
            policy_group_info: policyGroupInfo,
            inheritance: policyGroupInheritance,
        }) => {
            const data = parse('settings', params, {...query, constants});
            dispatch({
                type: LOADED,
                endpoint: 'settings',
                tasks,
                data: {
                    ...data,
                    companyInfo,
                    policyGroupInfo,
                    policyGroupInheritance,
                    constants,
                },
                ...query,
            });
            return [flag, data];
        },
        response => {
            dispatch({
                type: NET_FAIL,
                endpoint: 'settings',
                response,
                errors: getResponseErrorMessages(response),
                ...query,
            });

            throw response;
        }
    );
}

export function fetchSettings({treeName, id, section, system, policyGroupId}) {
    return function(dispatch, getState) {
        if (section === 'regions') {
            return dispatch(fetchRegions({treeName, id, section, system}));
        }
        return Promise.all([
            fetchParams(dispatch, {treeName, id, section, system, policyGroupId}),
            fetchInheritance(dispatch, getState, {treeName, section}),
        ]).then(
            ([data]) => {
                const [flag, params] = data;
                dispatch({
                    type: LOADED,
                    endpoint: 'inheritance',
                    flag,
                    treeName,
                    id,
                    section,
                    system,
                });

                return params;
            }).catch((err) => {
            //eslint-disable-next-line
            console.log(err);
        });
    };
}

export function cancelSettings({treeName, id, section, system}) {
    return function(dispatch, getState) {
        const query = {treeName, id, section, system};

        dispatch({type: CANCEL, endpoint: 'settings', ...query});
        dispatch({type: CANCEL, endpoint: 'inheritance', ...query});

        const {data} = getSettings(getState(), query);
        return Promise.resolve(data);
    };
}

export function discardSettings({treeName, id, section, system}) {
    return {type: DISCARD, endpoint: 'settings', treeName, id, section, system};
}

export function changeInheritanceForOverrideSetting({
    treeName,
    id,
    section,
    system,
    flag,
    policyGroupId,
    oldSystem,
    companyId,
}) {
    return function(dispatch, getState) {
        const entity = {
            id: policyGroupId ? id : companyId,
            system,
            ...(flag ? {...policyGroupId ? {policyGroupId} : {}} : {}),
        };
        return api('GET', urlFor({treeName, section, ...entity, inheritsFlag: flag})).then(
            ({params, policy_group_info: policyGroupInfo, constants}) => {
                const query = {treeName, id: companyId, section, system: oldSystem};
                const {data: currentData, policyGroupInheritance: inheritanceData} = getSettings(getState(), query);
                const data = {
                    ...currentData,
                    ...parse('settings', params, {...query, constants}, {fromParent: flag}),
                };
                dispatch({
                    type: CHANGED,
                    endpoint: 'inheritance',
                    flag,
                    ...query,
                });
                dispatch({
                    type: CHANGED,
                    endpoint: 'settings',
                    data: {
                        ...data,
                        policyGroupInfo,
                        policyGroupInheritance: inheritanceData,
                    },
                    ...query,
                });
                return data;
            },
            response => {
                dispatch({
                    type: NET_FAIL,
                    endpoint: 'settings',
                    treeName,
                    id,
                    section,
                    system,
                    response,
                });
            }
        );
    };
}

export function changeInheritance({treeName, id, section, system, flag}) {
    return function(dispatch, getState) {
        const policyGroupId = getURLParameter('policy_group_id');
        const queryWithPolicyGroupId = policyGroupId ? {id, system, policyGroupId} : {id, system};
        return api('GET', urlFor({treeName, section, ...queryWithPolicyGroupId, inheritsFlag: flag})).then(
            ({params, policy_group_info: policyGroupInfo, inheritance: policyGroupInheritance, constants}) => {
                const query = {treeName, id, section, system};
                const {data: currentData} = getSettings(getState(), query);
                const data = {
                    ...currentData,
                    ...parse('settings', params, {...query, constants}, {fromParent: flag}),
                };
                dispatch({
                    type: CHANGED,
                    endpoint: 'inheritance',
                    flag,
                    ...query,
                });
                dispatch({
                    type: CHANGED,
                    endpoint: 'settings',
                    data: {
                        ...data,
                        policyGroupInfo,
                        policyGroupInheritance,
                    },
                    ...query,
                });
                return data;
            },
            response => {
                dispatch({
                    type: NET_FAIL,
                    endpoint: 'settings',
                    treeName,
                    id,
                    section,
                    system,
                    response,
                });
            }
        );
    };
}

export function saveSettings({treeName, id, section, system, policyGroupId}) {
    return function(dispatch, getState) {
        if (section === 'regions') {
            return dispatch(saveRegions({treeName, id, section, system}));
        }

        const query = {treeName, id, section, system};
        const url = urlFor(query);
        const state = getState();
        const constants = getConstants(state, section);
        const {enabled: inheritanceEnabled} = inherits(state, query);
        const {loading, data: settings} = getSettings(state, query);

        if (loading) {
            return null;
        }

        dispatch({type: LOADING, endpoint: 'settings', ...query});

        const data = serialize(
            'settings',
            omit(settings, ['policyGroupInfo']),
            {...query, constants},
            inheritanceEnabled,
            policyGroupId,
        );

        return api('POST', url, {data}).then(
            async ({
                params,
                tasks,
                company_info: companyInfo,
                policy_group_info: policyGroupInfo,
                inheritance: policyGroupInheritance,
            }) => {
                await fetchInheritance(dispatch, getState, {treeName, section});
                const data = parse('settings', params, {section, treeName, constants});
                dispatch({
                    type: LOADED,
                    endpoint: 'settings',
                    data: {
                        ...data,
                        companyInfo,
                        policyGroupInfo,
                        policyGroupInheritance,
                        constants,
                    },
                    tasks,
                    ...query,
                });
                dispatch({
                    type: LOADED,
                    endpoint: 'inheritance',
                    flag: inheritanceEnabled,
                    ...query,
                });
                if (system === 'all') {
                    dispatch(discardSettings({treeName, id, section, system: 'aft'}));
                    dispatch(discardSettings({treeName, id, section, system: 'obt'}));
                    dispatch(
                        discardSettings({
                            treeName,
                            id,
                            section,
                            system: 'mobile',
                        })
                    );
                }
                return data;
            },
            response => {
                const errors = parse('settings', (response && response.responseJSON) || null, query, {errors: true});
                dispatch({
                    type: NET_FAIL,
                    endpoint: 'settings',
                    response,
                    errors,
                    ...query,
                });
                throw response;
            }
        );
    };
}

export function changeSettings({treeName, id, section, system, data, errors}) {
    return {
        type: CHANGED,
        endpoint: 'settings',
        treeName,
        id,
        section,
        system,
        data,
        errors,
    };
}

export function copySettingsFromCompany({treeName, entityId, section, system, companyId, currentChannel}) {
    return async function(dispatch) {
        const data = await dispatch(
            fetchSettings({
                treeName,
                id: companyId,
                section,
                system: currentChannel,
            })
        );
        const omitFields = [
            ...(OMIT_FIELDS_BY_SYSTEM_AND_SECTION[`${section}:${system}`] || []),
            ...(OMIT_FIELDS_BY_SECTION[section] || []),
        ];

        dispatch(
            changeSettings({
                treeName,
                id: entityId,
                section,
                system,
                data: omit(data, omitFields),
            })
        );
        dispatch({
            type: CHANGED,
            endpoint: 'inheritance',
            flag: false,
            ...{treeName, id: entityId, section, system},
        });
        dispatch(hideModal('copy'));
    };
}
