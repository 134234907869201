import {LOADED, NET_FAIL, REDIRECT_OUT} from './types';
import window from 'airborne/window';
import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';
import moment from 'moment';

const TEST_NOTIFICATION_TIME = 60 * 1000;
const NOTIFICATION_TIME = 5 * 60 * 1000;

export function redirect(url) {
    window.location = url;  // eslint-disable-line immutable/no-mutation
    return {
        type: REDIRECT_OUT,
        url,
    };
}

export function extendSession() {
    return function (dispatch) {
        const url = '/accounts/extend-session/';
        api('GET', url).then(
            ()=> {
                dispatch({type: LOADED, endpoint: 'session', ends: false});
                window.dispatchEvent(new Event('update_session_time'));
            },
            ()=> {
                dispatch({type: NET_FAIL, endpoint: 'session'});
            }
        );
    };
}


let logoutTimeout = null;

function resetLogoutTimeout(dispatch) {
    if (settings['session:timeout_period']) {
        // settings['session:timeout_period'] === 2, here 2 is a time session under flag QA_TEST_SESSION_EXPIRATION_SETTINGS
        const notificationTime = settings['session:timeout_period'] === 2 ? TEST_NOTIFICATION_TIME : NOTIFICATION_TIME;
        const expirationTime = (settings['session:timeout_period'] * 60 * 1000) - notificationTime;
        clearTimeout(logoutTimeout);
        logoutTimeout = setTimeout(() => {
            dispatch({type: LOADED, endpoint: 'session', ends: true});
        }, expirationTime);
    }
}

export function listenTimeSession(dispatch) {
    //this for local tab
    window.addEventListener('update_session_time', () => {
        window.localStorage.setItem(settings.SUB_PARTNER + '_last_requests_time', moment.now());
        resetLogoutTimeout(dispatch);
    });
    //this for tabs in the same domain, but not for the local one
    window.addEventListener('storage', function(event) {
        if (event.key === settings.SUB_PARTNER + '_last_requests_time') {
            resetLogoutTimeout(dispatch);
            dispatch({type: LOADED, endpoint: 'session', ends: false});
        }
    });
}
