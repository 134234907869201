import some from 'lodash/some';
import find from 'lodash/find';
import {createSelector} from 'reselect';

import normalize from 'airborne/search2/helpers/normalize';
import {serializeSearchMode} from 'airborne/homepage2/helpers/serialize';
import {getPnrProfile, isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {hasPnr} from 'airborne/homepage2/helpers/pnr';
import {getDetails} from './rates';
import {rateLabeling} from '../helpers/hyatt';

import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';


function populateIds(offer, hotels, rates) {
    return {
        ...offer,
        hotel: find(hotels, {id: offer.hotel}),
        rate: find(rates[offer.hotel], {'rate_key': offer.rate}),
    };
}

function getOffers(state) {
    return state.hotels.offers;
}

export function inEmailOffers(state, {rateKey, hotelId}) {
    return Boolean(find(getOffers(state), {hotel: hotelId, rate: rateKey}));
}

export function isEnabledEmailOffers(state, index) {
    const profile = getPnrProfile(state, index);
    return hasPnr(profile) && !isMultiPnr(state);
}

export function mapEmailOfferButton(state, {rateKey, hotelId}) {
    return {
        selected: inEmailOffers(state, {rateKey, hotelId}),
        show: isEnabledEmailOffers(state, 0),
    };
}

export function prepareOffer(offer, hotels, rates, details) {
    const populated = populateIds(offer, hotels, rates);

    return {
        ...populated,
        rate: normalize(populated.rate),
        hotel: normalize(populated.hotel),
        details: normalize(details),
    };
}

const offersSelector = ({hotels: {offers}})=> offers;
const offerSelector = ({hotels: {offers}}, {hotelId, rateKey})=> (
    find(offers, {hotel: hotelId, rate: rateKey})
);
const hotelsSelector = ({hotels: {hotels}})=> {
    if (hotels.data.length !== 1 || !hotels.data[0]['alternative_hotels']) {
        return hotels.data;
    };

    return [hotels.data[0], ...hotels.data[0]['alternative_hotels']];
};
const ratesSelector = ({hotels: {rates}})=> rates.data;
const detailsSelector = (state, {hotelId, rateKey})=> getDetails(state, hotelId, rateKey);

export const mapEmailOffers = createSelector([
    offersSelector, hotelsSelector,
    ratesSelector,
    (state)=> state
], (offers, hotels, rates, state)=> {
    const loading = some(offers.map(({hotel, rate})=> (
        getDetails(state, hotel, rate).loading
    )));
    offers = offers.map((offer)=> (
        prepareOffer(
            offer, hotels, rates,
            getDetails(state, offer.hotel, offer.rate).details
        )
    ));
    return {
        offers,
        loading,
        total: offers.length,
        show: isEnabledEmailOffers(state, 0),
    };
});

export const mapEmailOffer = createSelector([
    offerSelector, hotelsSelector, ratesSelector, detailsSelector,
    (state)=> rateLabeling(state),
], (offer, hotels, rates, {loading, details}, labeling)=> ({
    offer: prepareOffer(offer, hotels, rates, details),
    loading, labeling,
}));

export function getSearchParams(state) {
    const {homepage: {sso}} = state;
    const destination = getDestination(state);
    const profile = getPnrProfile(state, 0);
    return {
        'checkin': destination.dates.min,
        'checkout': destination.dates.max,
        'configuration_id': getSelectedConfiguration(state) || profile.company,
        'distance': destination.distance,
        'email': profile.email,
        'original_gds_oid': profile['original_gds_oid'],
        'pnr': profile.pnr,
        'pnr_name_first': profile['pnr_name_first'],
        'pnr_name_last': profile['pnr_name_last'],
        'pnr_name_title': profile['pnr_name_title'],
        'profile_name': profile['profile_name'],
        'room1': destination.guests,
        'search_mode': serializeSearchMode(state),
        'special_rates': destination['special_rates'],
        'tspm_company_id': profile['tspm_company_id'],
        'tspm_traveler_id': profile['tspm_traveler_id'],
        'sso_saml_art': sso && sso['sso_saml_art'],
        'sso_url': sso && sso['sso_url'],
    };
}
