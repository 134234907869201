import {ADDED, LOADING, NET_FAIL} from '../actions/types';

function initialState() {
    return {
        reportUrl: null,
        reportId: null,
        loading: false,
        errors: null,
    };
}

function reportAdded(state, action) {
    return {
        ...state,
        loaded: true,
        reportUrl: action.data.reportUrl || null,
        reportId: action.data.reportId || null,
    };
}

function reportLoading(state) {
    return {
        ...state,
        loaded: false,
    };
}

function reportFail(state, action) {
    return {
        ...state,
        loading: false,
        error: true,
        errors: action.errors?.errors,
    };
}

export default function reports(state, action) {
    if (!state) {
        return initialState();
    }

    if (action.type === ADDED) {
        return reportAdded(state, action);
    }

    if (action.type === LOADING) {
        return reportLoading(state, action);
    }

    if (action.type === NET_FAIL) {
        return reportFail(state, action);
    }

    return state;
}

