import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FieldLabel from 'midoffice/newforms/components/FieldLabel';
import FieldError from 'midoffice/newforms/components/FieldError';


export default class Field extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        col: PropTypes.number,
        grid: PropTypes.bool,
        control: PropTypes.bool,
        className: PropTypes.string,
        plainWidget: PropTypes.bool,
        wrapperClassName: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.oneOf([false])
        ]),
        secondLabel: PropTypes.string,
        labelComponent: PropTypes.string,
        labelClassName: PropTypes.string,
        widgetClassName: PropTypes.string,
        labelCol: PropTypes.number,
        small: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ])),
        ]),
        labelSuffix: PropTypes.string,
        labelAfter: PropTypes.node,
        inputCol: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['auto'])
        ]),
        errors: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
            PropTypes.array,
            PropTypes.bool
        ]),
        errorsPosition: PropTypes.oneOf(['normal', 'top', 'bottom']),
        showErrorMessage: PropTypes.bool,
        hint: PropTypes.string,
        required: PropTypes.bool,
        before: PropTypes.node,
        after: PropTypes.node,
        widget: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),

        autoFocus: PropTypes.bool,

        onFocus: PropTypes.func,
        onBlur: PropTypes.func,

        children: PropTypes.any,
        errorAsBlock: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        label: '',
        labelClassName: '',
        labelComponent: 'label',
        labelCol: 2,
        labelSuffix: null,
        inputCol: 'auto',
        showErrorMessage: true,
        required: false,
        grid: true,

        onFocus: noop,
        onBlur: noop,
    };

    componentDidMount() {
        if (this.props.autoFocus) {
            this.focus();
        }
    }

    focus() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    getLabelProps(isColumn) {
        const {
            name,
            label,
            required,
            hint,
            labelClassName,
            labelCol,
            labelComponent,
            labelSuffix,
            grid,
            control,
            labelAfter,
        } = this.props;
        const className = classnames(labelClassName, {
            'control-label': !isColumn && (grid || control),
            [`col-xs-${labelCol}`]: !isColumn && grid,
            'block-label': isColumn
        });
        return Object.assign({
            className,
            component: labelComponent,
            name,
            hint,
            label,
            required,
            after: labelAfter,
        }, isNull(labelSuffix) ? {} : {suffix: labelSuffix});
    }

    renderInput() {
        if (this.props.widget) {
            const props = {
                ...omit(this.props, 'widget', 'className', 'widgetClassName', 'errorAsBlock'),
                className: this.props.widgetClassName,
            };

            return React.createElement(this.props.widget, {
                ref: (input)=> { this.inputRef = input; }, // eslint-disable-line immutable/no-mutation
                ...props,
            });
        }

        return this.props.children;
    }

    renderSecondLabel() {
        const {secondLabel} = this.props;
        if (!secondLabel) {
            return null;
        }
        return (<span className="form-control--label">{secondLabel}</span>);
    }

    renderSmallText() {
        const {small} = this.props;
        if (!small) {
            return null;
        }

        return React.Children.map(small, (item, idx)=> (
            <div className="form-group__note" key={idx}>{item}</div>
        ));
    }

    renderErrors() {
        const {errors, errorsPosition, showErrorMessage, secondLabel, errorAsBlock} = this.props;
        if (!showErrorMessage) {
            return null;
        }
        return (<FieldError
            errors={errors}
            asBlock={errorAsBlock}
            position={(errorsPosition || (Boolean(secondLabel))) ? 'bottom' : 'normal'} />
        );
    }

    render() {
        let props = this.props;

        if (props.widget && props.widget.fieldProps) {
            props = Object.assign({}, props, props.widget.fieldProps);
        }

        const isColumn = Boolean(props.col);
        const grid = Boolean(props.grid);

        const rootClassName = classnames('form-group', props.className, {
            [`col-xs-${props.col}`]: isColumn && grid,
            'has-error': Boolean(props.errors),
        });

        const labelCol = props.label === false ? 0 : props.labelCol;
        const inputCol = (!isColumn && props.inputCol === 'auto') ? 12 - labelCol : props.inputCol;

        const wrapperClassName = classnames(
            props.wrapperClassName,
            {[`col-xs-${inputCol}`]: !isColumn && grid}
        );

        return props.plainWidget ? this.renderInput() : (
            <div className={rootClassName}>
                {props.before}
                {
                    props.label !== false
                        && labelCol !== 0
                        && <FieldLabel {...this.getLabelProps(isColumn, grid)} />
                }
                <div className={wrapperClassName}>
                    {this.renderInput()}
                    {this.renderSmallText()}
                    {this.renderSecondLabel()}
                    {this.renderErrors()}
                </div>
                {props.after}
            </div>
        );
    }

}
