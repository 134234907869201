import {
    FILTER, CHECK, SORT, SEARCH, REMOVE_FILTER, PAGINATE,
    CHANGED, REMOVED,
    LOADED, LOADED_PARTIAL,
    LOADING, NET_FAIL,
    UPLOAD_CLEAR,
} from './types';

import api from 'midoffice/helpers/api';
import getErrors from 'midoffice/helpers/apiErrors';
import {parseList} from 'midoffice/data/types';
import {getUrl} from 'midoffice/data/types';

import {fetchCollection, fetchEntry, removeEntry, addEntry, changeEntry} from './collection';
import {emptyCompany} from 'midoffice/data/types/companies';
export {fetchCollection, fetchEntry, removeEntry, addEntry, changeEntry};


function wasLoadedTree({siblingsFor: id}, {full, empty, tree, partial}) {
    if (full === true && empty === false) {
        return true;
    }
    if (id === 'root') {
        return tree.root.length > 0;
    }

    return Boolean(partial[id]);
}

function wasLoaded(endpoint, params, state) {
    if (endpoint === 'companies' || endpoint === 'agencies') {
        return wasLoadedTree(params, state);
    }
    return false;
}

export function fetchPartial(endpoint, {force, ...params}, throwOnFail) {
    return function (dispatch, getState) {
        if (!force && wasLoaded(endpoint, params, getState()[endpoint])) {
            return Promise.resolve();
        }
        dispatch({type: LOADING, endpoint});

        const url = getUrl(endpoint, 'fetchCollection', params);

        return api('GET', url)
            .then((data)=> {
                const {results=null, total=null} = data;
                const parsed = parseList(endpoint, results || data, params);
                dispatch({type: LOADED_PARTIAL, endpoint, data: parsed, total: total});
                return parsed;
            }, (response)=> {
                dispatch({type: NET_FAIL, endpoint, response, errors: getErrors(response)});
                if (throwOnFail) {
                    throw response;
                }
            });
    };
}

export function markCompaniesAsNotLoaded(ids) {
    const data = ids.map(emptyCompany);
    return {
        type: LOADED_PARTIAL,
        endpoint: 'companies',
        data,
        total: data.length
    };
}

export function sortCollection(endpoint, field) {
    return {type: SORT, endpoint, field};
}

export function searchCollection(endpoint, query) {
    return {type: SEARCH, endpoint, query};
}

export function filterCollection(endpoint, query) {
    return {type: FILTER, endpoint, query};
}

export function removeFilter(endpoint, query) {
    return {type: REMOVE_FILTER, endpoint, query};
}

export function clearCollection(endpoint) {
    return {type: LOADED, endpoint, data: []};
}

export function paginateCollection(endpoint, query) {
    return {type: PAGINATE, endpoint, query};
}
export function discardEntry(endpoint, id) {
    return {type: REMOVED, endpoint, id};
}

export function editEntry({endpoint, id, data}) {
    return {type: CHANGED, endpoint, id, data};
}

export function checkEntry({endpoint, id, flag}) {
    return {type: CHECK, endpoint, id, flag};
}

export function clearUpload({endpoint, pendingId}) {
    return {type: UPLOAD_CLEAR, endpoint, pendingId};
}
