import {gettext} from 'airborne/gettext';
import moment from 'moment';

export const durationToMinutes = ({hours, minutes}) => hours * 60 + minutes;

export const minutesToDuration = mins => {
    const hours = Math.floor(mins / 60);
    return {hours, minutes: mins - hours * 60};
};

export const showDuration = ({hours, minutes} = {}) => {
    return `${hours ? `${hours} ${gettext('hr')} ` : ''}${minutes ? `${minutes} ${gettext('min')}` : ''}`;
};

export const showDurationFromMinutes = (mins) => {
    return showDuration(minutesToDuration(mins));
};

export const showShortDuration = ({hours, minutes}) => {
    return `${hours}:${moment(minutes, 'mm').format('mm')}`;
};

export const showShortDurationFromMinutes = (mins) => {
    return showShortDuration(minutesToDuration(mins));
};

export const diffInDays = (momentA, momentB) =>
    momentA.startOf('day').diff(momentB.startOf('day'), 'days');

export const getSegmentsDiffMins = (current, next) =>
    moment.duration(moment(next.departure.datetime)
        .diff(moment(current.arrival.datetime), 'minutes'));

export const getSegmentsDiffDuration = (current, next) =>
    showDurationFromMinutes(getSegmentsDiffMins(current, next));

export const getSegmentsDurationObject = (current, next) => {
    const minutes = moment.duration(moment(next.arrival.datetime)
        .diff(moment(current.departure.datetime), 'minutes'));
    
    return minutesToDuration(minutes);
};

export const getMinutesFromMidnight = datetime =>
    moment(datetime).diff(moment(datetime).startOf('day'), 'minutes');

export const getTimeFromMinutes = mins => {
    const {hours, minutes} = minutesToDuration(mins);
    return moment(`${hours}:${minutes}`, 'HH:mm').format('HH:mm');
};
