import sortBy from 'lodash/sortBy';
import {titleForTreeSelect} from 'midoffice/helpers/format';


export class TreeAutocomplete {

    constructor(treeName) {
        this.treeName = treeName; // eslint-disable-line immutable/no-mutation
    }

    match(query, item, {leafOnly, folderOnly, disableRoot}) {
        const {
            title,
            label,
            'tspm_entity_id': entityId,
            'smid': alternativeEntityId,
        } = item;

        const inTitle = title.toLowerCase().indexOf(query) !== -1;
        const inId = entityId && entityId.toLowerCase().indexOf(query) !== -1;
        const inAltId = alternativeEntityId && alternativeEntityId.toLowerCase().indexOf(query) !== -1;
        const inLabel = label.toLowerCase().indexOf(query) !== -1;
        const filtered = (folderOnly && !item.isFolder) ||
                         (leafOnly && item.isFolder) ||
                         (disableRoot && !item.parent);

        return (inTitle || inId || inAltId || inLabel) && !filtered;
    }

    query(search, {list, ...params}) {
        const query = search.toLowerCase();
        const data = sortBy(list, (item)=> item.title.toLowerCase())
            .filter((item) => {
                const modifiedItem = {...item, label: titleForTreeSelect(item.id, list)};
                return this.match(query, modifiedItem, params);
            });
        if (!data.length) {
            return Promise.reject(`No ${this.treeName} found for "${search}"`);
        }

        return Promise.resolve(data.map((node)=> ({
            value: node.id,
            label: titleForTreeSelect(node.id, list),
        })));
    }
}

export class AgencyAutocomplete extends TreeAutocomplete {
    constructor() {
        super('agencies');
    }
}

export class CompanyAutocomplete extends TreeAutocomplete {
    constructor() {
        super('companies');
    }
}
