export function parse(data, {treeName}) {
    if (treeName === 'companies') {
        const paramValue = data['tripsource:credits_and_unused_tickets_override'] || {};
        return {
            'tripsource:credits_and_unused_tickets:override_enabled': paramValue['override_enabled'],
            'tripsource:credits_and_unused_tickets:override_value': paramValue['override_value'],
        };
    }

    return {
        'agency:tripsource:credits_and_unused_tickets:enabled': data['agency:tripsource:credits_and_unused_tickets'].enabled,
    };
}

export function serialize(data, {treeName}) {
    if (treeName === 'companies') {
        return {
            'tripsource:credits_and_unused_tickets_override': {
                'override_enabled': data['tripsource:credits_and_unused_tickets:override_enabled'],
                'override_value': data['tripsource:credits_and_unused_tickets:override_value'],
            },
        };
    }
    return {
        'agency:tripsource:credits_and_unused_tickets': {
            enabled: data['agency:tripsource:credits_and_unused_tickets:enabled']
        }
    };
};
