export const FIELDS = {
    fareFamilyCode: 'fareFamilyCode',
    fareOptions: 'fareOptions',
    services: 'services',
    ignoreClassOfService: 'ignoreClassOfService',
    departureCabin: 'departureCabin',
};

export const FARE_OPTIONS = {
    refundable: 'refundable',
    changeable: 'changeable',
    baggageIncluded: 'baggageIncluded',
    excludePenalties: 'excludePenalties',
};
