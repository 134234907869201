import partial from 'lodash/partial';
import {changeFormValue, sendChangesWithoutSaveModal} from 'airborne/store/modules/user_settings/actions/userSettings';
import {showForgotPasswordSuccess} from 'airborne/store/modules/login/actions/login';

export const changeForgotPassword = partial(changeFormValue, 'forgotPassword');

export function sendForgotPassword(history) {
    return function sendNewPasswordD(dispatch) {
        return dispatch(sendChangesWithoutSaveModal('forgotPassword', '/internal/accounts/request_password_reset/', 'POST'))
            .then((success) => {
                history.push('/ui/reset-success/');
                return success && dispatch(showForgotPasswordSuccess());
            });
    };
}
