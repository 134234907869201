import {mapDataDirect, mapDataReverse} from 'midoffice/helpers/types';
import {dropEmpty} from 'midoffice/helpers/urlParams';

const NAMES_MAP = {
    companies: [
        ['cars:negotiated_rates', 'cars:negotiated_rates'],
    ],
    agencies: [
        ['agency:cars:negotiated_rates', 'cars:negotiated_rates'],
    ]
};

export function parse(raw, {treeName}) {
    const data =  mapDataDirect(raw, NAMES_MAP[treeName]);
    return {
        'cars:negotiated_rates': data['cars:negotiated_rates'].map((code)=> {
            const {'billing_reference': billingRef={}, ...rest} = code;
            return {
                ...rest,
                'billing_reference': {
                    prefix: billingRef['prefix'] || null,
                    'tspm_property': billingRef['tspm_property'] || null,
                    'is_freetext': Boolean(
                        !billingRef['tspm_property'] && billingRef['prefix']
                    ),
                }
            };
        })
    };
}

export function serialize(raw, {treeName}) {
    const data = {
        'cars:negotiated_rates': raw['cars:negotiated_rates'].map((code)=> {
            const {'billing_reference': billingRef={}, ...rest} = code;
            return {
                ...rest,
                ...(billingRef['prefix'] || billingRef['tspm_property']
                    ? {
                        'billing_reference': dropEmpty({
                            'prefix': billingRef['prefix'],
                            'tspm_property': billingRef['is_freetext'] ? null : billingRef['tspm_property'] ,
                        })
                    }
                    : {}),
            };
        })
    };
    return mapDataReverse(data, NAMES_MAP[treeName]);
}
