import moment from 'moment';

function parseOne({id=null, 'from_ts': fromDate, 'updated_ts': updateDate, type='inbox', element=null, ...rest}, idx) {
    const now = moment.now();
    return {
        ...rest,
        id: (id === null) ? idx : id,
        type,
        element,
        active: now > moment(fromDate),
        whenNeedToShow: moment(fromDate) > now ? moment(fromDate) - now : null,
        fromDate: moment(fromDate),
        updateDate: moment(updateDate),
    };
}

export function parse(messages) {
    let inboxMessages = messages['inbox_messages'];
    return inboxMessages && inboxMessages.map(parseOne);
}
