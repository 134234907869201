import React from 'react';
import PropTypes from 'prop-types';
import {RichUtils, EditorState, Entity} from 'draft-js';
import {default as Button, ButtonGroup} from './Button';
import {getSelectedEntityBoundaries, createSelection, getEntityAtCursor} from '../utils';
import AddLinkButton from './AddLinkButton';

function findLinkEntities(contentBlock, callback) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                Entity.get(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

class Link extends React.Component {
    static propTypes = {
        entityKey: PropTypes.string.isRequired,
        children: PropTypes.any,
    };

    render() {
        const {entityKey, children} = this.props;
        const {url} = Entity.get(entityKey).getData();

        return (
            <a href={url} target="_blank">
                {children}
            </a>
        );
    }
}

export const decorator = {
    strategy: findLinkEntities,
    component: Link,
};

export function getLinkAtCursor(editorState) {
    const entityKey = getEntityAtCursor(editorState);

    if (!entityKey) { return null; }
    const entity = Entity.get(entityKey);

    return entity && entity.getType() === 'LINK' ? entity : null;
}

export function getLinkUrl(entity) {
    if (!entity) { return null; }
    return entity.getData().url;
}


export class RemoveLinkButton extends React.Component {
    static propTypes = {
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const {onChange, editorState} = this.props;
        const {start, end} = getSelectedEntityBoundaries(editorState);
        const selection = createSelection(start, end);

        onChange(RichUtils.toggleLink(
            editorState,
            selection,
            null,
        ));
    }

    render() {
        const {disabled, editorState, ...props} = this.props;
        const hasLink = Boolean(getLinkAtCursor(editorState));

        return (
            <Button icon="remove-link"
                {...props}
                onClick={this.handleClick}
                disabled={disabled || !hasLink} />
        );
    }
}


export default (props)=> (
    <ButtonGroup>
        <AddLinkButton {...props} />
        <RemoveLinkButton {...props} />
    </ButtonGroup>
);
