import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';
import isEqual from 'lodash/isEqual';

import Autocomplete from './Autocomplete';

@injectField
export default class SelectAutocomplete extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        clearOnFocus: PropTypes.bool,
    };

    static defaultProps = {
        clearOnFocus: false,
    }

    componentDidMount() {
        const {clearOnFocus} = this.props;
        clearOnFocus && addEventListener('blur', (event) => {
            this.setState({focusChanged: true});
        });
    }

    state = {uncommited: null, focusChanged: false}

    focus() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    handleCommit = (value) => {
        this.setState({uncommited: null});
        this.props.onChange(value, this.props.name);
        this.value = value;
    }

    handleChange = (value) => {
        if (value === '') {
            this.props.onChange(null, this.props.name);
        }
        this.setState({uncommited: value || null});
    }

    handleBlur = () => {
        const {onChange, clearOnFocus, name, value} = this.props;

        this.setState({uncommited: null});
        this.props.onBlur && this.props.onBlur();

        if (clearOnFocus && !isEqual(this.value, value)) {
            onChange(this.value, name);
        }
    }

    handleReference = (input) => {
        this.inputRef = input;
    }

    handleFocus = () => {
        const {clearOnFocus, onChange, value} = this.props;
        if (clearOnFocus && !this.state.focusChanged) {
            this.value = value;
            onChange(null, this.props.name);
        }

        this.setState({focusChanged: false});

        return null;
    }

    render() {
        let {value} = this.props;
        let {uncommited} = this.state;

        value = uncommited === null ? (value ? value.label : null)
            : uncommited;
        return (<Autocomplete
            {...this.props}
            ref={this.handleReference}
            value={value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onCommit={this.handleCommit}
        />);
    }
}
