import React from 'react';


import PropTypes from 'prop-types';


export default class SimpleForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any.isRequired,
    };

    static defaultProps = {
        className: 'form-horizontal',
    };

    static contextTypes = {
        onSubmit: PropTypes.func,
        onReset: PropTypes.func,
    };

    render() {
        const {children, ...props} = this.props;
        const {onSubmit, onReset} = this.context;

        return (
            <form onSubmit={onSubmit} onReset={onReset} {...props}>
                {children}
            </form>
        );
    }
}
