import remove from 'lodash/remove';
import {ADDED, LOADED, LOADING, REMOVED} from '../actions/types';

function initialState() {
    return {
        list: []
    };
}

function reportAdded(state, action) {
    return {
        ...state,
        loaded: true,
        list: [action.data, ...state.list]
    };
}

function reportRemoved(state, action) {
    let list = [...state.list];
    remove(list, {'id': action.id});
    return {
        ...state,
        loaded: true,
        list
    };
}

function reportsLoaded(state, {data}) {
    return {
        ...state,
        loaded: true,
        list: data || [],
    };
}

function reportLoading(state) {
    return {
        ...state,
        loaded: false,
    };
}

export default function reports(state, action) {
    if (!state) {
        return initialState();
    }

    if (action.type === ADDED) {
        return reportAdded(state, action);
    }

    if (action.type === REMOVED) {
        return reportRemoved(state, action);
    }

    if (action.type === LOADED) {
        return reportsLoaded(state, action);
    }

    if (action.type === LOADING) {
        return reportLoading(state, action);
    }

    return state;
}

