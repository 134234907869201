import {NON_FIELD_ERRORS_KEY} from './constants';


/** An error thrown during validation */
export class ValidationError {
    constructor(detail, code) {
        if (detail instanceof ValidationError) {
            detail = ValidationError.getErrorDetail(detail);
        }

        this.detail = detail;
        this.code = code;
    }

    static getErrorDetail({detail}) {
        if (typeof detail !== 'object') {
            return {[NON_FIELD_ERRORS_KEY]: [detail]};
        }

        return detail;
    }
}


/** An error thrown to signal that a field should be omitted */
export class SkipField {}
