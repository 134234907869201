import reduce from 'lodash/reduce';
import omit from 'lodash/omit';

function isContractCodeField(key) {
    return key.includes('negotiated_fares:contract_codes');
}
function isCorporateRewardProgramsField(key) {
    return key.includes('negotiated_fares:corporate_reward_programs');
}

function parseContractCodeField(values) {
    return values.map(value => value?.code);
}

function serializeContractCodeField(values) {
    return values.map(value => ({code: value}));
}

export function serialize(params) {
    return reduce(
        omit(params, ['air_corporate_reward_programs_choices', 'policyGroupInfo']),
        (acc, value, key) => ({
            ...acc,
            [key]: isContractCodeField(key)
                ? serializeContractCodeField(value)
                : isCorporateRewardProgramsField(key) ?
                    {programs: value} :
                    value
        }),
        {}
    );
}

export function parse(params) {
    return reduce(
        params,
        (acc, value, key) => ({
            ...acc,
            [key]: isContractCodeField(key)
                ? parseContractCodeField(value)
                : isCorporateRewardProgramsField(key) ?
                    value.programs :
                    value,
        }),
        {}
    );
}
