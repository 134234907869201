import every from 'lodash/every';
import flow from 'lodash/flow';
import identity from 'lodash/identity';
import intersection from 'lodash/intersection';
import some from 'lodash/some';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import {PropTypes} from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import {createSelector} from 'reselect';

import settings from 'airborne/settings';
import systemData from 'airborne/systemData';
import {NEED_TO_PERMISSIONS, PERMISSION_TO_FILTER} from 'midoffice/helpers/permissionsConstants';

export const getGroupTree = createSelector(
    () => systemData.common.PERMISSIONS_PER_GROUP,
    permissionsPerGroup =>
        Object.keys(permissionsPerGroup).map(item => ({
            code: item,
            title: item,
        }))
);

export function flattenTree(nodes = [], processor = identity) {
    return nodes.reduce((acc, {children, ...node}) => {
        const flattenNodes = children ? flattenTree(children) : [node];
        return [
            ...acc,
            ...flattenNodes.map(flattenNode => {
                const prevTitle = flattenNode.prevTitle
                    ? `${node.title} -> ${flattenNode.prevTitle}`
                    : flattenNode.title;
                return processor({
                    ...flattenNode,
                    prevTitle,
                });
            }),
        ];
    }, []);
}

export const getTreeNodeByCode = createSelector(
    tree => tree,
    tree => {
        const codeMapper = flattenTree(tree).reduce((acc, item) => ({...acc, [item.code]: item}), {});
        return function(code) {
            return codeMapper[code];
        };
    }
);

export const getGroupPermissions = group => {
    if (group === 'superuser') {
        return flattenTree(systemData.common.ALL_PERMISSIONS).map(({code}) => code);
    }

    if (group === 'RESTRICTED_PERMISSIONS') {
        return systemData.common.RESTRICTED_PERMISSIONS;
    }

    return systemData.common.PERMISSIONS_PER_GROUP[group] || [];
};

const getSuperAdmin = value => (value['is_superuser'] ? ['superuser'] : []);

export const getDefaultPemissions = value =>
    flow(
        value => [...value['groups'], ...getSuperAdmin(value)],
        groups => groups.map(getGroupPermissions),
        groups => union(...groups),
        uniq
    )(value);

export function getPemissionNodeByCode(code) {
    return getTreeNodeByCode(systemData.common.ALL_PERMISSIONS)(code);
}

export function isDependenciesChecked(checkedPemission) {
    return function(code) {
        const node = getPemissionNodeByCode(code);
        const {dependencies} = node;

        return (
            dependencies.length === 0 ||
            every(
                dependencies,
                dependency => checkedPemission.includes(dependency) && isDependenciesChecked(dependency)
            )
        );
    };
}

function getGranularPermissions() {
    return (settings.USER_ALL_PERMISSIONS || []).filter(item => PERMISSION_TO_FILTER.indexOf(item) === -1);
}
const paternToRegExp = patern => patern.replace('*', '.*');
const isMatchingPattern = patern => str => str.match(paternToRegExp(patern));
const isPatern = str => str.includes('*');

export function hasAccess(need = [], permissions = getGranularPermissions()) {
    if (Object.keys(NEED_TO_PERMISSIONS).includes(need)) {
        need = NEED_TO_PERMISSIONS[need];
    }

    const needArray = Array.isArray(need) ? need : [need];
    return (
        intersection(permissions, needArray).length > 0 ||
        some(needArray, need => isPatern(need) && some(permissions, isMatchingPattern(need)))
    );
}

export function hasStrictAccess(need, permissions = getGranularPermissions()) {
    const needArray = Array.isArray(need) ? need : [need];
    return every(needArray, need => permissions.includes(need));
}

export function getGroupPermissionByAction(groupTitle, action) {
    if (groupTitle === 'RESTRICTED_PERMISSIONS') {
        return 'midoffice:restricted_permissions:edit';
    }

    const convertedTitle = groupTitle.toLowerCase().replaceAll(' ', '_');
    return `midoffice:groups:${convertedTitle}:${action}`;
}

export function canEditUser(user) {
    const groupEditPermissions =
        user.groups.length === 0
            ? ['midoffice:groups:user_without_group:edit']
            : user.groups.map(group => getGroupPermissionByAction(group, 'edit'));
    return hasStrictAccess(groupEditPermissions);
}

export function getAllPermissionCodes() {
    const flattenPermissionTree = flattenTree(systemData.common.ALL_PERMISSIONS || []);
    return flattenPermissionTree.map(({code}) => code);
}

export function getAllowedPermissions() {
    const permissionCodes = getAllPermissionCodes();

    const getGroupPermissions = group => {
        if (group === 'superuser') {
            return permissionCodes;
        }

        if (group === 'RESTRICTED_PERMISSIONS') {
            return systemData.common.RESTRICTED_PERMISSIONS;
        }

        return systemData.common.PERMISSIONS_PER_GROUP[group] || [];
    };

    const addDependencies = permissions =>
        uniq([
            ...permissions,
            ...permissions.reduce((acc, code) => {
                const {dependencies} = getPemissionNodeByCode(code);
                return [...acc, ...addDependencies(dependencies)];
            }, []),
        ]);

    const hasGroupAccess = type => groupTitle => hasAccess(getGroupPermissionByAction(groupTitle, type));

    const groupTree = getGroupTree();
    const groups = [...groupTree.map(({code}) => code), 'RESTRICTED_PERMISSIONS'];

    return flow(
        titles => titles.filter(hasGroupAccess('edit')),
        groupNames => (settings.USER.is_superuser ? [...groupNames, 'superuser'] : groupNames),
        groupNames => groupNames.map(getGroupPermissions),
        groups => union(...groups),
        addDependencies
    )(groups);
}

export function haveNotEnoughPermissions(user) {
    const groupEditPermissions =
        user.groups.length === 0
            ? ['midoffice:groups:user_without_group:edit']
            : user.groups.map(group => getGroupPermissionByAction(group, 'edit'));
    return hasAccess(groupEditPermissions) && !hasStrictAccess(groupEditPermissions);
}

export function toGranularPermissions(route, need) {
    const WrappedComponent = () => {
        const permissions = settings.USER_ALL_PERMISSIONS || [];
        if (!hasAccess(need, permissions)) {
            const {
                key,
                props: {exact, path},
            } = route;
            return (
                <Route exact={exact} path={path} key={key}>
                    <Redirect to="/" />
                </Route>
            );
        }
        return route;
    };

    return <WrappedComponent key={route.key} />;
}

@connect(() => ({permissions: getGranularPermissions()}))
export class Permission extends React.Component {
    static propTypes = {
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        need: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        children: PropTypes.any,
    };

    getNeed() {
        const {need} = this.props;
        return (Array.isArray(need) ? need : [need]).filter(item => item);
    }

    hasAccess() {
        const need = this.getNeed();
        return need.length === 0 || hasAccess(need, this.props.permissions);
    }

    render() {
        const hasAccess = this.hasAccess();
        const {children} = this.props;
        if (typeof children === 'function') {
            return children(hasAccess);
        }

        return hasAccess ? children : null;
    }
}
