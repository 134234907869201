import settings from 'airborne/settings';
import moment from 'moment';
import fromPairs from 'lodash/fromPairs';

import {createSchema, required} from 'midoffice/newforms/helpers';
import {Field, DateField, MultiChoiceField, BooleanField} from 'midoffice/newforms/fields-stateless';
import {Schema} from 'midoffice/newforms/schema-stateless';

import {DATE_FORMAT} from 'midoffice/data/types/aftMessaging';
import {LANGUAGES} from 'midoffice/helpers/languages';


const {USER} = settings;

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const SYSTEMS = [
    ['aft', 'AFT'],
    ['admin', 'ADMIN'],
];
export const SYSTEM_LABELS = fromPairs(SYSTEMS);
export const DATE_TIME_FORMAT = `${settings.USER.date_format_str || DEFAULT_DATE_FORMAT}, HH:mm [UTC]`;


const langFields = LANGUAGES.reduce((acc, [id])=> {
    const field = id === 'en' ? required(Field) : Field;
    return {
        ...acc,
        [`text-${id}`]: field
    };
}, {});


const MessageEditSchema = createSchema({
    fields: {
        'now': Field,
        from: required({
            ...DateField,
            format: DATE_FORMAT,
            inputFormat: USER.date_format_str,
        }),
        to: required({
            ...DateField,
            format: DATE_FORMAT,
            inputFormat: USER.date_format_str,
        }),
        systems: required(MultiChoiceField),
        ...langFields,
        onlyDefaultLang: BooleanField,
    },

    validate(value, isNew) {
        const errors = Schema.validate.call(this, value);
        if (errors) { return errors; }

        const now = moment();
        let {to, from, initialFrom} = value;
        to = moment.utc(to); from = moment.utc(from); initialFrom = moment.utc(initialFrom);

        if (to.isBefore(now)) {
            return {to: 'End Date should not be in past'};
        }
        if (isNew && from.isBefore(now)) {
            return {from: 'Start Date should not be in past'};
        }
        if (!isNew && from.isBefore(now) && !from.isSame(initialFrom)) {
            return {from: 'Start Date cannot be changed to past'};
        }
        if (to.isSameOrBefore(from)) {
            return {to: 'End Date should be later than Start Date'};
        }

        return null;
    },
});


export default MessageEditSchema;
