import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Autocomplete from 'midoffice/newforms/widgets/Autocomplete';

export default class UserInput extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func,
    };

    static defaultProps = {
        value: null,
        onKeyDown: noop,
    };

    constructor(props) {
        super(props);
        this.changeInput = this.changeInput.bind(this);
        this.commit = this.commit.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            text: null
        };
    }

    changeInput(value) {
        this.setState({text: value});
    }

    commit(value) {
        this.setState({text: null});
        this.props.onChange(value);
    }

    handleKeyDown(event) {
        let {text} = this.state;
        switch (event.key) {
            case 'Backspace':
                if (!text) {
                    this.setState({text: null});
                    this.props.onChange(null);
                }
                return;
        }
        this.props.onKeyDown(event);
    }

    render() {
        let {value} = this.props;
        value = this.state.text || (value ? value.label : null);
        return (<Autocomplete {...this.props}
                              onCommit={this.commit}
                              onChange={this.changeInput}
                              onKeyDown={this.handleKeyDown}
                              value={value} />);
    }
}
