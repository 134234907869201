import gettext from 'airborne/gettext';
import {createSchema, required} from 'midoffice/newforms/helpers';
import {EmailField} from 'midoffice/newforms/fields-stateless';

const ForgotPasswordSchema = createSchema({
    fields: {
        'email': required(EmailField, gettext('This field is required')),
    }
});

export default ForgotPasswordSchema;
