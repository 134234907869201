import api from 'midoffice/helpers/api';
import {parse} from 'airborne/types';

import {retry} from 'airborne/checkout2/helpers/retry';
import {getCarBookingUID} from 'airborne/store/modules/confirm_cars/selectors';

const RETRY_TIMEOUT = 3000;
const MANUAL_RETRIES = 3;  // retry on throw RETRY
const AUTO_RETRIES = 3;  // retry on any error

export const fetchBooking=retry(function fetchBooking() {
    return function (dispatch, getState) {
        const uid = getCarBookingUID(getState());
        dispatch({type: 'CARS_BOOKING_DETAILS_LOADING'});

        const url = `/latest/cars/get_reservation_details/?booking_uid=${uid}&system=aft`;

        return api('GET', url)
            .then((data)=> {
                const parsed = parse('carBookingDetails', data);
                dispatch({type: 'CARS_BOOKING_DETAILS_LOADED', data: parsed});
                return parsed;
            }, (response)=> {
                dispatch({type: 'CARS_BOOKING_DETAILS_FAIL', data: response});
            });
    };
}, RETRY_TIMEOUT, MANUAL_RETRIES, AUTO_RETRIES);
