import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/fp/some';
import intersection from 'lodash/fp/intersection';
import paginate from 'midoffice/helpers/paginate';

import {applyAirFilters, countTotalFlights} from 'airborne/search_air/helpers';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';

import {isCheckoutLoading} from './checkout';
import {getFareGroupKey} from './fareKeys';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';

export const getFareGroups = state => state.air.fareGroups.data;
export const isAnyFareGroups = createSelector(
    getFareGroups,
    fareGroups => Boolean(fareGroups.length)
);

export const isFaresLoading = state => state.air.fareGroups.loading;

export const getAirFilters = state => {
    return state.air.filters;
};

export const getFiltersExclusions = state => state.air.filtersExlusions;

export const getFilteredFareGroups = createSelector([
    getFareGroups,
    getAirFilters,
    getFiltersExclusions,
], (fareGroups, filters, exclusions) => applyAirFilters(fareGroups, filters, exclusions));

export const getFullFlightsCount = createSelector(
    getFareGroups,
    countTotalFlights
);

export const getFilteredFlightsCount = createSelector(
    getFilteredFareGroups,
    countTotalFlights
);

export const getRemnantFlightsCount = createSelector(
    getFullFlightsCount,
    getFilteredFlightsCount,
    (fullCount, filteredCount) => fullCount - filteredCount,
);

export const getCurrentPage = state => state.air.currentPage;

const GROUPS_PER_PAGE = 10;

export const getVisibleFareGroups = createSelector(
    getFilteredFareGroups,
    getCurrentPage,
    (groups, page) => {
        return paginate(groups, null, {page, 'page_size': GROUPS_PER_PAGE}).paged;
    }
);

export const getTotalGroups = state => getFilteredFareGroups(state).length;

export const getPagesTotal = createSelector(
    getFilteredFareGroups,
    groups => Math.ceil(groups.length / GROUPS_PER_PAGE)
);

export const isAnyFilterApplied = createSelector(
    getAirFilters,
    filters => some(filter => filter && (!isEmpty(filter) || typeof filter === 'number' || typeof filter === 'boolean'))(filters)
);

export const isBookButtonDisabled = (
    state,
    {fareGroupKey, originDestinations}
) =>
    (isCheckoutLoading(state) && getFareGroupKey(state) !== fareGroupKey) ||
    some(OD => !OD.flightOptions.length, originDestinations);

export const isBookButtonLoading = (state, {fareGroupKey}) =>
    isCheckoutLoading(state) && getFareGroupKey(state) === fareGroupKey;

export const getODByIndex = createSelector(
    getDestination,
    (state, ODIndex) => ODIndex,
    ({originDestinations, tripType}, ODIndex) => {
        const [{pickUp, dropOff}] = originDestinations;
        return (tripType === AIR_TRIP_TYPES.MULTI_CITY
            ? originDestinations.map(({pickUp, dropOff}) => ({origin: pickUp.iataCode, destination: dropOff.iataCode}))
            : [
                {origin: pickUp.iataCode, destination: dropOff.iataCode},
                {origin: dropOff.iataCode, destination: pickUp.iataCode},
            ])[ODIndex];
    }
);

export const makeGetPinFlightOptionStatus = (flightNumbers, ODIndex) => createSelector(
    getAirFilters,
    ({pinnedFlight}) => {
        if (!pinnedFlight) {
            return 'unpinned';
        }
        const cross = intersection(flightNumbers, pinnedFlight[ODIndex]).length;
        if (cross === 0) {
            return 'unpinned';
        }
        return cross === flightNumbers.length
            ? 'pinned'
            : 'semi-pinned';
    }
);

export const makeGetIsSegmentPinned = (flightNumber, ODIndex) => createSelector(
    getAirFilters,
    ({pinnedFlight}) => Boolean(pinnedFlight?.[ODIndex]?.includes(flightNumber)),
);

export const getFareGroupByKey = (state, fareGroupKey) =>
    getFilteredFareGroups(state).find(group => group.fareGroupKey === fareGroupKey);

export const isValidFareGroup = (state, fareGroupKey) => {
    const group = getFareGroupByKey(state, fareGroupKey);

    return group?.originDestinations.every(OD => !!OD.flightOptions[0]);
};

export const isAnyValidFareGroupByOD = createSelector(
    getFilteredFareGroups,
    (_, ODIndex) => ODIndex,
    (groups, ODIndex) =>
        groups.some(group => !!group.originDestinations?.[ODIndex].flightOptions.length)
);
