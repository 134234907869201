import {serializeDataCollection} from 'midoffice/data/types/settings/helpers';
import isEmpty from 'lodash/isEmpty';

const SETTINGS_BY_TREE = {
    companies: 'cars:geography_preferred_rates',
    agencies: 'agency:cars:preferred_rates',
};

export function parse(raw, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    if (treeName === 'companies') {
        const data = raw[setting].map((rule) => {
            return {
                ...rule,
                globalDestination: isEmpty(rule.destinations)
            };
        });
        return {
            'cars:geography_preferred_rates': data
        };
    }
    return raw[setting];
}

function getPreferredRates(data) {
    const vendors = serializeDataCollection(data['vendors']);

    return {
        'car_types': data['car_types'] || [],
        'vendors':  vendors.filter(obj => !(obj && Object.keys(obj).length === 0))
    };
}

export function serialize(data, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    if (treeName === 'companies') {
        const serializedData = data['cars:geography_preferred_rates'].map((rule) => {
            if (rule.globalDestination) {
                return {'label': rule['label'], 'preferred_rates': getPreferredRates(rule['preferred_rates'])};
            }
            return {
                'label': rule['label'],
                'preferred_rates': getPreferredRates(rule['preferred_rates']),
                'destinations': rule['destinations'],
            };
        });
        return {[setting]: serializedData};
    };
    return {
        [setting]: getPreferredRates(data)
    };
}
