import {i18nState} from '../types';
import get from 'lodash/get';

export function getI18N(state: i18nState): i18nState {
    return get(state, 'i18n');
}

export function getCurrency(state: i18nState): string {
    return get(state, 'i18n.currency');
}

export function getLang(state: i18nState): string {
    return get(state, 'i18n.lang');
}
