import mapValues from 'lodash/mapValues';
import every from 'lodash/every';
import reduceReducers from 'airborne/utils/reduceReducers';
import hash from 'midoffice/data/store/hash';

function markRead(message) {
    return {...message, 'new': false};
}

function match(message, query) {
    if (!query) return true;
    return every(
        Object.entries(query)
            .map(
                ([queryKey, queryValue])=> (message[queryKey] === queryValue)
            )
    );
}

function read(state, {type, query}) {
    if (type === 'MARK_READ') {
        return {
            ...state,
            hash: mapValues(state.hash, (message)=> (
                match(message, query) ? markRead(message) : message
            )),
        };
    }
    return state;
}

export default reduceReducers(hash, read);
