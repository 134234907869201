import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

/**
 * Returns size in Bytes of the resulting JSON from the target value
 *
 * @param target - Any value that's going to be converted to JSON
 * @returns Size of the target in Bytes
 */
export const getJsonSize = (target: unknown): number => {
    const jsonTarget = JSON.stringify(target);
    const encoder = new TextEncoder();
    const bytesArray = encoder.encode(jsonTarget);

    return bytesArray.length;
};

/**
 * Returns "guessed" size in Bytes based on the 3 elements from the start, middle, end of the array.
 * It's helpful for large arrays, to make quick assumption of the size of the array, without converting/iterating
 * over each element. Keep in mind, that this assumption will work properly and close to the real size
 * only in case all elements have same interface and relatively the same size.
 *
 * @param target - Array with any elements
 * @returns Rough size of the array in Bytes
 */
export const getRoughArraySize = (target: unknown[]): number => {
    const {
        length,
        [0]: first,
        [Math.floor(length / 2)]: middle,
        [length - 1]: last
    } = target;

    const sample = [first, middle, last];
    const sampleSize = getJsonSize(sample);

    return sampleSize / sample.length * length;
};

/**
 * Returns if rough size of the array exceeds the maximum size, provided in Megabytes
 *
 * @see {@link getRoughArraySize} for the way the "rough" size is calculated
 * @param target - Array with any elements
 * @param maxSize - Maximum allowed array size in Megabytes
 * @returns If array size exceeds the `maxSize`
 */
const isLargeArray = (target: unknown[], maxSize: number): boolean => {
    const jsonSizeBytes = getRoughArraySize(target);
    const jsonSizeMegabytes = jsonSizeBytes / Math.pow(1024, 2);

    return jsonSizeMegabytes >= maxSize;
};

/**
 * Returns if one of the array in the target collection most likely exceeds the provided maximum size in Megabytes.
 *
 * You can either pass an object with desired array somewhere in the tree, or an array, in which case,
 * only top level elements of the array will be checked (until pass `depth` argument).
 *
 * It's not deep for arrays (until you pass `depth`), it will flow only on the first level of the array.
 * It's deep for objects tho, it's going to dive inside all keys until find an array.
 * Ignores any other data types except objects/arrays
 *
 * @see {@link isLargeArray} for the way we decide if the array is most likely larger than provided maximum size
 * @param target - Target object/array of objects
 * @param maxSize - Approximately maximum allowed size in Megabytes for the biggest array in the collection
 * @param depth - Amount of array levels will be ignored for the size check
 * @returns If one of the arrays in the target collection most likely exceeds the `maxSize`
 */
export const isLargeArrayInCollection = (target: unknown, maxSize: number, depth = 0): boolean => {
    if (isArray(target) && depth < 1) {
        return isLargeArray(target, maxSize);
    }

    if (isObject(target)) {
        for (const key in target) {
            const current = (target as Record<string, unknown>)[key];
            const currentDepth = isArray(target) ? depth - 1 : depth;
            const result = isLargeArrayInCollection(current, maxSize, currentDepth);

            if (result) return result;
        }
    }

    return false;
};
