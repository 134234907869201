import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {selectError} from 'midoffice/newforms/helpers';


export default class FieldError extends React.Component {
    static propTypes = {
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
            PropTypes.bool
        ]),
        position: PropTypes.oneOf(['normal', 'top', 'bottom']),
        asBlock: PropTypes.bool,
    };

    static defaultProps = {
        asBlock: false,
    };

    render() {
        const {errors, position, asBlock} = this.props;
        if (!errors) {
            return null;
        }
        const message = selectError(errors);

        const className = classnames({
            'form-group__error': !asBlock,
            'form-group__error--bottom': !asBlock && position === 'bottom',
            'form-group__error--top': !asBlock && position === 'top',
            'alert alert-danger': asBlock,
        });
        return (
            <div className={className}>{gettext(message)}</div>
        );
    }
}
