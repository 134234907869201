import React from 'react';
import PropTypes from 'prop-types';
import {RichUtils, EditorState} from 'draft-js';
import {default as Button, ButtonGroup} from './Button';


export class BlockTypeButton extends React.Component {
    static propTypes = {
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        type: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onChange(
            RichUtils.toggleBlockType(
                this.props.editorState,
                this.props.type
            )
        );
    }

    render() {
        const {editorState, type, ...props} = this.props;

        const selection = editorState.getSelection();
        const blockType = editorState
              .getCurrentContent()
              .getBlockForKey(selection.getStartKey())
              .getType();

        return (
            <Button {...props}
                    icon={type}
                    active={blockType === type}
                    onClick={this.handleClick} />
        );
    }
}


export const Blockquote = (props)=> (
        <BlockTypeButton {...props} type="blockquote" />
);

export const UL = (props)=> (
        <BlockTypeButton {...props} type="unordered-list-item" />
);

export const OL = (props)=> (
        <BlockTypeButton {...props} type="ordered-list-item" />
);


export default class BlockTypeGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const {className='', ...props} = this.props;

        return (
            <ButtonGroup {...props} className={className}>
                <Blockquote {...props} />
                <UL {...props} />
                <OL {...props} />
            </ButtonGroup>
        );
    }
}
