import React from 'react';
import PropTypes from 'prop-types';
import Input from 'midoffice/newforms/widgets/Input';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import {injectField} from 'midoffice/newforms/decorators';
import {hasAccess} from 'midoffice/helpers/permission';

@injectField
export default class SecretInput extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };

    state = {
        show: false
    };

    getPermissionForShowSecret() {
        return hasAccess('midoffice:access_to_secrets:view');
    };

    static getDerivedStateFromProps(props, state) {
        if (props.disabled && state.show) {
            return {show: false};
        }
        return null;
    }

    handleSecret = (value) => {
        this.setState({show: value});
    }

    render() {
        let {show} = this.state;
        let {disabled, name} = this.props;
        const shouldShowSecretCheckbox = this.getPermissionForShowSecret();

        return (<div className="form-group--sub-link">
            <Input {...this.props}
                inputSize="max"
                type={(show && !disabled) ? 'text' : 'password'} />
            {shouldShowSecretCheckbox ?
                <Checkbox value={disabled ? false : show}
                    name={name+':secret'}
                    disabled={disabled}
                    label="Show Secret"
                    className="checkbox-inline"
                    onChange={this.handleSecret} /> : null
            }
        </div>);
    }
}
