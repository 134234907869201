import {
    getCheckoutFrequentFlyerNumbers,
    getSelectedOptionalServices,
} from 'airborne/store/modules/checkout_air/selectors/checkout';
import flow from 'lodash/flow';
import xor from 'lodash/xor';
import {getLastRequestParamsByKeys, getFlightPriceByKeys} from 'airborne/store/modules/search_air/selectors';

// We need this flightPrice selector inside checkout to prevent circular dependency
// checkout selectors are using flight price selectors, and this flightPrice selectors needs checkout ones :)
export const shouldUpdateFlightPriceByKeys = (state, fareGroupKey, flightOptionKeys, ticketIndex) => {
    const flightPrice = getFlightPriceByKeys(state, fareGroupKey, flightOptionKeys);

    if (!flightPrice) return true;

    const selectedServicesKeys = getSelectedOptionalServices(state, ticketIndex);

    const shouldUpdateFlightPrice = flow(
        ({requestedServices}) => requestedServices || [],
        (requestedServices) => requestedServices.map(({serviceKey}) => serviceKey),
        requestedServiceKeys => xor(requestedServiceKeys, selectedServicesKeys),
        diff => diff.length > 0,
    );

    const ftNumbers = getCheckoutFrequentFlyerNumbers(state);
    const lastRequestParams = getLastRequestParamsByKeys(state, fareGroupKey, flightOptionKeys);

    const alreadyLoaded = (
        Boolean(flightPrice)
        && !shouldUpdateFlightPrice(flightPrice)
        // We're comparing object reference because in case ftNumbers didnt' change
        // the object will be the same
        && lastRequestParams?.ftNumbers === ftNumbers
    );

    return !alreadyLoaded;
};
