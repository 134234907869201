import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {applyMiddleware} from 'redux';
import {compare2Levels} from 'airborne/helpers/compareList';

export const persistMiddleware = store=> {
    let oldState = null;
    let selector = null;
    let saver = null;
    return next=> action => {
        const ret = next(action);

        if (action.type === 'GLOBAL_RESTORE') {
            selector = action.selector;
            saver = action.saver;
            oldState = selector(store.getState());
            saver(oldState);
            return ret;
        }

        const changed = selector && selector(store.getState());
        if (saver && !compare2Levels(changed, oldState)) {
            saver(changed);
            oldState = changed;
        }

        return ret;
    };
};

const wrapReducers = next=> (reducer, initialState) => next(
    function persistent(state, action) {
        let ret = reducer(state, action);
        if (action.type === 'GLOBAL_RESTORE' && action.data) {
            ret = cloneDeep(ret);
            action.data.forEach(([fn, ...args])=> {
                if (fn === 'set') {
                    set(ret, ...args);
                }
            });
            return ret;
        }

        return reducer(state, action);
    },
    initialState
);

export function makePersistent(next) {
    return applyMiddleware(persistMiddleware)(
        wrapReducers(next)
    );
}
