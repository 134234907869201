import omit from 'lodash/omit';
import {AIRLINE_CODE_ALL_AIRLINES} from 'midoffice/data/schemas/AirOSIMessagingSchema';

export function parse(data) {
    return {
        ...data,
        'air:osi_messaging': data['air:osi_messaging'].map((item) => {
            return {
                ...item,
                'all_airlines': item['airline_code'] === AIRLINE_CODE_ALL_AIRLINES
            };
        })
    };
}

function omitEmptyPrefixes(prefixGroups) {
    return prefixGroups.map(({code, freetext}) => ({
        freetext,
        ...(code && {code})
    }));
}

export function serialize(data) {
    const items = data['air:osi_messaging'].map((value) => {
        const {'all_airlines': allAirlines, 'airline_code': airlineCode} = value;
        const prefixGroups = omitEmptyPrefixes(value['prefix_groups']);

        return omit({
            ...value,
            'airline_code': allAirlines ? AIRLINE_CODE_ALL_AIRLINES : airlineCode,
            'prefix_groups': prefixGroups
        }, ['all_airlines']);
    });

    return omit(
        {
            'air:osi_messaging': items,
        },
        ['air_osi_messaging_freetext_choices']
    );
}
