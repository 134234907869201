import {OPTION_KEY_DOWN, OPTION_KEY_UP} from '../actionTypes';

const initialState = {
    activeOptionKey: false,
};

export default function navigationShortcuts(state= initialState, {type}) {
    if (type === OPTION_KEY_UP) {
        return {activeOptionKey: false};
    }
    if (type === OPTION_KEY_DOWN) {
        return {activeOptionKey: true};
    }
    return state;
}
