export const isDirectFareGroup = fareGroup => {
    const {originDestinations} = fareGroup;

    return originDestinations.every(originDestination => {
        return originDestination.flightOptions.every(flightOption => {
            return flightOption.segments.length === 1;
        });
    });
};

export const isOneStopFareGroup = fareGroup => {
    const {originDestinations} = fareGroup;

    return (
        originDestinations.every(originDestination => {
            return originDestination.flightOptions.every(flightOption => {
                return flightOption.segments.length <= 2;
            });
        }) &&
        originDestinations.some(originDestination => {
            return originDestination.flightOptions.some(flightOption => {
                return flightOption.segments.length > 1;
            });
        })
    );
};

export const isTwoOrMoreStopsFareGroup = (fareGroup) => {
    const {originDestinations} = fareGroup;

    return originDestinations.some(originDestination => {
        return originDestination.flightOptions.some(flightOption => {
            return flightOption.segments.length >= 3;
        });
    });
};

export const applyStopsFilter = (fareGroup, filters) => {
    const {stops} = filters;
    if (!stops?.length) {
        return true;
    }

    let include = false;

    if (stops.includes(1) && isDirectFareGroup(fareGroup)) {
        include = true;
    }

    if (stops.includes(2) && isOneStopFareGroup(fareGroup)) {
        include = true;
    }

    if (stops.includes(3) && isTwoOrMoreStopsFareGroup(fareGroup)) {
        include = true;
    }

    return include;
};
