import {register} from 'airborne/types';
import normalize from 'airborne/search2/helpers/normalize';

import {parse as parseRate} from 'airborne/search_cars/types';


function parse(rawData) {
    const data = rawData.booking || rawData;
    const {rate, ...rest} = normalize(data);

    return {
        rate: {
            ...parseRate(rate),
            freetext: data.freetext,
        },
        ...rest
    };
}

register('carBookingDetails', {parse});
