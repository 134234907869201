import flatten from 'lodash/flatten';
import get from 'lodash/get';

import COMMON_KEYS from './common';
import HOTELS_KEYS from './hotels';
import CARS_KEYS from './cars';
import AIR_KEYS from './air';

import {isAirExchangeException} from './air';

function restoreKeys(keys, path, state) {
    const substate = get(state, path);
    if (Array.isArray(substate)) {
        return flatten(substate.map((part, index)=> (
            restoreKeys(keys, [...path, index], state)
        )));
    }

    return flatten(keys.map((key)=> {
        if (Array.isArray(key)) {
            const [nextPrefix, subkeys] = key;
            return restoreKeys(subkeys, [...path, ...nextPrefix.split('.')], state);
        }

        return (typeof key === 'function')
            ? key(state)
            : [[
                'set',
                [...path, key].join('.'),
                get(state, [...path, ...key.split('.')])
            ]];
    }));
}


const FOR_SAVE = [
    ...COMMON_KEYS,
    ...HOTELS_KEYS,
    ...CARS_KEYS,
    ...AIR_KEYS,
];

function filterExceptions(keys, state, exceptionFns) {
    return keys.filter(keyTuple => {
        return exceptionFns.every(fn => !fn(keyTuple, state));
    });
}

export default function restorable(state) {
    const keys = restoreKeys(FOR_SAVE, [], state);

    return filterExceptions(keys, state, [isAirExchangeException]);
}
