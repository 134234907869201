/* global global */
import session from 'airborne/session';
import setFavicon from 'airborne/utils/setFavicon';
import settings from 'airborne/settings';
import {savedForm, saveSSO, setContainerId} from 'airborne/store/modules/homepage/actions/saved';
import history from 'airborne/homepage2/history';
import store from 'airborne/store';
import {
    setTermsToStore,
    shouldShowTerms,
    showHomepage,
    showMidoffice,
} from 'airborne/store/modules/login/actions/login';
import {loadI18N} from 'airborne/store/modules/header/actions/i18n';
import {changeTo} from 'airborne/store/modules/search_hotels/actions/changeTo';
import {parseHash, setCheckinLimits} from 'airborne/store/modules/login/helpers/login';
import {requestFeatureFlags} from 'airborne/store/modules/featureFlags/actions';
import {connectStreamService} from 'airborne/store/modules/stream/actions';
import browserHistory from 'airborne/browserHistory';
import systemData from 'airborne/systemData';
import loadGmap from 'airborne/homepage2/googlemap';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

function aftStartApp(dispatch, response) {
    const form = response['search_form_params'];
    const {cloneSid, hotelId, name} = parseHash(global.location.hash.toString());

    session.set('sid', response.sid);
    setFavicon(settings);
    setCheckinLimits(form);
    setContainerId(form);
    dispatch(saveSSO(form));
    dispatch(savedForm(form, cloneSid)).then(() => {
        cloneSid && dispatch(changeTo(`Hotel:${hotelId}`, decodeURIComponent(name), 'h'));
        history(store, !cloneSid);
    });
    loadGmap(response.GOOGLE_API_KEY).catch(noop);
}

function setAppSettings(response) {
    const isMidoffice = systemData.common.SITE_SUBDOMAIN === 'admin';
    settings.set(isMidoffice ? response : omit(response, 'COUNTRIES'));
}

export function startAfterLogin(response, dispatch, redirectUrl) {
    setAppSettings(response);
    dispatch(requestFeatureFlags()).then(() => {
        dispatch(connectStreamService());
    });
    if (response.SUB_PARTNER && response.SUB_PARTNER !== 'common') {
        dispatch(loadI18N());
        aftStartApp(dispatch, response);
        if (response['is_need_to_accept_terms']) {
            setTermsToStore();
            return browserHistory.push('/ui/new-terms/');
        }
        browserHistory.push('/ui/homepage/');
        return dispatch(showHomepage());
    }

    if (response['is_need_to_accept_terms']) {
        setTermsToStore(redirectUrl);
        return browserHistory.push('/ui/new-terms/');
    }

    if (redirectUrl) {
        browserHistory.push(redirectUrl);
    }

    return dispatch(showMidoffice());
}

export function startApp(response, dispatch) {
    const isMidoffice = systemData.common.SITE_SUBDOMAIN === 'admin';
    setAppSettings(response);
    if (shouldShowTerms()) {
        aftStartApp(dispatch, response);
        return null;
    }
    if (isMidoffice) {
        return dispatch(showMidoffice());
    }
    aftStartApp(dispatch, response);
    return dispatch(showHomepage());
}
