import {CharField, Field, DateTimeField} from 'midoffice/newforms/fields-stateless';

import {createSchema} from 'midoffice/newforms/helpers';

const FiltersSchema = createSchema({
    fields: {
        'search': CharField,
        'timestamp_min': createSchema(DateTimeField),
        'timestamp_max': createSchema(DateTimeField),
        'user': Field,
        'section': Field,
        'agency_id': Field,
        'company_id': Field,
        'config_area': Field,
        'system': Field,
        'affected_user_type': Field,
        'affected_user': Field,
    }
});

export default FiltersSchema;
