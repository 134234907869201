export function getAirBooking(state, id) {
    return state.airBookings.hash[id];
}

export function getAirBookingCompany(state, id) {
    return getAirBooking(state, id)?.company;
}

export function isLoading(state) {
    return state.airBookings.loading;
}

export function isModificationInProgress(state, id) {
    return state.airBookingModification.uuid === id;
}

export function getAirBookingsIds(state) {
    return state.airBookings.paged;
}
