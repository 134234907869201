import * as availabilityTypes from 'airborne/store/modules/availability_air/actionTypes';
import * as airPricingActionTypes from 'airborne/store/modules/pricing_air/actionTypes';

import {Step, HomepageState} from '../types';

function initialState(): HomepageState {
    return {
        step: Step.pnr,
        isPnrReady: false,
        destination: null,
        sso: null,
        search: null,
        companyWithoutPnrOpen: false,
    };
}

function discard(state: HomepageState): HomepageState {
    return {
        ...state,
        step: Step.pnr,
        destination: null,
        isPnrReady: false,
        companyWithoutPnrOpen: false,
    };
}


export default function homepage (state: HomepageState, {type, step, destination, value}: {type: string, step: Step, destination: any, value: any}): HomepageState {
    if (!state) return initialState();

    if (type === 'OPEN_ADD_PNR') {
        return {...state, step: Step.pnr, isPnrReady: false};
    }

    if (type === 'LOADED_PNR' && (state.step === Step.pnr || state.step === null)) {
        return {...state, step: Step.dest, isPnrReady: true};
    }

    if (type === 'BOOK_CARS_FOR_DESTINATION') {
        return {...state, step: Step.dest, isPnrReady: true};
    }

    if (type === 'BOOK_PRODUCT_FROM_CONFIRMATION') {
        return {...state, step: Step.dest, isPnrReady: true};
    }

    if (type === 'CLOSE_ADD_PNR') {
        return {...state, step: Step.dest, isPnrReady: true};
    }
    if (type === 'SHOW_PROFILE_SETTINGS') {
        return {...state, step: Step.settings};
    }
    if (type === 'SHOW_BOOKING_RECORDS') {
        return {...state, step: Step.aftBookingRecords, search: value};
    }
    if (type === 'SHOW_BOOKING_RECORDS_CARS') {
        return {...state, step: Step.aftBookingRecordsCars, search: value};
    }
    if (type === 'SHOW_BOOKING_RECORDS_AIR') {
        return {...state, step: Step.aftBookingRecordsAir, search: value};
    }
    if (type === 'SHOW_EXTERNAL_BOOKING_RECORDS_AIR') {
        return {...state, step: Step.aftExternalBookingRecordsAir};
    }
    if (type === 'SHOW_WITH_NEW_ROUTER') {
        return {...state, step: null};
    }
    if (type === 'SHOW_TERMS_AND_CONDITIONS_PAGE') {
        return {...state, step: Step.termsConditions, search: value};
    }
    if (type === 'SHOW_MY_BOOKINGS') {
        return {...state, step: Step.myBookings};
    }
    if (type === 'SHOW_EMAIL_OFFERS') {
        return {...state, step: Step.emailOffers};
    }

    if (type === 'NO_PNR') {
        return {...state, step: Step.dest, isPnrReady: true};
    }

    if (type === 'DISCARD_PNR' || type === 'FAIL_DESTINATION_OPTIONS') {
        return {...state, ...discard(state)};
    }

    if (type === 'COMPANY_WITHOUT_TRAVELER_OPEN') {
        return {...state, companyWithoutPnrOpen: true};
    }

    if (type === 'COMPANY_WITHOUT_TRAVELER_CLOSE') {
        return {...state, companyWithoutPnrOpen: false};
    }

    if ([
        'START_SEARCH',
        'CARS_START_SEARCH',
        'SEARCH_DESTINATION',
        'AIR_START_SEARCH',
        availabilityTypes.AIR_AVAILABILITY_START_SEARCH,
    ].includes(type)) {
        return {...state, destination};
    }
    if (type === 'START_SEARCH_SWITCH') {
        return {...state, destination, step: Step.search, isPnrReady: true};
    }
    if (type === 'CHECKOUT_SWITCH') {
        return {...state, destination, step: Step.checkout, isPnrReady: true};
    }
    if (type === 'SEARCH_DESTINATION_LABEL') {
        return {...state, destination};
    }

    if (['TO_SEARCH_RESULT', 'CARS_TO_SEARCH_RESULT', 'AIR_TO_SEARCH_RESULT'].includes(type)) {
        return {...state, step: Step.search};
    }

    if (type === availabilityTypes.AIR_AVAILABILITY_TO_SEARCH) {
        return {...state, step: Step.airAvailability};
    }

    if (type === airPricingActionTypes.AIR_PRICING_TO_PRICING) {
        return {...state, step: Step.airPricing};
    }

    if (type === 'AVAIL_LOADED_PARTIAL') {
        return {...state, step: state.step === Step.dest ? Step.search: state.step};
    }

    if (type === 'RATE_DETAILS_FAIL' && state.step === Step.checkout) {
        return {...state, step: Step.search};
    }

    if (type === 'CARS_RATE_DETAILS_FAIL' && state.step === Step.checkout) {
        return {...state, step: Step.search};
    }

    if (type === 'HOTELS_FAIL') {
        return {...state, step: Step.dest};
    }

    if (type === 'GO_TO_HOMEPAGE') {
        return {...state, step: Step.dest};
    }
    if (type === 'PNR_READY') {
        return {...state, isPnrReady: true};
    }

    if (type === 'TO_CHECKOUT' || type === 'TO_MULTI_CHECKOUT' || type === 'CARS_TO_CHECKOUT' || type === 'AIR_TO_CHECKOUT') {
        return {...state, step: Step.checkout};
    }

    if (type === 'NAVIGATE') {
        return {...state, step, destination};
    }

    if (type === 'SET_SABRE_SSO') {
        return {...state, sso: value};
    }

    return state;
}
