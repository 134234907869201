import isEmpty from 'lodash/isEmpty';
import settings from 'airborne/settings';
import {createSelector} from 'reselect';
import fixCurrency from 'airborne/search2/helpers/fixCurrency';
import fixDistance from 'airborne/search2/helpers/fixDistance';
import {populatePreferences} from 'airborne/search2/helpers/preference';
import {getAvail} from 'airborne/search2/helpers/avail';
import {getHotelAvailabilities, destSingleHotel} from 'airborne/search2/helpers/hotels';


const params = [
    state => getHotelAvailabilities(state),
    state => state.hotels.rates,
    ({i18n}) => i18n.currency,
    ({hotels: {filters}}) => filters.value.locations,
    function processHotels (hotelsData, availData, rates, currency, distanceTo) {
        const {'distance_units': unit} = settings.USER;
        return hotelsData.map((hotel)=> populatePreferences(fixDistance(fixCurrency({
            ...hotel,
            available: getAvail(availData, rates, hotel.id, currency),
        }, currency), distanceTo, unit)));
    }
];

const hotelSelector = createSelector(...[
    state=> state.hotels.hotels.data.filter(({isAlternative}) => !isAlternative),
    ...params,
]);
export default hotelSelector;

export const getAlternativeHotels = createSelector(...[
    state=> state.hotels.hotels.data.filter(({isAlternative}) => isAlternative),
    ...params,
]);

const getAllHotels = createSelector(...[
    state=> state.hotels.hotels.data,
    ...params,
]);

export const getAlternativeHotelForMap = createSelector(...[
    (state, hotels) =>  (hotels && hotels[0]) ? hotels[0].alternative_hotels || [] : [],
    ...params,
]);

export const getHotelById = (state, hotelId) => getAllHotels(state).find(({id}) => id === hotelId);

export const canShowAlternativeHotel = state => {
    if (!Boolean(destSingleHotel(state))) {
        return false;
    }
    return !isEmpty(getAlternativeHotels(state));
};

export const shouldShowAlternativeForHotel = (state, hotelId) => {
    const {
        available: {'is_available': isAvailable, 'out_of_policy':outOfPolicy},
        'tmc_preferred': tmcPreferred
    } = getHotelById(state, hotelId);
    //TODO: Move this contition to helper
    return !isAvailable || !tmcPreferred || outOfPolicy;
};

export const getAlternativeHotel = state => getAlternativeHotels(state).find(({available}) => available && available['is_available'] && !available['out_of_policy']);
