import pullAt from 'lodash/pullAt';


export function removeAtIdx(list, idx) {
    const ret = [...list];
    ret.splice(idx, 1);
    return ret;
};

export function removeAtIds(list, ids) {
    const ret = [...list];
    pullAt(ret, ids);
    return ret;
};

export function insertAtIdx(list, idx, newValue) {
    const ret = [...list];
    ret.splice(idx, 0, newValue);
    return ret;
};

export function replaceAtIdx(list, idx, newValue) {
    const ret = [...list];
    ret.splice(idx, 1, newValue);
    return ret;
};

export function editAtIdx(list, idx, newValue) {
    if (!newValue) {
        return removeAtIdx(list, idx);
    }

    if (idx >= list.length) {
        return [...list, newValue];
    }

    return replaceAtIdx(list, idx, newValue);
};

export function moveFromIdx(list, fromIdx, toIdx) {
    const element = list[fromIdx];
    if (!element) {
        return list;
    }
    const ret = editAtIdx(list, fromIdx, null);
    ret.splice(toIdx, 0, element);
    return ret;
};
