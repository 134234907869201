/* global module,IS_PROD */
import {createStore} from 'redux';
import composeWithMiddleware from './config/rootMiddleware';
import rootReducer from './config/rootReducer';

const finalCreateStore = composeWithMiddleware()(createStore);
const store = finalCreateStore(rootReducer, {});

if (!IS_PROD) {
    if (module.hot) {
        module.hot.accept('./config/rootReducer', () => {
            const nextReducers = require('./config/rootReducer');
            store.replaceReducer(nextReducers);
        });
    }
}

export default store;
