export function parse(data) {
    return {
        'multiple_passengers:enabled': data['rail:multiple_passengers'],
    };
};

export function serialize(data) {
    return {
        'rail:multiple_passengers': data['multiple_passengers:enabled'],
    };
}
