import partial from 'lodash/partial';
import {changeFormValue, sendChangesWithoutSaveModal} from 'airborne/store/modules/user_settings/actions/userSettings';
import {resetTermsInStore, showHomepage, showMidoffice, getTermsRedirectUrl} from './login';
import settings from 'airborne/settings';
import setFavicon from 'airborne/utils/setFavicon';
import history from 'airborne/browserHistory';

export function openPrivacyPolicy() {
    window.open('https://www.bcdtravel.com/privacy-policy/', '_blank');
}

export const changeTerms = partial(changeFormValue, 'terms');

export function sendTerms() {
    return function sendTermsD(dispatch) {
        return dispatch(sendChangesWithoutSaveModal('terms', '/internal/accounts/reaccept_terms/', 'POST'))
            .then((success) => {
                settings.set({'is_need_to_accept_terms': false});
                const redirectUrl = getTermsRedirectUrl();
                resetTermsInStore();
                setFavicon(settings);

                if (settings.SUB_PARTNER === 'common') {
                    if (redirectUrl) {
                        history.push(redirectUrl);
                        return success && showMidoffice();
                    }

                    return success && history.push('/');
                }
                history.push('/');
                return success && dispatch(showHomepage());
            });
    };
}

export function hideTermsModal() {
    return {
        type: 'HIDE_TERMS_MODAL'
    };
};

export function showTermsModal() {
    return {
        type: 'SHOW_TERMS_MODAL'
    };
};
