import settings from 'airborne/settings';
import some from 'lodash/some';
import {convertUnit} from 'geolib';

import timeout from 'airborne/search2/helpers/timeout';

import {currentCompany, searchForm} from 'airborne/homepage2/helpers/search';
import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {allExcluded, searchSingleHotel} from 'airborne/search2/helpers/hotels';
import destinationModal from 'airborne/store/modules/homepage/actions/destmodal';
import {locationCity} from 'airborne/store/modules/homepage/actions/address';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import getCompanyLocations from './companyLocations';
import {loadSettings} from './settings';
import {getAlternativeHotel, getAlternativeHotels, canShowAlternativeHotel, shouldShowAlternativeForHotel} from 'airborne/search2/helpers/hotelSelector';

import getAvail, {loadSingleAvail, loadAvail} from './avail';
import {selectHotel} from './filters';
import {markRecentSearch} from './recentSend';
import {showError} from './index';
import {apiError} from 'airborne/search2/helpers/apiError';
import {searchRequest} from './searchRequest';
import {handleApiError} from './air';
import browserHistory from 'airborne/browserHistory';
import {isHotels} from 'airborne/store/modules/homepage/selectors/product';
import {getSelectedConfiguration} from '../../homepage/selectors/tspm';

const RESTRICTED_ERROR_CODE = 1076000;

function availFilter(oldValue, available) {
    const value = {
        ...oldValue,
        price: {
            ...oldValue.price,
            available,
            isOnRequestHotel: true,
        },
    };
    return {type: 'CHANGE_HOTEL_FILTERS', value, errors: null};
}

function setOnRequestFilter(oldValue) {
    const value = {
        ...oldValue,
        price: {
            ...oldValue.price,
            isOnRequestHotel: true,
        },
    };
    return {type: 'CHANGE_HOTEL_FILTERS', value, errors: null};
}

function someHotelAvailable(hotelsData, availData) {
    return some(
        hotelsData.map(
            ({id})=> availData[id] && availData[id]['is_available']
        )
    );
}

function updateAvailFilter() {
    return function updateAvailFilterD(dispatch, getState) {
        const {
            dest: {options: {filters=[]}},
            hotels: {avail, hotels, filters: {value: filterForm}},
        } = getState();

        dispatch(setOnRequestFilter(filterForm));
        if (!filters || !filters.includes('AVAILABILITY')) {
            return null;
        }
        const searchedHotels = hotels.data.filter(({isAlternative}) => !isAlternative);
        return dispatch(availFilter(filterForm, someHotelAvailable(searchedHotels, avail.data)));
    };
}

function updateCompanyLocations() {
    return function updateCompanyLocationsD(dispatch, getState) {
        const {dest: {options: {filters=[]}}} = getState();
        if (!filters || !filters.includes('COMPANY_LOCATIONS')) {
            return null;
        }
        return dispatch(getCompanyLocations());
    };
}

function checkAllExcluded() {
    return function checkAllExcludedD(dispatch, getState) {
        const {hotels: {hotels, avail}} = getState();
        if (allExcluded(hotels.data, avail.data)) {
            dispatch(showModal('excluded'));
        }
    };
}

function loadList(refresh) {
    return async function singleHotelRequest(dispatch, getState) {
        dispatch({type: 'HOTELS_LOADING'});
        const {data, total} = await searchRequest(getState(), refresh);
        return dispatch({type: refresh ? 'HOTELS_LOADED' : 'HOTELS_LOADED_MORE', data, total});
    };
}

export function loadMore() {
    return async function loadMoreD(dispatch) {
        try {
            await dispatch(loadList(false));
            await dispatch(getAvail());
        }
        catch (response) {
            dispatch(showError(apiError(response), 'search'));
            dispatch({type: 'HOTELS_FAIL_MORE'});
        }
    };
}

export function loadHotelList() {
    return async function loadHotelListD(dispatch) {
        try {
            await dispatch(loadList(true));
        }
        catch (response) {
            dispatch(showError(apiError(response), 'search'));
            dispatch({type: 'HOTELS_FAIL'});
        }
    };
}

function destinationHotelId(state) {
    const {dest} = searchForm(state, 0);
    const [,hotel] = dest.destination.value.split(':');
    return Number(hotel);
}

function loadSingleHotel(rethrow) {
    return async function loadSingleHotelD(dispatch, getState) {
        try {
            await dispatch(loadList(true));
            const id = destinationHotelId(getState());

            if (canShowAlternativeHotel(getState())) {
                const alternativeHotelsIds = getAlternativeHotels(getState()).map(({id}) => id);
                await dispatch(loadAvail([id, ...alternativeHotelsIds]));
            }

            if (!getAlternativeHotel(getState()) || !shouldShowAlternativeForHotel(getState(), id)) {
                const availP = dispatch(loadSingleAvail(id));
                dispatch(selectHotel(id, 'rates'));
                await availP;
            }
        }
        catch (response) {
            if (response.body.errors[0]['error_code'] === RESTRICTED_ERROR_CODE) {
                dispatch({
                    type: 'MODAL_SHOW',
                    kind: 'restrictedDestination',
                    message: apiError(response)[0],
                });
            } else {
                dispatch(showError(apiError(response), 'search'));
            }
            dispatch({type: 'HOTELS_FAIL'});
            if (rethrow) { throw response; }
        }
    };
}

function startNewSearch() {
    return function startNewSearchD(dispatch, getState) {
        const destination = getDestFormValue(getState());
        dispatch({type: 'START_SEARCH', destination, index: 'all'});
    };
}

function switchList() {
    return function switchListD(dispatch) {
        dispatch(loadSettings());
        return dispatch({type: 'TO_SEARCH_RESULT'});
    };
}

function catchHotels404(response) {
    return function catchHotels404D(dispatch) {
        if (response.status === 404) {
            if (response.body.errors[0]['error_code'] === RESTRICTED_ERROR_CODE) {
                dispatch({
                    type: 'MODAL_SHOW',
                    kind: 'restrictedDestination',
                    message: apiError(response)[0],
                });
                return dispatch({type: 'HOTELS_EMPTY'});
            }
            return dispatch({type: 'HOTELS_EMPTY', errors: apiError(response)});
        }
        throw response;
    };
}

export function startHotelSearch(rethrow=false) {
    return function startHotelSearchD(dispatch, getState) {
        const {dest} = searchForm(getState(), 0);
        const configId = getSelectedConfiguration(getState()) || currentCompany(getState());
        markRecentSearch(dest, configId);
        dispatch(updateCompanyLocations());

        if (searchSingleHotel(getState())) {
            return dispatch(loadSingleHotel(rethrow))
                .then(()=> dispatch(updateAvailFilter()));
        }

        return dispatch(loadList(true))
            .then(()=> dispatch(getAvail()))
            .then(()=> dispatch(updateAvailFilter()))
            .then(()=> dispatch(checkAllExcluded()))
            .catch((response)=> dispatch(catchHotels404(response)))
            .catch((response)=> {
                try {
                    handleApiError(response);
                    dispatch({type: 'HOTELS_ABORT'});
                }
                catch (error) {
                    dispatch(showError(apiError(response), 'search'));
                    dispatch({type: 'HOTELS_FAIL'});
                }
                return rethrow ? Promise.reject(response) : null;
            });
    };
}

const WAIT_SWITCH = 2000;
export default function searchHotels() {
    return function searchHotelsD(dispatch) {
        let searchPromise;
        return dispatch(destinationModal())
            .then(()=> dispatch(startNewSearch()))
            .then(()=> {
                searchPromise = dispatch(startHotelSearch(true));
                browserHistory.push('/ui/hotels/search/');
                return Promise.race([
                    searchPromise,
                    timeout(WAIT_SWITCH)
                ]);
            })
            .then(()=> {
                dispatch(switchList());
                return searchPromise;
            })
            .catch(()=> null);
    };
}

function coordsToDestination(lat, lon) {
    return {
        value: `Region:${lat},${lon}`,
        label: `@ ${lat.toFixed(2)} ${lon.toFixed()}`,
        type: 'l',
        lat, lon,
    };
}

function setDestinationCity(lat, lon) {
    return async function setDestinationCityD(dispatch, getState) {
        let city;
        try {
            city = await locationCity(lat, lon);
        }
        catch (error) {
            return null;
        }
        const {homepage: {destination}} = getState();
        if (destination && destination.destination.value === `Region:${lat},${lon}`) {
            const newDestination = {
                ...destination,
                destination: {...destination.destination, label:city},
            };

            dispatch({type: 'SEARCH_DESTINATION_LABEL', destination: newDestination});
        }
    };
}

export function searchCoords({lat, lng}, distance) {
    return function searchCoordsD(dispatch, getState) {
        const unit = settings.USER.distance_units;
        dispatch({
            type: 'REUSE_DEST',
            value: {
                destination: coordsToDestination(lat, lng),
                distance: convertUnit(unit.toLowerCase(), distance, 1),
            },
        });

        dispatch({type: 'SEARCH_DESTINATION', destination: getDestFormValue(getState())});
        return Promise.all([
            dispatch(loadList(true)),
            dispatch(setDestinationCity(lat, lng))
        ]).then(()=> dispatch(getAvail()))
            .then(()=> dispatch(updateAvailFilter()))
            .catch((response)=> dispatch(catchHotels404(response)))
            .catch((response)=> {
                dispatch(showError(apiError(response), 'search'));
                dispatch({type: 'HOTELS_FAIL'});
            });
    };
}

export function reloadHotelsSearch() {
    return function reloadHotelsSearchD(dispatch, getState) {
        const state = getState();
        const onTheSearchPage = browserHistory.location.pathname.includes('/ui/hotels/search');
        return isHotels(state) && onTheSearchPage ? dispatch(searchHotels()): Promise.resolve();
    };
}
