
export function compareList(cmpFn, inputA, inputB) {
    if (!inputA ^ !inputB) return false;
    if (inputA === inputB) return true;
    if (inputA.length !== inputB.length) return false;
    return inputA.reduce((acc, part, index)=> (
        acc && (
            cmpFn(part, inputB[index])
        )
    ), true);
}

export const compare2Levels = compareList.bind(null, compareList.bind(null, Object.is));
