export function getProduct(state) {
    return state.dest.product;
}

export function getPrevProduct(state) {
    return state.dest.prevProduct;
}

export function isHotels(state) {
    return getProduct(state) === 'hotels';
}

export function isCars(state) {
    return getProduct(state) === 'cars';
}

export function isAir(state) {
    return getProduct(state) === 'air';
}
