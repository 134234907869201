import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import isEmpty from "lodash/isEmpty";

export function parseLocations(locations) {
    return Array.isArray(locations) ? locations.map((location)=> ({...location, orderIndex: parseInt(uniqueId())})) : [];
}


export function parse(data) {
    return {
        ...data,
        'company_locations': parseLocations(data['company_locations']),
    };
}

export function serialize(data) {

    const {'company_locations': rawCompanyLocations} = data;
    const companyLocations = rawCompanyLocations.reduce((acc, location)=> {
        acc.push(omit(location, 'orderIndex'));
        return acc;
    }, []);

    const serializedLocations = companyLocations
        .map((location) => ({
            'address': '',
            'city': '',
            'country': '',
            ...location,
            'latitude': parseFloat(location.latitude),
            'longitude': parseFloat(location.longitude),
        }))
        .filter((location) => !isEmpty(location));

    return {
            ...data,
            'company_locations': serializedLocations
    }
}
