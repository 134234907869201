import result from 'lodash/result';
import sortBy from 'lodash/sortBy';

export function allData({data}) {
    return Promise.resolve(
        data.map(([value, label])=> ({label, value}))
    );
}

function matchValueOrLabel(query, [value, label]) {
    return label.toLowerCase().includes(query) || result(value, 'toLowerCase') === query;
}

function exactMatchFirst(query, [value, label]) {
    return [!(result(value, 'toLowerCase') === query), label];
}


export function filterData(query, data) {
    query = query.toLowerCase();
    const results = sortBy(
        data.filter(matchValueOrLabel.bind(null, query)),
        exactMatchFirst.bind(null, query)
    )
        .map(([value, label])=> ({label, value}))
        .sort((a, b) => a.label.localeCompare(b.label));

    return new Promise((resolve, reject)=> {
        if (results.length === 0) {
            reject('No results found');
        }
        else {
            resolve(results);
        }
    });
}

export default class LocalAutocomplete {

    constructor(data) {
        this.data = data;
    }

    query(query) {
        if (!query || query.length < 2) {
            return Promise.reject('Enter at least two characters');
        }


        return filterData(query, this.data);
    }

    getData() {
        return this.data;
    }
}
