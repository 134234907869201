import omit from 'lodash/omit';
import {LOADING, LOADED, NET_FAIL, CANCEL} from 'midoffice/data/actions';

function initialState() {
    return {};
}

function update(state, id, category, type, data, time) {
    if (type === LOADING) {
        return {id, category, loading: true};
    }
    if (type === NET_FAIL) {
        return {id, category, loading: false, error: true, time, message: data.message};
    }
    if (type === LOADED) {
        return {id, category, loading: false, error: false, time, message: data.message};
    }
}

function updateQueue(state, type, {id, category, data, time, product}) {
    const key = category ? `${category}/${id}` : id;
    if (type === CANCEL) {
        return omit(state, key);
    }

    if (product) {
        return {
            ...state,
            [key]: {
                ...state[key],
                [product]: update(state[key]?.[product] || {}, id, category, type, data, time),
            }
        };
    }

    return {
        ...state,
        [key]: update(state[key] || {}, id, category, type, data, time),
    };
}

function updateEntity(state, type, {entityId, section, ...action}) {
    const key = `${entityId}/${section}`;
    return {
        ...state,
        [key]: updateQueue(state[key] || {}, type, action),
    };
}

export default function (state, {type, ...action}) {
    if (!state) {
        return initialState();
    }

    if (type === LOADING || type === LOADED || type === NET_FAIL || type === CANCEL) {
        return updateEntity(state, type, action);
    }

    return state;
}
