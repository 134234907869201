'process i18n';
import partial from 'lodash/partial';
import 'midoffice/bookings/types';
import {
    clearTags, removeTag,
    changeForm,
    serverPaginateCollection,
    serverSearchCollection,
    serverSortCollection
} from 'midoffice/data/actions/serverFiltered';
import {paginateCollection} from 'midoffice/data/actions/common';
import {freshBookingsActions} from 'midoffice/store/modules/freshBookings';
import {getBookingsIds} from 'midoffice/bookings/selectors';
import aio from 'midoffice/helpers/aio';
import api from 'midoffice/helpers/api';
import {CHANGE_ACTIONABLE_COUNTER} from 'airborne/store/modules/actionable_booking/actionTypes';


const endpoint = 'bookings';
const filtersEndpoint = 'bookingFilters';

export const defaultPaginateBookings = partial(paginateCollection, endpoint);
export const paginateBookings = partial(serverPaginateCollection, endpoint, filtersEndpoint);
export const sortBookings = partial(serverSortCollection, endpoint, filtersEndpoint);

export const changeFiltersForm = partial(changeForm, filtersEndpoint);

export const changeActionableCounter = (value) => {
    return {type: CHANGE_ACTIONABLE_COUNTER, value};
};

export const searchBookings = partial(serverSearchCollection, endpoint, filtersEndpoint);
export const refreshBookings = function() {
    return async function refreshBookingsD(dispatch, getState) {
        const prevBookingsIds = getBookingsIds(getState());
        await dispatch(searchBookings());
        const newBookingsIds = getBookingsIds(getState());
        const freshBookings = newBookingsIds.filter(
            bookingId => !prevBookingsIds.includes(bookingId)
        );
        dispatch(freshBookingsActions.setFreshBookings(freshBookings));
        api('GET', '/api/bookings/count_actionable/')
            .then((result) => {
                dispatch(changeActionableCounter(result['actionable_bookings']));
            });
        await aio.sleep(3.5);
        dispatch(freshBookingsActions.clearFreshBookings());
    };
};

export const clearBookingsTags = partial(clearTags, searchBookings, filtersEndpoint);

export const clearBookingsTag = partial(removeTag, searchBookings, filtersEndpoint);
