import {createSelector} from 'reselect';

import {filteredForm} from './helpers';

const restoreCarBookingForm = createSelector(
    (booking)=> booking.value,
    (value)=> ([['set', 'carBooking.value', filteredForm(value)]]),
);


const restoreCarBookingStep = createSelector(
    (details)=> details.uid,
    (uid)=> ([
        ['set', 'carBooking.loading', Boolean(uid)],
        ['set', 'carBooking.step', uid ? 'payment' : 'general'],
    ]),
);


export default [
    'cars.rates.data',
    'cars.rates.total',
    'cars.rates.errorCode',
    'carBooking.rateId',
    'carBooking.uuid',
    'carBooking.retry',
    'carBooking.checkoutData.data',
    'carBookingDetails.uid',
    'carBookingDetails.data',
    'carCancelAtModifyFlow.cancellationConfirmationNumber',
    'carCancelAtModifyFlow.cancellationStatus',
    (state)=> restoreCarBookingForm(state.carBooking),
    (state)=> restoreCarBookingStep(state.carBookingDetails),
];
