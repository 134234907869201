import every from 'lodash/every';
import isNull from 'lodash/isNull';

function isEmptyObject(obj) {
    return every(Object.values(obj || {}), isNull);
}

function isEmptyArray(arr) {
    return !arr || !arr.length;
}

function sanitizeRule({globalDestination, ...rule}) {
    if (isEmptyObject(rule.nights)) {
        delete rule.nights;
    }
    if (isEmptyObject(rule.price)) {
        delete rule.price;
    }
    if (rule.booking) {
        rule.booking = rule.booking.filter((part)=> !isEmptyObject(part));
    }
    if (isEmptyArray(rule.booking)) {
        delete rule.booking;
    }
    if (isEmptyArray(rule.stars)) {
        delete rule.stars;
    }
    if (isEmptyArray(rule.preference)) {
        delete rule.preference;
    }
    if (rule.checkin) {
        rule.checkin = rule.checkin.filter((part)=> !isEmptyObject(part));
    }
    if (isEmptyArray(rule.checkin)) {
        delete rule.checkin;
    }
    if (globalDestination) {
        rule.destination = [];
    }

    return rule;
}

export function policySerialize(data) {
    return {
        ...data,
        'travel_policy:rules': data['travel_policy:rules'].map((policy)=> ({
            ...policy,
            rules: policy.rules.map(sanitizeRule),
        })),
    };
}

export function serialize(data) {
    return {
        ...data,
        'travel_policy:rate_caps': data['travel_policy:rate_caps'].map(sanitizeRule),
    };
}

export function policyParse(data) {
    data['travel_policy:rules'] = data['travel_policy:rules'].map((rule, idx)=> ({
        ...rule,
        serverIndex: idx,
    }));

    return data;
}

function parseCapsRule(rule) {
    const globalDestination = isEmptyArray(rule.destination);
    const active = rule.incomplete ? false : rule.active;
    const ignored = true;
    return {...rule, globalDestination, active, ignored};
}

export function parse(data) {
    return {
        ...data,
        'travel_policy:rate_caps': data['travel_policy:rate_caps'].map(parseCapsRule)
    };
}
