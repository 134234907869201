import pick from 'lodash/pick';
import get from 'lodash/get';
import {getPnrProfile, getPrevBookingId, isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getCompany, getDestForm, getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';

function selectProfile(profile, {traveler}) {
    if (!traveler || traveler.myself) {
        return profile;
    }

    return {
        ...profile,
        'company': traveler.companyId,
        'company_name': traveler.company,
        'tspm_company_id': traveler.tspmCompanyId || null,
        'tspm_traveler_id': traveler.tspmTravelerId || null,
    };
}

export function searchForm(state, profileIdx) {
    const {homepage: {sso}, ...rest} = state;
    const destination = getDestination(state);
    return {
        ...rest,
        profile: selectProfile(getPnrProfile(state, profileIdx), destination),
        dest: destination,
        sso,
        previousBookingId: getPrevBookingId(state, profileIdx),
        currency: getCurrency(state)
    };
}

export function currentCompany(state) {
    const dest = getDestForm(state);
    const profile = selectProfile(getPnrProfile(state, 0), dest.value);
    const company = getCompany(state);
    return profile ? profile.company : (company?.value || null);
}

export function getDestTraveler(state) {
    return get(state, 'dest.hotels.value.traveler');
}

export function serializeSSO(sso) {
    return pick(sso, ['sso_saml_art', 'sso_url']);
}


export function serializeTSPM(profile) {
    return pick(profile, ['tspm_traveler_id', 'tspm_company_id']);
}

export function isMultiSearch(state) {
    return Boolean(isMultiPnr(state));
}
