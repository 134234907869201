import * as types from '../actionTypes';
import {FIELDS} from 'airborne/pricing_air/types';

const defaultFormValue = {
    value: {
        [FIELDS.ignoreClassOfService]: true,
    },
    errors: null,
};
const form = (state = defaultFormValue, {type, value, errors}) => {
    switch (type) {
        case types.AIR_PRICING_RESET:
            return defaultFormValue;
        case types.AIR_PRICING_CHANGE_FORM:
            return {value, errors};
        default:
            return state;
    }
};

export default form;
