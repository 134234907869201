'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';


class Tag extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        data: PropTypes.string.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    handleRemove = ()=> {
        this.props.onRemove(this.props.id);
    };

    render () {
        const el = this.props.data;
        return (
            <span className="tag-list__item">
                {el}
                <span onClick={this.handleRemove} className="glyphicon glyphicon-remove clickable" />
            </span>);
    }
}

class TagList extends React.Component {
    static propTypes = {
        actions: PropTypes.shape({
            removeFilter: PropTypes.func,
            clearFilters: PropTypes.func,
        }),
        status: PropTypes.array.isRequired,
        onRemove: PropTypes.func,
        onClear: PropTypes.func,
    };

    handleRemove = (idx)=> {
        const tag = this.props.status[idx];
        if (this.props.actions) {
            this.props.actions.removeFilter(tag);
        }

        if (this.props.onRemove) {
            this.props.onRemove(tag);
        }
    };

    handleClear = ()=> {
        if (this.props.actions) {
            this.props.actions.clearFilters();
        }

        if (this.props.onClear) {
            this.props.onClear();
        }
    };

    renderTag (el, idx) {
        return (<Tag id={idx} data={el.label} key={idx} onRemove={this.handleRemove} />);
    }

    render () {
        if (!this.props.status || !this.props.status.length) {
            return false;
        }

        return (<div className="row-wrapper row-wrapper--condensed">
            <div className="tag-list">
                <div className="tag-list__title">{gettext('Showing only:')}</div>

                <div className="tag-list__wrapper">
                    <div className="tag-list__tags">
                        {this.props.status.map((tag, idx)=> this.renderTag(tag, idx))}
                    </div>
                    <a onClick={this.handleClear} className="nowrap clickable tag-list__clear-lnk">{gettext('Clear all filters')}</a>
                </div>
            </div>
        </div>);
    }
}

export default TagList;
