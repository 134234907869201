import chunk from 'lodash/chunk';
import moment from 'moment-range';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function isBefore(date, before) {
    return Boolean(before && date.isBefore(before));
}

function isAfter(date, after) {
    return Boolean(after && date.isAfter(after));
}


class SelectionWrapper {
    constructor(selection, min, max) {
        if (!selection) {
            this.valid = false;
        }
        else if (Array.isArray(selection)) {
            let [start, end] = selection;
            let startValid = start && start.isValid();
            let endValid = end && end.isValid();

            if (startValid && endValid) {
                this.range = moment.range(start, end);
                this.start = start;
                this.end = end;
            }
            else if (startValid) {
                this.date = start;
                this.start = start;
                this.end = null;
            }
            else if (endValid) {
                this.date = end;
                this.end = end;
                this.start = null;
            }
            this.valid = startValid || endValid;
        }
        else {
            this.date = moment(selection);
            this.valid = this.date.isValid();
        }

        this.min = min;
        this.max = max;
    }

    contains(date) {
        if (!this.valid) { return false; }
        if (this.range) { return this.range.contains(date); }
        return this.date.isSame(date, 'day');
    }

    startsWith(date) {
        if (!this.start) return false;
        return this.start.isSame(date, 'day');
    }

    endsWith(date) {
        if (!this.end) return false;
        return this.end.isSame(date, 'day');
    }

    beforeAllowed(date) {
        if (!this.min) return false;
        return date.isBefore(this.min);
    }

    afterAllowed(date) {
        if (!this.max) return false;
        return date.isAfter(this.max);
    }
}

class Day extends React.Component {
    static propTypes = {
        selected: PropTypes.bool,
        start: PropTypes.bool,
        end: PropTypes.bool,
        past: PropTypes.bool,
        future: PropTypes.bool,
        day: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.day);
    }

    render() {
        const {selected, day, start, end, past, future} = this.props;
        const disabled = Boolean(past || future);
        return (
            <td className={cx('day', {selected, start, end, past, future})}
                onClick={disabled ? null : this.handleClick}>
                {day.date()}
            </td>
        );
    }
}

export default class Month extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        selected: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        minDate: PropTypes.object,
        maxDate: PropTypes.object,
        onClick: PropTypes.func,
        onPrev: PropTypes.func,
        onNext: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.renderDay = this.renderDay.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(day) {
        if (this.props.onClick) {
            this.props.onClick(day, this.props.name);
        }
    }

    renderDay({day, currentMonth, ...params}, idx) {
        if (!currentMonth) { return <td key={idx} />; }
        return (<Day key={idx} day={day} {...params}
                     onClick={this.handleClick} />);
    }

    render() {
        const {year, month, selected, minDate, maxDate} = this.props;
        const start = moment([year, month]).startOf('week');
        const end = moment([year, month]).endOf('month').endOf('week');

        const selection = new SelectionWrapper(selected, minDate, maxDate);
        const showPrev = Boolean(!isBefore(
            moment([year, month]).add(-1, 'days'),
            minDate
        ) && this.props.onPrev);
        const showNext = Boolean(!isAfter(
            moment([year, month]).add(1, 'month'),
            maxDate
        ) && this.props.onNext);

        const days = [];
        moment.range(start, end).by('days', (day)=> {
            days.push({
                day,
                past: selection.beforeAllowed(day),
                future:  selection.afterAllowed(day),
                selected: selection.contains(day),
                start: selection.startsWith(day),
                end: selection.endsWith(day),
                currentMonth: day.month() === month
            });
        });

        return (
            <table className="table-condensed">
                <thead>
                    <tr>
                        {showPrev ?
                            <th className="prev" onClick={this.props.onPrev}>«</th> :
                            <th />
                        }
                        <th colSpan={5}>{moment.months(month)} {year}</th>
                        {showNext ?
                            <th className="next" onClick={this.props.onNext}>»</th> :
                            <th />
                        }
                    </tr>
                    <tr>
                        {moment.weekdaysMin(true).map((day)=>
                            <th key={day} className="dow">{day}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {chunk(days, 7).map((week, index)=>
                        <tr key={index}>{week.map(this.renderDay)}</tr>
                    )}
                </tbody>
            </table>
        );
    }
}
