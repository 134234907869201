/* global IS_PROD */
import {silentThrow} from 'midoffice/helpers/silentThrow';


export const requireOneOfProps = IS_PROD ? ()=> null : function requireOneOfProps(props, propNames) {
    const numberOfProps = propNames.reduce((acc, propName)=> {
        return acc + (props[propName] ? 1 : 0);
    }, 0);
    if (numberOfProps !== 1) {
        silentThrow(`Invalid prop: One of ${propNames.join(', ')} is required.`);
    }
};
