import intersection from 'lodash/intersection';
import some from 'lodash/some';
import gettext from 'airborne/gettext';

export const getTabs = () => [
    {key: 'ruleApplication', name: gettext('Rule Application')},
    {key: 'penalties', name: gettext('Penalties')},
    {key: 'refunds', name: gettext('Refunds')},
    {key: 'advanceResTicketing', name: gettext('Advanced reservations and ticketing')},
    {key: 'voluntaryChanges', name: gettext('Voluntary changes')},
    {key: 'voluntaryRefunds', name: gettext('Voluntary refunds')},
    {key: 'otherFreetext', name: gettext('Other Information')},
];

export function getFareRulesTabs(booking) {
    const fareGroup = booking.fareGroup;
    const fareRules = (fareGroup.fareComponents || []).map(({fareRules, fareBasisCode, segmentIds})=>({
        ...fareRules,
        fareBasisCode,
        segmentIds,
    }));

    const fareRulesByOD = fareGroup.originDestinations.map(OD => {
        const ids = OD.segments.map(s => s.segmentIdRef);
        return fareRules.filter(({segmentIds}) => intersection(segmentIds, ids).length);
    });

    return getTabs().map(tab => ({
        ...tab,
        dataByOD: fareRulesByOD.map(fareRules => fareRules.map(fareRule => ({
            data: fareRule[tab.key],
            fareBasisCode: fareRule.fareBasisCode,
        }))),
    })).filter(tab => some(tab.dataByOD, dataArr => some(dataArr, item => item.data)));
}
