import noop from 'lodash/noop';
import pick from 'lodash/pick';
import range from 'lodash/range';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import settings from 'airborne/settings';
import {PlainInput} from 'midoffice/components/IE9';
import Select from './Select';

import Month from 'midoffice/components/calendar/Month';
import {injectField} from 'midoffice/newforms/decorators';

const TIME_CHOICES = range(24).map((value)=> (
    [value, `${value}:00`]
));


export class DateInput extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        inputFormat: PropTypes.string,
        inputSize: PropTypes.string,
        minDate: PropTypes.object,
        maxDate: PropTypes.object,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        time: PropTypes.bool,
        value: PropTypes.any,
        hideOnDateChange: PropTypes.bool,

        'data-testid': PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
    };

    static defaultProps = {
        className: 'form-control',
        inputSize: 'medium',
        disabled: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop
    };

    state = {
        open: false,
        value: null,
        inputFormat: '',
        calendarDisplay: null,
        textDisplay: null,
        timeDisplay: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {inputFormat=settings.USER.date_format_str} = nextProps;

        if (!prevState
            || (nextProps.value !== prevState.value && !moment(nextProps.value, inputFormat).isSame(prevState.value))
            || inputFormat !== prevState.inputFormat
        ) {
            return {
                value: nextProps.value,
                inputFormat,
                calendarDisplay: DateInput.getCalendarDisplay(nextProps, inputFormat),
                textDisplay: DateInput.getTextDisplay(nextProps, inputFormat),
                timeDisplay: DateInput.getTimeDisplay(nextProps, inputFormat),
            };
        }
        return prevState;
    }

    static getCalendarDisplay({value, minDate: minValue}, inputFormat) {
        const date = moment(value, inputFormat);
        const minDate = moment(minValue, inputFormat);

        if (date.isValid()) {
            return date;
        }
        if (minDate.isValid()) {
            return minDate;
        }

        return moment();
    }

    static getTimeDisplay({value}, inputFormat) {
        const date = moment(value, inputFormat);
        return date.isValid() ? date.hours() : 0;
    }

    static getTextDisplay({value}, inputFormat) {
        return moment.isMoment(value) ? value.format(inputFormat) : (value || '');
    }

    handleFocus = ()=> {
        this.setState({open: true});
        this.props.onFocus();
    };

    handleBlur = ()=> {
        if (!this.datepickerClick) {
            this.setState({open: false});
            this.props.onBlur();
        }
        this.datepickerClick = false;  // eslint-disable-line immutable/no-mutation
    };

    handleChange = (event)=> {
        let {value} = event.target;
        const {onChange, name, time} = this.props;
        const {timeDisplay, inputFormat} = this.state;

        if (!value) {
            this.setState({textDisplay: ''});
            return onChange(null, name);
        }
        if (value.length < inputFormat.length) {
            return this.setState({textDisplay: value});
        }

        const momentValue = moment(value, inputFormat);
        if (time && momentValue.isValid()) {
            value = momentValue.hours(timeDisplay);
        }

        onChange(value, name);
    };

    handleChangeTime = (time)=> {
        const {onChange, name} = this.props;
        const {textDisplay, inputFormat} = this.state;

        this.setState({timeDisplay: time});

        if (textDisplay.length >= inputFormat.length) {
            const momentValue = moment(textDisplay, inputFormat);
            if (momentValue.isValid()) {
                onChange(momentValue.hours(time), name);
            }
        }
    };

    handleSelectDate = (date)=> {
        const {name, onChange, time} = this.props;
        const {timeDisplay, inputFormat} = this.state;
        if (time) {
            onChange(date.hours(timeDisplay), name);
        }
        else {
            onChange(date.format(inputFormat), name);
        }

        this.handleDone();
    };

    handleDone = () => {
        const {hideOnDateChange, time} = this.props;

        if (hideOnDateChange && !time) {
            this.setState({open: false});
            ReactDOM.findDOMNode(this.refs.input).blur();
        }
    }

    handlePickerMouseDown = ()=> {
        this.datepickerClick = true; // eslint-disable-line immutable/no-mutation

        setTimeout(()=> {
            ReactDOM.findDOMNode(this.refs.input).focus();
        });
    };

    handlePrev = ()=> {
        this.state.calendarDisplay.subtract(1, 'months');
        this.forceUpdate();
    };

    handleNext = ()=> {
        this.state.calendarDisplay.add(1, 'months');
        this.forceUpdate();
    };

    render() {
        const {value, minDate, maxDate, placeholder, className, inputSize, time, onFocus, onBlur} = this.props;
        const {calendarDisplay, textDisplay, timeDisplay, inputFormat} = this.state;

        let selected;
        if (value) {
            const date = moment(textDisplay, inputFormat);
            if (date.isValid()) { selected = date; }
        }

        return (
            <div className={cx('dropdown', {open: this.state.open})}>
                <PlainInput {...pick(this.props, Object.keys(PlainInput.propTypes))}
                    value={textDisplay}
                    className={cx(className, `input-${inputSize}`)}
                    type="text"
                    ref="input"
                    placeholder={placeholder || inputFormat.toLowerCase()}
                    autoComplete="off"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
                {time && (
                    <Select inputSize="xsmall"
                        choices={TIME_CHOICES}
                        value={timeDisplay}
                        onChange={this.handleChangeTime}
                        onFocus={onFocus}
                        onBlur={onBlur} />
                )}
                {open && <div className="dropdown-menu datepicker"
                    onMouseDown={this.handlePickerMouseDown}>
                    <Month year={calendarDisplay.year()}
                        month={calendarDisplay.month()}
                        selected={selected}
                        minDate={minDate}
                        maxDate={maxDate}
                        onClick={this.handleSelectDate}
                        onPrev={this.handlePrev}
                        onNext={this.handleNext} />
                </div>}
            </div>
        );
    }
}

export default injectField(DateInput);
