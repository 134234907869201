import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export const serializeDataCollection = (collection = []) => {
    if (isEmpty(collection)) {
        return [];
    }

    return collection.map(obj =>
        omitBy(obj, (prop) => isNil(prop) || prop === '')
    );
};
