/* eslint-disable immutable/no-mutation */
import countBy from 'lodash/countBy';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import template from 'lodash/template';
import {ValidationError} from './exceptions';


class BaseLimitValidator {

    code = 'limitValue';
    message = template('Ensure this value is <%= limitValue %> (it is <%= value %>).');

    constructor(limitValue, message) {
        this.limitValue = limitValue;
        if (typeof message !== 'undefined') {
            this.message = template(message);
        }
    }

    clean(value) {
        return value;
    }

    compare(value, limit) {
        return value !== limit;
    }

    validate(value) {
        let cleaned = this.clean(value);
        if (this.compare(cleaned, this.limitValue)) {
            throw new ValidationError(
                this.message({limitValue: this.limitValue, value: cleaned}),
                this.code
            );
        }
    }
}


export class MaxValueValidator extends BaseLimitValidator {

    code = 'maxValue';
    message = template('Ensure this value is less than or equal to <%= limitValue %>.');

    compare(value, limit) {
        return value > limit;
    }
}


export class MinValueValidator extends BaseLimitValidator {

    code = 'minValue';
    message = template('Ensure this value is greater than or equal to <%= limitValue %>.');

    compare(value, limit) {
        return value < limit;
    }
}

export class ExactLengthValidator extends BaseLimitValidator {

    code = 'exactLength';
    message = template('Field must have <%= limitValue %> characters (it has <%= value %>).');

    compare(value, limit) {
        return value !== 0 && value !== limit ;
    }

    clean(value) {
        return value.length;
    }
}

export class MaxLengthValidator extends BaseLimitValidator {

    code = 'maxLength';
    message = template('Ensure this value has at most <%= limitValue %> characters (it has <%= value %>).');

    compare(value, limit) {
        return value > limit;
    }

    clean(value) {
        return value.length;
    }
}


export class MinLengthValidator extends BaseLimitValidator {

    code = 'minLength';
    message = template('Ensure this value has at least <%= limitValue %> characters (it has <%= value %>).');

    compare(value, limit) {
        return value !== 0 && value < limit;
    }

    clean(value) {
        return value.length;
    }
}


export class EmailValidator {
    code = 'email';
    message = 'Please enter a valid email address.';
    /* copied from jquery-validate */
    regexp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

    constructor(message) {
        if (typeof message !== 'undefined') {
            this.message = message;
        }
    }

    validate(value) {
        if (!this.regexp.test(value)) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class EmailOrBlankValidator extends EmailValidator {

    validate(value) {
        if (value && !this.regexp.test(value)) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class EmailTagValidator extends EmailValidator {

    message = template('Next emails are invalid: <%= emails %>. ' +
                       'Please enter a valid email addresses.');

    validate(emailList) {
        const invalidEmails = emailList.filter((email)=> {
            if (!this.regexp.test(email)) {
                return email;
            }
        });

        if (invalidEmails.length) {
            throw new ValidationError(
                this.message({emails: invalidEmails.join(', ')}),
                this.code
            );
        }
    }
}


export class UniqueListValidator {
    code = 'unique';
    message = template('Next items are not unique: <%= items %>. ' +
                       'Please enter only unique items.');

    constructor(message) {
        if (typeof message !== 'undefined') {
            this.message = message;
        }
    }

    validate(valueList) {
        const notUnique = keys(pick(countBy(valueList), (qnt)=> qnt > 1));
        if (notUnique.length) {
            throw new ValidationError(
                this.message({items: notUnique.join(', ')}),
                this.code
            );
        }
    }
}


export class ColorValidator {
    code = 'color';
    message = 'Please enter a valid hex color.';

    regexp = /^#[a-f0-9]{6}$/i;

    constructor(message) {
        if (typeof message !== 'undefined') {
            this.message = message;
        }
    }

    validate(value) {
        if (!this.regexp.test(value)) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class RegexpValidator {
    code = 'regexp';
    message = 'This field must match the regexp.';

    constructor(regexp, message) {
        this.regexp = regexp;
        if (typeof message !== 'undefined') {
            this.message = message;
        }
    }

    validate(value) {
        if (!this.regexp.test(value)) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class DefaultLanguageValidator {
    code = 'defaultLanguage';
    message = 'You should provide text for the default language.';

    constructor({message, defaultLanguage}) {
        if (typeof message !== 'undefined') {
            this.message = message;
        }
        this.defaultLanguage = defaultLanguage;
    }

    validate(value={}) {
        if (!value[this.defaultLanguage]) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class IataCodeValidator extends RegexpValidator {
    code = 'iata';
    message = 'Input valid IATA code.';

    static regexp = /^[A-Z]{3}$/;

    constructor(message) {
        super(IataCodeValidator.regexp, message);
    }
}
