export function isEveryChecked(checked, paged) {
    return paged.reduce((acc, id)=> {
        return acc && Boolean(checked[id]);
    }, Boolean(paged.length));
}

export function isAnyChecked(checked, paged) {
    return paged.reduce((acc, id)=> {
        return acc || Boolean(checked[id]);
    }, false);
}

export function checkAll(paged, flag) {
    const ret = {};
    paged.forEach((id)=> {
        ret[id] = flag;
    });
    return ret;
}

export function isEveryObjChecked(checked, paged) {
    return paged.reduce((acc, obj)=> {
        return acc && Boolean(checked[obj.id]);
    }, Boolean(paged.length));
}

export function isAnyObjChecked(checked, paged) {
    return paged.reduce((acc, obj)=> {
        return acc || Boolean(checked[obj.id]);
    }, false);
}
