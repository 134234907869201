import omit from 'lodash/omit';
import {CANCEL, CHANGED, LOADED, LOADING, NET_FAIL} from '../actions/types';
import {keyForInheritance} from 'midoffice/helpers/settings';

function initial() {
    return {hash: {}, sizeHash: {}, loading: false};
}

function fixAllCommon(matrix) {
    return Object.keys(matrix).reduce((acc, id)=> {
        const {common, ...inh} = matrix[id];
        if (common === true || common === false) {
            acc[id] = {...inh, all: common};
        }
        else {
            acc[id] = inh;
        }
        return acc;
    }, {});
}

function parse(data) {
    return fixAllCommon(data);
}


function setLoading({hash, ...state}, action, flag) {
    const key = keyForInheritance(action);
    return {
        ...state,
        hash: {
            ...hash,
            [key]: {...hash[key]},
        },
        loading: flag,
    };
}

function setParams({hash, sizeHash}, {data, size, ...action}) {
    const key = keyForInheritance(action);
    const {section} = action;
    return {
        hash: {
            ...hash,
            [key]: parse(data, section),
        },
        sizeHash: {
            ...sizeHash,
            [key]: size,
        },
        loading: false,
    };
}

function editFlag({hash, ...state}, {flag, id, system='all', ...action}) {
    const key = keyForInheritance(action);
    const matrix = hash[key][id] || {};
    const isChanged = (matrix[system] !== false) !== flag;
    const newMatrix = {
        ...hash[key],
        [id]: isChanged ? {...matrix, edit: {[system]: {flag}}}
            : omit(matrix, 'edit')
    };
    return {
        ...state,
        hash: {
            ...hash,
            [key]: newMatrix,
        },
    };
}

function setFlag({hash, ...state}, {flag, id, system='all', ...action}) {
    const key = keyForInheritance(action);
    const matrix = omit(hash[key] && hash[key][id] || {}, 'edit');
    const newMatrix = {
        ...hash[key],
        [id]: {
            ...matrix,
            [system]: flag,
        },
    };
    return {
        ...state,
        hash: {
            ...hash,
            [key]: newMatrix,
        },
    };
}

function cancelFlag({hash, ...state}, {id, ...action}) {
    const key = keyForInheritance(action);
    const matrix = hash[key][id];
    const newMatrix = {
        ...hash[key],
        [id]: omit(matrix, 'edit')
    };
    return {
        ...state,
        hash: {
            ...hash,
            [key]: newMatrix,
        },
    };
}

export default function inheritance (state, {type, ...action}) {
    if (!state) {
        return initial();
    }

    if (type === LOADING) {
        return setLoading(state, action, true);
    }

    if (type === NET_FAIL) {
        return setLoading(state, action, false);
    }

    if (type === LOADED) {
        return action.data ? setParams(state, action)
            : setFlag(state, action);
    }

    if (type === CHANGED) {
        return editFlag(state, action);
    }

    if (type === CANCEL) {
        return cancelFlag(state, action);
    }

    return state;
}
