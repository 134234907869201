import {showModal, hideModal} from "midoffice/data/actions/modals";
import {addEntry2} from "midoffice/data/actions/collection";
import {parse} from "airborne/types";

export const endpoint = 'airReports';

function addReport(data) {
    return async function (dispatch) {
        dispatch(showModal('reportProcessing'));

        try {
            const responseData = await dispatch(addEntry2(endpoint, data, true));
            const parsedResponse = parse(endpoint, responseData, {local: data});

            dispatch(hideModal('reportProcessing'));
            dispatch(showModal('reportProcessing', {...parsedResponse, type: 'success'}));
        } catch (response) {
            dispatch(hideModal('reportProcessing'));
            dispatch(showModal('reportProcessing', {errors: response.errors?.errors, type: 'error'}));
        }
    };
}

export function createReport() {
    return function createReportD(dispatch, getState) {
        const {airBookingFilters: {value}} = getState();
        return dispatch(addReport(value));
    };
}
