import without from 'lodash/without';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import entries from 'lodash/entries';
import zipObject from 'lodash/zipObject';
import isString from 'lodash/isString';
import every from 'lodash/every';
import omit from 'lodash/omit';
import $ from 'jquery'; // eslint-disable-line import/no-unresolved

import Base62 from 'base62';
import uuid from 'uuid';

export function fixSearchStringBeginning(searchString) {
    return searchString.replace(/\?+&+|^&+|^\?+|^\??/igm, '?');
}

export function generateTaskId() {
    return Base62.encode(uuid.v4('binary').join(''));
}

export function getDestinationArray(destinationString) {
    return without(destinationString.split(','), '');
}

export function getSearchParamPattern(key) {
    return new RegExp('(?:^|[?&]+)(' + key + ')=([^&$]*)', 'igm');
}

export function getURLSearchString() {
    return window.location.search;
}

export function getURLParameter(name, searchString, defaultValue) {
    const match = getSearchParamPattern(name).exec(
        searchString || getURLSearchString());
    if (isArray(match)) {
        let value = decodeURIComponent(match[2]);
        if (isObject(defaultValue)) {
            try {
                value = JSON.parse(value);
            }
            catch (err) {
                return defaultValue;
            }
        }
        return value;
    }
    else {
        return isUndefined(defaultValue) ? null : defaultValue;
    }
}

export function makeURL(base, params, exclude, searchString) {
    base = base || '';
    params = params || '';
    var search = isEmpty(searchString) ?
        getURLSearchString() :
        fixSearchStringBeginning(searchString);

    if (isArray(exclude)) {
        each(exclude, function(key) {
            search = search.replace(getSearchParamPattern(key), '');
        });
    }

    if (isObject(params)) {
        each(entries(params), function(item) {
            const key = item[0];
            const value = item[1];
            const paramRe = getSearchParamPattern(key);
            // Old JQuery encodes space as "+" while this should only be done in forms
            // https://github.com/jquery/jquery/issues/2658
            const param = $.param(zipObject([key], [value])).replace(/\+/g, '%20');
            if (search.search(paramRe) !== -1) {
                search = search.replace(paramRe, param ? '&' + param : '');
            }
            else {
                search += param ? '&' + param : '';
            }
        });

    }
    else if (params) {
        search = search ? search + '&' : '?';
        search += params;
    }
    return base + fixSearchStringBeginning(search);
}

export function parseCoordinates(location) {
    let coordinates = [null, null];
    if (isString(location)) {
        const parts = location.split(',');
        if (parts.length === 2) {
            coordinates = [parseFloat(parts[0]), parseFloat(parts[1])];
        }
    }
    return every(coordinates) ? coordinates : null;
}

/* Update filters URL parameter */
export function updateFilters(searchString, extend, exclude) {
    searchString = searchString || getURLSearchString();
    let filters = getURLParameter('filters', searchString, {});
    if (isObject(extend)) {
        extend(filters, extend);
    }
    if (isArray(exclude)) {
        filters = omit(filters, exclude);
    }
    if (isEmpty(filters)) {
        searchString = makeURL('', null, ['filters'], searchString);
    }
    else {
        searchString = makeURL(
            '', {filters: JSON.stringify(filters)}, null, searchString);
    }
    return searchString;
}

export default {
    fixSearchStringBeginning: fixSearchStringBeginning,
    getDestinationArray: getDestinationArray,
    generateTaskId: generateTaskId,
    getSearchParamPattern: getSearchParamPattern,
    getURLParameter: getURLParameter,
    getURLSearchString: getURLSearchString,
    makeURL: makeURL,
    parseCoordinates: parseCoordinates,
    updateFilters: updateFilters,
};
