import {LOADER_SHOW, LOADER_HIDE} from '../actions';

function hide() {
    return {
        show: false,
        message: null,
    };
}

function show({message}) {
    return {
        show: true,
        message,
    };
}

export default function loader(state, action) {
    if (!state) {
        return hide();
    }

    if (action.type === LOADER_SHOW) {
        return show(action);
    }

    if (action.type === LOADER_HIDE) {
        return hide();
    }

    return state;
}

