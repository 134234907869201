import {combineReducers} from 'redux';
import externalExtraActionTypes from './actionTypes';

const initialFormState = {
    showBookingTable: false,
    formErrorMessage: '',
};

const form = (state = initialFormState, action) => {
    switch (action.type) {
        case externalExtraActionTypes.SET_BOOKING_TABLE_DISPLAYING:
            const {display} = action;

            return {
                ...state,
                showBookingTable: display,
            };
        case externalExtraActionTypes.SET_SEARCH_FORM_ERROR_MESSAGE:
            const {message} = action;

            return {
                ...state,
                formErrorMessage: message,
            };
        case externalExtraActionTypes.SET_EXTERNAL_SEARCH_TYPE:
            return {
                ...initialFormState
            };
        default:
            return state;
    }
};

const initialDataModal = {
    loading: false,
    data: {},
    errorMessage: null,
    externalBookingData: {},
    searchType: null,
};

const dataModal = (
    state = initialDataModal,
    {type, loading, errorMessage, uid, data, externalBookingData, searchType}
) => {
    switch (type) {
        case externalExtraActionTypes.SET_BOOKING_TABLE_DISPLAYING:
            return {
                ...initialDataModal,
                searchType: state.searchType,
            };
        case externalExtraActionTypes.SET_RAW_MODAL_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [uid]: data,
                },
                errorMessage: null,
            };
        case externalExtraActionTypes.SET_DATA_MODAL_LOADING:
            return {
                ...state,
                loading,
            };
        case externalExtraActionTypes.SET_DATA_MODAL_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage,
            };
        case externalExtraActionTypes.SET_EXTERNAL_BOOKING_DATA:
            return {
                ...state,
                externalBookingData: {
                    ...state.externalBookingData,
                    [uid]: externalBookingData,
                },
                errorMessage: null,
            };
        case externalExtraActionTypes.SET_EXTERNAL_SEARCH_TYPE:
            return {
                ...state,
                searchType,
                errorMessage: null,
            };
        default:
            return state;
    }
};

const externalExtra = combineReducers({
    form,
    dataModal,
});

export default externalExtra;
