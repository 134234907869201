import pick from 'lodash/pick';

import {retrievePNR, noPNR, selectCompany, reuseSearch} from 'airborne/store/modules/homepage/actions/pnr';
import {restoreLocal} from 'airborne/homepage2/persist/actions';
import {selectTspmIds, loadConfigurationIds} from 'airborne/store/modules/homepage/actions/tspm';
import {switchEmailOffer} from 'airborne/store/modules/checkout_hotels/actions/offers';
import {saveSsoContainerId} from 'airborne/helpers/ssoContainerId';
import {parseHotelsValue, parseCarsValue, getCompanyFromFormData} from '../../login/helpers/login';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

function parseProfile(data) {
    if (!data.company) {
        return null;
    }

    return {
        pnr: data.pnr,
        company: {
            value: data.company,
            label: data['company_name'],
            gdsOid: data['office_id'],
        },
    };
}

export function savedForm(data, cloneSid) {
    return function savedForm(dispatch, getState) {
        const restore = restoreLocal(data.sid, cloneSid);
        const enableCompanyIdField = getFeatureFlag(getState(), 'ENABLE_COMPANY_ID_FIELD');
        dispatch(restore);
        if (restore.data) {
            return Promise.resolve();
        }

        const profile = parseProfile(data);
        const hotels = parseHotelsValue(data);
        const cars = parseCarsValue(data.cars, enableCompanyIdField);

        const previousBookingId = data['booking_id'] || null;
        dispatch(reuseSearch(profile, previousBookingId, {hotels, cars}));
        if (!profile) {
            return Promise.resolve();
        }
        return dispatch(selectCompany(getCompanyFromFormData(data)))
            .then(()=> dispatch(data.pnr ? retrievePNR(true, false, data['booking_id'], null, false) : noPNR(profile.company, 0)))
            .then(()=> data['tspm_traveler_id'] && dispatch(selectTspmIds(data, 0)))
            .then(() => data['tspm_traveler_id'] && dispatch(loadConfigurationIds({select: data['configuration_id']})))
            .then(()=> (data.step === 'search') && dispatch({type: 'START_SEARCH_SWITCH', destination: hotels}))
            .then(()=> (data.step === 'checkout') && dispatch(switchEmailOffer(hotels, data, 0)))
            .catch(()=> null);
    };
}

export function saveSSO(form) {
    return {
        type: 'SET_SABRE_SSO',
        value: pick(form, ['sso_saml_art', 'sso_url', 'case_number']),
        index: 'all',
    };
}

export function setContainerId({'container_id': containerId}) {
    if (containerId) {
        saveSsoContainerId(containerId);
    }
}
