import gettext from 'airborne/gettext';

export const FARE_TYPES = {
    NEGOTIATED: 'negotiated',
    BCD_NEGOTIATED: 'bcdNegotiated',
    COMPANY_NEGOTIATED: 'companyNegotiated',
    PUBLISHED: 'published',
};


export function getFareType({isNegotiated, isBcdNegotiated, isCompanyNegotiated}) {
    if (isBcdNegotiated) {
        return FARE_TYPES.BCD_NEGOTIATED;
    }

    if (isCompanyNegotiated) {
        return FARE_TYPES.COMPANY_NEGOTIATED;
    }

    if (isNegotiated) {
        return FARE_TYPES.NEGOTIATED;
    }

    return FARE_TYPES.PUBLISHED;
}

export function getFareTypeText(fareType) {
    return {
        [FARE_TYPES.PUBLISHED]: gettext('Published'),
        [FARE_TYPES.BCD_NEGOTIATED]: gettext('BCD Negotiated'),
        [FARE_TYPES.COMPANY_NEGOTIATED]: gettext('Company Negotiated'),
        [FARE_TYPES.NEGOTIATED]: gettext('Negotiated'),
    }[fareType];
}
