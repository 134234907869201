import settings from 'airborne/settings';
import {mergeIdNumbers} from 'airborne/store/modules/homepage/reducers/destination';
import {parseUrl} from 'midoffice/helpers/url';
import systemData from 'airborne/systemData';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import moment from 'moment';


function parseDate([yyyy, mm, dd]) {
    return moment([yyyy, mm - 1, dd]).format('YYYY-MM-DD');
}

export function getLocale() {
    const lang = settings.USER_LOCALE !== 'en_US' ? settings.USER_LOCALE : '';
    return systemData.common.SITE_SUBDOMAIN !== 'admin' && lang ? {locale: lang} : {};
}

export function parseHash(hash) {
    return hash ? parseUrl(hash.substr(1)) : {};
}

export function setCheckinLimits({'server_min_date': minCheckinDate, 'server_max_date': maxCheckinDate}) {
    settings.set({minCheckinDate, maxCheckinDate});
}

export function parseHotelsValue(data) {
    const {
        checkin, checkout,
        chains,
        distance,
        destination,
        room1,
        'destination_code': destinationCode,
        'destination_type': destinationType,
        'hotel_name': hotel,
        'special_rates': rates,
        'user_id': userId,
        'tspm_company_id': tspmCompanyId,
        'tspm_traveler_id': tspmTravelerId,
    } = data;

    return {
        destination: destinationCode
            ? {value: destinationCode, label: destination, type: destinationType}
            : null,
        dates: {
            min: checkin ? parseDate(checkin) : null,
            max: checkout ? parseDate(checkout) : null,
        },
        distance: distance || 1,
        guests: Number(room1) || 1,
        chains: chains || null,
        'hotel_name': hotel || null,
        'special_rates': rates || null,
        traveler: userId
            ? {
                value: userId,
                myself: true,
                tspmCompanyId,
                tspmTravelerId,
            }
            : null,
    };
}

export function parseCarsValue(value, enableCompanyIdField) {
    if (!value) {
        return {};
    }

    const {
        datetimes: dates,
        'pickup': pickupLabel,
        'pickup_code': pickupValue,
        'pickup_distance': pickUpDistance,
        'pickup_type': pickUpType,
        'pickup_category': pickUpCategory,
        'pickup_iata': pickUpIATA,
        'dropoff': dropoffLabel,
        'dropoff_code': dropOffValue,
        'dropoff_iata': dropOffIATA,
        'dropoff_distance': dropOffDistance,
        'dropoff_type': dropOffType,
        'dropoff_category': dropOffCategory,
        'id_numbers': idNumbers,
        ...rest
    } = value;
    const pickUp = {value: pickupValue, label: pickupLabel, type: pickUpType, category: pickUpCategory, iataCode: pickUpIATA};
    const dropOff = {value: dropOffValue, label: dropoffLabel, type: dropOffType, category: dropOffCategory, iataCode: dropOffIATA};
    return dropEmpty({
        ...rest,
        dates,
        pickUp, dropOff,
        pickUpDistance, dropOffDistance,
        differentDropOff: dropOffValue !== pickupValue,
        idNumbers: enableCompanyIdField ? mergeIdNumbers(idNumbers, []) : (idNumbers || null),
    });
}

export function getCompanyFromFormData(data) {
    return {
        value: data['company'],
        label: data['company_name'],
        tspmId: data['tspm_company_id'],
    };
}
