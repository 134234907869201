
export default function externalBooking(state, {type, data, message}) {
    if (!state || type === 'CLOSED') {
        return {loading: false, data: null, loaded: false, cancelled: false, error: null};
    }
    if (type === 'LOADING') {
        return {...state, loading: true};
    }
    if (type === 'LOADED') {
        return {...state, loading: false, data, loaded: true};
    }
    if (type === 'CANCELLED') {
        return {...state, loading: false, cancelled: true, error: null};
    }
    if (type === 'FAILED') {
        return {...state, loading: false};
    }
    if (type === 'CANCEL_FAILED') {
        return {...state, loading: false, error: message};
    }

    return state;
}
