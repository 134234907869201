import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {dropEmpty} from 'midoffice/helpers/urlParams';

function getProvider(label) {
    if (isEmpty(label.chains)) {
        return null;
    }
    const firstChain = label.chains[0];
    return firstChain.substring(0, firstChain.indexOf(':'));
}

export function parse(data) {
    let labeling = data['labeling:hotels'];
    const customLabeling = labeling['custom_labeling'] && labeling['custom_labeling'].map((label) => {
        const provider = getProvider(label);
        const chains = label.chains && label.chains.map((chain) => chain.substring(chain.indexOf(':') + 1));
        const brands = label.brands && label.brands.map(({id, name}) => ({value: id, label: name}));
        const hotels = label.hotels && label.hotels.map(({id, name}) => ({value: id, label: name}));
        return dropEmpty({
            ...label,
            provider,
            chains,
            brands,
            hotels,
        });
    });
    return {...data, 'labeling:hotels': {...labeling, 'custom_labeling': customLabeling, 'custom_labeling_enabled': labeling['custom_labeling_enabled'] || false}};
}

export function serialize(data) {
    let labeling = data['labeling:hotels'];
    const customLabeling = labeling['custom_labeling'].map((label) => {
        const chains = label.chains && label.chains.map((chain) => label['provider'] + ':' + chain);
        const brands = label.brands && label.brands.map(({value, label}) => ({id: value, name: label}));
        const hotels = label.hotels && label.hotels.map(({value, label}) => ({id: value, name: label}));
        const updatedLabel = {
            ...label,
            chains,
            brands,
            hotels,
            'use_default_translation': label['use_default_translation'] || false,
        };
        return omit(dropEmpty(updatedLabel), 'provider');
    });

    labeling = {
        ...labeling,
        'custom_labeling': customLabeling,
        'agency_preferred_tier': (
            labeling['agency_preferred'] && labeling['agency_preferred_tier'] || false
        ),
        'agency_preferred_by_chain': (
            labeling['agency_preferred'] && labeling['agency_preferred_by_chain'] || false
        ),
        'client_preferred_tier': (
            labeling['client_preferred'] && labeling['client_preferred_tier'] || false
        ),
        'client_preferred_by_chain': (
            labeling['client_preferred'] && labeling['client_preferred_by_chain'] || false
        ),
    };

    return {...data, 'labeling:hotels': labeling};
}
