import isEmpty from 'lodash/isEmpty';

const DEFAULT_SSO_CONNECTIONS = {
    'sso_configurations': [{
        'connection_type': null,
        'enabled': false,
        'redirect_url': null,
        'service_provider': null}]
};


export function parse(data) {
    const {'sso_configurations': ssoConfigurations} = data;
    return isEmpty(ssoConfigurations) ? DEFAULT_SSO_CONNECTIONS : data;
}
