const FIELDS = {
    HOTEL_PROVIDER_PAY_PAYMENT: 'supplier:hrsd:hotel_provider_pay_payment',
    CARD_PAYMENT: 'supplier:hrsd:card_payment',
};

export function parse(data) {
    const {
        [FIELDS.HOTEL_PROVIDER_PAY_PAYMENT]: hotel,
        [FIELDS.CARD_PAYMENT]: card,
        ...rest
    } = data;

    let paymentOptions = [];
    if (hotel) { paymentOptions.push('hotel'); }
    if (card) { paymentOptions.push('card'); }

    /* Payment options -- representation of invoice_payment and card_payment
     * parameters. Possible payment options values:
     * "invoice", "card", "invoice,card"
     */
    paymentOptions = paymentOptions.join(',');

    return {paymentOptions, ...rest};
}

export function serialize({paymentOptions, ...data}) {
    paymentOptions = paymentOptions.split(',');

    return {
        ...data,
        [FIELDS.HOTEL_PROVIDER_PAY_PAYMENT]: paymentOptions.includes('hotel'),
        [FIELDS.CARD_PAYMENT]: paymentOptions.includes('card'),
    };
}
