import settings from 'airborne/settings';
import GoogleMapsLoader from 'google-maps';


export default function loadGmap(key) {
    GoogleMapsLoader.KEY = key || settings.GOOGLE_API_KEY; // eslint-disable-line immutable/no-mutation
    GoogleMapsLoader.VERSION = '3.53'; // eslint-disable-line immutable/no-mutation

    if (!GoogleMapsLoader.KEY) {
        return Promise.reject();
    }
    return new Promise((resolve, reject)=> {

        GoogleMapsLoader.load(function(google) {
            google ? resolve(google) : reject(google);
        });
    });
}
