import get from 'lodash/get';
import {getPnrOid} from './pnrProfile';
import {getProduct} from './product';
import {Step} from '../types';

export function getStep(state: any): Step {
    return get(state, 'homepage.step') || null;
}

export function isPnrReady(state: any): Step {
    return get(state, 'homepage.isPnrReady');
}

export function getCompany(state: any) {
    const company = state.multiPnr.currentCompany;
    return company || null;
}
export function getOriginalCompany(state: any) {
    const company = state.multiPnr.originalCompany;
    return company || null;
}

export function getCompanyName(state: any) {
    const company = getCompany(state);
    return company ? company.label : null;
}

export function getCompanyOID(state: any) {
    return getPnrOid(state);
}

export function getOptions(state: any) {
    return get(state, 'dest.options') || {};
}
export function getOptionsParams(state: any) {
    return get(state, 'dest.optionsParams');
}

export function getCarsNegotiatedRates(state: any) {
    return get(state, 'dest.options.cars_negotiated_rates') || {};
}

export function getAdvertisingStatus(state: any) {
    return get(state, 'dest.options.is_cars_advertising_enabled') || false;
}

export function getHotelsAdvertisingStatus(state: any) {
    return get(state, 'dest.options.is_hotels_advertising_enabled') || false;
}

export function getFligthsAdvertisingStatus(state: any) {
    return get(state, 'dest.options.is_air_advertising_enabled') || false;
}

export function getDestForm(state: any) {
    const product = getProduct(state);
    return state.dest[product];
}

export function getDestFormValue(state: any) {
    return getDestForm(state).value;
}

export function getDestination(state: any) {
    return get(state, 'homepage.destination');
}

export function getDestinationTripType(state: any) {
    return get(state, 'homepage.destination.tripType');
}

export function isRoundTrip(state: any) {
    return getDestinationTripType(state) === 'roundTrip';
}

export function isLoadingBookCarsForDestination(state: any) {
    return get(state, 'bookCarsForDestination.loading');
}

export function getSSOCaseNumber(state: any) {
    return get(state, 'homepage.sso.case_number');
}

export function getCollapsableSections(state: any) {
    return get(state, 'dest.collapsableSections', {});
}

export function getAirSearchType(state: any) {
    return get(state, ['dest', 'airSearchType']);
}

export function isCompanyWithoutPnr(state: any) {
    return get(state, 'homepage.companyWithoutPnrOpen');
}
