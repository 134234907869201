import isEmpty from 'lodash/isEmpty';


export const KEY = 'agency:custom_country_codes';

let LAST = 0;
export function generateTempCode(startFrom=LAST) {
    LAST = startFrom + 1;
    return `temp--${startFrom}`;
}

export function isTempCode(code) {
    return code && code.startsWith('temp--');
}


export function validate(data, agencyName) {
    const errors = {};

    const names = [];
    for (let {name, code} of data) {
        if (names.includes(name)) {
            errors[code] = 'Name should be unique'; // eslint-disable-line immutable/no-mutation
        }
        names.push(name);
        if (!name.startsWith(`${agencyName} `)) {
            errors[code] = `Name should start with '${agencyName} '`; // eslint-disable-line immutable/no-mutation
        }
    }

    return isEmpty(errors) ? null : errors;
}


export function serialize({[KEY]: countryCodes, ...settings}) {
    return {
        ...settings,
        [KEY]: countryCodes.map(({code, ...rest})=> ({
            ...rest,
            code: isTempCode(code) ? '' : code,
        })),
    };
}
