import {
    CAR_BOOKING_MODIFICATION_FLOW_QUIT, CAR_BOOKING_MODIFICATION_FLOW,
    CAR_DESTINATION_LOADING, CAR_DESTINATION_LOADED,
} from 'midoffice/car-bookings/modify/actionTypes';

const DEFAULT_STATE = {
    uuid: null,
    modificationFlow: false,
    bookingDestinationLoading: false,
    booking: null,
};

export default function bookingModification(state = DEFAULT_STATE, {type, id, booking}) {
    if (!state || type === 'LOADED' || type === 'NET_FAIL') {
        return {...state, uuid: null};
    }
    if (type === 'LOADING') {
        return {...state, uuid: id};
    }
    if (type === CAR_BOOKING_MODIFICATION_FLOW) {
        return {...state, booking, modificationFlow: true, bookingDestinationLoading: true};
    }
    if (type === CAR_BOOKING_MODIFICATION_FLOW_QUIT) {
        return {...state, booking: null, modificationFlow: false, bookingDestinationLoading: false};
    }

    if (type === CAR_DESTINATION_LOADING) {
        return {...state, bookingDestinationLoading: true};
    }

    if (type === CAR_DESTINATION_LOADED) {
        return {...state, bookingDestinationLoading: false};
    }

    return state;
}
