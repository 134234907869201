import React from 'react';
import PropTypes from 'prop-types';
import {RichUtils, EditorState} from 'draft-js';
import {default as Button, ButtonGroup} from './Button';


export class InlineStyleButton extends React.Component {
    static propTypes = {
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        style: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onChange(
            RichUtils.toggleInlineStyle(
                this.props.editorState,
                this.props.style
            )
        );
    }

    render() {
        const {editorState, style, ...props} = this.props;
        const currentStyle = editorState.getCurrentInlineStyle();
        return (
            <Button {...props}
                    icon={style}
                    active={currentStyle.has(style)}
                    onClick={this.handleClick} />
        );
    }
}


export const Bold = (props)=> (
        <InlineStyleButton {...props} style="BOLD" />
);

export const Italic = (props)=> (
        <InlineStyleButton {...props} style="ITALIC" />
);

export const Underline = (props)=> (
        <InlineStyleButton {...props} style="UNDERLINE" />
);

export const Strikethrough = (props)=> (
        <InlineStyleButton {...props} style="STRIKETHROUGH" />
);

export const Monospace = (props)=> (
        <InlineStyleButton {...props} style="CODE" />
);


export default class InlineStyleGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const {className='', ...props} = this.props;

        return (
            <ButtonGroup {...props} className={className}>
                <Bold {...props} />
                <Italic {...props} />
                <Underline {...props} />
                <Strikethrough {...props} />
                <Monospace {...props} />
            </ButtonGroup>
        );
    }
}
