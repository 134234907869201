import {register} from 'airborne/types';
import isString from 'lodash/isString';

function getUrl(endpoint, pnr, {entityId, system}) {
    return `/api/${endpoint}/${pnr}/?entity=${entityId}&system=${system}`;
}

export const fetchEntry = getUrl;
export const removeEntry = getUrl;

export function addEntry(endpoint) {
    return `/api/${endpoint}/`;
}

export function parse(data) {
    if (isString(data)) {
        return {text: data};
    }

    return {id: data.hotel_pnr_response.pnr_number};
}

export function serialize({entity, ...data}) {
    return {
        ...data,
        entity: String(entity.value),
    };
}

register('pnr-tool', {fetchEntry, removeEntry, addEntry, parse, serialize});

