import {loaded, loadAble} from 'airborne/search2/helpers/loadable';
import {combineReducers} from 'redux';

import 'airborne/search_cars/types';


const RATES_PER_PAGE = 20;

function updateRate(state, rateId, data) {
    return {
        ...state,
        data: state.data.map((rate, index) => {
            if (index === rateId) {
                return {
                    ...rate,
                    ...data,
                };
            }
            return rate;
        })
    };
}

const rates = loadAble(function rates(state, {type, rateId, data, total, errors, errorCode}) {
    if (!state || type === 'CARS_START_SEARCH') {
        return {loading: false, data: [], total: 0, errors: null, errorCode: null};
    }

    if (type === 'CARS_RATES_LOADED') {
        return loaded({...state, total, errors: null}, data);
    }

    if (type === 'CARS_RATES_EMPTY') {
        return {...state, loading: false, total: 0, errors, data: [], errorCode};
    }

    if (type === 'CARS_RATE_DETAILS_LOADING') {
        return updateRate(
            state, rateId,
            {detailsLoading: true, detailsLoaded: false}
        );
    }

    if (type === 'CARS_RATE_DETAILS_LOADED') {
        return updateRate(
            state, rateId,
            {
                ...data,
                detailsLoading: false,
                detailsLoaded: true,
            }
        );
    }

    if (type === 'CARS_RATE_DETAILS_FAIL') {
        return updateRate(
            state, rateId,
            {detailsLoading: false, detailsLoaded: false}
        );
    }


    return state;
}, 'CARS_RATES_LOADING', 'CARS_RATES_FAIL');


function order(state, {type, order}) {
    if (!state && state !== null || type === 'CARS_START_SEARCH') {
        return '-preference';
    }

    if (type === 'CHANGE_CARS_ORDER') {
        return order;
    }

    return state;
}

function pagination(state, {type, pagination}) {
    if (!state || type === 'CARS_START_SEARCH') {
        return {page: 1, pageSize: RATES_PER_PAGE};
    }

    if (type === 'CHANGE_CARS_PAGINATION') {
        return {...state, ...pagination};
    }

    if (type === 'CHANGE_CARS_ORDER' || type === 'CHANGE_CARS_FILTERS') {
        return {...state, page: 1};
    }

    return state;
}

function filters(state, {type, value, saveFilters}) {
    if (!state || (type === 'CARS_START_SEARCH' && !saveFilters)) {
        return {};
    }

    if (type === 'CHANGE_CARS_FILTERS') {
        return value || {};
    }

    return state;
}

function mapView(state, {type}) {
    if (!state) {
        return {showMap: false};
    }

    if (type === 'TOGGLE_MAP_VIEW') {
        return {showMap: !state.showMap};
    }

    return state;
}

const reducer = combineReducers({
    rates,
    order,
    pagination,
    filters,
    mapView,
});
export default reducer;
