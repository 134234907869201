import React from 'react';
import PT from 'prop-types';
import Cancellation from './Cancellation';
import AdvanceChange from './AdvanceChange';
import Baggage from './Baggage';
import {TicketAttributesShape} from '../shapes';
import Seats from 'airborne/search_air/FareGroups/Seats';

export default class TicketAttributes extends React.Component {
    static propTypes = {
        ticketAttributes: TicketAttributesShape,
        showSeats: PT.bool,
    }
    render() {
        const {showSeats} = this.props;

        const {
            cancellation,
            advanceChange,
            baggage,
            seats,
        } = this.props.ticketAttributes || {};

        return (
            <>
                <Cancellation cancellation={cancellation}/>
                <AdvanceChange advanceChange={advanceChange}/>
                <Baggage baggage={baggage} className="a-content__item__rate-details"/>
                {showSeats && <Seats seats={seats} className="a-content__item__rate-details"/>}
            </>
        );
    }
}
