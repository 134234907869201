import StreamService from 'airborne/services/Stream';
import api from 'midoffice/helpers/api';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const MAINTENANCE_NOTIFICATION_TIME = 60 * 60 * 6;

function showMaintenance(dispatch, maintenance) {
    const {showPreNotification, end, start} = maintenance;
    const now = moment().unix();
    dispatch({type: 'SET_MESSAGE', currentMaintenance: maintenance});
    const hideTime = showPreNotification ? start - now : end - now;
    setTimeout(() => {
        dispatch({type: 'RESET'});
        // eslint-disable-next-line no-use-before-define
        getMaintenance(dispatch);
    }, hideTime * 1000);
}

function parseMaintenance(maintenance) {
    const now = moment().unix();
    const {start} = maintenance;
    const showPreNotification = now > (start - MAINTENANCE_NOTIFICATION_TIME) && now < start;
    const isActive = now >= start;

    const timeToShowWithDelay = maintenance.showPreNotification ?
        start - now - MAINTENANCE_NOTIFICATION_TIME
        : start - now;
    return {
        ...maintenance, showPreNotification, isActive, timeToShowWithDelay
    }

}

export function getMaintenance(dispatch) {
    const checkResult = (maintenances)=> {
        const hiddenMaintenances = localStorage.getItem('listMaintenances') || [];
        if (!isEmpty(maintenances)) {
            const maintenance = parseMaintenance(maintenances[0]);

            if ((maintenance.showPreNotification || maintenance.isActive) && !hiddenMaintenances.includes(maintenance.id)) {
                showMaintenance(dispatch, maintenance);
            } else {
                setTimeout(() => {
                    showMaintenance(dispatch, maintenance);
                }, maintenance.timeToShowWithDelay * 1000);
            }
            dispatch({type: 'SET_MAINTENANCE_LIST', maintenances});
        }
    };
    api('GET', '/maintenance/check-maintenance/').then(checkResult, ()=> {});
}

export const startListenMaintenances = (dispatch) => {
    if (!StreamService.connected) {
        setTimeout(() => startListenMaintenances(dispatch), 1000);
        return null;
    }
    StreamService.subscribeOn(
        'MAINTENANCE_MESSAGE',
        () => {
            getMaintenance(dispatch);
        }
    );

};

export function hideMaintenance (id) {
    return function hideMaintenanceD(dispatch) {
        let hiddenMaintenances = localStorage.getItem('listMaintenances') || [];
        const arrayOfHiddenMaintenances = isArray(hiddenMaintenances) ? hiddenMaintenances : [hiddenMaintenances];
        arrayOfHiddenMaintenances.push(id);
        localStorage.setItem('listMaintenances', arrayOfHiddenMaintenances);
        dispatch({type: 'RESET'});
    };
}
