export function parse(data) {
    const hubs = data['air:custom_airport_hubs']?.hubs || [];
    
    return {
        ...data,
        'air:custom_airport_hubs': hubs
    };
}

export function serialize(data) {
    const hubs = data['air:custom_airport_hubs'];
    
    return {
        'air:custom_airport_hubs': hubs.length
            ? {hubs: data['air:custom_airport_hubs']}
            : {}
    };
}
