import {
    getUserSettingsErrors,
    getUserSettingsLoading,
    getUserSettingsValue,
} from 'airborne/store/modules/user_settings/selectors/userSettings';

export const mapStateToProps = (state, endpoint) => ({
    value: getUserSettingsValue(state, endpoint),
    loading: getUserSettingsLoading(state, endpoint),
    errors: getUserSettingsErrors(state, endpoint),
});
