import moment from 'moment';

import settings from 'airborne/settings';

const DATE_RANGE_SEPARATOR = '|';

export function dropEmpty(object) {
    const ret = {};
    Object.entries(object).forEach(([key, value])=> {
        if (value !== null && value !== '' && value !== undefined && !(Array.isArray(value) && value.length === 0)) { //eslint-disable-line no-undefined
            ret[key] = value;  // eslint-disable-line immutable/no-mutation
        };
    });
    return ret;
}

function serializeDate(date) {
    if (!date) {
        return '';
    }

    return moment.isMoment(date)
        ? date.format(settings.DATE_FORMAT)
        : date;
}

export function serializeDateRange({min, max}={}) {
    if (!min && !max) {
        return null;
    }
    return `${serializeDate(min)}${DATE_RANGE_SEPARATOR}${serializeDate(max)}`;
}


function serializeDatetime(date) {
    if (!date) {
        return '';
    }

    return moment(date).toISOString();
}

export function serializeDatetimeRange({min, max}) {
    if (!min && !max) {
        return null;
    }
    return `${serializeDatetime(min)}${DATE_RANGE_SEPARATOR}${serializeDatetime(max)}`;
}
