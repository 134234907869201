import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

export const isFalsyEmpty = value =>
    isEmpty(value) &&
    typeof value !== 'number' &&
    typeof value !== 'boolean' &&
    typeof value !== 'function';

export const isEmptyDeep = item => {
    if (Array.isArray(item)) {
        return item.every(value => isEmptyDeep(value));
    }
    else if (item && typeof item === 'object') {
        return Object.values(item).every(value => isEmptyDeep(value));
    }
    return isFalsyEmpty(item);
};

export const dropEmptyDeep = input => {
    if (!input || typeof input !== 'object') return input;

    if (Array.isArray(input)) {
        return input.map(item => dropEmptyDeep(item)).filter(item =>
            (item && typeof item === 'object' && Object.keys(item).length)
            || !isFalsyEmpty(item)
        );
    }

    return reduce(input, (acc, value, key) => {
        if (
            (value && typeof value === 'string')
            || typeof value === 'number'
            || typeof value === 'boolean'
        ) return {...acc, [key]: value};
        if (value && Array.isArray(value) && value.length) {
            const processed = value.map(dropEmptyDeep).filter(item => !isFalsyEmpty(item));
            return processed.length ? {...acc, [key]: processed} : acc;
        };
        if (value && typeof value === 'object' && Object.keys(value).length) {
            const processed = dropEmptyDeep(value);
            return Object.keys(processed).length ? {...acc, [key]: processed} : acc;
        };
        return acc;
    }, {});
};
