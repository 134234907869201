import React from 'react';
import find from 'lodash/find';
import {getSections as getCompanySections} from 'midoffice/companies/SettingsMenu';
import {getSections as getAgencySections} from 'midoffice/agencies/SettingsMenu';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

import ActionableCounter from 'airborne/common/ActionableCounter';
import {hasAccess} from 'midoffice/helpers/permission';

const BOOKING_RECORDS_PRODUCTS = ['hotels', 'cars', 'air', 'rail', 'external'];

function wrapWithActionableCounter(item) {
    return (<ActionableCounter placement="right">{item}</ActionableCounter>);
}

export const PRODUCTS = [
    {id: 'general', title: 'General', singular: 'General'},
    {id: 'hotels', title: 'Hotels', singular: 'Hotel', wrapper: wrapWithActionableCounter},
    {id: 'cars', title: 'Cars', singular: 'Car'},
    {id: 'air', title: 'Flights', singular: 'Flight'},
    {id: 'rail', title: 'Rail', singular: 'Rail'},
    {id: 'external', title: 'External Bookings', singular: 'External Booking'},
    {id: 'policy-groups', title: 'Overrides', singular: 'Overrides'},
];

export function getTitle(id) {
    return find(PRODUCTS, {id}).title;
}

export function getProducts(treeName) {
    const sections = treeName === 'companies' ? getCompanySections() : getAgencySections();
    const ids = Object.keys(sections).filter(
        (id)=> sections[id].length
    );

    return PRODUCTS.filter(({id})=> ids.includes(id));
}

export function getBookingRecordsProducts() {
    const canShowExternalTab = hasAccess('aft:check_external_booking:view');
    const railEnabled = getFeatureFlag(null, 'RAIL_ENABLED')
    return PRODUCTS.filter(({id})=> {
        if (id === 'external' && !canShowExternalTab) return false;
        if (id === 'rail' && !railEnabled) return false
        return BOOKING_RECORDS_PRODUCTS.includes(id);
    });
}

export function getDefaultProduct(treeName) {
    const [defaultProduct] = getProducts(treeName);
    return defaultProduct.id;
}
