import * as types from '../actionTypes';
import {toAirPricing} from 'airborne/store/modules/pricing_air/actions';
import {getAirSearchType} from 'airborne/store/modules/homepage/selectors/homepage';
import {AIR_SEARCH_TYPES} from 'airborne/homepage2/types';
import {resetCheckoutFormFields} from 'airborne/store/modules/checkout_air/actions/checkoutForm';
import browserHistory from 'airborne/browserHistory';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";
import {isAirExchangeFlow} from "airborne/store/modules/exchange_air/selectors";

export function toSearch() {
    return {type: types.AIR_TO_SEARCH_RESULT};
}

export const backToSearch = () => (dispatch, getState) => {
    const searchType = getAirSearchType(getState());

    dispatch(resetCheckoutFormFields(['fakeBooking']));

    if (isAirSeparatedTicketsMode(getState())) {
        dispatch({type: types.AIR_FARE_FAMILIES_RESET_SEPARATED_TICKETS});
    }

    if (searchType === AIR_SEARCH_TYPES.AVAILABILITY) {
        browserHistory.push('/ui/air_availability/');
        return dispatch(toAirPricing());
    }

    if (!isAirExchangeFlow(getState())) {
        browserHistory.push('/ui/air/search/');
    }
    return dispatch(toSearch());
};
