import noop from 'lodash/noop';
import pick from 'lodash/pick';
import api from 'midoffice/helpers/api';
import {getAirRequestParams} from 'airborne/store/modules/search_air/selectors';
import {injectRecentSearchesParams} from 'airborne/search_air/helpers';

export function sendRecentAirSearch(state) {
    const params = getAirRequestParams(state);
    const airRecentSearchParams = pick(
        injectRecentSearchesParams(state, params),
        [
            'configuration_id', 'origin_destinations', 'travelers',
            'included_baggage', 'refundable_only',
            'changeable_only',
            'exclude_penalties', 'airline_codes', 'airline_alliances',
        ]
    );

    api('POST', '/internal/update_air_recent_searches/', {data: airRecentSearchParams})
        .then(noop, noop);

}
