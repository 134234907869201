import get from 'lodash/get';
import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';
import {formatUrl} from 'midoffice/helpers/url';
import location from 'airborne/location';
import SentryAdapter from 'airborne/sentryAdapter';

function addCommonParams(params, system) {
    return {
        ...params,
        'system': system || settings.SUB_PARTNER,
        'locale': settings.USER_LOCALE,
        'session_id': settings.sid || 'none',
    };
}

export function handleApiError(response) {
    if (response instanceof Error) {
        SentryAdapter.captureException(response, {tags: {api: 'asb'}});
    }
    if (get(response, 'request.isAborted')) {
        return null;
    }
    throw response;
}

export default function air(meth, url, params, system=null) {
    const apiUrl = `/latest${url}`;
    return (meth === 'POST'
        ? api(meth, apiUrl, {data: addCommonParams(params, system)})
        : api(meth, formatUrl(apiUrl, addCommonParams(params, system))))
        .catch((response)=> {
            if (response && response.status === 401) {
                location.assign(formatUrl('/accounts/login/', {next: location.pathname + location.search}));
            }
            return Promise.reject(response);
        });
}
