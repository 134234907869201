import {
    CONFIRM_SHOW, CONFIRM_HIDE, CONFIRM_ACCEPT, CONFIRM_CANCEL,
} from './types';


export function showConfirm({id, title, content, buttonOk, buttonOkClass, buttonCancel, progress}) {
    return {type: CONFIRM_SHOW, id, title, content, buttonOk, buttonOkClass, buttonCancel, progress};
}

export function closeConfirm() {
    return {type: CONFIRM_HIDE};
}

export function acceptConfirm(progress) {
    return function (dispatch) {
        dispatch({type: CONFIRM_ACCEPT});
        !progress && dispatch(closeConfirm());
    };
}

export function cancelConfirm(progress) {
    return function (dispatch) {
        dispatch({type: CONFIRM_CANCEL});
        !progress && dispatch(closeConfirm());
    };
}
