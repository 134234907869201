import SentryAdapter from 'airborne/sentryAdapter';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {
    clearFlightPriceErrors, flightPriceWithUpdatedKeys,
} from 'airborne/store/modules/search_air/actions/flightPrice';
import {requestSeatMap, requestSingleSeatMap} from 'airborne/store/modules/search_air/actions/seatMap';
import {
    resetCheckoutFormFields,
    resetTicketOptionalServices
} from 'airborne/store/modules/checkout_air/actions/checkoutForm';
import * as actionTypes from '../actionTypes';
import {
    getFareGroupKey,
    getFlightOptionKeys,
    isFareFamilyGroupsFetched,
    getSelectedFareFamilyGroupKeys,
    getFareFamiliesSelection,
    getSeparatedTicketSelectedFareFamily,
} from 'airborne/store/modules/search_air/selectors';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {extractFareKeys, getUniqueFareKey, parseFareFamilies} from 'airborne/search_air/helpers';
import {getErrorMessages} from 'airborne/helpers/apiError';
import {setFareKeys} from 'airborne/store/modules/search_air/actions/toBook';
import {
    clearAllSeatSelection,
    clearTicketSeatSelection
} from 'airborne/store/modules/checkout_air/actions/seatSelection';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {getAirExchangeBookingKey, isAirManualExchange} from 'airborne/store/modules/exchange_air/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {addPolicyRules, parsePolicyRules} from 'airborne/store/modules/search_air/actions/searchAir';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";
import fare from "airborne/checkout_air/BrandedFares/Fare";

const setFareFamiliesData = data => ({type: actionTypes.AIR_FARE_FAMILIES_SET_DATA, data});
const setFareFamilies = (fareGroupKey, flightOptionKeys, ODIndex) => ({
    type: actionTypes.AIR_FARE_FAMILIES_SELECT_FAMILY,
    fareGroupKey,
    flightOptionKeys,
    ODIndex,
});
export const setFareFamilyGroup = (fareFamilyKey, ODIndex)=>
    ({type: actionTypes.AIR_FARE_FAMILIES_SELECT_FARE, fareFamilyKey, ODIndex});
const setFareFamiliesLoading = loading => ({type: actionTypes.AIR_FARE_FAMILIES_SET_LOADING, loading});
const setFareFamiliesErrors = errors => ({type: actionTypes.AIR_FARE_FAMILIES_SET_ERRORS, errors});

export const resetCheckoutSelection = (ticketIndex) => (dispatch, getState) => {
    const isSeparatedTickets = isAirSeparatedTicketsMode(getState());
    // We should reset optional services selection and seat selection,
    // because each group has different optional services and seat map
    if (isSeparatedTickets) {
        dispatch(resetTicketOptionalServices(ticketIndex));
        dispatch(clearTicketSeatSelection(ticketIndex));
    } else {
        dispatch(resetCheckoutFormFields(['optional_services']));
        dispatch(clearAllSeatSelection());
    }
};

export const requestFareFamiliesDeps = (fareGroupKey, flightOptionKeys, ticketIndex) => async (dispatch) => {

    dispatch(clearFlightPriceErrors());

    const response = await dispatch(flightPriceWithUpdatedKeys({fareGroupKey, flightOptionKeys, ticketIndex}));
    const [pricingFareGroupKey, pricingFlightOptionKeys] = extractFareKeys(response);
    await dispatch(requestSingleSeatMap(pricingFareGroupKey, pricingFlightOptionKeys));
};

export const selectOriginalFare = () => (dispatch, getState) => {
    const {fareGroupKey, flightOptionKeys} = getFareFamiliesSelection(getState());

    dispatch(setFareKeys(fareGroupKey, flightOptionKeys));
    dispatch(setFareFamilyGroup(null));
    dispatch(resetCheckoutSelection());
    dispatch(requestFareFamiliesDeps(fareGroupKey, flightOptionKeys));
};

export const selectFareFamilyGroup = (fareFamilyKey, ticketIndex) => (dispatch, getState) => {
    dispatch(setFareFamilyGroup(fareFamilyKey, ticketIndex));

    const isSeparatedTickets = isAirSeparatedTicketsMode(getState());

    dispatch(resetCheckoutSelection(ticketIndex));

    // We are setting keys selection anyway,
    // because a lot of places tied to this value
    // to get current flightPrice / seatMap data from store using these keys
    const [fareGroupKey, flightOptionKeys] = isSeparatedTickets
        ? extractFareKeys(getSeparatedTicketSelectedFareFamily(getState(), ticketIndex))
        : getSelectedFareFamilyGroupKeys(getState());

    dispatch(setFareKeys(fareGroupKey, flightOptionKeys, ticketIndex));

    dispatch(requestFareFamiliesDeps(fareGroupKey, flightOptionKeys, ticketIndex));
};

const resetFareFamilySelection = () => (dispatch) => {
    dispatch(resetCheckoutSelection());
    dispatch(setFareFamilyGroup(null));
};

const handleResponseError = (dispatch, response, rethrow) => {
    if (response instanceof Error) {
        SentryAdapter.captureException(response);
    }
    if (rethrow) throw response;

    dispatch(setFareFamiliesErrors(getErrorMessages(response)));

    return {'fare_groups': []};
};

const getFareFamilies = (rethrow, ticketIndex) => async (dispatch, getState) => {
    const state = getState();

    if (isFareFamilyGroupsFetched(state, ticketIndex)) {
        return Promise.resolve();
    }

    const fareGroupKey = getFareGroupKey(state, ticketIndex);
    const flightOptionKeys = getFlightOptionKeys(state, ticketIndex);
    const currency = getCurrency(state);
    const {company} = getPnrProfile(state, 0);
    const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});
    const isManualExchange = isAirManualExchange(state);

    try {
        dispatch(setFareFamiliesLoading(true));
        const response = await air('POST', '/air/pricing/', dropEmpty({
            'currency': currency || 'EUR',
            'configuration_id': getSelectedConfiguration(state) || company,
            'fare_group_key': fareGroupKey,
            'flight_option_keys': flightOptionKeys,
            'fare_families': true,
            'exchange_key': getAirExchangeBookingKey(state),
            'is_manual_exchange': isManualExchange,
        })).catch((response) => handleResponseError(dispatch, response, rethrow));

        const policyRules = parsePolicyRules(response);
        dispatch(addPolicyRules(policyRules));

        const data = parseFareFamilies(response);
        dispatch(setFareFamiliesData({[storeKey]: data}));
        return response;
    }
    finally {
        dispatch(setFareFamiliesLoading(false));
    }
};

export const requestFareFamilies = ({rethrow = false, keepSelection = false, ticketIndex} = {}) => async (dispatch, getState) => {
    const fareGroupKey = getFareGroupKey(getState(), ticketIndex);
    const flightOptionKeys = getFlightOptionKeys(getState(), ticketIndex);
    dispatch(setFareFamilies(fareGroupKey, flightOptionKeys, ticketIndex));

    if (!keepSelection) {
        dispatch(resetFareFamilySelection());
    }

    try {
        // We don't need to wait until seat map request is finished
        dispatch(requestSeatMap(ticketIndex));
        return await dispatch(getFareFamilies(rethrow, ticketIndex));
    }
    catch (response) {
        dispatch(setFareFamiliesErrors(getErrorMessages(response)));
        if (rethrow) throw response;
        return Promise.resolve();
    }
};
