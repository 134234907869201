import partial from 'lodash/partial';

import {
    compareSteps as baseCompareSteps,
    stepAfter as baseStepAfter,
    stepBefore as baseStepBefore,
    toStep as baseToStep,
} from 'airborne/common/steps';

const STEPS = ['general','payment', 'details'];
const COL_DEL_STEPS = ['general', 'collectionDelivery','payment', 'details'];

const getSteps = () => JSON.parse(sessionStorage.getItem('withCollectionAndDelivery')) ? COL_DEL_STEPS : STEPS;
export const getCompareSteps = () => partial(baseCompareSteps, getSteps());
export const getStepBefore = () => partial(baseStepBefore, getSteps());
export const getStepAfter = () => partial(baseStepAfter, getSteps());
export const getToStep = () => partial(baseToStep, getSteps());
