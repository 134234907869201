import omit from 'lodash/omit';
import {
    parseCategories,
    parseSorters,
    serializeSorter,
} from './hotelSorting';

export function parse(data) {
    const {'cars:sorters': sorters, 'sorter_choices': choices} = data;
    return {
        'sorter_categories': parseCategories(choices),
        'cars:sorters': parseSorters(sorters),
    };
}

export function serialize(data) {
    const {'cars:sorters': sorters} = data;
    return {
        'cars:sorters': sorters.map(serializeSorter),
    };
}

export function fromParent(data) {
    return omit(data, 'sorter_categories');
}
