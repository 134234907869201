import React from 'react';
import PT from 'prop-types';
import gettext from 'airborne/gettext';
import {BaggageAttrShape} from 'airborne/search_air/shapes';
import {getBaggageWeightText, isBaggageIncluded, isBaggageWeightExist} from 'airborne/search_air/helpers';
import Glyphicons from 'midoffice/components/Glyphicons';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

export default class Baggage extends React.Component {
    static propTypes = {
        baggage: BaggageAttrShape,
        icon: PT.bool,
        highlightText: PT.bool,
        className: PT.string,
    };

    renderText() {
        const {baggage} = this.props;
        const included = isBaggageIncluded(baggage);

        if (included === true) {
            return <span className="highlight-green">{gettext('Included')}</span>;
        }

        if (included === false) {
            return <span className="highlight-red">{gettext('Not Included')}</span>;
        }

        return <span className="highlight-gray">{gettext('Unknown')}</span>;
    }

    renderWeightAndPieces() {
        const {baggage} = this.props;

        if (isBaggageWeightExist(baggage)) {
            const weightText = getBaggageWeightText(baggage);
            return weightText && `• ${weightText}`;
        }

        return '';
    }

    renderDescription(withText) {
        const {baggage} = this.props;
        const description = baggage?.description;

        if (description) {
            return (
                <AnnotateTooltip id="baggage-description">
                    {withText ? (
                        <span className="highlight-blue border-bottom">
                            {gettext('Show Details')} <span className="glyphicons glyphicons-chat" />
                        </span>
                    ) : (
                        <span className="glyphicons glyphicons-chat" />
                    )}
                    {description}
                </AnnotateTooltip>
            );
        }

        return null;
    }

    render() {
        const {baggage, icon, className} = this.props;
        const isOnlyDescriptionExist = Boolean(
            isBaggageIncluded(baggage) == null && !isBaggageWeightExist(baggage) && baggage?.description
        );

        return (
            <div className={className} data-testid="fare-group-baggage-attribute">
                {icon ? <Glyphicons glyph="luggage" /> : `${gettext('Baggage')}: `}
                {isOnlyDescriptionExist ? (
                    this.renderDescription(true)
                ) : (
                    <span>
                        {this.renderText()} {this.renderWeightAndPieces()} {this.renderDescription(false)}
                    </span>
                )}
            </div>
        );
    }
}
