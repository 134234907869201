import noop from 'lodash/noop';
import pick from 'lodash/pick';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';

import {loadCarAvailParams} from 'airborne/store/modules/search_cars/actions/searchRequest';

import {searchForm} from 'airborne/homepage2/helpers/search';
import {dropEmpty} from 'midoffice/helpers/urlParams';

/* We like API consistency, but not when it makes EP write more code,
 * that's why we dont use first two */
function serializeDestination3(value) {
    const [type, code] = value.split(':', 2);
    return type === 'Hotel'
        ? {hotel: code}
        : {destination: code};
}

export function markRecentSearch({destination, 'hotel_name': keywords, dates, chains, distance}, configId) {
    const search = dropEmpty({
        'checkin': dates.min,
        'checkout': dates.max,
        ...serializeDestination3(destination.value),
        'destination_name': destination.label,
        'destination_type': destination.type,
        'configuration_id': configId,
        'distance': distance || settings.USER.search_radius,
        'hotel_name_keyword': keywords,
        chains,
    });
    return api('POST', '/internal/update_hotels_recent_searches/', {data: search})
        .then(noop, noop);
}

function locationDetails({type, label, value}, prefix) {
    const hotel = type === 'h' ? {
        [`${prefix}_location_hotel`]: value,
    } : null;

    return {
        [`${prefix}_location_type`]: type,
        [`${prefix}_location_name`]: label,
        ...hotel,
    };
}

export function markRecentCarSearch(state) {
    const preparedRequest = loadCarAvailParams(state);

    let {dest: {pickUp, dropOff, differentDropOff}} = searchForm(state, 0);
    dropOff = differentDropOff ? dropOff : pickUp;

    const {'configuration_id': configId, 'car_types': carType} = preparedRequest;

    const recentParams = dropEmpty({
        'configuration_id': configId,

        ...pick(preparedRequest, 'pickup_datetime', 'pickup_search_radius', 'pickup_location'),
        ...locationDetails(pickUp, 'pickup'),

        ...pick(preparedRequest, 'dropoff_datetime', 'dropoff_search_radius', 'dropoff_location'),
        ...(differentDropOff ? locationDetails(dropOff, 'dropoff') : {}),

        'different_dropoff': differentDropOff,
        'car_types': carType,
    });

    return api('POST', '/internal/update_cars_recent_searches/', {data: recentParams})
        .then(noop, noop);

}
