import without from 'lodash/without';
import {PRICE_FIELD} from './price';


function policyValue(list) {
    if (!list || list.length !== 1) {
        return null;
    }

    const [value] = list;
    if (value === 'in') {
        return false;
    }

    if (value === 'out') {
        return true;
    }

    return null;
}

const roundTyFilter = (tyRating) => {
    if (tyRating > 0.5) {
        return tyRating - 0.5;
    }
    return tyRating;
};

export default function convertFilters({
    name,
    neighborhood,
    preference,
    stars,
    tyRating,
    ecoFriendly,
    femaCompliance,
    amenities,
    price={},
    policy,
    iataGuarantee,
    chain,
    locations,
    covidPrecautions,
    propertyTypes,
    securityVetted,
}, single) {
    const ret = [
        {field: 'available.is_excluded', op: 'eq', value: single ? null : false},
        {field: 'name', op: 'includes', value: name || null},
        {field: 'regions.neighborhood', op: 'intersect', value: neighborhood || null},
        {field: 'preferredBy', op: 'intersect', value: preference || null},
        {field: 'stars', op: 'in', value: stars || null},
        {field: 'trustyou_rating', op: 'gte', value: roundTyFilter(tyRating) || null},
        {field: 'has_ecofriendly_badge', op: 'eq', value: ecoFriendly || null},
        {field: 'security_vetted', op: 'eq', value: securityVetted || null},
        {field: 'is_fema_compliant', op: 'eq', value: femaCompliance || null},
        {field: 'allAmenities', op: 'intersectAnd', value: amenities || null},
        {field: 'allAmenities', op: 'intersectAnd', value: covidPrecautions || null},
        {field: 'property_type', op: 'in', value: propertyTypes || null},
        {field: 'available.is_available', op: 'eq', value: price.available || null},
        {field: PRICE_FIELD, op: 'gte', value: price.min || null, matchNull: true},
        {field: PRICE_FIELD, op: 'lte', value: price.max || null, matchNull: true},
        {field: 'available.out_of_policy', op: 'eq', value: policyValue(policy)},
        {field: 'iata_guarantee', op: 'eq', value: iataGuarantee || null},
        {field: 'chains', op: 'intersect', value: chain || null},
        {field: 'location', op: 'noop', value: (locations && locations.length) ? locations: null},
        {field: 'is_on_request_hotel', op: 'inverse', value: price.isOnRequestHotel || null},
    ].filter(({value, op})=> value !== null || op === 'inverse');
    return ret;
};

export function getFilters(dest) {
    const {view, options: {filters = []}} = dest;

    return view === 'list' ? filters : without(filters, 'COMPANY_LOCATIONS');
}
