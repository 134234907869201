import {combineReducers} from 'redux';
import * as types from '../actionTypes';

const defaultData = null;
const data = (state = defaultData, {type, data}) => {
    switch (type) {
        case types.AIR_PRICING_SET_FARES_DATA:
            return data;
        case types.AIR_PRICING_RESET:
            return defaultData;
        default:
            return state;
    }
};

const defaultLoading = false;
const loading = (state = defaultLoading, {type}) => {
    switch (type) {
        case types.AIR_PRICING_SET_FARES_LOADING:
            return true;
        case types.AIR_PRICING_SET_FARES_DATA:
        case types.AIR_PRICING_SET_FARES_ERRORS:
            return defaultLoading;
        default:
            return state;
    }
};

const defaultErrors = null;
const errors = (state = defaultErrors, {type, errors}) => {
    switch (type) {
        case types.AIR_PRICING_SET_FARES_ERRORS:
            return errors;
        case types.AIR_PRICING_SET_FARES_DATA:
        case types.AIR_PRICING_RESET:
            return defaultErrors;
        default:
            return state;

    }
};

const defaultErrorCodes = [];

const errorCodes = (state = defaultErrorCodes, {type, errorCodes}) => {
    switch (type) {
        case types.AIR_PRICING_SET_FARES_ERROR_CODES:
            return errorCodes;
        case types.AIR_PRICING_SET_FARES_DATA:
        case types.AIR_PRICING_RESET:
            return defaultErrorCodes;
        default:
            return state;

    }
}

const defaultInitialView = true;
const initialView = (state = defaultInitialView, {type}) => {
    switch (type) {
        case types.AIR_PRICING_SET_FARES_DATA:
        case types.AIR_PRICING_SET_FARES_ERRORS:
            return false;
        case types.AIR_PRICING_RESET:
            return defaultInitialView;
        default:
            return state;
    }
};

const fares = combineReducers({
    data,
    loading,
    errors,
    errorCodes,
    initialView,
});

export default fares;
