import timeout from 'airborne/search2/helpers/timeout';

import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import destinationModal from 'airborne/store/modules/homepage/actions/destmodal';

import {showError} from 'airborne/store/modules/search_hotels/actions';
import {apiError} from 'airborne/search_cars/helpers/apiError';
import {isCars} from 'airborne/store/modules/homepage/selectors/product';

import {searchRequest, mergeResults, areTwoRequestsNeeded} from './searchRequest';

import {markRecentCarSearch} from 'airborne/store/modules/search_hotels/actions/recentSend';
import {parseList} from 'airborne/search_cars/types';
import browserHistory from 'airborne/browserHistory';

const WAIT_SWITCH = 2000;

const COMPANY_LEVEL = 'company';
const AGENCY_LEVEL = 'agency';

async function getResponseCd(state) {
    try {
        return await searchRequest(state, COMPANY_LEVEL);
    }
    catch (e) {
        return {data: [], total: 0};
    }
}
async function getResponseAgency(state) {
    try {
        return await searchRequest(state, AGENCY_LEVEL);
    }
    catch (response) {
        return {data: [], total: 0, errors: apiError(response), errorCode: response.body.errors[0]['error_code']};
    }
}

function loadRates() {
    return async function loadRatesD(dispatch, getState) {
        dispatch({type: 'CARS_RATES_LOADING'});
        const state = getState();

        if (areTwoRequestsNeeded(state)) {
            const responseAgency = await getResponseAgency(state);
            const responseCd = await getResponseCd(state);
            const {data, total} = mergeResults(responseCd, responseAgency);
            if (total === 0) {
                if (responseAgency.errorCode === 1076000) {
                    dispatch({
                        type: 'MODAL_SHOW',
                        kind: 'restrictedDestination',
                        message: responseAgency.errors[0],
                    });
                }
                return dispatch({
                    type: 'CARS_RATES_EMPTY',
                    errors: responseAgency.errors,
                    errorCode: responseAgency.errorCode,
                });
            }
            return dispatch({type: 'CARS_RATES_LOADED', data: parseList(data), total});
        }
        else {
            const {data, total} = await searchRequest(state);
            return dispatch({type: 'CARS_RATES_LOADED', data, total});
        }
    };
}

function catch404(dispatch, response) {
    if (response.status === 404) {
        if (response.body.errors[0]['error_code'] === 1076000) {
            dispatch({
                type: 'MODAL_SHOW',
                kind: 'restrictedDestination',
                message: apiError(response)[0],
            });
        }
        return {type: 'CARS_RATES_EMPTY', errors: apiError(response), errorCode: response.body.errors[0]['error_code']};
    }
    throw response;
}

function startNewSearch(saveFilters) {
    return function startNewSearchD(dispatch, getState) {
        const destination = getDestFormValue(getState());
        dispatch({type: 'CARS_START_SEARCH', destination, saveFilters});
    };
}

function switchList() {
    return {type: 'CARS_TO_SEARCH_RESULT'};
}

function startCarSearch(rethrow=false) {
    return function startCarSearchD(dispatch) {
        return dispatch(loadRates())
            .catch((response)=> dispatch(catch404(dispatch, response)))
            .catch((response)=> {
                dispatch(showError(apiError(response), 'search'));
                dispatch({type: 'CARS_RATES_FAIL'});
                return rethrow ? Promise.reject(response) : null;
            });
    };
}


export default function searchCars(saveFilters) {
    return async function searchD(dispatch, getState) {
        const destination = getDestFormValue(getState());
        try {
            await dispatch(destinationModal('pickUp'));
            destination.differentDropOff && await dispatch(destinationModal('dropOff'));

            dispatch(startNewSearch(saveFilters));

            markRecentCarSearch(getState());

            const searchPromise = dispatch(startCarSearch(true));
            await Promise.race([searchPromise, timeout(WAIT_SWITCH)]);

            dispatch(switchList());
            browserHistory.push('/ui/cars/search/');
            return await searchPromise;
        }
        catch (e) {
            return null;
        }
    };
}

export function reloadCarSearch() {
    return function reloadCarSearchD(dispatch, getState) {
        const state = getState();
        const onTheSearchPage = location.pathname.includes('/ui/cars/search');
        return isCars(state) && onTheSearchPage ? dispatch(searchCars()): Promise.resolve();
    };
}
