import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import systemData from 'airborne/systemData';

export function getAirlineChoices() {
    return Object.entries(settings.AIRLINES)
        .map(([code, name]) => [code, `${name} (${code})`]);
}

export function getAllianceChoices() {
    return Object.keys(systemData.air.AIR_ALLIANCES)
        .map((alliance) => [alliance, alliance]);
}

export function getAirTripTypeTuples() {
    return [
        [AIR_TRIP_TYPES.ONE_WAY, gettext('One way')],
        [AIR_TRIP_TYPES.ROUND_TRIP, gettext('Round trip')],
        [AIR_TRIP_TYPES.MULTI_CITY, gettext('Multi-city')],
    ];
}

export function getCabinClassChoices() {
    const allowedCabinClasses = [
        'economy',
        'premium_economy',
        'business',
        'first',
    ];
    return allowedCabinClasses.map(cabinClass => {
        return [cabinClass, settings.CABIN_CLASSES?.[cabinClass] || cabinClass];
    });
}

export function isRoundTrip(destinations) {
    if (destinations.length !== 2) {
        return false;
    }

    const [
        {pickUp: {iataCode: outboundPickUpCode}, dropOff: {iataCode: outboundDropOffCode}},
        {pickUp: {iataCode: inboundPickUpCode}, dropOff: {iataCode: inboundDropOffCode}},
    ] = destinations;

    return (
        outboundDropOffCode === inboundPickUpCode &&
        inboundDropOffCode === outboundPickUpCode
    );
}

export function getTripType(destinations) {
    if (destinations.length === 1) {
        return AIR_TRIP_TYPES.ONE_WAY;
    }

    if (isRoundTrip(destinations)) {
        return AIR_TRIP_TYPES.ROUND_TRIP;
    }

    return AIR_TRIP_TYPES.MULTI_CITY;
}

export function isOneWay(tripType) {
    return tripType === AIR_TRIP_TYPES.ONE_WAY;
}

export function isOneWayMode(tripType) {
    return [AIR_TRIP_TYPES.ONE_WAY, AIR_TRIP_TYPES.MULTI_CITY].includes(tripType);
}

export function handleTripTypeChange(value, errors, tripType) {

    return {
        value: {
            ...value,
            tripType,
            originDestinations: value.originDestinations.slice(0, 1).map(OD => ({
                ...OD,
                timeRestrictionInbound: {
                    time: null,
                    timeWindow: 2,
                },
                dateRange: {min: OD.dateRange?.min, max: null},
            })),
            connections: value.connections?.slice(0, 1),
        },
        errors: {
            ...errors,
            originDestinations: errors?.originDestinations?.slice(0, 1).map(OD => ({
                ...OD,
                dateRange: OD.dateRange?.min
                    ? {min: OD.dateRange.min}
                    : null,
                timeRestrictionInbound: null,
            })) || null,
            connections: errors?.connections?.slice(0, 1) || null
        },
    };
}

export function getSearchChoicesValue(value, searchChoices) {
    return searchChoices
        .map(([key]) => key)
        .filter(key => value[key]);
}

export function handleSearchOptionsChange(oldValue, newValue, searchChoices) {
    const choices = searchChoices.map(([key]) => key);
    const selectedPairs = choices.reduce((acc, choice) =>
        ({
            ...acc,
            [choice]: newValue.includes(choice)
        }), {}
    );

    return {
        value: {
            ...oldValue,
            ...selectedPairs
        }
    };
}
