import get from 'lodash/get';

export function getUniqueFareKey({fareGroupKey, flightOptionKeys}) {
    return [fareGroupKey, ...flightOptionKeys].join('|');
}

export function getFullFlightCodeFromSegment(segment) {

    const {code, flightNumber, flightNumberSuffix} = get(segment, 'carrier', {});

    return `${code || ''}${code && flightNumber ? '-' : ''}${flightNumber || ''}${flightNumberSuffix || ''}`;
}

export function extractFlightOptionKeys(originDestinations) {
    return originDestinations.map(originDestination => originDestination?.flightOptionKey);
}

export function extractFareKeys(fareGroup) {
    const {fareGroupKey, originDestinations} = fareGroup;
    const flightOptionKeys = extractFlightOptionKeys(originDestinations);

    return [fareGroupKey, flightOptionKeys];
}
