import settings from 'airborne/settings';
import moment from 'moment';
import partial from 'lodash/partial';
import omit from 'lodash/omit';
import flow from 'lodash/flow';
import curry from 'lodash/curry';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';

import {getPnrProfile, getHotelsPaymentPriority} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import emailValidator from 'midoffice/newforms/validators/email';

import normalize from 'airborne/search2/helpers/normalize';
import {isCNPConfiguredToAsk} from 'airborne/search2/helpers/hyatt';
import {matchConditions, isCrdKey} from './crd';

import {getSelectedRate, getSelectedHotel} from 'airborne/search2/helpers/rates';

import {
    getBooking,
    vpaCardPools,
    vpaPaymentInstructions,
} from 'airborne/store/modules/checkout_hotels/selectors/booking';
import {getCardType} from 'airborne/checkout2/helpers/cardValidators';
import {getPaidBy} from 'airborne/checkout_cars/helpers/checkoutForm';

//It is realy needed for Gettext Static Extractor
const gettext = (item) => item;

const SUPPORTED_SALUTATION = [
    gettext('Mr'),
    gettext('Mrs'),
    gettext('Ms'),
    gettext('Miss'),
];

const SUPPORTED_SALUTATION_PROVIDERS = [
    'hcorpo',
    'premierinn',
    'totalstay',
];

const RATETYPE_POLICY_CONDITIONS = ['out_of_policy', 'in_policy', 'guarantee', 'deposit', 'evoucher_vpa_guarantee', 'evoucher_card', 'evoucher_iata'];

export function getCurrentStep(state, index) {
    return getBooking(state, index).step;
}

export function getSupportedSalutations() {
    return SUPPORTED_SALUTATION;
}

function isValidSalutation(salutation) {
    return getSupportedSalutations().includes(salutation);
}

function isValidProvider(provider) {
    return SUPPORTED_SALUTATION_PROVIDERS.includes(provider);
}

function isSalutationRequired(state, index) {
    const rate = getSelectedRate(state, index);
    const {value} = getBooking(state, index);
    return isValidProvider(rate.provider) && (value.isEmptySalutationByDefault || !isValidSalutation(value.salutation));
}

function andFn(...functions) {
    return function andFn(element) {
        return functions.reduce((acc, fn) => (acc && fn(element)), true);
    };
}

function filterType(types, {cardType}) {
    return types.includes(cardType);
}

export function validDatePolicy(checkout, paidPolicy, year, month) {
    // In case there is no data provided, let user enter it
    if (!year || !month) {
        return true;
    }
    // javascipt enumerates months from zero, so - 1
    const expirationDate = moment([year, month - 1])
        .endOf('month');
    const endMonth = moment().endOf('month');
    const minDate = (paidPolicy === 'G')
        ? moment(checkout).endOf('month').add(-1, 'day')
        : endMonth;
    return expirationDate.isAfter(minDate) && expirationDate.isAfter(endMonth);
}

function validCardPolicy(checkout, paidPolicy, card) {
    return validDatePolicy(checkout, paidPolicy, card.cardYear, card.cardMonth);
}

function filterCards(cards, rate, checkout) {
    if (!rate.paymentCardsAccepted || !cards) return [];
    const acceptedTypes = rate.paymentCardsAccepted.map(({code}) => code);
    return cards && cards.filter(
        andFn(
            partial(filterType, acceptedTypes),
            partial(validCardPolicy, checkout, rate.paidPolicy),
        ),
    );
}

export function selectCards(state, index) {
    const {'credit_cards': cards} = getBooking(state, index).settings || {};
    const checkout = moment(getDestination(state).dates.max, 'YYYY-MM-DD');
    const rate = getSelectedRate(state, index);
    return filterCards(normalize(cards), rate, checkout);
}

function getManualCardType(cardNumber) {
    const type = getCardType(cardNumber);
    return type && type.value || null;
}

function getTspmCardType(state, index, cardIndex) {
    const {'credit_cards': cards} = getBooking(state, index).settings || {};
    if (!cards) return null;
    const [card] = normalize(cards).filter((card) => card.cardIndex === cardIndex);
    return card && (card.cardType || '').toLowerCase() || null;
}

export function getCurrentCardType(state, index, cardIndex, cardNumber) {
    return cardIndex
        ? getTspmCardType(state, index, cardIndex)
        : getManualCardType(cardNumber);
}

function prefillTSPMCardName(value, card) {
    const shouldUsePnrName = !(card.firstName && card.lastName);
    return {
        'credit_card_first_name': shouldUsePnrName ? value['first_name'] : card.firstName,
        'credit_card_last_name': shouldUsePnrName ? value['last_name'] : card.lastName,
    };
}

function prefillFirstTspmCard(state, index, value) {
    const shouldPrefill = value.paid_by === 'card' && value.card_index === 'first';
    if (!shouldPrefill) {
        return value;
    }

    const [card] = selectCards(state, index);
    return card
        ? {
            ...value,
            'card_index': card.cardIndex,
            'credit_card_expiration': {
                year: card.cardYear,
                month: card.cardMonth,
            },
            ...prefillTSPMCardName(value, card),
        }
        : {
            ...value,
            'card_index': 'manual',
        };
}

function prefillManualCard(state, index, value) {
    const shouldPrefill = value['card_index'] === 'manual' && value['paid_by'] === 'card';

    if (!shouldPrefill) {
        return value;
    }

    return {
        ...value,
        'card_index': null,
        'credit_card_expiration': {
            year: null,
            month: null,
        },
        'credit_card_first_name': value['first_name'] || null,
        'credit_card_last_name': value['last_name'] || null,
    };
}

function filterLoyaltyCard(state, field, index) {
    const hotel = getSelectedHotel(state, index);
    const programCodes = hotel.chainInfo.map((chain) => chain[field]);
    return (lcard) => programCodes.includes(lcard['chain_code']);
}

function selectLoyalty(state, index) {
    const {'loyalty_cards': lcards} = getBooking(state, index).settings || {};
    return normalize([
        ...(lcards || []).filter(filterLoyaltyCard(state, 'chainCode', index)),
        ...(lcards || []).filter(filterLoyaltyCard(state, 'masterChainCode', index)),
    ]);
}

function prefillLoyalty(state, index, value) {
    if (value.loyalty_card !== 'first') {
        return value;
    }
    const [card] = selectLoyalty(state, index);
    return {
        ...value,
        'loyalty_card': card && card.cardIndex || null,
    };
}

function fixLoyalty(state, index, value) {
    const rate = getSelectedRate(state, index);
    return rate.loyaltyCardAllowed
        ? value
        : omit(value, 'loyalty_card');
}

function prefillHcorpoRequests({'is_smoking': smoke}) {
    return {
        [false]: 'NO_SMOKING_ROOM',
        [true]: 'SMOKING_ROOM',
    }[smoke] || null;
}

function prefillTextRequests(data) {
    return data['special_requests'] || null;
}

function prefillSpecialRequest(state, index, {'special_requests_auto': auto, ...value}) {
    const {checkoutData} = getBooking(state, index);
    const rate = getSelectedRate(state, index);

    if (rate && rate.specialRequests && !rate.specialRequests.srAllowed) {
        return omit(value, ['specialRequests']);
    }
    if (!auto) return value;

    return {
        ...value,
        'special_requests': (rate.provider === 'hcorpo')
            ? prefillHcorpoRequests(checkoutData)
            : prefillTextRequests(checkoutData),
    };
}

function defaultVpaGuarantee(state, index, value) {
    if (value.paid_by !== 'vpa_guarantee') {
        return value;
    }
    const [defaultPool, ...rest] = vpaCardPools(state, index);
    const defaultValues = {
        'vpa_card_pool_id': defaultPool && !rest.length && defaultPool[0] || null,
    };

    return {
        ...defaultValues,
        ...value,
    };
}

function defaultVpaPayment(state, index, value) {
    if (value.paid_by !== 'vpa_payment') {
        return value;
    }
    const [defaultPool, ...rest] = vpaCardPools(state, index);
    const defaultValues = {
        'vpa_card_pool_id': defaultPool && !rest.length && defaultPool[0] || null,
        'vpa_payment_instructions': vpaPaymentInstructions(state, index),
        'vpa_no_send_fax': false,
    };
    return {...defaultValues, ...value};
}

function fixEvoucher(value, rate) {
    const option = value['evoucher']
        ? `evoucher_${ value['paid_by'] }`
        : value['paid_by'];
    return rate.paymentOptions.includes(option)
        ? value
        : {...value, 'evoucher': false};
}

function fixPayment(state, index, value) {
    const rate = getSelectedRate(state, index);
    const filteredOptions = rate.paymentOptions.filter(
        (method) => !method.startsWith('evoucher_'),
    );
    const isOnlyVpaPayments = filteredOptions.every(opt => opt.startsWith('vpa'));
    const vpaDefault = filteredOptions.find(opt => opt === 'vpa_payment');
    const firstOption = !filteredOptions.includes('card') && vpaDefault && isOnlyVpaPayments ? vpaDefault : filteredOptions[0];
    value = fixEvoucher(value, rate);
    return rate.paymentOptions.includes(value['paid_by'])
        ? value
        : {...value, 'paid_by': firstOption};
}

function fixSalutation(state, index, value) {
    if (isSalutationRequired(state, index) && !isValidSalutation(value.salutation)) {
        return {
            ...value,
            salutation: 'Mr',
        };
    }
    return value;
}

export function prefillCrd(value, question) {
    return value.hasOwnProperty(question.code)
        ? value
        : {...value, [question.code]: question.default || null};
}

function filterCrd(state, index, value) {
    const rate = getSelectedRate(state, index);
    const crd = get(getBooking(state, index), 'settings.crd') || [];
    // filteredCrd is temporary until BE have not done migrations to remove duplicate crd questions with same code and name
    const filteredCrd = crd.filter(crdQuestion => crdQuestion.conditions && (crdQuestion.conditions.includes(value['paid_by']) || crdQuestion.conditions.some(condition => RATETYPE_POLICY_CONDITIONS.includes(condition)) || crdQuestion.conditions.length === 0));
    const crdKeyed = keyBy(filteredCrd, 'code');
    const matchFn = partial(matchConditions, rate, value['paid_by'], value['evoucher']);
    return pickBy(
        filteredCrd.reduce(prefillCrd, value),
        (value, key) => !isCrdKey(key) || matchFn(crdKeyed[key] || {}),
    );
}

function prefillBillingAdressPhone(state, index, value) {
    if (value['credit_card_phone']) {
        return value;
    }

    return {
        ...value,
        'credit_card_phone': {},
    };
}

function prefillCounterOfferEmail(state, index, value) {
    if ('counter_offer_email' in value) {
        return value;
    }

    const prefilled = get(
        getBooking(state, index),
        'checkoutData.default_counter_offer_email',
    );

    return {...value, 'counter_offer_email': prefilled || null};
}

function defaultPayment(state, index, value) {
    const paymentPriority = getHotelsPaymentPriority(state) || settings.ALLOWED_PAYMENT_OPTIONS?.map(option => option[0]);
    const {paymentOptions} = getSelectedRate(state, index);

    if (!paymentPriority || value.defaultPaidBy) {
        return value;
    }

    return {
        ...value,
        ...getPaidBy(paymentPriority, paymentOptions),
        defaultPaidBy: true,
    };
}

const GENERAL_PROCESSORS = [
    fixSalutation,
    prefillLoyalty,
    fixLoyalty,
    prefillSpecialRequest,
];
const PAYMENT_PROCESSORS = [
    ...GENERAL_PROCESSORS,
    defaultPayment,
    fixPayment,
    prefillFirstTspmCard,
    prefillBillingAdressPhone,
    prefillManualCard,
    defaultVpaPayment,
    defaultVpaGuarantee,
];
const DETAILS_PROCESSORS = [...PAYMENT_PROCESSORS, filterCrd];
const BOOK_PROCESSORS = [...DETAILS_PROCESSORS, prefillCounterOfferEmail];

const VALUE_PROCESSORS = {
    general: GENERAL_PROCESSORS,
    payment: PAYMENT_PROCESSORS,
    details: DETAILS_PROCESSORS,
    book: BOOK_PROCESSORS,
};

function getPrefillCheckoutValue(state, index) {
    const {value, step} = getBooking(state, index);
    return flow(VALUE_PROCESSORS[step].map(proc => curry(proc)(state, index)))(value);
}

export function getCheckoutErrors(state, index) {
    return getBooking(state, index).errors;
}

export function checkoutForm(state, index) {
    return {
        value: getPrefillCheckoutValue(state, index),
        errors: getCheckoutErrors(state, index),
    };
}

export function isSpecialRequiestPrefilled(state, index) {
    const {checkoutData} = getBooking(state, index);
    return Boolean(checkoutData.special_requests);
}

function getTspmEmail(state, index) {
    const profile = getPnrProfile(state, index);
    return profile ? profile['tspm_email'] : null;
}

function getCheckouData(state, index) {
    return getBooking(state, index).checkoutData;
}

//Check is there TSPM email and is it correct, because checkoutData has email without indication its source (tspm, pnr, pofile)
function hasCorrectTspmEmail(state, index) {
    const tspmEmail = getTspmEmail(state, index);
    const {email} = getCheckouData(state, index);
    return Boolean(tspmEmail) && emailValidator()(tspmEmail) === null && tspmEmail === email;
}

export function isReadonlyEmail(state, index) {
    return hasCorrectTspmEmail(state, index);
}

export function shouldDisplaySalutation(state, index) {
    const profile = getPnrProfile(state, index);
    const salutation = profile.pnr ? profile.pnr.pnr_name_title : profile.tspm_salutation;
    return isSalutationRequired(state, index) && !isValidSalutation(salutation);
}

export function selectSpecialRequestKind(state, index) {
    const rate = getSelectedRate(state, index);
    if (rate && rate.provider === 'hcorpo') {
        return 'complex';
    }
    if (rate && rate.specialRequests.srAllowed) {
        return 'text';
    }
    return null;
}

function showCNP(provider, paidPolicy) {
    return provider === 'premierinn' && paidPolicy === 'G';
}

export function isCNPEnabled(state, index) {
    const rate = getSelectedRate(state, index);
    return isCNPConfiguredToAsk(state) && showCNP(rate.provider, rate.paidPolicy);
}
