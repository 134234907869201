import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import findLastIndex from 'lodash/findLastIndex';
import map from 'lodash/map';
import moment from 'moment';
import omit from 'lodash/omit';
import {replaceAtIdx} from 'midoffice/helpers/editAtIdx';
import {toISODateString} from 'midoffice/helpers/date';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

const KEYS = {
    'companies': {
        hotels: 'client_preferred:hotels',
        dates: 'client_preferred:indices',
    },
    'agencies': {
        hotels: 'agency:tmc_preferred:hotels',
        dates: 'agency:tmc_preferred:indices',
    },
};


let lastKey = 0;
export function generateKey() {
    return ++lastKey;
}

export function resetKeyGenerator() {
    lastKey = 0;
}

export function normalizeList(programs) {
    programs = programs.slice();
    programs.sort((programA, programB)=> {
        return programB.date < programA.date;
    });

    return programs;
}

export function getStatuses(programs) {
    const TODAY = toISODateString(moment());
    const dates = map(programs, 'date');
    let statuses = dates.map((date)=> {
        if (date < TODAY) { return 'expired'; }
        if (date === TODAY) { return 'current'; }
        if (date > TODAY) { return 'future'; }
    });

    if (!statuses.includes('current') && statuses.includes('expired')) {
        const lastExpiredId = findLastIndex(statuses, (s)=> s === 'expired');
        statuses = replaceAtIdx(statuses, lastExpiredId, 'current');
    }

    return statuses;
}


export function parseHotels(hotels) {
    return Array.isArray(hotels) ? hotels.map((hotel)=> ({...hotel, orderIndex: generateKey()})) : [];
}


export function parse(data, {treeName}) {
    const dataKeys = KEYS[treeName];

    const {hotels: hotelsKey, dates: datesKey, tier: defaultTier} = dataKeys;

    const {[hotelsKey]: programs,
        [datesKey]: dates,
        [defaultTier]: dTier,
        ...rest} = data;
    dates.sort((a, b)=> new Date(a).getTime() - new Date(b).getTime());
    return {
        ...rest,
        programs: programs.map((program, index)=> ({
            hotels: parseHotels(program),
            date: dates[index],
            defaultTier: dTier,
            key: generateKey(),
        })),
    };
}

export function serialize(data, {treeName}) {
    const dataKeys = KEYS[treeName];

    const {hotels: hotelsKey, dates: datesKey, tier: defaultTier} = dataKeys;

    const {programs, ...rest} = data;
    const {hotels, dates, tiers} = programs.reduce((acc, {hotels, date, defaultTier})=> {
        acc.hotels.push(hotels.map((hotel)=> omit(hotel, 'orderIndex')));
        acc.dates.push(date);
        acc.tiers.push(defaultTier);
        return acc;
    }, {hotels: [], dates: [], tiers: []});
    const sortedHotels = dates.map((date, i)=> ({date, programs: hotels[i]}));
    sortedHotels.sort((a, b)=> new Date(a.date).getTime() - new Date(b.date).getTime());

    const hotelPrograms = sortedHotels.map(hotel => hotel.programs).filter(programs => programs.length);
    const hotelDates = isEmpty(hotelPrograms) ? [] : sortedHotels.filter(hotel => hotel.programs.length).map(hotel => hotel.date);
    const isSingleHotelChain = !isEmpty(hotelPrograms) && isEmpty(compact(hotelDates));

    return omitBy({
        ...rest,
        [hotelsKey]: hotelPrograms,
        [datesKey]:  isSingleHotelChain ? [moment().format()] : hotelDates,
        [defaultTier]: tiers[0],
    }, isNull);
}


function serializeHotelAgencyForm(data, freebies) {
    return {
        'free_wifi': freebies.includes('wifi') ? 'x' : '',
        'free_parking': freebies.includes('parking') ? 'x' : '',
        'free_internet': freebies.includes('internet') ? 'x' : '',
        'free_breakfast': freebies.includes('breakfast') ? 'x' : '',
        'is_bcd_great_rate_hotel': data.greatRate ? 'x' : '',
    };
}

function parseHotelAgencyForm(data) {
    const {
        'free_wifi': wifi,
        'free_parking': parking,
        'free_internet': internet,
        'free_breakfast': breakfast,
    } = data;

    const freebies = [];
    if (wifi) { freebies.push('wifi'); }
    if (parking) { freebies.push('parking'); }
    if (internet) { freebies.push('internet'); }
    if (breakfast) { freebies.push('breakfast'); }

    const {
        'is_bcd_great_rate_hotel': greatRateSymbol,
    } = data;
    return {
        freebies,
        greatRate: greatRateSymbol === 'x',
    };
}


export function serializeHotelForm(data, treeName) {
    const {freebies, hotelChain, ...hotel} = data;
    const {category, label, value} = hotelChain;
    const region = data.region ? data.region.label : '';

    return {
        ...hotel,
        'region': region,
        'hotel_name': category === 'hotel' ? label : '',
        'chain_name': category === 'chain' ? label : '',
        'provider_id': value,
        ...(treeName === 'agencies' ? serializeHotelAgencyForm(data, freebies) : {})
    };
}


export function parseHotelForm(data, treeName) {
    const {
        'provider_id': providerId,
        'hotel_name': hotelName,
        'chain_name': chainName,
        ...hotel
    } = data;

    const region = data.region ? {label: data.region} : '';
    const tier = data.tier ? Number(data.tier) : '';

    return {
        ...hotel,
        region,
        tier,
        hotelChain: {
            value: providerId,
            category: chainName ? 'chain' : 'hotel',
            label: chainName ? chainName : hotelName,
        },
        ...(treeName === 'agencies' ? parseHotelAgencyForm(data) : {}),
    };
}
