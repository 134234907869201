import isEqual from 'lodash/isEqual';


export default function listDiff(initial, current) {
    const intialKeys = initial.map((el)=> el.id);
    const initialMap = initial.reduce((acc, el)=> {
        acc[el.id] = el;
        return acc;
    }, {});
    const currentIds = current.map((el)=> el.id);
    const toDelete = intialKeys.filter((id)=> !currentIds.includes(id));
    const toAdd = current.filter((el)=> !el.id || !initialMap[el.id]);
    const toChange = current.filter((el)=> (
        initialMap[el.id] && !isEqual(el, initialMap[el.id])
    ));
    return {toAdd, toDelete, toChange};
}
