import omit from 'lodash/omit';
import flow from 'lodash/flow';

const POLICY_CONDITIONS = [
    [null, 'All Policies'],
    ['out_of_policy', 'Out of Policy Rate'],
    ['in_policy', 'In Policy Rate'],
];

const DEPOSIT_CONDITIONS = [
    [null, 'All Rates'],
    ['deposit', 'Deposit Rate'],
    ['guarantee', 'Guarantee Rate'],
];

function splitList(list) {
    return list ? list.split(',') : [];
}

const parseConditions = question => {
    if (question.conditions) {
        return {
            ...question,
            conditions: question.conditions.reduce(
                (acc, curr) => {
                    if (POLICY_CONDITIONS.map(([key]) => key).includes(curr)) {
                        return {...acc, policy: curr};
                    }

                    if (DEPOSIT_CONDITIONS.map(([key]) => key).includes(curr)) {
                        return {...acc, deposit: curr};
                    }

                    return {...acc, payment: curr};
                },
                {
                    policy: null,
                    deposit: null,
                    payment: null,
                }
            ),
        };
    }

    return question;
};

const parseCode = question => ({
    ...question,
    code: question.code.substring(question.code.indexOf('.') + 1),
});

const parseDynamic = question => {
    const {
        default_select_dynamic: defaultSelectDynamic,
        default_text_dynamic: defaultTextDynamic,
        type,
    } = question;

    return omit(
        {
            ...question,
            'default_text_dynamic': splitList(
                type === 'select' ? defaultSelectDynamic : defaultTextDynamic
            ),
        },
        ['default_select_dynamic']
    );
};

const parseQuestion = flow(parseConditions, parseCode, parseDynamic);

const parseQuestions = questions => questions.map(parseQuestion);

export const parse = data => {
    const {policyGroupInfo, ...rest} = data;
    return {
        ...Object.fromEntries(
            Object.entries(rest).map(([key, value]) => [key, parseQuestions(value)])
        ),
        policyGroupInfo,
    };
};

const addSystemCode = (question, system) => {
    return {...question, code: `${system.toUpperCase()}.${question.code}`};
};

const serializeConditions = question => {
    if (question.conditions) {
        return {
            ...question,
            conditions: Object.values(question.conditions).filter(
                item => !!item
            ),
        };
    }

    return question;
};

const serializeDynamic = question => {
    const {
        default_text_dynamic: defaultTextDynamic,
        default_employee_text: defaultEmployeeText,
        default_guest_text: defaultGuestText,
        help_text: helpText,
    } = question;
    return {
        ...question,
        'default_text_dynamic': defaultTextDynamic
            ? defaultTextDynamic.join(',')
            : '',
        'default_employee_text': defaultEmployeeText || '',
        'default_guest_text': defaultGuestText || '',
        'help_text': helpText || '',
    };
};

const removeUselessFields = question => {
    if (question.type === 'select') {
        return {
            ...omit(question, [
                'min_length',
                'max_length',
                'validation_type',
                'default_employee_text',
                'default_guest_text',
                'default_text_dynamic'
            ]),
            'default_select_dynamic': question.default_text_dynamic
        };
    }

    return omit(question, ['choices', 'default_select_dynamic']);
};

const serializeQuestion = flow(
    addSystemCode,
    serializeConditions,
    serializeDynamic,
    removeUselessFields,
);

const serializeQuestions = (questions, system) =>
    questions.map(question => serializeQuestion(question, system));

export const serialize = (data, {system}) => {
    return Object.fromEntries(
        Object.entries(omit(data, ['policyGroupInfo'])).map(([key, value]) => [
            key,
            serializeQuestions(value, system),
        ])
    );
};
