import {combineReducers} from 'redux';
import form from './form';
import fares from './fares';
import separatedTickets from './separatedTickets';

const airPricing = combineReducers({
    form,
    fares,
    separatedTickets,
});

export default airPricing;
