import api from 'midoffice/helpers/api';
import {saveFile, getFileOptions} from 'midoffice/helpers/file';


export function generateFile(data, url) {

    return api('POST', url, {
        data,
        responseType: 'arraybuffer',
        fullResponse: true,
    }).then((response)=> (
        {
            ...getFileOptions(response.headers),
            contents: response.body
        }
    )).then(({contents, name, type})=> (
        saveFile(name, contents, type)
    ));
}
