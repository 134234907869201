export function parse(data, {treeName}) {
    if (treeName === 'companies') {
        const invoiceCollection = data['invoice_collection_override'] || {};
        return {
            'invoice_collection:override_enabled': invoiceCollection['override_enabled'],
            'invoice_collection:override_value': invoiceCollection['override_value'],
        };
    }

    return {
        'agency:invoice_collection:enabled': data['agency:invoice_collection'].enabled,
    };
}

export function serialize(data, {treeName}) {
    if (treeName === 'companies') {
        return {
            'invoice_collection_override': {
                'override_enabled': data['invoice_collection:override_enabled'],
                'override_value': data['invoice_collection:override_value'],
            },
        };
    }
    return {
        'agency:invoice_collection': {
            enabled: data['agency:invoice_collection:enabled']
        }
    };
};
