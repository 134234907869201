import get from 'lodash/get';
import last from 'lodash/last';

export function getModal(state) {
    return last(get(state, 'modal')) || {kind: null};
}

export function isModalOpen(state) {
    return getModal(state).kind !== null;
}
