/* eslint-disable immutable/no-mutation */
/* global global */
import noop from 'lodash/noop';
import some from 'lodash/some';
import values from 'lodash/values';
import result from 'lodash/result';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import validate from './validate';

const internal = Symbol('private');

const requests = global._xhrs = [];

function helloIENiceToMeetYouToo() {
    result(global, 'CollectGarbage');
}

const METHOD = {
    OPTIONS: 'OPTIONS',
    GET: 'GET',
    HEAD: 'HEAD',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

const STATE = {
    UNSENT: 'unsent',
    SENT: 'sent',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
    HEADERS_RECEIVED: 'headersReceived',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    ABORT: 'abort',
    TIMEOUT: 'timeout'
};

const STATUS_GROUP = {
    INFORMATION: 100,
    OK: 200,
    REDIRECT: 300,
    CLIENT_ERROR: 400,
    SERVER_ERROR: 500
};
const STATUS_BROKEN = 599;

const DATA_TYPES = [
    'Int8Array', 'Uint8Array', 'Int16Array', 'Uint16Array',
    'Int32Array', 'Uint32Array', 'Float32Array', 'Float64Array',
    'ArrayBuffer', 'Blob', 'Document', 'FormData',
]
    .filter((name) => name in global)
    .map((name) => global[name]);

const TYPE = {
    TEXT: '',
    ARRAY_BUFFER: 'arraybuffer',
    BLOB: 'blob',
    DOCUMENT: 'document',
    JSON: 'json'
};

class StatusGroup {
    constructor(statusGroup) {
        this.isInformation = statusGroup === STATUS_GROUP.INFORMATION;
        this.isOk = statusGroup === STATUS_GROUP.OK;
        this.isRedirect = statusGroup === STATUS_GROUP.REDIRECT;
        this.isClientError = statusGroup === STATUS_GROUP.CLIENT_ERROR;
        this.isServerError = statusGroup === STATUS_GROUP.SERVER_ERROR;
    }
}

export class Response {
    constructor(request) {
        let privates = this[internal] = {};
        this.broken = false;

        //save request reference
        privates.request = request;
    }

    tryBody() {
        try {
            return this.body;
        }
        catch (_ignore) {
            this.broken = true;
            this[internal].response = null;
        }
    }

    get request() {
        return this[internal].request;
    }

    get body() {
        let privates = this[internal];
        const {request, request: {responseType}} = privates;

        //return cached response
        if ('response' in privates) {
            return privates.response;
        }

        if (![STATE.LOADED, STATE.ABORT, STATE.ERROR, STATE.TIMEOUT].includes(request.state)) {
            return null;
        }

        let xhr = request[internal].xhr;
        let response = xhr.response;
        // In case IE is still ignoring responseType,
        // parse it manually. Yes, it still does in IE11.
        if (xhr.responseType !== responseType && responseType === 'json') {
            response = (response === '') ? null : JSON.parse(response);
        }
        return privates.response = response;
    }

    get responseJSON() {
        return this.body;
    }

    get status() {
        return this.broken
            ? STATUS_BROKEN
            : this[internal].request[internal].xhr.status;
    }

    get statusGroup() {
        const {status} = this;

        return new StatusGroup(Object.values(STATUS_GROUP).find((value) => status - value < 100));
    }

    get statusText() {
        return this[internal].request[internal].xhr.statusText;
    }

    get headers() {
        return this[internal].headers;
    }
}

function handleUploadProgress(event) {
    this[internal].state = STATE.UPLOADING;
    this.onUploadProgress({
        loaded: event.loaded,
        total: event.total
    }, this.response);
}

function handleUpload() {
    this[internal].state = STATE.UPLOADED;
    this.onUpload();
}

const NORMALIZE_RE = /(^|-)(.)/g;
function normalizeReplace(match, p1, p2) {
    return p1 + p2.toUpperCase();
}
function normalizeHeader(name) {
    return name.toLowerCase().replace(NORMALIZE_RE, normalizeReplace);
}

function parseHeaders(headersString) {
    const headers = {};
    headersString.split('\r\n').forEach((part)=> {
        const [name, value] = part.split(':');
        if (value) {
            headers[normalizeHeader(name.trim())] = value.trim();
        }
    });
    return headers;
}

function handleHeadersReceived() {
    let xhr = this[internal].xhr;

    if (xhr.readyState !== XMLHttpRequest.HEADERS_RECEIVED) {
        return;
    }

    this[internal].state = STATE.HEADERS_RECEIVED;
    let headersString = xhr.getAllResponseHeaders().trim();
    this.response[internal].headers = parseHeaders(headersString);
    this.onHeaders(this.response.headers);
}

function handleLoadProgress(event) {
    this[internal].state = STATE.LOADING;
    this.onLoadProgress({
        loaded: event.loaded,
        total: event.total
    }, this.response);
}

function handleLoad() {
    this[internal].state = STATE.LOADED;
    helloIENiceToMeetYouToo();
    this.response.tryBody();
    const {body, statusGroup} = this.response;
    if (statusGroup.isOk || statusGroup.isRedirect) {
        this.onLoad(this.fullResponse ? this.response : body);
        this.resolve(this.fullResponse ? this.response : body);
    }
    else {
        this.onError(this.response);
        this.reject(this.response);
    }
}

function handleAbort() {
    this[internal].state = STATE.ABORT;
    this.onAbort(this.response);
    this.reject(this.response);
}

function handleError() {
    this[internal].state = STATE.ERROR;
    this.onError(this.response);
    this.reject(this.response);
}

function handleTimeout() {
    this[internal].state = STATE.TIMEOUT;
    this.onTimeout(this.response);
    this.reject(this.response);
}

function handleDone() {
    requests.splice(requests.indexOf(this), 1);
    this.response.tryBody();
    this.onDone(this.response.body);
}

function exactMatch(value, pattern) {
    const match = value.match(pattern);
    return Boolean(match && match[0] === value);
}

export function abortMatching(pattern) {
    requests
        .filter(({id})=> id && exactMatch(id, pattern))
        .forEach((request)=> request.abort());
}

export function abortAll() {
    requests.forEach((request)=> request.abort());
}

export default class Request {
    constructor({method, url, headers, options}) {
        let privates = this[internal] = {};

        //create XMLHttpRequest object
        privates.xhr = new XMLHttpRequest();
        privates.state = STATE.UNSENT;

        //set parameters
        this.method = method;
        this.url = url;
        this.headers = headers;
        this.data = options.data;
        this.responseType = options.responseType;
        this.timeout = options.timeout;
        this.credentials = options.credentials;
        this.fullResponse = options.fullResponse;
        this.id = options.id;

        //set state handlers
        this.onUploadProgress = options.onUploadProgress || noop;
        this.onAbort = options.onAbort || noop;
        this.onError = options.onError || noop;
        this.onTimeout = options.onTimeout || noop;
        this.onUpload = options.onUpload || noop;
        this.onHeaders = options.onHeaders || noop;
        this.onLoadProgress = options.onLoadProgress || noop;
        this.onDone = options.onDone || noop;
        this.onLoad = options.onLoad || noop;
    }

    get data() {
        return this[internal].data;
    }

    set data(data) {
        let privates = this[internal];

        if (isUndefined(data) || data === null) {
            delete privates.data;
            return;
        }

        validate(some(DATA_TYPES, (Type) => (data instanceof Type)) || isString(data),
            `set:data - given data '${data}' can not be sent with XMLHttpRequest. ` +
            'Use ArrayBuffer/ArrayBufferView/Blob/Document/DOMString/FormData');

        privates.data = data;
    }

    get responseType() {
        return this[internal].responseType;
    }

    set responseType(responseType) {
        let privates = this[internal];
        validate(values(TYPE).indexOf(responseType) >= 0,
            `set:responseType - given unknown responseType '${responseType}'`);
        privates.responseType = responseType;
    }

    get state() {
        return this[internal].state;
    }

    send() {
        let privates = this[internal];
        const {method, url, data, responseType, headers, timeout, credentials} = this;

        validate(privates.state === STATE.UNSENT, 'send - request must not be sent yet');
        validate(!data || method === METHOD.PATCH || method === METHOD.POST || method === METHOD.PUT,
            `send - can not send data with method '${method}'`);

        privates.state = STATE.SENT;
        let xhr = privates.xhr;
        this.response = new Response(this);
        let promise = new Promise((resolve, reject)=> {
            this.resolve = resolve;
            this.reject = reject;
        });

        if (xhr.upload) {
            xhr.upload.onprogress = handleUploadProgress.bind(this);
            xhr.upload.onabort = handleAbort.bind(this);
            xhr.upload.onerror = handleError.bind(this);
            xhr.upload.ontimeout = handleTimeout.bind(this);
            xhr.upload.onload = handleUpload.bind(this);
        }

        xhr.onreadystatechange = handleHeadersReceived.bind(this);

        xhr.onprogress = handleLoadProgress.bind(this);
        xhr.onabort = handleAbort.bind(this);
        xhr.onerror = handleError.bind(this);
        xhr.ontimeout = handleTimeout.bind(this);
        xhr.onload = handleLoad.bind(this);
        xhr.onloadend = handleDone.bind(this);

        xhr.open(method, url, true);

        for (let header in headers) {
            xhr.setRequestHeader(header, headers[header]);
        }

        xhr.responseType = responseType;
        xhr.timeout = timeout;
        xhr.withCredentials = credentials;

        if (data) {
            xhr.send(data);
        }
        else {
            xhr.send();
        }

        //track request
        requests.push(this);

        return promise;
    }

    abort() {
        validate(this.state !== STATE.UNSENT, 'abort - request must be sent to abort it');
        this[internal].xhr.abort();
    }

    get isFailed() {
        return this[internal].state === STATE.ERROR;
    }

    get isTimedOut() {
        return this[internal].state === STATE.TIMEOUT;
    }

    get isAborted() {
        return this[internal].state === STATE.ABORT;
    }
}
