import React from 'react';
import PropTypes from 'prop-types';

export default class Loader extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        children: PropTypes.any,
    };

    renderSpinner() {
        return (<div className="overlay-mute">
            <div className="overlay-mute--spinner" />
        </div>);
    }

    render() {
        const {loading, children} = this.props;

        return (<div>
            {loading && this.renderSpinner()}
            {children}
        </div>);
    }
}

