import {filterData, allData} from './LocalAutocomplete';


function ignoreSelected(selectedValues, data) {
    return data.filter((el)=> !selectedValues.includes(el.value));
}

const StatelessAutocomplete = {
    query: function (query, {data, selectedValues=[]}) {
        selectedValues = selectedValues.map(({value}) => value);
        return filterData(query, data)
            .then((data) => ignoreSelected(selectedValues, data))
            .then((data) => (
                data.length
                    ? Promise.resolve(data)
                    : Promise.reject('No results found')));
    },
    empty: allData,
};


export default StatelessAutocomplete;
