import {createSchema, required} from 'midoffice/newforms/helpers';
import {Field, EmailField} from 'midoffice/newforms/fields-stateless';
import gettext from 'airborne/gettext';

const LoginSchema = createSchema({
    fields: {
        'email': required(EmailField, gettext('This field is required')),
        'password': required(Field, gettext('This field is required')),
    }
});

export default LoginSchema;
