import api from 'midoffice/helpers/api';
import forms from 'midoffice/newforms';
import UserInput from 'midoffice/components/users/UserInput';

function fullName(user) {
    const names = [user.first_name, user.middle_name, user.last_name];
    return names.filter((el)=> Boolean(el)).join(' ');
}

function nameCompany(user) {
    return `${fullName(user)} (${user.company_name})`;
}

export class UserAutocomplete {
    constructor({url}) {
        this.url = url;
    }

    query(query, {forUser, forCompanies}) {
        return new Promise((resolve, reject)=> {
            const method = forCompanies ? 'POST' : 'GET';
            return api(method, this.url, {
                data: {
                    name: query,
                    pk: forUser,
                    companies: forCompanies
                        ? forCompanies.join(',')
                        : ''
                },
                id: 'managed_travelers_autocomplete',
            }).then(
                (results=[])=> {
                    results = this.parseResponse(results);
                    if (results.length > 0) {
                        resolve(results);
                    }
                    else {
                        reject('No users found');
                    }
                },
                ({request})=> {
                    if (request.isAborted) {
                        resolve(null);
                    }
                    else {
                        reject('Server error');
                    }
                }
            );
        });
    }

    parseResponse(data) {
        return data.map((user)=> {
            const label = nameCompany(user);
            return {value: user.id, label};
        });
    }
}
