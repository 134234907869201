/* eslint-disable immutable/no-mutation */
function getFileType(headers) {
    return headers['Content-Type'] || null;
}


const FILE_NAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
function getFileName(headers) {
    const disposition = headers['Content-Disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const matches = FILE_NAME_REGEX.exec(disposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }
    return null;
}


export function getFileOptions(headers) {
    return {
        name: getFileName(headers),
        type: getFileType(headers),
    };
}


export function saveFileUrl(name, url) {
    let link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.hidden = true;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function saveBlob(blob, name) {
    if (window.navigator.msSaveBlob) {
        return window.navigator.msSaveBlob(blob, name);
    }

    saveFileUrl(name, URL.createObjectURL(blob));
}

export function saveFile(name, contents, type) {
    const blob = new Blob([contents], {type});
    saveBlob(blob, name);

}

