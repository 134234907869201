import {ADDED, UPLOADING, UPLOAD_CLEAR, NET_FAIL} from '../actions/types';

export default function upload(state, {type, pendingId, data}) {
    if (!state) {
        return {};
    }

    if (type === UPLOADING) {
        return {
            ...state,
            [pendingId]: {
                data,
                loading: true,
                error: null,
                id: null,
                pendingId
            },
        };
    }

    if (type === ADDED) {
        return {
            ...state,
            [pendingId]: {
                loading: false,
                error: null,
                id: data.id,
                pendingId,
            },
        };
    }

    if (type === NET_FAIL && state[pendingId]) {
        return {
            ...state,
            [pendingId]: {
                ...state[pendingId],
                loading: false,
                error: true,
                id: null,
                pendingId,
            },
        };
    }

    if (type === UPLOAD_CLEAR) {
        if (pendingId === null) {
            return {};
        }
        return {
            ...state,
            [pendingId]: null,
        };
    }

    return state;
}
