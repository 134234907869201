import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

function isQualifierDefined({value}) {
    return isBoolean(value) || !isEmpty(value) || isNumber(value);
}

function removeEmpty(rules) {
    return rules && rules
        .map(({qualifiers}) => ({
            qualifiers: qualifiers.filter(isQualifierDefined)
        }))
        .filter(({qualifiers}) => qualifiers.length);
}

function convertBrands(brands, shouldParse) {
    if (!brands) {
        return [];
    }

    return brands.map((brand) => {
        return shouldParse ?  {value: brand.id, label: brand.name} : {id: brand.value, name: brand.label};
    });
}

function convertChains(value) {
    return value.map((chain) => {
        return chain.slice(- 2);
    });
}

function getProvider(value) {
    return value[0].slice(0, -3);
}

function convertDataFormat(rules, shouldParse) {
    return rules && rules
        .map(({qualifiers}) => ({
            qualifiers: qualifiers.map((qualifier) => {
                const {value} = qualifier;
                if (qualifier.field === 'hotel_chain') {
                    return {
                        ...qualifier,
                        provider: getProvider(value),
                        value: convertChains(value),
                    };
                }

                return qualifier.field === 'brands' ? {
                    ...qualifier,
                    value: convertBrands(qualifier.value, shouldParse)
                } : qualifier;
            })
        }));
}

function addProvider(value, provider) {
    return value.map((chain) => provider + ':' + chain);
}

function convertDataSerialize(rules, shouldParse) {
    return rules && rules
        .map(({qualifiers}) => ({
            qualifiers: qualifiers.map((qualifierWithProvider) => {
                const {provider, ...qualifier} = qualifierWithProvider;

                if (qualifier.field === 'hotel_chain') {
                    return {
                        ...qualifier,
                        value: addProvider(qualifier.value, provider),
                    };
                }

                return qualifier.field === 'brands' ? {
                    ...qualifier,
                    value: convertBrands(qualifier.value, shouldParse)
                } : qualifier;
            })
        }));
}

export function serialize(data, query) {
    const systemType = query.treeName === 'agencies' ? 'agency:rate_exclusions:rules' : 'rate_exclusions:rules_list';
    const rules = convertDataSerialize(data[systemType]);
    return {
        ...data,
        [systemType]: removeEmpty(rules),
    };
}

export function parse(data, query) {
    const systemType = query.treeName === 'agencies' ? 'agency:rate_exclusions:rules' : 'rate_exclusions:rules_list';
    const rules = data[systemType];
    return {
        ...data,
        [systemType]: convertDataFormat(rules, true),
    };
}
