import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function wrap(value) {
    return React.isValidElement(value)
        ? value
        : (value ? (<span>{value}</span>) : null);
}

const UpdatingTooltip = React.forwardRef(
    ({popper, children, ...props}, ref) => {
        React.useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Tooltip ref={ref} {...props}>
                {children}
            </Tooltip>
        );
    },
);


export default class AnnotateTooltip extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.any,
        placement: PropTypes.string,
    };

    static defaultProps = {
        placement: 'top',
    };

    renderTooltip(id, contents, placement) {
        return (<UpdatingTooltip id={id} placement={placement}>{wrap(contents)}</UpdatingTooltip>);
    }

    render() {
        const {id, placement} = this.props;
        const [text, annotate] = React.Children.toArray(this.props.children);
        if (!annotate) {
            return wrap(text);
        }
        return (
            <OverlayTrigger placement={placement} overlay={this.renderTooltip(id, annotate, placement)}>
                {wrap(text)}
            </OverlayTrigger>
        );
    }
}

export class AnnotateTooltipWithInfo extends React.Component {
    static propTypes = {
        additionalInfo: PropTypes.string,
    };

    render() {
        const {additionalInfo, ...props} = this.props;
        return (<>
            <AnnotateTooltip {...props} />
            {additionalInfo && <small>{additionalInfo}</small>}
        </>);
    }
}
