import StreamService, {MESSAGE_TYPES} from 'airborne/services/Stream';
import * as streamTypes from 'airborne/store/modules/stream/actionTypes';

const ACTIONS = {
    [MESSAGE_TYPES.USER_SESSION_LOGOUT]: {type: streamTypes.USER_SESSION_LOGOUT},
    [MESSAGE_TYPES.FEATURE_FLAGS]: {type: streamTypes.FEATURE_FLAGS},
    'ROLLOUT_FLAGS': {type: streamTypes.ROLLOUT_FLAGS},
};

const insertPayload = (action, payload) => ({...action, payload});

const makeObserver = store => event => {
    const {type, message} = event;

    if (type in ACTIONS) {
        store.dispatch(insertPayload(ACTIONS[type], {type, message}));
    }
};

const createStreamMiddleware = handlers => store => {

    const observer = makeObserver(store);
    StreamService.subscribe(observer);

    return next => action => {

        const {type, payload} = action;

        // TODO We should make SEND_MESSAGE a unique Symbol
        // to prevent a chance to get income message with the same type
        if (type === streamTypes.SEND_MESSAGE) {
            StreamService.sendMessage(payload);
        }

        if (handlers && type in handlers) {
            handlers[type].map(func => func(store, action));
        }

        return next(action);
    };
};

export const createHandler = (type, handler) => ({
    [type]: handler,
});

export default createStreamMiddleware;
