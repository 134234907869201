import entries from 'lodash/entries';
import includes from 'lodash/includes';
import capitalize from 'lodash/capitalize';
import size from 'lodash/size';
import fromPairs from 'lodash/fromPairs';

import settings from 'airborne/settings';

import {NON_FIELD_ERRORS_KEY} from './constants';
import {ValidationError, SkipField} from './exceptions';
import {BoundSchemaField, BoundSchemaListField} from './boundFields';
import DateRange from './widgets/DateRange';
import {Field,
    DateField,
    IntegerField} from './fields';


/**
 * A composite field that consists of other fields
 * @class {Schema}
 * @extends {Field}
 */
export class Schema extends Field {

    /**
     * @param {object} [options]
     * @param {object.<string, Field>} [options.fields]
     */
    constructor(options = {}) {
        let {fields, ...rest} = options;
        super(rest);
        if (fields) { this.fields = fields; }
    }

    /**
     * Helper to get schema's fields paired with their names
     * @params {String[]} [names] - field names to only return
     * @returns {Array.<[String, Field]>}
     */
    getFields(names) {
        var fields = entries(this.fields);

        if (Array.isArray(names)) {
            return fields.filter(([name])=> includes(names, name));
        }

        return fields;
    }

    getBoundField(fieldName, form) {
        return new BoundSchemaField(this, fieldName, form);
    }

    /**
     * Given the incoming value, return the value for this field or null
     * @param {*} value
     * @returns {*}
     */
    getIncomingValue(value) {
        try {
            value = super.getIncomingValue(value);
        }
        catch (e) {
            if (!(e instanceof SkipField)) {
                throw e;
            }
            return null;
        }

        let data = {};
        for (let [name, field] of this.getFields()) {
            try {
                data[name] = field.getIncomingValue(value[name]);
            }
            catch (e) {
                if (!(e instanceof SkipField)) { throw e; }
            }
        }
        return data;
    }

    /**
     * Override to return initial data of contained fields
     * @returns {object}
     */
    getInitial() {
        return fromPairs(
            this.getFields()
                .map(([name, field])=> [name, field.getInitial()])
        );
    }

    /**
     * Overriding the default `runValidation`, because the validation
     * performed by validators and the `.validate()` method should be coerced
     * into an error dictionary with a 'non_fields_error' key.
     * @param {object} data
     * @param {String[]} [fieldNames] - optional list of fields to validate
     * @returns {*}
     */
    runValidation(data, fieldNames) {
        let partial = fieldNames && fieldNames.length;
        let value = this.validateEmptyValue(data);

        if (value) {
            value = this.toInternalValue(value, fieldNames);
            if (!partial) {
                try {
                    this.runValidators(value);
                    value = this.validate(value);
                }
                catch (e) {
                    if (e instanceof ValidationError) {
                        throw new ValidationError(e);
                    }
                    throw e;
                }
            }
        }
        return value;
    }

    /**
     * Override to run validations on contained fields and collect
     * validated data or errors for each of them.
     * @param {object} data
     * @param {String[]} [fieldNames]
     * @returns {object}
     */
    toInternalValue(data, fieldNames) {
        if (typeof data !== 'object') {
            throw new ValidationError({
                [NON_FIELD_ERRORS_KEY]: `Invalid data: ${data}`
            });
        }

        let result = {};
        let errors = {};

        for (let [name, field] of this.getFields(fieldNames)) {
            try {
                let value = field.runValidation(data[name]);
                let validator = this['validate' + capitalize(name)];
                if (typeof validator === 'function') {
                    value = validator.call(this, value);
                }
                result[name] = value;
            }
            catch (e) {
                if (e instanceof SkipField) { continue; }
                if (e instanceof ValidationError) {
                    errors[name] = e.detail;
                }
                else {
                    throw e;
                }
            }
        }

        if (Object.keys(errors).length > 0) {
            throw new ValidationError(errors);
        }

        return result;
    }

    /**
     * Get representation from all contained fields
     * @param {*} value
     * @returns {object}
     */
    toRepresentation(value) {
        if (typeof value !== 'object') { return null; }
        let data = {};
        for (let [name, field] of this.getFields()) {
            try {
                data[name] = field.toRepresentation(value[name]);
            }
            catch (e) {
                if (!(e instanceof SkipField)) { throw e; }
            }
        }
        return data;
    }

    /**
     * Override this and throw ValidationError for custom validation
     * @param {object} data
     * @returns {object}
     */
    validate(data) { return data; }
}


/**
 * A list of Schemas
 * @class {SchemaList}
 * @extends {Schema}
 */
export class SchemaList extends Schema {

    initial = [];

    /**
     * Either `child` or `fields` option is required
     * @param {object} options
     * @param {Schema} [options.child] - Schema to validate list items
     * @param {Field[]} [options.fields] - list of fields to create child schema
     */
    constructor(options={}) {
        let {child, fields, ...rest} = options;
        super(rest);

        if (child) { this.child = child; }
        else if (fields) { this.child = new Schema({fields}); }
        else { throw new Error('A "child" or "fields" option is required'); }
    }

    getDefaultMessages() {
        let messages = super.getDefaultMessages();
        return {...messages, notAList: 'Bad data'};
    }

    getIncomingValue(value) {
        value = super.getIncomingValue(value);
        if (Array.isArray(value)) {
            return value.map((item)=> this.child.getIncomingValue(item));
        }
        throw new SkipField();
    }

    getBoundField(fieldName, form) {
        return new BoundSchemaListField(this, fieldName, form);
    }

    /**
     * Override to deal with a list of objects
     * @param {Array} data
     * @returns {Array}
     */
    toInternalValue(data) {
        if (!Array.isArray(data)) {
            throw new ValidationError({
                [NON_FIELD_ERRORS_KEY]: this.messages.notAList
            });
        }

        let result = [];
        let errors = [];

        for (let item of data) {
            try {
                result.push(this.child.runValidation(item));
                errors.push({});
            }
            catch (e) {
                if (e instanceof ValidationError) { errors.push(e.detail); }
                else { throw e; }
            }
        }

        if (errors.some(size)) {
            throw new ValidationError(errors);
        }

        return result;
    }

    /**
     * Override to deal with a list of objects
     * @param {*} value
     * @returns {Array}
     */
    toRepresentation(value) {
        if ((value == null || value.length === 0)) {
            if (this.allowEmpty) { return []; }
            throw new SkipField();
        }

        return value.map((item)=> this.child.toRepresentation(item));
    }
}


/**
 * Base class for fields that have a start and an end
 * @class {BaseRangeField}
 * @extends {Schema}
 */
export class BaseRangeField extends Schema {

    validate(data) {
        let {min, max} = data;
        if (min && max && min > max) {
            throw new ValidationError({
                min: 'Minimum value cannot be greater than maximum'
            });
        }
        return data;
    }

    toRepresentation(value) {
        let data = super.toRepresentation(value);
        if (data && (data.min || data.max)) { return data; }
        throw new SkipField();
    }

}


/**
 * Range of integers
 * @class {IntegerRangeField}
 * @extends {BaseRangeField}
 */
export class IntegerRangeField extends BaseRangeField {

    constructor(options={}) {
        let {minValue, maxValue, ...rest} = options;
        super({
            ...rest,
            fields: {
                min: new IntegerField({minValue, maxValue, required: false}),
                max: new IntegerField({minValue, maxValue, required: false})
            }
        });

        this.minValue = minValue;
        this.maxValue = maxValue;
    }

    getDefaultProps() {
        return {type: 'number'};
    }
}


/**
 * Range of dates
 * @class {DateRangeField}
 * @extends {BaseRangeField}
 */
export class DateRangeField extends BaseRangeField {

    constructor(options={}) {
        let {widget=DateRange, format=settings.DATE_FORMAT,
            inputFormat=settings.USER.date_format_str,
            minDate, maxDate,
            minRequired=false, maxRequired=false, ...rest} = options;

        super({
            ...rest,
            widget,
            fields: {
                min: new DateField({format, inputFormat, minDate, maxDate, required: minRequired}),
                max: new DateField({format, inputFormat, minDate, maxDate, required: maxRequired}),
            }
        });

        this.format = format;
        this.inputFormat = inputFormat;
        this.minDate = minDate;
        this.maxDate = maxDate;
    }

    validate({min, max}) {
        if (min && max && min.isAfter(max)) {
            return {min: max, max: min};
        }
        return {min, max};
    }
}
