import { isBoolean, isEmpty, isNumber, uniq, uniqBy } from 'lodash';

const FIELD_CHAIN = 'chain';
const FIELD_PROPERTY_TYPE = 'property_type';
const FIELD_HOTELS = 'hotels';
const FIELD_DESTINATION = 'destination';

function isQualifierDefined({ value }) {
    return isBoolean(value) || !isEmpty(value) || isNumber(value);
}

function removeEmpty(rules) {
    return rules?.map(({ qualifiers }) => ({
        qualifiers: qualifiers.filter(isQualifierDefined)
    })).filter(({ qualifiers }) => qualifiers.length);
}

function addProvider(value, provider) {
    return value ? value.map((chain) => `${provider}:${chain}`) : null;
}

function getProvider(value) {
    return value[0].slice(0, -3);
}

function convertChains(value) {
    return value.map((chain) => chain.slice(-2));
}

function uniqueValues(value) {
    return uniqBy(value, (item) => item.value.toString());
}

const serializeProcessors = {
    [FIELD_CHAIN]: (qualifier, provider) => ({
        ...qualifier,
        value: addProvider(qualifier.value, provider),
    }),
    [FIELD_PROPERTY_TYPE]: (qualifier) => ({
        ...qualifier,
        value: uniq(qualifier.value),
    }),
    [FIELD_HOTELS]: (qualifier) => ({
        ...qualifier,
        value: uniqueValues(qualifier.value),
    }),
    [FIELD_DESTINATION]: (qualifier) => ({
        ...qualifier,
        value: uniqueValues(qualifier.value),
    }),
};

const deserializeProcessors = {
    [FIELD_CHAIN]: (qualifier) => ({
        ...qualifier,
        value: convertChains(qualifier.value),
        provider: getProvider(qualifier.value),
    }),
};

function processQualifier(qualifier, provider, processors) {
    const processor = processors[qualifier.field];
    return processor ? processor(qualifier, provider) : qualifier;
}

function convertData(rules, processors) {
    return rules?.map(({ qualifiers }) => ({
        qualifiers: qualifiers.map((qualifierWithProvider) => {
            const { provider, ...qualifier } = qualifierWithProvider;
            return processQualifier(qualifier, provider, processors);
        })
    }));
}

export function serialize(data) {
    const rules = convertData(data['hotel_exclusion_rules'], serializeProcessors);
    return {
        ...data,
        'hotel_exclusion_rules': removeEmpty(rules),
    };
}

export function parse(data) {
    const rules = convertData(data['hotel_exclusion_rules'], deserializeProcessors);
    return {
        ...data,
        'hotel_exclusion_rules': rules,
    };
}
