import pick from 'lodash/pick';

export function parse(raw) {
    const {
        'air:search_customizations': {homogeneous}
    } = raw;

    return {
        homogeneous: homogeneous === null ? 'disabled' : Boolean(homogeneous),
        ...pick(
            raw['air:search_customizations'],
            [
                'hide_paid_ancillaries',
                'hide_branded_fares',
                'hide_paid_seats',
                'disable_multi_city',
                'hide_airport_transfer',
            ]
        )
    };
}

export function serialize(data) {
    const {homogeneous} = data;

    return {
        'air:search_customizations': {
            'homogeneous': homogeneous === 'disabled' ? null : homogeneous,
            ...pick(
                data,
                [
                    'hide_paid_ancillaries',
                    'hide_branded_fares',
                    'hide_paid_seats',
                    'disable_multi_city',
                    'hide_airport_transfer',
                ]
            )
        }

    };
}
