import {injectField} from 'midoffice/newforms/decorators';
import React from 'react';

import PropTypes from 'prop-types';

import Editor from 'midoffice/components/draft/HTMLEditor';
import DraftToolbar from 'midoffice/components/draft/toolbar/Toolbar';

import Button, {ButtonGroup} from 'midoffice/components/draft/toolbar/Button';
import InlineStyleGroup from 'midoffice/components/draft/toolbar/InlineStyleButtons';
import BlockTypeGroup from 'midoffice/components/draft/toolbar/BlockTypeButtons';
import BlockTypeSelect from 'midoffice/components/draft/toolbar/BlockTypeSelect';
import HistoryGroup from 'midoffice/components/draft/toolbar/HistoryButtons';
import LinkButtons from 'midoffice/components/draft/toolbar/LinkButtons';

import Glyphicons from 'midoffice/components/Glyphicons';

import print from 'midoffice/helpers/print';

const iframeStyles = {
    height: '0px',
    width: '0px',
    position: 'absolute',
};
class Toolbar extends React.Component {
    static propTypes = {
        downloadLink: PropTypes.string,
        printable: PropTypes.bool,
        noToolbar: PropTypes.bool,
        onPrint: PropTypes.func,
    };

    renderAdditionalButtons() {
        const {downloadLink, printable, onPrint} = this.props;

        const buttons = [];

        if (downloadLink) {
            buttons.push(
                <a href={downloadLink} className="draft-button" key="download" download>
                    <Glyphicons bsClass="glyphicon" glyph="download-alt" />
                </a>
            );
        }

        if (printable) {
            buttons.push(
                <Button onClick={onPrint} key="print">
                    <Glyphicons bsClass="glyphicon" glyph="print" />
                </Button>
            );
        }

        if (!buttons.length) { return null; }
        return (
            <ButtonGroup className="pull-right">
                {buttons}
            </ButtonGroup>
        );
    }

    render() {
        if (this.props.noToolbar) {
            return null;
        }
        return (
            <DraftToolbar {...this.props}>
                <BlockTypeSelect {...this.props} />
                <InlineStyleGroup {...this.props} />
                <BlockTypeGroup {...this.props} />
                <LinkButtons {...this.props} />
                <HistoryGroup {...this.props} />

                {this.renderAdditionalButtons()}
            </DraftToolbar>
        );
    }
}


@injectField
export default class RichText extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        downloadLink: PropTypes.string,
        printable: PropTypes.bool,
    };

    static defaultProps = {
        printable: false,
    };

    constructor(props) {
        super(props);

        this.handlePrint = this.handlePrint.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handlePrint() {
        print(this.props.value);
    }

    handleChange(value) {
        const {name, onChange} = this.props;
        onChange(value, name);
    }

    render() {
        return (
            <div className="rich-text-container">
                <iframe id="ifmcontentstoprint" style={iframeStyles} />
                <Editor {...this.props}
                    onChange={this.handleChange}
                    filterWarnings>
                    <Toolbar {...this.props} onPrint={this.handlePrint} />
                </Editor>
            </div>
        );
    }
}
