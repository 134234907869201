
export function booleanReducer(initialState=false, trueActions=[], falseActions=[]) {

    if (typeof initialState !== 'boolean' || !Array.isArray(trueActions) || !Array.isArray(falseActions)) {
        throw new Error('Wrong param types. Should be: (Boolean, Array, Array)');
    }

    return function(state=initialState, {type}) {
        if (trueActions.includes(type)) {return true;}
        if (falseActions.includes(type)) {return false;}
        return state;
    };
}
