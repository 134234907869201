import omit from 'lodash/omit';
import {register, serialize as serializeByType} from 'airborne/types';
import {endpoint} from "midoffice/data/actions/airReporting";

function getUrl(endpoint) {
    return `/latest/air/export/`;
}

export const addEntry = getUrl;

export const parse = (data) => {
    const {url: reportUrl, 'report_id': reportId} = data;
    return {reportUrl, reportId};
}

export const serialize = (data) => omit(serializeByType('airBookings', data), ['limit', 'offset']);

register(endpoint, {addEntry, serialize, parse});
