import {createSchema, required} from 'midoffice/newforms/helpers';
import {ArrayField, CharField, EmailField, Field, NamePart} from 'midoffice/newforms/fields-stateless';

const PNRField = {
    ...CharField,

    validate(value) {
        const errors = CharField.validate.call(this, value);
        if (errors) { return errors; }

        if (value && value.length !== 6) {
            return 'PNR Number should contain exactly 6 characters.';
        }

        return null;
    }
};


export const PNRRetrieveSchema = createSchema({
    fields: {
        'entity': required(Field),
        'office_id': required(CharField),
        'pnr': required(PNRField),
    }
});


const Name = createSchema({
    fields: {
        'first_name': required(NamePart),
        'last_name': required(NamePart),
    },
});

export const PNRCreateSchema = createSchema({
    fields: {
        'entity': required(Field),
        'office_id': required(CharField),
        'passengers': required({...ArrayField, child: Name}),
        'email': EmailField,
        'tspm_entity_id': CharField,
        'traveler_id': CharField,
        'smid': CharField,
    }
});
