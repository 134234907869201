import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import flatten from 'lodash/flatten';
import applyFilters from 'midoffice/helpers/filters';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import isBoolean from 'lodash/isBoolean';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';


/*eslint-disable camelcase*/

function notDefined(value) { return isNull(value) || isUndefined(value); }

export function intComparator(a, b) {
    a = Number(a);
    b = Number(b);
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }

    return 0;
}

const getFieldValue = (obj, key, isNumberField) => {
    if (isNumberField) {
        return get(obj, key) || Infinity;
    }

    return get(obj, key);
};

export function comparator(a, b, orderBy, forceNullDown, numberFields = []) {
    const isNumberField = numberFields.includes(orderBy);
    const originalField = orderBy.indexOf('-') === 0 ? orderBy.substr(1) : orderBy;
    const aValue = getFieldValue(a, originalField, isNumberField);
    const bValue = getFieldValue(b, originalField, isNumberField);

    // 2, -2 used to help callee to determine whether result caused by null value
    if (forceNullDown) {
        if (notDefined(aValue) && notDefined(bValue)) { return 0; }
        if (notDefined(aValue)) { return 2; }
        if (notDefined(bValue)) { return -2; }
    }

    // Revert order for `-` field
    if (orderBy.indexOf('-') === 0) {
        return comparator(b, a, orderBy.substr(1), forceNullDown);
    }

    // Compare numbers
    if (typeof (aValue) === 'number' || typeof (bValue) === 'number') {
        return intComparator(aValue, bValue);
    }

    // Compare dates
    if (aValue && aValue.diff) {
        if (!bValue) { // date is greater than null
            return 1;
        }
        return aValue.diff(bValue);
    }

    if (bValue && bValue.diff) {
        return -1; // null is lesser than date
    }

    // Compare strings
    a = (aValue || '').toString();
    b = (bValue || '').toString();
    return a.localeCompare(b);
}

const MATCHSUM = {
    OR: (query, result)=> Boolean(result.length),
    AND: (query, result)=> query.length === result.length,
};

function extractWords(obj) {
    return flatten(Object.values(obj).map((value)=>
        (value || '').toString().toLowerCase().split(' ')
    ));
}

function partialMatch(query, word) {
    return word.includes(query);
}

function searchMatch(el, query, sum) {
    const sumFn = MATCHSUM[sum];
    const objectWords = extractWords(el);
    return sumFn(
        query,
        query.filter(queryWord=>
            objectWords.filter(partialMatch.bind(null, queryWord))
                .length
        )
    );
}

export function orderInline(list, orderBy, forceNullDown, numberFields) {
    list.sort((a, b)=> comparator(a, b, orderBy, forceNullDown, numberFields));
    return list;
}

export const FILTERABLE_AMENITIES = ['FWIFI', 'FBFST', 'FPARK'];
export function filterList(list, filterBy) {
    const {rateFilter} = filterBy;
    const amenitiesFilter = rateFilter.filter(
        rate => FILTERABLE_AMENITIES.includes(rate)
    );

    const includingAmenitites = ({'rate_value_adds': amenities = []}) => (
        amenitiesFilter.every((code) => {
            if (code === 'FWIFI') {
                return ['FWIFI', 'FINTT'].some(e => amenities.includes(e));
            }

            return amenities.includes(code);
        })
    );

    const checkNonRefundable = ({non_refundable, cancel_by}) => isBoolean(non_refundable) ? !Boolean(non_refundable) : Boolean(cancel_by);
    return list
        .filter((rate) => rateFilter.includes('rate_filter') ? rate.paid_policy === 'G' : rate)
        .filter((rate) => rateFilter.includes('non_refundable') ? checkNonRefundable(rate) : rate)
        .filter(includingAmenitites);
}

function sortRates(a, b) {
    return a.sort_metric - b.sort_metric;
}

export default function paginate(list, orderBy, paginate, searchBy=null, searchSum='OR', filterBy=null) {
    list = list.slice();
    const isRateGrouping = getFeatureFlag(null, 'RATE_GROUPING');
    const isTopLevel = isRateGrouping && Boolean(list[0] && list[0]['rate_group_id']);

    if (searchBy) {
        searchBy = searchBy.toLowerCase().split(' ');
        list = list.filter(el=> searchMatch(el, searchBy, searchSum));
    }
    const simplefilteredList = isEmpty(filterBy) ? list : filterList(list, filterBy);
    const collapsedList = groupBy(simplefilteredList, 'rate_group_id');
    const finalArray = values(collapsedList)
        .map(rates => ({...rates.sort(sortRates)[0]}));

    const filteredList = isTopLevel ? finalArray : simplefilteredList;

    if (orderBy) {
        orderInline(filteredList, orderBy);
    }

    if (!paginate) {
        paginate = {page: 1, page_size: filteredList.length};
    }

    let total = filteredList.length;
    let total_pages = Math.ceil(total / paginate.page_size);
    let start_index = (paginate.page - 1) * paginate.page_size;
    let end_index = Math.min(start_index + paginate.page_size, total);

    start_index++;
    const pagedList = filteredList.slice(start_index - 1, end_index);
    return {
        paged: pagedList,
        fullPagedList: simplefilteredList,
        params: {...paginate, start_index, end_index, total, total_pages},
    };
}

export function paginateStore(state) {
    const {hash, pageBy, searchBy, sortBy, filterBy, filters} = state;
    const {paged, params} = paginate(
        applyFilters(Object.values(hash), filters),
        sortBy,
        pageBy,
        searchBy,
        filterBy
    );

    return {pageBy: params, paged};
}
