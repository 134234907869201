import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';

import 'airborne/search2/type';


import {searchForm} from 'airborne/homepage2/helpers/search';
import {isAirport, getCarsDistanceChoices} from 'airborne/homepage2/helpers/searchDistance';
import {serializeDatetime} from 'airborne/search_cars/helpers/date';
import settings from 'airborne/settings';
import {
    getDestFormValue, getOptions,
} from 'airborne/store/modules/homepage/selectors/homepage';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {parseList} from 'airborne/types';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {uniqWith, isEqual} from 'lodash';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

const URL = '/cars/get_availability/';

function prepareLocation(dest) {
    const latlon = `${dest.lat},${dest.lon}`;
    return isAirport(dest) ? dest['iataCode'] : latlon;
}

function prepareDiscounts(discounts) {
    discounts = discounts || [];
    discounts = compact(discounts.map(({vendor, number})=> (
        vendor && number && `${vendor}:${number.toUpperCase()}`
    )));

    return discounts.length ? discounts : null;
}

function normalizeDiscounts(discounts) {
    return discounts.map((discount) => {
        const {number, customInput, vendor, agencyNumber} = discount;
        if (number === 'custom') {
            return customInput
                ? {vendor, number: customInput, agencyNumber}
                : {vendor, number: agencyNumber, agencyNumber: null};
        }
        return discount;
    });
}

function normalizeNegotiatedIdNumbers(discounts) {
    return discounts
        .map(({vendor, number, idNumber, customIdInput}) => ({
            vendor,
            number: (number === 'custom' || idNumber === 'custom') ? customIdInput : idNumber,
        }));
}

function mapDiscountToVendorNumber({vendor, number, agencyNumber}, configurationLevel) {
    if (configurationLevel === 'agency') {
        // Use agencyNumber only when number and agencyNumber are both truthy and different.
        const useAgencyNumber = number && agencyNumber && number !== agencyNumber;
        number = (useAgencyNumber) ? agencyNumber : null;
    }
    else {
        // In other cases, use number or agencyNumber, whichever is truthy.
        number = number || agencyNumber;
    }

    return {vendor, number};
}

function transformDiscount({vendor, number}) {
    return vendor && number && `${vendor}:${number.toUpperCase()}`;
}

function prepareDiscountNumbers(discounts, configurationLevel) {
    discounts = discounts || [];
    discounts = normalizeDiscounts(discounts);
    discounts = compact(discounts.map(discount => mapDiscountToVendorNumber(discount, configurationLevel)).map(transformDiscount));
    return discounts.length ? discounts : null;
}

function prepareCarsDiscountNumbers(discounts) {
    discounts = discounts || [];
    discounts = compact(discounts.map(transformDiscount));
    return discounts.length ? discounts : null;
}

function prepareCurrency(currency) {
    return currency === '___' ? null : currency;
}

function getSearchRadius(destination, distance, gds) {
    const unit = settings.USER['distance_units'];

    // getCarsDistanceChoices contains logic to determine whether we need to set distance or not
    const choices = getCarsDistanceChoices(unit, destination, gds);

    return choices ? distance : null;
}

export function loadCarAvailParams(state, configurationLevel) {
    const addItNumbers = getOptions(state)?.cars_home_page?.render_it_numbers;
    const {profile, dest, i18n} = searchForm(state, 0);
    const {'primary_gds': primaryGds} = state.dest.options;

    const distances = pickBy({
        'pickup_search_radius': getSearchRadius(dest.pickUp, dest.pickUpDistance, primaryGds),
        'dropoff_search_radius': dest.differentDropOff
            ? getSearchRadius(dest.dropOff, dest.dropOffDistance, primaryGds)
            : null,
    });

    return dropEmpty({
        'configuration_id': getSelectedConfiguration(state) || profile.company,
        'currency': prepareCurrency(i18n.currency),

        'pickup_location': prepareLocation(dest.pickUp),
        'pickup_datetime': serializeDatetime(dest.dates.min),

        'dropoff_location': dest.differentDropOff
            ? prepareLocation(dest.dropOff)
            : null,
        'dropoff_datetime': serializeDatetime(dest.dates.max),

        'search_radius_unit': settings.USER['distance_units'],
        'car_types': dest.acriss,

        'id_numbers': (getFeatureFlag(state, 'ENABLE_COMPANY_ID_FIELD') ?
            prepareCarsDiscountNumbers(normalizeNegotiatedIdNumbers(dest.idNumbers)) :
            prepareDiscounts(dest.idNumbers)),
        'cd_numbers': prepareDiscountNumbers(dest.cdNumbers, configurationLevel),
        'it_numbers': addItNumbers ? prepareDiscountNumbers(dest.itNumbers, configurationLevel): null,
        ...distances,
    });
}

function request(params) {
    return air('GET', URL, params).then(
        ({'rates': rates})=> {
            const data = parseList('cars', rates);
            const total = rates.length;
            return {data, total};
        }
    );
}

export function mergeResults(response1, response2) {
    const data2 = response2.data.filter(rate => rate.isBcdCdRate || rate.isBcdItRate);
    const data1 = response1.data;
    const merged = data1.concat(data2);
    const uniqueList = uniqWith(merged, isEqual);

    return {data: uniqueList, total: uniqueList.length};
}

export function areTwoRequestsNeeded(state) {
    // need two requests when at least one vendor has cd number and agency number or and it number
    const carsValue = getDestFormValue(state);
    const cdNumbers =  carsValue?.cdNumbers || [];
    const itNumbers =  carsValue?.itNumbers || [];
    const findNumberWithTwoIdentifiers = (numbers) => numbers.find(({agencyNumber, customInput, number}) => {
        //field "number" could contain value "custom", when number is not prefilled
        //and a number, when it's prefilled from admin
        const existsPrefilledNumber = number && number !== 'custom';
        return agencyNumber && (customInput || existsPrefilledNumber);
    });
    return !!findNumberWithTwoIdentifiers(cdNumbers) || !!findNumberWithTwoIdentifiers(itNumbers);
}

export function searchRequest(state, configurationLevel) {
    const params = loadCarAvailParams(state, configurationLevel);
    return request(params);
}
