import pick from 'lodash/pick';
import zip from 'lodash/zip';
import keys from 'lodash/keys';
import gettext from 'airborne/gettext';
import api from 'midoffice/helpers/api';
import genericError from 'airborne/bookings2/genericError';

import {getTspm, getConfigurations, getConfigurationsParams} from 'airborne/store/modules/homepage/selectors/tspm';
import {
    getHomepagePnrIndex,
    getPnrProfile,
    getProfilesCount,
    getPnr,
    getPnrIndex
} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getDestFormValue, getStep} from 'airborne/store/modules/homepage/selectors/homepage';
import {loadCompanySettings} from 'airborne/store/modules/homepage/actions/pnr';
import {toggleCollapsableSection} from 'airborne/store/modules/homepage/actions';
import {currentCompany} from 'airborne/homepage2/helpers/search';
import {getSelectedConfiguration, getConfigurationLoadingParams} from 'airborne/store/modules/homepage/selectors/tspm';

import settings from 'airborne/settings';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import searchHotels from '../../search_hotels/actions/search';
import {getProduct} from '../selectors/product';
import searchCars from '../../search_cars/actions/search';
import browserHistory from 'airborne/browserHistory';


function tspmError() {
    return {
        title: gettext('Search Error'),
        body: gettext('Please try your search again. If the problem persists, please file a One Touch case to contact AgentSource Booking support.'),
    };
}

export function showMultyProfileModal(index) {
    return showModal('tspmMultyTraveler', {index});
}

export function showProfileModal() {
    return showModal('tspmTraveler');
}

export function openProceedWithoutPnr(pnrId) {
    return function openProceedWithoutPnrD(dispatch, getState) {
        const pnrIndex = getPnrIndex(getState(), pnrId);
        const pnr = getPnr(getState(), pnrIndex);
        const {profile} = pnr || {};

        if (!pnrId || !profile.tspm_traveler_id) {
            dispatch({type: 'COMPANY_WITHOUT_TRAVELER_OPEN'});
            return dispatch(showModal('tspmTraveler'));
        }
    };
}

export function closeProceedWithoutPnr() {
    return {type: 'COMPANY_WITHOUT_TRAVELER_CLOSE'};
}

export function showCompanyModal() {
    return showModal('tspmCompany');
}

export function discardTspmProfiles(index) {
    return {type: 'TRAVELER_PROFILES_DISCARD', index};
}

export function discardTspmCompanyProfiles(index) {
    return {type: 'COMPANY_PROFILES_DISCARD', index};
}

function parseTraveler(traveler) {
    const {company_name: companyName=null, entity_id: company, traveler_id: id, ...rest} = traveler;
    return {
        ...rest,
        companyName,
        company,
        id,
    };
}

function parseSuggestedCompany(company) {
    return {
        id: company['entity_id'],
        name: company.title || null,
        source: company.source,
    };
}

function parseCompany(company) {
    return {
        id: company['entity_id'],
        name: company['company_name'],
    };
}

function loadTraveler(id) {
    return api('GET', `/accounts/api/tspm/traveler/${id}`)
        .then(parseTraveler);
}

function loadCompany(id) {
    return api('GET', `/accounts/api/tspm/company/${id}`)
        .then(parseCompany);
}

function selectError({body}) {
    return body && body['error_details'] || tspmError();
}

function getLoadConfigurationAftParams(state) {
    const company = currentCompany(state);
    const profile = getPnrProfile(state, 0);
    if (!company) {
        return null;
    }

    return {
        'tspm_company_id': null,
        ...pick(profile, ['tspm_traveler_id', 'tspm_company_id']),
        'entity_id': company,
        system: settings.SUB_PARTNER,
    };
}

const LOAD_CONF_PARAM_GETTERS = {
    'aft': getLoadConfigurationAftParams,
};

function getLoadConfigurationParams(system) {
    return LOAD_CONF_PARAM_GETTERS[system];
}

const searches = {
    hotels: searchHotels,
    cars: searchCars,
};

function isSearchStep() {
    return browserHistory.location.pathname.split('/').includes('search');
}

const restartHotelSearch = (dispatch, getState) => shouldResearch => {
    const product = getProduct(getState());
    const searchProductAction = searches[product];
    if (shouldResearch && isSearchStep() && searchProductAction) {
        dispatch(searchProductAction());
    }
    else {
        return Promise.resolve();
    }
};

function discardConfiguration() {
    return function discardConfigurationD(dispatch, getState) {
        dispatch({type: 'DISCARD_CONFIGUTATION', index: 0});
        return dispatch(loadCompanySettings())
            .then(restartHotelSearch(dispatch, getState));
    };
}

function selectConfiguration(id) {
    return function selectConfigurationD(dispatch, getState) {
        const configurations = getConfigurations(getState(), 0);
        const configId = id || configurations[0].id;
        dispatch({type: 'SELECT_CONFIGURATION', index: 0, id: configId});
        return dispatch(loadCompanySettings())
            .then(restartHotelSearch(dispatch, getState));
    };
}

function hasParamsChanged(data, configParams) {
    return data['tspm_traveler_id'] !== configParams['tspm_traveler_id']
        || data['tspm_company_id'] !== configParams['tspm_company_id'];
}

export function loadConfigurationIds({select}={}) {
    return function loadConfigurationIdsD(dispatch, getState) {
        if (getProfilesCount(getState()) > 1) {
            return getSelectedConfiguration(getState())
                ? dispatch(discardConfiguration())
                : Promise.resolve();
        }

        const data = getLoadConfigurationParams(settings.SUB_PARTNER)(getState());

        if (!data || !data['tspm_traveler_id']) {
            return dispatch(discardConfiguration());
        }

        if (!hasParamsChanged(data, getConfigurationsParams(getState()))) {
            return Promise.resolve();
        }

        if (!hasParamsChanged(data, getConfigurationLoadingParams(getState()))) {
            return Promise.resolve();
        }

        dispatch({
            type: 'CONFIGURATIONS_LOADING',
            index: 0,
            params: pick(data, ['tspm_traveler_id', 'tspm_company_id']),
        });
        return api('GET', '/latest/get_configurations', {
            data: dropEmpty(data),
        })
            .then(
                ({configurations}={}) => {
                    if (hasParamsChanged(data, getConfigurationLoadingParams(getState()))) {
                        return Promise.resolve();
                    }
                    dispatch({
                        type: 'CONFIGURATIONS_LOADED',
                        configurations,
                        params: pick(data, ['tspm_traveler_id', 'tspm_company_id']),
                        index: 0
                    });
                    const isConfigIdLoaded = configurations.map(({id}) => id).includes(select);
                    return dispatch(selectConfiguration(isConfigIdLoaded && select));
                },
                (response) => {
                    if (hasParamsChanged(data, getConfigurationLoadingParams(getState()))) {
                        return;
                    }
                    const {body: {errors}} = response;
                    dispatch({type: 'CONFIGURATIONS_ERROR', errors, index: 0});
                },
            );
    };
}

export function searchTspmCompanyProfile(id, index) {
    return function searchTspmCompanyProfileD(dispatch) {
        dispatch({type: 'COMPANY_PROFILES_LOADING', index});

        return loadCompany(id)
            .then((data)=> {
                return dispatch({
                    type: 'COMPANY_PROFILES_LOADED',
                    data: data.id ? [data] : [],
                    index,
                });
            }, (response)=> {
                return dispatch({
                    type: 'COMPANY_PROFILES_FAIL',
                    error: selectError(response),
                    index,
                });
            });
    };
}

export function selectTspmCompanyProfile(profile, index) {
    return function selectTspmCompanyProfileD(dispatch) {
        dispatch({type: 'COMPANY_PROFILE_CHANGED', profile, index});
        return dispatch(loadConfigurationIds());
    };
}

export function suggestProfiles(travelerProfiles, companyProfiles, index, shouldSkipConfigurationLoading=false) {
    return function suggestProfilesD(dispatch, getState) {
        dispatch({
            type: 'TSPM_PROFILES_SUGGESTED',
            travelers: travelerProfiles.map(parseTraveler),
            companies: companyProfiles.map(parseSuggestedCompany),
            index,
        });

        if (travelerProfiles.length > 1) {
            dispatch(showMultyProfileModal(index));
        }
        else if (!shouldSkipConfigurationLoading && index === 0) {
            dispatch(loadConfigurationIds());
        }
        else if (getSelectedConfiguration(getState())) {
            dispatch(discardConfiguration());
        }
    };
}

export function searchTspmProfile({email, name}, companyId, index) {
    return function searchTspmProfileD(dispatch) {
        dispatch({type: 'TRAVELER_PROFILES_LOADING', index});
        const data = {}
        if(email){
            data['email'] = email;
        }
        if(name){
            data['last_name'] = name;
            data['configuration_id'] = companyId;
        }
        return api('GET', '/accounts/api/tspm/traveler/search/', {data})
            .then(({travelers})=> {
                return dispatch({
                    type: 'TRAVELER_PROFILES_LOADED',
                    data: travelers.map(parseTraveler),
                    index,
                });
            }, (response)=> {
                return dispatch({
                    type: 'TRAVELER_PROFILES_FAIL',
                    error: selectError(response),
                    index,
                });
            });
    };
}

export function discardTSPM(index) {
    return function discardTSPMD(dispatch) {
        dispatch({type: 'TRAVELER_PROFILE_CHANGED', profile: null, index});
        return dispatch(loadConfigurationIds());
    };
}

export function selectTspmProfile(profile, index) {
    return function selectTspmCompanyProfileD(dispatch) {
        if (!profile) {
            dispatch(discardTSPM(index));
            return Promise.resolve();

        }

        dispatch({type: 'COMPANY_PROFILES_LOADING', index});
        return Promise.all([loadCompany(profile.company), loadTraveler(profile.id)])
            .then(async ([company, traveler]) => {
                dispatch({
                    type: 'COMPANY_SUGGESTED_ADDED',
                    data: [company],
                    index,
                });
                await dispatch({
                    type: 'TRAVELER_PROFILE_CHANGED',
                    profile: {
                        ...profile,
                        ...pick(traveler, ['cars_id_numbers', 'ft_numbers']),
                        'company_name': company.name,
                    },
                    index,
                });

                if (keys(traveler['ft_numbers']).length) {
                    dispatch(toggleCollapsableSection('frequentFlyerNumber', true));
                }

                return dispatch(loadConfigurationIds());
            }, ({body})=> {
                dispatch(genericError(body && body.message));
            })
            .finally(() => {
                dispatch({type: 'TSPM_MODAL_LOADED', index});
            });
    };
}

export function selectTspmIds(form, index) {
    return {
        type: 'TRAVELER_PROFILE_IDS_CHANGED',
        value: pick(form, ['tspm_traveler_id', 'tspm_company_id']),
        index,
    };
}

function zipMerge(listA, listB) {
    return zip(listA, listB)
        .map(([elA, elB])=> ({...elA, ...elB}));
}

export function loadSuggested() {
    return function loadSuggestedD(dispatch, getState) {
        const index = getHomepagePnrIndex(getState());
        const list = getTspm(getState(), index).suggestedCompanies.data;
        const loadedProfiles = list.filter(({name})=> name);
        const toLoad = list.filter(({name})=> !name);
        const idsToLoad = toLoad.map(({id})=> id);

        if (idsToLoad.length === 0) {
            return Promise.resolve();
        }
        dispatch({type: 'COMPANY_SUGGESTED_LOADING', index});

        return Promise.all(idsToLoad.map(loadCompany))
            .then((companies)=> {
                dispatch({
                    type: 'COMPANY_SUGGESTED_LOADED',
                    data: [
                        ...loadedProfiles,
                        ...zipMerge(toLoad, companies),
                    ],
                    index,
                });
            }, ({body})=> {
                dispatch(genericError(body && body.message));
                dispatch({type: 'COMPANY_SUGGESTED_FAIL', index});
            });
    };
}
