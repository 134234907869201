import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import NavDropdown from 'react-bootstrap/NavDropdown';
import {setLang} from 'airborne/store/modules/header/actions/i18n';
import {getLang} from 'airborne/store/modules/header/selectors/i18n';
import {getStep} from 'airborne/store/modules/homepage/selectors/homepage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

import {TEST_LANGUAGE} from 'midoffice/helpers/languages';

function flag(code) {
    return (<i className={`icon-flag-${code}`} />);
}

function mapStateProps(state) {
    return {
        isTestLanguageFlag: getFeatureFlag(state, 'QA_TEST_LANGUAGE'),
        lang: getLang(state),
        show: getStep(state) !== 'checkout' && getStep(state) !== 'settings',
    };
}

class Header extends React.Component {
    render() {
        return (<span className="dropdown-header">
            {gettext('Choose a language')}
        </span>);
    }
}

class Locale extends React.Component {
    static propTypes = {
        code: PropTypes.string,
        label: PropTypes.string,
    };

    render() {
        const {code, label} = this.props;
        return (<span>
            <span className="dropdown-menu__icon">{flag(code)}</span> {label}
        </span>);
    }
}

@connect(mapStateProps, {setLang})
export default class LocaleDropdown extends React.Component {
    static propTypes = {
        isTestLanguageFlag: PropTypes.bool,
        lang: PropTypes.string,
        setLang: PropTypes.func.isRequired,
        show: PropTypes.bool,
    };

    renderLang(code, label, active) {
        return (
            <NavDropdown.Item eventKey={code} key={code} active={active} >
                <Locale code={code} label={label} />
            </NavDropdown.Item>
        );
    }

    render() {
        const {lang, show, isTestLanguageFlag} = this.props;

        if (!show) {
            return null;
        }

        const languages = (
            isTestLanguageFlag ?
                settings.LANGUAGES :
                settings.LANGUAGES.filter(([code])=> code !== TEST_LANGUAGE)
        );

        return (<NavDropdown title={flag(lang)} id="locale" onSelect={this.props.setLang} as="li">
            <Header />
            {languages.map(([code, label])=> this.renderLang(code, label, code === lang))}
        </NavDropdown>);
    }
}
