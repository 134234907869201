import {
    CAR_BOOKING_MODIFICATION_FLOW_QUIT, CAR_BOOKING_MODIFICATION_FLOW,
    CAR_DESTINATION_LOADING, CAR_DESTINATION_LOADED,
} from 'midoffice/car-bookings/modify/actionTypes';
import browserHistory from 'airborne/browserHistory';
import {changeProduct, discardPNR, noPNR, retrievePNR} from 'airborne/store/modules/homepage/actions';
import {reuseSearch, selectCompany} from 'airborne/store/modules/homepage/actions/pnr';
import {patchBooking} from 'airborne/store/modules/checkout_cars/actions/book';

import parsePhoneNumber from 'libphonenumber-js';

import isEqual from 'lodash/isEqual';

const endpoint = 'carBookingModification';

const extractLocationCode = (code) => code?.slice(0, 3) || '';
const isAirportLocation = (location) => Boolean(location.airport);
export const parseCarBookingValue = ({rate}) => {
    if (!rate) {
        return {};
    }

    const pickupLocationCode = rate.pickupLocation?.locationCode;
    const dropOffLocationCode = rate.dropoffLocation?.locationCode;

    return {
        pickUp: {
            label: extractLocationCode(pickupLocationCode),
            isAirport: isAirportLocation(rate.pickupLocation),
        },
        dropOff: {
            label: extractLocationCode(dropOffLocationCode),
            isAirport: isAirportLocation(rate.dropoffLocation)
        },
        dates: {
            min: rate.pickupDatetime,
            max: rate.dropoffDatetime,
        },
        differentDropOff: pickupLocationCode !== dropOffLocationCode,
    };
};

const parseProfile = (booking) => {
    return {
        pnr: booking.pnr.id,
        company: {
            label: booking.company.name,
            value: booking.company.id,
        },
    };
};

const getCountryCode = (phoneNumber) => {
    return parsePhoneNumber(phoneNumber, 'UA').country;
};

export const parseCollectionDelivery = (booking) => {
    const DELIVERY_KEY = 'delivery_';
    const COLLECTION_KEY = 'collection_';

    const {collection, delivery} = booking['collection_delivery'] || {};
    if (!collection && !delivery) {
        return null;
    }

    const sameAsDelivery = isEqual(collection, delivery);

    const checkoutValue = {};
    if (collection) {
        collection.phone['country_code'] = getCountryCode(collection.phone.number);
        for (const [key, value] of Object.entries(collection)) {
            checkoutValue[COLLECTION_KEY + key] = value;
        }
    }

    if (delivery) {
        delivery.phone['country_code'] = getCountryCode(delivery.phone.number);
        for (const [key, value] of Object.entries(delivery)) {
            checkoutValue[DELIVERY_KEY + key] = value;
        }
    }

    return {
        ...checkoutValue,
        'same_as_delivery': sameAsDelivery,
        withDelivery: Boolean(delivery),
        withCollection: Boolean(collection),
    };
};

export function modifyCarBookingFlow(booking) {
    return function modifyCarBookingFlowD(dispatch) {
        dispatch({type: CAR_BOOKING_MODIFICATION_FLOW, endpoint, booking: booking});
        browserHistory.push('/ui/homepage');


        const profile = parseProfile(booking);

        dispatch(discardPNR());
        dispatch(reuseSearch(profile, null, {cars: parseCarBookingValue(booking)}));

        dispatch(selectCompany(profile.company))
            .then(() => dispatch(profile.pnr ? retrievePNR(true, false, null, null, false) : noPNR(profile.company, 0)))
            .then(() => dispatch(changeProduct('cars')))
            .then(() => dispatch(patchBooking({
                toChange: {value: {
                    ...parseCollectionDelivery(booking),
                }}
            })))
            .catch(()=> null);
    };
}

export function modifyCarBookingFlowQuit(withRedirect = true) {
    return function modifyCarBookingFlowQuitD(dispatch) {
        dispatch({type: CAR_BOOKING_MODIFICATION_FLOW_QUIT, endpoint});

        if (withRedirect) {
            browserHistory.push('/bookings/cars/');
        }
    };
}

export function carsDestinationLoading () {
    return function modifyCarsDestinationLoadingD(dispatch) {
        dispatch({type: CAR_DESTINATION_LOADING, endpoint});
    };
}

export function carsDestinationLoaded () {
    return function modifyCarsDestinationLoadingD(dispatch) {
        dispatch({type: CAR_DESTINATION_LOADED, endpoint});
    };
}
