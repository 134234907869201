import {createSelector} from 'reselect';
import {getItemByIndex} from 'airborne/homepage2/helpers/multipnr';

export function getBooking(state, index) {
    return getItemByIndex(state.multiBooking.items, index);
}

export function vpaCardPools(state, index) {
    const {checkoutData} = getBooking(state, index);
    const {
        'vpa_card_pools': cardPools,
    } = checkoutData;
    return cardPools ? cardPools.map(
        ({'vpa_client_id': id, 'card_pool_name': name})=> ([id, name])) : [];
}

export function vpaPaymentInstructions(state, index) {
    const {checkoutData} = getBooking(state, index);
    return checkoutData['vpa_payment_instructions'] || null;
}

export function getAllBookings(state) {
    return state.multiBooking.items;
}

export function getAllBookingsLoading(state)  {
    return getAllBookings(state)
        .map((booking)=> booking.loading);
}

export const getBookErrors = createSelector(
    (state, index)=> getBooking(state, index).message,
    (message)=> (
        ((typeof message) === 'string' || Array.isArray(message))
            ? {external: message}
            : message
    )
);

export function getCurrent(state) {
    return state.multiBooking.current;
}
