import noop from 'lodash/noop';
import React from 'react';
import PropTypes from 'prop-types';
import {getFieldProps, noErrors, patchForm, applyPatch} from 'midoffice/newforms/helpers';
import {requireOneOfProps} from 'midoffice/helpers/props';
import Field from 'midoffice/newforms/components/Field';
import MultiField from 'midoffice/newforms/components/MultiField';


function copyMissingFields(fromOb, toOb) {
    Object.keys(fromOb).forEach((key)=> {
        if (typeof(toOb[key]) === 'undefined') {
            toOb[key] = fromOb[key]; // eslint-disable-line immutable/no-mutation
        }
    });
}

export function injectField(DecoratedComponent) {
    class FieldWrapper extends React.Component {
        static propTypes = {
            name: PropTypes.string.isRequired,
            multi: PropTypes.bool,
            nowrap: PropTypes.bool,
            children: PropTypes.any,
            errorAsBlock: PropTypes.bool,
        };

        static contextTypes = {
            form: PropTypes.shape({
                fields: PropTypes.object.isRequired,
                value: PropTypes.object.isRequired,
                errors: PropTypes.object.isRequired,
            }).isRequired,

            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            onFocus: PropTypes.func.isRequired,
        };

        focus() {
            this.fieldRef && this.fieldRef.focus && this.fieldRef.focus();
        }

        handleChange = (value)=> {
            this.context.onChange(this.props.name, value);
        };

        handleFocus = ()=> {
            this.context.onFocus(this.props.name);
        };

        handleBlur = ()=> {
            this.context.onBlur(this.props.name);
        };

        handleReference = (field)=> {
            this.fieldRef = field; // eslint-disable-line immutable/no-mutation
        };

        render() {
            const {name, children, multi, nowrap, errorAsBlock, ...props} = this.props;

            const {fields, value, errors, errorField, schemaParams} = this.context.form;
            const field = fields[name];
            const fieldValue = value[name];
            const fieldErrors = errors[name];
            const showErrorMessage = Boolean(errorField === name || errorAsBlock);

            if (!field) {
                throw new Error(`The "${name}" field wasn't declared in schema`);
            }

            const fieldProps = {
                required: Boolean(field.isRequired),
                ...getFieldProps(field),
                name,

                value: fieldValue,
                errors: fieldErrors,
                showErrorMessage,

                schemaParams,

                onBlur: this.handleBlur,
                onChange: this.handleChange,
                onFocus: this.handleFocus,

                ref: this.handleReference,
                errorAsBlock,
                ...props,
            };
            if (nowrap) {
                return (<DecoratedComponent {...fieldProps} />);
            }
            const FieldClass = multi ? MultiField : Field;

            return (
                <FieldClass {...fieldProps} widget={DecoratedComponent}>
                    {children}
                </FieldClass>
            );
        }
    }

    DecoratedComponent.Field = FieldWrapper; // eslint-disable-line immutable/no-mutation

    return DecoratedComponent;
}

export function injectFormContext(DecoratedComponent) {
    class FormContextWrapper extends React.Component {
        static propTypes = {
            schema: PropTypes.object.isRequired,
            schemaParams: PropTypes.object,
            value: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.array
            ]),
            errors: PropTypes.object,
            children: PropTypes.any,

            onSubmit: PropTypes.func,
            onReset: PropTypes.func,
            onChange: PropTypes.func,
            onPatch: PropTypes.func,
        };

        static defaultProps = {
            onReset: noop,
        }

        static childContextTypes = {
            form: PropTypes.shape({
                fields: PropTypes.object.isRequired,
                schemaParams: PropTypes.any,
                value: PropTypes.object.isRequired,
                errors: PropTypes.object.isRequired,
            }).isRequired,

            onSubmit: PropTypes.func,
            onReset: PropTypes.func,

            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            onFormPatch: PropTypes.func.isRequired,
            onFocus: PropTypes.func.isRequired,
        };

        constructor(props) {
            requireOneOfProps(props, ['onChange', 'onPatch']);
            super(props);
        }

        state = {focusedField: null};

        getChildContext() {
            let {schema, schemaParams, value, errors} = this.props;
            errors = errors || {};

            let {focusedField: errorField} = this.state;

            if (!errorField) {
                errorField = Object.keys(errors).filter(
                    (key)=> !noErrors(errors[key])
                )[0];
            }

            value = schema.toInternalValue(value);

            return {
                form: {
                    fields: schema.fields,
                    errors,
                    value,
                    errorField,
                    schemaParams,
                },

                onSubmit: this.props.onSubmit,
                onReset: this.props.onReset,

                onChange: this.handleChange,
                onFormPatch: this.handleFormPatch,

                onBlur: this.handleBlur,
                onFocus: this.handleFocus,
            };
        }

        applyPatch = ({toChange, toRemove}) => {
            const {value, errors, onPatch, onChange} = this.props;
            if (onPatch) {
                onPatch({toChange, toRemove});
            }
            else {
                const formData = applyPatch({value, errors, toChange, toRemove});
                onChange(formData);
            }

        }

        handleChange = (fieldName, fieldValue)=> {
            this.handleFormPatch({[fieldName]: fieldValue});
        };

        handleFormPatch = (toChange, toRemove)=> {
            const {schema, schemaParams} = this.props;
            const patch = patchForm({schema, schemaParams, toChange});
            this.applyPatch({toChange: patch, toRemove});
        }

        handleBlur = (field)=> {
            const {value, schema: {fields}, schemaParams} = this.props;
            const fieldValue = value[field];
            const fieldErrors = fields[field].validate(fieldValue, schemaParams);

            if (this.state.focusedField === field) {
                this.setState({focusedField: null});
            }

            this.applyPatch({
                toChange: {
                    value: {
                        [field]: fieldValue,
                    },
                    errors: {
                        [field]: fieldErrors,
                    },
                }
            });
        };

        handleFocus = (field)=> {
            this.setState({focusedField: field});
        };

        render() {
            const {children, ...props} = this.props;

            return (
                <DecoratedComponent {...props}>
                    {children}
                </DecoratedComponent>
            );
        }
    }

    copyMissingFields(DecoratedComponent, FormContextWrapper);

    return FormContextWrapper;
}
