import React from 'react';
import {Route} from 'react-router-dom';

import {UsersRootHandler} from 'midoffice/components/users/lazyloader';

export default [
    <Route key="users" component={UsersRootHandler} path={[
        '/:tab(agents|admins|allusers)/:userId/',
        '/users/:tab(agents|admins|allusers)',
        '/users/',
    ]} />,
];
