import {UserAutocomplete} from 'midoffice/data/ManagedTravelerForm';

const URL = '/api/audit-logs/affected_user_autocomplete/';

export default class AffectedUserAutocomplete extends UserAutocomplete {
    constructor() {
        super({url: URL});
    }

    query(query) {
        return super.query(query, {forUser: 0, forCompanies: null});
    }

    parseResponse(data) {
        return data.map((user)=> {
            const label = user.email;
            return {value: user.id, label};
        });
    }
}
