const PNR_FOR_SAVE = [
    'profile',
    'previousBookingId',
];

const TSPM_FOR_SAVE = [
    'companies',
    'suggestedCompanies',
    'travelers',
    'configurations',
];

export default [
    (state)=> ([['multipnr', state.multiPnr.items.length]]),
    ['multiPnr.items', PNR_FOR_SAVE],
    'multiPnr.form',
    'multiPnr.currentCompany',
    'multiPnr.originalCompany',
    'multiPnr.oid',
    'multiPnr.errors',
    'multiPnr.retrieveErrors',
    'dest.hotels.value',
    'dest.cars.value',
    'dest.air.value',
    'dest.options',
    'dest.optionsParams',
    'dest.view',
    'dest.product',
    'dest.airSearchType',
    'homepage.step',
    'homepage.destination',
    'homepage.sso',
    'homepage.isPnrReady',
    ['tspms', TSPM_FOR_SAVE],
    'i18n.currency',
    'carBookingModification',
];
