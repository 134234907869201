const externalExtraActionTypes = {
    SET_BOOKING_TABLE_DISPLAYING: 'externalExtra/SET_BOOKING_TABLE_DISPLAYING',
    SET_SEARCH_FORM_ERROR_MESSAGE: 'externalExtra/SET_FORM_ERROR_MESSAGE',
    SET_DATA_MODAL_LOADING: 'externalExtra/SET_DATA_MODAL_LOADING',
    SET_DATA_MODAL_ERROR_MESSAGE: 'externalExtra/SET_DATA_MODAL_ERROR_MESSAGE',
    SET_RAW_MODAL_DATA: 'externalExtra/SET_DATA_MODAL_DATA',
    SET_EXTERNAL_BOOKING_DATA: 'externalExtra/SET_EXTERNAL_BOOKING_DATA',
    SET_EXTERNAL_SEARCH_TYPE: 'externalExtra/SET_EXTERNAL_SEARCH_TYPE',
};

export default externalExtraActionTypes;
