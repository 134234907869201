export const ELEMENT_H = 15;
export const OFFSCREEN = 200;

/*
 * This function finds ids of currently visible tree nodes
 * given their expanded/collapsed status and current scroll
 * position.
 *
 * Return value contains objects where keys are ids of all nodes
 * that are found to be visible at given moment.
 *
 * This function works more and more slower as user scrolls
 * down the tree. Accordning to profiler, this however is not
 * the slowest part of scroll handler.
 *
 * */
export function findVisible(expanded, tree, uptree, scrollSpace) {
    const ret = {};

    let visibleSpace = window.innerHeight * 1.5;

    let idx = 0;
    let parent = 'root';
    let deep = [];
    let currentId = null;

    while (visibleSpace > -OFFSCREEN) {
        currentId = tree[parent][idx];
        if (idx < tree[parent].length) {
            if (scrollSpace > 0) {
                scrollSpace -= ELEMENT_H;
            }
            else {
                visibleSpace -= ELEMENT_H;
            }

            if (scrollSpace < OFFSCREEN) {
                ret[currentId] = true;
                if (!ret[parent]) uptree[currentId].forEach((id)=> {
                    ret[id] = true;
                });
            }

            if (tree[currentId] && expanded[currentId]) {
                deep.push({idx: idx+1, parent});
                parent = currentId;
                idx = 0;
            }
            else {
                idx = idx + 1;
            }
        }
        else if (deep.length) {
            let up = deep.pop();
            idx = up.idx;
            parent = up.parent;
        }
        else {
            break;
        }
    }
    return ret;
}

/* This function calculates height (in nodes, not px) of specific
 * node or subtree. Expanded node with three children would count
 * for 4. Node without children or collapsed node counts for 1 */
function countSize(id, tree, expanded) {
    if (!expanded[id] || !tree[id] || !tree[id].length) {
        return 1;
    }

    return tree[id].reduce((acc, child)=> (
        acc + countSize(child, tree, expanded)
    ), 1);
}

const BEFORE = 0;
const VISIBLE = 1;
const AFTER = 2;

/*
 * This functions gets list of node ids as input and calculates
 * number of nodes before and after visible part of tree, as
 * well as ids of visible nodes between them.
 * */
export function splitVisible(ids, visible, expanded, tree) {
    let before = 0;
    let after = 0;

    const body = [];

    let state = BEFORE;
    let idx = 0;
    let end = ids.length;
    for (idx=0; idx < end; idx++) {
        let id = ids[idx];
        let isVisible = visible[id];

        if (state === BEFORE) {
            if (isVisible) {
                state = VISIBLE;
            }
            else {
                before += countSize(id, tree, expanded);
            }
        }
        if (state === VISIBLE) {
            if (isVisible) {
                body.push(id);
            }
            else {
                state = AFTER;
            }
        }
        if (state === AFTER) {
            after += countSize(id, tree, expanded);
        }
    }

    return {before, body, after};
}

/* This function calculates distance from tree root to arbitary node.
 * Distance is calculated as number of nodes, including expanded
 * ones with thildren (see `countSize` above) */
export function nodePosition(id, tree, uptree, expanded) {
    const ancestors =  uptree[id] || [];
    const parent = ancestors[0] || 'root';
    const siblings = tree[parent] || [];
    const idx = siblings.indexOf(id);
    const ownPos = siblings.slice(0, idx).reduce((acc, nodeId)=> (
        acc + countSize(nodeId, tree, expanded)
    ), 0);

    if (parent === 'root') {
        return ownPos;
    }

    return ownPos + 1 + nodePosition(parent, tree, uptree, expanded);
}
