import {partial, noop} from 'lodash';
import {changeFormValue, sendWithData} from 'airborne/store/modules/user_settings/actions/userSettings';
import {startAfterLogin, startApp} from 'airborne/store/modules/login/entryPoint';
import {
    getParamsGetUserData,
    normalizeParams,
    shouldRefreshUserData,
} from 'airborne/store/modules/login/helpers/getUserData';
import {getLocale} from 'airborne/store/modules/login/helpers/login';
import settings from 'airborne/settings';
import systemData from 'airborne/systemData';
import {setLang} from 'airborne/store/modules/header/actions/i18n';
import api from 'midoffice/helpers/api';
import {startListenMaintenances} from 'airborne/store/modules/maintenance/actions';
import {store} from 'midoffice/store';
import session from 'airborne/session';
import {formatUrl} from 'midoffice/helpers/url';
import {sendLogout} from 'airborne/store/modules/logout/actions/logout';

export const changeLoginValue = partial(changeFormValue, 'login');

const DEFAULT_FALLBACK_LANG = 'en';
const TERMS_REDIRECT_URL_KEY = 'termsRedirectUrl';
const SHOULD_SHOW_TERMS_KEY = 'shouldShowTerms';

export function showForgotPassword() {
    return {
        type: 'SHOW_FORGOT_PASSWORD',
    };
}

export function showForgotPasswordSuccess() {
    return {
        type: 'SHOW_RESET_PASS_SUCCESS',
    };
}
export function showHomepage() {
    return {
        type: 'SHOW_HOMEPAGE',
    };
}
export function showMidoffice() {
    return {
        type: 'SHOW_MIDOFFICE',
    };
}

export function shouldShowTerms() {
    return localStorage.getItem(SHOULD_SHOW_TERMS_KEY) === '1';
}

export function getTermsRedirectUrl() {
    return localStorage.getItem(TERMS_REDIRECT_URL_KEY);
}

export function setTermsToStore(redirectUrl) {
    localStorage.setItem('shouldShowTerms', 1);
    redirectUrl && localStorage.setItem(TERMS_REDIRECT_URL_KEY, redirectUrl);
}

export function resetTermsInStore() {
    localStorage.removeItem(SHOULD_SHOW_TERMS_KEY);
    localStorage.removeItem(TERMS_REDIRECT_URL_KEY);
}

export function showTerms() {
    setTermsToStore();
    return {
        type: 'SHOW_TERMS',
    };
}

export async function getSystemData() {
    await api('GET', '/internal/accounts/get_system_data').then(response => {
        systemData.set(response);
        startListenMaintenances(store.dispatch);
    });
}

function setUserLanguage(response, dispatch, isLogin) {
    if (response.SUB_PARTNER && response.SUB_PARTNER !== 'common') {
        if (isLogin) {
            dispatch({type: 'LOADING', endpoint: 'login'});
        }
        const lang = settings.LANGUAGE_CODE || response.USER['lang_code'] || DEFAULT_FALLBACK_LANG;
        return dispatch(setLang(lang)).then(() => response);
    }
    else {
        return response;
    }
}

export const getRedirectUrl = () => {
    const url = new URL(location.href);
    return url.searchParams.get('next');
};

function isNeedToLogOut(oldSettings, newSettings) {
    function isPermissionCodeRemoved(code) {
        return oldSettings.USER_ALL_PERMISSIONS.includes(code) && !newSettings.USER_ALL_PERMISSIONS.includes(code);
    }
    const DISPLAY_MODE = settings['DISPLAY_MODE'];
    return DISPLAY_MODE === 'aft' && isPermissionCodeRemoved('aft:login')
        || DISPLAY_MODE === 'admin' && isPermissionCodeRemoved('admin:login');
}

export function sendLogin() {
    return function sendLoginD(dispatch) {
        const data = getLocale();
        const redirectUrl = getRedirectUrl();

        return dispatch(sendWithData('login', '/internal/accounts/login/', 'POST', data, true))
            .then(response => setUserLanguage(response, dispatch, true))
            .then(async response => {
                await getSystemData();
                startAfterLogin(response, dispatch, redirectUrl);
            })
            .catch(noop); /// all error handling is done in sendChanges
    };
}

const getUserDataRequestParams = shouldRefreshData => {
    const params = getParamsGetUserData();
    const baseUrl = '/internal/accounts/get_user_data/';
    const url = shouldRefreshData ? baseUrl : baseUrl + normalizeParams(params);
    return {
        endpoint: '',
        url,
        type: 'GET',
        additionalData: '',
        rethrow: true,
    };
};

export function getUserData(refresh = false) {
    return function getUserDataD(dispatch) {
        const STOP_PROMISE_CHAIN = 'stop chain';
        let isPermissionsChanged = false;
        const params = getUserDataRequestParams(refresh);

        if (shouldShowTerms() && !refresh) {
            dispatch(showTerms());
        }

        return dispatch(sendWithData(...Object.values(params)))
            .then(response => {
                if (refresh) {
                    isPermissionsChanged = shouldRefreshUserData(response.USER_ALL_PERMISSIONS);
                    if (isNeedToLogOut(settings, response)) {
                        dispatch(sendLogout('permissions_changed'));
                    }
                    if (isPermissionsChanged) {
                        settings.set(response);
                    }
                }
                return response;
            })
            .then(response => {
                if (refresh) throw new Error(STOP_PROMISE_CHAIN);
                return response;
            })
            .then(response => setUserLanguage(response, dispatch))
            .then(response => startApp(response, dispatch))
            .catch(response => {
                if (response.status === 401) {
                    location.assign(formatUrl('/accounts/login/', {next: location.pathname + location.search}));
                    return response;
                }

                if (refresh && response.message === STOP_PROMISE_CHAIN) {
                    throw isPermissionsChanged;
                }
                if (refresh) {
                    throw response;
                }
                noop();
            });
    };
}

export function resetSid() {
    return function resetSidD(dispatch) {
        const params = getUserDataRequestParams(false);

        return dispatch(sendWithData(...Object.values(params)))
            .then(response => {
                session.set('sid', response.sid);
                settings.set({sid: response.sid});
            })
            .catch(error => {
                throw error;
            });
    };
}

export function storeUrl({search}) {
    const query = new URLSearchParams(search);
    const source = query.get('source');
    const timeout = query.get('timeout');
    return {
        type: 'SET_URL_PARAMS',
        source,
        timeout,
    };
}
