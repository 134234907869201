import React from 'react';
import gettext from 'airborne/gettext';
import {CancellationShape} from '../shapes';
import {formatStrict} from 'airborne/utils/price';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

export default class Cancellation extends React.Component {
    static propTypes = {
        cancellation: CancellationShape,
    }

    renderText() {
        const {cancellation} = this.props;
        const refundable = cancellation?.refundable;

        if (refundable === true) {
            return <span className="highlight-green">{gettext('Refundable')}</span>;
        }

        if (refundable === false) {
            return <span className="highlight-red">{gettext('Non-Refundable')}</span>;
        }

        return <span className="highlight-gray">{gettext('Unknown')}</span>;
    }

    renderFee() {
        const {cancellation} = this.props;
        const fee = cancellation?.fee;
        const amount = cancellation?.amount;

        if (fee === true) {
            const amountText = amount ? formatStrict(amount.amount, amount.currency) : '';
            return `• ${gettext('Fee')} ${amountText}`;
        }

        return null;
    }

    renderDescription() {
        const {cancellation} = this.props;
        const description = cancellation?.description;

        if (description) {
            return (
                <AnnotateTooltip id={'cancellation-description'}>
                    <span className="glyphicons glyphicons-chat" />
                    {description}
                </AnnotateTooltip>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="a-content__item__rate-details" data-testid="fare-group-cancellation">
                {gettext('Cancellation')}:{' '}
                <span>
                    {this.renderText()} {this.renderFee()} {this.renderDescription()}
                </span>
            </div>
        );
    }
}
