import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';
import debounce from 'debounce-promise';

import {parseDestinations, parseRecent, parseRecentAir, parseRecentCars, nopAborted} from 'airborne/homepage2/helpers/autocomplete';

const ID = 'destination_autocomplete';

function getHotelsRecentItems(configId) {
    return api('GET', '/internal/get_hotels_recent_items/', {
        data: {'configuration_id': configId},
        id: ID,
    }).then(parseRecent, nopAborted);
}

function getCarsRecentItems() {
    return api('GET', '/internal/get_cars_recent_searches/', {
        id: ID,
    }).then(parseRecentCars, nopAborted);
}

function getAirRecentItems() {
    return api('GET', '/internal/get_air_recent_searches/', {
        id: ID,
    }).then(parseRecentAir, nopAborted);
}

const debouncedApi = debounce(api, 200);

export function queryDestinations(term, {companyId, product, gds}, applyDebounce, id) {
    const {
        USER_LOCALE: locale='en_US',
        SUB_PARTNER: system='aft',
        sid,
    } = settings;

    if (term?.length < 2) {
        api.abortMatching(ID);
        return Promise.reject(null);
    }

    const operatingApi = applyDebounce ? debouncedApi : api;
    return operatingApi('GET', '/latest/destination_lookup/', {
        data: {
            'configuration_id': companyId,
            'session_id': sid,
            product,
            locale,
            system,
            term,
        },
        id,
    }).then(parseDestinations.bind({gds: gds}, term), nopAborted);
}

export default class Autocomplete {
    constructor (id) {
        if (id) {

            this.id = id;
        }
    }

    id = ID;

    query(term, {companyId, product, gds, applyDebounce=true}) {
        return queryDestinations(term, {companyId, product, gds}, applyDebounce, this.id);
    }

    empty({product='hotels', companyId, recentSearches=true}) {
        if (!companyId) {
            api.abortMatching(ID);
            return Promise.reject(null);
        }

        if (!recentSearches) { return Promise.resolve(null); }

        const getRecentItems = {
            'hotels': getHotelsRecentItems,
            'cars': getCarsRecentItems,
            'air': getAirRecentItems,
        }[product];

        return getRecentItems(companyId);
    }
}
