export const PROVIDER_TABS = [
    {
        id: 'conferma',
        name: 'Conferma Settings',
    },
    {
        id: 'troovo',
        name: 'Troovo Settings',
    },
    {
        id: 'itelya',
        name: 'Itelya Settings',
    },
];

export const ED = [
    [true, 'Enabled'],
    [false, 'Disabled'],
];

export const AIR_PRODUCTS = [
    ['gds', 'GDS'],
    ['lcc', 'LCC'],
    ['ndc', 'NDC'],
];

export const PROVIDERS = [
    ['conferma', 'Conferma'],
    ['troovo', 'Troovo'],
    ['itelya', 'Itelya'],
];

export const BILLING_PHONE_HINT = 'Should always be used as billing phone for VPA';

export const PAYMENT_OPTIONS = [
    ['vpa_guarantee', 'Guarantee bookings only'],
    ['vpa_payment', 'Payment only'],
    ['payment_or_guarantee', 'Guarantee or Payment'],
];

export const AD = [
    [false, 'Disallowed'],
    [true, 'Allowed'],
];

export const BOOKING_ISSUES_EMAIL_HINT = `Faxes will be sent for bookings
instructing booked hotels not to request credit cards from travelers.
Should a fax fail, an email alert will be sent to the address provided.
Should card authorization fail, hotels will be instructed
to email this address.`;

export const EXTRA_DAYS_HINT = {
    hotels: 'The number of extra days of virtual credit card validity added to checkout date.',
    cars: 'The number of extra days of virtual credit card validity added to dropoff date.',
    air: 'The number of extra days of virtual credit card validity added to arrival date.',
};

export const EXTRA_DAYS_BEFORE_LABEL = {
    hotels: 'Extra days before checkin for G rates',
    cars: 'Extra days before pickup',
};

export const EXTRA_DAYS_BEFORE_HINT = {
    hotels: 'The number of extra days of virtual credit card validity prior to checkin date for G rates. For D rates virtual credit card is activated immediately after issuing.',
    cars: 'The number of extra days of virtual credit card validity prior to pickup date.',
};

export const NON_CONFERMA_CREDIT_CARDS_HINT = `Allowing non-VPA credit cards will
enable users to book using credit cards not issued by VPA.
Disallowing non-VPA credit cards will exclude all card-only rates from
hotels without a valid fax number in case when 'Guarantee bookings only' is
set to 'No' and will exclude deposit card-only rates
from all hotels if 'Guarantee bookings only' is
set to 'Yes'.`;

export const NON_CONFERMA_CREDIT_CARDS_HINT_NOT_HOTELS = `Allowing non-VPA credit cards will
enable users to book using credit cards not issued by VPA.`;

export const GUARANTEE_BOOKINGS_ONLY_HINT = `Using single-use virtual credit cards
for guarantee-only means that travelers will be expected to present
payment at the hotel. VPA payments will not be permitted for all types of
rates. No fax will be sent to hotel.`;

export const PRODUCTS = [
    {
        id: 'general_vpa',
        title: 'Generic VPA',
    },
    {
        id: 'hotels',
        title: 'Hotels VPA',
    },
    {
        id: 'cars',
        title: 'Cars VPA',
    },
    {
        id: 'air',
        title: 'Flights VPA',
    },
    {
        id: 'general',
        title: 'Providers Configuration',
    },
    {
        id: 'payment_manager',
        title: 'vCC Deployment',
    },
];
