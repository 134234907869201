import {combineReducers} from 'redux';
import * as types from '../actionTypes';

const defaultCurrentOD = 0;
const currentOD = (state = defaultCurrentOD, {type, OD}) => {
    switch (type) {
        case types.AIR_AVAILABILITY_START_SEARCH:
        case types.AIR_AVAILABILITY_SET_DEFAULT_FLIGHT_OPTIONS_SELECTION:
        case types.AIR_AVAILABILITY_RESET_FLIGHT_OPTIONS_SELECTION:
            return defaultCurrentOD;
        case types.AIR_AVAILABILITY_SET_CURRENT_OD:
            return OD;
        default:
            return state;
    }
};

const defaultFlightOptionsSelection = [];
const flightOptionsSelection = (
    state = defaultFlightOptionsSelection,
    {type, flightOptionId, ODIndex, length, selection}
) => {
    switch (type) {
        case types.AIR_AVAILABILITY_START_SEARCH:
            return defaultFlightOptionsSelection;
        case types.AIR_AVAILABILITY_SET_FLIGHT_OPTIONS_SELECTION:
            return selection;
        case types.AIR_AVAILABILITY_SET_DEFAULT_FLIGHT_OPTIONS_SELECTION:
            return [...Array(length)].map(() => null);
        case types.AIR_AVAILABILITY_RESET_FLIGHT_OPTIONS_SELECTION:
            return state.map(() => null);
        case types.AIR_AVAILABILITY_ADD_FLIGHT_OPTION_SELECTION:
            return state.map((opt, index) => index === ODIndex ? flightOptionId : opt);
        default:
            return state;
    }
};

const defaultCurrentPage = 1;
const currentPage = (state = defaultCurrentPage, {type, page}) => {
    switch (type) {
        case types.AIR_AVAILABILITY_START_SEARCH:
        case types.AIR_AVAILABILITY_SET_CURRENT_OD:
            return defaultCurrentPage;
        case types.AIR_AVAILABILITY_SET_CURRENT_PAGE:
            return page;
        default:
            return state;
    }
};

const selection = combineReducers({
    currentOD,
    flightOptionsSelection,
    currentPage,
});

export default selection;
