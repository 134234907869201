import omit from 'lodash/omit';
import {CANCEL, CHANGED, LOADED, LOADING, DISCARD, NET_FAIL} from '../actions/types';
import {keyForSettings, keyForCompanyInfo} from 'midoffice/helpers/settings';

function initialState() {
    return {
        tasks: {},
        hash: {},
        companyInfoHash: {},
        initial: {},
        errors: {},
        failed: {},
        loading: false,
        constants: {},
    };
}

function setLoading(state, loading) {
    return {
        ...state,
        loading,
    };
}

function setErrors({errors, failed, ...state}, {errors: settingErrors, ...action})  {
    const key = keyForSettings(action);

    return {
        ...state,
        companyInfo: {},
        errors: {
            ...errors,
            [key]: settingErrors
        },
        failed: {
            ...failed,
            [key]: true,
        },
        loading: false,
    };
}

function setParams({hash, companyInfoHash, initial, errors, tasks, failed, constants}, {data, errors: settingErrors, tasks: settingTasks, ...action}, sync) {
    const key = keyForSettings(action);
    const companyInfoKey = keyForCompanyInfo(action);

    settingErrors = sync ? null : (settingErrors || null);
    const cleanedData = data && omit(data, ['companyInfo', 'constants']);
    return {
        hash: {
            ...hash,
            [key]: cleanedData,
        },
        companyInfoHash: data && data.companyInfo ? {
            ...companyInfoHash,
            [companyInfoKey]: data.companyInfo,
        } : companyInfoHash,
        initial: sync ? {
            ...initial,
            [key]: cleanedData,
        } : initial,
        tasks: sync ? {
            ...tasks,
            [key]: settingTasks,
        } : tasks,
        errors: {
            ...errors,
            [key]: settingErrors,
        },
        failed: omit(failed, key),
        loading: false,
        constants: sync && data && data.constants
            ? {
                ...constants,
                [action.section]: data.constants,
            }
            : constants,
    };
}

function cancelParams({hash, errors, ...state}, action) {
    const key = keyForSettings(action);
    const data = state.initial[key];

    return {
        ...state,
        hash: {
            ...hash,
            [key]: data,
        },
        errors: {
            ...errors,
            [key]: null,
        },
        loading: false,
    };
}

export default function settings (state, {type, ...action}) {
    if (!state) {
        return initialState();
    }

    if (type === LOADING) {
        return setLoading(state, true);
    }
    if (type === LOADED) {
        return setParams(state, action, true);
    }
    if (type === CHANGED) {
        return setParams(state, action, false);
    }
    if (type === CANCEL) {
        return cancelParams(state, action);
    }
    if (type === DISCARD) {
        return setParams(state, action, true);
    }
    if (type === NET_FAIL) {
        return setErrors(state, action);
    }

    return state;
}
