/* global IS_PROD */
export function createAfterMiddleware(handlers={}) {
    return (store) => (next) => (action) => {
        if (typeof action.type === 'string' && action.type.startsWith('afterMiddleware')) {
            return;
        }

        const prevState = store.getState();
        const actionResult = next(action);

        if (action.type in handlers) {
            !IS_PROD && store.dispatch({type: `afterMiddleware:${action.type}:${handlers[action.type].name}`});
            handlers[action.type](
                store.dispatch,
                store.getState,
                () => prevState,
            );
        }

        return actionResult;
    };
}
