import find from 'lodash/find';

export function countryName(id, list) {
    if (!id || !list[id]) {
        return null;
    }
    const node = list[id];
    if (node['is_agency']) {
        const parentNode = list[node.parent];
        return parentNode.title;
    }
    if (node['is_country']) {
        return node.title;
    }

    return null;
}

export default function agencyCountry(agencies, countries, agencyId) {
    const country = countryName(agencyId, agencies);
    if (country === 'United States') {
        return 'US';
    }
    const ret = find(countries, ([, name])=> country === name);
    return ret && ret[0] || null;
}
