const CARDS = {
    VI: 'Visa',
    AX: 'American Express',
    CA: 'MasterCard',
    DS: 'Discover',
    JC: 'Japan Credit Bureau (JCB)',
    DC: 'Diners Club',
};

export function cardLabel(type) {
    return CARDS[type] || type;
}

export function pad(num) {
    if (!num) {
        return null;
    }
    const ret = num.toString();
    return (ret.length < 2) ? '0' + ret : ret;
}
