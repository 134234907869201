import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import {loadHotelList} from 'airborne/store/modules/search_hotels/actions/search';
import generateUUID from 'airborne/checkout2/helpers/uuid';


function removePrefix(prefix, value) {
    return value.startsWith(prefix) ? value.substr(prefix.length) : value;
}

export function switchEmailOffer(value, data, index) {
    return function switchEmailOfferD(dispatch) {
        const hotelId = Number(removePrefix('Hotel:', value.destination.value));
        const rateKey = data['rate_key'];
        const currency = data['currency'];

        dispatch({
            type: 'CHECKOUT_SWITCH',
            currency, rateKey, hotelId, uuid: generateUUID(), destination: value,
            index,
        });
        dispatch(loadHotelList());
        dispatch(getRateDetails(hotelId, rateKey));
    };
}
