import wrap from 'midoffice/components/LazyComponent';

function load() {
    document.body.classList.add('layout-rev');
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./BookingRecordsPage');
            resolve(loaded);
        }, 'car-bookings');
    });
}
export const CarsBookingsHandler = wrap(load);
