import {combineReducers, Reducer} from 'redux';

const lang = (state=null, {type, lang=null}: {type: string, lang: string | null}): string | null => {
    if (type === 'LOAD_I18N' || type === 'SET_LANG') {
        return lang;
    }
    return state;
};

const currency = (state=null, {type, currency=null}: {type: string, currency: string | null}): string | null => {
    if (type === 'LOAD_I18N' || type === 'SET_CURRENCY') {
        return currency;
    }
    return state;
};

const loading = (state=false, {type}: any): boolean => {
    if (type === 'LANG_LOADING') {
        return true;
    }
    if (type === 'LANG_LOADED') {
        return false;
    }
    return state;
};

export default combineReducers({
    currency: currency as Reducer<any>,
    lang: lang as Reducer<any>,
    loading: loading as Reducer<any>,
});
