import flow from 'lodash/flow';
import pick from 'lodash/fp/pick';
import uniqBy from 'lodash/uniqBy';
import intersection from 'lodash/intersection';
import normalize from 'airborne/search2/helpers/normalize';
import {generateId, resetId} from 'airborne/search2/helpers/id';
import {getMinutesFromMidnight, getSegmentsDiffMins, minutesToDuration} from 'airborne/search_air/helpers';
import {PREFERRED_AIRLINES_CHOICES} from 'airborne/search_air/helpers';
import {isPreferredByKey} from 'airborne/search_air/types';
import systemData from 'airborne/systemData';

const SEAT_STATUSES = {
    // No available seats
    '0': '0'
};

const flightOptionsIdScope = Symbol('Air Availability Flight Options ID scope');
const segmentsIdScope = Symbol('Air Availability Segments ID scope');
const classesIdScope = Symbol('Air Availability Class Of Service ID scope');

const getCarriers = (segments) => {
    return uniqBy(segments.map(({carrier: {code, name}}) => ({code, name})), 'code');
};

const getCarrierNames = segments => getCarriers(segments).map(carrier => carrier.name);

const getAlliances = (segments) => {
    const airAlliances = Object.entries(systemData.air.AIR_ALLIANCES);
    const airlineCodes = getCarriers(segments).map(({code}) => code);
    return airAlliances
        .filter(([, allianceAirlineCodes]) => intersection(allianceAirlineCodes, airlineCodes).length)
        .map(([alliance]) => alliance);
};

const parseClasses = classes => {
    const filteredClasses = classes.map(serviceClass => {
        const {seatsStatus, ...rest} = serviceClass;

        if (!seatsStatus) {
            return {
                seatsStatus: {code: ''},
                ...rest,
            };
        }

        return {seatsStatus, ...rest};
    }).filter(serviceClass => {
        const {seatsStatus: {code}} = serviceClass;

        return code !== SEAT_STATUSES['0'];
    });

    return filteredClasses.map(serviceClass => {
        const id = String(generateId(classesIdScope));

        return {
            ...serviceClass,
            id,
        };
    });
};

const parseSegments = segments => {
    return segments.map((segment, index) => {
        const id = String(generateId(segmentsIdScope));
        const {classes} = segment;
        const nextSegment = segments[index + 1];
        const parsedClasses = parseClasses(classes);

        return {
            ...segment,
            id,
            classes: parsedClasses,
            available: Boolean(parsedClasses.length),
            layoverDuration: nextSegment
                ? flow(getSegmentsDiffMins, minutesToDuration)(segment, nextSegment)
                : null
        };
    });
};

const parseFlightOptions = (flightOptions, OD, ODIndex) => {
    const {departureIata, arrivalIata} = OD;
    const {BCD_PREFERRED, COMPANY_PREFERRED} = PREFERRED_AIRLINES_CHOICES;
    return flightOptions
        .map((flightOption) => {
            const id = String(generateId(flightOptionsIdScope));
            const {segments} = flightOption;
            return {
                ...flightOption,
                ODIndex,
                departureIata,
                arrivalIata,
                id,
                departureMinutes: getMinutesFromMidnight(segments[0].departure.datetime),
                arrivalMinutes: getMinutesFromMidnight(segments[segments.length - 1].arrival.datetime),
                segments: parseSegments(segments),
                carriers: getCarriers(segments),
                airlines: getCarrierNames(segments),
                alliances: getAlliances(segments),
                [BCD_PREFERRED]: isPreferredByKey(segments, 'bcdPreferredAirline'),
                [COMPANY_PREFERRED]: isPreferredByKey(segments, 'preferredAirline'),
                flightNumbers: uniqBy(flightOption.segments, 'carrier.flightNumber')
                    .map(({carrier}) =>`${carrier.code}${carrier.flightNumber}`),
            };
        })
        .sort((a, b) => {
            return a.currentlyBooked === b.currentlyBooked
                ? 0 : a.currentlyBooked ? -1 : 1;
        });
};

const parseData = data => {
    resetId(flightOptionsIdScope);
    resetId(segmentsIdScope);
    resetId(classesIdScope);

    const {originDestinations} = data;

    return {
        ...data,
        originDestinations: originDestinations.map((OD, ODIndex) => {
            const {flightOptions} = OD;

            return {
                ...OD,
                ODIndex,
                flightOptions: parseFlightOptions(flightOptions, OD, ODIndex),
            };
        }),
    };
};

export const parseAvailability = response => {
    const data = flow(
        normalize,
        pick('originDestinations'),
        parseData,
    )(response);

    return data;
};
