function timezoneOffset() { return (new Date()).getTimezoneOffset(); }
export function getTimezoneAbbr(offset=timezoneOffset()) {
    const sign = offset > 0 ? '-' : '+';
    const inHours = Math.floor(Math.abs(offset) / 60);
    return `GMT${sign}${inHours}`;
}


export function isToday(date) {
    const dateString = (new Date(date)).toDateString();
    const todayString = (new Date()).toDateString();
    return dateString === todayString;
}


export function toISODateString(momentDate) {
    return momentDate.toISOString().split('T')[0];
}

export function toDayStart(momentDate) {
    if (!momentDate || !momentDate.isValid()) { return momentDate; }
    return momentDate.clone().hour(0).minute(0).second(0).millisecond(0);
}

export function isSameDay(date1, date2) {
    return toDayStart(date1).isSame(toDayStart(date2));
}

export function isCompleteRange({min, max}) {
    return min && min.isValid() && max && max.isValid();
}
