
export function parse(data) {
    const agentChat = data['agent_chat'] || {};
    return {
        'agent_chat:enable': agentChat['enable'],
        'agent_chat:provider': agentChat['provider'],
        'agent_chat:gms_service_name': agentChat['gms_service_name'] || null,
        'agent_chat:chat_url': agentChat['chat_url'] || null,
        'agent_chat:genesys_deployment_id': agentChat['genesys_deployment_id'] || null,

    };
};

export function serialize(data) {
    return {
        'agent_chat': {
            'enable': data['agent_chat:enable'],
            'provider': data['agent_chat:provider'],
            'gms_service_name': data['agent_chat:gms_service_name'],
            'chat_url': data['agent_chat:chat_url'],
            'genesys_deployment_id': data['agent_chat:genesys_deployment_id'],
        }
    };
}
