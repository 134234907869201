// STEPS is a constant defined by product

export function compareSteps(steps, base, step) {
    return steps.includes(step) && steps.indexOf(step) < steps.indexOf(base);
}

export function stepAfter(steps, currentStep, stepsToSkip= []) {
    const idx = steps.indexOf(currentStep);
    const nextStep = steps[idx + 1] || steps[steps.length - 1];
    return stepsToSkip.includes(nextStep) ? stepAfter(steps, nextStep, stepsToSkip) : nextStep;
}

export function stepBefore(steps, step, stepsToSkip= []) {
    const idx = steps.indexOf(step);
    const prevStep = steps[idx - 1] || steps[0];
    return stepsToSkip.includes(prevStep) ? stepBefore(steps, prevStep, stepsToSkip) : prevStep;
}

export function toStep(steps, currentStep, newStep) {
    const newBeforeCurrent = compareSteps(steps, currentStep, newStep);
    return newBeforeCurrent ? newStep : currentStep;
}
