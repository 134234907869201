import indexOf from 'lodash/indexOf';
import each from 'lodash/each';

const TERM_SPLIT_PATTERN = /[\\\/\.\s\-—,+*?[\]^&(){}=!<>|:"]+/igm;

function clear(str) {
    return str
        .toLowerCase()
        .split(TERM_SPLIT_PATTERN)
        .sort()
        .join(' ');
}

function formatResultParts(result) {
    let formatted = [];
    each(result.address_components, ({types, short_name})=> {  // eslint-disable-line camelcase
        if (indexOf(types, 'postal_code') !== -1) { return; }
        formatted.push(clear(short_name));
    });
    return formatted.join(', ');
}

export default function ignoreDuplicates(original, query) {
    const exists = [];
    const existsParts = [];
    const formattedQuery = clear(query);
    let results = [];

    for (var i = 0; i < original.length; i++) {
        let result = original[i];
        let formattedAddress = clear(result.formatted_address);
        if (formattedQuery === formattedAddress) {
            return [result];
        }
        if (indexOf(exists, formattedAddress) === -1) {
            let resultParts = formatResultParts(result);
            exists.push(formattedAddress);

            if (indexOf(existsParts, resultParts) === -1) {
                existsParts.push(resultParts);
                results.push(result);
            }
        }
    }
    return results;
}
