import React from 'react';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import {mapStateToProps} from 'airborne/user_settings2/helpers/formHelpers';
import {Link} from 'react-router-dom';

@connect((state) => mapStateToProps(state, 'resetPassword'))
class ResetPasswordError extends React.Component {
    render() {
        return (
            <div>
                <h3 className="floated-form__body__header">{gettext('Error')}</h3>

                <p>
                    <strong>{gettext('The password reset request has expired.')}</strong>
                </p>
                <p>
                    {gettext('You may reset your password again on the login page.')}
                </p>
                <p>
                    <Link to="/accounts/login" >{gettext('Click here')}</Link>
                    {gettext(' to redirect to login page.')}
                </p>
            </div>
        );
    }
}


export default ResetPasswordError;
