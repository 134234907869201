import moment from 'moment';
import get from 'lodash/get';
import filter from 'lodash/filter';

export function getMessages(state, query) {
    const messages = Object.values(get(state, 'inbox.hash'));
    const activeMessages = messages.filter(({active}) => (active));
    return query ? filter(activeMessages, query) : activeMessages;
}

export function getLatestTimestamp(messages) {
    if (!messages || !messages.length) {
        return null;
    }
    const [{fromDate: last}] = messages;
    const ret = messages.reduce((acc, {fromDate, updateDate})=>
        moment.max([acc, fromDate, updateDate]),
    last
    );
    // Postgres stores timestamps with nanosecond resolution.
    // However, when dat is parsed from ISO8601 nanoseconds are lost.
    // This results in FE sending date that is technically *before*
    // date of last message, which breaks if-modified-since flow.
    // Line below return original string representation of date,
    // as returned by API.
    return ret && ret._i; // explain this
}

export function getMessageForElement(state, queryElement) {
    const [message] = getMessages(state, {'element': queryElement, 'new': true});
    return message || null;
}
