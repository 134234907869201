export function getItemByIndex(items, index) {
    if (!Array.isArray(items)) {
        throw new Error('items should be array');
    }

    if (typeof index !== 'number') {
        throw new Error('multislot index missing');
    }

    return items[index] || null;
}
