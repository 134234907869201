function initialState() {
    return {
        items: [],
        errors: {},
        pnr: '',
        sortBy: '-data_sent',
    };
}

export function sortItems(items, sortBy='-date_sent') {
    if (!sortBy.includes('date_sent')) { return items; }
    const direction = sortBy[0] === '-';
    const sortField = sortBy[0] === '-' ? sortBy.substring(1) : sortBy;
    return items.sort((item1, item2) => {
        const d1 = new Date(item1[sortField]).getTime();
        const d2 = new Date(item2[sortField]).getTime();
        return direction ?
            d1 < d2 ? 1 : -1 :
            d1 > d2 ? 1 : -1;
    });
}

export default function emailOffers (state=initialState(), {items, type, errors, pnr, sortBy}) {
    if (type === 'SHOW_EMAIL_OFFERS') {
        return {...state, pnr};
    }
    if (type === 'SET_OFFERS') {
        return {...state,  items: sortItems(items)};
    }
    if (type === 'SORT_OFFERS') {
        return {...state,  items: sortItems(state.items, sortBy), sortBy};
    }
    if (type === 'FAILED') {
        return {...state, errors};
    }
    return state;
}
