import {noop} from 'lodash';
import api from 'midoffice/helpers/api';

export function navigateToLogin(message) {
    window.location = `/accounts/login/?source=${message}`;
}

export function sendLogout(message='logout_request') {
    return function sendLogoutD() {
        return api('GET', '/internal/accounts/logout/', {
            data: {reason: message}
        })
            .then(() => {
                navigateToLogin(message);
            })
            .catch(noop);
    };
}
