import React from 'react';
import PropTypes from 'prop-types';

import {hasAccess} from 'midoffice/helpers/permission';
import some from 'lodash/some';

export default class Permission extends React.Component {
    static propTypes = {
        need: PropTypes.string,
        nested: PropTypes.array,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]).isRequired,
    };

    shouldRenderNested = () => {
        const {nested, need} = this.props;
        if (!nested) {
            return hasAccess(need);
        }
        return some(
            nested.map(({permission}) => permission),
            permission => hasAccess(permission),
        );
    };

    renderNothing() {
        return (<div style={{display: 'none'}} />);
    }

    render() {
        if (!this.shouldRenderNested()) {
            return this.renderNothing();
        }

        return this.props.children;
    }
}
