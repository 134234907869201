import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import applyFilters from 'midoffice/helpers/filters';
import convertFilters from 'airborne/search2/helpers/filters';
import paginate, {orderInline} from 'midoffice/helpers/paginate';
import {priceOrder, comparePrices} from 'airborne/search2/helpers/price';
import {preferenceOrder, comparePreferences} from 'airborne/search2/helpers/preference';
import {getDestForm, getDestination} from 'airborne/store/modules/homepage/selectors/homepage';

export function serializeDestination(value) {
    const [type, code] = value.split(':');
    if (type === 'Coordinates') {
        return code;
    }

    return value;
}


function sort(hotels, order) {
    if (preferenceOrder(order)) {
        return hotels.sort((a, b)=> comparePreferences(a, b, order.startsWith('-')));
    }
    if (priceOrder(order)) {
        return hotels.sort((a, b)=> comparePrices(a, b, order.startsWith('-')));
    }
    return orderInline(hotels, order, true);
}


function forceUp(hotels, hotelId) {
    if (!hotelId) {
        return hotels;
    }
    const idx = findIndex(hotels, {'id': hotelId});
    if (idx === 0 || idx === -1) {
        return hotels;
    }
    const ret = [...hotels];
    const pinnedHotel = hotels[idx];
    ret.splice(idx, 1);
    ret.splice(0, 0, pinnedHotel);
    return ret;
}

/* This function filters, sorts and paginates hotels.
 * In case of sorting by price places sold out hotels to the end of the list.
 */
export function paginateHotels(hotels, filters, order, pagination, pin, single) {
    const filtered = filters ? applyFilters(hotels, convertFilters(filters, single)) : hotels;
    const preordered = forceUp(
        order ? sort(filtered, order) : filtered,
        pin
    );
    return pagination
        ? paginate(preordered, null, pagination)
        : preordered;
}

export function isFiltered(filters, single) {
    const filterList = convertFilters(filters, single)
        .filter(({field, op})=> field !== 'available.is_excluded' && op !== 'inverse');
    return filterList.length !== 0;
}

export function mergeAvail(hotels, avail) {
    return hotels.map((hotel)=> ({
        ...hotel,
        available: avail[hotel.id],
    }));
}

export function allExcluded(hotels, avail) {
    return hotels.reduce((acc, {id})=> {
        const {'is_excluded': excluded} = avail[id] || {};
        return acc && excluded;
    }, true);
}

function isHotel(value) {
    return Boolean(value && value.startsWith('Hotel:'));
}

export function getHotel({hotels: {hotels}}, id) {
    return find(hotels.data, {id});
}

export function searchSingleHotel(state) {
    return isHotel(get(getDestForm(state), 'value.destination.value'));
}

export function destSingleHotel(state) {
    return isHotel(get(getDestination(state), 'destination.value'));
}

export function allSameCountry(list) {
    if (!list.length) return null;
    return Boolean(list.reduce(
        (acc, {'country_code': code})=>
            (code === acc) ? code : false,
        list[0]['country_code']
    ));
}

export function isLoading(state) {
    const {hotels, avail} = state.hotels;
    return hotels.loading || !isEmpty(avail.loading);
}

export function getHolelSelection(state) {
    return state.hotels.selected;
}

export function getHotelAvailabilityLoadings(state) {
    return state.hotels.avail.loading;
}

export function getHotelAvailabilities(state) {
    return state.hotels.avail.data;
}
