import * as types from '../actionTypes';
import {createHandler} from 'airborne/store/config/middlewares/stream';
import {navigateToLogin} from 'airborne/store/modules/logout/actions/logout';

export default createHandler(
    types.USER_SESSION_LOGOUT,
    (store, action) => {
        const {payload: {message}} = action;

        navigateToLogin(message);
    }
);
