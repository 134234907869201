import 'url-search-params-polyfill';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';

import {onKeyUpSlash} from 'airborne/homepage2/helpers/autocomplete';
import Auth from 'airborne/login/Auth';
import StreamService from 'airborne/services/Stream';
import settings from 'airborne/settings';
import FeatureFlagModal from 'airborne/store/modules/featureFlags/Modal';
import {requestFeatureFlags} from 'airborne/store/modules/featureFlags/actions';
import {isFeatureFlagsLoaded} from 'airborne/store/modules/featureFlags/selector';
import {getUserData, shouldShowTerms} from 'airborne/store/modules/login/actions/login';
import Maintenance from 'midoffice/components/Maintenance';
import AlertModal from 'midoffice/components/modals/AlertModal';
import ConfirmModal from 'midoffice/components/modals/ConfirmModal';
import SessionModal from 'midoffice/components/modals/SessionModal';
import SentryTest from 'midoffice/components/SentryTest';
import {modules} from 'midoffice/modules';
import {fetchCollection, fetchPartial, showModal} from 'midoffice/data/actions';
import {store} from 'midoffice/store';
import {connectStreamService} from 'airborne/store/modules/stream/actions';
import {setShortcutOptionKeyDown, setShortcutOptionKeyUp} from 'airborne/store/modules/navigation_shortcuts/actions';
import {bindOptionDefaultShortcut} from 'midoffice/components/shortcut/helper';
import {hasAccess} from 'midoffice/helpers/permission';

import ApplicationHeader, {getMenu} from './ApplicationHeader';

const FEATURE_FLAG_URL = '/internal/accounts/flags';

function getDefaultTab(sub) {
    if (sub !== 'admin') {
        return 'bookings';
    }

    let available = getMenu().filter(function(tab) {
        return hasAccess(tab.permission);
    });
    const availableIds = available?.length > 0 ? available.map(availableProducts => availableProducts.id) : [];
    const isFirstBookingRecordsTab = sub === 'admin' && availableIds[0] === 'bookings';
    if (isFirstBookingRecordsTab) {
        return availableIds[1] ? availableIds[1] : 'profile-settings';
    }
    let first = available[0];
    if (first) {
        return first.id;
    }

    return null;
}

class Midoffice extends React.Component {
    static propTypes = {
        sub: PropTypes.oneOf(['aft', 'admin']),
        fetchPartial: PropTypes.func.isRequired,
        fetchCollection: PropTypes.func.isRequired,
        midoffice: PropTypes.bool,
        isFeatureFlagsLoaded: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const {fetchPartial, fetchCollection} = this.props;
        fetchCollection('countryCodes');
        if (settings.DISPLAY_MODE === 'admin') {
            fetchPartial('companies', {siblingsFor: 'root'});
            if (hasAccess('section:branch') || hasAccess('user:agents')) {
                fetchCollection('agencies');
            }
        }
    }

    render() {
        const {LANGUAGES, LANGUAGE_CODE, USER} = settings;
        const {sub, isFeatureFlagsLoaded} = this.props;
        if (!isFeatureFlagsLoaded) {
            return <div>Loading...</div>;
        }
        return (
            <Fragment>
                <Maintenance settings={settings} />
                <ApplicationHeader
                    mainMenu={sub === 'admin'}
                    showLang={sub !== 'admin'}
                    user={USER}
                    selectedLanguage={LANGUAGE_CODE}
                    languages={LANGUAGES}
                />

                <Switch>
                    <Route exact path="/">
                        <Redirect to={`/${getDefaultTab(sub)}/`} />
                    </Route>
                    <Route exact path="/accounts/login/">
                        <Redirect to={`/${getDefaultTab(sub)}/`} />
                    </Route>
                    {modules(sub)}
                </Switch>
                <ConfirmModal />
                <AlertModal />
                <SessionModal />
                <SentryTest />
                <FeatureFlagModal url={FEATURE_FLAG_URL} shortcut={['ctrl+alt+q', 'ctrl+alt+a']} />
            </Fragment>
        );
    }
}

@connect(
    state => ({
        midoffice: state.auth.midoffice,
        isFeatureFlagsLoaded: isFeatureFlagsLoaded(state),
    }),
    {
        fetchPartial,
        fetchCollection,
        setShortcutOptionKeyUp,
        setShortcutOptionKeyDown,
        getUserData,
        showModal,
    }
)
export default class ApplicationRoot extends React.Component {
    static propTypes = {
        sub: PropTypes.oneOf(['aft', 'admin']),
        fetchPartial: PropTypes.func.isRequired,
        fetchCollection: PropTypes.func.isRequired,
        midoffice: PropTypes.bool,
        isFeatureFlagsLoaded: PropTypes.bool.isRequired,
        setShortcutOptionKeyUp: PropTypes.func.isRequired,
        setShortcutOptionKeyDown: PropTypes.func.isRequired,
        getUserData: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
    };
    async componentDidMount() {
        const {setShortcutOptionKeyDown, setShortcutOptionKeyUp} = this.props;
        this.subscribeToUserDataChange();
        await store.dispatch(requestFeatureFlags());
        store.dispatch(connectStreamService());
        document.addEventListener('keyup', onKeyUpSlash, false);
        bindOptionDefaultShortcut(setShortcutOptionKeyDown, setShortcutOptionKeyUp);
    }
    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
        document.removeEventListener('keyup', onKeyUpSlash, false);
    }

    subscribeToUserDataChange() {
        this.unsubscribe = StreamService.subscribe(async event => {
            if (event && event.type === 'IDENTITY_CHANGE') {
                try {
                    await this.props.getUserData(true);
                }
                catch (isPermissionsChanged) {
                    if (isPermissionsChanged) {
                        this.props.showModal('warningWithText', {
                            title: 'Notification',
                            message: 'Your permissions have been updated',
                            onDone: () => {location.reload();}
                        });
                        this.forceUpdate();
                    }
                }
            }
        });
    }

    render() {
        const {USER} = settings;
        const {midoffice, sub, fetchPartial, fetchCollection, isFeatureFlagsLoaded} = this.props;
        const noUser = !Boolean(USER.id);
        const shouldShowReset = location.pathname.includes('/accounts/reset-password/');
        const showTerms = location.pathname.includes('/ui/terms-conditions/');

        return (!midoffice && noUser) || shouldShowTerms() || shouldShowReset || showTerms ? (
            <Auth />
        ) : (
            <Midoffice
                sub={sub}
                midoffice={midoffice}
                isFeatureFlagsLoaded={isFeatureFlagsLoaded}
                fetchPartial={fetchPartial}
                fetchCollection={fetchCollection}
            />
        );
    }
}
