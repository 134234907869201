import some from 'lodash/some';
import chunk from 'lodash/chunk';
import get from 'lodash/get';

import StreamService, {MESSAGE_TYPES} from 'airborne/services/Stream';
import {parse} from 'airborne/types';
import {needAllHotels, selectHotels} from 'airborne/search2/helpers/avail';
import {RETRY} from 'airborne/checkout2/helpers/retry';
import {hotelLabeling, searchLimit} from 'airborne/search2/helpers/hyatt';

import {chunkRequest} from './availRequest';
import {getRatesOnce} from './rates';
import {handleApiError} from './air';
import {getChunksCountById, resetChunksCountById} from 'airborne/helpers/chunksProcessing';

const RETRY_DELAY = 4 * 1000;
const FALLBACK_TIMEOUT = 45 * 1000;


const loadOneChunk = function(ids) {
    return async function loadOneChunkD(dispatch, getState) {
        const labeling = hotelLabeling(getState());
        const oldRateTarget = get(getState(), 'hotels.avail.rateTarget', 0);
        const {
            data,
            searchId,
            rateTarget,
            completed,
            chunksRequested,
            chunksReceived
        } = await chunkRequest(getState(), ids);
        dispatch({
            type: completed ? 'AVAIL_LOADED' : 'AVAIL_LOADED_PARTIAL',
            ids,
            data: parse('avail', data, labeling),
            searchId,
            rateTarget: oldRateTarget || rateTarget,
        });

        const receivedChunks = getChunksCountById(searchId);
        const chunksLeft = chunksRequested - receivedChunks;
        return {
            searchId,
            chunksRequested,
            chunksLeft,
            chunksReceived,
            completed,
            data
        };
    };
};

let completedData = null;
async function waitForChunks(chunksLeft, searchId, dispatch, ids) {

    function loadAfterFirstChunk() {
        dispatch(loadOneChunk(ids));
    }

    let chunks = 0;
    function conditionCheck(event) {
        const {message} = event;

        if (message.searchId === searchId) {
            chunks++;
        }
        if (chunks === 1 && chunksLeft > 1) {
            loadAfterFirstChunk();
        }

        if (chunks === chunksLeft) {
            resetChunksCountById(searchId);
            return true;
        }
    }

    function pollingCheck(event) {
        const {chunksRequested, chunksReceived} = event;
        if (chunksRequested === chunksReceived) {
            return true;
        }
    }


    try {
        await StreamService.subscribeOnEventOrPolling({
            eventType: MESSAGE_TYPES.HOTELS_RATES_CHUNK,
            eventProcessor: event => {
                if (event?.polling) {
                    return pollingCheck(event);
                }
                return conditionCheck(event);
            },
            eventDeadline: FALLBACK_TIMEOUT,
            pollingInterval: RETRY_DELAY,
            pollingExecutor: async eventProcessor => {
                const {chunksRequested, chunksReceived, completed} = await dispatch(loadOneChunk(ids));
                if (completed) {
                    completedData = true;
                }
                return eventProcessor({
                    chunksRequested,
                    chunksReceived,
                    polling: true
                });
            }
        });
    }
    catch (error) {
        throw error;
    }
}


const loadChunk = function loadChunk(ids) {
    return async function loadChunkD(dispatch) {
        completedData = null;
        const {searchId, chunksRequested, completed: completedDataForSearch, data: searchData} = await dispatch(loadOneChunk(ids));
        if (completedDataForSearch) return searchData;

        const receivedChunks = getChunksCountById(searchId);
        const chunksLeft = chunksRequested - receivedChunks;
        if (chunksLeft === 0) {
            await dispatch(loadOneChunk(ids));
            return;
        }
        await waitForChunks(chunksLeft, searchId, dispatch ,ids);
        if (completedData) {
            return;
        }
        const {data} = await dispatch(loadOneChunk(ids));
        return data;
    };
};

function hasRates({hotels: {avail}}) {
    return some(Object.values(avail.data).map(({'is_available': avail})=> avail));
}

function isOffline(response) {
    return response && response.status === 0;
}

export function loadAvail(ids) {
    return async function loadAvailD(dispatch, getState) {
        const CHUNK_SIZE = searchLimit(getState());
        const chunks = chunk(ids, CHUNK_SIZE);
        try {
            return await Promise.all(chunks.map(async (ids)=> {
                dispatch({type: 'AVAIL_LOADING', ids});
                try {
                    return await dispatch(loadChunk(ids));
                }
                catch (response) {
                    try {
                        handleApiError(response);
                        dispatch({type: 'AVAIL_ABORT', ids});
                    }
                    catch (error) {
                        dispatch((response === RETRY)
                            ? {type: 'AVAIL_TIMEOUT', ids}
                            : {type: 'AVAIL_FAIL', ids});
                    }

                    throw response;
                }
            }));
        }
        catch (error) {
            if (!hasRates(getState()) || isOffline(error)) {
                throw error;
            }
        }
    };
}

export function loadSingleAvail(id) {
    return getRatesOnce(id);
}

export default function getAvail() {
    return function getAvailD(dispatch, getState) {
        const {dest} = getState();
        const {hotels: {hotels, order, pagination, avail}} = getState();
        const {options: {filters}={}, view} = dest;
        const {preference} = hotels.data[0] || {};

        const needAll = needAllHotels(order, filters, preference, view);

        const ids = selectHotels(
            hotels, avail,
            filters, order, pagination,
            needAll
        );

        return dispatch(loadAvail(ids));
    };
}
