import React, {Component} from 'react';
import {SeatsAttrShape} from 'airborne/search_air/shapes';
import PT from 'prop-types';
import gettext from 'airborne/gettext';
import capitalize from 'lodash/capitalize';

export default class Seats extends Component {
    static propTypes = {
        seats: SeatsAttrShape,
        className: PT.string,
    }

    renderText(text, cls) {
        const {className} = this.props;
        return (<div className={className} data-testid="air-seats-ticket-attribute">
            {gettext('Seats: ')}
            <span className={cls}>
                {text}
            </span>
        </div>);
    }

    renderSeatInformation(seats) {
        const seatsLines = seats.seatSelection
            .map(seat => this.renderSeatInfoItem(seat)).join('; ')

        return this.renderText(seatsLines)
    }

    renderSeatInfoItem({description, assessment}) {
        return `${capitalize(assessment)} - ${description}`;
    }

    renderUnknown() {
        return this.renderText(gettext('Unknown'), "highlight-gray");
    }

    render() {
        const {seats} = this.props;

        return seats?.seatSelection
            ? this.renderSeatInformation(seats)
            : this.renderUnknown();
    }
}
