import moment from 'moment';
import omit from 'lodash/omit';
import {LANGUAGES} from 'midoffice/helpers/languages';


const collectionUrl = ()=> '/api/aft_messages/';
const entryUrl = (endpoint, id)=> `${collectionUrl()}${id}/`;

export const fetchCollection = collectionUrl;
export const addEntry = collectionUrl;
export const changeEntry = entryUrl;
export const removeEntry = entryUrl;


export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const SYSTEMS = ['aft', 'admin'];


function parseText(text) {
    const texts = LANGUAGES.reduce((acc, [id])=> ({
        ...acc, [`text-${id}`]: text[id],
    }), {});
    const definedTexts = Object.values(texts).filter((t)=> t);
    const onlyDefaultLang = definedTexts.length <= 1;

    return {...texts, onlyDefaultLang};
}

export function parse({'from_ts': from, 'to_ts': to, 'created_ts': created, systems, text, ...data}) {
    to = moment(to).utc();
    from = moment(from).utc();
    const now = moment();

    let status = 0; // past
    if (to.isAfter(now)) {
        status = 1; // active
    }
    if (from.isAfter(now)) {
        status = 2; // future
    }

    // Preference order - sort by status and then by created date
    const preference = `${status} ${created}`;
    return {
        ...data,
        systems: Object.keys(systems).filter((key)=> systems[key]),
        from: from.format(DATE_FORMAT),
        to: to.format(DATE_FORMAT),
        initialFrom: from.format(DATE_FORMAT),
        status,
        preference,
        ...parseText(text),
    };
}


function serializeText({onlyDefaultLang, ...data}) {
    const rest = omit(data, LANGUAGES.map(([id])=> `text-${id}`));

    const text = LANGUAGES.reduce((acc, [id])=> {
        let langText = data[`text-${id}`];
        if (onlyDefaultLang && id !== 'en') { langText = ''; }

        return langText ? {...acc, [id]: langText} : acc;
    }, {});

    return {text, ...rest};
}

export function serialize({from, to, systems, ...data}) {
    return {
        systems: SYSTEMS.reduce((acc, key)=> ({
            ...acc, [key]: systems.includes(key),
        }), {}),
        'from_ts': moment.utc(from).toISOString(),
        'to_ts': moment.utc(to).toISOString(),
        ...serializeText(omit(data, 'status')),
    };
}
