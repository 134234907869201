import isObject from 'lodash/isObject';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from 'react-redux';

import {retrieve, cancel} from 'midoffice/data/actions/pnrTool';
import PageTitle from 'midoffice/components/PageTitle';

//TODO: GG-21407 Improve PNRView component to avoid relation from router
export class PNRView extends React.Component {
    static propTypes = {
        plain: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errors: PropTypes.object,
        match: PropTypes.shape({
            params: PropTypes.shape({
                entityId: PropTypes.string.isRequired,
                pnr: PropTypes.string.isRequired,
                system: PropTypes.string.isRequired,
            }),
        }),
        pnr: PropTypes.object,
        onCancel: PropTypes.func.isRequired,
        onRetrieve: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {onRetrieve, match: {params: {pnr, entityId, system}}} = this.props;
        onRetrieve(pnr, entityId, system);
    }

    handleCancel = ()=> {
        const {onCancel, match: {params: {pnr, entityId, system}}} = this.props;
        onCancel(pnr, entityId, system);
    };

    renderHeader() {
        const {
            pnr: {canceled}={},
            match: {params: {pnr}},
            loading,
            error
        } = this.props;

        return (
            <header className="content-header">
                <div className="pull-left">
                    <h5 className="no-margin">PNR:</h5>
                    <h3 className="content-header__title"> {pnr} </h3>
                </div>
                <div className="pull-right">
                    {canceled ? (
                        <h3 className="text-danger">
                            <strong>Canceled</strong>
                        </h3>
                    ) : (
                        <button className="btn btn-danger btn-lg"
                            onClick={this.handleCancel}
                            disabled={error || loading}
                        >
                            Cancel PNR&hellip;
                        </button>
                    )}
                </div>
                <PageTitle>Tools</PageTitle>
            </header>
        );
    }

    renderErrors() {
        let {errors} = this.props;

        if (isObject(errors)) {
            errors = Object.values(errors);
        }
        else {
            errors = [errors];
        }

        return (
            <div className="alert alert-danger">
                {errors.map((error)=> (
                    <Fragment key={error}>
                        {error}
                        <br/>
                    </Fragment>
                ))}
            </div>
        );
    }

    renderResult() {
        const {
            pnr: {text}={},
            loading,
            error,
        } = this.props;

        let body;
        if (loading) {
            body = (<pre> Loading &hellip; <br/> </pre>);
        }
        else if (error) {
            body = this.renderErrors();
        }
        else {
            body = (<pre>{text}</pre>);
        }

        return (
            <div className="result-block" data-testid="pnr-view-result">
                {body}
            </div>
        );
    }

    render() {
        const {plain} = this.props;
        const cls = classnames({
            'container-full': !plain,
            'container-full--top-fix': !plain,
        });
        return (
            <div className={cls}>
                {!plain && this.renderHeader()}
                {this.renderResult()}
            </div>
        );
    }
}

export default connect(function({'pnr-tool': {hash, loading, error, errors}}, {match: {params: {pnr}}}) {
    return {pnr: hash[pnr], pnrNumber: pnr, loading, error, errors};
}, {onCancel: cancel, onRetrieve: retrieve})(PNRView);
