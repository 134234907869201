/* global global */

const systemData = global.SYSTEM_DATA = global.SYSTEM_DATA || {
    common: {}
};   //eslint-disable-line immutable/no-mutation

systemData.set = function(obj) {
    Object.assign(systemData, obj);
};

export default systemData;
