import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';

import Tags from 'midoffice/newforms/widgets/Tags';

import {validateCarTypeFormat} from 'airborne/homepage2/CarsDestinationSearchSchema';
import {getExplanationText} from 'airborne/homepage2/helpers/acriss';


function getValidCodes(codes) {
    if (!codes || !codes.length) { return []; }
    return codes.filter((code)=> !validateCarTypeFormat([code]));
}

export default class AcrissField extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.string),
        disabled: PropTypes.bool,
    };

    static contextTypes = {
        form: PropTypes.shape({
            value: PropTypes.object.isRequired,
        }).isRequired,
    };

    renderExplanation() {
        const {name} = this.props;

        const {form: {value: formValue}} = this.context;
        const codes = formValue[name];

        const validCodes = getValidCodes(codes);

        return (
            <div className="form-group__active-field">
                {!validCodes.length && (
                    <em className="form-group__active-field__title">
                        {gettext('Enter up to 5 ACRISS/SIPP or Pseudo codes.')}
                    </em>
                )}
                {validCodes.map((code, index)=> (
                    <div className="form-group__active-field__item" key={index}>
                        <div className="form-group__active-field__label">
                            #{index+1}: <strong>{code}</strong>
                        </div>
                        <div className="form-group__active-field__value">
                            {getExplanationText(code)}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div>
                <Tags.Field {...this.props} freeText />
                {this.renderExplanation()}
            </div>
        );
    }
}
