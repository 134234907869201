import {combineReducers} from 'redux';
import * as types from '../actionTypes';

const defaultData = null;
const data = (state = defaultData, {type, data}) => {
    switch (type) {
        case types.AIR_AVAILABILITY_FLIGHT_OPTIONS_LOADED:
            return data;
        case types.AIR_AVAILABILITY_START_SEARCH:
        case types.AIR_AVAILABILITY_FLIGHT_OPTIONS_ERROR:
            return defaultData;
        default:
            return state;
    }
};

const loading = (state = false, {type}) => {
    switch (type) {
        case types.AIR_AVAILABILITY_FLIGHT_OPTIONS_LOADING:
            return true;
        case types.AIR_AVAILABILITY_FLIGHT_OPTIONS_LOADED:
        case types.AIR_AVAILABILITY_FLIGHT_OPTIONS_ERROR:
            return false;
        default:
            return state;
    }
};

const flightOptions = combineReducers({
    data,
    loading,
});

export default flightOptions;
