import {
    COUNTER_OFFER_REQUEST,
    COUNTER_OFFER_REQUEST_SUCCESS,
    COUNTER_OFFER_REQUEST_FAILUIRE,
    COUNTER_OFFER_CLEANUP_STATE,
    COUNTER_OFFER_BACKUP_BOOKING,
} from '../actionTypes';

const initialState = {
    loading: false,
    error: null,
    requestType: null,
    requestSuccess: false,
};

export default function actionableCounteroffer(state = initialState, {type, error, requestType, booking}) {
    if (type === COUNTER_OFFER_CLEANUP_STATE) {
        return initialState;
    }

    if (type === COUNTER_OFFER_REQUEST) {
        return {...state, error: null, loading: true, requestType};
    }

    if (type === COUNTER_OFFER_REQUEST_SUCCESS) {
        return {...state, error: null, loading: false, requestSuccess: true};
    }

    if (type === COUNTER_OFFER_REQUEST_FAILUIRE) {
        return {...state, loading: false, error};
    }

    if (type === COUNTER_OFFER_BACKUP_BOOKING) {
        return {...state, backupBooking: booking};
    }

    return state;
}
