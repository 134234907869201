import gettext from 'airborne/gettext';

import React from 'react';
import PropTypes from 'prop-types';

import Field from 'midoffice/newforms/components/Field';
import Select from 'midoffice/newforms/widgets/Select';

export const PICKUP_DISTANCE = 'pickup.distance.value';

const SORT_CHOICES =  [
    ['-preference', gettext('Preferred')],
    [PICKUP_DISTANCE, gettext('Distance')],
    ['car.type', gettext('ACRISS/SIPP Code - Ascending')],
    ['-car.type', gettext('ACRISS/SIPP Code - Descending')],
    ['total', gettext('Price – Low to High')],
    ['-total', gettext('Price – High to Low')],
];

export default class CarsSorters extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {onChange, value} = this.props;
        return (<div className="section-wrapper__single-control">
            <Field
                widget={Select}
                label={gettext('Sort By')}
                choices={SORT_CHOICES}
                value={value}
                onChange={onChange}
            />
        </div>);
    }
}
