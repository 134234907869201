import moment from 'moment';
import isPlainObject from 'lodash/isPlainObject';
import {getDefaultLanguage} from 'midoffice/helpers/languages';

function normalizeValue(obj) {
    if (obj.key === 'checkin' || obj.key === 'booking_date') {
        const {min, max} = obj.value;
        return {
            min: moment(min),
            max: moment(max),
        };
    }

    if (obj.key === 'hotel_chain') {
        const provider = obj.value.length ? obj.value[0].split(':')[0] : null;
        return {
            provider,
            codes: obj.value.map(code => code.split(':')[1]),
        };
    }

    return obj.value;
}

function normalize(obj) {
    return {
        variable: obj.key,
        relation: obj.relation,
        value: normalizeValue(obj),
        ...(obj.rates && {rates: obj.rates.map(normalize)}),
    };
}

function normalizeQualifiers(obj) {
    return Object.entries(obj).reduce((acc, curr) => {
        return [
            ...acc,
            ...curr[1].map(qualifier => ({
                source: curr[0],
                ...normalize(qualifier),
            })),
        ];
    }, []);
}

function normalizeOptimizationValue(key, value) {
    if (key.endsWith('deposit_rates')) {
        return value === -100 ? 'not_recommend' : 'recommend';
    }

    if (key.endsWith('rate_codes')) {
        return Object.entries(value).map(([name, val]) => ({name, value: val}));
    }

    return value;
}

function normalizeOptimizations(obj, prefix) {
    const baseName = (prefix || 'rate_optimization') + ':';

    return Object.entries(obj).reduce((acc, [key, value]) => {
        const newKey = baseName + key;

        if (isPlainObject(value) && key !== 'rate_codes') {
            return {
                ...acc,
                ...normalizeOptimizations(value, newKey),
            };
        }

        return {
            ...acc,
            [newKey]: normalizeOptimizationValue(newKey, value),
        };
    }, {});
}

function normalizeExclusions(obj) {
    return {
        results: obj.results.map(normalize),
        rates: obj.rates.map(normalize),
    };
}

export function normalizeModifyRates(obj) {
    return {
        results: obj.results.map(normalize),
        description: Object.entries(obj.fields[0].format).map(desc => ({language: desc[0], template: desc[1]}))
    };
}

function normalizeActions(obj) {
    const isOptimize = obj.optimization;
    const isExclusion = obj.exclusions && obj.exclusions.length;
    const isModifyRate = obj.modify_rate && obj.modify_rate.length;

    return [
        ...(isOptimize ? [{type: 'optimize', data: normalizeOptimizations(obj.optimization)}] : []),
        ...(isExclusion ? obj.exclusions.map(item => ({type: 'exclude', data: normalizeExclusions(item)})) : []),
        ...(obj.modify_hotel
            ? [{type: 'modify_hotel', data: obj.modify_hotel}]
            : []),
        ...(isModifyRate ? obj.modify_rate.map(item => ({type: 'modify_rate', data: normalizeModifyRates(item)})) : []),
    ];
}

export function parse(obj) {
    const rules = obj['agency:search_engine_configuration:rules'];
    const defaultLanguage = getDefaultLanguage(obj, 'agency:regional:language');

    return {
        'agency:search_engine_configuration:rules': rules.map(rule => ({
            name: rule.name,
            enabled: 'enabled' in rule ? rule.enabled : true,
            collapsed: 'collapsed' in rule ? rule.collapsed : false,
            qualifiers: normalizeQualifiers(rule.qualifiers),
            actions: normalizeActions(rule.actions),
        })),
        'agency:regional:language': defaultLanguage,
    };
}

function transformValue(obj) {
    if (obj.variable === 'checkin' || obj.variable === 'booking_date') {
        const {min, max} = obj.value;
        return {
            min: min.format('YYYY-MM-DD'),
            max: max.format('YYYY-MM-DD'),
        };
    }

    if (obj.variable === 'hotel_chain') {
        const {provider, codes} = obj.value;
        return codes.map(code => `${provider}:${code}`);
    }

    return obj.value;
}

function transformQualifier(obj) {
    return {
        key: obj.variable,
        relation: 'relation' in obj ? obj.relation : true,
        value: transformValue(obj),
        ...(!!obj.rates && {rates: obj.rates.map(transformQualifier)}),
    };
}

function transformQualifiers(obj) {
    return obj.reduce((acc, curr) => {
        const key = curr.source;
        return {
            ...acc,
            [key]: [...(acc[key] || []), transformQualifier(curr)],
        };
    }, {});
}

function transformOptimizationValue(key, value) {
    if (key === 'deposit_rates') {
        return value === 'not_recommend' ? -100 : 0;
    }

    if (key === 'rate_codes') {
        return value.reduce((acc, {name, value}) => ({...acc, [name]: value}), {});
    }

    return value;
}

function transformOptimizations(obj) {
    const metricWeights = Object.entries(obj)
        .filter(([key]) => key.startsWith('rate_optimization:metric_weights'))
        .map(([key, value]) => [key.replace('rate_optimization:metric_weights:', ''), value])
        .reduce((acc, [key, value]) => {
            if (key.includes(':')) {
                const [parent, child] = key.split(':');

                if (parent === 'rate_codes') {
                    return acc;
                }

                return {
                    ...acc,
                    [parent]: {
                        ...(acc[parent] || {}),
                        [child]: transformOptimizationValue(child, value),
                    },
                };
            }

            return {
                ...acc,
                [key]: transformOptimizationValue(key, value),
            };
        }, {});

    return {
        metric_weights: metricWeights,
        rate_codes_to_exclude: obj['rate_optimization:rate_codes_to_exclude'],
    };
}

function transformExclusions(arr) {
    return arr.map(({data: {results, rates}}) => ({
        results: results.map(transformQualifier),
        rates: rates.map(transformQualifier),
    }));
}

export function transformModifyRates(arr) {
    return arr.map(({data: {results, description}}) => ({
        results: results.map(transformQualifier),
        fields: [
            {
                key: 'rate_description',
                format: description.reduce((description, descriptionToMap) => ({...description, [descriptionToMap.language]: descriptionToMap.template}), {})
            },
        ],
    }));
}

function transformActions(obj) {
    const optimization = obj.find(item => item.type === 'optimize');
    const exclusions = obj.filter(item => item.type === 'exclude');
    const hotels = obj.filter(item => item.type === 'modify_hotel');
    const descriptions = obj.filter(item => item.type === 'modify_rate');


    return {
        ...(!!optimization && {
            optimization: transformOptimizations(optimization.data),
        }),
        ...(!!exclusions.length && {exclusions: transformExclusions(exclusions)}),
        ...(!!hotels.length
            && {modify_hotel: {append: hotels[0].data.append, prepend: hotels[0].data.prepend}}),
        ...(!!descriptions.length && {
            modify_rate: transformModifyRates(descriptions),
        }),
    };
}

export function serialize(obj) {
    const defaultLanguage = getDefaultLanguage(obj, 'agency:regional:language');
    return {
        'agency:search_engine_configuration:rules': obj['agency:search_engine_configuration:rules'].map(rule => ({
            name: rule.name,
            enabled: rule.enabled,
            collapsed: rule.collapsed,
            qualifiers: transformQualifiers(rule.qualifiers),
            actions: transformActions(rule.actions),
        })),
        'agency:regional:language': defaultLanguage,
    };
}
