import {LOADED, NET_FAIL, SCHEDULE} from 'midoffice/data/actions/types';

const DEFAULT_INTERVAL = 60 * 1000;

function initial() {
    return {
        timer: null,
        ends: false,
        interval: DEFAULT_INTERVAL,
    };
}

function moreTime({interval, ...state}) {
    return {
        ...state,
        interval: interval + DEFAULT_INTERVAL,
    };
}

export default function session (state, {type, ends, timer}) {
    if (!state) {
        return initial();
    }

    if (type === LOADED) {
        return {...state, interval: DEFAULT_INTERVAL, ends};
    }

    if (type === NET_FAIL) {
        return moreTime(state);
    }

    if (type === SCHEDULE) {
        return {...state, timer};
    }

    return state;
}
