import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {hasAccess} from 'midoffice/helpers/permission';

export function hasPermissionToSection(section) {
    const {permission} = section;
    return hasAccess(permission);
}

export const checkSectionFeatureFlag = (item) => {
    if (item.featureFlag) {
        return getFeatureFlag(null, item.featureFlag);
    }
    return true;
};

export function filterSections(sections) {
    return sections.map((section)=> ({
        ...section,
        items: section.items.filter(
            (item)=> item.section && hasPermissionToSection(item) && checkSectionFeatureFlag(item)
        )
    })).filter((section)=> section.items.length !== 0);

}
