import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Header from './Header';

import {
    isFareFamilyGroupSelected,
    makeGetFareFamilyPriceDifference,
} from 'airborne/store/modules/search_air/selectors';
import {selectFareFamilyGroup} from 'airborne/store/modules/search_air/actions/fareFamilies';

import {FareFamilyGroupShape} from './shapes';
import TicketAttributes from 'airborne/search_air/FareGroups/TicketAttributes';
import {getPolicyRules} from 'airborne/store/modules/search_air/selectors/policyRules';
import {extractEmissionsFromFare} from 'airborne/search_air/helpers';
import Emissions from "airborne/checkout_air/BrandedFares/Emissions";

export class Fare extends Component {

    static propTypes = {

        // From parent
        fare: FareFamilyGroupShape.isRequired,
        hideCO2Separator: PropTypes.bool,
        hideHeaderCheckbox: PropTypes.bool,
        isOriginalFare: PropTypes.bool,

        // From store state
        isSelected: PropTypes.bool,
        priceDifference: PropTypes.number,
        policyRules: PropTypes.array,

        // From store dispatch
        selectFareFamilyGroup: PropTypes.func,
    };

    handleSelect = () => {
        const {
            fare: {fareGroupKey},
            isSelected,
            selectFareFamilyGroup,
        } = this.props;

        if (!isSelected) {
            selectFareFamilyGroup(fareGroupKey);
        }
    }

    render() {
        const {
            isSelected,fare, priceDifference, policyRules, lang,
            index, hideCO2Separator, hideHeaderCheckbox, isOriginalFare
        } = this.props;

        const {ticketAttributes, unbookableRules} = fare;
        const emissions = extractEmissionsFromFare(fare);
        const isUnbookable = !isEmpty(unbookableRules);
        const rootClasses = classnames(
            'selector-grid__item',
            {
                'selector-grid__item--selected': isSelected,
                'selector-grid__item--disabled': isUnbookable,
            }
        );

        return (
            <div
                className={rootClasses}
                onClick={this.handleSelect}
                data-testid="branded-fare"
                data-branded-fare-id={index}
            >
                <Header
                    fare={fare}
                    priceDifference={priceDifference}
                    policyRules={policyRules}
                    lang={lang}
                    hideHeaderCheckbox={hideHeaderCheckbox}
                    originalFare={isOriginalFare} />
                <div className="selector-grid__content selector-grid__content--list">
                    <TicketAttributes showSeats ticketAttributes={ticketAttributes} />
                    {emissions > 0 &&
                        <>
                            {!hideCO2Separator && <hr className="hr-condensed" />}
                            <Emissions emissions={emissions} />
                        </>}
                </div>
            </div>
        );
    }
}

export default connect((state, {fare: {fareGroupKey}}) => {
    const getFareFamilyPriceDifference = makeGetFareFamilyPriceDifference(fareGroupKey);

    return (state, {fare: {fareGroupKey}}) => ({
        isSelected: isFareFamilyGroupSelected(state, fareGroupKey),
        priceDifference: getFareFamilyPriceDifference(state),
        policyRules: getPolicyRules(state),
    });
}, (dispatch) => ({
    selectFareFamilyGroup: (fareFamilyKey) => dispatch(selectFareFamilyGroup(fareFamilyKey)),
})
)(Fare)
