
export function getStepLogin(state) {
    return state['auth'].stepLogin;
}
export function getSource(state) {
    return state['auth'].source;
}
export function getTimeout(state) {
    return state['auth'].timeout;
}
export function getTermsModalState(state) {
    return state['auth'].isShowTermsModal;
}

export function getAuthLoader(state) {
    return state.resetPassword.loading || (state.i18n && state.i18n.loading);
}
