import omit from 'lodash/omit';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {
    injectParamsToDestinations,
    serializeDestination,
} from './destinations';

export function extractCurrency(currency) {
    return currency === '___' ? null : currency;
}

export function getDestinationsFromValue(formValue) {
    const destinations = injectParamsToDestinations(formValue);

    return destinations.map((destination, index) =>
        omit(serializeDestination(destination, index, formValue), ['airline_codes', 'airline_alliances'])
    );
}

export function serializeFTNumbers(numbers) {
    return numbers
        .filter(({vendor, number}) => vendor && number)
        .map(ff => ({
            'airline_code': ff.vendor,
            'ff_number': ff.number,
        }));
}

export function createAirRequestParams(value, profile, currency, exchangeKey, selectedConfigurationId) {
    return dropEmpty({
        'configuration_id': selectedConfigurationId || profile.company,
        currency,
        travelers: [
            {
                'traveler_type': 'adult',// hardcode untill we have option on UI
                'ff_numbers': serializeFTNumbers(value.ftNumbers),
            }
        ],
        'origin_destinations': getDestinationsFromValue(value),
        'included_baggage': value.allowedBaggage,
        'refundable_only': value.refundableOnly,
        'changeable_only': value.changeableOnly,
        'exclude_penalties': value.excludePenalties,
        'airline_codes': value.airlineCodes,
        'airline_alliances': value.airlineAlliances,
        'exchange_key': exchangeKey,
        'retrieve_amenities': true,
    });
}
