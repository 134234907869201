'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

const FLAG_NAME = 'QA_PROVIDER_TEST_ENVS';

@connect(state => ({
    isProviderTestEnvs: getFeatureFlag(state, FLAG_NAME),
}))
export default class ProviderTestEnvsWarning extends React.Component {
    static propTypes = {
        isProviderTestEnvs: PropTypes.bool,
    }

    render() {
        const {isProviderTestEnvs} = this.props;

        if (!isProviderTestEnvs) {
            return null;
        }

        return (
            <div className="header-message">
                <div className="container">
                    <h3 className="header-message__title">
                        {gettext('Feature flag {flagName} is on', {flagName: FLAG_NAME})}
                    </h3>
                    {gettext('Bookings for LHG/FLX NDC, BA NDC, Sabre, Amadeus providers are not going to be real.')}
                </div>
            </div>
        );
    }
}
