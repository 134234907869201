const SETTINGS_BY_TREE = {
    companies: 'air:exclusions',
    agencies: 'agency:air:exclusions',
};

export function parse(raw, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];

    return raw[setting];
}

export function serialize(data, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    return {
        [setting]: {
            ...data,
            'carrier_codes': data['carrier_codes'] || [],
        }
    };
}
