import {dropEmptyDeep} from 'airborne/helpers/emptyDeep';
import {isOneWayMode} from 'airborne/homepage2/AirDestinationSearch/helpers';
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';

export const locationCodes = {
    'a': 'airport',
    'c': 'city',
};

export function swapLocations(destination) {
    const {pickUp, dropOff} = destination;

    return {
        ...destination,
        dropOff: pickUp,
        pickUp: dropOff,
    };
}

function serializeTimeRestriction(time) {
    return time?.time ? {
        'time': time.time,
        'time_type': 'departure',
        'time_window': time.timeWindow
    } : null;
}

function serializeConnections(connection) {
    return [connection?.iataCode];
}

function injectDefaultDestinationParams(destination, roundTrip) {
    const {
        dateRange: {min},
        timeRestrictionOutbound,
        departureCabin,
        noCabinPreference,
        preserveInboundDest,
    } = destination;

    return {
        ...destination,
        departureDate: min,
        timeRestriction: timeRestrictionOutbound,
        cabin: noCabinPreference ? null : departureCabin,
        ...(roundTrip && preserveInboundDest ? {preserve: preserveInboundDest} : {})
    };
}

function injectRoundTripDestinationParams(destination) {
    const {
        dateRange: {max},
        timeRestrictionInbound,
        departureCabin,
        arrivalCabin,
        noCabinPreference,
        preserveOutboundDest
    } = destination;

    return {
        ...swapLocations(destination),
        departureDate: max,
        timeRestriction: timeRestrictionInbound,
        cabin: noCabinPreference
            ? null
            : arrivalCabin === 'same' ? departureCabin : arrivalCabin,
        ...(preserveOutboundDest ? {preserve: preserveOutboundDest} : {})
    };
}

function injectDestinationParamsForOneWay(originDestinations) {
    return originDestinations.map(injectDefaultDestinationParams);
}

function injectDestinationParamsForRoundTrip(originDestinations) {
    const [destination] = originDestinations;

    return [
        injectDefaultDestinationParams(destination, true),
        injectRoundTripDestinationParams(destination),
    ];
}

export function injectParamsToPartiallyFlownDestinations(formValue) {
    const {originDestinations} = formValue;
    return originDestinations.map(injectDefaultDestinationParams).slice(1);
}

export function injectParamsToDestinations(formValue) {
    const {originDestinations, tripType} = formValue;

    return isOneWayMode(tripType)
        ? injectDestinationParamsForOneWay(originDestinations)
        : injectDestinationParamsForRoundTrip(originDestinations);
}

export function serializeDestination(destination, index, formValue) {
    const {
        pickUp,
        dropOff,
        departureDate,
        timeRestriction,
        cabin,
        preserve,
    } = destination;
    const {directFlights, airlineCodes, connections, airlineAlliances} = formValue;

    return dropEmptyDeep({
        'departure_location': {
            'location_code': pickUp.iataCode,
            'location_type': locationCodes[pickUp.type],
        },
        'arrival_location': {
            'location_code': dropOff.iataCode,
            'location_type': locationCodes[dropOff.type],
        },
        'direct_only': directFlights,
        'departure_date': departureDate,
        'airline_codes': airlineCodes,
        'airline_alliances': airlineAlliances,
        'time_restriction': serializeTimeRestriction(timeRestriction),
        'connecting_locations_codes': serializeConnections(connections?.[index]),
        'cabin': cabin,
        'preserve': preserve,
    });
}

export const getSegmentDatetime = ({datetime, date, time}) => {
    return datetime || ((date && time) ? (date + 'T' + time) : 'no-date');
};

export const getSegmentKey = ({departure, arrival}) => [
    departure.airport.code,
    getSegmentDatetime(departure),
    arrival.airport.code,
    getSegmentDatetime(arrival),
].join('-');

export const extractEmissionsFromFare = (fare) => {
    return fare.originDestinations.reduce((acc, {segments}) => {
        return acc + extractEmissionsFromSegments(segments)
    }, 0);
};

export const extractEmissionsFromSegments = (segments) => {
    if (!segments.every(({emissions}) => Boolean(emissions))) {
        return 0;
    }
    return segments.reduce((acc, {emissions}) => emissions ? acc + emissions : acc, 0);
};

export const getSegments = (originDestinations) => {
    return flatMap(originDestinations, ({segments}) => segments) || [];
}

export const getCarriers = (originDestinations) => {
    const segments = getSegments(originDestinations);

    return uniqBy(
        filter(
            flatMap(
                segments.map(({carrier, operatingCarrier}) => ([carrier, operatingCarrier]))
            ),
            carrier => !isNull(carrier) && carrier.code !== '**'
        ),
        'code'
    );
}

export const getRouteKey = ({segments}) => {
    return segments.map(getSegmentKey).join('|');
};
