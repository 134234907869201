import {combineReducers} from 'redux';
import pick from 'lodash/pick';
import endpoint from 'midoffice/helpers/endpoint';

import multiPnr, {multi} from 'airborne/store/modules/homepage/reducers/multiPnr';
import homepage from 'airborne/store/modules/homepage/reducers/homepage';
import dest from 'airborne/store/modules/homepage/reducers/destination';
import tspm, {REQUIRED_INDEX_ACTIONS} from 'airborne/store/modules/homepage/reducers/tspm';

import alert from 'midoffice/data/store/alert';
import pnrTool from 'midoffice/data/store/pnrTool';
import i18n from 'airborne/store/modules/header/reducers/i18n';
import inbox from 'airborne/store/modules/header/reducers/inbox';
import hotels from 'airborne/store/modules/search_hotels/reducers/hotels';
import cars from 'airborne/store/modules/search_cars/reducers/cars';
import air from 'airborne/store/modules/search_air/reducers';
import airBooking from 'airborne/store/modules/checkout_air/reducers/airBooking';
import airBookingDetails from 'airborne/store/modules/confirm_air/reducers/airBookingDetails';
import carBooking from 'airborne/store/modules/checkout_cars/reducers/carBooking';
import carBookingDetails from 'airborne/store/modules/confirm_cars/reducers/carBookingDetails';
import carCancelAtModifyFlow from 'airborne/store/modules/confirm_cars/reducers/carCancelAtModifyFlow';
import multiBooking from 'airborne/store/modules/checkout_hotels/reducers/multiBooking';
import bookingDetails from 'airborne/store/modules/confirm_hotels/reducers/bookingDetails';
import session from 'midoffice/data/store/session';
import userSettings from 'airborne/store/modules/user_settings/reducers/userSettings';
import termsConditions from 'airborne/termsConditions/reducers/terms';
import hash from 'midoffice/data/store/hash';
import form from 'airborne/bookings2/reducers/form';
import auth from 'airborne/store/modules/login/reducers/auth';
import emailOffers from 'airborne/email_offers/reducers/emailOffers';
import loader from 'midoffice/data/store/loader';
import tree from 'midoffice/data/store/tree';
import {delegateModals} from 'airborne/store/modules/header/reducers/modal';
import bookingModification from 'midoffice/bookings/modify/reducer';
import confirm from 'midoffice/data/store/confirm';
import {freshBookings} from 'midoffice/store/modules/freshBookings';
import externalBooking from 'midoffice/data/store/externalBooking';
import externalExtra from 'midoffice/store/modules/externalExtra/reducer';
import updatePaymentInfo from 'airborne/store/modules/update_payment/reducers';
import maintenances from 'airborne/store/modules/maintenance/reducers';
import confermaFaxes from 'midoffice/bookings/conferma/reducer';
import actionableCounteroffer from 'airborne/store/modules/counteroffer/reducers';
import featureFlags from 'airborne/store/modules/featureFlags/reducer';
import airAvailability from 'airborne/store/modules/availability_air';
import airPricing from 'airborne/store/modules/pricing_air';
import actionableBooking from 'airborne/store/modules/actionable_booking/reducers';
import actionableCounter from 'airborne/store/modules/actionable_booking/reducers/actionableCounter';
import airExchange from 'airborne/store/modules/exchange_air/reducers';
import airNgsData from 'airborne/store/modules/air_ngs_data';
import {bedTypes} from 'midoffice/store/modules/bedTypes';
import navigationShortcuts from 'airborne/store/modules/navigation_shortcuts/reducers';
import reports from 'midoffice/data/store/reports';
import airReports from 'midoffice/data/store/airReports';


const reduceStores = {
    loader,
    auth,
    multiPnr,
    homepage,
    dest,
    tspms: multi(tspm, REQUIRED_INDEX_ACTIONS),
    hotels,
    cars,
    air,
    airAvailability,
    airNgsData,
    airPricing,
    airBooking,
    airBookingDetails,
    airExchange,
    carBooking,
    carBookingDetails,
    carCancelAtModifyFlow,
    multiBooking,
    multiBookingDetails: multi(bookingDetails),
    alert,
    i18n,
    confirm,
    termsConditions,
    actionableCounter,
    modal: delegateModals,
    session: endpoint('session', session),
    'pnr-tool': endpoint('pnr-tool', pnrTool),
    preferences: endpoint('preferences', userSettings),
    changePassword: endpoint('changePassword', userSettings),
    login: endpoint('login', userSettings),
    forgotPassword: endpoint('forgotPassword', userSettings),
    terms: endpoint('terms', userSettings),
    resetPassword: endpoint('resetPassword', userSettings),
    arrangers: endpoint('arrangers', hash),
    bookings: endpoint('bookings', hash),
    bookingFilters: endpoint('bookingFilters', form),
    bookingModification: endpoint('bookingModification', bookingModification),
    carBookingModification: endpoint('carBookingModification', bookingModification),
    airBookingModification: endpoint('airBookingModification', bookingModification),
    emailOffers,
    inbox: endpoint('inbox', inbox),
    carBookingFilters: endpoint('carBookingFilters', form),
    companies: endpoint('companies', tree),
    carBookings: endpoint('carBookings', hash),
    airBookings: endpoint('airBookings', hash),
    airBookingFilters: endpoint('airBookingFilters', form),
    externalBookings: endpoint('externalBookings', hash),
    externalBookingFilters: endpoint('externalBookingFilters', form),
    countryCodes: endpoint('countryCodes', hash),
    externalBooking: endpoint('externalBooking', externalBooking),
    bookCarsForDestination: endpoint('bookCarsForDestination', userSettings),
    externalExtra,
    freshBookings,
    updatePaymentInfo,
    maintenances,
    confermaFaxes: endpoint('confermaFaxes', confermaFaxes),
    actionableCounteroffer,
    featureFlags,
    actionableBooking,
    bedTypes,
    navigationShortcuts,
    reports,
    airReports,
};

const rootReducer = combineReducers(reduceStores);
export default rootReducer;

export function partlyReducers(...keys) {
    return combineReducers(pick(reduceStores, keys));
}
