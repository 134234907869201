/* global IS_PROD */
import {
    ContentState, EditorState,
    SelectionState, CompositeDecorator,
    convertFromHTML
} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {decorator} from './toolbar/LinkButtons';

function contentState(html) {
    if (!html) {
        return ContentState.createFromText('');
    }
    const blocks = convertFromHTML(html);
    return ContentState.createFromBlockArray(
        blocks.contentBlocks,
        blocks.entityMap
    );
}

export function toEditorState(html) {
    return EditorState.createWithContent(
        contentState(html),
        new CompositeDecorator([decorator])
    );
}
export function toHTML(editorState) {
    const state = editorState.getCurrentContent();
    if (!state.hasText()) { return ''; }

    return stateToHTML(state, {defaultBlockTag: 'div'});
}
export function draftifyHTML(html) {
    return toHTML(toEditorState(html));
}


/* eslint-disable no-console,immutable/no-mutation */
export function filterWarnings() {
    if (IS_PROD) { return (()=> {}); }

    const originalError = console.error;
    console.error = function(message, ...args) {
        if (typeof message !== 'string' || message.indexOf('component is `contentEditable`') === -1) {
            originalError.call(console, message, ...args);
        }
    };

    return ()=> { console.error = originalError; };
}
/* eslint-enable no-console,immutable/no-mutation */


export function getMeta(editorState) {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const startBlock = contentState.getBlockForKey(startKey);
    const endBlock = contentState.getBlockForKey(endKey);

    return {
        contentState, selection,
        start: {block: startBlock, offset: startOffset},
        end: {block: endBlock, offset: endOffset}
    };
}


export function next(contentState, {block, offset}) {
    if ((offset + 1) < block.getLength()) {
        return {block, offset: offset + 1};
    }

    block = contentState.getBlockAfter(block.getKey());
    if (!block) { return null; }

    offset = 0;
    return {block, offset};
}


export function prev(contentState, {block, offset}) {
    if (offset > 0) {
        return {block, offset: offset - 1};
    }

    block = contentState.getBlockBefore(block.getKey());
    if (!block) { return null; }

    offset = block.getLength() - 1;
    return {block, offset};
}


export function* iterate(contentState, {block, offset}, backward=false) {
    const step = backward ? prev : next;
    if (!backward) { yield {block, offset}; }

    while (block) {
        let position = step(contentState, {block, offset});
        if (!position) { return null; }

        block = position && position.block;
        offset = position && position.offset;
        yield {block, offset};
    }
}


export function getEntityAtCursor(editorState) {
    let {
        contentState, selection,
        start, end,
    } = getMeta(editorState);

    end = prev(contentState, end);

    const startEntity = start.block.getEntityAt(start.offset);
    if (selection.isCollapsed()) { return startEntity; }

    const endEntity = end.block.getEntityAt(end.offset);

    return startEntity === endEntity ? startEntity : null;
}


export function getEntityBoundaries(editorState, position) {
    const {contentState} = getMeta(editorState);

    const entity = position.block.getEntityAt(position.offset);

    let endPosition = position;
    let startPosition = position;

    for (let pos of iterate(contentState, position)) {
        endPosition = pos;
        if (pos.block.getEntityAt(pos.offset) !== entity) {
            break;
        }
    }

    for (let pos of iterate(contentState, position, true)) {
        if (pos.block.getEntityAt(pos.offset) !== entity) {
            break;
        }
        startPosition = pos;
    }

    return {start: startPosition, end: endPosition};
}


export function getSelectedEntityBoundaries(editorState) {
    const {start} = getMeta(editorState);
    return getEntityBoundaries(editorState, start);
}


export function createSelection(start, end) {
    const selection = SelectionState.createEmpty(start.block.getKey());
    return selection.merge({
        anchorOffset: start.offset,
        focusKey: end.block.getKey(),
        focusOffset: end.offset,
    });
}
