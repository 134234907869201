import wrap from 'midoffice/components/LazyComponent';

function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./index.js');
            resolve(loaded);
        }, 'users');
    });
}

export const UsersRootHandler = wrap(load, 'UsersRootHandler');
