import cookie from 'js-cookie';
import SentryAdapter from 'airborne/sentryAdapter';
import sessionStorage from './sessionStorage';
import {isLargeArrayInCollection} from 'midoffice/helpers/largeItems';


let storage;

try {
    // In Safari (OSX and iOS) private browsing mode, local and session
    // storage quotas are set 0, so setting any value generates an
    // exception. In case of any error cowardly falling back to cookies.
    sessionStorage.setItem('testItem', 'testValue');
    sessionStorage.removeItem('testItem');
    storage = sessionStorage;
}
catch (e) {
    storage = null;
}

if (!storage) {
    storage = {
        setItem(item, value) { cookie.set(item, value); },
        getItem(item) { return cookie.get(item); },
        removeItem(item) { cookie.remove(item); },
        key() { return null;},
        clear() { return null;},
    };
}

function getSize() {
    let ret = 0;
    for (let i=0; i<storage.length;i++) {
        let item = storage.getItem(storage.key(i));
        ret +=  item && item.length || 0;
    }
    return ret;
}

export function set(item, value) {
    try {
        const onlyValues = value?.map?.(items => items[2]);
        const isStorageMaxSizeExceeded = isLargeArrayInCollection(onlyValues, 9, 1);
        if (isStorageMaxSizeExceeded) {
            // Don't throw an error in case of Maximum Storage size exceeded
            // to prevent cloning and sending HUGE store to the Sentry
            // These errors are muted is Sentry anyway
            return storage.removeItem(item);
        }
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    try {
        value = JSON.stringify(value);
    }
    catch (e) {
        return;
    }
    try {
        storage.setItem(item, value);
    }
    catch (e) {

        if (e.name === 'QuotaExceededError') {
            // Handle Maximum Storage Size exceeded error natively,
            // in case lazy size check (see isStorageMaxSizeExceeded) didn't handle some cases
            return storage.removeItem(item);
        }

        const sizeBefore = getSize(storage);
        try {
            storage.clear();
            storage.setItem(item, value);
            SentryAdapter.captureMessage(
                'Recovered from session storage error by clearing data', {
                    extra: {sizeBefore, sizeAfter: getSize(storage), sizeSet: value.length, sessionKeys: storage.length},
                    level: 'warning',
                }
            );
        }
        catch (e) {
            SentryAdapter.captureMessage(
                'Ignored session storage error', {
                    extra: {sizeBefore, sizeAfter: getSize(storage), sizeSet: value.length, sessionKeys: storage.length},
                }
            );
        }
    }
}

export function get(item) {
    try {
        return JSON.parse(storage.getItem(item));
    }
    catch (e) {
        return null;
    }
}

export function remove(item) {
    storage.removeItem(item);
}

export default {set, get, remove};
