import omit from 'lodash/omit';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import keyBy from 'lodash/keyBy';
import {editAtIdx, removeAtIdx} from 'midoffice/helpers/editAtIdx';
import {paginate} from 'airborne/search2/helpers/rates';
import {loadAble, loaded, fail} from 'airborne/search2/helpers/loadable';
import {loadAbleKeys, loadedKeys, setKeys} from 'airborne/search2/helpers/loadableKeys';
import {combineReducers} from 'redux';


function concat(state, data) {
    const ids = state.data.map(hotel=> hotel.id);
    return [...state.data, ...data.filter((hotel=> !ids.includes(hotel.id)))];
}

function detectPriceChange(oldTotal, newTotal) {
    if (!oldTotal || !newTotal) {
        return false;
    }

    return Math.round(oldTotal) < Math.round(newTotal);
}

function findIndexAdd(data, predicate) {
    const idx = findIndex(data, predicate);
    return (idx === -1) ? data.length : idx;
}

function updateRate(state, id, rateKey, data) {
    const rates = state.data[id] || [];
    const idx = findIndexAdd(rates, {'rate_key': rateKey});
    const oldData = rates[idx] || [];

    data = {
        'rate_key': rateKey,
        ...oldData,
        ...data,
        'price_change': detectPriceChange(oldData.total, data.total),
    };

    return {
        ...state,
        data: {
            ...state.data,
            [id]: (data.total || data.detailsLoading)
                ? editAtIdx(rates, idx, data)
                : removeAtIdx(rates, idx),
        }
    };
}

function maybeDetails(rate) {
    return (rate && rate.detailsLoaded) ? rate : null;
}

function mergeRates(state, id, data) {
    const oldData = state.data[id] || [];
    const byRateKey = keyBy(oldData, 'rate_key');
    return data.map((rate)=> (maybeDetails(byRateKey[rate['rate_key']]) || rate));
}

export function paginateRatesState(state, id) {
    return {
        ...state,
        paginated: {
            ...state.paginated,
            [id]: paginate(state.data[id], state.order[id], state.expanded[id], state.filters[id]),
        },
    };
}

function ratesSid(state, {type, id, searchId}) {
    if (!state || type === 'START_SEARCH' || type === 'SET_LANG' || type === 'SET_CURRENCY') {
        return {};
    }

    if (type === 'RATES_LOADED' || type === 'RATES_LOADED_PARTIAL') {
        return {...state, [id]: searchId};
    }

    return state;
}

function setSid(state, ids, searchId) {
    return ids
        ? ids.reduce((ret, key)=> ({
            ...ret,
            [key]: searchId,
        }), state)
        : state;
}

function availSid(state, {type, ids, searchId}) {
    if (!state || type === 'START_SEARCH') {
        return {};
    }

    if (type === 'AVAIL_LOADED' || type === 'AVAIL_LOADED_PARTIAL') {
        return setSid(state, ids, searchId);
    }

    return state;
}

function dropPriceChange({data, ...state}) {
    return {
        ...state,
        data: Object.entries(data)
            .reduce((acc, [hotelId, rates])=> ({
                ...acc,
                [hotelId]: rates.map((rate)=> ({...rate, 'price_change': false}))
            }), {}),
    };
}

const rates = loadAbleKeys(function rates(state, {type, id, rateKey, data, booktype}) {
    if (!state || type === 'START_SEARCH' || type === 'SET_LANG' || type === 'SET_CURRENCY') {
        return {loading: {}, data: {}, order: {}, ratesOrder: {}, expanded: {}, selected: {}, paginated: {}, filters: {}};
    }

    if (type === 'RATES_FILTER') {
        return paginateRatesState({
            ...state,
            filters: {...state.filters, [id]: data},
        }, id);
    }

    if (type === 'RATES_LOADED') {
        return paginateRatesState(
            loadedKeys(state, {[id]: mergeRates(state, id, data)}),
            id
        );
    }

    if (type === 'RATES_LOADED_PARTIAL') {
        return paginateRatesState(setKeys(state, {[id]: mergeRates(state, id, data)}), id);
    }

    if (type === 'CHANGE_RATES_ORDER') {
        return paginateRatesState({
            ...state,
            order: {...state.order, [id]: data},
        }, id);
    }

    if (type === 'RATES_EXPAND') {
        return paginateRatesState({
            ...state,
            expanded: {
                ...state.expanded,
                [id]: data,
            },
        }, id);
    }

    if (type === 'SELECT_RATE') {
        return {...state, selected: {...state.selected, [id]: {rateKey, booktype}}};
    }

    if (type === 'UNSELECT_RATE') {
        return {...state, selected: omit(state.selected, id)};
    }

    if (type === 'RATE_DETAILS_LOADING') {
        return updateRate(
            state, id, rateKey,
            {detailsLoading: true, detailsLoaded: false}
        );
    }

    if (type === 'RATE_DETAILS_LOADED' || type === 'RATE_RECOMMENDATION_LOADED') {
        return updateRate(
            state, id, rateKey,
            {
                ...data,
                detailsLoading: false,
                detailsLoaded: true,
            }
        );
    }
    if (type === 'RATE_DETAILS_FAIL' || type === 'RATE_DETAILS_ABORT') {
        return updateRate(
            {...state, selected: {}},
            id, rateKey,
            {detailsLoading: false, detailsLoaded: false}
        );
    }

    if (type === 'BOOKING_LOADING2') {
        return dropPriceChange(state);
    }

    return state;
}, 'RATES_LOADING', 'RATES_FAIL', 'RATES_ABORT');

function appendWarnings(state, ids, toAppend) {
    return ids.reduce((ret, id)=> ({
        ...ret,
        [id]: (ret[id]
            ?  [...ret[id], toAppend]
            : [toAppend])
    }), state);
}

function setWarnings(state, availHotels) {
    return Object.entries(availHotels).reduce((ret, [id, avail])=> ({
        ...ret,
        [id]: avail.warnings,
    }), state);
}

function rateWarnings(state, {type, id, ids, warnings, data}) {
    if (!state || type === 'START_SEARCH' || type === 'SET_LANG') {
        return {};
    }

    if (type === 'AVAIL_LOADED') {
        return setWarnings(state, data);
    }

    if (type === 'AVAIL_TIMEOUT') {
        return appendWarnings(state, ids, {provider: null, message: null, type: 'ETIMEOUT'});
    }

    if (type === 'RATES_LOADED') {
        return {...state, [id]: warnings};
    }

    return state;
}


const hotels = loadAble(function hotels(state, {type, data, total, errors}) {
    if (!state || type === 'START_SEARCH') {
        return {loading: false, data: [], total: 0, loaded: false, errors: null};
    }

    if (type === 'HOTELS_LOADED') {
        return loaded({...state, total, errors: null, loaded: true}, data);
    }

    if (type === 'HOTELS_LOADED_MORE') {
        return loaded({...state, total, loaded: true}, concat(state, data));
    }
    if (type === 'HOTELS_FAIL_MORE') {
        return fail(state);
    }
    if (type === 'HOTELS_EMPTY') {
        return {...state, loading: false, loaded: true, total: 0, errors, data: []};
    }

    return state;
}, 'HOTELS_LOADING', 'HOTELS_FAIL', 'HOTELS_ABORT');

const hotelDetails = loadAbleKeys(function hotelDetails(state, {type, id, data}) {
    if (!state || type === 'SET_LANG') {
        return {loading: {}, data: {}};
    }

    if (type === 'HOTEL_DETAILS_LOADED') {
        return loadedKeys(state, {[id]: data});
    }

    return state;
}, 'HOTEL_DETAILS_LOADING', 'HOTEL_DETAILS_FAIL', 'HOTEL_DETAILS_ABORT');


function timeoutHotel(data={}, id) {
    return {
        'id': id,
        'is_available': false,
        ...data,
    };
}

function timeoutKeys(state, ids) {
    return {
        ...state,
        data: {
            ...state.data,
            ...(ids.reduce((acc, id)=> ({
                ...acc,
                [id]: timeoutHotel(state.data[id], id),
            }), {})),
        },
        loading: omit(state.loading, ...ids),
    };
}

const avail = loadAbleKeys(function avail(state, {type, ids, data, rateTarget}) {
    if (!state || type === 'START_SEARCH') {
        return {loading: {}, data: {}};
    }

    if (type === 'AVAIL_TIMEOUT') {
        return timeoutKeys(state, ids);
    }

    if (type === 'AVAIL_LOADED') {
        return loadedKeys(state, data, rateTarget);
    }

    if (type === 'AVAIL_LOADED_PARTIAL') {
        return setKeys(state, data);
    }

    return state;
}, 'AVAIL_LOADING', 'AVAIL_FAIL', 'AVAIL_ABORT');

const locations = loadAble(function locations(state, {type, data}) {
    if (!state || type === 'START_SEARCH') {
        return {loading: false, data: []};
    }

    if (type === 'COMPANY_LOCATIONS_LOADED') {
        return loaded(state, data);
    }

    return state;
}, 'COMPANY_LOCATIONS_LOADING', 'COMPANY_LOCATIONS_FAIL');

function order(state, {type, order}) {
    if (!state && state !== null) {
        return '-preference';
    }

    if (type === 'CHANGE_HOTEL_ORDER') {
        return order;
    }

    return state;
}

function pagination(state, {type, pagination}) {
    if (!state) {
        return {'page': 1, 'page_size': 20};
    }

    if (type === 'CHANGE_HOTEL_PAGINATION') {
        return {...state, ...pagination};
    }

    if (type === 'CHANGE_HOTEL_ORDER' || type === 'CHANGE_HOTEL_FILTERS') {
        return {...state, page: 1};
    }

    return state;
}

function selected(state, {type, id, tab}) {
    if (!state) {
        return {};
    }

    if (type === 'SELECTED_HOTEL') {
        return {...state, [id]: tab};
    }

    if (type === 'UNSELECTED_HOTEL' || type === 'RATES_FAIL') {
        return omit(state, id);
    }
    if (type === 'START_SEARCH') {
        return {};
    }

    return state;
}

function filters(state, {type, value}) {
    if (!state || type === 'CLEAR_HOTEL_FILTERS' || type === 'START_SEARCH') {
        return {value: {}};
    }

    if (type === 'CHANGE_HOTEL_FILTERS') {
        return {
            value: {
                ...state.value,
                ...value
            }
        }
    }
    if (type === 'SET_CURRENCY' && state.value.price) {
        return {
            value: {
                ...state.value,
                price: omit(state.value.price, ['min', 'max']),
            }
        };
    }

    return state;
}

function offers(state, {type, hotel, rate}) {
    if (!state || type === 'CLEAR_OFFERS' || type === 'START_SEARCH') {
        return [];
    }

    if (type === 'OFFER_ADD') {
        return [...state, {hotel, rate}];
    }

    if (type === 'OFFER_REMOVE') {
        return reject(state, {hotel, rate});
    }

    return state;
}

function pinnedId(state=null, {type, hotelId, id}) {
    if (type === 'START_SEARCH') {
        return null;
    }

    if (type === 'TO_CHECKOUT' || type === 'CHECKOUT_SWITCH') {
        return hotelId;
    }

    if (type === 'UNSELECTED_HOTEL') {
        return (state === id) ? null : state;
    }

    return state;
}

const reducer = combineReducers({
    avail,
    availSid,
    hotels, hotelDetails,
    rates,
    ratesSid,
    rateWarnings,
    order, selected, pagination,
    filters,
    locations,
    offers,
    pinnedId,
});
export default reducer;
