export function getCarBooking(state, id) {
    return state.carBookings.hash[id];
}

export function isLoading(state) {
    return state.carBookings.loading;
}

export function isModificationInProgress(state, id) {
    return state.carBookingModification.uuid === id;
}

export function getCarsBookingsIds(state) {
    return state.carBookings.paged;
}

export function getCarModificationFlowBooking(state) {
    return state.carBookingModification.booking;
}

export function getCarModificationFlowBool(state) {
    return state.carBookingModification.modificationFlow;
}

export function getCarsModificationDestLoadingBool(state) {
    return state.carBookingModification.bookingDestinationLoading;
}
