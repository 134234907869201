/* global global */
import session from 'airborne/session';
import settings from 'airborne/settings';
import {parseHash} from 'airborne/store/modules/login/helpers/login';
import systemData from 'airborne/systemData';
import xor from 'lodash/xor';

function shouldAddSid () {
    const {cloneSid, templateSid} = parseHash(global.location.hash.toString());
    const sid = templateSid ? settings.TEMPLATE_SID : session.get('sid');
    if (cloneSid) {
        return false;
    }
    const step = (session.get(`sid:${settings.sid}`) || [])
        .find((item) => {
            return  item[1] === 'homepage.step';
        });
    return sid && ((step && step[2] !== 'pnr') || !step);
}

export function normalizeParams(params) {
    return params !== '' ? '?' + params: '';
}

export function getParamsGetUserData() {
    const {templateSid} = parseHash(global.location.hash.toString());
    const sidFromTemplate = settings.TEMPLATE_SID;
    const currentLang = systemData.common.SITE_SUBDOMAIN !== 'admin' ? 'locale='  + settings.USER_LOCALE : '';
    const sid = templateSid ? sidFromTemplate : session.get('sid');

    return shouldAddSid() ? 'sid=' + sid + '&' + currentLang : '' + currentLang;
}

export function shouldRefreshUserData(newPermissions) {
    const oldPermissions = settings.USER_ALL_PERMISSIONS;
    return xor(oldPermissions, newPermissions).length > 0;
}
