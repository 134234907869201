export const AIR_SEARCH_TYPES = {
    FLIGHT: 'search',
    AVAILABILITY: 'availability',
};

export const AIR_TRIP_TYPES = {
    ONE_WAY: 'oneWay',
    ROUND_TRIP: 'roundTrip',
    MULTI_CITY: 'multiCity',
};
