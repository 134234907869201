import pick from 'lodash/pick';

import settings from 'airborne/settings';
import {serializeTSPM} from 'airborne/homepage2/helpers/search';
import {isErrorRecoverable} from 'airborne/search2/helpers/apiError';
import {apiError} from 'airborne/search_cars/helpers/apiError';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {normilizeCCN} from 'airborne/checkout2/helpers/cardValidators';
import {trimSpacesFromNames} from 'airborne/checkout2/helpers/names';
import {monthAsStr} from 'airborne/store/modules/checkout_hotels/actions/book';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getCarBooking, getSelectedRate} from 'airborne/checkout_cars/selectors';
import {fetchBooking} from 'airborne/store/modules/confirm_cars/actions/fetchBooking';
import {checkoutForm, filterCrd} from 'airborne/checkout_cars/helpers/checkoutForm';
import {selectCrd} from 'airborne/store/modules/checkout_hotels/actions/book';
import {getCarsCrd} from 'airborne/checkout_cars/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {cancelForModifyBooking} from 'airborne/store/modules/confirm_cars/actions/modifyBooking';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';
import {startsWith} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import unset from 'lodash/unset';

function getStatesBy(country) {
    return settings.COUNTRY_STATES[country];
}

export function patchBooking({toChange, toRemove}) {
    return {type: 'CARS_PATCH_BOOKING_FIELDS', toChange, toRemove};
}

export function nextStep() {
    return function nextStepD(dispatch, getState) {
        const crd = getCarsCrd(getState());
        dispatch({type: 'CARS_TO_NEXT_CHECKOUT_STEP', crd});
    };
}

export function prevStep() {
    return function prevStepD(dispatch, getState) {
        const crd = getCarsCrd(getState());
        dispatch({type: 'CARS_TO_PREV_CHECKOUT_STEP', crd});
    };
}

export function toStep(step) {
    return {type: 'CARS_TO_CHECKOUT_STEP', step};
}


const BILLING_NO_FIELDS = [
    'billing_number',
    'billing_reference',
];

const IT_FIELDS = [
    'it_number',
    'invoice_code',
];

const VPA_FIELDS = [
    'vpa_card_pool_id',
];

const CARD_FIELDS = [
    'credit_card_first_name',
    'credit_card_last_name',
    'credit_card_number',
    'credit_card_expiration_year',
    'credit_card_expiration_month',
];

const TSPM_CARD_FIELDS = [
    'card_index',
    'credit_card_first_name',
    'credit_card_last_name',
    'credit_card_expiration_year',
    'credit_card_expiration_month',
];

function getPaymentFields(paidBy, withBillingFields, cardIndex, itNumber, vpa) {
    return [
        ...(withBillingFields ? BILLING_NO_FIELDS : []),
        ...(itNumber ? IT_FIELDS : []),
        ...(vpa ? VPA_FIELDS : []),
        ...(paidBy === 'card'
            ? cardIndex
                ? TSPM_CARD_FIELDS
                : CARD_FIELDS
            : []),
    ];
};

function fixCard(form) {
    const {
        'credit_card_expiration': ccExp,
        ...rest
    } = form;

    return {
        ...rest,
        'credit_card_expiration_month': ccExp && monthAsStr(ccExp.month),
        'credit_card_expiration_year': ccExp && ccExp.year,
        'credit_card_number': normilizeCCN(rest['credit_card_number']),
    };
}

function fixVoucher(form) {
    return {
        ...form,
        'paid_by': form.evoucher ? 'evoucher_voucher' : form.paid_by,
    };
}

function serializePayment(form, withBillingFields) {
    const {'paid_by': paidBy, 'it_number': itNumber, 'vpa_card_pool_id': vpa} = form;
    const fixFn = {
        card: fixCard,
        voucher: fixVoucher,
    }[paidBy];
    const data = fixFn ? fixFn(form) : form;
    return {
        ...pick(data, getPaymentFields(paidBy, withBillingFields, form.card_index, itNumber, vpa)),
        'payment_method': data.paid_by,
    };
}

const COLLECTION_KEY = 'collection_';
const DELIVERY_KEY = 'delivery_';

export function serializeCollectionAndDelivery(form) {
    const {withCollection, withDelivery, 'same_as_delivery': sameAsDelivery} = form;

    if (!withCollection && !withDelivery) {
        return null;
    }

    let collection = {};
    let delivery = {};

    for (const [key, value] of Object.entries(form)) {
        if (withCollection && !sameAsDelivery && startsWith(key, COLLECTION_KEY)) {
            collection[key.replace(COLLECTION_KEY, '')] = value;
        }

        if (withDelivery && startsWith(key, DELIVERY_KEY)) {
            delivery[key.replace(DELIVERY_KEY, '')] = value;
        }
    }

    if (sameAsDelivery) {
        collection = {...delivery};
    }

    const STATE_CODE = 'state_code';
    if (!getStatesBy(collection.country_code)) {
        unset(collection, STATE_CODE);
    }

    if (!getStatesBy(delivery.country_code)) {
        unset(delivery, STATE_CODE);
    }


    return {
        'collection_delivery': dropEmpty({
            collection: !isEmpty(collection) ? collection : null,
            delivery: !isEmpty(delivery) ? delivery : null,
        })
    };
}

function toConfirmation() {
    return {type: 'CARS_TO_CONFIRM'};
}

function bookFailure(response) {
    return function bookFailureD(dispatch) {
        const recoverable = isErrorRecoverable(response);
        const message = apiError(response) || 'EUNKNOWN';
        return dispatch({type: 'CARS_BOOKING_FAIL', message, recoverable});
    };
}


export function confirmBooking() {
    return function makeBookingD(dispatch, getState) {
        const booking = getCarBooking(getState());
        const {rateKey, isCdRate, vendor} = getSelectedRate(getState());
        const withBillingFields = isCdRate || settings.CARS_NON_SPECIFIC_VENDORS.includes(vendor.code);
        const profile = getPnrProfile(getState(), 0);
        const {value: form} = checkoutForm(getState());
        const crd = filterCrd(getState(),form);
        const isCarModifyFlowActive = getCarModificationFlowBool(getState());
        const params = dropEmpty(trimSpacesFromNames({
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'rate_key': rateKey,

            'pnr_id': profile.pnr || null,

            ...serializePayment(form, withBillingFields),
            ...serializeCollectionAndDelivery(form),

            'booker_email': settings.USER.email,
            'booker_first_name': settings.USER.first_name,
            'booker_last_name': settings.USER.last_name,

            'booktrack_id': `${booking.uuid}R${booking.retry}`,

            'traveler_first_name': form['first_name'],
            'traveler_last_name': form['last_name'],
            'traveler_email': form.email,

            'flight_number': form['flight_number'] || null,
            'special_information': form['special_information'] || null,
            'special_equipment': form['special_equipment'] || null,

            'client_reportable_data': selectCrd(crd),

            ...serializeTSPM(profile),
        }));

        dispatch({type: 'CARS_BOOKING_LOADING'});

        return air('POST', '/cars/book/', params)
            .then((response)=> dispatch({type: 'CARS_BOOKING_UID', uid: response['booking_uid']}))
            .then(()=> (isCarModifyFlowActive && dispatch(cancelForModifyBooking())))
            .then(()=> dispatch(fetchBooking()))
            .then(()=> dispatch(toConfirmation()))
            .catch((response)=> dispatch(bookFailure(response)))
    };
}

export function toSearch() {
    return {type: 'CARS_TO_SEARCH_RESULT'};
}
