import get from 'lodash/get';
import {PRICE_FIELD, AVAILABILITY_FIELD} from './price';
import {comparator} from 'midoffice/helpers/paginate';


const POLICY_FIELD = 'available.out_of_policy';


export function preferenceOrder(order) {
    return order === 'preference' || order === '-preference';
};

export function comparePreferences(a, b, reverse) {
    const prefA = a.preference || a.sortMetric || [];
    const prefB = b.preference || b.sortMetric || [];
    let order = prefA.reduce((order, {ascending, ...a}, idx)=> {
        if (order) { return order; }

        const b = prefB[idx];
        const orderField = ascending ? '-value' : 'value';

        return comparator(a, b, orderField, true);
    }, 0);

    // Keep original order if values are equal
    order = order || (a.serverOrder < b.serverOrder ? 1 : -1);

    // 2, -2 means sorted because one of these values is not defined
    if (order === 2 || order === -2) { return order; }
    return reverse ? -order : order;
};

export function populatePreferences({preference, ...hotel}) {
    return {
        ...hotel,
        preference: preference && preference.map((preference)=> {
            if (preference.name === 'rate') {
                return {...preference, value: get(hotel, PRICE_FIELD)};
            }
            if (preference.name === 'rate_availability') {
                return {...preference, value: get(hotel, AVAILABILITY_FIELD)};
            }
            if (preference.name === 'rate_policy') {
                return {...preference, value: get(hotel, POLICY_FIELD)};
            }

            return preference;
        }),
    };
};
