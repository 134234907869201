import React from 'react';
import {gramsToKilograms} from "airborne/utils/numbers";

const Emissions = ({emissions}) => {
    return (
        <div className="a-content__item__rate-details" data-testid="branded-fares-co2">
            <span>CO<sub>2</sub> {gettext('Emissions')}:</span>
            {' '}
            {gramsToKilograms(emissions)} {gettext('kg')}
        </div>
    );
};

export default Emissions;
