import providerCredentialsTypes from 'midoffice/store/modules/providerCredentials/actionTypes';
import api from 'midoffice/helpers/api';
import {
    showQuickAlert
} from 'airborne/store/modules/homepage/actions';

export const setProviderCreds = credentials => ({
    type: providerCredentialsTypes.SET_LIST,
    credentials
});

export const addCredential = newCredential => ({
    type: providerCredentialsTypes.ADD_CREDENTIALS,
    newCredential
});

export const setInitState = credentials => ({
    type: providerCredentialsTypes.SET_INITIAL_STATE,
    credentials
});

export const resetForm = () => ({
    type: providerCredentialsTypes.RESET_FORM,
});

export const setCountDependencies = (count) => ({
    type: providerCredentialsTypes.SET_COUNT_DEPENDENCIES,
    count,
});
export const showModal = (id) => ({
    type: providerCredentialsTypes.TRAVELPORT_SHOW_MODAL,
    id
});
export const hideModal = () => ({
    type: providerCredentialsTypes.TRAVELPORT_HIDE_MODAL,
});
export const showAddModal = (id) => ({
    type: providerCredentialsTypes.TRAVELPORT_SHOW_ADD_MODAL,
    id
});
export const hideAddModal = () => ({
    type: providerCredentialsTypes.TRAVELPORT_ADD_HIDE_MODAL,
});

export const editCredentials = function(id) {
    return async function fetchTravelportCredsD(dispatch) {
        dispatch(showAddModal(id))
    };
};

export const canDeleteProviderCred = function(id) {
    return async function fetchTravelportCredsD(dispatch) {
        const result = await api('GET', `/api/provider-credentials/get_dependencies/?id=${id}`);
        dispatch(setCountDependencies(result['count_dependencies']));
        dispatch(showModal(id))
    };
};
export const fetchProviderCreds = function() {
    return async function fetchProviderCredsD(dispatch) {
        const result = await api('GET', '/api/provider-credentials/');
        const withLocalIds = result.map((item, index) => ({
            ...item,
            localId: index,
        }))
        dispatch(setInitState(withLocalIds));
    };
};

export const postCreds = function(data) {
    return async function postLabelsD(dispatch) {
        const dataWithoutLocalId = {}
        dataWithoutLocalId.credentials = data.credentials.map((item) => {
            const {localId, ...otherFields} = item;
            return {...otherFields};
        });
        await api('POST', '/api/provider-credentials/', {data: dataWithoutLocalId});
        dispatch(fetchProviderCreds());
    };
};

export const deleteProviderCred = function(id) {
    return async function deleteTravelportCredD(dispatch) {
        await api('DELETE',  `/api/provider-credentials/${id}/`);
        dispatch(setCountDependencies(null));
        dispatch(hideModal());
        dispatch(showQuickAlert('Changes saved'));
        dispatch(fetchProviderCreds());
    };
};
