import flow from 'lodash/flow';
import omit from 'lodash/omit';

function mergeConfig(data) {
    const {config, ...rest} = data;
    const prefixedConfig = Object.entries(config).reduce((acc, [key, value])=> {
        return {...acc, [`config:${key}`]: value};
    }, {});

    return {
        ...rest,
        ...prefixedConfig,
    };
}

function parsePcc(data) {
    const {'config:parent_pcc': parentPcc} = data;
    return {
        ...data,
        'config:parent_pcc': parentPcc
            ? {label: parentPcc, value: parentPcc}
            : null,
    };
}

const DEFAULT_ENDPOINT_CHOICES = [
    'https://americas.universal-api.travelport.com/B2BGateway/connect/uAPI',
    'https://apac.universal-api.travelport.com/B2BGateway/connect/uAPI',
    'https://emea.universal-api.travelport.com/B2BGateway/connect/uAPI',
];

const CUSTOM_ENDPOINT_PROVIDER = ['apollo', 'galileo'];
function isCustomEndpointProvider({provider}) {
    return CUSTOM_ENDPOINT_PROVIDER.includes(provider);
}

function parseEndpoint(data) {
    const {'config:endpoint': configEndpoint} = data;

    if (!configEndpoint || DEFAULT_ENDPOINT_CHOICES.includes(configEndpoint) || !isCustomEndpointProvider(data)) {
        return data;
    }

    return {
        ...data,
        'config:endpoint': 'custom',
        'config:endpoint_custom': configEndpoint,
    };
}

const parseProfile = flow(mergeConfig, parsePcc, parseEndpoint);

export function parse(data) {
    const {'gds_profiles': gdsProfiles} = data;
    const profiles = gdsProfiles.map(parseProfile);

    return {
        ...data,
        'actions': [],
        'gds_profiles': profiles
    };
};

function splitConfig(data) {
    return Object.entries(data).reduce((acc, [key, value])=> {
        if (key.startsWith('config:')) {
            return {
                ...acc,
                config: {...acc.config, [key.substr('config:'.length)]: value}
            };
        }
        return {...acc, [key]: value};
    }, {config: {}});
}

function fixParentPCC(data) {
    const {'config:parent_pcc': parentPcc} = data;
    data = {
        ...data,
        'config:parent_pcc': parentPcc ? parentPcc.value : null,
    };

    // BE makes no sense here
    if (!data['config:parent_pcc']) {
        return omit(data, 'config:parent_pcc');
    }

    return data;
}

function serializeEndpoint(data) {
    const {
        'config:endpoint': endpointChoice,
        'config:endpoint_custom': customEndpoint,
    } = data;

    if (endpointChoice === 'custom') {
        return {
            ...omit(data, 'config:endpoint_custom'),
            'config:endpoint': customEndpoint,
        };
    }

    return data;
}

const serializeProfile = flow(serializeEndpoint, fixParentPCC, splitConfig);

export function serialize(data) {
    const {
        actions,
        'gds_profiles': profiles,
    } = data;

    return {
        actions,
        'gds_profiles': profiles.map(serializeProfile),
    };
}

export function serializeInheritance(inherits, params) {
    return params;
}

function splitAmadeusGdsProfile(profile) {
    return Object.entries(profile).reduce((acc, [key, value])=> {
        if (key.startsWith('air_')) {
            return {
                hotels: {...acc.hotels},
                flights: {...acc.flights, [key.replace(/^air_/, '')]: value}
            };
        }
        return {
            hotels: {...acc.hotels, [key]: value},
            flights: acc.flights.hasOwnProperty(key) ? {...acc.flights} : {...acc.flights, [key]: value}
        };
    }, {hotels: {}, flights: {}});
}

export function configOnly(data, product) {
    let {config} = serializeProfile(data);
    if (product) config = splitAmadeusGdsProfile(config)[product];
    return config;
}
