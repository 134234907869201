import omit from 'lodash/omit';

function loadingKeys(state, ids) {
    const loading = ids.reduce((hash, id)=> ({
        ...hash,
        [id]: true,
    }), state.loading);

    return {
        ...state,
        loading,
    };
}

function failKeys(state, ids) {
    return {
        ...state,
        loading: omit(state.loading, ...ids),
    };
}


export function setKeys(state, data) {
    return {
        ...state,
        data: {...state.data, ...data},
    };
}

export function loadedKeys(state, data, rateTarget) {
    return {
        ...setKeys(state, data),
        rateTarget,
        loading: omit(state.loading, ...Object.keys(data)),
    };
}

export function loadAbleKeys(fn, loadingType, failType, abortType) {
    return function loadAbleR(state, action) {
        if (!state) return fn(state, action);
        const ids = action.ids || [action.id];
        if (action.type === loadingType) {
            return fn(loadingKeys(state, ids), action);
        }
        if (action.type === failType || action.type === abortType) {
            return fn(failKeys(state, ids), action);
        }

        return fn(state, action);
    };
}

