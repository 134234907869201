import union from 'lodash/union';
import gettext from 'airborne/gettext';
import addressDestination, {googleAddress} from './address';
import {currentCompany} from 'airborne/homepage2/helpers/search';
import {getDestForm, getOptions} from 'airborne/store/modules/homepage/selectors/homepage';
import {toggleCollapsableSection} from 'airborne/store/modules/homepage/actions';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getSelectedConfiguration} from '../selectors/tspm';

function destinationError(destination) {
    return showModal('search', {
        message: gettext('Sorry, destination {destination} was not found. Please try changing the destination.', {destination}),
    });
}

function destinationAddressError(param) {
    return {
        type: 'CHANGE_DEST_ERRORS',
        errors: {
            [param]: gettext('No destination found'),
        }
    };
}


function selectRecentHotel(selected) {
    const {value, label, type, expired, checkin, checkout, chains, distance, keywords} = selected;
    return {type: 'REUSE_DEST', value: {
        destination: {value, label, type},
        expired,
        dates: {min: checkin, max: checkout},
        chains, 'hotel_name': keywords,
        distance,
    }};
}


function selectRecentCar(selected) {
    const {value, label, type, iataCode, lat, lon,
        dropOff, pickUpDistance, dropOffDistance,
        dates, differentDropOff, acriss=[], expired} = selected;

    return {type: 'REUSE_DEST', value: {
        pickUp: {value, label, type, iataCode, lat, lon},
        dropOff, pickUpDistance, dropOffDistance,
        dates, differentDropOff, acriss, expired,
    }};
}

function selectRecentAir(selected) {
    return function(dispatch) {
        const {connections, airlineCodes, airlineAlliances, ftNumbers} = selected;

        // If something from Transfer Airport section are going to be autofilled,
        // we should open this section to show which fields have been filled
        if (connections?.length) {
            dispatch(toggleCollapsableSection('transferAirport', true));
        }
        if (airlineCodes || airlineAlliances) {
            dispatch(toggleCollapsableSection('airlines', true));
        }
        if (ftNumbers?.length) {
            dispatch(toggleCollapsableSection('frequentFlyerNumber', true));
        }

        return dispatch({type: 'REUSE_DEST', value: selected});
    };
}

export function selectDestination(selected, param) {
    if (selected === null) {
        return {
            type: 'REUSE_DEST', value: {[param]: null}
        };
    }

    if (!selected.hasOwnProperty('expired')) {
        const {value, label, type=null, ...rest} = selected;
        return {
            type: 'REUSE_DEST',
            value: {
                [param]: {value, label, type, ...rest},
            }
        };
    }

    if (selected.isAir) {
        return selectRecentAir(selected);
    }

    const isCar = ['pickUp', 'dropOff'].includes(param);
    return isCar ? selectRecentCar(selected) : selectRecentHotel(selected);
}

function selectOnlyDestination(data) {
    const results = data.reduce((acc, {items})=> union(acc, items), []);
    if (results.length === 1) {
        return results[0];
    }
    return null;
}

function onlyDestinationOrModal(results, {toSearch=false, param}={}) {
    return function onlyDestinationOrModalD(dispatch) {
        const onlyMatch = selectOnlyDestination(results);
        if (onlyMatch) {
            dispatch(selectDestination(onlyMatch, param));
        }
        else {
            const kind = param === 'destination' ? 'destinations' : 'carsDestinations';
            dispatch(showModal(kind, {data: {results, param}, toSearch}));
        }
        return Boolean(onlyMatch);
    };
}

export function reuseDestination(selected, param='destination') {
    return function reuseDestinationD(dispatch) {
        if (selected && selected.type === 'search') {
            return googleAddress(selected.label)
                .then((results)=> dispatch(onlyDestinationOrModal(results, {param})))
                .catch(()=> dispatch(destinationAddressError(param)));
        }
        return dispatch(selectDestination(selected, param));
    };
}

export default function destinationModal(param='destination') {
    return function (dispatch, getState) {
        const state = getState();
        const dest = getDestForm(state);
        const destOptionsGDS = getOptions(state)?.['primary_gds'];
        const {[param]: destination} = dest.value;

        if (destination.value) {
            return Promise.resolve();
        }
        const configId = getSelectedConfiguration(state) || currentCompany(state);
        return addressDestination(destination.label, configId, destOptionsGDS)
            .then((data)=> dispatch(onlyDestinationOrModal(data, {toSearch: true, param})))
            .then((singlMatch)=> (
                singlMatch
                    ? Promise.resolve()
                    : Promise.reject()
            ), ()=> {
                dispatch(destinationError(destination.label));
                return Promise.reject();
            });
    };
}
