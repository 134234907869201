const CARS_VENDORS_PARAM_KEY = 'cars:payment_methods_by_vendors';
const VENDORS_CODES_KEY = 'vendor_codes';
const VENDORS_PAYMENTS_METHODS_KEY = 'payment_methods';

export function parse(data) {
    const carsPerVendorsData = data[CARS_VENDORS_PARAM_KEY] || [];

    return {
        ...data,
        [CARS_VENDORS_PARAM_KEY]: carsPerVendorsData.map(paymentPerVendorObj => ([
            paymentPerVendorObj[VENDORS_PAYMENTS_METHODS_KEY],
            paymentPerVendorObj[VENDORS_CODES_KEY]
        ]))
    };
}

export function serialize(data) {
    const carsPerVendorsData = data[CARS_VENDORS_PARAM_KEY] || [];

    return {
        ...data,
        [CARS_VENDORS_PARAM_KEY]: carsPerVendorsData.map(([paymentMethods, vendorCodes]) => ({
            [VENDORS_PAYMENTS_METHODS_KEY]: paymentMethods,
            [VENDORS_CODES_KEY]: vendorCodes,
        }))
    };
}
