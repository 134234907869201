import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';

function parseCategory(item) {
    const id = Object.keys(item)[0];
    let {orders, label, custom=null} = item[id];
    orders = Object.entries(orders).map(([label, id])=> ([id, label]));
    if (custom) {
        custom = Object.keys(custom).map((customKey)=> ({
            label: customKey,
            id: custom[customKey],
        }));
        orders = [...orders, ['custom', 'Custom order']];
    }

    return {id, label, orders, custom};
}

export function parseCategories(data) {
    return data
        .reduce((acc, item) => {
            const category = parseCategory(item);
            return {...acc, [category.id]: category};
        }, {});
}

function parseSorter([category, order], id) {
    return {
        id, category,
        order: isObject(order) || isNull(order) ? 'custom' : order,
        custom: isObject(order) ? order : null,
    };
}

export function parseSorters(arr) {
    return arr.map(parseSorter);
}

export function parse(data) {
    const {
        'sorters:area': area,
        'sorters:point_of_interest': poi,
        'sorter_choices': choices,
    } = data;

    return  {
        'sorter_categories': parseCategories(choices),
        'sorters:area': parseSorters(area),
        'sorters:point_of_interest': parseSorters(poi),
    };
}

export function serializeSorter({category, order, custom}) {
    return [category, order !== 'custom' ? order : (custom || [])];
}


export function serialize(data) {
    const {
        'sorters:area': area,
        'sorters:point_of_interest': poi,
    } = data;
    return {
        'sorters:area': area.map(serializeSorter),
        'sorters:point_of_interest': poi.map(serializeSorter),
    };
}

export function fromParent(data) {
    return omit(data, 'sorter_categories');
}
