import {
    LOADED, LOADED_PAGINATED, REMOVED, ADDED,
    LOADING, UPLOADING, NET_FAIL,
} from './types';

import {parse, parseList, getUrl, serialize} from 'airborne/types';

import api from 'midoffice/helpers/api';
import getErrors from 'midoffice/helpers/apiErrors';

function getUrlParams(endpoint, kind, params) {
    const url = getUrl(endpoint, 'fetchCollection', params);
    if (Array.isArray(url)) {
        return url;
    }
    return ['GET', url];
}

function updatePagination(endpoint, state, data, params) {
    const {pageBy} = state[endpoint];
    const {total, 'total_count_accurate': precise} = data;
    const {page, 'page_size': pageSize} = params;
    const totalPages = Math.ceil(total/pageSize);
    const startIndex = ((page - 1) * pageSize) + 1;

    const endIndex = page === totalPages && ['airBookings', 'carBookings'].includes(endpoint)
        ? total
        : (startIndex + pageSize) - 1;

    return {
        ...pageBy,
        page,
        total,
        'page_size': pageSize,
        'end_index': endIndex,
        'start_index': startIndex,
        'total_pages': totalPages,
        'total_count_accurate': precise,
    };
}

export function fetchCollection(endpoint, params, throwOnFail, query) {
    return function (dispatch, getState) {
        dispatch({type: LOADING, endpoint});
        const [method, url, customQuery] = getUrlParams(endpoint, 'fetchCollection', params);

        return ((method === 'POST')
            ? api(method, url, {
                data: {...serialize(endpoint, params, {search: true}), ...customQuery}
            })
            : api(method, url))
            .then((data)=> {
                let pagination = data && data.pagination;
                const {ordering} = params || {};
                const warnings = data.warnings;

                if (data && data.hasOwnProperty('items')) {
                    data = data.items;
                }
                else if (data && data.hasOwnProperty('data')) {
                    data = data.data;
                }
                else if (data && data.hasOwnProperty('bookings')) {
                    pagination = updatePagination(endpoint, getState(), data, params);
                    data = data.bookings;
                }
                else if (!pagination && data.hasOwnProperty('page')) {
                    let {results, ...rest} = data;
                    data = results;
                    pagination = rest;
                }
                const parsed = parseList(endpoint, data);
                const sortOrder = ordering ? {ordering: ordering} : {};

                if (pagination) {
                    pagination = {...pagination, ...sortOrder};

                    dispatch({
                        type: LOADED_PAGINATED, endpoint,
                        data: parsed,
                        pagination,
                        query: query && Object.entries(query),
                        ...(warnings ? {warnings} : {}),
                    });
                }
                else {
                    dispatch({type: LOADED, endpoint, data: parsed});
                }
                return parsed;
            }, (response)=> {
                dispatch({type: NET_FAIL, endpoint, response, errors: getErrors(response)});
                if (throwOnFail) {
                    throw response;
                }
            });
    };
}

export function fetchEntry(endpoint, id, params) {
    return function (dispatch) {
        dispatch({type: LOADING, endpoint, id});

        const url = getUrl(endpoint, 'fetchEntry', id, params);

        return api('GET', url)
            .then((data)=> {
                dispatch({type: LOADED, endpoint, id, data: parse(endpoint, data)});
                return data;
            }, (response)=> {
                dispatch({type: NET_FAIL, endpoint, id, response, errors: getErrors(response)});
            });
    };
}

export function removeEntry(endpoint, id, params=null, rethrow=false) {
    return function (dispatch) {
        dispatch({type: LOADING, endpoint, id});

        const url = getUrl(endpoint, 'removeEntry', id, params);

        return api('DELETE', url)
            .then((data)=> {
                dispatch({type: REMOVED, endpoint, id});
                return data;
            }, (response)=> {
                dispatch({type: NET_FAIL, endpoint, id, response, errors: getErrors(response)});
                if (rethrow) {
                    throw response;
                }
            });
    };
}

export function addEntry2(endpoint, data, rethrow=false, params) {
    return function (dispatch, getState) {
        const {upload={}} = getState()[endpoint];
        const pendingId = Object.keys(upload).length;

        dispatch({type: LOADING, endpoint});
        dispatch({type: UPLOADING, endpoint, data, pendingId});

        const url = getUrl(endpoint, 'addEntry', params);

        return api('POST', url, {data: serialize(endpoint, data)})
            .then((rdata)=> {
                dispatch({type: ADDED, endpoint, pendingId, data: parse(endpoint, rdata, {local: data})});
                return {...rdata, type: 'success'};
            }, (response)=> {
                const errors = getErrors(response);
                dispatch({type: NET_FAIL, endpoint, response, errors, pendingId});
                if (rethrow) {
                    throw response;
                }
                else {
                    return {type: 'error', errors};
                }
            });
    };
}

export function addEntry({endpoint, ...data}, ...args) {
    return addEntry2(endpoint, data, ...args);
}


export function loadedEntry(endpoint, id, data) {
    return {type: LOADED, endpoint, id, data: parse(endpoint, data)};
}

export function changeEntry({endpoint, id, data}, rethrow=false, replace=false) {
    return function (dispatch) {
        dispatch({type: LOADING, endpoint, id});

        const url = getUrl(endpoint, 'changeEntry', id);
        const method = replace ? 'PUT' : 'PATCH';

        return api(method, url, {data: serialize(endpoint, data)})
            .then((data)=> {
                dispatch(loadedEntry(endpoint, id, data));
                return data;
            }, (response)=> {
                dispatch({type: NET_FAIL, endpoint, id, response, errors: getErrors(response)});
                if (rethrow) {
                    throw response;
                }
            });
    };
}
