const SETTINGS_BY_TREE = {
    companies: 'cars:rate_exclusions',
    agencies: 'agency:cars:rate_exclusions',
};

function fillDefaultsCarsRateExclusions(rawObject) {
    const fields = [
    	'exclude_on_request_rates',
        'not_cd_rates',
        'not_bcd_cd_rates',
        'not_public_rates',
        'not_id_rates',
        'not_it_rates'
    ];
    fields.forEach((field) => {
        if (!(field in rawObject)) {
            rawObject[field] = false;
        }
    });

    return rawObject;
}

export function parse(raw, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    if (treeName === 'companies') {
        return fillDefaultsCarsRateExclusions(raw[setting]);
    }
    return raw[setting];
}

export function serialize(data, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    return {
        [setting]: {
            ...data,
            'car_types': data['car_types'] || [],
            'vendor_codes': data['vendor_codes'] || [],
        }
    };
}
