import isEmpty from 'lodash/isEmpty';
export function formatQs(qs) {
    return Object.entries(qs).reduce((acc, [key, value])=> (
        [...acc, `${key}=${encodeURIComponent(value)}`]
    ), []).join('&');
}

export function formatUrl(base, qs) {
    if (!qs || isEmpty(qs)) {
        return base;
    }

    return `${base}?${formatQs(qs)}`;
}

export function parseUrl(url) {
    const ret = {};
    url.split('&').forEach((part)=> {
        const [key,value] = part.split('=', 2);
        ret[key] = value;
    });
    return ret;

}

export function parseUrlQuery(query) {
    const queryParams = (query || '').replace('?', '')
        .split('&')
        .filter(item => item)
        .map(item => item.split('='))
        .reduce(
            (acc, [key, value]) => ({...acc, [key]: value}),
            {}
        );
    return queryParams;
}

export function stringifyUrlQuery(queryParams) {
    const query = Object.entries(queryParams || {})
        .filter(([,value]) => (typeof value !== 'undefined'))
        .map(item => item.join('='))
        .join('&');
    return query ? '?' + query : '';
}
