import {getUniqueFareKey} from 'airborne/search_air/helpers';
import {createSelector} from 'reselect';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";

export function getFareKeysRoot(state) {
    return state.air.fareKeys;
}

export function getFareKeys(state, ticketIndex = 0) {
    return getFareKeysRoot(state)[ticketIndex];
}

export function getFareGroupKey(state, ticketIndex = 0) {
    return getFareKeys(state, ticketIndex).fareGroupKey;
}

export function getFlightOptionKeys(state, ticketIndex = 0) {
    return getFareKeys(state, ticketIndex).flightOptionKeys;
}

// TODO We should refactor most of selectors to use this one
export const getFareGroupStoreKey = createSelector(
    getFareGroupKey,
    getFlightOptionKeys,
    (fareGroupKey, flightOptionKeys) =>
        fareGroupKey &&
        flightOptionKeys &&
        getUniqueFareKey({fareGroupKey, flightOptionKeys}),
);

export const getSeparatedTicketsFareKeys = (state) => {
    if (!isAirSeparatedTicketsMode(state)) {
        return [];
    }

    return [
        getFareGroupStoreKey(state, 0),
        getFareGroupStoreKey(state, 1),
    ];
}

export function getCheckoutFareGroupKey(state, ticketIndex = 0) {
    const flightPriceData = state.air.flightPrice.data;
    return flightPriceData[getFareGroupStoreKey(state, ticketIndex)]?.fareGroupKey;
}
