import partial from 'lodash/partial';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import gettext from 'airborne/gettext';
import {combineValidators, strictOnly} from 'midoffice/newforms/helpers';
import {CharField} from 'midoffice/newforms/fields-stateless';


function inRange(value, from, to) {
    return value >= from && value <= to;
}

function getDigits(value, count) {
    return parseInt(value.toString().substr(0, count), 10);
}

function checkPattern(pattern, value) {
    if (!Array.isArray(pattern)) {
        return String(value).startsWith(pattern);
    }
    const [from, to] = pattern;
    const {length} = from;

    return inRange(getDigits(value, length), parseInt(from, 10), parseInt(to, 10));
}

function checkLength(length, complete, value) {
    if (!Array.isArray(length)) {
        return complete ? value.length === length : value.length <= length;
    }
    const [from, to] = length;
    return complete
        ? (value.length >= from && value.length <= to)
        : value.length <= to;
}

function checkRule(value, complete=true, rule) {
    const {pattern, length} = rule;
    return checkPattern(pattern, value) && checkLength(length, complete, value);
}

// source: https://gist.github.com/DiegoSalazar/4075533
export function luhn10(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) === 0;
}

const CC_VALIDATORS = [
    {
        name: 'Visa',
        value: 'vi',
        codes: ['VI', 'VS'],
        numbers: [
            {
                pattern: '4',
                length: 16,
            },
            {
                pattern: '4',
                length: 19,
            }
        ]
    },
    {
        name: 'Master Card',
        value: 'mc',
        codes: ['CA', 'MC', 'EC', 'MD', 'MS', 'IK', 'XS'],
        numbers: [
            {
                pattern: ['51', '55'],
                length: 16,
            },
            {
                pattern: ['2221', '2720'],
                length: 16,
            }
        ]
    },
    {
        name: 'Airplus',
        value: 'tp',
        codes: ['TP'],
        numbers: [
            {
                pattern: '1220',
                length: 15,
            },
            {
                pattern: '1920',
                length: 15,
            }
        ]
    },
    {
        name: 'American Express',
        value: 'ax',
        codes: ['AX'],
        numbers: [
            {
                pattern: '34',
                length: 15,
            },
            {
                pattern: '37',
                length: 15,
            }
        ]
    },
    {
        name: 'DinersClub',
        value: 'dc',
        codes: ['DC'],
        numbers: [
            {
                pattern: '36',
                length: [14, 19],
            },
            {
                pattern: ['300', '305'],
                length: [16, 19],
            },
            {
                pattern: '3095',
                length: [16, 19],
            },
            {
                pattern: ['38', '39'],
                length: [16, 19],
            },
        ],
    },
    {
        name: 'Discover',
        value: 'ds',
        codes: ['DS'],
        numbers: [
            {
                pattern: '6011',
                length: [16, 19],
            },
            {
                pattern: '64',
                length: [16, 19],
            },
            {
                pattern: '65',
                length: [16, 19],
            },

        ],
    },
    {
        name: 'JSB',
        value: 'jc',
        codes: ['JC'],
        numbers: [
            {
                pattern: ['3528', '3589'],
                length: [16, 19],
            },
        ],
    },
];

export const API_TO_INTERNAL = CC_VALIDATORS.reduce((acc, {value, codes})=> {
    const mapCades = codes.reduce((acc, code)=> ({...acc, [code]: value}), {});
    return {
        ...acc,
        ...mapCades,
    };
}, {});

export function getAcceptedCards(apiCodes) {
    const internals = (Array.isArray(apiCodes) ? apiCodes : []).map(({code}) => API_TO_INTERNAL[code]);
    return uniq(compact(internals));
}

export function normilizeCCN(value) {
    return value && value.replace(/(\s|-)/g, '');
}

export function getCardType(value) {
    if (!value) return null;
    const normValue = normilizeCCN(value);
    const validator = CC_VALIDATORS
        .find(({numbers})=> Boolean(numbers.find(partial(checkRule, normValue, false))));
    return validator || null;
}

export function isComplete(value, cardType) {
    const {numbers} = CC_VALIDATORS.find(({value})=> cardType === value);
    return Boolean(numbers.find(partial(checkRule, value, true)));
}

export function validateCCNumber(paymentCardsAccepted, value, schemaParams={}) {
    const type = getCardType(value);

    const acceptedCC = getAcceptedCards(paymentCardsAccepted);
    if (!schemaParams.strict && !value) {
        return null;
    }

    if (type === null) {
        return gettext('It is unknown card type. This rate does not accept them.');
    }

    if (acceptedCC.includes(type.value) === false) {
        return gettext('This rate does not accept {name}.', {name: type.name});
    }

    return (isComplete(value, type.value) || !schemaParams.strict)
        ? null
        : gettext('Please check CC number.');
}

export function validateLuhn(value, schemaParams={}) {
    return (luhn10(value) || !schemaParams.strict)
        ? null
        : gettext('Please check CC number.');
}

export function getCCNumberSchema(paymentCards=null) {
    const ccnValidator = (paymentCards && paymentCards.length)
        ? partial(validateCCNumber, paymentCards)
        : ()=> null;
    const baseField = strictOnly(CharField);
    return {
        ...baseField,
        minLength: 2,
        minLengthMessage: CharField.emptyMessage,
        validate: function (value, params) {
            const validators = combineValidators(
                baseField.validate,
                ccnValidator,
                validateLuhn,
            );
            return validators.call(this, normilizeCCN(value), params);
        },
    };
}
