import session from 'airborne/session';
import settings from 'airborne/settings';

const getSidBasedKey = () => `${settings.sid}:sso_container_id`;

export const saveSsoContainerId = (containerId) => {
    session.set(getSidBasedKey(), containerId);
};

export const getSsoContainerId = () => {
    return session.get(getSidBasedKey());
};
