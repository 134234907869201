import {default as midofficeModal} from 'midoffice/data/store/modals';

const modalKinds = [
    'compare',
    'rateDetails',
    'bookingCancellation',
    'fareRules',
    'bookingCancellation3',
    'cancellationPolicy',
    'bookingModification3',
    'updatePaymentInfo',
    'confermaFax',
    'counterofferModal',
    'bookingResolveModal',
    'bookingNoteModal',
    'rawResponse',
    'airModify',
    'airExternalModify',
    'airManualRefund',
    'airExternalManualRefund',
    'bookingHotelsCancellation',
    'airRefundData',
    'airExternalRefundData',
    'hotelsExternalCancellation',
    'carsBookingModifyModal',
];

function hideModal(state) {
    if (Array.isArray(state)) {
        return state.slice(0, -1);
    }
    return {kind: null};
}

// Reset AFT modal state if previous modal was from midoffice
function getCompatibleState(state) {
    if (state.hasOwnProperty('kind')) {
        return [];
    }

    return state;
}

export default function modal(state = [], {type, kind, ...data}) {

    if (type === 'MODAL_SHOW') {
        const compatibleState = getCompatibleState(state);
        return [...compatibleState, {kind, data}];
    }

    if (type === 'MODAL_HIDE') {
        return hideModal(state);
    }

    if (type === 'NAVIGATE') {
        return [];
    }

    return state;
}

export const delegateModals = (state=[], action) => {
    if (modalKinds.includes(action.kind)) {
        return midofficeModal(state, action);
    }
    return modal(state, action);
};

