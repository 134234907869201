import {createSchema} from 'midoffice/newforms/helpers';

import {PasswordField, RepeatPassword} from 'midoffice/newforms/validators/password';

const ResetPasswordSchema = createSchema({
    fields: {
        'password1': PasswordField,
        'password2': RepeatPassword,
    },
});


export default ResetPasswordSchema;

