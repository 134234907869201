import {combineReducers} from 'redux';
import * as actionTypes from '../actionTypes';

const defaultData = {};
const data = (state = defaultData, {type, data}) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
            return defaultData;
        case actionTypes.AIR_FLIGHT_PRICE_LOADED:
        case actionTypes.AIR_FLIGHT_PRICE_FAIL:
            return {
                ...state,
                ...data,
            };
        default:
            return state;
    }
};

const defaultLoading = false;
const loading = (state = defaultLoading, {type}) => {
    switch (type) {
        case actionTypes.AIR_FLIGHT_PRICE_LOADING:
            return true;
        case actionTypes.AIR_FLIGHT_PRICE_LOAD_END:
            return false;
        default:
            return state;

    }
};

const defaultErrors = null;
const errors = (state = defaultErrors, {type, errors}) => {
    switch (type) {
        case actionTypes.AIR_FLIGHT_PRICE_FAIL:
            return errors;
        case actionTypes.AIR_START_SEARCH:
        case actionTypes.AIR_FLIGHT_PRICE_LOADED:
        case actionTypes.AIR_FLIGHT_PRICE_CLEAR_ERRORS:
            return defaultErrors;
        default:
            return state;
    }
};

const defaultRequests = [];
const requests = (state = defaultRequests, {type, requestId}) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
        case actionTypes.AIR_FLIGHT_PRICE_CLEAR_REQUESTS:
            return defaultRequests;
        case actionTypes.AIR_FLIGHT_PRICE_ADD_REQUEST:
            return [...state, requestId];
        case actionTypes.AIR_FLIGHT_PRICE_REMOVE_REQUEST:
            return state.filter(id => id !== requestId);
        default:
            return state;
    }
};

const defaultLastRequestParams = {};
const lastRequestParams = (state = defaultLastRequestParams, {type, params}) => {
    switch (type) {
        case actionTypes.AIR_START_SEARCH:
            return defaultLastRequestParams;
        case actionTypes.AIR_FLIGHT_PRICE_SET_LAST_REQUEST_PARAMS:
            return {
                ...state,
                ...params,
            };
        default:
            return state;
    }
};

const flightPrice = combineReducers({
    data,
    loading,
    errors,
    requests,
    lastRequestParams,
});

export default flightPrice;
