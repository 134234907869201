import api from 'midoffice/helpers/api';
import {nopAborted} from 'airborne/homepage2/helpers/autocomplete';

function parseResponse(data) {
    return data.map((el)=> {
        return {value: el.value, label: el.value};
    });
}

const settingsAutocomplete = {

    query(term) {
        return api('GET', '/api/auditlog-section/', {data: {term}, id: 'settings_autocomplete'})
            .then((data)=> data.items)
            .then((codes)=> {
                if (codes.length) {
                    return parseResponse(codes);
                }

                return 'No results.';
            })
            .catch(nopAborted);
    }
};
export default settingsAutocomplete;
