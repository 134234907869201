import {FILECHECK_DONE, FILECHECK_LOADING, FILECHECK_FAILED, FILEINPUT_OPEN, FILEINPUT_CLOSE} from '../actions';

function initialState() {
    return {
        isChecking: false,
        status: '',
        inputIsOpen: false
    };
}

export default function fileCheckProcessing(state, action) {
    if (!state) {
        return initialState();
    }

    if (action.type === FILEINPUT_OPEN) {
        return {
            ...state.fileCheckProcessing,
            inputIsOpen: true
        };
    }

    if (action.type === FILEINPUT_CLOSE) {
        return {
            ...state.fileCheckProcessing,
            inputIsOpen: false
        };
    }

    if (action.type === FILECHECK_DONE) {
        return {
            isChecking: false,
            status: 'finished',
            inputIsOpen: false
        };
    }

    if (action.type === FILECHECK_LOADING) {
        return {
            isChecking: true,
            status: 'loading',
            inputIsOpen: false
        };
    }

    if (action.type === FILECHECK_FAILED) {
        return {
            isChecking: false,
            status: 'failed',
            inputIsOpen: false
        };
    }

    return null;
}
