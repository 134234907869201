import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class Scroll extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.handlePosition = this.handlePosition.bind(this);
        this.state = {scroll: 0};
    }

    componentDidMount() {
        if (this._pendingPos) {
            this.handlePosition(this._pendingPos);
        }
    }

    handlePosition(position) {
        const scroll = ReactDOM.findDOMNode(this.refs.scroll);
        if (!scroll) {
            this._pendingPos = position;
            return;
        }
        scroll.scrollTop = position;
        delete this._pendingPos;
    }

    handleScroll({target}) {
        let scroll = target.scrollTop || 0;
        let delta = (this.state.scroll - scroll);
        delta = (delta > 0) ? delta : -delta;

        if (delta < 100) {
            return;
        }

        this.setState({scroll});
    }

    render() {
        const {children, className} = this.props;
        const {scroll} = this.state;

        return (<div className={className} onScroll={this.handleScroll} ref="scroll">
            {React.Children.map(children, (child)=> (
                React.cloneElement(child, {
                    scroll, onPosition: this.handlePosition
                })
            ))}
        </div>);
    }
}
