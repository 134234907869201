const CONDITIONS = {
    'out_of_policy': (rate)=> rate.outOfPolicy,
    'in_policy': (rate)=> !rate.outOfPolicy,
    'deposit': (rate)=> rate.paidPolicy === 'D',
    'guarantee': (rate)=> rate.paidPolicy === 'G',
    'card': (rate, paidBy, evoucher)=> !evoucher && paidBy === 'card' || paidBy === 'card' && rate?.car && evoucher,
    'pnr': (rate, paidBy, evoucher)=> !evoucher && paidBy === 'pnr',
    'evoucher_card': (rate, paidBy, evoucher)=> evoucher && paidBy === 'card',
    'evoucher_voucher': (rate, paidBy, evoucher)=>  paidBy === 'voucher' && evoucher,
    'voucher': (rate, paidBy, evoucher)=>  paidBy === 'voucher' && !evoucher,
    'vpa_payment': (rate, paidBy)=> paidBy === 'vpa_payment',
    'no_payment': (rate, paidBy)=> paidBy === 'no_payment',
    'vpa_guarantee': (rate, paidBy, evoucher)=> !evoucher && paidBy === 'vpa_guarantee',
    'evoucher_vpa_guarantee': (rate, paidBy, evoucher)=> evoucher && paidBy === 'vpa_guarantee',
    'iata': (rate, paidBy, evoucher)=> !evoucher && paidBy === 'iata',
    'evoucher_iata': (rate, paidBy, evoucher)=> evoucher && paidBy === 'iata',
    'invoice': (rate, paidBy)=> paidBy === 'invoice',
    'hotel_provider_pay': (rate, paidBy)=> paidBy === 'hotel_provider_pay',
};

export function matchConditions(rate, paidBy, evoucher, {conditions}) {
    return (conditions || []).reduce((acc, cond)=> {
        const checkFn = CONDITIONS[cond];
        return acc && rate && checkFn && checkFn(rate, paidBy, evoucher);
    }, true);
}

export function isCrdKey(key) {
    return key.match(/^[A-Z]{3}\.(COMMON\.|HOTELS\.|AIR\.|CARS\.|)CRD\./);
}
