export function buildSelectionSeat(seat, segmentIdRef, ticketIndex) {
    const {row, info} = seat;
    const {number} = row;
    const {column, amount, seatKey} = info;

    return {
        segmentIdRef,
        ticketIndex,
        row: number,
        column,
        amount,
        seatKey,
    };
}

export function isSameSeats(firstSeat, secondSeat) {
    return firstSeat?.seatKey === secondSeat?.seatKey;
}
