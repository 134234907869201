import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class Radio extends React.Component {

    static propTypes = {
        choices: PropTypes.array,
        defaultValue: PropTypes.any,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        uniqueName: PropTypes.string,
        value: PropTypes.any,

        block: PropTypes.bool,
        rawLabel: PropTypes.bool,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
    };

    static defaultProps = {
        block: false,
        choices: [],
        defaultValue: null,
        className: '',
        disabled: false,
        onBlur: noop,
        onFocus: noop,
        rawLabel: false,
        uniqueName: '',
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    checkValue(value, defaultValue) {
        if (defaultValue !== null && value !== false && !value) {
            return defaultValue;
        }
        return value;
    }

    handleChange({target: {value}}) {
        const {choices, defaultValue} = this.props;
        value = this.checkValue(value, defaultValue);
        const choice = choices[value];
        if (choice) {
            this.props.onChange(choice[0], this.props.name);
        }
    }

    render() {
        let {block, choices, defaultValue, className, disabled,
            name, rawLabel, value, uniqueName} = this.props;

        let inputClassName = classnames('radio', 'radio-rev', {
            'radio-inline': !block,
            'disabled': disabled,
        });

        value = this.checkValue(value, defaultValue);
        return (
            <div className={className}>
                {choices.map(([choiceValue, label, helpText = ''], index)=>

                    <label key={`radio-label-${name}-${uniqueName}-${choiceValue}`}
                        className={inputClassName}>
                        <input type="radio"
                            name={name+uniqueName}
                            key={`radio-${name}-${choiceValue}`}
                            value={index}
                            disabled={disabled}
                            onChange={this.handleChange}
                            checked={isEqual(value, choiceValue)}
                        />

                        <span className="radio__icon" />
                        {rawLabel ?
                            <span dangerouslySetInnerHTML={{__html: label}} /> :
                            label}
                        <div className="muted"> {helpText} </div>
                    </label>
                )}
            </div>
        );
    }
}
