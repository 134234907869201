'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import Input from 'midoffice/newforms/widgets/Input';
import SecretInput from 'midoffice/newforms/widgets/SecretInput';
import Modal from 'react-bootstrap/Modal';
import Button from 'midoffice/components/Button';
import {
    addCredential,
    hideAddModal, setProviderCreds,
} from 'midoffice/store/modules/providerCredentials/actions';
import {
    getCredentialsById,
    getCurrentId,
    getList
} from 'midoffice/store/modules/providerCredentials/selectors';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

@connect((state) => {
    const currentId = getCurrentId(state);
    return {
        credsList: getList(state) || [],
        currentCred: getCredentialsById(state, currentId),
        currentId,
    };
}, {
    onHide: hideAddModal,
    addCredential,
    setProviderCreds,
})
export default class AddCredsModal extends React.Component {
    static propTypes = {
        credsList: PropTypes.array,
        currentId: PropTypes.number,
        currentCred: PropTypes.object,
        onHide: PropTypes.func.isRequired,
        addCredential: PropTypes.func.isRequired,
        setProviderCreds: PropTypes.func.isRequired,
    };

    state = {
        name: this.props?.currentCred?.name || '',
        username: this.props?.currentCred?.username || '',
        password: this.props?.currentCred?.password || '',
        isNotUnique: false,
    }

    updateCredentials = () => {
        const {credsList, currentId, setProviderCreds, onHide} = this.props;
        const {name, username, password} = this.state;
        const updatedFields = {
            'name': name, 'username': username, 'password': password,
        };
        const hasDuplicates = credsList.filter((creds) => (creds.localId !== currentId))
            .some((creds) => (creds.name === name));
        if (hasDuplicates) {
            return this.setState({isNotUnique: true});
        }
        const idForUpdate = findIndex(credsList, {localId: currentId});
        const elemForUpdate = find(credsList, {localId: currentId});
        credsList.splice(idForUpdate, 1, {...elemForUpdate, ...updatedFields});
        const credentials = {list: credsList};
        setProviderCreds(credentials);
        onHide();
    }

    saveNew = () => {
        const {credsList, addCredential, onHide} = this.props;
        const {name, username, password} = this.state;
        const newLocalId = isEmpty(credsList) ? 1 : credsList[credsList.length-1].localId + 1;
        const newCredential = {
            'name': name, 'username': username, 'password': password, localId: newLocalId
        };
        const hasDuplicates = credsList.some((creds) => (creds.name === name));
        if (hasDuplicates) {
            return this.setState({isNotUnique: true});
        }
        addCredential(newCredential);
        onHide();
    }

    handleChange = (value, field) => {
        this.setState({[field]: value, isNotUnique: false});
    }

    handleSave = () => {
        const {currentId} = this.props;
        if (isNumber(currentId)) {
            this.updateCredentials();
        }
        else {
            this.saveNew();
        }
    }

    render() {
        const {name, username, password, isNotUnique} = this.state;
        const {
            onHide,
        } = this.props;

        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Credentials')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-horizontal">
                        <div className="form-group">
                            <label
                                className="control-label col-xs-2">Name:</label>
                            <div className="col-xs-7">
                                <Input name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label
                                className="control-label col-xs-2">Username:</label>
                            <div className="col-xs-7">
                                <Input name="username"
                                    value={username}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label
                                className="control-label col-xs-2">Password:</label>
                            <div className="col-xs-7">
                                <SecretInput name="password"
                                    value={password}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        {isNotUnique && <div className="error-label">Please, change the name. Name should be unique.</div>}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" onClick={onHide}>{gettext('Close')}</Button>
                    <Button
                        bsStyle="success"
                        onClick={this.handleSave}
                    >
                        {gettext('Update')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
