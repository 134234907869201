import React from 'react';
import PropTypes from 'prop-types';

import {clearForms} from 'midoffice/data/actions/pnrTool';
import PageTitle from 'midoffice/components/PageTitle';

import PNRRetrieve from './PNRRetrieve';
import PNRCreate from './PNRCreate';
import {store} from 'midoffice/store';


export default class PNRTool extends React.Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
    };

    componentWillUnmount() {
        store.dispatch(clearForms());
    }

    handleRetrieve = (entityId, pnr)=> {
        this.props.history.push(`/pnr-tool/${entityId}/${pnr}/common`);
    };

    render() {
        return (
            <div className="container-full container-full--top-fix">
                <header className="content-header">
                    <div className="pull-left">
                        <h3 className="content-header__title">Tools</h3>
                    </div>
                </header>

                <div className="container form-horizontal--narrow-labels form-horizontal--label-fix">
                    <PNRCreate onSubmit={this.handleRetrieve} />
                    <PNRRetrieve onSubmit={this.handleRetrieve} />
                </div>
                <PageTitle>Tools</PageTitle>
            </div>
        );
    }
}
