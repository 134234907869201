import settings from 'airborne/settings';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import sortBy from 'lodash/sortBy';

import SelectTags from './SelectTags';
import Select from './Select';
import {injectField} from 'midoffice/newforms/decorators';

const countryChoices = createSelector(
    [
        (state, {custom})=> custom,
        ({countryCodes})=> countryCodes.hash,
    ],
    (custom, countryCodes)=> ({
        choices: custom
            ? sortBy(countryCodes, 'name')
                .map(({id, name})=> ([id, name]))
            : settings.COUNTRIES
    }),
);

export const CountrySelect = injectField(connect(countryChoices)(Select));

const CountryTags = injectField(connect(countryChoices)(SelectTags));
export default CountryTags;

