import {register} from 'airborne/types';

register('adPlacements', require('./adPlacements'));
register('adReports', require('./adReports')),
register('agency_dependencies', require('./agencyDependencies'));
register('campaigns', require('./campaigns'));
register('company_dependencies', require('./companyDependencies'));
register('countries', require('./countries'));
register('countryCodes', require('./countryCodes'));
register('languages', require('./languages'));
register('settings', require('./settings'));
register('region_dependencies', require('./regionDependencies'));
register('office_dependencies', require('./officeDependencies'));
register('reports', require('./reports'));
register('airReports', require('./airReports'));
register('aftMessaging', require('./aftMessaging'));
register('flags', require('./flags'));
register('regions', require('./regions'));
register('companies', require('./companies'));

export {getUrl, parse, parseList, serialize} from 'airborne/types';
