import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {RichUtils, EditorState} from 'draft-js';


const HEADING_TYPES = [
    {label: 'Paragraph', type: 'unstyled'},
    {label: 'Header 1', type: 'header-one'},
    {label: 'Header 2', type: 'header-two'},
    {label: 'Header 3', type: 'header-three'},
];
export default class BlockTypeSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        editorState: PropTypes.instanceOf(EditorState).isRequired,
        onChange: PropTypes.func.isRequired,
        group: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        group: true,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const type = event.target.value;
        this.props.onChange(
            RichUtils.toggleBlockType(this.props.editorState, type)
        );
    }

    render() {
        let {editorState, className, group, disabled} = this.props;

        const selection = editorState.getSelection();
        const blockType = editorState
              .getCurrentContent()
              .getBlockForKey(selection.getStartKey())
              .getType();

        className = cx('draft-select', {
            'draft-button-group': group,
            disabled
        }, className);

        return (
            <select className={className} value={blockType} onChange={this.handleChange} disabled={disabled}>
                {HEADING_TYPES.map(({label, type})=> (
                    <option key={type} value={type}>{label}</option>
                ))}
            </select>
        );
    }
}
