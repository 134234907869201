import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import api from 'midoffice/helpers/api';
import {memoize} from 'lodash';
import debounce from 'debounce-promise';

import {nopAborted, parseCompanyList} from 'airborne/homepage2/helpers/autocomplete';
import {capitalizeEveryWord} from 'airborne/helpers/strings';

function parseResult({'match_data': data}) {
    if (!Array.isArray(data) || data.length === 0) {
        return null;
    }
    return parseCompanyList(data);
}

function noCompanies(result) {
    if (result === null) {
        return gettext('No companies found');
    }
    return result;
}

function notEnough() {
    return Promise.reject(gettext('Please enter 2 or more characters'));
}

function load(method, url, data={}) {
    return api(method, url, {data, id: 'company_autocomplete'})
        .then(parseResult, nopAborted)
        .then(noCompanies);
}

function empty(extra) {
    const locale = typeof extra === 'string'
        ? extra
        : settings.USER_LOCALE;

    return load(
        'GET',
        '/hotels/api/recent-companies/',
        {locale}
    );
}
const emptyCached = memoize(empty);

const debouncedLoad = debounce(load, 200);

function findByQuery({term, system, USER_LOCALE},) {
    if (term.length < 2) {
        return notEnough();
    }

    return debouncedLoad(
        'GET',
        '/internal/entity_lookup/',
        {
            system,
            term,
            locale: USER_LOCALE,
        },
    );
}
function query(term) {
    const {SUB_PARTNER: system='aft', USER_LOCALE} = settings;

    return findByQuery({term, system, USER_LOCALE});
}

function queryByCommonChannel(term) {
    const {USER_LOCALE} = settings;

    return findByQuery({term, system: 'common', USER_LOCALE});
}

export const companyAutocompleteRecent = {
    query,
    empty: emptyCached,
    preload: emptyCached
};
export const companyAutocomplete = {query};

export const companyAutocompleteAft = {query: queryByCommonChannel};

function capitalizedResults(func) {
    return async function wrapper(...args) {
        const companiesList = await func(...args);
        return !Array.isArray(companiesList) ? companiesList : companiesList.map(item => ({
            ...item,
            label: capitalizeEveryWord(item.label)
        }));
    };
}

const capitalizedQuery = capitalizedResults(query);
const capitalizedEmpty = capitalizedResults(emptyCached);

export const companyAutocompleteRecentCapitalized = {
    query: capitalizedQuery,
    empty: capitalizedEmpty,
    preload: capitalizedEmpty
};
