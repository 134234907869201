import {LOADED, LOADING, NET_FAIL}  from './types';
import {getSettings} from 'midoffice/helpers/settings';

import listDiff from 'midoffice/helpers/listDiff';

import {fetchCollection, addEntry, changeEntry, removeEntry} from 'midoffice/data/actions/common';

function deleteAll(dispatch, endpoint, ids) {
    return ids.map((id)=> dispatch(removeEntry(endpoint, id, null, true)));
}

function addAll(dispatch, endpoint, entries) {
    return entries.map((data)=> dispatch(addEntry({endpoint, ...data}, true)));
}

function changeAll(dispatch, endpoint, entries) {
    return entries.map((data)=> dispatch(changeEntry({endpoint, data, id: data.id}, true, true)));
}

export function fetchRegions(query) {
    return function (dispatch, getState) {
        dispatch({type: LOADING, endpoint: 'inheritance', ...query});
        dispatch({type: LOADING, endpoint: 'settings', ...query});
        const {agencies} = getState();
        const [rootId] = agencies.tree.root;

        return dispatch(fetchCollection('regions', null, true)).then((regions)=> {
            const data = {regions};
            const inhData = {
                [rootId]: {all: false},
            };
            dispatch({type: LOADED, endpoint: 'settings', data, ...query});
            dispatch({type: LOADED, endpoint: 'inheritance', data: inhData, ...query});
            return data;
        },
        (response)=> {
            dispatch({type: NET_FAIL, endpoint: 'settings', response, ...query});
            dispatch({type: NET_FAIL, endpoint: 'inheritance', response, ...query});
        });
    };
}

export function saveRegions(query) {
    return function (dispatch, getState) {
        dispatch({type: LOADING, endpoint: 'inheritance', ...query});
        dispatch({type: LOADING, endpoint: 'settings', ...query});

        const {data, initial} = getSettings(getState(), query);
        const {toAdd, toChange, toDelete} = listDiff(initial.regions, data.regions);

        return Promise.all([
            ...addAll(dispatch, 'regions', toAdd),
            ...deleteAll(dispatch, 'regions', toDelete),
            ...changeAll(dispatch, 'regions', toChange),
        ]).then(()=> {
            const {regions} = getState();
            const inhData = {
                [query.id]: {all: false},
            };
            const params = {
                regions: Object.values(regions.hash),
            };
            dispatch({type: LOADED, endpoint: 'settings', data: params, ...query});
            dispatch({type: LOADED, endpoint: 'inheritance', data: inhData, ...query});
            return data;
        }, ()=> {
            dispatch({type: NET_FAIL, endpoint: 'settings', ...query});
            dispatch({type: NET_FAIL, endpoint: 'inheritance', ...query});
            throw new Error('NET FAIL');
        });
    };
}
