import api from 'midoffice/helpers/api';
import {showAlert} from 'airborne/store/modules/homepage/actions';
import gettext from 'airborne/gettext';
import {getLocale} from 'airborne/store/modules/login/helpers/login';

export function sendChangesWithoutSaveModal (endpoint, url, type, rethrow=false) {
    return function onSubmitD(dispatch, getState) {
        const value =  getState()[endpoint].value;
        const locale = getLocale();
        dispatch({type: 'LOADING', endpoint});
        return api(type, url, {data: {...value, ...locale}})
            .then((response) => {
                dispatch({type: 'LOADED', endpoint});
                return response;
            }, (response)=> {
                const errors = response.body;
                dispatch({type: 'FAILED', errors: errors || '', endpoint,});
                if (rethrow) throw response;
            });
    };
}
export function sendWithData (endpoint, url, type, additionalData, rethrow=false) {
    return function onSubmitD(dispatch, getState) {
        const value =  endpoint ? getState()[endpoint].value : {};
        dispatch({type: 'LOADING', endpoint});
        return api(type, url, {data: {...value, ...additionalData}})
            .then((response) => {
                dispatch({type: 'LOADED', endpoint});
                return response;
            }, (response)=> {
                const errors = response.body;
                dispatch({type: 'FAILED', errors: errors || '', endpoint,});
                if (rethrow) throw response;
            });
    };
}
export function sendChanges (endpoint, url, type, rethrow=false) {
    return function onSubmitD(dispatch, getState) {
        const value = getState()[endpoint].value;
        dispatch({type: 'LOADING', endpoint});
        const localUrl = url || '/accounts/api/profile/';
        const localType = type || 'PUT';

        return api(localType, localUrl, {data: value})
            .then(() => {
                dispatch({type: 'LOADED', endpoint});
                return dispatch(showAlert(gettext('Changes saved')));
            }, (response)=> {
                const errors = response.body;
                dispatch({type: 'FAILED', errors: errors || '', endpoint,});
                if (rethrow) throw response;
            });
    };
}


export function changeFormValue(endpoint, value, errors) {
    return {
        type: 'CHANGE',
        endpoint,
        value,
        errors,
    };
};
