import keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';
import findIndex from 'lodash/findIndex';


export function sortByIdsSample(array, sample, idAttribute) {
    const indexed = keyBy(array, idAttribute);
    return sample.map((id)=> indexed[id]);
}

export const listMap = memoize(function listMap(list) {
    return list.reduce((acc, [key, value])=> ({
        ...acc,
        [key]: value,
    }), {});
});

export function insertAfter(arr, predicate, element) {
    const elementPosition = findIndex(arr, predicate);

    if (arr.length <= 1 || elementPosition === arr.length - 1) {
        return [...arr, element];
    }

    const breakpointPosition = elementPosition + 1;
    const before = arr.slice(0, breakpointPosition);
    const after = arr.slice(breakpointPosition, arr.length);

    return [...before, element, ...after];
}
