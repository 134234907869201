import bedTypesActionTypes from 'midoffice/store/modules/bedTypes/actionTypes';

export default function bedTypes(state = [], action) {
    switch (action.type) {
        case bedTypesActionTypes.SET_DESCRIPTIONS:
            return {
                ...state,
                ...action.payload
            };
        case bedTypesActionTypes.SET_CATEGORIES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
