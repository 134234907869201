import noop from 'lodash/noop';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import {PlainInput} from 'midoffice/components/IE9';
import {injectField} from 'midoffice/newforms/decorators';
import {stringToFloat, stringToInt} from 'midoffice/helpers/types';
import {removeExceptFirst} from 'airborne/helpers/strings';


function isNumber(type) {
    return type === 'number' || type === 'float' || type === 'negativeFloat';
}


@injectField
export default class Input extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        inputSize: PropTypes.string,
        placeholder: PropTypes.string,
        raw: PropTypes.bool,
        readOnly: PropTypes.bool,
        type: PropTypes.string,
        value: PropTypes.any,
        precision: PropTypes.number,
        autoComplete: PropTypes.string,
        'data-testid': PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        className: 'form-control',
        type: 'text',
        placeholder: '',
        inputSize: 'max',
        disabled: false,
        raw: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop,
        onChange: noop,
        autoFocus: false
    };

    state = {
        display: null,
    };

    static getDerivedStateFromProps({type, value}, prevState) {
        if (type === 'number' || type === 'float' || type === 'negativeFloat') {
            const {display} = prevState;
            if (type === 'negativeFloat' && value === '-') {
                return {display: value};
            }

            value = parseFloat(value);

            if (display && parseFloat(display) !== value) {
                return {display: value ? (type === 'number' ? value.toFixed(2) : value) : null};
            }
        }

        return null;
    }

    focus() {
        this.refs.input.focus();
    }

    processValue(value) {
        const {type, precision} = this.props;

        if (type === 'negativeFloat' && value === '-') {
            return value;
        }

        if (['', '-'].includes(value)) { return null; }
        if (!isNumber(type)) { return value; }
        const parse = (
            type === 'number'
                ? stringToInt
                : stringToFloat
        );
        value = parse(value, precision);
        if (!isNaN(value)) { return value; }
        return isNaN(this.props.value) ? null : this.props.value;
    }

    handleChange = (event) => {
        let value = event.target.value;
        if (!this.props.raw && typeof value === 'string') {
            value = value.trimLeft();

            if (this.props.type === 'float') {
                value = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
            }

            if (this.props.type === 'negativeFloat') {
                value = value.replace(/[^\-\d.]/g, '')
                    .replace(/(\..*)\./g, '$1');

                value = removeExceptFirst(value, '-');
            }
        }
        this.setState({display: value});
        this.props.onChange(this.processValue(value), this.props.name);
    };

    handleBlur = (event) => {
        this.props.onBlur(event, this.props.name);
    };

    handleFocus = (event) => {
        this.props.onFocus(event, this.props.name);
    };

    render() {
        let {value, type, inputSize, className, autoComplete, ...props} = this.props;
        const {display} = this.state;
        if (isNumber(type) && (value == null || isNaN(value))) {
            value = '';
        }
        if ((type === 'float' || type === 'negativeFloat') && display !== null) {
            value = display;
        }
        if (isNumber(type)) {
            type = 'text';
        }

        return (
            <PlainInput {...omit(pick(props, Object.keys(Input.propTypes)), ['raw'])}
                ref="input"
                type={type}
                autoComplete={autoComplete}
                className={cx(className, `input-${inputSize}`)}
                value={(value === null) ? '' : value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus} />
        );
    }
}
