export const getAvailabilityStep = state =>
    state.airAvailability.selection;

export const getCurrentODStep = state =>
    getAvailabilityStep(state).currentOD;

export const getFlightOptionsSelection = state =>
    getAvailabilityStep(state).flightOptionsSelection;

export const getCurrentPage = state =>
    getAvailabilityStep(state).currentPage;
