import {serializeDataCollection} from 'midoffice/data/types/settings/helpers';

const SETTINGS_BY_TREE = {
    companies: 'air:preferred_airlines',
    agencies: 'agency:air:preferred_airlines',
};

export function parse(raw, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    return raw[setting];
}

export function serialize(data, {treeName}) {
    const setting = SETTINGS_BY_TREE[treeName];
    return {
        [setting]: {
            'airlines': serializeDataCollection(data['airlines']),
        }
    };
}
