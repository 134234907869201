import settings from 'airborne/settings';
import {SPECIAL_SYMBOLS_REGEX} from 'midoffice/newforms/validators/password';

const consecutiveCharactersRegex = /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789|890)+/;

export const PASSWORD_MAX_LENGTH = 64;

function checkForEmailContains(password) {
    const userEmail = settings.USER?.email;
    if (!userEmail) return;
    const [email, emailDomain] = userEmail.toLowerCase().split('@');
    const emailNoDomain = emailDomain.split('.')[0];
    const passwordLength = password.length;
    if (passwordLength >= email.length || passwordLength >=emailNoDomain.length) {
        return email.includes(password) || password.includes(email) || password.includes(emailNoDomain) || emailNoDomain.includes(password);
    }
}

function checkForDomainContains(password) {
    const siteDomain = window.location.hostname.split('.');
    return siteDomain
        .filter(domainPart => domainPart !== 'com')
        .some(domainPart => password.length >= domainPart.length && password.includes(domainPart));
}

function checkForUserInfoContains(password) {
    const userInfoKeys = ['first_name', 'middle_name', 'last_name'];
    const user = settings.USER || {};
    const userInfo = userInfoKeys.map(key => Boolean(user[key]) && user[key]?.toLowerCase());
    return userInfo.some(info => password.length >= info.length && password.includes(info));
}

function checkForConsecutiveSymbols(password) {
    const reversedPassword = password.split('').reverse().join('');
    return consecutiveCharactersRegex.test(password) || consecutiveCharactersRegex.test(reversedPassword);
}

export const passwordValidation = {
    minLength: false,
    maxLength: false,
    isLowerCase: false,
    isUpperCase: false,
    isHasSymbol: false,
    isHasNumber: false,
    repeatSymbols: false,
    hasUserInfo: false,

    checkMinLength(password) {
        return !password || password.length < settings.PASSWORD_MIN_LENGTH;
    },
    checkMaxLength(password) {
        return password.length > PASSWORD_MAX_LENGTH;
    },
    checkLowerCaseLetters(password) {
        return password === password.toUpperCase();
    },
    checkUpperCaseLetters(password) {
        return password === password.toLowerCase();
    },
    checkNumbers(password) {
        return !/[0-9]/.test(password);
    },
    checkSymbols(password) {
        return !SPECIAL_SYMBOLS_REGEX.test(password);
    },
    checkRepeat(password) {
        const normilizedPassword = password.toLowerCase();
        return /(.)\1{2,}/.test(normilizedPassword) || /(\d)\1{2,}/.test(normilizedPassword) || checkForConsecutiveSymbols(normilizedPassword);
    },
    checkForUserInfo(password) {
        const normilizedPassword = password.toLowerCase();
        return checkForEmailContains(normilizedPassword) || checkForDomainContains(normilizedPassword) || checkForUserInfoContains(normilizedPassword);
    },
    validate(password) {
        if (!password) return {};
        return Object.assign({}, {
            minLength: this.checkMinLength(password),
            maxLength: this.checkMaxLength(password),
            isLowerCase: this.checkLowerCaseLetters(password),
            isUpperCase: this.checkUpperCaseLetters(password),
            isHasNumber: this.checkNumbers(password),
            isHasSymbol: this.checkSymbols(password),
            repeatSymbols: this.checkRepeat(password),
            hasUserInfo: this.checkForUserInfo(password),
        });
    }
};

window.passwordValidation = passwordValidation;
