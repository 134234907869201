import providerCredentialsTypes from 'midoffice/store/modules/providerCredentials/actionTypes';

export default function providerCredentials(state = {}, {credentials, newCredential, type, count, id}) {
    switch (type) {
        case providerCredentialsTypes.SET_INITIAL_STATE:
            return {
                init: [...credentials],
                list: [...credentials],
                currentId: null,
            };
        case providerCredentialsTypes.SET_LIST:
            return {
                ...state,
                ...credentials,
            };
        case providerCredentialsTypes.ADD_CREDENTIALS:
            return {
                ...state,
                list: [
                    ...state.list,
                    newCredential
                ],
            };
        case providerCredentialsTypes.SET_COUNT_DEPENDENCIES:
            return {
                ...state,
                countDependencies: count,
            };
        case providerCredentialsTypes.TRAVELPORT_SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                currentId: id,
            };
        case providerCredentialsTypes.TRAVELPORT_HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                currentId: null,
            };
        case providerCredentialsTypes.TRAVELPORT_SHOW_ADD_MODAL:
            return {
                ...state,
                showAddModal: true,
                currentId: id,
            };
        case providerCredentialsTypes.TRAVELPORT_ADD_HIDE_MODAL:
            return {
                ...state,
                showAddModal: false,
                currentId: null,
            };
        case providerCredentialsTypes.RESET_FORM:
            return {
                ...state,
                list: [...state.init]
            };
        default:
            return state;
    }
}
