import cookie from 'js-cookie';
import {FEATURE_FLAGS_PREFIX} from './actions';

export const getAllFeatureFlags = state => state?.featureFlags?.flags;
export const canOverrideFeatureFlags = state => state?.featureFlags.canOverride || false;
export const getValueSwitches = state => state?.featureFlags?.valueSwitches;

function getCookiesName(name) {
    return FEATURE_FLAGS_PREFIX + name;
}

function getFeatureFlagFromCookie(name) {
    const cookieValue = cookie.get(getCookiesName(name)) || '';
    return cookieValue.toLowerCase() === 'true';
}

export function getFeatureFlag(state, flagName, {canGetFromCookie=false}={}) {
    const flags = getAllFeatureFlags(state) || window.FEATURE_FLAGS;
    return Boolean(
        flags?.[flagName]?.value
            || (canGetFromCookie && getFeatureFlagFromCookie(flagName))
    );
}

export function isFeatureFlagsLoaded(state) {
    const flags = getAllFeatureFlags(state) || window.FEATURE_FLAGS || {};

    return Object.entries(flags).length !== 0;
}
