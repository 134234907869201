import {BULK_ADDED, BULK_FAILED, BULK_LOADING} from '../actions';

function bulkLoading(bulkUploadState, action) {
    const fileName = action.fileName;
    const {request, progress=0} = action.data;
    return {
        fileName,
        request,
        progress,
        state: 'loading',
    };
}

function initialState() {
    return {};
}

export default function bulkUpload(state, action) {
    if (!state) {
        return initialState();
    }

    if (action.type === BULK_LOADING) {
        return bulkLoading(state, action);
    }

    if (action.type === BULK_ADDED) {
        return initialState();
    }

    if (action.type === BULK_FAILED) {
        return initialState();
    }

    return null;
}
