import wrap from 'midoffice/components/LazyComponent';

function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./UserSettings');
            resolve(loaded);
        }, 'usersettings');
    });
}

export const UserSettingsHandler = wrap(load);
