export function serialize(data) {
    const {
        'air:amadeus_gds:excluded_carriers': carriers,
    } = data;
    return {
        'air:amadeus_gds': {
            'excluded_carriers': carriers,
        }
    };
}

export function parse(raw) {
    const carriers =  raw['air:amadeus_gds']?.['excluded_carriers'] || [];
    return {
        'air:amadeus_gds:excluded_carriers': carriers,
    };
}
