import {combineReducers} from 'redux';
import union from 'lodash/union';
import difference from 'lodash/difference';
import {dropEmpty} from 'midoffice/helpers/urlParams';

const items = (state={}, {type, payload}) => {
    if (type === 'USERS_LOADED') {
        const {users} = payload;
        return users.reduce((acc, item) => ({...acc, [item.id]: item}), {});
    }

    return state;
};

const order = (state=[], {type, payload}) => {
    if (type === 'USERS_LOADED') {
        const {users} = payload;
        return users.map(({id}) => id);
    }
    return state;
};

const sortBy = (state='-created_ts', {type, payload}) => {
    if (type === 'CHANGE_SORTING') {
        return payload;
    }
    return state;
};

const isLoading = (state=false, {type}) => {
    if (type === 'USERS_LOADING') {
        return true;
    }

    if (type === 'USERS_LOADED' || type === 'USERS_FAILED') {
        return false;
    }
    return state;
};

const defaultPagination = {
    'page': 1,
    'page_size': 20,
    'total': 0,
    'total_pages': 0,
    'start_index': 1,
    'end_index': 20,
};
const pagination = (state=defaultPagination, {type, payload={}}) => {
    if (type === 'USERS_LOADED') {
        const {pagination} = payload;
        return pagination;
    }

    if (type === 'CHANGE_PAGINATION') {
        const {page_size, page} = payload;
        return {...state, page_size, page};
    }

    if (type === 'CHANGE_SORTING' || type === 'APPLY_USERS_FILTERS') {
        return {...state, page: 1};
    }

    return state;
};

const checked = (state=[], {type, payload}) => {
    if (type === 'CHECK_USERS') {
        return union(state, payload);
    }

    if (type === 'UNCHECK_USERS') {
        return difference(state, payload);
    }

    if (type === 'CHANGE_SORTING' || type === 'CHANGE_PAGINATION' || type === 'APPLY_USERS_FILTERS') {
        return [];
    }

    return state;
};

export const CREATE_USER_FORM_VALUES = {
    salutation: null,
    'first_name': null,
    'middle_name': null,
    'last_name': null,
    'email': null,
    'permissions': [],
    'groups': [],
    'lang_code': 'en',
    'currency_code': '...',
    'date_format': 'fr',
    'distance_units': 'KM',
    'search_radius': 5,
    'car_search_radius': 5,
    'agent_global': false,
    'allow_access_restricted_companies': false,
};

const editor = (state={value: CREATE_USER_FORM_VALUES, errors: {}}, {type, payload}) => {
    if (type === 'CHANGE_USER_EDITOR' || type === 'USER_FAILED') {
        return {...state, ...payload};
    }

    if (type === 'RESET_USER_EDITOR') {
        return {value: CREATE_USER_FORM_VALUES, errors: {}};
    }

    if (type === 'USER_LOADED' || type === 'USER_UPDATED') {
        return {...state, value: {...CREATE_USER_FORM_VALUES, ...payload.user}};
    }

    return state;
};

const isEditorLoading = (state=false, {type}) => {
    if (type === 'USER_LOADING') {
        return true;
    }
    if (type === 'USER_FAILED' || type === 'USER_LOADED') {
        return false;
    }
    return state;
};
const isEditorUpdating = (state=false, {type}) => {
    if (type === 'USER_UPDATING') {
        return true;
    }
    if (type === 'USER_UPDATED' || type === 'USER_FAILED') {
        return false;
    }
    return state;
};

const approvalActionItem = (state=null, {type, payload}) => {
    if (type === 'SET_APPROVAL_ACTION') {
        return payload;
    }

    if (type === 'CLEAR_APPROVAL_ACTION') {
        return null;
    }

    return state;
};

const approvalActionIsProcessing = (state=false, {type}) => {
    if (type === 'APPROVAL_ACTION_LOADING') {
        return true;
    }

    if (type === 'APPROVAL_ACTION_SUCCESS' || type === 'APPROVAL_ACTION_FAILURE') {
        return false;
    }

    return state;
};

const filters = (state={}, {type, payload}) => {
    if (type === 'APPLY_USERS_FILTERS') {
        return payload;
    }

    if (type === 'CLEAR_USERS_FILTERS') {
        const {name, value} = payload;

        if (!name) {
            return {};
        }

        const removeValue = values => {
            if (!Array.isArray(values)) {
                return null;
            }
            const filtered = values.filter(val => val !== value);
            return filtered.length > 0 ? filtered : null;
        };

        return dropEmpty({...state,
            [name]: value ? removeValue(state[name]) : null,
        });
    }

    return state;
};

const approvalAction = combineReducers({
    action: approvalActionItem,
    isProcessing: approvalActionIsProcessing
});

export default combineReducers({
    isLoading,
    items,
    order,
    sortBy,
    pagination,
    checked,
    editor,
    isEditorUpdating,
    isEditorLoading,
    approvalAction,
    filters,
});
