import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';

import {acceptConfirm, cancelConfirm} from 'midoffice/data/actions/confirm';

const EMPTY = {};
@connect(
    (state)=> (state.confirm && state.confirm[0] || EMPTY),
    {onOk: acceptConfirm, onCancel: cancelConfirm}
)
export default class AlertModal extends React.Component {
    static propTypes = {
        onOk: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        title: PropTypes.string,
        content: PropTypes.any,
        buttonOk: PropTypes.string,
        buttonOkClass: PropTypes.string,
        buttonCancel: PropTypes.string,
        status: PropTypes.string,
        progress: PropTypes.bool,
    };

    handleOk = () => {
        const {progress, onOk} = this.props;
        onOk(progress);
    }

    handleCancel = () => {
        const {progress, onCancel} = this.props;
        onCancel(progress);
    }

    render() {
        const {
            title,
            content,
            buttonOk = gettext('Ok'),
            buttonOkClass = 'success',
            buttonCancel = gettext('Cancel'),
            status,
        } = this.props;

        if (!status) {
            return null;
        }
        return (
            <Modal show keyboard={false} backdrop="static">
                {title ? (
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )
                    : (<div style={{display: 'none'}} />)
                }

                <Modal.Body>
                    <div className="modal-content__box modal-content__box--inner-padding">
                        {content}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" progress={status === 'canceled'} onClick={this.handleCancel} disabled={status !== 'pending'} >
                        {buttonCancel}
                    </Button>
                    <Button progress={status==='accepted'} bsStyle={buttonOkClass} disabled={status !== 'pending'} onClick={this.handleOk}>
                        {buttonOk}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
