import get from 'lodash/get';
import find from 'lodash/find';
import {intComparator} from 'midoffice/helpers/paginate';
import settings from 'airborne/settings';


export const PRICE_FIELD = 'available.min_avg_rate_usd';
export const AVAILABILITY_FIELD = 'available.is_available';

export function fxRate(currency) {
    const [rate] = find(settings.FX_RATES, ([,code])=> code === currency);
    return rate;
}

export function toUSD(price, currency) {
    if (!price || !currency) { return price; }
    return price / fxRate(currency);
}

export function fromUSD(price, currency) {
    if (!price || !currency) { return price; }
    return price * fxRate(currency);
}

export function priceOrder(field) {
    return field === PRICE_FIELD || field === `-${PRICE_FIELD}`;
}

export function priceFilter(filters) {
    if (!filters) { return false; }

    const withAvailability = filters.includes('AVAILABILITY');
    const withTravelerPolicy = filters.includes('TRAVELER_POLICY');
    return withAvailability || withTravelerPolicy;
}

export function pricePreference(preferences) {
    return Boolean(find(preferences, {name: 'rate'}));
}

export function comparePrices(a, b, reverse) {
    const priceA = get(a, PRICE_FIELD);
    const priceB = get(b, PRICE_FIELD);

    const availableA = get(a, AVAILABILITY_FIELD);
    const availableB = get(b, AVAILABILITY_FIELD);

    // Unavailable hotel is always smaller than available
    if (!availableA && availableB) { return 1; }
    if (!availableB && availableA) { return -1; }

    // Keep order or compare prices if hotels have equal availability
    return (
        reverse
            ? intComparator(priceB, priceA)
            : intComparator(priceA, priceB)
    );
}
