import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

const SUPPLIER_PREMIERINN_ALLOWANCES_KEY = 'supplier:premierinn:allowances';

export function parse(obj) {
    const {
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY]: allowances,
        ...rest
    } = obj;

    const newFields = {
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':dinner_allowance_amount']: allowances.dinner_allowance_amount,
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':dinner_allowance_currency']: allowances.dinner_allowance_currency,
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':alcohol_allowed']: allowances.alcohol_allowed,
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':parking_allowed']: allowances.parking_allowed,
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':wifi_allowed']: allowances.wifi_allowed,
        [SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':other_allowed']: allowances.other_allowed,
    };

    return {...rest, ...newFields};
}


export function serialize(obj) {
    return {
        'supplier:premierinn:enabled': obj['supplier:premierinn:enabled'],
        'supplier:premierinn:endpoint': obj['supplier:premierinn:endpoint'],
        'supplier:premierinn:username': obj['supplier:premierinn:username'],
        'supplier:premierinn:password': obj['supplier:premierinn:password'],
        'supplier:premierinn:cellcodes': obj['supplier:premierinn:cellcodes'],
        'supplier:premierinn:guarantee_strategy': obj['supplier:premierinn:guarantee_strategy'],
        'supplier:premierinn:allowances': omitBy({
            'dinner_allowance_amount': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':dinner_allowance_amount'] || 0,
            'dinner_allowance_currency': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':dinner_allowance_currency'],
            'alcohol_allowed': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':alcohol_allowed'],
            'parking_allowed': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':parking_allowed'],
            'wifi_allowed': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':wifi_allowed'],
            'other_allowed': obj[SUPPLIER_PREMIERINN_ALLOWANCES_KEY + ':other_allowed'],
        }, isNull),
    };
}
