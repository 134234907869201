import {patchCheckoutForm} from 'airborne/store/modules/checkout_air/actions/checkoutForm';
import {getTicketIndexes} from "airborne/pricing_air/helpers/separatedTickets";

export function prefillCheckout(destinationForm, isAvailabilitySearch) {
    return function prefillSearchD(dispatch) {
        const {ftNumbers} = destinationForm;

        dispatch(patchCheckoutForm(
            {toChange: {value: {ftNumbers}}})
        );

        if (isAvailabilitySearch) {
            const separatedTicketsFields = getTicketIndexes().map(ticketIndex => ({ftNumbers}));

            const ssrCodesSeparatedTickets = getTicketIndexes().map(ticketIndex => ({ssrCodes: []}))

                dispatch(
                patchCheckoutForm({
                    toChange: {
                        value: {separatedTicketsFields, ssrCodesSeparatedTickets}
                    }
                })
            );
        }
    };
}

