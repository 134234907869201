import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';

import {BookingsHandler} from 'midoffice/bookings/lazyloader';
import {CarsBookingsHandler} from 'midoffice/car-bookings/lazyloader';
import {AirBookingsHandler} from 'midoffice/air-bookings/lazyloader';
import RailBookings from 'midoffice/rail-bookings/BookingRecordsPage';
import {UserSettingsHandler} from 'airborne/user_settings2/lazyloader';
import {ExternalBookingsHandler} from 'midoffice/external-bookings/lazyloader';

class ProductTransition extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    };

    render() {
        const {location} = this.props;
        return (<Redirect to={`/bookings/hotels/${location.search}`} />);
    }
}

export default [
    <Route exact path="/bookings/" key="bookings" component={ProductTransition} />,
    <Route path="/bookings/hotels/" key="bookings-hotels" component={BookingsHandler} />,
    <Route path="/bookings/cars/" key="bookings-cars" component={CarsBookingsHandler} />,
    <Route path="/bookings/air/" key="bookings-air" component={AirBookingsHandler} />,
    <Route path="/bookings/rail/" key="bookings-rail" component={RailBookings} />,
    <Route path="/profile-settings/" key="profile-settings" component={UserSettingsHandler} />,
    <Route path="/bookings/external/" key="external" component={ExternalBookingsHandler} />
];
