'process i18n';

import defer from 'lodash/defer';
import noop from 'lodash/noop';
import flatten from 'lodash/flatten';
import chunk from 'lodash/chunk';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import gettext from 'airborne/gettext';
import format from 'midoffice/helpers/format';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {TRAVELPORT_GDS} from 'airborne/homepage2/helpers/autocomplete';


import SelectableContext from '@restart/ui/SelectableContext';
import Dropdown from 'react-bootstrap/Dropdown';
import Glyphicons from 'midoffice/components/Glyphicons';

import {PlainInput} from 'midoffice/components/IE9';

import {injectField} from 'midoffice/newforms/decorators';
import settings from 'airborne/settings';

export const PropAutocompleteItem = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    icon: PropTypes.string
});
export const PropAutocompleteItemGroup = PropTypes.shape({
    category: PropTypes.string,
    items: PropTypes.arrayOf(PropAutocompleteItem)
});

export function ensureVisible(element) {
    const isShouldScroll = element.parentElement.clientHeight < element.parentElement.scrollHeight;
    isShouldScroll && element.scrollIntoView && element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
}

function strongHighlight(text, highlight) {
    if (!highlight) {
        return text;
    }

    // Regexp symbols to shield: ()[]+.*|^$?{}\
    const regexedHighlight = highlight.replace(/\(|\)|\[|\]|\+|\.|\*|\||\^|\$|\?|\{|\}|\\/g, (p)=> `\\${p}`);
    const splitRe = new RegExp(`(${regexedHighlight})`, 'i');
    const split = text.split(splitRe);
    return chunk(split, 2).map(([regular, strong], idx)=> {
        if (!regular) {
            return (<strong key={idx}>{strong}</strong>);
        }
        return (<span key={idx}>{regular}
            {strong && (<strong>{strong}</strong>)}
        </span>);
    });
}

const CustomToggle = React.forwardRef(({className, children, onClick, ...rest}, ref) => {
    return (
        <div ref={ref} className={className} onClick={onClick} {...rest}>
            {children}
        </div>
    );
});

CustomToggle.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

const DropdownContextEditor = ({children, onSelect}) => {
    return (
        <SelectableContext.Provider value={onSelect}>
            {children}
        </SelectableContext.Provider>
    );
};

DropdownContextEditor.propTypes = {
    children: PropTypes.any,
    onSelect: PropTypes.func,
};

export class AutocompleteItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        icon: PropTypes.string,
        label: PropTypes.string,
        extra: PropTypes.string,
        highlightMatch: PropTypes.bool,
        highlight: PropTypes.string,
        itemClass: PropTypes.string,
        excludeChoices: PropTypes.array,
        originalItem: PropTypes.object,
        excludeChoicesMessage: PropTypes.string,
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    render() {
        const {
            active,
            index,
            icon,
            label,
            highlight,
            highlightMatch,
            extra,
            itemClass,
            excludeChoices,
            originalItem,
            excludeChoicesMessage
        } = this.props;

        if (excludeChoices.includes(originalItem.value)) {
            return (
                <DropdownContextEditor onSelect={noop}>
                    <Dropdown.Item eventKey={index} style={{cursor: 'default'}}>
                        {icon ? <Glyphicons glyph={icon} /> : null}
                        <s className="muted control--disabled">{strongHighlight(label, highlightMatch && highlight, true)}</s>
                        <br/>
                        <div className="small muted" style={{marginLeft: '17px'}}>{excludeChoicesMessage}</div>
                    </Dropdown.Item>
                </DropdownContextEditor>
            );
        }

        return (
            <Dropdown.Item eventKey={index} className={cx({active})}>
                <span className={itemClass}>
                    {icon ? <Glyphicons glyph={icon} /> : null}
                    {strongHighlight(label, highlightMatch && highlight)}
                    <br/>
                    <div className="muted"> {extra ? extra : null } </div>
                </span>
            </Dropdown.Item>
        );
    }
}

export class CompanyItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        label: PropTypes.string.isRequired,
        parentLabel: PropTypes.string,
        gcnId: PropTypes.string,
        gdsOid: PropTypes.string,
        gdsName: PropTypes.string,
        smid: PropTypes.string,
        tspmId: PropTypes.string,
        obtClientId: PropTypes.string,
        sapAccountNumber: PropTypes.string,
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    render() {
        const {
            active, index,
            label, parentLabel,
            smid, tspmId,
            gdsName, gdsOid,
            obtClientId, gcnId,
            sapAccountNumber,
        } = this.props;

        return (
            <Dropdown.Item key={index} eventKey={index} className={cx({active})} >
                {label}
                {parentLabel
                    ? (<span className="muted text-offset">
                        ({parentLabel})
                    </span>)
                    : null}
                <div className="dropdown-menu__sub">
                    {gdsOid && (<div className="muted">{gdsName} OID: {gdsOid} </div>)}
                    {tspmId && (<div className="muted">TSPM ID: {tspmId}</div>)}
                    {smid && (<div className="muted">SMID: {smid}</div>)}
                    {obtClientId && (<div className="muted">OBT Client ID: {obtClientId}</div>)}
                    {gcnId && (<div className="muted">GCN ID: {gcnId}</div>)}
                    {sapAccountNumber && (<div className="muted">SAP Account Number: {sapAccountNumber}</div>)}
                </div>
            </Dropdown.Item>
        );
    }
}


class DestinationItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        highlight: PropTypes.string,
        icon: PropTypes.string.isRequired,
        checkin: PropTypes.string,
        checkout: PropTypes.string,
        expired: PropTypes.bool,
        keywords: PropTypes.string,
        chains: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    render() {
        const {
            active, index,
            label, icon, type,
            highlight,
            checkin, checkout, expired,
            chains, keywords,
        } = this.props;
        const className = cx({
            active,
            'dropdown-menu__footer': type === 'search',
        });

        return (
            <Dropdown.Item key={index} eventKey={index} className={className}>
                {icon ? <Glyphicons glyph={icon} /> : null}
                {(type === 'search')
                    ? (<span>
                        {gettext('Search for ')}
                        "<strong>{label}</strong>"
                        {gettext(' as address')}
                    </span>)
                    : strongHighlight(label, highlight)
                }
                {(checkin && checkout) && (<div className={cx('dropdown-menu__dates', {expired})}>
                    {format.date(checkin)} - {format.date(checkout)}
                </div>)}
                {Boolean(chains && chains.length) && (<div className="small">
                    Chains: {chains.join(', ')}
                </div>)}
                {keywords && (<div className="small">
                    Hotel Name: {keywords}
                </div>)}
            </Dropdown.Item>
        );
    }
}


class RecentRow extends React.Component {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        children: PropTypes.any.isRequired,
    };

    render() {
        const {icon, children} = this.props;

        return (
            <div className="dropdown-menu__item">
                <div className="dropdown-menu__inline-label">
                    <Glyphicons glyph={icon} />
                </div>
                <div className="dropdown-menu__inline-value">
                    {children}
                </div>
            </div>
        );
    }
}

class AirRecentItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        oneWay: PropTypes.bool,
        expired: PropTypes.bool,
        originDestinations: PropTypes.array,
        tripType: PropTypes.oneOf([
            AIR_TRIP_TYPES.ONE_WAY,
            AIR_TRIP_TYPES.ROUND_TRIP,
            AIR_TRIP_TYPES.MULTI_CITY,
        ]),
    };

    getTripType() {
        const {tripType} = this.props;

        switch (tripType) {
            case AIR_TRIP_TYPES.ONE_WAY:
                return gettext('One way trip');
            case AIR_TRIP_TYPES.ROUND_TRIP:
                return gettext('Round trip');
            case AIR_TRIP_TYPES.MULTI_CITY:
                return gettext('Multi-city trip');
        }
    }

    getDestinations(originDestinations) {
        return originDestinations.map(({pickUp, dropOff}, index) => <Fragment key={index}>
            <strong>{pickUp.iataCode}</strong> → <strong>{dropOff.iataCode}</strong>
            {index !== originDestinations.length - 1 && ', '}
        </Fragment>);
    }

    render() {
        const {
            active,
            index,
            expired,
            originDestinations,
            tripType,
        } = this.props;
        const {dateRange: {min, max: firstMax}, pickUp} = originDestinations[0];
        const {dateRange: {min: lastMin}, dropOff} = originDestinations[originDestinations.length - 1];
        const max = tripType === AIR_TRIP_TYPES.ROUND_TRIP ? firstMax : lastMin;

        const {date_format_str: dateFormat} = settings.USER;
        const minDate = moment(min).format(dateFormat);
        const maxDate = moment(max).format(dateFormat);

        return (
            <Dropdown.Item key={index} className={cx({active})} eventKey={index}>
                <span className="dropdown-menu__wrapper">
                    <RecentRow icon={'airplane'}>
                        {tripType === AIR_TRIP_TYPES.MULTI_CITY ? this.getDestinations(originDestinations)
                            : <><strong>{pickUp.iataCode}</strong> • {pickUp.label} → <strong>{dropOff.iataCode}</strong> • {dropOff.label}</>
                        }
                    </RecentRow>
                    <RecentRow icon={'repeat'}>
                        <em className="text-gray">{this.getTripType()}</em>
                    </RecentRow>
                    <RecentRow icon={'clock'}>
                        <div className={cx('dropdown-menu__dates', {expired})}>
                            {tripType === AIR_TRIP_TYPES.ONE_WAY ? minDate : minDate + ' - ' + maxDate}
                        </div>
                    </RecentRow>
                </span>
            </Dropdown.Item>
        );
    }
}

class AirDestinationItem extends React.Component {
    static propTypes = {
        recentItem: PropTypes.bool,
        nested: PropTypes.bool,
    }

    render() {
        const {recentItem, nested} = this.props;

        const classes = cx(
            'dropdown-menu__rev',
            {'dropdown-menu__rev--nested': nested}
        );

        return (
            recentItem
                ? <AirRecentItem {...this.props} />
                : <AutocompleteItem
                    {...this.props}
                    itemClass={classes}
                />
        );
    }
}

class CarsDestinationItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        highlight: PropTypes.string,
        icon: PropTypes.string.isRequired,
        dropOff: PropTypes.object,
        differentDropOff: PropTypes.bool,
        dates: PropTypes.object.isRequired,
        recentItem: PropTypes.bool.isRequired,
        expired: PropTypes.bool,
        schemaParams: PropTypes.object,
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    renderDates({min, max}, expired) {
        const USER_DATETIME_FORMAT = `${settings.USER.date_format_str} ${settings.TIME_FORMAT}`;

        const minStr = moment(min).format(USER_DATETIME_FORMAT);
        const maxStr = moment(max).format(USER_DATETIME_FORMAT);
        const datesStr = `${minStr} → ${maxStr}`;

        return expired ? <s className="text-muted">{datesStr}</s> : datesStr;
    }

    renderRecent() {
        const {active, index, label, icon,
            differentDropOff, dropOff, dates} = this.props;

        return (
            <Dropdown.Item key={index} className={cx({active})} eventKey={index}>
                <span className="dropdown-menu__wrapper">
                    <RecentRow icon={icon}>{label}</RecentRow>
                    {differentDropOff ? (
                        <RecentRow icon={dropOff.icon}>{dropOff.label}</RecentRow>
                    ) : (
                        <RecentRow icon="repeat"><em className="text-gray">Round trip</em></RecentRow>
                    )}
                    <RecentRow icon="time">{this.renderDates(dates)}</RecentRow>
                </span>
            </Dropdown.Item>
        );
    }

    renderNoAddressSearchAvailable(address) {
        return (
            <Dropdown.Item>
                <span>
                    {gettext('Search for ')}
                     "<strong>{address}</strong>"
                    {gettext(' as address is not available')}
                </span>
            </Dropdown.Item>
        );
    }

    render() {
        const {
            active, index,
            recentItem,
            label, highlight,
            icon, type, schemaParams
        } = this.props;

        const className = cx({
            active,
            'dropdown-menu__footer': type === 'search',
        });

        const isTravelPortGds = TRAVELPORT_GDS.includes(schemaParams?.gds);

        if (recentItem) { return this.renderRecent(); }
        if (isTravelPortGds && type === 'search') return this.renderNoAddressSearchAvailable(label);

        return (
            <Dropdown.Item key={index} eventKey={index} className={className}>
                {icon ? <Glyphicons glyph={icon} /> : null}
                {(type === 'search')
                    ? (<span>
                        {gettext('Search for ')}
                        "<strong>{label}</strong>"
                        {gettext(' as address')}
                    </span>)
                    : strongHighlight(label, highlight)
                }
            </Dropdown.Item>
        );
    }
}


class TravelerItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        user: PropTypes.string.isRequired,
        myself: PropTypes.bool,
        highlight: PropTypes.string,
        company: PropTypes.string,
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    render() {
        const {
            active, index,
            user, company, myself,
            highlight,
        } = this.props;

        return (
            <Dropdown.Item key={index} eventKey={index} className={cx({active})}>
                {myself && (<span><strong>[ME]</strong>&nbsp;</span>)}
                {strongHighlight(user, highlight)}
                &nbsp;
                ({company})
            </Dropdown.Item>
        );
    }
}

class ChainItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        parentValue: PropTypes.string.isRequired,
        highlight: PropTypes.string,
        company: PropTypes.string,
    };

    componentDidUpdate(prev) {
        const {active} = this.props;
        if (active && !prev.active) {
            ensureVisible(ReactDOM.findDOMNode(this));
        }
    }

    render() {
        const {
            active, index,
            value, label, parentValue,
            highlight,
        } = this.props;
        return (
            <Dropdown.Item key={index} eventKey={index} className={cx({active})}>
                <span className={cx('dropdown__code', {'dropdown__code--parent': value === parentValue})}>
                    {value}
                </span>
                {strongHighlight(label, highlight)}
            </Dropdown.Item>
        );
    }
}

class AgencyItem extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        label: PropTypes.string,
        agencyType: PropTypes.string,
        countryName: PropTypes.string,
    };

    render() {
        const {index, active, label, agencyType, countryName} = this.props;
        return (
            <Dropdown.Item
                key={index}
                eventKey={index}
                className={cx({active})}
            >
                <strong>{label}</strong> {countryName && `(${countryName})`}
                <br />
                <div className="muted">
                    <span className="text-sm">
                        {agencyType}
                    </span>
                </div>
            </Dropdown.Item>
        );
    }
}

class AutocompleteDropdown extends React.Component {

    static propTypes = {
        selectedItem: PropTypes.number,
        withCompanyInfo: PropTypes.bool,
        withDestinationInfo: PropTypes.bool,
        withCarDestinationInfo: PropTypes.bool,
        withAirDestinationInfo: PropTypes.bool,
        withTravelerInfo: PropTypes.bool,
        withChainInfo: PropTypes.bool,
        withAgencyInfo: PropTypes.bool,
        items: PropTypes.oneOfType([
            PropTypes.arrayOf(PropAutocompleteItem),
            PropTypes.arrayOf(PropAutocompleteItemGroup),
            PropTypes.string
        ]),
        highlight: PropTypes.string,
        highlightMatch: PropTypes.bool,
        show: PropTypes.bool,
        rootCloseEvent: PropTypes.string,
        schemaParams: PropTypes.object,
        excludeChoices: PropTypes.array,
        dropdownTestId: PropTypes.string,
    };

    static defaultProps = {
        withCompanyInfo: false,
        withDestinationInfo: false,
        withCarDestinationInfo: false,
        withAirDestinationInfo: false,
        withTravelerInfo: false,
        withChainInfo: false,
        withAgencyInfo: false
    };

    renderItem(item, index) {
        const active = this.props.selectedItem === index;
        const {
            highlight,
            highlightMatch,
            withCompanyInfo,
            withDestinationInfo,
            withCarDestinationInfo,
            withAirDestinationInfo,
            withTravelerInfo,
            withChainInfo,
            withAgencyInfo,
            schemaParams,
            excludeChoices,
            excludeChoicesMessage,
        } = this.props;

        const ItemWidget =
            (withCompanyInfo && CompanyItem) ||
            (withDestinationInfo && DestinationItem) ||
            (withTravelerInfo && TravelerItem) ||
            (withChainInfo && ChainItem) ||
            (withCarDestinationInfo && CarsDestinationItem) ||
            (withAirDestinationInfo && AirDestinationItem) ||
            (withAgencyInfo && AgencyItem) ||
            AutocompleteItem;

        const props = {
            active,
            index,
            key: index,
            highlight,
            highlightMatch,
            schemaParams,
            excludeChoices: excludeChoices.map(item => item.value),
            excludeChoicesMessage,
        };

        return (<ItemWidget {...item} {...props} originalItem={item} />);
    }

    render() {
        const {withCompanyInfo, items, show, rootCloseEvent, dropdownTestId} = this.props;
        const cls = cx({
            'dropdown-menu--wide': withCompanyInfo,
        });

        if (typeof items == 'string') {
            return (
                <Dropdown.Menu show={show} rootCloseEvent={rootCloseEvent} data-testid={dropdownTestId}>
                    <DropdownContextEditor onSelect={noop}>
                        <Dropdown.Item>{items}</Dropdown.Item>
                    </DropdownContextEditor>
                </Dropdown.Menu>
            );
        }

        if (Array.isArray(items)) {
            const options = [];
            let offset = 0;

            // eslint-disable-next-line no-unused-vars
            for (let group of items) {
                if (group.hasOwnProperty('items')) {
                    if (group.category) {
                        options.push(<Dropdown.Header key={group.id || group.category}>{group.category}</Dropdown.Header>);
                    }
                    for (let index = 0; index < group.items.length; index++) {
                        let item = group.items[index];
                        options.push(this.renderItem(item, offset + index));
                    }
                    offset += group.items.length;
                }
                else {
                    options.push(this.renderItem(group, offset));
                    offset += 1;
                }
            }

            return (
                <Dropdown.Menu show={show} rootCloseEvent={rootCloseEvent} className={cls} data-testid={dropdownTestId}>
                    {options}
                </Dropdown.Menu>
            );
        }

        return (<span className="hide" />);
    }
}


@injectField
export default class Autocomplete extends React.Component {

    static propTypes = {
        value: PropTypes.any,
        placeholder: PropTypes.string,
        name: PropTypes.string,
        commitText: PropTypes.bool,
        testId: PropTypes.string,
        dropdownTestId: PropTypes.string,

        autocompleteSource: PropTypes.shape({
            query: PropTypes.func.isRequired
        }),
        autocompleteExtra: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array
        ]),
        queryMatchFn: PropTypes.func,

        className: PropTypes.string,
        id: PropTypes.string,
        inputClassName: PropTypes.string,
        inputSize: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        clearable: PropTypes.bool,
        withCompanyInfo: PropTypes.bool,
        withDestinationInfo: PropTypes.bool,
        withCarDestinationInfo: PropTypes.bool,
        withAirDestinationInfo: PropTypes.bool,
        withTravelerInfo: PropTypes.bool,
        withChainInfo: PropTypes.bool,
        withAgencyInfo: PropTypes.bool,
        highlightMatch: PropTypes.bool,
        excludeChoices: PropTypes.array,
        excludeChoicesMessage: PropTypes.string,

        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onCommit: PropTypes.func,
        onKeyDown: PropTypes.func,

        prepend: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        append: PropTypes.element,
        schemaParams: PropTypes.object,

        autoSearch: PropTypes.bool,
        autoSearchValue: PropTypes.string,
        autoSearchValueType: PropTypes.string,

        startLoadFn: PropTypes.func,
        finishLoadFn: PropTypes.func,
    }

    static defaultProps = {
        clearable: false,
        value: '',
        className: 'tags-input',
        inputClassName: null,
        inputSize: 'max',
        disabled: false,
        commitText: false,
        highlightMatch: false,
        withCompanyInfo: false,
        withDestinationInfo: false,
        withCarDestinationInfo: false,
        withAirDestinationInfo: false,
        withTravelerInfo: false,
        withChainInfo: false,
        withAgencyInfo: false,
        placeholder: '',
        autocompleteExtra: {},
        excludeChoices: [],

        onFocus: noop,
        onBlur: noop,
        onCommit: noop,
        onKeyDown: noop,

        startLoadF: noop,
        finishLoadFn: noop,
    };

    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }

    state = {
        options: null,
        selectedOption: null,
        focused: false,
        open: false,
        value: '',
        queryLoading: false,
    };

    componentDidMount () {
        const {autocompleteSource, autocompleteExtra} = this.props;

        if (autocompleteSource && autocompleteSource.preload) {
            autocompleteSource.preload(autocompleteExtra);
        }

        this.autoSearch();
    }

    queryPromise = Promise.resolve(null);

    autoSearch() {
        const {autoSearch, startLoadFn, autoSearchValue, finishLoadFn} = this.props;

        if (autoSearch) {
            startLoadFn();

            this.loadQuery(autoSearchValue);
            this.autoCommit(this.state.selectedOption)
                .then(() => {
                    finishLoadFn();
                });
        }
    }

    parseOptions(options) {
        if (Array.isArray(options)) {
            return flatten(options.map((item)=>
                item.hasOwnProperty('items') ? item.items : item));
        }
    }

    getOptions() {
        return this.parseOptions(this.state.options);
    }

    getValue() {
        if (this.props.value) {
            return this.props.value.trim();
        }
        return this.props.value;
    }

    getSelected(index) {
        if (!this.props.autocompleteSource) {
            return this.getValue();
        }

        const {selectedOption} = this.state;
        const options = this.getOptions();

        if (typeof options == 'string') {
            return null;
        }

        if (index === null) {
            index = selectedOption;
        }

        if (Array.isArray(options) && options.length) {
            return options[index];
        }

        return null;
    }

    getHeads(options) {
        const defaultHead = [0];
        if (options[0]?.head) {
            return options.reduce((acc, curr, index) => {
                const {head} = curr;

                return head && index ? [...acc, index] : acc;
            }, [...defaultHead]);
        }

        const acc = {heads: []};
        options?.map((option, index) => {
            if (!acc[option.autocompleteCategory]) {
                acc[option.autocompleteCategory] = true;
                acc.heads.push(index);
            }
        });

        return isEmpty(acc.heads) ? [...defaultHead] : acc.heads;
    }


    focusOption(dir) {
        let selectedOption = this.state.selectedOption || 0;
        let options = this.getOptions();
        if (!Array.isArray(options) || !options.length) { return; }

        const heads = this.getHeads(options);
        if (dir === 'prev') {
            selectedOption = Math.max(0, selectedOption - 1);
        }
        else if (dir === 'next') {
            selectedOption = Math.min(options.length - 1, selectedOption + 1);
        }
        else if (dir === 'prevHead') {
            const underHeads = heads.filter(head => head < selectedOption);
            selectedOption = underHeads.length ? underHeads[underHeads.length - 1] : heads[heads.length - 1];
        }
        else if (dir === 'nextHead') {
            selectedOption = heads.find(head => head > selectedOption) || heads[0];
        }

        this.setState({selectedOption});
    }

    focus() {
        let node = this.textInput.current;
        if (!node.focus) { node = ReactDOM.findDOMNode(node); }
        node.focus();
    }

    commitIfAny(index=null) {
        const value = this.getSelected(index);
        if (value) {
            this.commitValue(value);
        }
    }

    async autoCommit(index = null, traceCallFromTab = false) {
        const autoValue = await this.queryPromise;
        const selectedValue = this.getSelected(index);
        const value = selectedValue ? selectedValue : traceCallFromTab ? (!index ? null : autoValue) : autoValue;

        if (value) {
            this.commitValue(value);
        }

        return Promise.resolve();
    }

    commit(index=null) {
        const value = this.getSelected(index);
        this.commitValue(value);
    }

    commitValue(value) {
        if (this.props.autocompleteSource) {
            this.setState({options: null, selectedOption: null});
        }

        if (this.props.commitText) {
            if (value) {
                this.props.onChange(value && value.label);
            }
        }
        else {
            this.props.onCommit(value);
        }
    }

    loadEmpty() {
        const {autocompleteSource, autocompleteExtra} = this.props;

        if (!autocompleteSource || !autocompleteSource.empty) {
            return;
        }

        autocompleteSource.empty(autocompleteExtra)
            .then(
                (options)=> {
                    this.setState({options, selectedOption: 0});
                },
                this.handleError,
            );
    }

    loadQuery(value) {
        const {
            autocompleteSource,
            autocompleteExtra,
            queryMatchFn,
        } = this.props;

        this.setState({queryLoading: true});

        this.queryPromise = new Promise((resolve) => {
            autocompleteSource.query(value, autocompleteExtra)
                .then(
                    (options)=> {
                        const firstMatch = findIndex(options, (el)=> !el.skip);
                        const selectedOption = firstMatch > 0 ? firstMatch : 0;
                        const parsedOptions = this.parseOptions(options);
                        this.setState({queryLoading: false}, () => {
                            resolve(
                                !queryMatchFn
                                    ? parsedOptions?.[selectedOption]
                                    : queryMatchFn(value, parsedOptions)
                            );
                        });

                        if (this.state.focused && this.state.value.length) {
                            this.setState({options, open: true, selectedOption});
                        }
                    },
                    this.handleError,
                );
        });
    }

    handleError = (error)=> {
        // Ignore aborted requests
        if (error && error.ignore === true) {
            return;
        }
        this.setState({options: error});
    };

    handleInputChange = (event)=> {
        const value = event.target.value;
        const {autocompleteSource} = this.props;
        this.setState({value});

        if (autocompleteSource && value.length) {
            if (this.state.options === null) {
                this.setState({options: gettext('Searching…')});
            }
            this.loadQuery(value);
        }
        else if (autocompleteSource && !value.length) {
            this.setState({options: null});
            this.loadEmpty();
        }
        else {
            this.setState({options: null});
        }

        this.props.onChange(value);
    };

    handleInputDown = ()=> {
        this._insideClick = true; // eslint-disable-line immutable/no-mutation
        defer(()=> {
            this._insideClick = false; // eslint-disable-line immutable/no-mutation
        });
    };

    handleInputClick = (event)=> {
        if (!event.isDefaultPrevented()) {
            this.focus();
        }
        this._insideClick = false; // eslint-disable-line immutable/no-mutation
    };

    handleKeyDown = (event)=> {
        event.stopPropagation();
        switch (event.key) {
            case 'Tab':
                this.autoCommit(this.state.selectedOption, true);
                return;
            case 'Enter':
                event.preventDefault();
                this.commitIfAny(this.state.selectedOption);
                return;
            case 'ArrowUp':
                event.preventDefault();
                if (event.shiftKey) {
                    return this.focusOption('prevHead');
                }
                return this.focusOption('prev');
            case 'ArrowDown':
                event.preventDefault();
                if (event.shiftKey) {
                    return this.focusOption('nextHead');
                }
                return this.focusOption('next');
        }
        this.props.onKeyDown(event);
    };

    handleFocus = ()=> {
        this.setState({focused: true});
        this.props.onFocus();
    };

    handleBlur = ()=> {
        if (!this._insideClick) {
            const {queryLoading, selectedOption} = this.state;
            if (queryLoading) {
                this.autoCommit(selectedOption);
            }

            this.setState({focused: false, open: false});
            this.props.onBlur();
        }
        else {
            this.focus();
        }

        this._insideClick = false; // eslint-disable-line immutable/no-mutation
    };

    handleClear = ()=> {
        this.props.onChange(null);
        this.setState({options: null});
    };

    handleDropdownClick = (key)=> {
        this.commit(key);
    };

    handleToggle = (open)=> {
        const {value} = this.props;
        const {options} = this.state;

        this.setState({open});

        if (open && !value && !options) {
            this.loadEmpty();
        }

        if (open && value) {
            this.loadQuery(value);
        }
    };

    renderClearButton() {
        if (!(this.props.clearable && this.props.value)) {
            return null;
        }

        return (
            <span onClick={this.handleClear} className="input-group__clear">
                <Glyphicons bsClass="glyphicon" glyph="remove" />
            </span>
        );
    }

    render() {
        let {
            value,
            disabled,
            placeholder,
            name,
            readOnly,
            inputSize,
            withCompanyInfo,
            withDestinationInfo,
            withCarDestinationInfo,
            withAirDestinationInfo,
            withTravelerInfo,
            withChainInfo,
            withAgencyInfo,
            highlightMatch,
            id,
            schemaParams,
            excludeChoices,
            excludeChoicesMessage,
            testId,
            dropdownTestId,
        } = this.props;
        let {focused, open, selectedOption} = this.state;

        let className = cx(
            'dropdown',
            this.props.className,
            `input-${inputSize}`,
            {
                disabled,
                focused,
            }
        );

        let inputClassName = cx('borderless', this.props.inputClassName);

        if ((value && value.length) || focused) {
            placeholder = null;
        }

        return (
            <Dropdown
                className={className}
                show={open}
                onMouseDown={this.handleInputDown}
                onClick={this.handleInputClick}
                onToggle={this.handleToggle}
                onSelect={this.handleDropdownClick}
            >
                {this.props.prepend}
                <Dropdown.Toggle
                    id="autocomplete"
                    as={CustomToggle}
                    className="input-group__inline-control"
                >
                    <PlainInput
                        type="text"
                        className={inputClassName}
                        id={id}
                        ref={this.textInput}
                        value={value || ''}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        name={name}
                        disabled={disabled}
                        onChange={this.handleInputChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        autoComplete="off"
                        data-testid={testId}
                        onKeyDown={this.handleKeyDown}
                    />
                    {this.renderClearButton()}
                </Dropdown.Toggle>
                <AutocompleteDropdown
                    items={this.state.options}
                    highlight={value}
                    highlightMatch={highlightMatch}
                    withCompanyInfo={withCompanyInfo}
                    withDestinationInfo={withDestinationInfo}
                    withCarDestinationInfo={withCarDestinationInfo}
                    withAirDestinationInfo={withAirDestinationInfo}
                    withTravelerInfo={withTravelerInfo}
                    withChainInfo={withChainInfo}
                    withAgencyInfo={withAgencyInfo}
                    selectedItem={selectedOption}
                    schemaParams={schemaParams}
                    excludeChoices={excludeChoices}
                    excludeChoicesMessage={excludeChoicesMessage}
                    dropdownTestId={dropdownTestId}
                />
                {this.props.append}
            </Dropdown>
        );
    }
}
