/*eslint-disable camelcase*/

import flow from 'lodash/flow';

function parseStarRating(data) {
    const starRating = data['star_rating'];
    return flow(
        starRating => Object.entries(starRating),
        ratingKeyValues =>
            ratingKeyValues.map(keyVal => [
                `star_rating_${keyVal[0]}`,
                keyVal[1],
            ]),
        mappedRating => Object.fromEntries(mappedRating)
    )(starRating);
}

function parsePreferences(data) {
    const preferences = data['preferences'];
    return flow(
        preferences => Object.entries(preferences),
        preferencesKeyValues =>
            preferencesKeyValues.map(keyVal => [
                `preference_${keyVal[0]}`,
                keyVal[1],
            ]),
        mappedRating => Object.fromEntries(mappedRating)
    )(preferences);
}

function parsePropertyType(data) {
    const propertyTypes = data['property_type'];
    if (!propertyTypes) {
        return [];
    }
    return Object.keys(propertyTypes).map(propertyType => ({
        type: propertyType,
        typeValue: propertyTypes[propertyType],
    }));
}

export function parse(parseData) {
    const data = parseData['sorters:dynamic'];
    const dataCopy = {...data};
    const parsedStarRating = parseStarRating(data);
    const parsedPreferences = parsePreferences(data);
    const parsedProperties = parsePropertyType(data);
    delete dataCopy['preferences'];
    delete dataCopy['star_rating'];
    return {
        ...dataCopy,
        ...parsedPreferences,
        ...parsedStarRating,
        property_type: parsedProperties,
    };
}

function serializePreference(data) {
    return flow(
        preferenceData =>
            Object.keys(preferenceData).filter(key =>
                key.startsWith('preference')
            ),
        dataKeys => dataKeys.map((key, index) => [index, data[key]]),
        dataToObject => Object.fromEntries(dataToObject)
    )(data);
}

function serializeStarRating(data) {
    return flow(
        preferenceData =>
            Object.keys(preferenceData).filter(key =>
                key.startsWith('star_rating_')
            ),
        dataKeys => dataKeys.filter(key => key !== 'star_rating_weight'),
        dataKeys => dataKeys.map(key => [key.split('_').pop(), data[key]]),
        dataToObject => Object.fromEntries(dataToObject)
    )(data);
}

function serializePropertyType(data) {
    const propertyTypes = data['property_type'];
    if (!propertyTypes) {
        return null;
    }
    return Object.fromEntries(
        propertyTypes.map(propertyType => [
            propertyType.type,
            propertyType.typeValue,
        ])
    );
}

export function serialize(serializeData) {
    const data = serializeData;
    const propertyType = serializePropertyType(data);
    return {
        'sorters:dynamic': {
            distance_weight: data['distance_weight'],
            property_type_weight: data['property_type_weight'],
            star_rating_weight: data['star_rating_weight'],
            preferred_weight: data['preferred_weight'],
            bcd_score_weight: data['bcd_score_weight'],
            price_multiplier: data['price_multiplier'],
            not_preferred: data['not_preferred'],
            preferred_chain: data['preferred_chain'],
            preferences: {...serializePreference(data)},
            star_rating: {...serializeStarRating(data)},
            ...(propertyType ? {property_type: propertyType} : {}),
        },
    };
}
