// shared components with airborne
import Row from 'react-bootstrap/Row';
import Button from 'midoffice/components/Button';

import AcrissField from 'airborne/homepage2/Acriss';
import DestinationSelect from 'airborne/homepage2/DestinationSelect';
import IdNumbers, {AddMore} from 'airborne/homepage2/IdNumbers';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import Select from 'midoffice/newforms/widgets/Select';
import CheckinRange from 'airborne/homepage2/CheckinRange';

import HotelDestinationAutocomplete from 'airborne/homepage2/autocomplete/HotelDestinationAutocomplete';

import {PRODUCT_TYPES, setBroadStreetTargets} from 'airborne/helpers/setBroadStreetTargets';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';

import air from 'airborne/store/modules/search_hotels/actions/air';

import {createSchema, required, combineValidators, noErrors} from 'midoffice/newforms/helpers';
import {
    NumberField,
    Field,
    CharField,
    BooleanField,
    ArrayField,
    DateTimeRange,
    UpperCaseField,
} from 'midoffice/newforms/fields-stateless';
import {Schema} from 'midoffice/newforms/schema-stateless';
import {AlphanumericField} from 'midoffice/newforms/fields-stateless';

import {isAcriss, isPseudo} from 'airborne/homepage2/helpers/acriss';
import {getDateLimits} from 'airborne/homepage2/helpers/dateLimits';

import {changeDestination, reuseDestination} from 'airborne/store/modules/homepage/actions';
import searchCars from 'airborne/store/modules/search_cars/actions/search';
import {
    carsDestinationLoaded,
    carsDestinationLoading,
    parseCarBookingValue,
    modifyCarBookingFlowQuit
} from 'midoffice/car-bookings/modify/modifyActions';

import {getCarsDistanceChoices} from 'airborne/homepage2/helpers/searchDistance';

import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {isRatesLoading} from 'airborne/store/modules/search_cars/selectors';


export default {
    components: {
        Row,
        Button,
        AcrissField,
        DestinationSelect,
        IdNumbers,
        AddMore,
        Checkbox,
        Select,
        CheckinRange,
        Shortcut,
        HotelDestinationAutocomplete,
        NumberField,
        Field,
        CharField,
        BooleanField,
        ArrayField,
        DateTimeRange,
        UpperCaseField,
        Schema,
        AlphanumericField,
    },
    utils: {
        SHORTCUTS_LIST,
        PRODUCT_TYPES,
        setBroadStreetTargets,
        air,
        isAcriss,
        isPseudo,
        getDateLimits,
        createSchema,
        required,
        combineValidators,
        noErrors,
    },
    actions: {
        carsDestinationLoaded,
        carsDestinationLoading,
        parseCarBookingValue,
        modifyCarBookingFlowQuit,
        changeDestination,
        reuseDestination,
        getSelectedConfiguration,
        searchCars,
        getCarsDistanceChoices,
        isRatesLoading,

    }
};
