import {ALERT_SHOW, ALERT_HIDE} from 'midoffice/data/actions/types';

function getEmptyState() {
    return {
        messages: [],
        closeTimeout: null
    };
}

export default function alert(state, {type, messages, closeTimeout}) {

    if (!state) {
        return getEmptyState();
    }

    if (type === ALERT_SHOW) {
        return {
            messages: state.messages.concat(messages),
            closeTimeout,
        };
    }

    if (type === ALERT_HIDE) {
        if (!state.messages.length) {
            return getEmptyState();
        }

        return {
            messages: state.messages.slice(1),
            closeTimeout: state.closeTimeout,
        };
    }

    return state;
}
