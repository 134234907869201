import omit from 'lodash/omit';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import zipObject from 'lodash/zipObject';
import constant from 'lodash/constant';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
    CATEGORIES,
    TYPES,
    TRANSMISSIONS,
    FUELS,
    expandAcriss,
    prepareAcrissLabel
} from 'airborne/homepage2/helpers/acriss';
import {default as applyFiltersHelper} from 'midoffice/helpers/filters';


function acrissToRegexp(acriss) {
    return new RegExp(`^${acriss.replace(/\*/g, '.')}$`, 'i');
}

function convertFilters({vendor, preference, rateType, mileageType, vendorLocationCode, price={}, acriss}) {
    return [
        {field: 'vendor.name', op: 'in', value: vendor || null},
        {field: 'preferredBy', op: 'intersect', value: preference || null},
        {field: 'rateType', op: 'intersect', value: rateType || null},
        {field: 'mileageType', op: 'in', value: mileageType || null},
        {field: 'vendorLocationCode', op: 'eq', value: vendorLocationCode || null},
        {field: 'total', op: 'gte', value: price.min || null, matchNull: true},
        {field: 'total', op: 'lte', value: price.max || null, matchNull: true},
        {field: 'car.type', op: 'match', value: acriss ? acrissToRegexp(acriss) : null},
    ].filter(({value})=> value !== null);
}

export function applyFilters(rates, filters) {
    return applyFiltersHelper(rates, convertFilters(filters));
}

function countMatching(rates, filters, fn, except, ...args) {
    return fn(applyFilters(rates, omit(filters, except)), ...args);
}

function countValues(rates, key, initial) {
    return rates.reduce(function(acc, rate) {
        const value = get(rate, key);
        (Array.isArray(value) ? value : [value]).forEach((item)=> {
            acc[item] = acc[item] + 1;  // eslint-disable-line immutable/no-mutation
        });
        return acc;
    }, initial);
}

function countVendors(rates, vendors) {
    const initial = zipObject(vendors, map(vendors, constant(0)));
    return countValues(rates, 'vendor.name', initial);
}

function countPreference(rates) {
    return countValues(rates, 'preferredBy', {
        carType: 0,
        bcdCarType: 0,
        vendor: 0,
        bcdVendor: 0,
    });
}

function countRateType(rates) {
    return countValues(rates, 'rateType', {pub: 0, id: 0, cd: 0, bcdCd: 0, it: 0});
}

function countMileageType(rates) {
    return countValues(rates, 'mileageType', {limited: 0, unlimited: 0});
}

function priceLimits(rates) {
    if (isEmpty(rates)) {
        return {min: -Infinity, max: Infinity};
    }

    return rates.reduce(function (acc, rate) {
        const {total} = rate;
        return {
            min: Math.min(acc.min, total),
            max: Math.max(acc.max, total),
        };
    }, {min: Infinity, max: -Infinity});
}

function getAcrissAttributesChoices(rates) {
    function toChoices(values, labels) {
        const withWildcard = values.size === 1 ? Array.from(values) : ['*', ...values];
        return withWildcard.sort().map((value)=> [value, prepareAcrissLabel(value, labels)]);
    }

    const acrissCategoriesValues = rates.reduce(function (acc, rate) {
        Object.entries(expandAcriss(rate.car.type)).forEach(([attribute, value]) => {
            acc[attribute].add(value);
        });
        return acc;
    }, {category: new Set(), type: new Set(), transmission: new Set(), fuel: new Set()});

    return {
        category: toChoices(acrissCategoriesValues.category, CATEGORIES),
        type: toChoices(acrissCategoriesValues.type, TYPES),
        transmission: toChoices(acrissCategoriesValues.transmission, TRANSMISSIONS),
        fuel: toChoices(acrissCategoriesValues.fuel, FUELS),
    };
}

export function filterCounters(rates, filters) {
    const vendors = uniq(map(rates, 'vendor.name'));

    const prices = countMatching(rates, filters, priceLimits, 'price');
    const vendorCount = countMatching(rates, filters, countVendors, 'vendor', vendors);
    const prefCount = countMatching(rates, filters, countPreference, 'preference');
    const rateTypeCount = countMatching(rates, filters, countRateType, 'rateType');
    const mileageTypeCount = countMatching(rates, filters, countMileageType, 'mileageType');
    const acrissAttributesChoices = countMatching(rates, filters, getAcrissAttributesChoices, 'acriss');

    return {vendorCount, prefCount, rateTypeCount, mileageTypeCount, acrissAttributesChoices, prices};
}


export function isFiltered(filters) {
    return !isEmpty(convertFilters(filters));
}
