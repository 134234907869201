import isArray from 'lodash/isArray';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import classnames from 'classnames';

import {hideAlert} from 'midoffice/data/actions/modals';
import Timer from 'midoffice/lib/Timer';

function error(text) {
    return (text && text.length)
        ? text
        : gettext('We\'re sorry, our system has encountered an error. Please try again in a few minutes.');

}

class AlertModal extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.oneOf([
                    'success',
                    'danger',
                    'error',
                    'info',
                    null
                ]),
                html: PropTypes.any,
            })
        ),
        closeTimeout: PropTypes.number,
    };

    static defaultProps = {
        closeTimeout: 5
    };

    componentDidMount() {
        this.startTimter(this.props);
    }

    componentDidUpdate() {
        this.startTimter(this.props);
    }

    startTimter({messages, closeTimeout}) {
        if (this.timer || !messages.length || !closeTimeout) {
            return;
        }

        this.timer = new Timer(()=> { // eslint-disable-line immutable/no-mutation
            this.handleHide();
        }, closeTimeout * 1000);
    }

    resumeTimer = ()=> {
        this.timer && this.timer.resume();
    };

    pauseTimer = ()=> {
        this.timer && this.timer.pause();
    };

    handleHide = ()=> {
        this.pauseTimer();
        delete this.timer;
        this.props.onHide();
    };

    render() {
        const {messages} = this.props;

        if (!messages.length) {
            return null;
        }

        const {type, html} = messages[0];
        const className = classnames('alert', 'alert-overlay', `alert-${type}`);

        return (
            <div className={className}
                 onMouseOver={this.pauseTimer}
                 onMouseOut={this.resumeTimer}
                 onClick={this.handleHide} >
                {(isArray(html) && html.length > 1)
                    ? html.map((message, index)=> (<div key={index}>{error(message)}</div>))
                    : error(html)}
            </div>
        );
    }
}


export {AlertModal};
export default connect((state)=> state.alert, {onHide: hideAlert})(AlertModal);
