import get from 'lodash/get';
import every from 'lodash/every';
import {getItemByIndex} from 'airborne/homepage2/helpers/multipnr';


export function getTspm(state, index) {
    return getItemByIndex(state.tspms, index);
}

export function isTravelersLoading(state, index) {
    const tspm = getTspm(state, index);
    return get(tspm, 'travelers.loading');
}

export function isCompaniesLoading(state, index) {
    const tspm = getTspm(state, index);
    return get(tspm, 'companies.loading');
}

export function isSuggestedCompaniesLoading(state, index) {
    const tspm = getTspm(state, index);
    return get(tspm, 'companies.loading');
}

export function getTravelers(state, index) {
    const {results, data, suggested} = getTspm(state, index).travelers;
    return results ? data : suggested;
}

export function hasResults(state, index) {
    return get(getTspm(state, index), 'travelers.results');
}

export function noTravelersFound(state, index) {
    const {results, data} = getTspm(state, index).travelers;
    return results && data.length === 0;
}

export function getTravelersError(state, index) {
    return get(getTspm(state, index), 'travelers.error');
}

export function getCompanies(state, index) {
    const tspm = getTspm(state, index);
    return get(tspm, 'companies.results')
        ? get(tspm, 'companies.data')
        : get(tspm, 'suggestedCompanies.data');
}

export function getCompaniesError(state, index) {
    return get(getTspm(state, index), 'companies.error');
}

export function noCompaniesFound(state, index) {
    const {results, data} = getTspm(state, index).companies;
    return results && data.length === 0;
}

export function getConfigurations(state, index) {
    return getTspm(state, index).configurations.data;
}

export function getSelectedConfiguration(state) {
    return getTspm(state, 0).configurations.selected;
}

export function getConfigurationsParams(state) {
    return getTspm(state, 0).configurations.params;
}

export function getConfigurationLoadingParams(state) {
    return getTspm(state, 0).configurations.loading;
}

export function isConfigurationLoading(state) {
    const params = getConfigurationLoadingParams(state);
    return Boolean(every(Object.values(params), val => val === null));
}
