import {combineReducers} from 'redux';
import {multi} from 'airborne/store/modules/homepage/reducers/multiPnr';
import booking, {BOOKING_ACTIONS} from './booking';

function current(state=0, {type, index}) {
    if (type === 'SWITCH_BOOKING') {
        return index;
    }
    if (type === 'DELETE_PNR') {
        return 0;
    }
    return state;
}

export default combineReducers({
    current,
    items: multi(booking, BOOKING_ACTIONS),
});
