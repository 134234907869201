import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import flatten from 'lodash/flatten';
import gettext from 'airborne/gettext';

import {showAlert, hideAlert} from 'midoffice/data/actions/modals';
import {showConfirm, closeConfirm} from 'midoffice/data/actions/confirm';
import {store} from 'midoffice/store';
import airborneStore from 'airborne/store';
import systemData from 'airborne/systemData';

/**
 * Show a simple alert(s) that disappears  after some time or when clicked
 * Usage:
 * - pass object as single option - message and options in one object
 * - pass string as first argument - it is considered an alert message
 *   second argument is, possibly, a message kind
 * - pass array as first argument - it is considered as a messages array
 *   options are passed as second argument
 */

function getStore() {
    return systemData.common.SITE_SUBDOMAIN !== 'admin' ? airborneStore : store;
}

function alert(message, options) {

    const isExactlyObject = (obj)=> isObject(obj) && !isArray(obj);
    if (isExactlyObject(message)) {
        getStore().dispatch(showAlert({messages: [message], ...message}));
        return;
    }

    let messages;
    if (isArray(message) || isString(message)) {
        messages = isExactlyObject(message[0]) ?
            message[0] : [{
                type: isString(options) ? options : 'success',
                html: message
            }];
    }
    else {
        messages = message;
    }

    let data;
    if (isObject(options)) {
        data = {messages, ...options};
    }
    else {
        data = {messages};
    }

    getStore().dispatch(showAlert(data));
}

function hide() {
    getStore().dispatch(hideAlert());
}

/**
 * Shows a simple confirmation window that allows either accept or cancel something
 * Usage:
 * - pass content string as single option - options is considered as confirmation content
 * - pass options object as single option - message and options in one object
 *
 * Available options:
 * - title. Modal window title. Ignored, if empty
 * - content. Required, modal will not be shown otherwise
 * - buttonOk. Allows to change accept button text
 * - buttonCancel. Allows to change cancel button text
 */
function confirm(options) {
    return new Promise((resolve, reject) => {
        const id = uniqueId();
        const unsubscribe = getStore().subscribe(() => {
            const {confirm: [item]} = getStore().getState();
            if (!item || item.id !== id) {
                return;
            }

            if (item.status === 'pending') {
                return;
            }

            if (item.status === 'accepted') {
                resolve();
            }
            else {
                reject();
            }

            unsubscribe();
        });

        if (isString(options)) {
            options = {content: options};
        }

        getStore().dispatch(showConfirm({id, ...options}));
    });
}

function finalizeConfirm() {
    getStore().dispatch(closeConfirm());
}

function flattenIfAny(value) {
    value = isObject(value) && !Array.isArray(value) ? Object.values(value) : value;
    const array = flatten(value);
    return array && array.length && array;
}

/** Default error handler for Backbone's requests
 * @param response - XHR response object */
function oops(xhr) {
    let response = xhr.responseJSON || {};
    let message = '';

    if (xhr && xhr.status < 500) {
        message = (
            response &&
            response.message ||
            response.non_field_errors ||
            flattenIfAny(response) ||
            gettext('Something went wrong.')
        );
    }
    else {
        message = gettext('Internal server error');
    }

    alert(message, 'danger');
}


export default {alert, confirm, finalizeConfirm, oops, hide};
