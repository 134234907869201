import * as actionTypes from '../actionTypes';

const initialState = {
    loading: false,
    errorMessage: null,
    entities: null,
    entityId: null,
    inherits: null,
};

export default function tdlFeatures(state = initialState, {type, errorMessage, entities, entityId, inherits}) {
    switch (type) {
        case actionTypes.TDL_FEATURES_REQUEST:
            return {...state, loading: true, errorMessage: null, entityId};
        case actionTypes.TDL_FEATURES_REQUEST_FAILURE:
            return {...state, loading: false, errorMessage};
        case actionTypes.TDL_FEATURES_REQUEST_SUCCESS:
            return {...state, loading: false, errorMessage: null, entities, inherits};
        case actionTypes.TDL_FEATURES_REQUEST_CLEAR:
            return initialState;
    }

    return state;
}
