function initialState() {
    return {
        value: {},
        loading: false,
        errors: {},
    };
}

export default function userSettings (state, {value, type, errors}) {
    if (!state) return initialState();

    if (type === 'CHANGE') {
        const allErrors = errors === 'reset' ? null : Object.assign({}, state.errors, errors);
        return {...state, value, errors: allErrors};
    }
    if (type === 'LOADING') {
        return {...state, loading: true};
    }
    if (type === 'LOADED' || type === 'FAILED') {
        return {...state, loading: false, errors};
    }
    return state;
}
