import {
    compareSteps as baseCompareSteps,
    stepAfter as baseStepAfter,
    stepBefore as baseStepBefore,
    toStep as baseToStep,
} from 'airborne/common/steps';
import partial from 'lodash/partial';

export const STEPS = ['general', 'advance-passenger', 'passport', 'services', 'payment', 'details'];

export const compareSteps = partial(baseCompareSteps, STEPS);
export const stepBefore = partial(baseStepBefore, STEPS);
export const stepAfter = partial(baseStepAfter, STEPS);
export const toStep = partial(baseToStep, STEPS);
