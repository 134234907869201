import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import {Field} from 'midoffice/newforms/fields-stateless';
import {noErrors} from './helpers';

export const Schema = {
    ...Field,

    toInternalValue(value) {
        if (!value) return value;
        return Object.values(this.fields).reduce((acc, field)=> {
            if (has(value, field.name)) {
                acc[field.name] = field.toInternalValue(value[field.name]);
            }
            return acc;
        }, {});
    },

    toRepresentationFields(value={}, params) {
        return reduce(this.fields, (acc, field)=> {
            if (!has(value, field.name)) {
                return acc;
            }

            const representation = field.toRepresentation(value[field.name], params);
            acc.value[field.name] = representation.value;
            acc.errors[field.name] = representation.errors;
            return acc;
        }, {value: {}, errors: {}});
    },

    toRepresentation(formValue, params) {
        let {value, errors} = this.toRepresentationFields(formValue, params);

        if (noErrors(errors)) {
            errors = this.validate(value, params);
        }

        return {value, errors};
    },

    validateField(fieldName, value, errors, params) {
        const field = this.fields[fieldName];

        const fieldError = field.validate(value, params);
        if (fieldError) {
            return {...errors, [fieldName]: fieldError};
        }
        else {
            errors = omit(errors, fieldName);
            return isEmpty(errors) ? null : errors;
        }
    },

    validateFields(value, params) {
        return reduce(this.fields, (errors, field)=> (
            this.validateField(field.name, value[field.name], errors, params)
        ), null);
    },

    validate(value, params) {
        if (typeof value === 'undefined') {
            return null;
        }
        return this.validateFields(value, params);
    }
};
