import 'airborne/search2/type';
import air from './air';
import {dropEmpty} from 'midoffice/helpers/urlParams';

import {parseList} from 'airborne/types';
import {hotelLabeling, searchLimit} from 'airborne/search2/helpers/hyatt';

import {serializeDestination, destSingleHotel} from 'airborne/search2/helpers/hotels';
import {searchForm} from 'airborne/homepage2/helpers/search';
import settings from 'airborne/settings';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';


const URL = '/hotels/get_list/';

function markAsAlternative(hotel) {
    return {...hotel, isAlternative: true};
}

function extractAlternativeHotels(hotels) {
    if (hotels.length !== 1) {
        return hotels;
    }
    const {'alternative_hotels': alternativeHotels=[], ...rest} = hotels[0];
    return [rest, ...alternativeHotels.map(markAsAlternative)];
}

function request({hotelsBefore=0, ...params}, labeling) {

    return air('GET', URL, params).then(
        ({'hotels_data': hotelsData, 'hotels_found_total': total})=> {
            const data = parseList('hotels', extractAlternativeHotels(hotelsData), hotelsBefore, labeling);
            return {data, total};
        }
    );
}

function getSuggestAlternativeFlag(state) {
    return Boolean(destSingleHotel(state)) ? true : null;
}

function loadListParams(state) {
    const {profile, dest} = searchForm(state, 0); // search uses company from first profile
    const distanceUnits = settings.USER.distance_units;

    const limit = searchLimit(state);
    const page = 1;

    return dropEmpty({
        'destination': serializeDestination(dest.destination.value),
        'checkin': dest.dates.min,
        'configuration_id': getSelectedConfiguration(state) || profile.company,
        'limit': limit,
        'page': page,
        'search_radius': dest.distance,
        'search_radius_unit': distanceUnits,
        'chains': dest.chains || null,
        'hotel_name_keyword': dest['hotel_name'] || null,
        'suggest_alternative': getSuggestAlternativeFlag(state),
        'exclude_non_traditional': dest['exclude_non_traditional'],
    });
}

function nextPage(loadedSize, pageSize) {
    return Math.floor(loadedSize / pageSize) + 1;
}

function loadParams(state, refresh) {
    const {data} = state.hotels.hotels;
    const listParams = loadListParams(state);

    return refresh
        ? listParams
        : {
            ...listParams,
            page: nextPage(data.length, listParams.limit),
            hotelsBefore: data.length,
        };
}

export function searchRequest(state, refresh) {
    return request(
        loadParams(state, refresh),
        hotelLabeling(state)
    );
}
