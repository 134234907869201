export function parse(data) {
    const railTrainline = data['rail:suppliers:trainline'] || {};
    return {
        'rail_trainline:enabled': railTrainline['enabled'],
        'rail_trainline:tenant_id': railTrainline['tenant_id'] || null,
        'rail_trainline:corporate_id': railTrainline['corporate_id'] || null,
        'rail_trainline:statistical_fields': railTrainline['statistical_fields'] || [],
        'rail_trainline:statistical_field_choices': data['rail:suppliers:trainline:statistical_field_choices'] || [],

    };
};

export function serialize(data) {
    return {
        'rail:suppliers:trainline': {
            'enabled': data['rail_trainline:enabled'],
            'tenant_id': data['rail_trainline:tenant_id'],
            'corporate_id': data['rail_trainline:corporate_id'],
            'statistical_fields': data['rail_trainline:statistical_fields'],
        }
    };
}
