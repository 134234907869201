import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import isEqual from 'lodash/isEqual';
import {parseFlightPrice} from 'airborne/search_air/types';
import {getFareBasisCodes, getFarePriceClassNames} from "airborne/checkout_air/helpers/brandedFares";
import set from "lodash/fp/set";
import isEmpty from "lodash/isEmpty";

export const isSameFares = (...fares) => {
    const [firstFare, ...restFares] = fares;
    const {totalAfterTaxes: firstTotalAfterTaxes} = firstFare;
    const firstFareBasisCodes = getFareBasisCodes(firstFare);
    const firstFarePriceClassNames = getFarePriceClassNames(firstFare);

    return restFares.every(fare => {
        if (!fare) {
            return false;
        }

        const {totalAfterTaxes} = fare;
        const fareBasisCodes = getFareBasisCodes(fare);
        const farePriceClassNames = getFarePriceClassNames(fare);

        const isSamePrice = firstTotalAfterTaxes === totalAfterTaxes;
        const isSameFareByBasis = isEqual(firstFareBasisCodes, fareBasisCodes);

        const isPriceClassNamesAvailable = firstFarePriceClassNames.every(Boolean) && farePriceClassNames.every(Boolean);
        const isSameFareByClass =  !isPriceClassNamesAvailable || (isPriceClassNamesAvailable && isEqual(firstFarePriceClassNames, farePriceClassNames));

        const isSameByFareBasisCodeAndPriceClass = isSameFareByBasis && isSameFareByClass;

        return isSamePrice || isSameByFareBasisCodeAndPriceClass;
    });
};

export const mergeFlightPriceAttributes = (flightPrice, fareFamilyGroups) => {
    const sameFare = fareFamilyGroups?.find(fare => isSameFares(fare, flightPrice));



    const fareComponent = sameFare?.fareComponents[0] || {};
    const priceClassName = flightPrice?.priceClassName || fareComponent.priceClassName;
    const priceClassDescription = flightPrice?.priceClassDescription || fareComponent.priceClassDescription;

    return sameFare
        ? set(
            'fareComponents[0].priceClassName',
            priceClassName
        )({...flightPrice, priceClassName, priceClassDescription})
        : flightPrice;
}

export const getBaseFareDifference = (fareGroup, originalFare) => fareGroup?.total - originalFare?.total;

export const extractLowestBrandedFare = (fareFamilyGroups, originalFare) => {
    return [...fareFamilyGroups, originalFare]
        .filter(fare => isEmpty(fare.unbookableRules))
        .reduce((fareA, fareB) => fareA.total < fareB.total ? fareA : fareB);
}

export const displayPriceDifference = (priceDifference, currency) =>
    `${priceDifference < 0 ? '-' : '+'} ${Math.abs(priceDifference).toFixed(2)} ${currency}`;

const sortFareGroupsByPrice = fareGroups =>
    fareGroups.sort((groupA, groupB) => groupA.total - groupB.total);

const extractFareComponent = fareGroup => {
    // We are selecting the first fare component,
    // there is no any particular reason for it,
    // they're just kinda the same for FE
    const {fareComponents: [component]} = fareGroup;

    return component;
};

export const extractFareGroupPriceClassCode = fareGroup => {
    const {priceClassCode} = extractFareComponent(fareGroup);

    return priceClassCode;
};

export const parseFareFamilies = data => {
    const {fare_groups: fareGroups} = data;
    const parseGroups = flow(
        map(parseFlightPrice),
        sortFareGroupsByPrice,
    );

    return fareGroups && parseGroups(fareGroups);
};
