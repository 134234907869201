import {ArrayField, CharField, BooleanField, Field} from 'midoffice/newforms/fields-stateless';
import {createSchema, required} from 'midoffice/newforms/helpers';
import {makeReField} from 'airborne/checkout2/fields';

export const AIRLINE_CODE_ALL_AIRLINES = 'YY';

const FREETEXT_RE = /^[A-z0-9@\-_./{} ]+$/;

const FreeTextField = makeReField(
    CharField,
    FREETEXT_RE,
    () => 'Please enter a correct value'
);

const PrefixesField = {
    fields: {
        'code': Field,
        'freetext': required(FreeTextField)
    }
};

const AirlineCodeField = {
    ...Field,
    validate(value) {
        if (this.isRequired) {
            return value ? null : this.emptyMessage;
        }
    }
};

export const createOSIMessagingSchema = ({allAirlines}) => {
    return createSchema({
        fields: {
            'airline_code': allAirlines ? AirlineCodeField : required(AirlineCodeField),
            'all_airlines': BooleanField,
            'prefix_groups': required({
                ...ArrayField,
                child: createSchema(PrefixesField)
            }),
        },
    });
};

export default createSchema({
    fields: {
        'air:osi_messaging': {
            ...ArrayField,
            child: createOSIMessagingSchema({}),
        }
    },
});
