const CAR_BOOKING_MODIFICATION_FLOW = 'CAR_BOOKING_MODIFICATION_FLOW';
const CAR_BOOKING_MODIFICATION_FLOW_QUIT = 'CAR_BOOKING_MODIFICATION_FLOW_QUIT';

const CAR_DESTINATION_LOADING = 'CAR_DESTINATION_LOADING';
const CAR_DESTINATION_LOADED = 'CAR_DESTINATION_LOADED';

export {
    CAR_BOOKING_MODIFICATION_FLOW,
    CAR_BOOKING_MODIFICATION_FLOW_QUIT,
    CAR_DESTINATION_LOADING,
    CAR_DESTINATION_LOADED,
};
