export function showModal(kind, data={}) {
    return {
        type: 'MODAL_SHOW',
        kind,
        ...data,
    };
}

export function hideModal() {
    return {
        type: 'MODAL_HIDE',
    };
}
