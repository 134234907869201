const DIFFERENT_PROVIDERS_ERROR_CODE = 1036000;

const getPricingFaresRoot = (state) => state.airPricing.fares;

export const isPricingFaresLoading = (state) => getPricingFaresRoot(state).loading;
export const getPricingFares = (state) => getPricingFaresRoot(state).data;

export const getPricingFareByKey = (state, fareGroupKey) => {
    const pricingFares = getPricingFares(state);
    return pricingFares.find((fare) => fare.fareGroupKey === fareGroupKey);
}

export const isInitialView = (state) => getPricingFaresRoot(state).initialView;

export const isAnyPricingFares = (state) =>
    !!getPricingFares(state)?.length;

export const getPricingFaresErrors = (state) =>
    getPricingFaresRoot(state).errors;

export const isDifferentProvidersError = (state) =>
    getPricingFaresRoot(state).errorCodes?.includes(DIFFERENT_PROVIDERS_ERROR_CODE);

export const isAnyPricingErrors = (state) =>
    !!getPricingFaresErrors(state)?.length;
