export const AIR_FLIGHT_PRICE_LOADED = 'AIR_FLIGHT_PRICE_LOADED';
export const AIR_FLIGHT_PRICE_LOAD_END = 'AIR_FLIGHT_PRICE_LOAD_END';
export const AIR_FLIGHT_PRICE_LOADING = 'AIR_FLIGHT_PRICE_LOADING';
export const AIR_FLIGHT_PRICE_ABORT = 'AIR_FLIGHT_PRICE_ABORT';
export const AIR_FLIGHT_PRICE_FAIL = 'AIR_FLIGHT_PRICE_FAIL';
export const AIR_FLIGHT_PRICE_CLEAR_ERRORS = 'AIR_FLIGHT_PRICE_CLEAR_ERRORS';
export const AIR_FLIGHT_PRICE_ADD_REQUEST = 'AIR_FLIGHT_PRICE_ADD_REQUEST';
export const AIR_FLIGHT_PRICE_REMOVE_REQUEST = 'AIR_FLIGHT_PRICE_REMOVE_REQUEST';
export const AIR_FLIGHT_PRICE_CLEAR_REQUESTS = 'AIR_FLIGHT_PRICE_CLEAR_REQUESTS';
export const AIR_FLIGHT_PRICE_SET_LAST_REQUEST_PARAMS = 'AIR_FLIGHT_PRICE_SET_LAST_REQUEST_PARAMS';
