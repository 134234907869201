import omit from 'lodash/omit';
import {mapDataDirect, mapDataReverse} from 'midoffice/helpers/types';


const NAMES_MAP = {
    companies: [
        ['regional:currency', 'currency'],
        ['regional:date_format', 'dateFormat'],
        ['regional:distance_units', 'distanceUnits'],
        ['regional:language', 'language'],
        ['regional:search_radius', 'searchRadius'],
        ['regional:car_search_radius', 'carSearchRadius'],
    ],
    agencies: [
        ['agency:regional:currency', 'currency'],
        ['agency:regional:date_format', 'dateFormat'],
        ['agency:regional:distance_units', 'distanceUnits'],
        ['agency:regional:language', 'language'],
        ['agency:regional:search_radius', 'searchRadius'],
        ['agency:regional:car_search_radius', 'carSearchRadius'],
    ],
    options: [
        ['date_format', 'dateFormat'],
        ['distance_units', 'distanceUnits'],
        ['search_radius', 'searchRadius'],
    ]
};

export function parse(raw, {treeName}) {
    const data = mapDataDirect(raw, NAMES_MAP[treeName]);
    const options = mapDataDirect(data.options, NAMES_MAP.options);

    return {
        ...data,
        options,
    };
}

export function serialize(data, {treeName}) {
    return mapDataReverse(omit(data, 'options'), NAMES_MAP[treeName]);
}
