/* global global */
/* eslint-disable import/no-unresolved */
/* eslint-disable immutable/no-mutation */
import settings from 'airborne/settings';
import puttext from 'vendors/puttext';
import moment from 'moment';

const gettext = puttext();

global.gettext = gettext;
global.ngettext = gettext;

if (global.translations) {
    gettext.setMessages(global.translations);
}
moment.locale(settings.LANGUAGE_CODE);

export default gettext;
export {gettext as gettext, gettext as ngettext};
