import {UserAutocomplete} from 'midoffice/data/ManagedTravelerForm';
import {mergeNames} from 'midoffice/helpers/format';

const URL = '/api/audit-logs/admin_autocomplete/';
function getGroupsLabel({groups=[]}={}) {
    if (!groups.length) {
        return 'No groups';
    }
    const group = groups[0];

    return groups.length === 1 ? group : `${group} + ${groups.length - 1}`;
}


export default class AdminAutocomplete extends UserAutocomplete {
    constructor() {
        super({url: URL});
    }

    query(query) {
        return super.query(query, {forUser: 0, forCompanies: null});
    }

    parseResponse(data) {
        return data.map((user)=> {
            const groups = getGroupsLabel(user);
            const fullName = mergeNames(user);
            const label = `${fullName} (${groups})`;
            return {value: user.id, label};
        });
    }
}
