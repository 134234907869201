import moment from 'moment';

const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const serializeDatetime = (datetime)=> (
    moment(datetime).format(API_DATETIME_FORMAT)
);

export const parseDatetime = (datetime)=> (
    moment(datetime, API_DATETIME_FORMAT).format()
);
