import {getDistance, convertUnit} from 'geolib';

// Everything that is not strait E, W, S or N is
// counted as NE, SW, SW or SE as we don't want
// to confuse users with NEE or NNE directions.
const DIRECTIONS = [
    'W', 'SW', 'SW', 'SW',
    'S', 'SE', 'SE', 'SE',
    'E', 'NE', 'NE', 'NE',
    'N', 'NW', 'NW', 'NW',
    'W',
];

// We, as proud members of Flat Earth Society,
// assume that coordinates are located on plain.
// This code was initially added to bowmain (8a3965ff) in GG-938
// and uses different math as compared to `geolib.getCompassDirection()`.
// `Math.atan2()` output value space is -180 to 180.
function plainAzimuth(position, destination) {
    return Math.atan2(
        destination.latitude - position.latitude,
        destination.longitude - position.longitude,
    ).toDeg() + 180;
}

// Notice that we show directions from location to hotel, not hotel to location
export default function fixDistance(hotel, distanceTo, unit='km') {
    if (!distanceTo) {
        return hotel;
    }
    const [latitude, longitude] = distanceTo;
    const distance = getDistance({latitude, longitude}, hotel, 1);
    const azimuth = Math.round(
        plainAzimuth({latitude, longitude}, hotel) / 22.5
    );
    const direction = DIRECTIONS[azimuth];
    return {...hotel, direction, distance: convertUnit(unit.toLowerCase(), distance)};
}
