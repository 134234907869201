import StreamService from 'airborne/services/Stream';
import * as searchTypes from './actionTypes';

export const connectStreamService = () => () => {
    const subscriberUrl = window.SETTINGS.MERCURE_SUBSCRIBER_URL;
    const channels = window.SETTINGS.MERCURE_CHANNELS;
    StreamService.initialize({subscriberUrl, channels});
};

export const sendMessage = payload => ({
    type: searchTypes.SEND_MESSAGE,
    payload,
});
