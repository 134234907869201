import freshBookingsActionTypes from 'midoffice/store/modules/freshBookings/actionTypes';

export const setFreshBookings = payload => ({
    type: freshBookingsActionTypes.SET,
    payload,
});

export const clearFreshBookings = () => ({
    type: freshBookingsActionTypes.CLEAR,
});
