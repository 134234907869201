import {
    CONFIRM_SHOW,
    CONFIRM_ACCEPT,
    CONFIRM_CANCEL,
    CONFIRM_HIDE
} from 'midoffice/data/actions';

function getEmptyState() {
    return [];
}

function changeStatus([head, ...rest], status) {
    return [
        {...head, status},
        ...rest,
    ];
}

export default function confirm(state, {type, ...options}) {
    if (!state) {
        return getEmptyState();
    }

    if (type === CONFIRM_SHOW) {
        return [{...options, status: 'pending'}, ...state];
    }

    if (type === CONFIRM_HIDE) {
        return state.slice(1);
    }

    if (type === CONFIRM_ACCEPT) {
        return changeStatus(state, 'accepted');
    }

    if (type === CONFIRM_CANCEL) {
        return changeStatus(state, 'canceled');
    }
    return state;
}
