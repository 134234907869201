import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import forms from 'midoffice/newforms';
import api from 'midoffice/helpers/api';
import {agencySource} from 'midoffice/helpers/format';
import TreeSelect from 'midoffice/newforms/widgets/TreeSelect';

const RANGE_SEPARATOR = '|';
export class AgencyFullAutocomplete {

    endpoint = '/api/agencies/autocomplete/';
    emptyMessage = 'Nothing found.';
    minQueryLength = 2;

    parseItem([value, label]) {
        return {value, label};
    }

    parse(data) {
        return Object.keys(data).map((category)=> ({
            category: agencySource(category),
            items: data[category].map(this.parseItem),
        }));
    }

    query(query) {
        if (!query || query.length < this.minQueryLength) {
            api.abortMatching('generic_autocomplete');
            return Promise.resolve(null);
        }

        return new Promise((resolve, reject)=> {
            return api('GET', this.endpoint, {
                data: {term: query},
                id: 'generic_autocomplete',
            }).then(
                (data)=> {
                    if (data && !isEmpty(data)) {
                        resolve(this.parse(data));
                    }
                    else {
                        reject(this.emptyMessage);
                    }
                },
                ({request})=> {
                    if (request.isAborted) {
                        resolve(null);
                    }
                    else {
                        reject('Server error.');
                    }
                }
            );
        });
    }
}

export class DateRangeField extends forms.DateRangeField {

    getIncomingValue(value) {
        if (isString(value)) {
            const values = value.split(RANGE_SEPARATOR);
            return super.getIncomingValue({
                min: values[0],
                max: values[1],
            });
        }
        return super.getIncomingValue(value);
    }

    toRepresentation(value) {
        let {min, max} = super.toRepresentation(value || {});
        if (!min && !max) {
            return null;
        }
        return [min || '', max || ''].join(RANGE_SEPARATOR);
    }
}

export class CompanyField extends forms.Field {
    constructor(props) {
        super({widget: TreeSelect, ...props});
    }

    toRepresentation(value) {
        if (!value) {
            return null;
        }
        return value;
    }
}

export class TagsField extends forms.Field {
    toRepresentation(value) {
        if (!value) {
            return null;
        }
        return value.map(function (el) {
            return el.value || el;
        });
    }

    runValidation(value) {
        value = super.runValidation(value);
        if (isArray(value) && !value.length && !this.allowEmpty) {
            this.fail('required');
        }
        return value;
    }

}


export class OIDTagsField extends TagsField {
    toRepresentation(value) {
        if (!value) {
            return null;
        }

        return value.map((el)=> (
            isString(el) ? el : el.label
        ));
    }
}
