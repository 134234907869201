import * as actionTypes from '../actionTypes';

export const changeAirExchangeForm = ({value, errors}) => ({
    type: actionTypes.AIR_EXCHANGE_CHANGE_FORM,
    payload: {value, errors},
});

export const changeAirExchangeRecentSearches = (value) => {
    return changeAirExchangeForm({value, errors: null});
};

export const changeSingleAirExchangeFormValue = (fieldValue, fieldName) => {
    return {
        type: actionTypes.AIR_EXCHANGE_CHANGE_FORM_DESTINATION,
        payload: {fieldValue, fieldName},
    };
};

export const resetAirExchangeForm = () => ({
    type: actionTypes.AIR_EXCHANGE_RESET_FORM,
});
