import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {injectField} from 'midoffice/newforms/decorators';

@injectField
export default class Label extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
    };

    render() {
        const {value, className} = this.props;

        return (
            <label className={classnames('control-label', className)}><strong>{value}</strong></label>
        );
    }
}
