import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import InlineStyleGroup from './InlineStyleButtons';
import BlockTypeGroup from './BlockTypeButtons';
import BlockTypeSelect from './BlockTypeSelect';
import HistoryGroup from './HistoryButtons';
import LinkButtons from './LinkButtons';


export default class Toolbar extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
    };

    renderButtons() {
        const {children, className, ...props} = this.props; // eslint-disable-line no-unused-vars
        if (children) { return children; }

        return [
            <BlockTypeSelect key="bts" {...props} />,
            <InlineStyleGroup key="isg" {...props} />,
            <BlockTypeGroup key="btg" {...props} />,
            <LinkButtons key="lb" {...props} />,
            <HistoryGroup key="hg" {...props} />,
        ];
    }

    render() {
        const className = cx('draft-toolbar', this.props.className);

        return (
            <div className={className}>
                {this.renderButtons()}
            </div>
        );
    }
}
