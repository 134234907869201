import {LOADING, LOADED, FAILED} from 'midoffice/data/actions';

function getEmptyState() {
    return {
        loading: false,
        url: null,
        errors: null,
    };
}

export default function reportLoading(state, {type, url, errors}) {

    if (!state) {
        return getEmptyState();
    }

    if (type === LOADING) {
        return {loading: true};
    }

    if (type === LOADED) {
        return {
            loading: false,
            url,
        };
    }

    if (type === FAILED) {
        return {
            loading: false,
            errors,
        };
    }
}
