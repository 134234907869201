import React from 'react';
import PropTypes from 'prop-types';
import Scroll from 'midoffice/components/Scroll';
import TreeView from 'midoffice/components/TreeView';


export default class CompaniesTree extends React.Component {

    static propTypes = {
        treeName: PropTypes.string.isRequired,
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: 'form-control',
        value: null
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(item) {
        this.props.onChange(item);
    }

    render() {
        let {value, treeName} = this.props;

        return (
            <Scroll className="modal-content__box js-tree">
                <TreeView selected={{[value]: true}}
                          treeName={treeName}
                          dropdown={false}
                          onClick={this.handleChange} />
            </Scroll>
        );
    }

}
