'process i18n';
import noop from 'lodash/noop';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import settings from 'airborne/settings';

import {extendSession} from 'midoffice/data/actions/auth';
import {sendLogout} from 'airborne/store/modules/logout/actions/logout';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

const DEFAULT_SESSION_TIMEOUT = 4.6 * 60 * 1000;
const TEST_SESSION_TIMEOUT = 50 * 1000;

@connect((state)=> {
    return {
        show: state.session.ends,
        isQaTestSessionExpiration: getFeatureFlag(state,'QA_TEST_SESSION_EXPIRATION_SETTINGS'),
    }
}, {
    logout: sendLogout,
    extendSession,
})
export default class SessionModal extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        logout: PropTypes.func.isRequired,
        extendSession: PropTypes.func.isRequired,
    };

    state = {
        timeout: null,
    }

    static getDerivedStateFromProps({show, logout, isQaTestSessionExpiration}, {timeout}) {
        if (show && !timeout) {
            const timeout = isQaTestSessionExpiration ? TEST_SESSION_TIMEOUT : DEFAULT_SESSION_TIMEOUT;
            let logoutTimeout = setTimeout(() => {
                logout('expired&timeout='+settings['session:timeout_period']);
            }, timeout);
            return {timeout: logoutTimeout};
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        return {
            timeout: null,
        };
    }

    handleLogout = () => {
        clearTimeout(this.state.timeout);
        this.props.logout();
    }

    handleExtendSession = () => {
        clearTimeout(this.state.timeout);
        this.props.extendSession();
    }

    render() {
        const {show} = this.props;

        if (!show) {
            return null;
        }

        return (
            <Modal show keyboard={false} backdrop="static" onHide={noop} >
                <Modal.Header>
                    <Modal.Title>{gettext('Are you still there?')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p dangerouslySetInnerHTML={{
                        __html: gettext('You will be logged out in <strong>5 minutes</strong> due to inactivity.')
                    }} />
                    <p>
                        {gettext('Do you want to stay signed in?')}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={this.handleLogout}
                        variant="light" >
                        {gettext('Log Out')}
                    </Button>
                    <Button
                        onClick={this.handleExtendSession}
                        variant="success" >
                        {gettext('Stay Signed In')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
