import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import {register} from 'airborne/types';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';


function applyHotelLabeling(hotel, labeling) {
    return {
        ...hotel,
        'client_preferred':
          labeling['client_preferred'] &&
          (labeling['client_preferred_by_chain']
              ? hotel['client_preferred'] && !hotel['client_preferred_by_chain']
              : hotel['client_preferred']),
        'client_preference_tier': labeling['client_preferred_tier'] && hotel['client_preference_tier'],
        'tmc_preferred': labeling['agency_preferred_by_chain']
            ? hotel['tmc_preferred'] && !hotel['tmc_preferred_by_chain']
            : labeling['agency_preferred'] && hotel['tmc_preferred'],
        'tmc_preference_tier': labeling['agency_preferred_tier'] && hotel['tmc_preference_tier'],
        'client_preferred_by_chain': labeling['client_preferred_by_chain'] && hotel['client_preferred_by_chain'] && hotel['client_preferred'],
        'tmc_preferred_by_chain': labeling['agency_preferred_by_chain'] && hotel['tmc_preferred_by_chain'] && hotel['tmc_preferred'],
    };
}

function applyHotelLabelingAvail(hotel, labeling) {
    if (labeling['out_of_policy']) {
        return hotel;
    }
    return {
        ...hotel,
        'out_of_policy': false,
        'out_of_policy_reason': [],
    };
}

function fixBreakfast(amenities) {
    return amenities.includes('FBFST') ? [...amenities, 'BFST'] : amenities;
}

function makeDynamicSortMetricField(hotel) {
    return [{
        ascending: false,
        value: hotel.score
    }];
}

function parseHotel(hotel, hotelsBefore, labeling, idx) {
    const isDynamicSortEnabled = getFeatureFlag(null,'HOTEL_DYNAMIC_SORT');
    const {
        'chain_info': chainInfo=[],
        'client_preferred': companyPref,
        'tmc_preferred': agencyPref,
        'tmc_preferred_by_chain': agencyChain,
        'client_preferred_by_chain': companyChain,
        'sort_metric': sortMetric=null,
        regions,
        amenities={},
    } = applyHotelLabeling(hotel, labeling);
    const preferredBy = compact([
        companyPref && 'company',
        agencyPref && 'agency',
        agencyChain && 'agency_chain',
        companyChain && 'company_chain',
    ]);
    const neighborhood = (regions && regions.neighborhood) || [];
    const stars = Math.floor(hotel['hotel_rating']);
    const allAmenities = fixBreakfast(uniq([
        ...(amenities['property_amenities'] || []),
        ...(amenities['room_amenities'] || []),
    ]));
    const chains = chainInfo.map((chain)=> chain['chain_code']);
    const masterChains = chainInfo.map((chain)=> chain['master_chain_code']);
    const sortMetricField = isDynamicSortEnabled ? makeDynamicSortMetricField(hotel) : sortMetric;
    return applyHotelLabeling({
        ...hotel,
        chains: compact([...chains, ...masterChains]),
        stars,
        preferredBy,
        preference: sortMetricField,
        regions: {neighborhood},
        allAmenities,
        serverOrder: hotelsBefore + idx,
    }, labeling);
}


function parseAvail(data, labeling) {
    return data.reduce((acc, hotel)=> ({
        ...acc,
        [hotel.id]: applyHotelLabelingAvail(hotel, labeling),
    }), {});
}

function parseRateDetails({rates, 'check_in_instructions': checkinInstructions}) {
    const [{
        'rate_description': description,
        'rate_promoline': promoline,
        ...rate
    }] = rates;

    return {
        'check_in_instructions': checkinInstructions,
        'rate_details_description': description,
        'rate_details_promoline': promoline,
        ...rate
    };
}


register('hotels', {parse: parseHotel});
register('avail', {parse: parseAvail});
register('rateDetails', {parse: parseRateDetails});
