import {combineReducers} from 'redux';
import * as actionTypes from '../actionTypes';

const data = (
    state = {},
    {type, bookingUid, items, isCompleted}
) => {
    switch (type) {
        case actionTypes.AIR_BOOK_PROGRESS_SET_ITEMS:
            return {
                ...state,
                [bookingUid]: {
                    items,
                    isCompleted,
                }
            };
        default:
            return state;
    }
};

const inProgressUid = (state = null, {type, bookingUid}) => {
    switch (type) {
        case actionTypes.AIR_BOOK_SET_IN_PROGRESS_UID:
            return bookingUid;
        default:
            return state;
    }
};

const bookProgress = combineReducers({
    data,
    inProgressUid,
});

export default bookProgress;
