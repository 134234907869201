import union from 'lodash/union';
import without from 'lodash/without';
import sortBy from 'lodash/sortBy';

function mutableRecursiveChildren(ret, nodeId, tree) {
    (tree[nodeId] || []).forEach(function (el) {
        mutableRecursiveChildren(ret, el, tree);
    });
    ret.unshift(nodeId);
}

function getFamily(nodeId, tree, uptree) {
    const ret = [];
    const parents = [...uptree, nodeId];
    uptree.forEach(function (parentId) {
        tree[parentId].forEach(function (sibling) {
            ret.push(sibling);
        });
    });
    return without(ret, ...parents);
}

export function recursiveChildren(nodeId, tree) {
    const ret = [];
    mutableRecursiveChildren(ret, nodeId, tree);
    return ret;
}

export function toggleSelectionPlain(currentList, node) {
    if (currentList.includes(node)) {
        return without(currentList, node);
    }
    return union([node], currentList);
}

function normalize(list, parent, uptree) {
    return list.filter((el)=> (
        uptree[el] && !uptree[el].includes(parent)
    ));
}

function upToChecked(uptree, checked) {
    let stop = false;
    return uptree.reduce((acc, parentId)=> {
        if (stop) return acc;
        if (checked.includes(parentId)) {
            stop = true;
        }
        acc.push(parentId);
        return acc;
    }, []);
}

export function toggleSelection (currentList, nodeId, tree, uptree) {
    currentList = normalize(currentList, nodeId, uptree);
    const reallyChecked = currentList.includes(nodeId);
    if (reallyChecked) {
        return without(currentList, nodeId);
    }

    const withoutParents = without(currentList, nodeId, ...uptree[nodeId]);
    if (withoutParents.length !== currentList.length) {
        const family = getFamily(nodeId, tree, upToChecked(uptree[nodeId], currentList));
        return union(withoutParents, family);
    }

    return union(currentList, [nodeId]);
}

export function selectedMap(value) {
    return value.reduce((acc, id)=> {
        acc[id] = true;  // eslint-disable-line immutable/no-mutation
        return acc;
    }, {});
}

function normalizeIds(value) {
    let ids = value;
    if (!ids) {
        ids = [];
    }
    else if (!Array.isArray(ids)) {
        ids = [ids];
    }
    ids = ids.map(Number);
    return sortBy(ids);
}

function filterChildren(ids, uptree) {
    return ids.filter((id)=> (
        id && (!uptree[id] || !ids.includes(uptree[id][0]))
    ));
}

export function cleanValue(value, uptree, removeChildren = true) {
    let ids = normalizeIds(value);
    return removeChildren ? filterChildren(ids, uptree) : ids;
}
