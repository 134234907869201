import {combineReducers} from 'redux';
import reduceReducers from 'airborne/utils/reduceReducers';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';

function selectProfile(state, profile) {
    const newProfile = profile || {};
    return {
        ...state,
        'tspm_company_id': newProfile.company || null,
        'tspm_company_name': newProfile['company_name'] || null,
        'tspm_traveler_id': newProfile.id || null,
        'tspm_email': newProfile.email || null,
        'tspm_salutation': capitalize(newProfile.salutation) || null,
        'tspm_first_name': newProfile['first_name'] || null,
        'tspm_last_name': newProfile['last_name'] || null,
        'tspm_cars_id_numbers': newProfile['cars_id_numbers'] || null,
        'tspm_ft_numbers': newProfile['ft_numbers'] || null,
        'traveler_profile_mismatch': false,
    };
}

function selectCompany(state, profile) {
    const newProfile = profile || {};
    return {
        ...state,
        'tspm_company_name': newProfile.name || null,
        'tspm_company_id': newProfile.id || null,
        'company_profile_mismatch': false,
    };
}

const FEALDS_TO_UPDATE = [
    'email_offers_count',
    'car_bookings_count',
    'hotel_bookings_count',
];

function profile(state=null, {type, profile=null, value, update=false}) {
    if (type === 'NO_PNR') {
        return profile;
    }

    if (type === 'TRAVELER_PROFILE_CHANGED') {
        return selectProfile(state, profile);
    }

    if (type === 'COMPANY_PROFILE_CHANGED') {
        return selectCompany(state, profile);
    }

    if (type === 'LOADED_PNR') {
        return update
            ? {...state, ...pick(profile, FEALDS_TO_UPDATE)}
            : profile;
    }

    if (type === 'TRAVELER_PROFILE_IDS_CHANGED') {
        return {...state, ...value};
    }

    if (type === 'DELETE_PNR') {
        const {company, company_name: companyName} = state;
        return {company, 'company_name': companyName, pnr: null};
    }

    return state;
}

function raw(state=false, {type}) {
    if (type === 'SHOW_PNR') {
        return true;
    }

    if (type === 'HIDE_PNR') {
        return false;
    }

    return state;
}

function previousBookingId(state=null, {type, previousBookingId}) {
    if (type === 'REUSE_SEARCH') {
        return previousBookingId || null;
    }
    return state;
}

function resetStateReducer(resetActions, resetValue) {
    return (state, {type}) => resetActions.includes(type) ? resetValue : state;
}

const RESET_ACTIONS = ['DISCARD_PNR'];

const pnr = reduceReducers(
    resetStateReducer(RESET_ACTIONS),
    combineReducers({
        profile,
        raw,
        previousBookingId,
    }),
);

export default pnr;
