import partial from 'lodash/partial';
import 'midoffice/auditlogs/types';
import {
    clearTags, removeTag,
    changeForm,
    fetchCollection,
    serverPaginateCollection,
    serverSearchCollection,
    serverSortCollection,
} from 'midoffice/data/actions/serverFiltered';
import {paginateCollection} from 'midoffice/data/actions/common';

const DEFAULT_PARAMS = {ordering: '-timestamp'};
const endpoint = 'auditlogs';
const filtersEndpoint = 'auditlogFilters';

export const setPaginateLogs = partial(paginateCollection, endpoint);
export const fetchLogs = partial(fetchCollection, endpoint, DEFAULT_PARAMS, false, {});
export const sortLogs = partial(serverSortCollection, endpoint, filtersEndpoint);
export const paginateLogs = partial(serverPaginateCollection, endpoint, filtersEndpoint);

export const searchLogs = partial(serverSearchCollection, endpoint, filtersEndpoint);

export const changeFiltersForm = partial(changeForm, filtersEndpoint);
export const clearFilterTags = partial(clearTags, searchLogs, filtersEndpoint);

export const removeFilterTag = partial(removeTag, searchLogs, filtersEndpoint);
