import {register} from 'airborne/types';


function parseRates(options) {
    if (!options || !options.length) {
        return null;
    }
    return options.map(({code, name, 'is_preselected': preselect})=> [code, name, preselect]);
}

function parseChains(options) {
    if (!options) {
        return {};
    }
    return options.reduce((acc, {id, name, 'parent_id': parentValue})=> (
        acc[id] = {value: id, label: name, parentValue: parentValue || id},
        acc
    ), {});
}


function parseCompanySettings(value) {
    return {
        ...value,
        'chains': parseChains(value.chains),
        'special_rates': parseRates(value['special_rates']),
    };
}


register('company-settings', {parse: parseCompanySettings});
