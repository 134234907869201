import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import {ADDED, CHANGED, LOADED, LOADED_PARTIAL, LOADING, NET_FAIL, REMOVED} from '../actions/types';

function sortIdentity({title, isFolder}) {
    if (isFolder) {
        return 'A' + title.toLowerCase();
    }

    return 'Z' + title.toLowerCase();
}

/*
 * Here we parse json collection supplied by server and
 *  compute three structures:
 *
 *  - tree - object that maps parent id to list of
 *           children ids (one level deep)
 *  - uptree - object that maps node id to list of
 *           parent (full chain traversed to the root
 *  - list - object that maps node id to node attrubutes
*/
function parseTree(plainlist) {
    plainlist = plainlist.map((el)=> ({
        ...el,
        isFolder: Boolean(el.is_folder || el.is_country || el.is_tmc),
    }));

    plainlist = sortBy(plainlist, sortIdentity);

    const tree = plainlist.reduce((acc, el)=> {
        const children = acc[el.parent || 'root'] || [];
        children.push(el.id);
        acc[el.parent || 'root'] = children;
        return acc;
    }, {});
    const list = plainlist.reduce((acc, el)=> {
        acc[el.id] = el;
        return acc;
    }, {});
    const uptree = plainlist.reduce((acc, el)=> {
        const parents = [];
        let cur = el;
        while (cur.parent) {
            parents.push(cur.parent);
            cur = cur.parent ? list[cur.parent] : null;
        }
        acc[el.id] = parents;
        return acc;
    }, {});
    return {tree, list, uptree, total: plainlist.length};
}

function subtreeReoder(subtree, list) {
    return sortBy(subtree, (nodeId)=> (
        sortIdentity(list[nodeId])
    ));
}

function mergeSubTree(oldSub, newSub, list) {
    if (!oldSub) {
        return newSub;
    }

    return subtreeReoder(uniq([...oldSub, ...newSub]), list);
}

function deepMerge(oldTree, newTree, list) {
    const ret = {...oldTree};
    Object.entries(newTree).forEach(function ([key, value]) {
        ret[key] = mergeSubTree(ret[key], value, list);
    });
    return ret;
}

function mergeTree(state, plainlist, total) {
    const {tree, list, uptree} = parseTree(plainlist);
    const mergedList = {...state.list, ...list};
    const forId = Object.entries(tree).reduce((acc, [id, childlist])=> {
        if (childlist.length > 1) {
            acc[id] = true;
        }
        return acc;
    }, {});

    return {
        full: false,
        partial: {...forId, ...state.partial},
        tree: deepMerge(state.tree, tree, mergedList),
        uptree: {...state.uptree, ...uptree},
        list: mergedList,
        total,
    };
}

/*
 * Here we add new entry to tree and assume it was not previously there
 * Adding node with same id multiply times would break things
 */
export function pushEntry({list, tree, uptree, ...state}, el, reorder) {
    let parentUp = uptree[el.parent] || [];
    let parentDown = tree[el.parent || 'root'] || [];
    let isFolder = Boolean(el.is_folder || el.is_country);
    list = {...list, [el.id]: {isFolder, ...el}};
    uptree = {...uptree, [el.id]: [el.parent, ...parentUp]};

    let subtree = [el.id, ...parentDown];
    subtree = reorder ? subtreeReoder(subtree, list) : subtree;

    tree = {...tree, [el.parent || 'root']: subtree};
    return {...state, list, tree, uptree, total: null};
}

export function removeEntry({list, tree, uptree, ...state}, id) {
    const el = list[id];
    let subtree = tree[el.parent || 'root'] || [];
    subtree = subtree.filter(nodeId=> nodeId !== id);

    list = {...list};
    delete list[id];

    uptree = {...uptree};
    delete uptree[id];

    tree = {...tree, [el.parent || 'root']: subtree};

    return {...state, list, tree, uptree, total: null};
}

export function changeEntry({list, tree, uptree, ...state}, el, reorder) {
    let oldEl = list[el.id];
    if (oldEl.parent !== el.parent) {
        return pushEntry(
            removeEntry({list, tree, uptree, ...state}, el.id),
            {...oldEl, ...el}, true
        );
    }
    let isFolder = el.is_folder || el.is_country;
    let subtree = tree[el.parent || 'root'] || [];

    list = {...list, [el.id]: {isFolder, ...el}};

    subtree = reorder ? subtreeReoder(subtree, list) : subtree;
    tree = {...tree, [el.parent || 'root']: subtree};

    return {...state, list, tree, uptree};
}

function loaded(state) {
    return {...state, loading: false, empty: false};
}

function full(state) {
    return {...state, loading: false, empty: false, full: true, partial: null};
}

export default function tree(state, action) {
    if (!state) {
        return {list: {}, tree: {root: []}, uptree: {}, loading: false, empty: true, full: false, partial: {}, total: null};
    }

    if (action.type === LOADING) {
        return {...state, loading: true};
    }

    if (action.type === NET_FAIL) {
        return {...state, loading: false};
    }

    if (action.type === LOADED) {
        if (action.id) {
            return loaded(changeEntry(state, action.data, true));
        }
        return full(parseTree(action.data));
    }
    if (action.type === LOADED_PARTIAL) {
        if (state.full === true && state.empty === false) {
            return full(state);
        }
        return loaded(mergeTree(state, action.data, action.total));
    }
    if (action.type === ADDED) {
        return loaded(pushEntry(state, action.data, true));
    }
    if (action.type === CHANGED) {
        return changeEntry(state, action.data, true);
    }
    if (action.type === REMOVED) {
        return loaded(removeEntry(state, action.id));
    }

    return state;
}
