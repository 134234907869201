import {dropEmpty} from 'midoffice/helpers/urlParams';

function splitList(list) {
    return list ? list.split(',') : [];
}

function parseOne(value) {
    const {
        'code': questionCode,
        'validation_type': validationType,
        'default_select_dynamic': defaultSelectDynamic,
        'default_text_dynamic': defaultTextDynamic,
        ...rest
    } = value;

    return {
        ...rest,
        'code': questionCode.substring(questionCode.indexOf('.') + 1),
        'default_select_dynamic': splitList(defaultSelectDynamic),
        'default_text_dynamic': splitList(defaultTextDynamic),
        'validation_type': validationType,
    };
}

function serializeOne(value, system) {
    const {
        'code': questionCode,
        'validation_type': validationType,
        'default_select_dynamic': defaultSelectDynamic,
        'default_text_dynamic': defaultTextDynamic,
        ...rest
    } = value;

    const nonascii = validationType === 'nonascii';
    return dropEmpty({
        ...rest,
        'code': `${system.toUpperCase()}.${questionCode}`,
        'default_text_dynamic': defaultTextDynamic ? defaultTextDynamic.join(',') : null,
        'default_select_dynamic': defaultSelectDynamic ? defaultSelectDynamic.join(',') : null,
        'validation_type': nonascii ? 'text' : validationType,
    });
}

export function parse({'crd_questions': crdQuestions, ...rest}) {
    return {
        ...rest,
        crdQuestions: crdQuestions.map(parseOne),
    };
}

export function serialize({crdQuestions, ...rest}, {system}) {
    return {
        ...rest,
        'crd_questions': crdQuestions.map((value)=> serializeOne(value, system)),
    };
}
