import gettext from 'airborne/gettext';
import React from 'react';
import Loader from 'midoffice/components/Loader';

export default function wrap(asyncRequire, name='default') {
    class LoadWrapper extends React.Component {
        constructor(props) {
            super(props);
            this.module = {};
        }

        componentDidMount() {
            asyncRequire().then((module)=> {
                this.module = module;
                this.forceUpdate();
            });
        }
        render() {
            const Component = this.module[name];
            return Component
                ? (<Component {...this.props} />)
                : (<Loader loading>{gettext('Loading…')}</Loader>);
        }
    }
    return LoadWrapper;
}
