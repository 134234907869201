import React from 'react';
import PropTypes from 'prop-types';
import DraftEditor from './DraftEditor';
import {toEditorState, toHTML} from './utils';


export default class HTMLEditor extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const editorState = toEditorState(props.value);

        this.editorHTML = toHTML(editorState);
        this.state = {editorState};
    }

    UNSAFE_componentWillReceiveProps({value}) {
        if (value !== this.editorHTML) {
            const editorState = toEditorState(value);
            this.editorHTML = toHTML(editorState);
            this.setState({editorState});
        }
    }

    handleChange(editorState) {
        this.editorHTML = toHTML(editorState);
        this.setState({editorState});

        this.props.onChange(this.editorHTML);
    }

    render() {
        const {editorState} = this.state;
        return (
            <DraftEditor {...this.props}
                editorState={editorState}
                onChange={this.handleChange} />
        );
    }
}
