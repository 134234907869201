import isObject from 'lodash/isObject';

/** Get error message from failed response
 * @param {xhr} response
 * @returns {string|object} Error messages or message if only one
 */
export default function getErrors(response={}) {
    const {body} = response;

    if (!isObject(body)) { return body; }
    if (body.message && Object.keys(body).length === 1) {
        return body.message;
    }

    return body;
}

