import React from 'react';
import gettext from 'airborne/gettext';
import {AdvanceChangeShape} from '../shapes';
import {formatStrict} from 'airborne/utils/price';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

export default class AdvanceChange extends React.Component {
    static propTypes = {
        advanceChange: AdvanceChangeShape,
    }

    renderText() {
        const {advanceChange} = this.props;
        const allowed = advanceChange?.allowed;

        if (allowed === true) {
            return <span className="highlight-green">{gettext('Yes')}</span>;
        }

        if (allowed === false) {
            return <span className="highlight-red">{gettext('No')}</span>;
        }

        return <span className="highlight-gray">{gettext('Unknown')}</span>;
    }

    renderFee() {
        const {advanceChange} = this.props;
        const fee = advanceChange?.fee;
        const amount = advanceChange?.amount;

        if (fee === true) {
            const amountText = amount ? formatStrict(amount.amount, amount.currency) : '';
            return `• ${gettext('Fee')} ${amountText}`;
        }

        return null;
    }

    renderDescription() {
        const {advanceChange} = this.props;
        const description = advanceChange?.description;

        if (description) {
            return (
                <AnnotateTooltip id={'advance-change-description'}>
                    <span className="glyphicons glyphicons-chat" />
                    {description}
                </AnnotateTooltip>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="a-content__item__rate-details" data-testid="fare-group-advance-change">
                {gettext('Advance Change')}:{' '}
                <span>
                    {this.renderText()} {this.renderFee()}
                    {this.renderDescription()}
                </span>
            </div>
        );
    }
}
