import {dropEmpty} from 'midoffice/helpers/urlParams';

export function parse(data) {
    return data['cars:travel_policy'];
}

export function serialize(data) {
    return {
        'cars:travel_policy': dropEmpty(data),
    };
}
