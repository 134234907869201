import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {createAirRequestParams} from 'airborne/search_air/helpers';
import {getAirExchangeBookingKey} from 'airborne/store/modules/exchange_air/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

export const getAirRequestParams = state => {
    const profile = getPnrProfile(state, 0);
    const value = getDestFormValue(state);
    const currency = getCurrency(state);
    const exchangeKey = getAirExchangeBookingKey(state);
    const selectedConfigurationId = getSelectedConfiguration(state);

    return createAirRequestParams(value, profile, currency, exchangeKey, selectedConfigurationId);
};
