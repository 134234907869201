import mapValues from 'lodash/mapValues';

import {filterSections} from 'midoffice/companies/permissionFilter';

export const SECTIONS_BY_PRODUCT = {
    'general': [
        {
            label: 'General',
            items: [
                {section: 'settings', title: 'Company Settings', permission: 'midoffice:companies:general:company_settings:view'},
                {section: 'company-ids', title: 'Company IDs', permission: 'midoffice:companies:general:company_ids:view'},
                {section: 'company-locations', title: 'Company Locations', permission: 'midoffice:companies:general:company_locations:view'},
                {section: 'company-access', title: 'Company Access', permission: 'midoffice:companies:general:company_access:view'},
            ]
        }, {
            label: 'GDS',
            items: [
                {section: 'general-pnr-formatting', title: 'PNR Formatting',  permission: 'midoffice:companies:general:pnr_formatting:view'},
                {section: 'gds-configuration', title: 'Primary GDS', permission: 'midoffice:companies:general:primary_gds:view'},
                {section: 'queues-general', title: 'Queues', permission: 'midoffice:companies:general:queues:view'},
                {section: 'aft-gds-configuration', title: 'AFT Configuration', permission: 'midoffice:companies:general:aft_configuration:view'},
            ]
        }, {
            label: 'Traveler Management',
            items: [
                {section: 'tspm-connection', title: 'TSPM Connection', permission: 'midoffice:companies:general:tspm_connection_on_off:view'},
                {section: 'crd_questions', title: 'Client Reportable Data', permission: 'midoffice:companies:general:client_reportable_data:view'},
                {section: 'guest-traveler', title: 'Guest Travelers', permission: 'midoffice:companies:general:guest_travelers:view'},
            ]
        }, {
            label: 'Regional',
            items: [
                {section: 'regional-settings', title: 'Default Display Preferences', permission: 'midoffice:companies:general:default_display_preferences:view'},
            ],
        }, {
            label: 'Miscellaneous',
            items: [
                {section: 'config-export', title: 'Configuration Export', permission: 'midoffice:companies:general:configuration_export:view'},
                {section: 'feature-toggles', title: 'MOB Feature Toggles', permission: 'midoffice:companies:general:feature_toggles:view'},
                {section: 'agent-chat', title: 'Agent Chat', permission: 'midoffice:companies:general:agent_chat:view'},
                {section: 'invoice-collection', title: 'TripSource Invoice Collection', permission: 'midoffice:companies:general:invoice_collection:view'},
                {section: 'sso-configuration', title: 'SSO Configuration', permission: 'midoffice:companies:general:sso_configuration:view'},
                {section: 'tripsource-credits-and-unused-tickets', title: 'TripSource Credits & Unused Tickets', permission: 'midoffice:companies:general:tripsource_credits_and_unused_tickets:view'},
            ]
        }, {
            label: 'External Settings',
            items: [
                {section: 'external-settings', title: 'TDL Features', permission: 'midoffice:companies:general:features:view'},
            ],
        }, {
            label: 'Payment',
            items: [
                {section: 'vpa-settings', title: 'VPA Settings', permission: 'midoffice:companies:general:vpa_settings:view'},
                {section: 'dbi-airplus', title: 'Airplus DBI', permission: 'midoffice:companies:general:dbi_airplus:view'},
                {section: 'dbi-amex', title: 'Amex BTA', permission: 'midoffice:companies:general:dbi_amex:view'},
            ]
        }
    ],

    'hotels': [
        {
            label: 'General',
            items: [
                {section: 'payment-methods-priority', title: 'Payment Methods Priority', permission: 'midoffice:companies:hotels:payment_methods_priority:view'},
                {section: 'travel-restrictions', title: 'Travel Restrictions', permission: 'midoffice:companies:hotels:travel_restrictions:view'},
            ]
        },
        {
            label: 'GDS',
            items: [
                {section: 'pnr-formatting', title: 'PNR Formatting',  permission: 'midoffice:companies:hotels:pnr_formatting:view'},
                {section: 'gds-segment-configuration', title: 'GDS Segment Configuration', permission: 'midoffice:companies:hotels:gds_segment_configuration:view'},
            ]
        }, {
            label: 'Suppliers',
            items: [
                {section: 'supplier-bookingcom', title: 'Booking.com Credentials', permission: 'midoffice:companies:hotels:booking_com_credentials:view'},
                {section: 'supplier-eps', title: 'EPS Credentials', permission: 'midoffice:companies:hotels:eps_credentials:view'},
                {section: 'supplier-spc', title: 'Stay Private Channel Credentials', permission: 'midoffice:companies:hotels:spc_credentials:view'},
                {section: 'supplier-hcorpo', title: 'HCorpo Credentials', permission: 'midoffice:companies:hotels:hcorpo_credentials:view'},
                {section: 'supplier-hrs', title: 'HRS Credentials', permission: 'midoffice:companies:hotels:hrs_credentials:view'},
                {section: 'supplier-hrsd', title: 'HRS Direct Credentials', permission: 'midoffice:companies:hotels:hrsd_credentials:view'},
                {section: 'supplier-premierinn', title: 'Premier Inn Credentials', permission: 'midoffice:companies:hotels:premier_inn_credentials:view'},
                {section: 'supplier-totalstay', title: 'Totalstay Credentials', permission: 'midoffice:companies:hotels:totalstay_credentials:view'},
                {section: 'supplier-omnibees', title: 'OmniBees Credentials', permission: 'midoffice:companies:hotels:omnibees_credentials:view'},
            ]
        }, {
            label: 'Travel Policy',
            items: [
                {section: 'rate-caps', title: 'Rate Caps', permission: 'midoffice:companies:hotels:rate_caps:view'},
                {section: 'travel-policy', title: 'Travel Policy', permission: 'midoffice:companies:hotels:travel_policy:view'},
                {section: 'vat-settings', title: 'VAT Settings', permission: 'midoffice:companies:hotels:vat_settings:view'},
            ]
        }, {
            label: 'Hotels',
            items: [
                {section: 'security-vetted-hotels', title: 'Security Vetted Hotels', permission: 'midoffice:companies:hotels:company_secured_hotels:view'},
                {section: 'client-hotels', title: 'Company Preferred Hotels', permission: 'midoffice:companies:hotels:company_preferred_hotels:view'},
                {section: 'hotel-sorting', title: 'Hotel Sorting', permission: 'midoffice:companies:hotels:hotel_sorting:view'},
                {section: 'hotel-exclusions', title: 'Hotel Exclusions', permission: 'midoffice:companies:hotels:hotel_exclusions:view'},
                {section: 'hotel-exclusion-rules', title: 'Hotel Exclusion Rules', permission: 'midoffice:companies:hotels:hotel_exclusion_rules:view'},
                {section: 'hotel-labeling', title: 'Hotel Labeling', permission: 'midoffice:companies:hotels:hotel_labeling:view'},
                {section: 'hotel-dynamic-sort', title: 'Dynamic Property Sort', permission: 'midoffice:companies:hotels:hotel_sorting:view', featureFlag: 'HOTEL_DYNAMIC_SORT'}
            ]
        }, {
            label: 'Rates',
            items: [
                {section: 'rate-codes', title: 'Company Preferred Rate Codes', permission: 'midoffice:companies:hotels:company_preferred_rate_codes:view'},
                {title: 'Rate Sorting & Biasing'},
                {section: 'rate-exclusions', title: 'Rate Exclusions', permission: 'midoffice:companies:hotels:rate_exclusions:view'},
                {section: 'rate-labeling', title: 'Rate Labeling', permission: 'midoffice:companies:hotels:rate_labeling:view'},
                {section: 'rate-optimization', title: 'Rate Optimization', permission: 'midoffice:companies:hotels:rate_optimization:view'},
                {section: 'special-rates', title: 'Special Rates', permission: 'midoffice:companies:hotels:special_rates:view'},
            ]
        }, {
            label: 'Miscellaneous',
            items: [
                {section: 'advertising', title: 'Advertising', permission: 'midoffice:companies:hotels:advertising:view'},
                {section: 'autocomplete', title: 'Autocomplete', permission: 'midoffice:companies:hotels:autocomplete:view'},
                {section: 'filters', title: 'Filters', permission: 'midoffice:companies:hotels:filters:view'},
                {section: 'images-sources', title: 'Images & Media Content', permission: 'midoffice:companies:hotels:images_media_content:view'},
                {section: 'trustyou', title: 'TrustYou', permission: 'midoffice:companies:hotels:trustyou:view'},
                {section: 'merchant-content', title: 'BCD Merchant Content', permission: 'midoffice:companies:hotels:bcd_merchant_content:view'},
            ]
        }
    ],
    'cars': [
        {
            label: 'General',
            items: [
                {section: 'cars', title: 'Cars', permission: 'midoffice:companies:cars:cars:view'},
                {section: 'cars-payment-methods-priority', title: 'Payment Methods Priority', permission: 'midoffice:companies:cars:payment_methods_priority:view'},
                {section: 'cars-travel-restrictions', title: 'Travel Restrictions', permission: 'midoffice:companies:cars:travel_restrictions:view'},
                {section: 'cars-home-page', title: 'Home Page', permission: 'midoffice:companies:cars:cars_home_page:view'},
            ]
        }, {
            label: 'GDS',
            items: [
                {section: 'cars-pnr-formatting', title: 'PNR Formatting', permission: 'midoffice:companies:cars:pnr_formatting:view'},
                {section: 'segment-configuration', title: 'GDS Segment Configuration', permission: 'midoffice:companies:cars:segment_configuration:view'},
            ]
        }, {
            label: 'Policy',
            items: [
                {section: 'cars-travel-policy', title: 'Cars Travel Policy', permission: 'midoffice:companies:cars:travel_policy:view'},
                {section: 'cars-negotiated-rates', title: 'Negotiated Rates', permission: 'midoffice:companies:cars:company_negotiated_rates:view'},
                {section: 'cars-preferred-rates', title: 'Preferred Vendors & Car Types', permission: 'midoffice:companies:cars:company_preferred_rates:view'},
                {section: 'cars-rate-exclusions', title: 'Rate Exclusions', permission: 'midoffice:companies:cars:company_rate_exclusions:view'},
            ],
        }, {
            label: 'Miscellaneous',
            items: [
                {section: 'cars-advertising', title: 'Advertising', permission: 'midoffice:companies:cars:advertising:view'},
                {section: 'cars-autocomplete', title: 'Autocomplete', permission: 'midoffice:companies:cars:autocomplete:view'},
            ]
        }, {
            label: 'Cars',
            items: [
                {section: 'cars-sorting', title: 'Cars sorting', permission: 'midoffice:companies:cars:cars_sorting:view'},
                {section: 'cars-labeling', title: 'Cars Labeling', permission: 'midoffice:companies:cars:cars_labeling:view'},
            ]
        }
    ],
    'air': [
        {
            label: 'General',
            items: [
                {section: 'air', title: 'Flights', permission: 'midoffice:companies:flights:flights:view'},
                {section: 'air-exclusions', title: 'Air Exclusions', permission: 'midoffice:companies:flights:exclusions:view'},
                {section: 'air-fop-extension', title: 'Form of Payment Extension', permission: 'midoffice:companies:flights:fop_extension:view'},
                {section: 'air-travel-restrictions', title: 'Travel Restrictions', permission: 'midoffice:companies:flights:travel_restrictions:view'},
                {section: 'air-labeling', title: 'Airfare Labeling', permission: 'midoffice:companies:flights:air_labeling:view'},
                {section: 'air-custom-airport-hubs', title: 'Custom Airport Hubs', permission: 'midoffice:companies:flights:custom_airport_hubs:view'},
                {section: 'air-search-sorting-customization', title: 'Flights Sorting', permission: 'midoffice:companies:flights:search_sorting_customization:view'},
            ]
        }, {
            label: 'GDS',
            items: [
                {section: 'air-pnr-formatting', title: 'PNR Formatting', permission: 'midoffice:companies:flights:pnr_formatting:view'},
                {section: 'air-osi-messaging', title: 'OSI Messaging', permission: 'midoffice:companies:flights:osi_messaging:view'},
                {section: 'air-ssr-messaging', title: 'SSR Messaging', permission: 'midoffice:companies:flights:ssr_messaging:view'},
            ]
        },
        {
            label: 'Fares',
            items: [
                {section: 'air-policy', title: 'Air Policy', permission: 'midoffice:companies:flights:air_policy:view'},
                {section: 'negotiated-fares', title: 'Negotiated fares ', permission: 'midoffice:companies:flights:client_negotiated_fares:view'},
                {section: 'air-preferred-airlines', title: 'Preferred Airlines', permission: 'midoffice:companies:flights:preferred_airlines:view'},
                {section: 'air-search-customizations', title: 'Search Customization', permission: 'midoffice:companies:flights:search_customizations:view'},
            ]
        },
        {
            label: 'Suppliers',
            items: [
                {section: 'supplier-travelfusion', title: 'Travelfusion LCC', permission: 'midoffice:companies:flights:travelfusion:view'},
                {section: 'supplier-britishairways', title: 'British Airways', permission: 'midoffice:companies:flights:british_airways:view'},
                {section: 'supplier-lufthansa', title: 'Lufthansa Group', permission: 'midoffice:companies:flights:lufthansa:view'},
                {section: 'supplier-sabre-content-control', title: 'Sabre Content Control', permission: 'midoffice:companies:flights:sabre_content_control:view'},
                {section: 'supplier-amadeus-gds', title: 'Amadeus Content Control', permission: 'midoffice:companies:flights:amadeus_gds:view'},
            ]
        },
        {
            label: 'Integrations',
            items: [
                {section: 'air-gpos-integration', title: 'GPOS', permission: 'midoffice:companies:flights:gpos:view'},
            ]
        },
        {
            label: 'Miscellaneous',
            items: [
                {section: 'air-advertising', title: 'Advertising', permission: 'midoffice:companies:flights:advertising:view'},
                {section: 'air-co2-emissions', title: 'CO₂ Emissions', permission: 'midoffice:companies:flights:co2_emissions:view'},
            ]
        }
    ],
    'rail': [
        {
            label: 'General',
            items: [
                {section: 'rail', title: 'Rail', permission: 'midoffice:companies:rail:rail:view', featureFlag: 'RAIL_ENABLED'}
            ]
        }, {
            label: 'Suppliers',
            items: [
                {section: 'rail-supplier-trainline', title: 'Trainline', permission: 'midoffice:companies:rail:trainline:view', featureFlag: 'RAIL_ENABLED'},
                {section: 'rail-supplier-deutschebahn', title: 'Deutsche Bahn', permission: 'midoffice:companies:rail:deutschebahn:view', featureFlag: 'RAIL_ENABLED'},
            ]
        }, {
            label: 'Policy',
            items: [
                {section: 'rail-multiple-passengers', title: 'Multiple Passengers', permission: 'midoffice:companies:rail:multiple_passengers:view', featureFlag: 'RAIL_ENABLED'},
            ],
        },
    ]
};


export function getSections() {
    return mapValues(
        SECTIONS_BY_PRODUCT,
        (sections)=> filterSections(sections)
    );
}
