import settings from 'airborne/settings';

export function getBooking({bookings}, props) {
    return settings.SUB_PARTNER === 'common' ? bookings.hash[props.id] : bookings.hash[props.id || props.data.id];
}

export function getBookingsIds (state) {
    return state.bookings.paged;
}

