import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';


export class ButtonGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any.isRequired,
    };

    render() {
        return (
            <div className={cx('draft-button-group', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

export default class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        icon: PropTypes.string.isRequired,
        children: PropTypes.any,

        active: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,

        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: '',
        active: false,
        disabled: false,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onClick && this.props.onClick();
    }

    render() {
        const {active, disabled, icon} = this.props;
        const className = cx('draft-button', this.props.className, {
            'draft-button-active': active,
            'disabled': disabled,
        });
        const iconClassName = cx('icon', `draft-icon-${icon}`);

        return (
            <button type="button"
                    className={className}
                    onMouseDown={this.handleClick}
                    disabled={disabled}>
                {this.props.children || <span className={iconClassName} />}
            </button>
        );
    }
}
