function initial() {
    return {uid: null, data: null, prev: null, loading: false};
}

function cancel(data) {
    return {...data, 'cancellation_status': 'in-progress'};
}

function tryAgain(state) {
    const {data} = state;
    if (!data) return initial();
    return (data['booking_status'] === 'failed' || data['booking_status'] === 'canceled')
        ? initial()
        : state;
}

export default function bookingDetails(state, {type, uid, data}) {
    if (!state || type === 'BOOKING_FAIL2') {
        return initial();
    }
    if (type === 'TO_SEARCH_RESULT') {
        return tryAgain(state);
    }

    if (type === 'BOOKING_UID') {
        return {...state, uid};
    }
    if (type === 'BOOKING_DETAILS') {
        return {...state, data};
    }
    if (type === 'BOOKING_DETAILS_UPDATE') {
        return {...state, data: {...state.data, ...data}};
    }
    if (type === 'BOOKING_DETAILS_PREV') {
        return {...state, prev: data};
    }

    if (type === 'BOOKING_DETAILS_PREV_FAIL') {
        return {...state, prev: {'cancellation_status': 'unknown'}};
    }

    if (type === 'BOOKING_CANCEL_LOADING') {
        return {...state, loading: true};
    }
    if (type === 'BOOKING_CANCEL_FAIL') {
        return {...state, loading: false};
    }
    if (type === 'BOOKING_CANCEL') {
        return {...state, loading: false, data: cancel(state.data)};
    }

    return state;
}
