'process i18n';
import uniq from 'lodash/uniq';
import isString from 'lodash/isString';
import takeRight from 'lodash/takeRight';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {connect} from 'react-redux';
import Scroll from 'midoffice/components/Scroll';
import {ControlledTreeView, bindDispatch} from 'midoffice/components/TreeView';
import {toggleSelection, toggleSelectionPlain, cleanValue, selectedMap} from 'midoffice/helpers/selectUtils';
import {titleForTreeSelect} from 'midoffice/helpers/format';
import Tags from 'midoffice/newforms/widgets/Tags';
import {injectField} from 'midoffice/newforms/decorators';

import gettext from 'airborne/gettext';

import {AgencyAutocomplete} from 'midoffice/data/TreeAutocomplete';
import {companyAutocomplete, companyAutocompleteAft} from 'airborne/homepage2/autocomplete/CompanyAutocomplete';
import {filter} from 'lodash';

const AUTOCOMPLETE = {
    agencies: new AgencyAutocomplete(),
    companies: companyAutocomplete,
    companiesAft: companyAutocompleteAft,
};


export function filterOptions({treeName, list, leafOnly, folderOnly, disableRoot}) {
    return treeName === 'agencies'
        ? {leafOnly, folderOnly, disableRoot, list}
        : null;
}

const PIPELINE_LIMIT = 10;
const BLOCKING_LIMIT = 20;


export class ControlledTreeSelect extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        modalLabel: PropTypes.string,
        placeholder: PropTypes.string,
        linkText: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        keyField: PropTypes.string,
        noCounter: PropTypes.string,
        rootId: PropTypes.number,
        onlyOne: PropTypes.bool,
        folderOnly: PropTypes.bool,
        leafOnly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        loading: PropTypes.bool,
        tree: PropTypes.object.isRequired,
        uptree: PropTypes.object.isRequired,
        list: PropTypes.object.isRequired,
        treeName: PropTypes.string.isRequired,
        fetchPartial: PropTypes.func.isRequired,
        markCompaniesAsNotLoaded: PropTypes.func.isRequired,
        removeNotAllowed: PropTypes.bool,
        removeChildren: PropTypes.bool,
        isCommonSystem: PropTypes.bool,
        user: PropTypes.shape({
            'company_name': PropTypes.string
        })
    };

    static defaultProps = {
        label: gettext('Filter by Companies'),
        onlyOne: false,
        folderOnly: false,
        leafOnly: false,
        keyField: 'title',
        value: [],
        removeChildren: true,
        isCommonSystem: false,
    };

    state = {
        treeModal: false,
        value: null,
        currentList: [],
    };

    componentDidMount() {
        this.fetchMissing(this.state.currentList)
            .then(this.removeNotAllowed);
    }

    componentDidUpdate() {
        this.fetchMissing(this.state.currentList)
            .then(this.removeNotAllowed);
    }

    componentWillUnmount() {
        this.fetchMissingRecurcive = () => null;
    }

    static getDerivedStateFromProps(props, state) {
        const value = state.value === props.value
            ? state.currentList
            : props.value;

        return {
            currentList: cleanValue(value, props.uptree, props.removeChildren),
            value: props.value,
        };
    }

    fetchMissing(ids, counter = 0, batchNumberLimit=null) {
        const {list, fetchPartial, noCounter, markCompaniesAsNotLoaded} = this.props;
        const missingIds = ids.filter(id => !list[id]);
        if (!missingIds.length) {
            return Promise.resolve(missingIds);
        }

        if (missingIds.length >= BLOCKING_LIMIT) {
            markCompaniesAsNotLoaded(missingIds);
            return Promise.resolve(missingIds);
        }

        batchNumberLimit = batchNumberLimit || Math.ceil(missingIds.length / PIPELINE_LIMIT);
        if (!noCounter && counter >= batchNumberLimit) {
            return Promise.resolve(missingIds);
        }

        return Promise.all(
            takeRight(missingIds, PIPELINE_LIMIT)
                .map(fetchPartial)
        ).then(() => this.fetchMissingRecurcive(missingIds, counter + 1, batchNumberLimit));
    }

    fetchMissingRecurcive = (...args) => this.fetchMissing(...args);

    removeNotAllowed = (missingIds) => {
        const shouldRemoveNotAllowed = this.props.removeNotAllowed
            && missingIds && missingIds.length === 0
            && Array.isArray(this.props.value);

        if (shouldRemoveNotAllowed) {
            const items = this.props.value.map(id => ({
                id,
                label: this.getTitleForTreeSelect(id, this.props.list)
            }));

            const filteredItems = items.filter(item => item.label.indexOf('failed') === -1);

            if (filteredItems.length < items.length) {
                this.props.onChange(filteredItems.map(item => item.id));
            }
        }
    }

    haveEnough() {
        if (!this.props.onlyOne) {
            return false;
        }
        let value = this.props.value || [];

        return value.length > 0 || value.id;
    }

    getTitleForTreeSelect(nodeId, list) {
        const {user} = this.props;
        if (!list[nodeId]) {
            return user?.company_name || '...';
        }
        return titleForTreeSelect(nodeId, list);
    }

    handleChange = (value) => {
        if (this.props.onlyOne) {
            value = value[0] || null;
        }
        else {
            value = value && value.map((el) => el.id);
        }
        this.props.onChange(value);
    }

    handleCommit = (newValue) => {
        const {onlyOne, value} = this.props;
        if (isString(newValue)) {
            return;
        }

        if (onlyOne) {
            this.props.onChange(newValue ? newValue.value : null);
        }
        else if (newValue) {
            this.props.onChange(uniq(value ? [...value, newValue.value]
                : [newValue.value]));
        }
    }

    handleAdd = () => {
        let value = this.state.currentList.slice();
        if (this.props.onlyOne) {
            this.props.onChange(value[0] || null);
            this.setState({currentList: value.slice(0, 1), treeModal: false});
        }
        else {
            this.props.onChange(value);
            this.setState({treeModal: false});
        }
    }


    handleTreeModal = (event) => {
        if (this.state.treeModal) {
            this.props.onBlur();
        }
        else {
            this.props.onFocus();
        }

        this.setState({treeModal: !this.state.treeModal});
        if (event && event.preventDefault) {
            event.preventDefault();
        }
    }


    handleTreeClick = (nodeId) => {
        const {tree, uptree} = this.props;
        const {currentList} = this.state;
        let value = currentList;

        if (this.props.onlyOne) {
            value = toggleSelectionPlain(currentList, nodeId).slice(0, 1);
        }
        else {
            value = toggleSelection(currentList, nodeId, tree, uptree);
        }
        this.setState({currentList: value});
    }

    renderTreeModal() {
        const {
            onlyOne, leafOnly, folderOnly,
            label, modalLabel,
            rootId,
            list, tree, uptree, loading,
            fetchPartial
        } = this.props;
        const {currentList} = this.state;
        const selected = selectedMap(currentList);
        return (<Modal onHide={this.handleTreeModal} show >
            <Modal.Header closeButton>
                <Modal.Title>{modalLabel || label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Scroll className="modal-content__box">
                    <ControlledTreeView dropdown={false}
                        list={list}
                        tree={tree}
                        uptree={uptree}
                        loading={loading}
                        rootId={rootId}
                        selected={selected}
                        multiSelect={!onlyOne}
                        folderOnly={folderOnly}
                        leafOnly={leafOnly}
                        onClick={this.handleTreeClick}
                        fetchPartial={fetchPartial} />
                </Scroll>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <Button size="lg" variant="success"
                    onClick={this.handleAdd}>{gettext('Add')}</Button>
            </Modal.Footer>
        </Modal>);
    }

    renderButton() {
        return (<div className="text-right form-group__sub">
            <a className="clickable" onClick={this.handleTreeModal}>{this.props.linkText}</a>
        </div>);
    }

    render() {
        const {placeholder, disabled, onFocus, onBlur, list, value, uptree, treeName, removeChildren, isCommonSystem} = this.props;
        const autocomplete = isCommonSystem ? AUTOCOMPLETE.companiesAft : AUTOCOMPLETE[treeName];
        const nodeNames = cleanValue(value, uptree, removeChildren).map((nodeId) => (
            {id: nodeId, label: this.getTitleForTreeSelect(nodeId, list)}
        ));

        return (<div className="form-group__tags">
            <Tags onChange={this.handleChange}
                onCommit={this.handleCommit}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                value={nodeNames}
                autocompleteSource={autocomplete}
                autocompleteExtra={filterOptions(this.props)}
                placeholder={this.haveEnough() ? null : placeholder} />
            {!disabled && this.renderButton()}
            {this.state.treeModal && this.renderTreeModal()}
        </div>);
    }
}

export default injectField(connect(function (state, props) {
    return state[props.treeName];
}, bindDispatch)(ControlledTreeSelect));
