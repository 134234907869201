import {
    UPDATE_PAYMENT_PATCH_FORM,
    UPDATE_PAYMENT_CLEANUP_FORM,
    UPDATE_PAYMENT_REQUEST,
    UPDATE_PAYMENT_REQUEST_SUCCESS,
    UPDATE_PAYMENT_REQUEST_FAILUIRE
} from '../actionTypes';

const initialState = {
    value: {
        'credit_card_phone': {},
    },
    errors: {},
    loading: false,
    message: null,
};

export default function updatePaymentInfo(state = initialState, {type, value, errors, message, loading}) {
    if (type === UPDATE_PAYMENT_PATCH_FORM) {
        return {...state, value, errors};
    }

    if (type === UPDATE_PAYMENT_CLEANUP_FORM) {
        return initialState;
    }

    if (type === UPDATE_PAYMENT_REQUEST) {
        return {...state, loading, message};
    }

    if (type === UPDATE_PAYMENT_REQUEST_SUCCESS) {
        return {...state, loading, message};
    }

    if (type === UPDATE_PAYMENT_REQUEST_FAILUIRE) {
        return {...state, loading, message};
    }

    return state;
}
