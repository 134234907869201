import uniqBy from 'lodash/uniqBy';


export function serialize({...data}) {
    return Object.assign(
        data,
        {
            countries: uniqBy(data.countries, 'value'),
            airports: uniqBy(data.airports, 'value'),
            cities: uniqBy(data.cities, 'value'),
        }
    );
}
