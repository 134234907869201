import * as actionTypes from '../actionTypes';

const initialState = {
    loading: false,
    errorMessage: null,
    value: {},
    errors: {},
};

export default function actionableBooking(state = initialState, {type, errorMessage, value, errors}) {
    switch (type) {
        case actionTypes.UPDATE_RESOLUTION_FORM:
            return {...state, value, errors};

        case actionTypes.CLEANUP_RESOLUTION_FORM:
            return initialState;

        case actionTypes.CHANGE_ASSIGNEE_REQUEST:
        case actionTypes.CHANGE_RESOLUTION_REQUEST:
            return {...state, loading: true, errorMessage: null};

        case actionTypes.CHANGE_ASSIGNEE_REQUEST_FAILURE:
            return {...state, loading: false};
        case actionTypes.CHANGE_RESOLUTION_REQUEST_FAILURE:
            return {...state, loading: false, errorMessage};

        case actionTypes.CHANGE_ASSIGNEE_REQUEST_SUCCESS:
        case actionTypes.CHANGE_RESOLUTION_REQUEST_SUCCESS:
            return {...state, loading: false, errorMessage: null};
    }

    return state;
}
