
export function getUserSettingsValue(state, endpoint) {
    return state[endpoint].value;
}

export function getUserSettingsLoading(state, endpoint) {
    return state[endpoint].loading;
}

export function getUserSettingsErrors(state, endpoint) {
    return state[endpoint].errors;
}
