import React from 'react';

export default class Loader extends React.Component {
    render() {
        return (
            <div className="floated-form__body__preloader">
                <div className="loading-spinner__wrapper">
                    <span className="loading-spinner" />
                </div>
            </div>
        );
    }
}
