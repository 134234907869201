import {showError} from 'airborne/store/modules/search_hotels/actions/index';
import {apiError} from 'airborne/search2/helpers/apiError';
import {RETRY} from 'airborne/checkout2/helpers/retry';

import {ratesRequest} from './ratesRequest';
import {handleApiError} from './air';

import StreamService, {MESSAGE_TYPES} from 'airborne/services/Stream';
import {getChunksCountById, resetChunksCountById} from 'airborne/helpers/chunksProcessing';

export function sortRates(id) {
    return {type: 'CHANGE_RATES_ORDER', id};
}

export function expandRates(id, data) {
    return {type: 'RATES_EXPAND', id, data};
}

const RETRY_DELAY = 2 * 1000;
const FALLBACK_TIMEOUT = 45 * 1000;

export function filterRates(id, filters) {
    return {
        type: 'RATES_FILTER',
        data: filters,
        id,
    };
}
export function setRateOrder(id, order) {
    return {
        type: 'CHANGE_RATES_ORDER',
        data: order,
        id,
    };
}

let chunks = {};

let completedData = null;

const loadOneRatesChunk = hotelId => {
    return async function loadOneRatesChunkD(dispatch, getState) {
        const {rates, searchId, completed, warnings, chunksRequested, chunksReceived} = await ratesRequest(
            getState(),
            hotelId
        );
        const loadedType = completed ? 'RATES_LOADED' : 'RATES_LOADED_PARTIAL';
        dispatch({type: loadedType, data: rates, id: hotelId, warnings, searchId});
        return {rates, searchId, completed, warnings, chunksRequested, chunksReceived};
    };
};

async function waitForChunks(chunksLeft, searchId, hotelId, dispatch) {
    function conditionCheck(event) {
        const {message} = event;
        const chunksCount = chunks[message.hotelId] || 0;
        const newChunk = `${message.searchId}${message.hotelId}`;
        const oldChunk = `${searchId}${hotelId}`;
        if (newChunk === oldChunk) {
            chunks = {
                ...chunks,
                [hotelId]: chunksCount + 1,
            };
        }
        if (chunks[hotelId] === chunksLeft) {
            chunks = {
                ...chunks,
                [hotelId]: 0,
            };
            resetChunksCountById(searchId);
            return true;
        }
    }

    function pollingCheck(event) {
        const {chunksRequested, chunksReceived} = event;
        if (chunksRequested === chunksReceived) {
            return true;
        }
    }

    try {
        await StreamService.subscribeOnEventOrPolling({
            eventType: MESSAGE_TYPES.HOTEL_RATES_CHUNK,
            eventProcessor: event => {
                if (event?.polling) {
                    return pollingCheck(event);
                }
                return conditionCheck(event);
            },
            pollingInterval: RETRY_DELAY,
            getProcessId: () => null,
            eventDeadline: FALLBACK_TIMEOUT,
            pollingExecutor: async eventProcessor => {
                const {chunksRequested, chunksReceived, completed} = await dispatch(loadOneRatesChunk(hotelId));
                if (completed) {
                    completedData = true;
                }
                return eventProcessor({
                    chunksRequested,
                    chunksReceived,
                    polling: true,
                });
            },
        });
    }
    catch (error) {
        throw error;
    }
}

const getRatesLoop = hotelId => {
    return async function getRatesLoopD(dispatch) {
        completedData = null;
        const {rates, searchId, completed, chunksRequested} = await dispatch(loadOneRatesChunk(hotelId));

        if (completed) {
            return rates;
        }
        const receivedChunks = getChunksCountById(searchId);
        const chunksLeft = chunksRequested - receivedChunks;
        if (chunksLeft === 0) {
            await dispatch(loadOneRatesChunk(hotelId));
            return;
        };
        await waitForChunks(chunksLeft, searchId, hotelId, dispatch);
        if (completedData) {
            return;
        }
        await dispatch(loadOneRatesChunk(hotelId));
    };
};

export function getRates(hotelId, rethrow = false) {
    return async function getRatesD(dispatch) {
        dispatch({type: 'RATES_LOADING', id: hotelId});
        try {
            return await dispatch(getRatesLoop(hotelId));
        }
        catch (response) {
            try {
                handleApiError(response);
                dispatch({type: 'RATES_ABORT', id: hotelId});
            }
            catch (error) {
                if (response !== RETRY) {
                    dispatch(showError(apiError(response)));
                }
                dispatch({type: 'RATES_FAIL', id: hotelId});
            }
            if (rethrow) {
                throw response;
            }
        }
    };
}

export function getRatesOnce(hotelId, rethrow) {
    return getRates(hotelId, rethrow);
}

export function selectRate(hotelId, rateKey, booktype) {
    return {type: 'SELECT_RATE', id: hotelId, rateKey, booktype};
}

export function unselectRate(hotelId) {
    return {type: 'UNSELECT_RATE', id: hotelId};
}
