function serializeGroup(group) {
    return {
        enabled: group.enabled,
        id: group.id || null,
        match_conditions: {
            fields: group.tspm.map(value => value.value).join(),
            operator: 'equal',
            type: 'TSPM',
            value: group.value,
        },
        name: group.groupName,
    };
}

function parseGroup(group) {
    return {
        enabled: group.enabled,
        id: group.id,
        groupName: group.name,
        value: group.match_conditions.value,
        tspm: group.match_conditions.fields.split(',').map(value => ({value: value, label: value})),
        sections: group.sections
    };
}

export function serialize(data) {
    data['policy_groups:configured_groups'] = data['policy_groups:configured_groups'].map(
        serializeGroup
    );
    return data;
}

export function parse(data) {
    let dataToParse = data['policy_groups:configured_groups'];
    if (dataToParse.length === 0) {
        return data;
    }
    return {
        ...data,
        'policy_groups:configured_groups': dataToParse.map(parseGroup),
    };
}
