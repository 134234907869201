import axios from 'axios';
import {getCookie} from 'midoffice/helpers/ajax';
import {applyShackletonMock} from 'midoffice/helpers/shackleton';

const parseIn = ([{url, method, ...options}]) => [method, url, options];
const parseOut = ([method, url, options]) => ([{url, method, ...options}]);

export const REQUEST_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
};

export class AxiosApiProvider {
    constructor({axiosInstance} = {}) {
        this.decoratedAxios = applyShackletonMock(axiosInstance ? axiosInstance : axios, {parseIn, parseOut});
    }

    isFirstRequest = true;
    apiUrl = '';

    getSettings = async () => {
        this.isFirstRequest = false;

        try {
            const response = await this.request({url: '/settings'});
            this.apiUrl = response.data['API_URL'];
        }
        catch (error) {
            // we want to ignore any errors caused by /settings call
        }
    }

    getRequestUrl = (url) => {
        const regExp = /^https?:\/\//i;
        if (regExp.test(url) || !this.apiUrl) {
            return url;
        }

        return `${this.apiUrl}${url}`;
    }

    getHeaders = () => {
        return {
            'X-CSRFToken': getCookie('csrftoken'),
        };
    }

    async request({
        url,
        method= REQUEST_METHODS.GET,
        data = null,
        signal = null
    } = {}) {
        // eslint-disable-next-line no-undef
        if (process.env.EXTERNAL_API && this.isFirstRequest) {
            await this.getSettings();
        }

        const response = await this.decoratedAxios({
            url: this.getRequestUrl(url),
            headers: this.getHeaders(),
            method,
            data,
            signal,
        });

        return response;
    }
}

const axiosApiProvider = new AxiosApiProvider();

export default axiosApiProvider;
