import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {injectField} from 'midoffice/newforms/decorators';
import {listMap} from 'midoffice/helpers/array';

import StatelessAutocomplete from 'midoffice/data/StatelessAutocomplete';

import React from 'react';
import PropTypes from 'prop-types';
import Tags from './Tags';

function addLabel(value, choicesMap) {
    return {
        label: choicesMap[value] || value,
        value,
    };
}

export function addLabels(value, choices) {
    if (!value) {
        return value;
    }

    if (!isArray(value)) {
        value = [value];
    }

    return value.map((part)=> addLabel(part, choices));
}

function stripLabels(value) {
    if (!value) {
        return null;
    }

    return value.map((part)=> isObject(part) ? part.value : part);
}

@injectField
export default class SelectTags extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        choices: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        autocompleteSource: PropTypes.object,
    };

    static defaultProps = {
        autocompleteSource: StatelessAutocomplete,
    }

    handleChange = (value, name) => {
        this.props.onChange(stripLabels(value), name);
    };

    render() {
        const {value, choices, autocompleteSource, ...props} = this.props;

        return (<Tags
            value={addLabels(value, listMap(choices))}
            choices={choices}
            autocompleteSource={autocompleteSource}
            autocompleteExtra={{data: choices}}
            {...props}
            onChange={this.handleChange} />);
    }
}
