import React from 'react';
import PageTitle from 'midoffice/components/PageTitle';

import Messages from './Messages';
import MessageEdit from './MessageEdit';


export default class AftMessaging extends React.Component {
    render() {
        return (
            <div className="container-full container-full--top-fix">
                <header className="content-header">
                    <h3 className="content-header__title">AFT Messaging</h3>
                </header>
                <Messages />
                <MessageEdit />
                <PageTitle>AFT Messaging</PageTitle>
            </div>
        );
    }
}
