import get from 'lodash/get';
import timeout from 'airborne/search2/helpers/timeout';

export const RETRY = Symbol('retry');
export const IMMEDIATELY_RETRY = Symbol('Immediately call retry');

export function retry(fn, retryTimeout, triesLeft, patienceLeft=0) {
    return Object.defineProperty(function(...args) {
        return async function(dispatch) {
            try {
                return await dispatch(fn(...args));
            }
            catch (error) {
                if ([RETRY, IMMEDIATELY_RETRY].includes(error) && triesLeft > 0) {
                    await timeout(error === IMMEDIATELY_RETRY ? 0 : retryTimeout);
                    return await dispatch(retry(fn, retryTimeout, triesLeft - 1, patienceLeft)(...args));
                }
                if (get(error,'request.isAborted')) {
                    throw error;
                }
                if (error && error.status === 0 && patienceLeft > 0) {
                    await timeout(retryTimeout);
                    return await dispatch(retry(fn, timeout, triesLeft, patienceLeft - 1)(...args));
                }

                throw error;
            }
        };
    }, 'name', {value: 'retry_' + fn.name});
}

