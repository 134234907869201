import {fetchCollection, paginateCollection, sortCollection, checkEntry, addEntry, changeEntry, removeEntry} from './common';
import {hideModal, showModal} from './modals';


const endpoint = 'aftMessaging';

export const addMessage = (data)=> (dispatch)=> (
    dispatch(addEntry({endpoint, ...data}, true))
);

export const changeMessage = (id, data)=> (dispatch)=> (
    dispatch(changeEntry({endpoint, id, data}, true))
);

export const checkMessage = (id, flag)=> (dispatch)=> (
    dispatch(checkEntry({endpoint, id, flag}))
);

export const fetchMessages = ()=> (dispatch)=> (
    dispatch(fetchCollection(endpoint))
);

export const sortMessages = (field)=> (dispatch)=> (
    dispatch(sortCollection(endpoint, field))
);

export const paginateMessages = (params)=> (dispatch)=> (
    dispatch(paginateCollection(endpoint, params))
);

export const removeMessages = (ids)=> (dispatch)=> (
    Promise.all(ids.map((id)=> (
        dispatch(removeEntry(endpoint, id, null, true))
    )))
);

export const hideEditModal = ()=> (dispatch)=> (
    dispatch(hideModal('messageEdit'))
);

export const showEditModal = (id)=> (dispatch)=> (
    dispatch(showModal('messageEdit', {id}))
);
