import * as types from "airborne/store/modules/pricing_air/actionTypes";

const defaultState = {
    separatedTicketsMode: false,
    currentOD: 0,
    separatedFares: {
        0: null,
        1: null,
    },
};

const separatedTickets = (state = defaultState, {type, currentOD, separatedFare}) => {
    switch (type) {
        case types.AIR_ENABLE_SEPARATED_TICKETS:
            return {...state, separatedTicketsMode: true};
        case types.AIR_SET_SEPARATED_TICKET_OD:
            return {...state, currentOD};
        case types.AIR_RESET_SEPARATED_TICKETS:
            return defaultState;
        case types.AIR_SAVE_SEPARATED_FARE:
            return {
                ...state,
                separatedFares: {
                    ...state.separatedFares,
                    [currentOD]: separatedFare,
                }
            }
        default:
            return state;
    }
};

export default separatedTickets;
