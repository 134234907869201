import * as defaults from './defaults';

const TYPES = {};

function getHandler(type, name) {
    return type in TYPES && name in TYPES[type]
        ? TYPES[type][name]
        : defaults[name]; // eslint-disable-line import/namespace
}

export function getUrl(type, actionType, ...args) {
    return getHandler(type, actionType)(type, ...args);
}

export function parse(type, object, ...args) {
    return getHandler(type, 'parse')(object, ...args);
}

export function parseList(type, list, ...args) {
    const {parse, parseList} = TYPES[type] || {};
    list = parseList ? parseList(list, ...args) : list;
    return parse ? list.map((object, idx)=> parse(object, ...args, idx)) : list;
}

export function serialize(type, object, ...args) {
    return getHandler(type, 'serialize')(object, ...args);
}

export function register(name, type) {
    TYPES[name] = type; // eslint-disable-line immutable/no-mutation
}
