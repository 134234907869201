import Mousetrap from 'mousetrap';

export const SHORTCUTS_LIST = {
    A: 'a',
    B: 'b',
    C: 'c',
    D: 'd',
    E: 'e',
    F: 'f',
    G: 'g',
    H: 'h',
    I: 'i',
    J: 'j',
    K: 'k',
    L: 'l',
    M: 'm',
    N: 'n',
    O: 'o',
    P: 'p',
    Q: 'q',
    R: 'r',
    S: 's',
    T: 't',
    U: 'u',
    V: 'v',
    W: 'w',
    X: 'x',
    Y: 'y',
    Z: 'z',
    LOGOUT: 'shift+q',
    SETTINGS: 'shift+s',
    OPTION: 'option',
    ALT: 'alt',
    ENTER: 'enter',
    TAB: 'tab',
    COMMAND: 'command',
    CTRL: 'ctrl',
};

const createOptionShortcut = (shortcut) => {
    return [`option+${shortcut}`, `alt+${shortcut}`];
};

export const bindShortcut = (shortcut, action) => {
    let preventDoubleAction = false;
    Mousetrap.bind(createOptionShortcut(shortcut), () => {
        if (!preventDoubleAction) {
            action();

            preventDoubleAction = true;
            setTimeout(() => preventDoubleAction = false, 100);
        }
    });
};

export const bindOptionDefaultShortcut = (onKeyDown, onKeyUp) => {
    Mousetrap.bind([SHORTCUTS_LIST.OPTION, SHORTCUTS_LIST.ALT], () => {
        if (document.activeElement.tagName === 'INPUT') {
            return false;
        }

        onKeyDown();

        setTimeout(onKeyUp, 2000);
    }, 'keydown');

    Mousetrap.bind([SHORTCUTS_LIST.OPTION, SHORTCUTS_LIST.ALT], onKeyUp, 'keyup');

    // prevent display shortcuts after switching workspace on window/ubuntu
    Mousetrap.bind(['alt+tab'], onKeyUp, 'keyup');
};
