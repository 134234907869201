import moment from 'moment';

import settings from 'airborne/settings';
import {register} from 'airborne/types';
import {formatUrl} from 'midoffice/helpers/url';
import {dropEmpty, serializeDateRange, serializeDatetimeRange} from 'midoffice/helpers/urlParams';

function serializeValue(value) {
    return value.map((part)=> part.value);
}

function optional(fn, value, ...args) {
    return value ? fn(value, ...args) : null;
}

function serialize({ts_creation: create, checkin, checkout, is_ghost: isGhost, entity, entity_aft: entityAft, ...params}) {
    return dropEmpty({
        ...params,
        'entity': entity || optional(serializeValue, entityAft),
        'checkin': optional(serializeDateRange, checkin),
        'ts_creation': optional(serializeDatetimeRange, create),
        'checkout': optional(serializeDateRange, checkout),
        'is_ghost': isGhost || null,
    });
}


function fetchCollection() {
    return ['POST', formatUrl('/api/bookings/search/')];
}

function parse(booking) {
    return {
        ...booking,
        'checkin': optional(moment, booking['checkin'], settings.DEFAULT_DATE_FORMAT),
        'checkout': optional(moment, booking['checkout'], settings.DEFAULT_DATE_FORMAT),

        'created_ts': optional(moment.unix, booking['created_ts']),
        'cancellation_ts': optional(moment.unix, booking['cancellation_ts']),
        'cancellation_error_ts': optional(moment.unix, booking['cancellation_error_ts']),
    };
}

register('bookings', {parse, serialize, fetchCollection});
