/* global IS_PROD */

import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import {sentryState} from 'midoffice/helpers/format';
import {applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

// window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ - global enhancer generated by Redux DevTools browser plugin for connecting store
// trace turns on "Trace" tab in devtools, there is info about from where actions has been dispatched
// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Features/Trace.md
const composeEnhancers = IS_PROD
    ? compose
    : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true}))
    || compose;
const ravenMiddleware = createRavenMiddleware(Raven, {
    stateTransformer: sentryState
});

const createMiddleware = composeEnhancers(
    applyMiddleware(thunk, ravenMiddleware)
);

export default createMiddleware;
