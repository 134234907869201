import {formatUrl} from 'midoffice/helpers/url';

export function fetchCollection(endpoint, params) {
    return formatUrl(`/api/${endpoint}/`, params);
}

export function fetchEntry(endpoint, id) {
    return `/api/${endpoint}/${id}/`;
}

export function addEntry(endpoint) {
    return `/api/${endpoint}/`;
}

export function changeEntry(endpoint, id) {
    return `/api/${endpoint}/${id}/`;
}

export function removeEntry(endpoint, id) {
    return `/api/${endpoint}/${id}/`;
}

export function parse(object) {
    return object;
}

export function serialize(object) {
    return object;
}
