import {removeAtIdx} from 'midoffice/helpers/editAtIdx';

import {fetchCollection, loadedEntry} from 'midoffice/data/actions/collection';
export {fetchCollection, loadedEntry};


export function paramHelper(state, endpoint, filterEndpoint) {
    const {
        [endpoint]: {pageBy, sortBy: ordering}
    } = state;

    const query = filterEndpoint
        ? state[filterEndpoint].value
        : null;

    const params = {
        'page': pageBy['page'],
        'page_size': pageBy['page_size'],
        ...query,
        ordering,
    };
    return {params, query};
}


export function serverSortCollection(endpoint, filterEndpoint, ordering, ...args) {
    return function serverSortCollectionD(dispatch, getState) {
        const {params, query} = paramHelper(getState(), endpoint, filterEndpoint);
        return dispatch(fetchCollection(endpoint, {...params, ordering}, false, query, ...args));
    };
}

export function serverPaginateCollection(endpoint, filterEndpoint, pageBy, ...args) {
    return function serverPaginateCollectionD(dispatch, getState) {
        const {params, query} = paramHelper(getState(), endpoint, filterEndpoint);
        return dispatch(fetchCollection(endpoint, {...params, ...pageBy}, false, query, ...args));
    };
}

export function serverSearchCollection(endpoint, filterEndpoint, ...args) {
    return function searchCollectionD(dispatch, getState) {
        const {params, query} = paramHelper(getState(), endpoint, filterEndpoint);
        return dispatch(fetchCollection(endpoint, {...params, page: 1}, false, query, ...args));
    };
}

export function changeForm(endpoint, {value, errors=null}) {
    return {
        type: 'FORM_CHANGED', endpoint, value, errors,
    };
}

export function clearTags(searchFn, filterEndpoint) {
    return function clearTagsD(dispatch) {
        dispatch(changeForm(filterEndpoint, {value: {}}));
        return dispatch(searchFn());
    };
}


function removeHelper(form, key, idx) {
    const {[key]: fieldValue, ...value} = form;
    const fromArray = Array.isArray(fieldValue) && fieldValue.length > 1;
    return fromArray ? {
        ...value,
        [key]: removeAtIdx(fieldValue, idx)
    } : value;
}

function removeFormTag(endpoint, key, idx) {
    return function removeFormTagD(dispatch, getState) {
        const {[endpoint]: {value}} = getState();
        dispatch(changeForm(endpoint, {value: removeHelper(value, key, idx)}));
    };
}

export function removeTag(searchFn, filterEndpoint, {key, idx}) {
    return function removeTagD(dispatch) {
        dispatch(removeFormTag(filterEndpoint, key, idx));
        return dispatch(searchFn());
    };
}
