/* global module */
'process i18n';
import React from 'react';
import PropTypes from 'prop-types';

import gettext from 'airborne/gettext';

import PageTitle from 'midoffice/components/PageTitle';
import ProductTabs from 'midoffice/components/ProductTabs';

import {getBookingRecordsProducts} from 'midoffice/data/products';

import {MicroFrontEnd} from 'airborne/microFrontEnds/MicroFrontEnds';

class BookingRecordsPage extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    };

    componentWillUnmount() {
        document.body.classList.remove('layout-rev');
    }

    render() {
        const products = getBookingRecordsProducts().map(product => ({
            ...product,
            url: `/bookings/${product.id}/`,
        }));
        const product = 'rail';

        return (
            <div className="container-full container-full--top-fix">
                <PageTitle>{gettext('Rail Booking Records')}</PageTitle>
                <div className="content-header content-header--tabs">
                    <ProductTabs products={products} selected={product} />
                    <MicroFrontEnd name="rail" />
                </div>
            </div>
        );
    }
}

export default BookingRecordsPage;
