/* eslint-disable immutable/no-mutation */
import document from 'airborne/document';

export const AFT_FAVICON_PATH = 'midoffice/img/favicon-as.ico';

function removeFavicon() {
    const links = document.head.getElementsByTagName('link');

    for (const link of links) {
        if (link.getAttribute('rel') === 'shortcut icon') {
            document.head.removeChild(link);
        }
    }
}

function setFavicon(settings) {
    removeFavicon();

    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = settings['STATIC_URL'] + AFT_FAVICON_PATH;

    document.head.appendChild(link);
}

export default setFavicon;
