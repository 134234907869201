import {batch} from 'react-redux';
import {
    getAirExchangeBookingOriginDestinationCodes,
    getAirExchangeBookingDates,
    getAirExchangeFormValue, getAirExchangeFlownIndicators,
} from 'airborne/store/modules/exchange_air/selectors';
import {changeSingleAirExchangeFormValue} from './form';
import {getTripType} from 'airborne/homepage2/AirDestinationSearch/helpers';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {getDateLimits} from 'airborne/homepage2/helpers/dateLimits';
import AirDestinationAutocomplete from "airborne/homepage2/autocomplete/AirDestinationAutocomplete";

function getLookupTripType(destinationCodes) {
    const commonDestinations = destinationCodes.map(([departureCode, arrivalCode]) => ({
        pickUp: {iataCode: departureCode},
        dropOff: {iataCode: arrivalCode},
    }));

    return getTripType(commonDestinations);
}

const getDestinations = async (originDestinationCodes) => {
    return await Promise.all(
        originDestinationCodes.map(async ([departureCode, arrivalCode], index) => {
            const departureAutocomplete = new AirDestinationAutocomplete(`exchange_departure_${index}`, false);
            const arrivalAutocomplete = new AirDestinationAutocomplete(`exchange_arrival_${index}`, false);

            const results = await Promise.all([
                departureAutocomplete.query(departureCode),
                arrivalAutocomplete.query(arrivalCode),
            ]);

            return results.map(citiesAndAirports => {
                return citiesAndAirports.find(element => element.type === 'a');
            });
        })
    );
};

export const prefillAirExchangeForm = () => async (dispatch, getState) => {
    const originDestinationCodes = getAirExchangeBookingOriginDestinationCodes(getState());
    const dates = getAirExchangeBookingDates(getState());

    const destinations = await getDestinations(originDestinationCodes);
    const [dateMin, dateMax = null] = dates;
    const tripType = getLookupTripType(originDestinationCodes);

    const {originDestinations} = getAirExchangeFormValue(getState());
    const flownIndicators = getAirExchangeFlownIndicators(getState());

    const filledDestinations = destinations.map(([pickUp, dropOff], index) => ({
        ...originDestinations?.[index],
        pickUp,
        dropOff,
        dateRange: {min: dateMin, max: dateMax},
        flownIndicator: flownIndicators[index],
    }));

    const isRoundTrip = tripType === AIR_TRIP_TYPES.ROUND_TRIP;
    const firstODFlown = filledDestinations[0].flownIndicator;
    const roundTripOriginDestinations = firstODFlown ? filledDestinations : filledDestinations.slice(0, 1);

    if (isRoundTrip && firstODFlown) {
        const [origin, destination] = roundTripOriginDestinations;
        const {dateRange: {min, max}} = origin;
        const {min: today} = getDateLimits();
        origin.departureDate = min;
        origin.dateRange.min = today;
        destination.dateRange.min = max;
    }

    const formOriginDestinations = isRoundTrip
        ? roundTripOriginDestinations
        : filledDestinations;

    batch(() => {
        dispatch(changeSingleAirExchangeFormValue(tripType, 'tripType'));
        dispatch(changeSingleAirExchangeFormValue(formOriginDestinations, 'originDestinations'));
    });

};
