import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import identity from 'lodash/identity';
import capitalize from 'lodash/capitalize';
import {createSelector} from 'reselect';
import gettext from 'airborne/gettext';

import normalize from 'airborne/search2/helpers/normalize';

import paginateHelper from 'midoffice/helpers/paginate';
import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';


export function getHotelRates(state, hotelId) {
    return state.hotels.rates.data[hotelId] || null;
}

export function hasHotelRates(state, hotelId) {
    const rates = getHotelRates(state, hotelId);
    return Boolean(rates && rates.length);
}

export function getHotelRatesLoading(state, hotelId) {
    return state.hotels.rates.loading[hotelId] || false;
}

export function getHotelRatesSelection(state, hotelId) {
    return state.hotels.rates.selected[hotelId] || null;
}

export function getHotelRatesWarnings(state, hotelId) {
    return state.hotels.rateWarnings[hotelId] || null;
}

function getRates(state, hotelId) {
    return get(state, ['hotels', 'rates', 'data', hotelId]);
}

export const getRate = createSelector(
    getRates,
    (state, hotelId, rateKey) => rateKey,
    (state, hotelId, rateKey, shouldNormalize=true) => shouldNormalize ? normalize : identity,
    (rates, rateKey, proc)=> {
        return proc(find(rates, {'rate_key': rateKey}));
    },
);

export function getSelectedRateKey(state, index) {
    return getBooking(state, index).rateKey;
}

export function getSelectedHotelId(state, index) {
    return getBooking(state, index).hotelId;
}

export const getSelectedRate = createSelector(
    (state)=> state,
    getSelectedHotelId,
    getSelectedRateKey,
    (state, index, shouldNormalize)=> shouldNormalize,
    (state, hotelId, rateKey, shouldNormalize)=> getRate(state, hotelId, rateKey, shouldNormalize),
);


function findHotel(hotels, hotelId) {
    const altHoltes = hotels.hotels.data[0] && hotels.hotels.data[0]['alternative_hotels'] || [];
    return normalize(find([...hotels.hotels.data, ...altHoltes], {id: hotelId}));
}

export const getHotel = createSelector(
    (state)=> state.hotels,
    (state, hotelId)=> hotelId,
    findHotel,
);

export const getSelectedHotel = createSelector(
    (state)=> state.hotels,
    getSelectedHotelId,
    findHotel,
);


const EMPTY = [];
export const DEFAULT_PAGINATED = {rates: null, expandable: false, expanded: 'initial', ratesLeft: 0, order: 'sort_metric'};
const RATES_PAGE_SIZE = 5;
export function paginate(
    fullList=EMPTY,
    orderBy=DEFAULT_PAGINATED.order,
    expanded=DEFAULT_PAGINATED.expanded,
    filterBy=[],
) {
    const pagination = expanded === 'all' ? null : {'page': 1, 'page_size': RATES_PAGE_SIZE};
    const list = fullList.filter((rate)=> !rate.qualities || rate.qualities.length === 0);

    const {paged, fullPagedList, params} = paginateHelper(list, orderBy, pagination, null, null, filterBy);
    const ratesLeft = params.total > RATES_PAGE_SIZE ? params.total - RATES_PAGE_SIZE : params.total;
    return {
        rates: map(paged, 'rate_key'),
        expandable: params.total > RATES_PAGE_SIZE,
        fullPagedList: fullPagedList,
        ratesLeft,
        order: orderBy,
        filterBy: filterBy,
        expanded,
    };
}

export function getDetails({hotels: {rates}}, hotelId, rateKey) {
    const rate = find(rates.data[hotelId], {'rate_key': rateKey}) || {};
    const {
        detailsLoading: loading=false,
        detailsLoaded: loaded=false,
        ...details
    } = rate;

    return {details, loading, loaded};
}

export function getFilters({hotels: {rates}}, id) {
    return rates.filters[id] || {};
}

export const getRateFilterById = (state, id) =>
    getFilters(state, id).rateFilter || [];

export function getOrder({hotels: {rates}}, id) {
    return rates.order[id] || 'sort_metric';
}

const ROOM_LABEL_MAP = {
    standard: gettext('Standard'),
    'studio/apartment': gettext('Studio/Apartment'),
    'deluxe/superior': gettext('Deluxe/Superior'),
    suite: gettext('Suite'),
    executive: gettext('Executive'),
    other: gettext('Other'),
};

export const displayRoomType = roomType => ROOM_LABEL_MAP[roomType]
    ? gettext(ROOM_LABEL_MAP[roomType])
    : gettext('Unknown');

const BED_LABEL_MAP = {
    king: gettext('king'),
    queen: gettext('queen'),
    twin: gettext('twin'),
    double: gettext('double'),
    single: gettext('single'),
    other: gettext('other'),
};

export const displayBedTypes = bedTypes => bedTypes
    ? capitalize(
        bedTypes.map(item => gettext(BED_LABEL_MAP[item])).join(', ')
    )
    : gettext('Unknown');
