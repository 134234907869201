import * as types from '../actionTypes';

const defaultFilters = {
    onlyAvailable: [true],
};
const filters = (state = defaultFilters, {type, value}) => {
    switch (type) {
        case types.AIR_AVAILABILITY_START_SEARCH:
            return defaultFilters;
        case types.AIR_AVAILABILITY_SET_FILTERS:
            return value;
        default:
            return state;
    }
};

export default filters;
