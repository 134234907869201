import air from './air';
import {serializeDestination} from 'airborne/search2/helpers/hotels';
import {searchForm} from 'airborne/homepage2/helpers/search';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

export default function getCompanyLocations() {
    const URL = '/get_company_locations/';

    return function getCompanyLocationsD(dispatch, getState) {
        const {profile, dest} = searchForm(getState(), 0);

        const params = dropEmpty({
            'destination': serializeDestination(dest.destination.value),
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'search_radius': dest.distance || null,
        });
        dispatch({type: 'COMPANY_LOCATIONS_LOADING'});

        return air('GET', URL, params).then(({'company_locations': data})=> {
            dispatch({type: 'COMPANY_LOCATIONS_LOADED', data});
        }).catch(()=> {
            dispatch({type: 'COMPANY_LOCATIONS_FAIL'});
        });
    };
}
