'process i18n';
import React from 'react';
import gettext from 'airborne/gettext';
import systemData from 'airborne/systemData';

export default class UATWarning extends React.Component {
    render() {
        if (!systemData.common.IS_UAT) {
            return null;
        }
        const domain = systemData.common.SITE_SUBDOMAIN === 'admin' ? 'admin' : 'www';
        return (
            <div className="header-message">
                <div className="container">
                    <h3 className="header-message__title">
                        {gettext('You are accessing User Acceptance Testing (UAT) environment.')}
                    </h3>

                    {gettext('This is a test system. Please log out and use ')}
                    <a href={`https://${domain}.agentsourcebooking.com`} target="_blank">
                        {domain}.agentsourcebooking.com
                    </a>
                    {gettext(' for live (production) system access.')}
                </div>
            </div>
        );
    }
}
