import freshBookingsActionTypes from 'midoffice/store/modules/freshBookings/actionTypes';

export default function freshBookings(state = [], action) {
    switch (action.type) {
        case freshBookingsActionTypes.SET:
            return action.payload;
        case freshBookingsActionTypes.CLEAR:
            return [];
        default:
            return state;
    }
}
