import bedTypesActionTypes from 'midoffice/store/modules/bedTypes/actionTypes';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';

export const setCategories = payload => ({
    type: bedTypesActionTypes.SET_CATEGORIES,
    payload,
});


export const setDescriptions = payload => ({
    type: bedTypesActionTypes.SET_DESCRIPTIONS,
    payload
});

export const setLabels = () => ({
    type: bedTypesActionTypes.SET_LABELS
});

export const fetchCategories = function() {
    return async function fetchCategoriesD(dispatch) {
        const categories = await api('GET', '/internal/ml/labeling/get_label_categories/?locale=' + settings.USER_LOCALE + '&session_id=' + (settings.sid || 'none'));
        dispatch(setCategories(categories));
    };
};

export const fetchDescriptions = function() {
    return async function fetchCategoriesD(dispatch) {
        const descriptions = await api('GET', '/internal/ml/labeling/get_descriptions/?sample_size=1');
        dispatch(setDescriptions(descriptions));
    };
};

export const postLabels = function(data) {
    return async function postLabelsD(dispatch) {
        await api('POST', '/internal/ml/labeling/label/', {data});
        dispatch(fetchDescriptions());
    };
};
