import {AirNgsState} from './types';
import {RESET_NGS_DATA, SET_NGS_DATA} from '../actionTypes';
import {AirNgsAction} from '../actions/types';

const initialState: AirNgsState = {
    fareSearchResponse: null,
    availabilityResponse: null,
};

const airNgsData = (state: AirNgsState = initialState, action: AirNgsAction): AirNgsState => {
    switch (action.type) {
        case SET_NGS_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_NGS_DATA:
            return initialState;
        default:
            return state;
    }
};

export default airNgsData;
