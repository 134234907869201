const FIELDS = {
    INVOICE_PAYMENT: 'supplier:totalstay:invoice_payment',
    CARD_PAYMENT: 'supplier:totalstay:card_payment',
};

export function parse(data) {
    const {
        [FIELDS.INVOICE_PAYMENT]: invoice,
        [FIELDS.CARD_PAYMENT]: card,
        ...rest
    } = data;

    let paymentOptions = [];
    if (invoice) { paymentOptions.push('invoice'); }
    if (card) { paymentOptions.push('card'); }

    /* Payment options -- representation of invoice_payment and card_payment
     * parameters. Possible payment options values:
     * "invoice", "card", "invoice,card"
     */
    paymentOptions = paymentOptions.join(',');

    return {paymentOptions, ...rest};
}

export function serialize({paymentOptions, ...data}) {
    paymentOptions = paymentOptions.split(',');

    return {
        ...data,
        [FIELDS.INVOICE_PAYMENT]: paymentOptions.includes('invoice'),
        [FIELDS.CARD_PAYMENT]: paymentOptions.includes('card'),
    };
}
