import {default as airborneStore} from 'airborne/store';
import {store as midofficeStore} from 'midoffice/store';
import api from 'midoffice/helpers/api';
import systemData from 'airborne/systemData';

export function getTerms() {
    const URL = '/terms_en/';
    const appStore = systemData.common.SITE_SUBDOMAIN === 'admin' ? midofficeStore : airborneStore;
    const headers = {headers: {'Accept': 'application/xml'}, responseType:'document', fullResponse: true};

    return api('GET', URL, headers)
        .then((data) => {
            appStore.dispatch({type: 'SET_TERMS', text: data.body.querySelector('.page-box').outerHTML});
        });
}
