import {dropEmpty} from 'midoffice/helpers/urlParams';
import isEmpty from 'lodash/isEmpty';
import session from 'airborne/session';

export const PRODUCT_TYPES = {
    HOTEL: 'hotel',
    FLIGHTS: 'flights',
    CAR: 'car',
};

const SESSION_KEY = 'broadstreetTargets';

export const setBroadStreetTargets = ({destination, product, smid} = {}) => {
    const targets = dropEmpty({
        'shopped_product': product,
        destination,
        smid,
    });

    if (!isEmpty(targets)) {
        session.set(SESSION_KEY, targets);
        window.broadstreet?.setOptions({targets});
    }
    else {
        window.broadstreet?.setOptions({
            targets: session.get(SESSION_KEY) || {}
        });
    }

    window.broadstreet?.clearCampaignLog();
    window.broadstreet?.refreshAll();
};
