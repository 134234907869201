import noop from 'lodash/noop';
import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import {TextArea} from 'midoffice/components/IE9';

import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class Textarea extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        name: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        rows: PropTypes.number,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        className: 'form-control',
        type: 'text',
        rows: 5,
        placeholder: '',
        disabled: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop
    };

    handleChange = (event) => {
        const {name} = this.props;
        this.props.onChange(event.target.value, name);
    }

    render() {
        let value = this.props.value;
        if (value == null) { value = ''; }
        return (
            <TextArea {...pick(this.props, Object.keys(Textarea.propTypes))}
                value={value}
                onChange={this.handleChange} />
        );
    }
}
