import union from 'lodash/union';
import without from 'lodash/without';
import groupBy from 'lodash/groupBy';
import chunk from 'lodash/chunk';
import zip from 'lodash/zip';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from './Checkbox';
import {injectField} from 'midoffice/newforms/decorators';
import compact from 'lodash/compact';
import omit from 'lodash/omit';

class Option extends React.Component {

    static propTypes = {
        value: PropTypes.any.isRequired,
        selected: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        testIdPrefix: PropTypes.string,
    };

    handleChange = (checked)=> {
        const {value} = this.props;
        this.props.onChange(value, checked);
    };

    render() {
        const {selected, testIdPrefix, ...props} = this.props;
        return (<Checkbox {...props}
            value={selected}
            onChange={this.handleChange}
            {...(testIdPrefix ? {'data-testid': `${testIdPrefix}-${props.value}`} : {})} />);
    }
}

@injectField
export default class MultiCheckbox extends React.Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        wrapperStyle: PropTypes.object,
        value: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        table: PropTypes.bool,
        border: PropTypes.bool,
        scroll: PropTypes.bool,
        isSort: PropTypes.bool,
        groups: PropTypes.bool,
        isTransposed: PropTypes.bool,
        choices: PropTypes.array,
        disabledChoices: PropTypes.array,
        maxCheckedNumber: PropTypes.number, // other choices will be disabled after
        testIdPrefix: PropTypes.string,

        columns: PropTypes.number,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        className: '',
        value: [],
        columns: 1,
        disabled: false,
        readOnly: false,
        table: true,
        border: true,
        scroll: false,
        isSort: true,
        isTransposed: false,
    };

    getRows = () => {
        const {choices, isTransposed, columns} = this.props;

        const chunkSize = isTransposed
            ? Math.ceil(choices.length / columns)
            : columns;

        const rows = chunk(this.props.choices, chunkSize);
        return isTransposed
            ? zip(...rows).map(compact)
            : rows;
    };

    handleChange = (partValue, checked)=> {
        const value  = this.props.value ? [...this.props.value] : [];
        const newValue = checked
            ? union(value, [partValue])
            : without(value, partValue);

        if (this.props.isSort) {
            newValue.sort();
        }

        this.props.onChange(newValue, this.props.name);
    };

    renderChoice([partValue, label, description], idx) {
        const {value, maxCheckedNumber, disabledChoices = []} = this.props;
        const selected = Boolean(value && value.includes(partValue)) || disabledChoices.includes(partValue);
        const disabled = Boolean(this.props.disabled ||
            (maxCheckedNumber && !selected && value.length >= maxCheckedNumber)) ||
            disabledChoices.includes(partValue);

        const checkboxLabel = (<span>
            {label}
            {description && (
                <span className="text-secondary">{` - ${description}`}</span>
            )}
        </span>);
        const props = omit(this.props, ['isTransposed']);
        return (<Option {...props}
            key={idx}
            value={partValue}
            selected={selected}
            label={checkboxLabel}
            disabled={disabled}
            onChange={this.handleChange} />);
    }

    renderCell = ([value, ...rest]=[]) => (
        <td key={value}>
            {this.renderChoice([value, ...rest])}
        </td>
    );

    renderRow = (row, index) => (
        <tr key={index}>
            {row.map(this.renderCell)}
        </tr>
    );

    renderTable() {
        return (
            <table style={{width: '100%'}}>
                <tbody>
                    {this.getRows().map(this.renderRow)}
                </tbody>
            </table>
        );
    }

    renderDiv() {
        const {choices, border, scroll, groups, wrapperStyle} = this.props;
        const className = classnames({
            'multi-selector-body': border,
            'controls': scroll,
        });
        const choicesObj = choices.map(([name, label, description, group], index)=> ({index, data: [name, label, description], group}));

        return (<div className={className} style={wrapperStyle}>
            {groups
                ? Object.entries(groupBy(choicesObj, 'group'))
                    .map(([group, choices], idx)=>
                        <div key={idx}>
                            <label className="control-label">{group}</label>
                            {choices.map((el, idx)=> this.renderChoice(el.data, idx))}
                        </div>
                    )
                : choices.map((el, idx)=> this.renderChoice(el, idx))
            }
        </div>);
    }

    render() {
        return this.props.table ? this.renderTable()
            : this.renderDiv();
    }
}
