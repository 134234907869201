import noop from 'lodash/noop';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import settings from 'airborne/settings';
import {searchForm, serializeSSO, serializeTSPM} from 'airborne/homepage2/helpers/search';
import {getPrevBookingId, isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {apiError, isErrorRecoverable} from 'airborne/search2/helpers/apiError';
import air, {handleApiError} from 'airborne/store/modules/search_hotels/actions/air';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {checkoutForm, isCNPEnabled} from 'airborne/checkout2/helpers/checkoutForm';
import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';
import {normilizeCCN} from 'airborne/checkout2/helpers/cardValidators';
import {trimSpacesFromNames} from 'airborne/checkout2/helpers/names';
import {isCrdKey} from 'airborne/checkout2/helpers/crd';
import {sanitizeSpecialRequest} from 'airborne/checkout2/helpers/specialRequests.js';
import {getSelectedRate} from 'airborne/search2/helpers/rates';
import {getReservationDetals} from 'airborne/store/modules/confirm_hotels/actions/details';
import {getPrevReservationDetals} from 'airborne/store/modules/confirm_hotels/actions/prevdetails';
import {toConfirmation} from 'airborne/store/modules/confirm_hotels/actions/redirect';
import {getAllBookingUIDs} from 'airborne/store/modules/confirm_hotels/selectors/details';
import {getCrd} from 'airborne/store/modules/checkout_hotels/selectors/crd';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import isEmpty from 'lodash/isEmpty';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';


export function showNoAcceptedCardsModal() {
    return showModal('noAcceptedCards');
}

export function changeBooking({value, errors}, index) {
    return {type: 'CHANGE_BOOKING_FIELDS', value, errors, index};
}

export function nextStep(index) {
    return function (dispatch, getState) {
        const crd = getCrd(getState(), index);
        const book = getSelectedRate(getState(), index, false);

        dispatch({
            type: 'TO_NEXT_CHECKOUT_STEP',
            skipDetails: crd.length === 0,
            skipRecommendation: !isEmpty(book.qualities),
            index,
        });
        return Promise.resolve();
    };
}

export function prevStep(index) {
    return function (dispatch, getState) {
        const crd = getCrd(getState(), index);

        dispatch({
            type: 'TO_PREV_CHECKOUT_STEP',
            skipDetails: crd.length === 0,
            index,
        });
        return Promise.resolve();
    };
}

export function toStep(step, index) {
    return {type: 'TO_CHECKOUT_STEP', step, index};
}


export function monthAsStr(month) {
    return [null, '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'][month];
}


export function selectCrd(value) {
    return Object.entries(value)
        .filter(([key, value])=> isCrdKey(key) && !!value)
        .map(([key,value])=> ({'answer': value, 'code': key}));
}

export const PAYMENT_FIELDS = {
    'card': [
        'card_index',
        'credit_card_first_name',
        'credit_card_last_name',
        'credit_card_number',
        'credit_card_identifier',
        'credit_card_expiration_year',
        'credit_card_expiration_month',
        'credit_card_country_code',
        'credit_card_state_province_code',
        'credit_card_company_name',
        'credit_card_address',
        'credit_card_address2',
        'credit_card_city',
        'credit_card_postal_code',
        'credit_card_phone',
        'charge_guarantee_card',
    ],
    'vpa_payment': [
        'vpa_card_pool_id',
        'vpa_payment_instructions',
        'vpa_no_send_fax',
    ],
    'vpa_guarantee': [
        'vpa_card_pool_id',
    ],
    'iata': [],
    'invoice': [],
};

export function fixCard(form) {
    const {
        'credit_card_expiration': ccExp,
        'credit_card_phone': ccPhone,
        ...rest
    } = form;

    return {
        ...rest,
        'credit_card_expiration_month': ccExp && monthAsStr(ccExp.month),
        'credit_card_expiration_year': ccExp && ccExp.year,
        'credit_card_number': normilizeCCN(rest['credit_card_number']),
        'credit_card_phone': ccPhone && ccPhone.number,
    };
}

function omitStateField(data) {
    const countryCode = data?.['credit_card_country_code'];
    const shouldOmitStateField = !settings.COUNTRY_STATES[countryCode];
    return shouldOmitStateField ? omit(data, 'credit_card_state_province_code') : data;
}

function serializePayment(form) {
    const {'paid_by': paidBy} = form;
    const fixFn = {
        card: fixCard,
    }[paidBy];
    const data = fixFn ? fixFn(form) : form;
    return pick(omitStateField(data), PAYMENT_FIELDS[paidBy]);
}

function serializePaymentMethod(method, evoucher) {
    return evoucher
        ? `evoucher_${method}`
        : method;
}

function serializeLoyaltyCard(form, rate) {
    const [number, program] = (form['loyalty_card'] || '').split('\u001E');
    return {
        'loyalty_card_number': number || null,
        'loyalty_card_chain_code': program || (number ? rate.chainCode : null),
    };
}

function serializeCNP(form, enabled) {
    return {
        'charge_guarantee_card': enabled
            ? Boolean(form['charge_guarantee_card'])
            : null,
    };
}

function bookFailure(response, index) {
    return function bookFailureD(dispatch) {
        const recoverable = isErrorRecoverable(response);
        const message = apiError(response) || 'EUNKNOWN';
        return dispatch({type: 'BOOKING_FAIL2', message, recoverable, index});
    };
}

function waitPreviousBooking(index) {
    return function waitPreviousBookingD(dispatch, getState) {
        const previousBookingId = getPrevBookingId(getState(), index);
        if (!previousBookingId) {
            return Promise.resolve();
        }

        return dispatch(getPrevReservationDetals(index, previousBookingId))
            .catch(noop);
    };
}

function waitOneConfirmation(uid, index) {
    return function waitOneConfirmationD(dispatch) {
        return uid
            ? dispatch(getReservationDetals(index))
                .then(()=> dispatch(waitPreviousBooking(index)))
                .then(()=> dispatch(toConfirmation(index)))
                .catch(handleApiError)
                .catch((response)=> dispatch(bookFailure(response, index)))
            : Promise.resolve();
    };
}

function getBookingGroupId(state) {
    if (!isMultiPnr(state)) {
        return null;
    }
    const {sid, USER: {id}} = settings;
    return `${sid}:${id}`;
}

export function waitConfirmation() {
    return function waitConfirmationD(dispatch, getState) {
        return Promise.all(
            getAllBookingUIDs(getState())
                .map((uid, index)=> dispatch(waitOneConfirmation(uid, index)))
        );
    };
}

const excludeCrdDuplicates = (crd, form) => {
    return Object.entries(form)
        .filter(([code, answer]) => {
            return (isCrdKey(code) && !!answer) &&
                    crd.map(question => question.code).includes(code);
        })
        .map(([key,value])=> ({'answer': value, 'code': key}));
};

export function confirmBooking(index) {
    return function makeBookingD(dispatch, getState) {
        const {profile, dest, sso, previousBookingId} = searchForm(getState(), index);
        const booking = getBooking(getState(), index);
        const {value: form} = checkoutForm(getState(), index);
        const crd = getCrd(getState(), index);
        const rate = getSelectedRate(getState(), index);

        const params = dropEmpty(trimSpacesFromNames({
            'rate_key': booking.rateKey,
            'hotel_id': booking.hotelId,
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'pnr_id': profile.pnr || null,
            ...serializePayment(form),
            ...serializeLoyaltyCard(form, rate),
            ...serializeCNP(form, isCNPEnabled(getState(), index)),
            'payment_method': serializePaymentMethod(form['paid_by'], form['evoucher']),
            'client_reportable_data': excludeCrdDuplicates(crd, form),
            'booktrack_id': `${booking.uuid}R${booking.retry}P${index||0}`,
            'booker_email': settings.USER.email,
            'guest_count': dest.guests,
            'original_total': rate.originalTotal,
            'original_currency': rate.originalCurrency,
            'salutation': form.salutation || null,
            'traveler_first_name': form['first_name'],
            'traveler_last_name': form['last_name'],
            'traveler_email': form.email,
            'special_request': sanitizeSpecialRequest(form['special_requests'], rate.provider) || null,
            'recommendation_original_rate_key': booking.recommend.originalRateKey || null,
            'recommendation_rejected_rate_key': booking.recommend.rejectedRateKey || null,
            'recommendation_reject_reason': booking.recommend.excuse || null,
            'previous_booking_uid': previousBookingId,
            ...serializeSSO(sso),
            ...serializeTSPM(profile),
            'booking_group_id': getBookingGroupId(getState()),
            'counter_offer_email': rate.isOnRequest ? form['counter_offer_email'] : null,
        }));

        dispatch({type: 'BOOKING_LOADING2', index});

        return air('POST', '/hotels/book/', params)
            .then((response)=> dispatch({type: 'BOOKING_UID', uid: response['booking_uid'], index}))
            .then(()=> dispatch(getReservationDetals(index)))
            .then(()=> dispatch(waitPreviousBooking(index)))
            .then(()=> dispatch(toConfirmation(index)))
            .catch((response)=> dispatch(bookFailure(response, index)));
    };
}

export function toSearch() {
    return {type: 'TO_SEARCH_RESULT', index: 'all'};
}
