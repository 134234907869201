/* eslint-disable immutable/no-mutation */
import isEqual from 'lodash/isEqual';
import extend from 'lodash/extend';
import fromPairs from 'lodash/fromPairs';
import clone from 'lodash/clone';
import without from 'lodash/without';

import React from 'react';

import {Form, NestedForm} from './forms';


/**
 * Binds a field instance and provide an interface to interact
 * with the field in the code (i.e. when rendering).
 * @class {BoundField}
 */
export class BoundField {

    /**
     * @param {Field} field - the wrapped Field instance
     * @param {string} fieldName - name of the field on the form
     * @param {(Form|BoundField)} owner - instance that holds this field
     */
    constructor(field, fieldName, owner) {
        this.field = field;
        this.owner = owner;
        this.name = fieldName;
    }

    /**
     * Get field's value from container
     * @returns {*}
     */
    get value() { return this.owner.get(this.name); }

    /**
     * Update field's value
     * @param {*} value
     */
    set value(value) { this.owner.set(this.name, value); }

    toRepresentation() {
        return this.field.toRepresentation(this.value);
    }

    /**
     * Get list of errors for this field.
     * Returns null if there's no errors.
     * @returns {(String[]|null)}
     */
    get errors() {
        if (this.owner.errors && this.name in this.owner.errors) {
            let errors = this.owner.errors[this.name];
            return Array.isArray(errors) ? errors : [errors];
        }
        return null;
    }

    /** Clear all errors for this field */
    clearErrors() { this.owner.clearErrors(this.name); }

    /**
     * Getter for field's initial value.
     * @returns {*}
     */
    get initial() {
        let initial = this.owner.initial;
        return initial ? initial[this.name] : null;
    }

    /**
     * Check if this field's data has changed since initialization
     * @returns {boolean}
     */
    get changed() { return !isEqual(this.value, this.initial); }

    /** Trigger form's validation of this field */
    validate() { return this.owner.validate(this.name); }

    /**
    * Deferr field validation for some short time.
    * This code makes sure that validation occures after blur event
    * is dispatched and handled.
    **/
    deferrValidation() {
        window.clearTimeout(this._validateInt);
        this._validateInt = window.setTimeout(()=> this.validate(), 100);
    }

    /**
    * Abort any scheduled vlidation and clear existing errors
    **/
    resetValidation() {
        window.clearTimeout(this._validateInt);
        this.clearErrors();
    }

    /**
     * Get props for field component rendering.
     * @param {object} [extraProps] - extra properties to override
     * @returns {object}
     */
    props(extraProps={}) {
        let runtimeProps = {
            field: this,
            name: this.name,
            value: this.value,
            errors: this.errors,
            onChange: (value)=> {
                if (value && value.target) {
                    this.value = value.target.value;
                }
                else {
                    this.value = value;
                }
            },
            onBlur: ()=> { this.deferrValidation(); },
            onFocus: ()=> { this.resetValidation(); }
        };

        return extend(
            fromPairs(Object.entries(this.field)),
            this.field.props,
            runtimeProps,
            extraProps
        );
    }

    /**
     * Renders a default widget for the field
     * @param {object} [widgetProps] - optional additional properties
     * @returns {React.Element}
     */
    renderWidget(widgetProps) {
        return React.createElement(this.field.widget, widgetProps);
    }
}


/**
 * BoundField for Schema that has additional methods
 * to interact with the wrapped schema.
 * @class {BoundSchemaField}
 * @extends {BoundField}
 */
export class BoundSchemaField extends BoundField {

    /**
     * A map of this schema fields bound to this field as owner
     * @returns {object.<string, BoundField>}
     */
    get fields() {
        if (!this._fields) {
            this._fields = fromPairs(
                this.field.getFields()
                    .map(([name, subField])=> [name, subField.getBoundField(name, this)])
            );
        }
        return this._fields;
    }

    /**
     * Get nested field's value
     * @param {string} field
     * @returns {*}
     */
    get(field) {
        let value = this.value;
        if (value != null && field in value) {
            return value[field];
        }
    }

    /**
     * Update owner container with nested field's incoming value
     * @param {string} field - field's name
     * @param {*} value - the value to set
     */
    set(field, value) {
        this.value = extend(this.value, {[field]: value});
    }

    /**
     * Get a Form instance for this schema.
     * @returns {Form}
     */
    get form() {
        if (!this._form) {
            this._form = new NestedForm({
                owner: this,
                schema: this.field
            });
        }
        return this._form;
    }
}


/**
 * BoundField for SchemaList that has additional methods
 * to interact with the wrapped list of schemas.
 * @class {BoundSchemaListField}
 * @extends {BoundField}
 */
export class BoundSchemaListField extends BoundField {

    /**
     * Returns a list of BoundSchemaField for each item of the list
     * @returns {BoundField[]}
     */
    get items() {
        let items = this.value;

        if (!Array.isArray(items)) { return []; }

        // Should regenerate cached bound fields if the containing
        // array has changed
        if (!this._items || this._items.length !== items.length) {
            this._items = items.map((value, index)=>
                this.field.child.getBoundField(index, this));
        }

        return this._items;
    }

    /**
     * A form for new item.
     * Adds a new item when a form is saved and removes the form.
     * @returns {Form}
     */
    get newItemForm() {
        if (!this._newForm) {
            this._newForm = new Form({schema: this.field.child});
            this._newForm.on('save', (data)=> {
                delete this._newForm;
                this.add(data);
            });
        }
        return this._newForm;
    }

    /**
     * Add an item
     * @param {object} item
     */
    add(item) {
        this.value = this.value.concat([item]);
    }

    /**
     * Clone an item
     * @param {number} index
     */
    clone(index) {
        let value = this.value;
        value.splice(index, 0, clone(value[index]));
        this.value = value;
    }

    /**
     * Remove an item
     * @param {number} index
     */
    remove(index) {
        let value = this.value;
        this.value = without(value, value[index]);
    }

    /** Data access method for when this field is an owner */
    get(field) { return this.value[field]; }

    /** Data set method for for when this field is an owner */
    set(field, value) {
        //delete this._items;
        let fieldValue = clone(this.value);
        fieldValue[field] = value;
        this.value = fieldValue;
    }
}

