import mapValues from 'lodash/mapValues';

import {filterSections} from 'midoffice/companies/permissionFilter';


export const SECTIONS_BY_PRODUCT = {
    'general': [
        {
            label: 'General',
            items: [
                {section: 'contact-info',
                    title: 'Agency Contact Info', permission: 'midoffice:agencies:general:agency_contact_info:view'},
                {section: 'regions',
                    title: 'Custom Geographic Groups', permission: 'midoffice:agencies:general:custom_geographic_groups:view'},
                {section: 'booking_support_email',
                    title: 'Agency Booking Support Email', permission: 'midoffice:agencies:general:agency_booking_support_email:view'},
                {section: 'as-desktop',
                    title: 'Agentsource Desktop Integration', permission: 'midoffice:agencies:general:agentsource_desktop_integration:view'},
                {section: 'invoice-collection',
                    title: 'TripSource Invoice Collection', permission: 'midoffice:agencies:general:invoice_collection:view'},
                {section: 'tripsource-credits-and-unused-tickets',
                    title: 'TripSource Credits & Unused Tickets', permission: 'midoffice:agencies:general:tripsource_credits_and_unused_tickets:view'},
            ]
        }, {
            label: 'GDS',
            items: [
                {section: 'general-pnr-remarks',
                    title: 'Mandatory PNR Remarks Template', permission: 'midoffice:agencies:general:mandatory_pnr_remarks_template:view'},
                {section: 'amadeus-credentials',
                    title: 'Amadeus Credentials', permission: 'midoffice:agencies:general:amadeus_credentials:view'},
                {section: 'sabre-credentials',
                    title: 'Sabre Credentials', permission: 'midoffice:agencies:general:sabre_credentials:view'},
                {section: 'apollo-credentials',
                    title: 'Apollo Credentials', permission: 'midoffice:agencies:general:apollo_credentials:view'},
                {section: 'galileo-credentials',
                    title: 'Galileo Credentials', permission: 'midoffice:agencies:general:galileo_credentials:view'},
            ]
        }, {
            label: 'Other',
            items: [
                {section: 'regional-settings',
                    title: 'Default Display Preferences', permission: 'midoffice:agencies:general:default_display_preferences:view'},
                {section: 'terms-and-conditions',
                    title: 'Terms & Conditions', permission: 'midoffice:agencies:general:terms_conditions:view'},
                {section: 'custom-country-codes',
                    title: 'Custom Countries of Fulfillment', permission: 'midoffice:agencies:general:custom_countries_of_fulfillment:view'}
            ]
        },
    ],

    'hotels': [
        {
            label: 'Hotels',
            items: [
                {section: 'tmc-preferred-hotels',
                    title: 'BCD Travel Preferred Hotels', permission: 'midoffice:agencies:hotels:bcd_travel_preferred_hotels:view'},
                {section: 'hotel-exclusions',
                    title: 'Agency Hotel Exclusions', permission: 'midoffice:agencies:hotels:agency_hotel_exclusions:view'},
                {section: 'branch-hotel-exclusions', title: 'Branch Hotel Exclusions', permission: 'midoffice:agencies:hotels:branch_hotel_exclusions:view'},
            ]
        }, {
            label: 'Rates',
            items: [
                {section: 'rate-codes', title: 'BCD Travel Preferred Rate Codes', permission: 'midoffice:agencies:hotels:bcd_travel_preferred_rate_codes:view'},
                {section: 'rate-exclusions', title: 'Rate Exclusions', permission: 'midoffice:agencies:hotels:rate_exclusions:view'},
                {section: 'agency-rate-optimization', title: 'Rate Optimization', permission: 'midoffice:agencies:hotels:rate_optimization:view'},
                {section: 'revenue-management', title: 'Revenue Management', permission: 'midoffice:agencies:hotels:revenue_management:view'},
                {section: 'search-engine-configuration', title: 'Search Engine Configuration', permission: 'midoffice:agencies:hotels:search_engine_configuration:view'},
                {section: 'rate-overrides', title: 'Rate Overrides', permission: 'midoffice:agencies:hotels:rate_overrides:view'},
            ]
        }, {
            label: 'GDS',
            items: [
                {section: 'pnr-remarks',
                    title: 'Mandatory PNR Remarks Template', permission: 'midoffice:agencies:hotels:mandatory_pnr_remarks_template:view'},
                {section: 'passive-segments',
                    title: 'Passive Segment PNR Templates', permission: 'midoffice:agencies:hotels:passive_segment_pnr_templates:view'},
                {section: 'backoffice-send',
                    title: 'Send to backoffice after remarks are added', permission: 'midoffice:agencies:hotels:backoffice_send:view'},
            ]
        },
    ],

    'cars': [
        {
            label: 'GDS',
            items: [
                {section: 'cars-pnr-remarks',
                    title: 'Mandatory PNR Remarks Template', permission: 'midoffice:agencies:cars:mandatory_pnr_remarks_template:view'},
                {section: 'cars-agency-configuration',
                    title: 'Agency Configuration', permission: 'midoffice:agencies:cars:agency_configuration:view'},
                {section: 'cars-backoffice-send',
                    title: 'Send to backoffice after remarks are added', permission: 'midoffice:agencies:cars:backoffice_send:view'},
            ]
        },
        {
            label: 'Policy',
            items: [
                {section: 'cars-negotiated-rates',
                    title: 'Negotiated Rates', permission: 'midoffice:agencies:cars:agency_negotiated_rates:view'},
                {section: 'cars-preferred-rates',
                    title: 'Preferred Vendors & Car Types', permission: 'midoffice:agencies:cars:agency_preferred_rates:view'},
                {section: 'cars-rate-exclusions',
                    title: 'Rate Exclusions', permission: 'midoffice:agencies:cars:agency_rate_exclusions:view'},
            ]
        },
    ],
    'air': [
        {
            label: 'Fares',
            items: [
                {section: 'agency-negotiated-fares', title: 'BCD negotiated fares', permission: 'midoffice:agencies:flights:bcd_negotiated_fares:view'},
                {section: 'air-exclusions', title: 'Air Exclusions', permission: 'midoffice:agencies:flights:exclusions:view'},
                {section: 'air-preferred-airlines', title: 'Preferred Airlines', permission: 'midoffice:companies:flights:preferred_airlines:view'},
                {section: 'air-lcc-corporate-credentials', title: 'LCC Corporate', permission: 'midoffice:agencies:flights:lcc_corporate_credentials:view'},
                {section: 'air-policy', title: 'Air Policy', permission: 'midoffice:agencies:flights:policy:view'},
            ]
        },
        {
            label: 'GDS',
            items: [
                {section: 'air-pnr-remarks',
                    title: 'Mandatory PNR Remarks Template', permission: 'midoffice:agencies:flights:mandatory_pnr_remarks_template:view'},
                {section: 'air-passive-segments',
                    title: 'Passive Segment Configuration', permission: 'midoffice:agencies:flights:passive_segment_configuration:view'},
                {section: 'air-gds-ticketing', title: 'Ticketing', permission: 'midoffice:agencies:flights:gds_ticketing_configuration:view'},
            ]
        },
        {
            label: 'Suppliers',
            items: [
                {section: 'farelogix-credentials',
                    title: 'Farelogix credentials', permission: 'midoffice:agencies:flights:farelogix_credentials:view'},
            ]
        },
        {
            label: 'Miscellaneous',
            items: [
                {
                    section: 'air-office-hours',
                    title: 'Office Hours',
                    permission: 'midoffice:agencies:flights:office_hours:view'
                },
            ],
        },
    ],
};


export function getSections() {
    return mapValues(
        SECTIONS_BY_PRODUCT,
        (sections)=> filterSections(sections)
    );
}
