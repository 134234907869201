import * as actionTypes from '../actionTypes';
import {applyPatch} from 'midoffice/newforms/helpers';

export const INITIAL_MANUAL_EXCHANGE_FORM = {
    taxes: [],
    newBaseFarePrice: 0,
    originalBaseFarePrice: 0,
    baseFareDifference: 0,
    residualTaxes: 0,
    addCollectTaxes: 0,
    penalty: 0,
    commission: 0,
    totalCollect: 0,
    totalRefund: 0,
};

export const initialManualExchangeFormState = {
    value: INITIAL_MANUAL_EXCHANGE_FORM,
    errors: null,
};
const form = (state = initialManualExchangeFormState, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_MANUAL_EXCHANGE_FORM_RESET:
            return initialManualExchangeFormState;
        case actionTypes.AIR_MANUAL_EXCHANGE_FORM_CHANGE:
            return {value: payload.value, errors: payload.errors};
        case actionTypes.AIR_MANUAL_EXCHANGE_FORM_PATCH:
            const {toChange, toRemove} = payload;

            return {
                ...state,
                ...applyPatch({value: state.value, errors: state.errors, toChange, toRemove}),
            };
        default:
            return state;
    }
};

export default form;
