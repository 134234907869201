import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import noop from 'lodash/noop';
import last from 'lodash/last';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import defer from 'lodash/defer';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {injectField} from 'midoffice/newforms/decorators';

import Autocomplete from './Autocomplete';


function lastIndex(array, value) {
    return findLastIndex(array, (item)=> item === value);
}

function removeByIndex(array, index) {
    if (index < 0 || index > array.length) { return array; }

    return array.slice(0, index).concat(array.slice(index + 1));
}


class Tag extends React.Component {

    static propTypes = {
        tag: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string.isRequired,
                    PropTypes.number.isRequired,
                ]),
                label: PropTypes.string.isRequired,
            }),
        ]),
        index: PropTypes.number.isRequired,
        error: PropTypes.string,
        onRemove: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        error: '',
        disabled: false
    };

    handleRemove = (event)=> {
        event.preventDefault();
        this.props.onRemove(this.props.tag, this.props.index);
    };

    render() {
        let className = cx('tag', {
            disabled: this.props.disabled,
            'has-error': Boolean(this.props.error)
        });
        let tag = this.props.tag;
        return (
            <span className={className}>
                {typeof tag.label !== 'undefined' ? tag.label : tag}
                <span data-role="remove" onClick={this.handleRemove}>
                    &times;
                </span>
            </span>
        );
    }
}


function readonlyIndices(tags, readonlyTags) {
    return readonlyTags.map((readonlyTag)=> findIndex(tags, (tag)=> tag === readonlyTag));
}

@injectField
class Tags extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        autocompleteSource: PropTypes.shape({
            query: PropTypes.func.isRequired
        }),
        autocompleteExtra: PropTypes.object,
        disabled: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.bool,
            PropTypes.string,
        ]),
        inputSize: PropTypes.oneOf([
            'max', 'xlarge', 'large', 'medium', 'small', 'xsmall']),
        onChange: PropTypes.func.isRequired,
        onCommit: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onInteraction: PropTypes.func,
        onKeyDown: PropTypes.func,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.array,
        readonlyTags: PropTypes.array,
        /**
         * Plain changes structure of the value to `['value1', 'value2']`
         * instead of `[{value: 'value1', label: 'Label1'}, {value: 'value2, label: 'Label2'}]`
         */
        plain: PropTypes.bool,
        /**
         * Allow values that aren't in the `autocompleteSource.query` results
         */
        freeText: PropTypes.bool,
    };

    static defaultProps = {
        value: [],
        readonlyTags: [],
        disabled: false,
        inputSize: 'max',
        placeholder: '',
        onFocus: noop,
        onBlur: noop,
        onInteraction: noop,
        onKeyDown: noop,
        autofocus: false,
        plain: false,
        freeText: false,
    };

    state = {
        inputValue: '',
    };


    static getDerivedStateFromProps({inputValue}, prevState) {
        if (isString(inputValue) && inputValue !== prevState.inputValue) {
            return {inputValue};
        }

        return null;
    }

    removeTag(tag, tagIndex) {
        const {readonlyTags, value: tags} = this.props;

        if (typeof tagIndex === 'undefined') {
            tagIndex = lastIndex(tags, tag);
        }
        if (readonlyIndices(tags, readonlyTags).includes(tagIndex)) { return; }

        const newtags = removeByIndex(tags, tagIndex);

        this.props.onChange(newtags, this.props.name);
    }

    focus() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    handleBlur = ()=> {
        const {freeText} = this.props;
        const {inputValue} = this.state;
        if (inputValue) {
            this.setState({inputValue: null});
        }

        if (this.props.onCommit && inputValue) {
            this.props.onCommit(inputValue);
        }
        else if (inputValue && freeText) {
            this.handleCommit(inputValue);
        }

        defer(()=> {
            this.props.onBlur();
        });
    };

    handleCommit = (value)=> {
        if (this.props.onCommit) {
            this.setState({inputValue: null});
            return this.props.onCommit(value);
        }
        if (value) {
            this.setState({inputValue: null});

            if (this.props.plain) {
                value = value.value;
            }

            let oldValue = this.props.value || [];
            let newValue = oldValue.concat(value);

            this.props.onChange(newValue, this.props.name);
        }
    };

    handleReference = (input)=> {
        this.inputRef = input; // eslint-disable-line immutable/no-mutation
    };

    handleRemove = (tag, tagIndex)=> {
        this.removeTag(tag, tagIndex);
    };

    handleInputChange = (value)=> {
        this.setState({inputValue: value});
    };

    handleKeyDown = (event)=> {
        let inputValue = this.state.inputValue;
        if (event.key === 'Backspace' && !inputValue) {
            this.removeTag(last(this.props.value));
            return;
        }
        this.props.onKeyDown(event);
        this.props.onInteraction();
    };

    render() {
        let {value, readonlyTags, disabled, errors, className, autocompleteExtra} = this.props;
        let {inputValue} = this.state;
        value = value || [];
        if (value && !isArray(value)) {
            value = [value];
        }
        autocompleteExtra = {
            ...autocompleteExtra,
            selectedValues: value,
        };

        // in current situation errors may be assigned to Tags only they have array type
        let tagsErrors = find(errors, (error) => isArray(error)) || [];
        className = cx('tags-input', className, {
            'has-error': errors
        });

        let readonly = readonlyIndices(value, readonlyTags);
        let tags = value.map((tag, index)=>
            <Tag key={index}
                tag={tag}
                index={index}
                error={tagsErrors[index]}
                disabled={disabled || readonly.includes(index)}
                onRemove={this.handleRemove} />
        );
        return (<Autocomplete {...this.props}
            autocompleteExtra={autocompleteExtra}
            className={className}
            ref={this.handleReference}
            prepend={tags}
            value={inputValue}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            onCommit={this.handleCommit} />);
    }
}

export default Tags;
