import * as types from '../actionTypes';
import {createHandler} from 'airborne/store/config/middlewares/stream';
import {requestFeatureFlags} from 'airborne/store/modules/featureFlags/actions';

export const valueSwitcher = createHandler(
    types.ROLLOUT_FLAGS,
    (store) => {
        store.dispatch(requestFeatureFlags());
    }
);


export default createHandler(
    types.FEATURE_FLAGS,
    (store) => {
        store.dispatch(requestFeatureFlags());
    }
);
