import {BULK_ADDED, BULK_FAILED, BULK_LOADING} from './types';

import api from 'midoffice/helpers/api';

import {campaignBulkAction} from './campaigns';


export function bulkAction({endpoint, key}) {
    if (endpoint === 'campaigns' || endpoint === 'posts') {
        return campaignBulkAction(endpoint, key);
    }

    return null;
}

export function bulkLoading(endpoint, fileName, data = {}) {
    return {type: BULK_LOADING, endpoint, fileName, data};
}

export function bulkAdded(endpoint, fileName, response) {
    return {type: BULK_ADDED, endpoint, fileName, response};
}

export function bulkFailed(endpoint, fileName, response) {
    return {type: BULK_FAILED, endpoint, fileName, response};
}

const ENDPOINT_ATTEMPTS = {
    'agency:tmc_preferred:hotels': 60,
};

export function bulkUpload({endpoint, uploadUrl, fileName, ...data}) {
    return function (dispatch) {
        return api('POST', uploadUrl, {
            data,
            attempts: ENDPOINT_ATTEMPTS[endpoint],
            onUploadProgress: function progress(e, response) {
                dispatch(bulkLoading(endpoint, fileName, {
                    progress: e.loaded / e.total * 100,
                    request: response.request
                }));
            }
        })
            .then((response)=> {
                dispatch(bulkAdded(endpoint, fileName, response));
                return response;
            })
            .catch((response)=> {
                dispatch(bulkFailed(endpoint, fileName, response));
            });
    };
}


export function companiesBulkUpload({fileName, ...data}) {
    return bulkUpload({
        endpoint: 'companies',
        uploadUrl: '/api/companies/start_create_bulk/',
        fileName,
        ...data,
    });
}

export function usersUpload({fileName, ...data}) {
    return bulkUpload({
        endpoint: 'users',
        uploadUrl: '/api/users/start_create_bulk/',
        fileName,
        ...data
    });
}

