import {INITIAL_AIR_VALUE} from 'airborne/store/modules/homepage/reducers/destination';
import * as actionTypes from '../actionTypes';

export const initialFormState = {
    value: INITIAL_AIR_VALUE,
    errors: null,
};
const form = (state = initialFormState, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_EXCHANGE_RESET_FORM:
            return initialFormState;
        case actionTypes.AIR_EXCHANGE_CHANGE_FORM:
            return {value: payload.value, errors: payload.errors};

        case actionTypes.AIR_EXCHANGE_CHANGE_FORM_DESTINATION:
            return {
                value: {
                    ...state.value,
                    [payload.fieldName]: payload.fieldValue,
                },
                errors: state.errors,
            };

        default:
            return state;
    }
};

export default form;
