import wrap from 'midoffice/components/LazyComponent';

function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./index.js');
            resolve(loaded);
        }, 'entities');
    });
}

export const AgenciesRoot = wrap(load, 'AgenciesRoot');
export const CompaniesRoot = wrap(load, 'CompaniesRoot');
