import StreamService, {MESSAGE_TYPES} from 'airborne/services/Stream';
let CHUNKS_COUNT = {};

export function getChunksCountById(searchId) {
    return CHUNKS_COUNT[searchId] || 0;
}

function incrementChunksCountById(searchId) {
    const currentCount = getChunksCountById(searchId);


    CHUNKS_COUNT[searchId] = currentCount + 1;
}

export function resetChunksCountById(searchId) {
    delete CHUNKS_COUNT[searchId];
}

StreamService.subscribe((event) => {
    const {type, message} = event;

    if (type === MESSAGE_TYPES.HOTELS_RATES_CHUNK || type === MESSAGE_TYPES.HOTEL_RATES_CHUNK) {
        const {searchId} = message;

        incrementChunksCountById(searchId);
    }
});
