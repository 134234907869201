import {register} from 'airborne/types';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import normalize from 'airborne/search2/helpers/normalize';
import {retrieveDateEntities} from 'airborne/checkout_air/helpers/checkoutForm';

const CHECKOUT_DATA_FIELDS = [
    'first_name',
    'middle_name',
    'last_name',

    'gender',
    'genders_supported',
    'date_of_birth',
    'place_of_birth',

    'email',
    'phone',

    'secure_flight',

    'payment_options',
    'default_billing_address',

    'can_request_meal',
    'meal_request',
    'seat_preference',

    'billing_address_required',
    'cardholder_name_required',
    'cvv_required',
    'date_of_birth_required',
    'gender_required',
    'phone_number_required',
    'idcard_exp_date_required',
    'passport_required',
    'is_passport_or_national_id_required',

    'crd',
    'vpa_card_pools',

    'tsa_supported',
    'is_passport_required_for_redress',
    'tf_ff_numbers',
    'is_nationality_required',

    'salutation',
    'title',
    'suffix',
];

function parseIdCards({idcards}) {
    if (!idcards) {
        return [];
    }
    return idcards.map(idcard => {
        const {'expiration_date': expiryDate, number: idCardNumber, country: idCardCountry} = idcard;
        const [idCardExpiryYear, idCardExpiryMonth] = retrieveDateEntities(expiryDate);
        return {idCardNumber, idCardCountry, idCardExpiryMonth, idCardExpiryYear, expiryDate};
    });
}

function parse(data) {
    return {
        ...pickBy(pick(data, CHECKOUT_DATA_FIELDS)),
        creditCards: normalize(data.credit_cards),
        passports: normalize(data.passports),
        idCards: parseIdCards(data),
        ssrCodes: normalize(data['ssr_codes']),
        contentRestrictions: normalize(data.content_restrictions),
    };
}

register('airCheckoutData', {parse});
