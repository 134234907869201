import {createSelector} from 'reselect';
import {getFilteredFareGroups, isValidFareGroup} from './fareGroups';

export const getCheckedFlightOptionKeysByGroup = (state) =>
    state.air.checkedFlightOptionKeysByGroup;

export const getCheckedFlightOptionKeys = (state, groupKey) =>
    getCheckedFlightOptionKeysByGroup(state)[groupKey];

export const isFlightOptionChecked = createSelector(
    getFilteredFareGroups,
    getCheckedFlightOptionKeys,
    (_, fareGroupKey, ODIndex, optionKey) => ({fareGroupKey, ODIndex, optionKey}),
    (groups, checkedKeys, {fareGroupKey, ODIndex, optionKey}) => {
        const filteredFOs = groups.find(group => group.fareGroupKey === fareGroupKey)
            ?.originDestinations[ODIndex].flightOptions;
        const isFirst = filteredFOs?.[0].flightOptionKey === optionKey;

        if (!checkedKeys || !checkedKeys[ODIndex]) {
            // nothing checked by user, check first FO automatically
            return isFirst;
        }

        if (!filteredFOs.find(FO => FO.flightOptionKey === checkedKeys[ODIndex])) {
            // user checked some FO and then filtered it out, so check first visible FO
            return isFirst;
        }

        return checkedKeys[ODIndex] === optionKey;
    }
);

export const getSearchFlightOptionKeys = createSelector(
    getFilteredFareGroups,
    getCheckedFlightOptionKeys,
    isValidFareGroup,
    (_, fareGroupKey) => fareGroupKey,
    (groups, flightOptionKeys, isValidFareGroup, fareGroupKey) => {
        const group = groups.find((group)=> group.fareGroupKey === fareGroupKey);

        return isValidFareGroup
            ? group.originDestinations.map(({flightOptions}, ODIndex) => {
                const defaultKey = flightOptions[0].flightOptionKey;
                const checkedKey = flightOptionKeys?.[ODIndex];

                if (!checkedKey) {
                    return defaultKey;
                }

                return !!flightOptions.find(FO => FO.flightOptionKey === checkedKey)
                    ? checkedKey
                    : defaultKey;
            })
            : null;
    }
);
