import React from 'react';
import {Route} from 'react-router-dom';

import {PNRView, PNRTool} from 'midoffice/components/pnr-tool';


export default [
    <Route path="/pnr-tool/" exact component={PNRTool} />,
    <Route path="/pnr-tool/:entityId/:pnr/:system" component={PNRView} />
];
