import session from 'airborne/session';
import restorable from 'airborne/homepage2/persist/selectors';


export function saveData(sid, data) {
    const key = `sid:${sid}`;
    session.set(key, data);
}

export function restoreData(sid) {
    return session.get(`sid:${sid}`);
}

function findSlots(data) {
    const commands = (data || []).filter(([cmd])=> cmd === 'multipnr');
    if (!commands.length) return 1;
    const [[,number]] = commands;
    return number;
}

export function restoreLocal(sid, cloneSid) {
    const data = restoreData(cloneSid || sid);
    return {
        type: 'GLOBAL_RESTORE',
        data,
        slots: findSlots(data),
        selector: restorable,
        saver: (data)=> saveData(sid, data),
    };
}
