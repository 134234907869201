/**
 * Verifies, that given expression is true-like
 *
 * For example:
 *
 *     validate(a === b, 'a must be equal to b');
 *
 * @method validate
 *
 * @param {Boolean} expression evaluated expression value
 * @param {String} message error message
 */
export default function validate(expression, message) {
    if (!expression) {
        throw message;
    }
}
