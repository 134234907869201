import gettext from 'airborne/gettext';

export function getFareBasisCodes(fare) {
    const {fareComponents = []} = fare;
    return fareComponents.map(component => component.fareBasisCode);
}

export function getFarePriceClassNames(fare) {
    const {fareComponents = []} = fare;
    return fareComponents.map(component => component.priceClassName);
}

export function isAllPriceClassesTheSame(fare) {
    const {fareComponents} = fare;
    const [{priceClassCode, priceClassName}] = fareComponents;

    return fareComponents.every(component =>
        component.priceClassCode === priceClassCode || component.priceClassName === priceClassName
    );
}

export function getOriginalFareTitle(fare) {
    const defaultTitle = gettext('Originally Selected Fare');

    const {fareComponents} = fare;
    const [{priceClassName, priceClassCode}] = fareComponents;
    const suffix = priceClassName || priceClassCode;

    return suffix
        ? `${gettext('Original Fare:')} ${suffix}`
        : defaultTitle;
}
