import {combineReducers} from 'redux';
import * as types from '../actionTypes';


const data = (state = {}, {type, data}) => {
    if (type === types.AIR_SEAT_MAP_LOADED) {
        return {
            ...state,
            ...data
        };
    }

    return state;
};

const loading = (state = false, {type}) => {
    if (type === types.AIR_SEAT_MAP_LOADING) {
        return true;
    }

    if (type === types.AIR_SEAT_MAP_LOADED) {
        return false;
    }

    if (type === types.AIR_SEAT_MAP_FAIL) {
        return false;
    }
    return state;
};

const defaultRequests = [];
const requests = (state = defaultRequests, {type, requestId}) => {
    switch (type) {
        case types.AIR_SEAT_MAP_ADD_REQUEST:
            return [...state, requestId];
        case types.AIR_SEAT_MAP_CLEAR_REQUESTS:
        case types.AIR_TO_SEARCH_RESULT:
            return defaultRequests;
        default:
            return state;
    }
};

const errors = (state = null, {type, errors}) => {
    if (type === types.AIR_SEAT_MAP_LOADED) {
        return null;
    }

    if (type === types.AIR_SEAT_MAP_FAIL) {
        return errors;
    }
    return state;
};

export default combineReducers({
    data,
    loading,
    requests,
    errors,
});
