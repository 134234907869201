import gettext from 'airborne/gettext';
import React from 'react';

export const COMPANY_PREFERRED_LABEL = (
    <>
        <span className="ico-company-preferred" /> {gettext('Company Preferred')}
    </>
);
export const BCD_PREFERRED_LABEL = (
    <>
        <span className="ico-agency-preferred" /> {gettext('BCD Travel Preferred')}
    </>
);
