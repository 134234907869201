import getAvail from './avail';

export function sortHotels(order) {
    return function sortHotelsD(dispatch) {
        dispatch({type: 'CHANGE_HOTEL_ORDER', order});
        return dispatch(getAvail());
    };
}

export function paginateHotels(page) {
    return function paginateHotelsD(dispatch) {
        dispatch({type: 'CHANGE_HOTEL_PAGINATION', pagination: {page}});
        return dispatch(getAvail());
    };
}

export function selectHotel(id, tab) {
    return {type: 'SELECTED_HOTEL', id, tab};
}

export function unselectHotel(id) {
    return {type: 'UNSELECTED_HOTEL', id};
}

export function clearFiltersOnly() {
    return {type: 'CLEAR_HOTEL_FILTERS'};
}

export function clearFilters() {
    return function clearFiltersD(dispatch) {
        dispatch(clearFiltersOnly());
        return dispatch(getAvail());
    };
}

export function changeFilters({value, errors}) {
    return function changeFiltersD(dispatch) {
        dispatch({type: 'CHANGE_HOTEL_FILTERS', value, errors});
        return dispatch(getAvail());
    };
}
