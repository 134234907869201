import get from 'lodash/get';

export function parse(data) {
    const sortingRules = get(data, 'air:search_sorting_customization.sorting.sort_rules', []);

    return {
        sortingRules: sortingRules.map((rule, idx) => ({...rule, id: idx + 1}))
    };
}


export function serialize(data) {
    const {sortingRules} = data;

    return {
        'air:search_sorting_customization': {
            sorting : {
                'sort_rules': sortingRules.map(({field, order}) => ({field, order}))
            }
        }
    };
}
