import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {paginateStore} from 'midoffice/helpers/paginate';

import PageTitle from 'midoffice/components/PageTitle';
import {CommonTable, Row} from 'midoffice/components/CommonTable';

import {fetchFlags} from 'midoffice/data/actions/flags';


const TABLE_HEADS = [
    {label: 'Name', field: 'name'},
    {label: 'Everyone', w: 60, field: 'everyone'},
    {label: 'Superusers', w: 60, field: 'superusers'},
    {label: 'Assigned Users (Top 10)', field: 'users'},
    {label: 'Assigned Groups', field: 'groups'},
    {label: 'Overall user percentage', w: 120, field: 'user_percentage'},
    {label: 'API Keys', field: 'keys'},
    {label: 'API Consumers', field: 'consumers'},
];


const mapStateProps = createSelector(
    ({flags})=> flags,
    function (flags) {
        const {paged} = paginateStore(flags);
        return {
            flags: paged,
            loading: flags.loading,
        };
    }
);


@connect(mapStateProps, {
    fetch: fetchFlags,
})
export default class FlagsView extends React.Component {
    static propTypes = {
        flags: PropTypes.arrayOf(PropTypes.any),
        fetch: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    componentDidMount() {
        this.props.fetch();
    }

    renderFlagEntry = (entry) => {
        return (<Row key={entry.name} selectable={false}>
            <td>{entry.name}</td>
            <td>{entry.everyone}</td>
            <td>{entry.superusers}</td>
            <td><pre>{entry.users.join('\n')}</pre></td>
            <td><pre>{entry.groups.join('\n')}</pre></td>
            <td><b>{entry.user_percentage}</b></td>
            <td><pre>{entry.keys.join('\n')}</pre></td>
            <td><pre>{entry.consumers.join('\n')}</pre></td>
        </Row>);
    }

    render () {
        const {flags, loading} = this.props;
        return (<div className="container-full container-full--top-fix">
            <div className="content-header">
                <div className="pull-left">
                    <h3 className="content-header__title">Feature Flags Status</h3>
                </div>
            </div>

            <div className="row-wrapper">
                <CommonTable
                    heads={TABLE_HEADS}
                    emptyLabel="No flags here yet."
                    loadingLabel="Flag records are loading…"
                    errorLabel="Failed to load flags."
                    bsStyle="common"
                    loading={loading} >
                    <tbody>
                        { flags.map(this.renderFlagEntry) }
                    </tbody>
                </CommonTable>
            </div>
            <PageTitle>Feature Flags Status</PageTitle>
        </div>);

    }
}
