function trim(value) {
    return value.toString().replace(/^([\s]*)|([\s]*)$/g, '');
}


/** Get cookie value
 * @param {string} name - cookie name
 * @returns {string} cookie value */
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

/** Check if url has same origin as document
 * @param {string} url
 * @returns {boolean} */
export function sameOrigin(url) {
    // url could be relative or scheme relative or absolute
    const host = document.location.host; // host + port
    const protocol = document.location.protocol;
    const srOrigin = '//' + host;
    const origin = protocol + srOrigin;
    // Allow absolute or scheme relative URLs to same origin
    return (url === origin || url.slice(0, origin.length + 1) === origin + '/') ||
        (url === srOrigin || url.slice(0, srOrigin.length + 1) === srOrigin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

/** Check if method is unsafe and needs a CSRF token
 * @param {string} method
 * @returns {boolean} */
export function unsafeMethod(method) {
    return !(/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

export const CSRF_TOKEN_HEADER = 'X-CSRFToken';
export const CSRF_TOKEN_COOKIE = 'csrftoken';
