/* global define, console, _, $ */
/*
 (c) 2013 Instant Communication Ltd.
 under terms of ISC License
 */

(function (define) {
    return define(function () {
        var pluralRe = /^\s*nplurals\s*=\s*(\d+);\s*plural\s*=\s*([^a-zA-Z0-9\$]*([a-zA-Z0-9\$]+).+)$/m;

        function format(s, ctx) {
            return s.replace(/(^|.)\{([^\}]+)\}/g, function (match, prev, k) {
                if (prev === '\\') {
                    return '{' + k + '}';
                }
                return prev + ctx[k.split('#')[0].trim()];
            });
        }

        function parsePlural(header) {
            /*
             * GG-18446
             * This removes Plural-Forms header parser in gettext files.
             * There is no reason to eval it.
             * We don't have languages with plural forms other than n !== 1
             * and don't have that much languages to not hardcode this anyway.
             * */
            return {
                pluralNum: 2,
                isPlural: function (n) {
                    return n !== 1;
                }
            };
        }

        function gettrans(messages, isPlural, msg1, msg2, num) {
            if (!messages || !messages[msg1] || !messages[msg1][0]) {
                return num !== undefined && isPlural(num) ? msg2 : msg1;
            }

            var trans = messages[msg1];

            if (msg2 === undefined && num === undefined) {
                return typeof trans === 'string' ? trans : trans[0];
            }

            if (num !== undefined && typeof trans === 'string') {
                try {
                    throw format('Plural number ({num}) provided for "{msg1}", ' +
                        'but only singular translation exists: {trans}',
                        {num: num, msg1: msg1, trans: trans});
                } catch (e) {
                    console && console.error && console.error(e);
                }

                return trans;
            }

            return trans[+isPlural(num)];
        }

        return function (messages) {
            function puttext(msg1, msg2, num, ctx) {
                if (msg1 === null || msg1 === undefined) {
                    return msg1;
                }
                // in case of `puttext(msg, ctx);`
                if (typeof msg2 === 'object' && num === undefined &&
                    ctx === undefined) {
                    ctx = msg2;
                    msg2 = undefined;
                }

                var text = gettrans(puttext.messages, puttext.plural,
                    msg1, msg2, num);
                if (!isNaN(num) || num === undefined) {
                    if (!ctx) {
                        ctx = {
                            num: num
                        };
                    }
                    return format(text, ctx);
                }
                return text;
            }

            puttext.format = format;
            puttext.setMessages = function (messages) {
                puttext.messages = messages;
                var parsed = parsePlural(messages && messages[""]);
                puttext.pluralNum = parsed.pluralNum;
                puttext.plural = parsed.isPlural;
            };
            puttext.setMessages(messages);

            return puttext;
        };
    });
})(typeof define !== 'undefined' ? define : function (factory) {
        if (typeof module !== 'undefined' && typeof exports !== 'undefined') {
            return module.exports = factory();
        } else {
            return window.puttext = factory();
        }
    });
