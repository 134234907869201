import {
    MODAL_SHOW, MODAL_UPDATE, MODAL_HIDE,
    ALERT_SHOW, ALERT_HIDE,
    LOADER_SHOW, LOADER_HIDE,
} from './types';


export function showAlert({messages, closeTimeout}) {
    return {type: ALERT_SHOW, messages, closeTimeout};
}

export function hideAlert() {
    return {type: ALERT_HIDE};
}

export function showInfo(message, type) {
    const messages = [{
        type,
        html: message || null,
    }];
    return showAlert({messages});
}

export function showError(message) {
    return showInfo(message, 'danger');
}

export function showModal(kind, data) {
    return {type: MODAL_SHOW, kind, data};
}

export function updateModal(data) {
    return {type: MODAL_UPDATE, data};
}

export function hideModal() {
    return {type: MODAL_HIDE};
}

export function showLoader(message) {
    return {type: LOADER_SHOW, message};
}

export function hideLoader() {
    return {type: LOADER_HIDE};
}
