import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const DescriptionTooltip = ({
    text,
}) => {
    return (
        <OverlayTrigger
            overlay={
                <Tooltip
                    id="fare-family-price-class-description"
                >
                    {text}
                </Tooltip>}
        >
            <span className="glyphicons glyphicons-circle-info" />
        </OverlayTrigger>
    );
};

DescriptionTooltip.propTypes = {
    text: PropTypes.string,
};

export default DescriptionTooltip;
