'process i18n';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import settings from 'airborne/settings';
import moment from 'moment';
import gettext from 'airborne/gettext';
import * as agencyCountry from './agencyCountry';

const CURRENCY_LABELS = {
    org: '€£$',
    usd: '$',
    gbp: '£',
    eur: '€'
};

const CURRENCY_FORMATTERS = {
    usd: function (price) { return '$' + price; },
    eur: function (price) { return '€' + price; },
    gbp: function (price) { return '£' + price; },
    rub: function (price) { return price + ' ' + gettext('RUB'); }
};

/**
 * Split camelCase string, using ' ' as delimiter
 * @param {string} camelCaseString
 * @returns {string}
 */
export function splitCamelCase(camelCaseString) {
    return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
}

/**
 * Truncate string
 * @param {string} line
 * @param {number} maxLength
 * @returns {string}
 */
export function truncateLine(line, maxLength) {
    if (line.length >= maxLength) {
        line = line.substring(0, maxLength);
        line = line.substring(0, line.lastIndexOf(' ')) + ' ...';
    }
    return line;
}

/**
 * Display a currency symbol
 * @param {string} currencyCode
 * @returns {string}
 */
export function currency(currencyCode) {
    let code = currencyCode.toLowerCase();
    if (code in CURRENCY_LABELS) {
        return CURRENCY_LABELS[code];
    }
    return currencyCode.toUpperCase();
}

/** Display price with a currency sign
 * @param {number} price
 * @param {string} currency - 3 letter currency code
 * @returns {string} price with currency */
export function price(price, currency) {
    let formatter = CURRENCY_FORMATTERS[currency.toLowerCase()];

    price = parseFloat(price).toFixed(2);
    return formatter ?
        formatter(price) : currency.toUpperCase() + ' ' + price;
}

export function getTimeUntilExpiration(date) {
    const diff = moment.duration(date, 'seconds');
    return diff.humanize();
}

export function createDateFromEntities(year, month, day = 1) {
    const date = moment();
    date.set('year', year);
    date.set('month', month - 1);
    date.set('date', day);
    return date;
}

/** Format a date with given format string
 * @param {string|Date|moment} date
 * @param {?string} format - if omitted settings.USER.date_format_str is used
 * @returns {string} formatted date
 */
export function date(date, format) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = moment(date, moment.ISO_8601);
    }
    if (typeof date === 'number') {
        date = moment.unix(date);
    }
    else if (date instanceof Date) {
        date = moment(date);
    }

    if (format === 'long') {
        format = settings.USER.date_format_str + ' — HH:mm';
    }
    if (format === 'longtz') {
        format = settings.USER.date_format_str + ' — HH:mm [UTC]Z';
    }
    if (format === 'iso') {
        // TODO: GG-19270 should be moment.ISO_8601 since moment.v3
        // according to https://github.com/moment/moment/issues/3019
        format = 'YYYY-MM-DDTHH:mm:ssZ';
    }
    if (format === 'time') {
        format = 'HH:mm';
    }
    if (format === 'localeTime') {
        format = settings.TIME_FORMAT;
    }
    if (format === 'locale') {
        format = settings.DATE_FORMAT;
    }
    if (format === 'localeLong') {
        format = settings.DATE_FORMAT + ' — ' + settings.TIME_FORMAT;
    }

    if (typeof format === 'undefined') {
        format = settings.USER.date_format_str;
    }
    return date.format(format);
}


export function providerCode(provider) {
    return settings.PROVIDER_CODES[provider] || provider.toUpperCase();
}

export const airProviderCode = provider =>
    settings.AIR_PROVIDER_CODES[provider] || provider.substring(0,3).toUpperCase();


// /** Add a creation notice to PNR for visual representation
//  * @param {string} pnr
//  * @param {?boolean} asbCreated
//  * @returns {string} formatted pnr
//  */
export function byAsb(pnr, asbCreated) {
    return asbCreated
        ? `${pnr} (${gettext('Created by ASB')})`
        : pnr;
}

// /** Format a PNR with GDS for visual representation
//  * @param {string} pnr
//  * @param {?string} supplier name
//  * @returns {string} formatted pnr
//  */
export function pnrFormat(pnr, supplier='') {
    return supplier
        ? `[${providerCode(supplier)}] ${pnr}`
        : pnr;
}


/** Format title for entity in tree select
 * @param {string} entity id
 * @param {Object[]} entities list
 * @returns {string} formatted title
 */
export function titleForTreeSelect(id, list) {
    const node = list[id];
    if (!node) {
        return '...';
    }
    const {
        'tspm_entity_id': entityId,
        'is_agency': isAgency,
        title,
    } = node;
    if (isAgency) {
        const parentNode = list[node.parent];
        return `${title}, ${parentNode.title}`;
    }
    if (entityId) {
        return `${title} (${entityId})`;
    }

    return title;
}

export function countryName(id, list) {
    return agencyCountry.countryName(id, list);
}

export function normalizeEmail(email) {
    return email.replace(/_deleted_.*/, '');
}

const AGENCY_SOURCES = {
    agencies: 'Agency Name',
    amadeus: 'Amadeus OID',
    sabre: 'Sabre PCC',
    apollo: 'Apollo OID',
    galileo: 'Galileo OID',
};
export function agencySource(source) {
    return AGENCY_SOURCES[source];
}


const PREDICATES = {
    creditCard: /\d{12,19}/,
};
function processErrorMessage(message) {
    return Object.values(PREDICATES).reduce((message, regex)=> {
        return message && message.replace(regex, '***');
    }, message);
}

/* eslint-disable immutable/no-mutation */
function processException(report) {
    if (!report.exception) {
        return;
    }

    report.exception.values = (report.exception.values || []).map((row)=> {
        if (!row.value) { return row; }
        row.value = processErrorMessage(row.value);
        return row;
    });
}
/* eslint-enable immutable/no-mutation */

/* eslint-disable immutable/no-mutation */
export function sentryReport(report) {
    report.message = processErrorMessage(report.message);
    processException(report);
    report.breadcrumbs.values = (report.breadcrumbs.values || []).map((row)=> {
        if (!row || !row.message) {
            return row;
        }
        row.message = processErrorMessage(row.message);
        return row;
    });
    return report;
}
/* eslint-enable immutable/no-mutation */

const STATE_BLACKLIST = [
    'booking.value',
    'hotels.avail.data',
    'hotels.hotels.data',
    'hotels.hotelDetails.data',
    'hotels.rates.data',
    'hotels.locations.data',
    'air.fareGroups.data',
    'airAvailability.flightOptions.data'
];

export function sentryState(state) {
    const ret = cloneDeep(state);
    STATE_BLACKLIST.forEach((path)=> set(ret, path, null));
    return ret;
}

export function mergeNames(user) {
    const names = [user.first_name, user.middle_name, user.last_name];

    return names.filter((el)=> Boolean(el)).join(' ');
}

export default {
    currency, price, date, getTimeUntilExpiration, pnrFormat, byAsb, titleForTreeSelect,
    countryName, airProviderCode, providerCode, agencySource, normalizeEmail,
    sentryReport, mergeNames
};
