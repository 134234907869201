/**
 * Asynchronously waiting for some seconds before next operation
 *
 * @param {Number} seconds Time to sleep in seconds
 * @return {Promise}
 */
const sleep = seconds =>
    new Promise(resolve => {
        setTimeout(() => resolve(), seconds * 1000);
    });


/**
 * Function which retries to perform your operation some number of times, with delay
 *
 * @param {CallableFunction} fn Asynchronous Function to perform
 * @param {CallableFunction} condition Synchronous Callback that checks our condition and if condition is true it will return result and if false it will retry
 * @param {Number} sleepDuration Time to wait before retry in SECONDS
 * @param {Number} retryCount How much times function will retry to perform your operation
 * @param {Boolean} ignoreErrors If true it is ignoring errors threw by your func and continue retrying, if False it throws your function's error
 * @param {String} timeoutErrorText Replaces default error text to yours
 * @returns {CallableFunction} wrapper Function which will retry your async function until your condition will satisfied
 */
const retry = (
    fn = async arg => arg,
    condition = async () => true,
    sleepDuration = 0,
    retryCount = 1,
    ignoreErrors = false,
    timeoutErrorText = null,
) => {
    async function wrapped(retriesLeft, args) {
        try {
            const result = await fn(...args);
            const asyncResult = await condition(result);

            if (asyncResult) {
                return result;
            }
        }
        catch (e) {
            if (!ignoreErrors) {
                throw e;
            }
        }

        if (retriesLeft > 1) {
            await sleep(sleepDuration);
            return await wrapped(retriesLeft - 1, args);
        }

        throw new Error(
            timeoutErrorText ||
                'The maximum number of attempts has been exhausted'
        );
    }

    return async function wrapper(...args) {
        return await wrapped(retryCount, args);
    };
};

const aio = {sleep, retry};

export default aio;
