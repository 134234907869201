import clone from 'lodash/clone';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import {Events} from 'backbone';
import {ValidationError} from './exceptions';
import {Schema} from './schema';


/**
 * A container form for `Schema`'s data.
 * Creates a Form instance and binds schema fields to form's data.
 *
 * @class {Form}
 */
export class Form {

    /**
     * One of either `schema` or `fields` option is required.
     * @param {object} options
     * @param {Schema} [options.schema] - schema instance for the form
     * @param {Field[]} [options.fields] - array of fields for schema
     * @param {object} [options.data] - initial data for the form
     * @param {continious|blured} [options.validationPolicy] - way to call validate event on control,
              default is blured.
     */
    constructor(options={}) {
        Object.assign(this, Events);

        let {fields, id, schema, validationPolicy} = options;
        this.id = id;
        if (schema) { this.schema = schema; }
        else if (fields) { this.schema = new Schema({fields}); }
        else { throw new Error('A `schema` or `fields` option is required'); }

        this.validationPolicy = validationPolicy || 'blured';
        this._boundFields = this.schema.getFields()
            .map(([name, field])=> field.getBoundField(name, this));

        this.set(this._initData(options.data));
    }

    /**
     * Parse and set incoming initial data for form or get initial
     * values if no data provided.
     * @param {object} data
     * @returns {object}
     * @private
     */
    _initData(data={}) {
        this.initial = Object.assign(
            {},
            this.schema.getInitial(),
            this.schema.getIncomingValue(data)
        );

        return clone(this.initial);
    }

    /**
     * A map of fields bound to the form
     * @returns {object.<string, BoundField>}
     */
    get fields() {
        if (!this._fields) {
            this._fields = keyBy(this._boundFields, 'name');
        }
        return this._fields;
    }

    get orderedFields() {
        return sortBy(this._boundFields, ({field})=> field.creationCounter);
    }

    /**
     * Return current form data
     * @returns {object}
     */
    get data() { return this._data; }

    /**
     * Get field's value by name
     * @param {string} field
     * @returns {*}
     */
    get(field) {
        return this._data[field];
    }

    /**
     * Set form's data and trigger change event
     * @param {(object|string)} dataOrField
     * @param {(object|*)} [options]
     * @param {boolean} [options.merge=false]
     * @param {boolean} [options.validate=false]
     */
    set(dataOrField, options={}) {
        // If first param is a string, assume it is a
        // field name and second param is value
        if (typeof dataOrField === 'string') {
            return this.set({[dataOrField]: options}, {
                merge: true, validate: this.validationPolicy === 'continious'
            });
        }

        if (options.merge) { this._data = Object.assign({}, this._data, dataOrField); }
        else { this._data = dataOrField; }

        if (options.validate) { this.validate(); }
        if (!options.silent) { this.trigger('change'); }
    }

    unset(field, options) {
        this._data = clone(this._data);
        delete this._data[field];

        if (!options) {
            options = {
                validate: this.validationPolicy === 'continious'
            };
        }

        if (options.validate) { this.validate(); }
        if (!options.silent) { this.trigger('change'); }
    }

    /**
     * Reset form data and errors to initial state or new state
     * @param {object} [newInitial] - optional new data
     */
    reset(newInitial, {resetErrors=true}={}) {
        if (newInitial) { this.set(this._initData(newInitial)); }
        else if (this.initial) { this.set(this.initial); }
        else { this.set(this.schema.getInitial()); }
        if (resetErrors) this._errors = {};
        this.trigger('reset');
    }

    /** Clear form data replacing it with default values */
    clear() {
        this.set(this.schema.getInitial());
        this._errors = {};
        this.trigger('change');
    }

    /**
     * A mapping with form's errors or null if no errors
     * @returns {(object|null)}
     */
    get errors() {
        return isEmpty(this._errors) ? null : this._errors;
    }

    /**
     * Set form's errors and trigger change event
     * @param {object} errors - incoming errors object
     * @param {object} [options]
     * @param {boolean} [options.merge=false] - update instead of replacing
     */
    setErrors(errors, options={}) {
        if (options.merge) { Object.assign(this._errors, errors); }
        else { this._errors = errors; }
        this.trigger('change');
    }

    /**
     * Clear error messages for fields or all fields if parameter omitted
     * @param {(string|string[])} [fields]
     */
    clearErrors(fields) {
        this.setErrors(fields ? omit(this._errors, fields) : {});
    }

    /**
     * Execute validation and store the results and return true if no errors.
     * @param {(string|string[])} [fieldNames] - validate these fields only
     */
    validate(fieldNames) {
        if (fieldNames && !Array.isArray(fieldNames)) {
            fieldNames = [fieldNames];
        }

        this.clearErrors(fieldNames);
        try {
            let validData = this.schema.runValidation(this.data, fieldNames);
            this.set(validData, {merge: Boolean(fieldNames)});
        }
        catch (e) {
            if (e instanceof ValidationError) {
                this.setErrors(e.detail, {merge: true});
                return false;
            }
            throw e;
        }
        return true;
    }

    /**
     * Check if form's data has been changed since initialization
     * @returns {boolean}
     */
    get changed() {
        return !this.initial || !isEqual(this.initial, this._data);
    }

    /**
     * Check if form's data is valid without changing error data
     * @returns {boolean}
     */
    get valid() {
        try {
            this.schema.runValidation(this.data);
        }
        catch (e) {
            if (e instanceof ValidationError) {
                return false;
            }
            throw e;
        }
        return true;
    }

    toRepresentation() {
        return this.schema.toRepresentation(this.data);
    }

    toInternalValue(data) {
        return this.schema.toInternalValue(data);
    }

    /**
     * If form is valid set initial to be current data.
     * @returns {Promise}
     */
    save() {
        if (this.validate()) {
            let representation = this.toRepresentation();
            try {
                this.reset(this.toInternalValue(representation));
            }
            catch (e) {
                if (!e instanceof ValidationError || this.validationEnabled) {
                    throw e;
                }
            }
            this.trigger('save', representation);
            return Promise.resolve(representation);
        }

        return Promise.reject(this.errors);
    }
}

/**
 * @class {ModelForm}
 * @extends {Form}
 */
export class ModelForm extends Form {

    /**
     *
     * @param {object} options
     * @param {Backbone.Model} options.model
     */
    constructor({model, ...options}) {
        super({data: model.toJSON(), ...options});
        this.model = model;
    }

    /**
     *
     * @param {Backbone.Model} options.model
     */
    resetModel(model) {
        this.model = model;
        this.reset(model.toJSON());
    }

    /**
     * Save the data to the model
     * @returns {Promise}
     */
    save() {
        if (!this.validate()) {
            return Promise.reject('invalid');
        }

        return new Promise((resolve, reject)=> {
            let representation = this.schema.toRepresentation(this.data);

            this.model.save(representation, {defaultError: false})
                .then(()=> {
                    this.reset(representation);
                    resolve();
                })
                .catch((errors)=> {
                    this.setErrors(errors);
                    reject(errors);
                });
        });
    }

}


export class NestedForm extends Form {

    constructor(options={}) {
        let {owner, ...rest} = options;
        super({data: owner.value, ...rest});
        this.owner = owner;
    }

    save() {
        return super.save().then((data)=> {
            this.owner.value = this.data;
            return data;
        });
    }
}
