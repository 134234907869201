import gettext from 'airborne/gettext';
import moment from 'moment';
import range from 'lodash/range';
import compact from 'lodash/compact';
import memoize from 'lodash/memoize';


const PREFERENCES = [
    'NO_SMOKING_ROOM',
    'SMOKING_ROOM',
    'HIGH_FLOOR_ROOM',
    'LOW_FLOOR_ROOM',
    'BATH_ROOM',
];

function isPreference(value) {
    return PREFERENCES.includes(value);
}

function pad(num) {
    return num < 10 ? `0${num}` : `${num}`;
}

const formatHour = memoize(function formatHour(hour) {
    return moment([2000, 1, 1, hour, 0, 0, 0]).format('hh:mm A');
});

function makeHour(hour, prefix, group) {
    return [
        `${prefix}=${pad(hour)}:00`,
        formatHour(hour),
        group
    ];
}

export function earlyHours() {
    return range(0, 16).map((hour)=> makeHour(hour, 'EARLY_CHECKIN', gettext('Early check-in')));
}


export function lateHours() {
    return range(16, 24).map((hour)=> makeHour(hour, 'LATE_CHECKIN', gettext('Late check-in')));
}

const TIME_RE = /^(EARLY_CHECKIN=(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15):00)|(LATE_CHECKIN=(16|17|18|19|20|21|22|23):00)$/;

function isCheckin(value) {
    return TIME_RE.test(value);
}

export function transformIn(value) {
    const values = (value || '').split(',');
    const [time] = values.filter(isCheckin);
    const ret = {
        'preference': compact(values.filter(isPreference)),
        'checkin': time || null,
    };
    return ret;
}

export function transformOut({checkin, preference}) {
    return compact([checkin, ...preference]).join(',');
}

export function sanitizeSpecialRequest(value, provider) {
    return provider === 'hcorpo' ? transformOut(transformIn(value)) : value;
}
