'process i18n';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import {shallowAssign} from 'midoffice/helpers/types';
import {Schema} from 'midoffice/newforms/schema-stateless';
import {getCardType, luhn10} from 'airborne/checkout2/helpers/cardValidators';
import gettext from 'airborne/gettext';

export function noErrors(errors) {
    if (!isObject(errors)) { return !errors; }
    return every(values(errors), noErrors);
}

export function compactErrors(errors) {
    return isObject(errors)
        ? Object.entries(errors)
            .reduce((acc, [key, value])=> (
                compactErrors(value)
                    ? {...(acc || {}), [key]: value}
                    : acc
            ),
            null
            )
        : errors || null;
}

export function selectError(errors) {
    if (isObject(errors)) {
        errors = Object.values(errors);
    }

    if (!isArray(errors)) {
        return errors;
    }
    const valueableErrors = uniq(filter(flatten(errors)));
    return selectError(valueableErrors[0]);
}

export function createSchema(definition) {
    const fields = mapValues(definition.fields, (field, name)=> ({
        name, ...field,
    }));
    return {
        ...Schema,
        ...definition,
        fields,
    };
}

// We need shallow assign schema helper for the same purpose that we have shallowAssign helper itself.
// After we've stopped page reloading with each user login (accordingly, user settings update) -
// we have to find a way to DYNAMICALLY get Field properties for schema.
// Good example is DateField that have inputFormat property defined with setters/getters,
// in this way even if user settings updates on the fly - all parts of the application
// are referenced on the fresh/actual values.
// shallowAssign helpers allow us to copy getters/setters and preserve their original behavior.
//
// We need to use this one instead of the default one - only if the part of the application loads before user login
export function createShallowSchema(definition) {
    const fields = mapValues(definition.fields, (field, name) => {
        return shallowAssign({}, {name}, field);
    });

    return shallowAssign(
        {},
        Schema,
        definition, {fields}
    );
}

export function getFieldProps(field) {
    return reduce(field, (props, current, key)=> (
        isFunction(current) ? props : {
            ...props,
            [key]: current,
        }
    ), {});
}

export function required(field, emptyMessage=null) {
    return {
        ...field,
        emptyMessage: emptyMessage || field.emptyMessage,
        isRequired: true,
    };
}

/**
 *
 * @param {object}         field
 * @param {boolean|string} requiredParam - if it's boolean and equals true then validation will be performed.
 *
 *         If it's a string it will be used as a key to check in schemaParams, so if the key exists in
 *         schemaParams then validation will be performed.
 *
 *         Otherwise, validation will be ignored.
 *
 *
 * @return {{validate: (function(*=, *=): object|null)}}
 */
export function validateIf(field, requiredParam) {
    return {
        ...field,
        validate: function requireIf(value, schemaParams={}) {
            return (typeof requiredParam === 'boolean' && requiredParam) || schemaParams[requiredParam]
                ? field.validate.call(this, value, schemaParams)
                : null;
        },
    };
}

export function strictOnly(field) {
    return {
        ...field,
        validate: function strictOnlyValidate(value, schemaParams={}) {
            return schemaParams.strict
                ? field.validate.call(this, value, schemaParams)
                : null;
        },
    };
}

export function findWithResult(collection, predicate, context) {
    let result = null;

    function memoizedPredicate(...args) {
        result = predicate.call(this, ...args);
        return result;
    }
    const found = find(collection, memoizedPredicate, context);

    return found && result;
}

export function combineValidators(...validators) {
    return function validate(value, schemaParams) {
        const error = findWithResult(
            validators,
            (validate)=> validate.call(this, value, schemaParams)
        );

        return error || null;
    };
}

export function errorMessage(message, options) {
    if (isFunction(message)) return message(options);
    return message;
}

const isEqual = (value, other)=> value === other;
export function notAllowedValues(values, field, predicate=isEqual) {
    return {
        ...field,
        validate: function notAllowedValuesValidate(value, schemaParams={}) {
            const notAllowedVals = values.filter((val)=> predicate(val, value));
            if (notAllowedVals.length > 0) {
                return errorMessage(field.notAllowedValuesMessage || 'Not allowed value');
            }
            return field.validate.call(this, value, schemaParams);
        }
    };
}

export function combineFormValidators(...validators) {
    return function validate(value, schemaParams) {
        return validators.reduce(function validateOne(errors, validateFn) {
            const moreErrors = validateFn.call(this, value, schemaParams);
            return (errors && moreErrors)
                ? {...errors, ...moreErrors}
                : (errors || moreErrors);
        }, null);
    };
}

function extractValueFromObject(param) {
    return param instanceof Object && 'value' in param && 'errors' in param
        ? param.value
        : param;
}

function extractNameAndValue(param) {
    const [name, value] = param;

    if (Array.isArray(value)) {
        return [name, value.map(extractValueFromObject)];
    }

    return [name, extractValueFromObject(value)];
}

export function patchForm({schema, schemaParams, toChange}) {
    const changedFields = Object.entries(toChange)
        .reduce((acc, current)=>{
            const [fieldName, fieldValue] = extractNameAndValue(current);
            const cleanValue = schema.fields[fieldName].toRepresentation(fieldValue, schemaParams);
            return {
                value: {
                    ...acc.value,
                    [fieldName]: cleanValue.value,
                },
                errors: {
                    ...acc.errors,
                    [fieldName]: cleanValue.errors,
                },
            };

        }, {value: {}, errors: {}});

    return changedFields;
}

export function applyPatch({value, errors, toChange = {}, toRemove}) {
    return {
        value: {
            ...omit(value, toRemove),
            ...toChange.value,
        },
        errors: {
            ...omit(errors, toRemove),
            ...toChange.errors,
        },
    };
}

const isCreditCard = (value) => {
    const regex = /\d{15,16}/;
    const creditCards = regex.exec(value);
    return creditCards && getCardType(creditCards[0]) && Boolean(luhn10(creditCards[0]));
};

export function checkIsThereCreditCard(fields) {
    const errors = Object.keys(fields).reduce((acc, key) => {
        if (key !== 'credit_card_number' && fields[key] && isCreditCard(fields[key])) {
            return {...acc, [key]: gettext('Credit card number is not allowed here')};
        }
        return acc;
    }, {});
    return isEmpty(errors) ? null : errors;
}
