import wrap from 'midoffice/components/LazyComponent';

function load3() {
    document.body.classList.add('layout-rev');
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./records/BookingRecordsPage');
            resolve(loaded);
        }, 'bookings3');
    });
}
export const BookingsHandler = wrap(load3);
