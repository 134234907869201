import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import RowWrapper from 'midoffice/components/RowWrapper';
import {CommonTable, Row} from 'midoffice/components/CommonTable';
import Pager from 'midoffice/components/Pager';
import modals from 'midoffice/modals';

import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import {SYSTEM_LABELS} from 'midoffice/data/schemas/MessageEditSchema';
import {isEveryObjChecked, isAnyObjChecked} from 'midoffice/helpers/checked';
import {LANGUAGES} from 'midoffice/helpers/languages';
import {paginateStore} from 'midoffice/helpers/paginate';
import {
    fetchMessages, paginateMessages,
    sortMessages, checkMessage, removeMessages,
    showEditModal,
} from 'midoffice/data/actions/aftMessaging';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';


const TABLE_HEADS = [
    {label: 'Status', field: 'preference', w: 120},
    {label: 'Date', field: 'from', w: 220},
    {label: 'Message', field: 'text-en'},
    {label: 'Channel', w: 185},
    {label: 'Languages', w: 200},
    {label: '', w: 110},
];


class Actions extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    render() {
        const {disabled, onRemove} = this.props;
        return (
            <DropdownButton title="Edit" disabled={disabled} variant="light">
                <Dropdown.Item eventKey="delete" onClick={onRemove}>Delete</Dropdown.Item>
            </DropdownButton>
        );
    }
}


class Message extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        status: PropTypes.number.isRequired,
        systems: PropTypes.object.isRequired,
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        'text-en': PropTypes.string.isRequired,

        onCheck: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    handleCheck = (event)=> {
        const {id, onCheck} = this.props;
        onCheck(id, event.target.checked);
    };

    handleEdit = ()=> {
        const {id, onEdit} = this.props;
        onEdit(id);
    };

    renderStatus(status) {
        const STATUSES = [
            ['muted', 'Past'],
            ['highlight-green', 'Active'],
            ['highlight-blue', 'Future'],
        ];
        const [className, label] = STATUSES[status];

        return (<span className={className}>{label}</span>);
    }

    renderDates(from, to) {
        const dateFormat = `${settings.USER.date_format_str}, HH:mm [UTC]`;
        return [
            <div className="small">
                <strong>Start Date:</strong>
                &nbsp;
                {moment(from).format(dateFormat)}
            </div>,
            <div className="small">
                <strong>End Date:</strong>
                &nbsp;
                {moment(to).format(dateFormat)}
            </div>,
        ];
    }

    renderSystems(systems) {
        return systems.filter((key)=> SYSTEM_LABELS[key])
            .map((key)=> SYSTEM_LABELS[key]).join(', ');
    }

    renderLanguages(message) {
        return LANGUAGES.map(([code, label])=> (
            message[`text-${code}`] ? (
                <div key={code}>{label}</div>
            ) : null
        ));
    }

    render() {
        const {checked, status, from, to, 'text-en': textEn, systems} = this.props;

        return (
            <Row checked={checked} onCheck={this.handleCheck}>
                <td>{this.renderStatus(status)}</td>
                <td>{this.renderDates(from, to)}</td>
                <td>{textEn}</td>
                <td>{this.renderSystems(systems)}</td>
                <td>{this.renderLanguages(this.props)}</td>
                <td className="text-center">
                    <Button variant="light" size="sm" className="table-common__toggle" onClick={this.handleEdit}>
                        <Glyphicons bsClass="glyphicon" glyph="pencil"/> Edit
                    </Button>
                </td>
            </Row>
        );
    }
}


function deleteConfirmation(number) {
    return modals.confirm({
        title: gettext('Delete Message', 'Delete Messages', number),
        content: gettext(
            'Are you sure you want to delete {num} message?',
            'Are you sure you want to delete {num} messages?',
            number
        ),
        buttonOk: 'Delete',
        buttonOkClass: 'danger',
    });
}


const mapStateToProps = createSelector(
    ({aftMessaging})=> aftMessaging,
    function(messages) {
        const {checked, loading, sortBy} = messages;
        const {paged, pageBy} = paginateStore(messages);

        return {
            allChecked: isEveryObjChecked(checked, paged),
            anyChecked: isAnyObjChecked(checked, paged),
            messages: paged,
            checked, loading, sortBy, pageBy,
        };
    }
);


@connect(mapStateToProps, {checkMessage, removeMessages, fetchMessages, sortMessages, paginateMessages, showEditModal})
export default class Messages extends React.Component {
    static propTypes = {
        allChecked: PropTypes.bool.isRequired,
        anyChecked: PropTypes.bool.isRequired,
        messages: PropTypes.array.isRequired,
        checked: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        sortBy: PropTypes.string.isRequired,
        pageBy: PropTypes.object.isRequired,

        checkMessage: PropTypes.func.isRequired,
        removeMessages: PropTypes.func.isRequired,
        fetchMessages: PropTypes.func.isRequired,
        sortMessages: PropTypes.func.isRequired,
        paginateMessages: PropTypes.func.isRequired,

        showEditModal: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {fetchMessages, sortMessages} = this.props;
        fetchMessages();
        sortMessages('-preference');
    }

    handleCheckAll = (event)=> {
        const {messages, checkMessage} = this.props;
        const ids = messages.map(({id})=> id);
        checkMessage(ids, event.target.checked);
    };

    handleRemove = ()=> {
        const {messages, checked, removeMessages} = this.props;
        const ids = messages.map(({id})=> id).filter((id)=> checked[id]);

        deleteConfirmation(ids.length).then(
            ()=> removeMessages(ids).then(
                ()=> modals.alert('Your changes have been made'),
                modals.oops,
            ),
            ()=> {},
        );
    };

    handleCreate = ()=> {
        this.props.showEditModal('new');
    };

    handleEdit = (id)=> {
        this.props.showEditModal(id);
    };

    renderSpinner() {
        return (
            <div className="overlay-mute">
                <div className="overlay-mute--spinner" />
            </div>
        );
    }

    renderMessage(message) {
        const {checked, checkMessage} = this.props;
        return (
            <Message key={message.id}
                checked={Boolean(checked[message.id])}
                onCheck={checkMessage}
                onEdit={this.handleEdit}
                {...message} />
        );
    }

    render() {
        const {loading, allChecked, anyChecked, sortBy, messages, pageBy, sortMessages, paginateMessages} = this.props;

        return (
            <RowWrapper simple>
                <h5 className="form-secondary-title">Messages List</h5>
                <div className="well-section">
                    <div className="well-section__content">
                        <RowWrapper simple>
                            <div className="col-narrow">
                                <Button variant="success" onClick={this.handleCreate}>
                                    <Glyphicons bsClass="glyphicon" glyph="plus" /> Create New
                                </Button>
                            </div>

                            <div className="col-narrow">
                                <Actions disabled={!anyChecked || loading}
                                    onRemove={this.handleRemove} />
                            </div>
                        </RowWrapper>
                        <RowWrapper simple>
                            {loading && this.renderSpinner()}
                            <CommonTable bsStyle="common"
                                heads={TABLE_HEADS}
                                onSortBy={sortMessages}
                                onCheckAll={this.handleCheckAll}
                                allChecked={allChecked}
                                sortBy={sortBy}
                                emptyLabel="No AFT Messages."
                                selectable>
                                <tbody>
                                    {messages.map((message)=> this.renderMessage(message))}
                                </tbody>
                            </CommonTable>

                            <div className="table-common-footer">
                                <Pager {...pageBy} onChange={paginateMessages} />
                            </div>
                        </RowWrapper>
                    </div>
                </div>
            </RowWrapper>
        );
    }
}
