import {FILECHECK_LOADING, FILECHECK_DONE, FILECHECK_FAILED, FILEINPUT_OPEN, FILEINPUT_CLOSE} from 'midoffice/data/actions/types';

export function fileCheckStart() {
    return {type: FILECHECK_LOADING};
}

export function fileCheckEnd() {
    return {type: FILECHECK_DONE};
}

export function fileCheckFail(error) {
    return function(dispatch) {
        return dispatch({type: FILECHECK_FAILED, data: error});
    };
}

export function fileInputOpen() {
    return {type: FILEINPUT_OPEN};
}

export function fileInputClose() {
    return {type: FILEINPUT_CLOSE};
}
