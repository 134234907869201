import hash from './hash';
import {CHANGED} from 'midoffice/data/actions/types';


function formChanged(state, {value, errors, form}) {
    return {
        ...state,
        [form]: {
            value,
            errors,
        },
    };
}


export default function pnrTool(state, {type, ...action}) {
    if (!state) {
        return {
            ...hash(state, {type, ...action}),
            retrieveForm: {
                value: {},
                errors: {},
            },
            createForm: {
                value: {
                    passengers: [
                        {'first_name': '', 'last_name': ''},
                    ],
                },
                errors: {},
            },
        };
    }

    if (type === CHANGED) {
        return formChanged(state, action);
    }

    return hash(state, {type, ...action});
}
