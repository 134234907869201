import {combineReducers} from 'redux';

import flightOptions from './flightOptions';
import selection from './selection';
import filters from './filters';

const airAvailability = combineReducers({
    flightOptions,
    selection,
    filters,
});

export default airAvailability;
