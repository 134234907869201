import compact from 'lodash/compact';
import isFunction from 'lodash/isFunction';
import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';
import {formatUrl} from 'midoffice/helpers/url';

import {parseDestinations} from 'airborne/homepage2/helpers/autocomplete';
import loadGmap from 'airborne/homepage2/googlemap';
import ignoreDuplicates from 'airborne/search/addressDuplicates';


function parseGeoresult(item) {
    const ll = item.geometry.location;
    if (isFunction(ll.lat) && isFunction(ll.lng)) {
        return {
            label: item['formatted_address'],
            value: `Coordinates:${ll.lat()},${ll.lng()}`,
            lat: ll.lat(),
            lon: ll.lng(),
            icon: 'landmark',
            type: 'l',
        };
    }
}

function geocodeIt(geocoder, query) {
    return new Promise((resolve, reject)=> {
        geocoder.geocode(query, (results)=> {
            if (!results || results?.length === 0) {
                reject();
            }
            else {
                resolve(results);
            }
        });
    });
}

function googleIt(query) {
    return loadGmap().then(({maps})=> {
        return geocodeIt(new maps.Geocoder(), query);
    });
}

function findAddressType(components, type) {
    const [first] = components
        .filter(({types})=> types && types.includes(type));
    return first || null;
}

async function firstCity(results) {
    const locality = findAddressType(results, 'locality') || findAddressType(results, 'political');
    if (!locality) {
        throw new Error('No address found');
    }
    return locality['formatted_address'];
}

export function locationCity(lat, lng) {
    return googleIt({location: {lat, lng}}).then(firstCity);
}

export function googleAddress(address) {
    return googleIt({address})
        .then(results=> {
            const items = compact(
                ignoreDuplicates(results, address)
                    .map(parseGeoresult)
            );
            return ([{category: null, id: 'g', items}]);
        });

}

export default function addressDestination(term, companyId, gds) {
    const {
        USER_LOCALE: locale='en_US',
        SUB_PARTNER: system='aft'
    } = settings;

    const url = formatUrl('/latest/destination_lookup/', {
        locale, system, term,
        'configuration_id': companyId,
    });
    return api('GET', url)
        .then((data)=> {
            const result = parseDestinations.call({gds: gds},null, data);
            if (!result) {
                throw new Error();
            }
            return result;
        })
        .catch(()=> googleAddress(term));
}
