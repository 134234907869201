import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import {connect} from 'react-redux';
import moment from 'moment';

import modals from 'midoffice/modals';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import {hideEditModal, addMessage, changeMessage, removeMessages} from 'midoffice/data/actions/aftMessaging';

import settings from 'airborne/settings';
import Schema, {SYSTEMS, DATE_TIME_FORMAT} from 'midoffice/data/schemas/MessageEditSchema';
import {LANGUAGES} from 'midoffice/helpers/languages';

import Label from 'midoffice/newforms/widgets/Label';
import Textarea from 'midoffice/newforms/widgets/Textarea';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import DateInput from 'midoffice/newforms/widgets/DateInput';
import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';

const {USER} = settings;


function getCurrentUTCTime() {
    return moment.utc().format(DATE_TIME_FORMAT);
}


class CurrentTime extends React.Component {
    componentDidMount() {
        this.interval = setInterval( // eslint-disable-line immutable/no-mutation
            ()=> this.forceUpdate(),
            30 * 1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="well well-sm">
                <Label.Field name="now" label="Current UTC" value={getCurrentUTCTime()} inputCol={8} />
            </div>
        );
    }
}


@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        isNew: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleStartNow = ()=> {
        const {value, errors, onChange} = this.props;
        const now = moment().utc().add(1, 'hour').startOf('hour');

        onChange({value: {...value, from: now}, errors});
    };

    render() {
        const {value: {onlyDefaultLang}, isNew} = this.props;
        const extra = {inputCol: 8};

        return (<form className="form-horizontal">
            <h5 className="modal-body__section-title">
                Schedule
                <aside className="required-note"><span className="highlight-red">*</span>&nbsp;Required</aside>
            </h5>
            <CurrentTime />
            <div className="well well-sm">
                <div className="relative">
                    <DateInput.Field
                        name="from"
                        placeholder={USER.date_format_str}
                        label="Start Date (UTC)"
                        inputSize="small"
                        minDate={isNew && moment().startOf('day')}
                        time
                        {...extra} />
                    <a href="#" className="form-group__date-control-lnk btn btn-default" onClick={this.handleStartNow}>Start Now</a>
                </div>
                <DateInput.Field
                    name="to"
                    placeholder={USER.date_format_str}
                    label="End Date (UTC)"
                    inputSize="small"
                    minDate={isNew && moment().startOf('day')}
                    time
                    {...extra} />

            </div>
            <h5 className="modal-body__section-title">Channel</h5>
            <div className="well well-sm">
                <MultiCheckbox.Field
                    name="systems"
                    label="Channel"
                    choices={SYSTEMS}
                    table={false}
                    {...extra} />
            </div>
            <h5 className="modal-body__section-title">Message</h5>
            <div className="well well-sm">
                <Textarea.Field name="text-en" label="Message (EN)" {...extra} />
                <div className="form-group__label-offset">
                    <Checkbox.Field
                        name="onlyDefaultLang"
                        label="Display translations in default language"
                        {...extra} />
                </div>
            </div>

            {onlyDefaultLang ? null : [
                <h5 className="modal-body__section-title">Translations</h5>,
                <div className="well well-sm">
                    {LANGUAGES.map(([id, label])=> id === 'en' ? null : (
                        <Textarea.Field
                            key={['lang', id]}
                            name={`text-${id}`}
                            label={label}
                            {...extra} />
                    ))}
                </div>
            ]}
        </form>);
    }
}

function modalProps(id, hash, loading) {
    return {id, value: hash[id] || {onlyDefaultLang: true}, loading};
}

@connect(({modal: {kind, data}={}, aftMessaging: {hash, loading}})=> (
    kind === 'messageEdit'
        ? modalProps(data.id, hash, loading)
        : {loading}
), {
    onCancel: hideEditModal,
    onAdd: addMessage,
    onChange: changeMessage,
    onRemove: (id)=> removeMessages([id]),
})
export default class MessageEdit extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        onAdd: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = {value: null, errors: null};

    static getDerivedStateFromProps({id, value}, prevState) {
        if (id && prevState.id !== id && prevState.value !== value) {
            return {value, id, errors: null};
        }

        return null;
    }

    handleSave = ()=> {
        const {onAdd, onChange, onCancel, id} = this.props;
        const {value} = this.state;

        const errors = Schema.validate(this.state.value, id === 'new');
        if (noErrors(errors)) {
            return (
                id === 'new' ? onAdd(value) : onChange(id, value)
            ).then(onCancel, modals.oops);
        }
        else {
            this.setState({value, errors});
        }
    };

    handleChange = ({value, errors})=> {
        this.setState({value, errors});
    };

    handleRemove = ()=> {
        modals.confirm('Are you sure you want to delete this message?').then(()=> {
            const {id, onRemove, onCancel} = this.props;
            onRemove(id).then(onCancel, modals.oops);
        }, noop);
    };

    render() {
        const {id, onCancel, loading} = this.props;
        const {value, errors} = this.state;
        const title = id === 'new' ? 'Add AFT Message' : 'Edit AFT Message';

        if (!id) { return null; }

        return (
            <Modal onHide={loading ? noop : onCancel} show>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Editor
                        schema={Schema}
                        isNew={id === 'new'}
                        value={value}
                        errors={errors}
                        onChange={this.handleChange} />
                </Modal.Body>
                <Modal.Footer>
                    {id !== 'new' && (
                        <Button
                            variant="link"
                            className="btn-link--narrow highlight-red pull-left"
                            onClick={this.handleRemove}
                            disabled={loading}
                        >
                            Delete message
                        </Button>
                    )}
                    <Button variant="light" onClick={onCancel} disabled={loading}>Cancel</Button>
                    <Button variant="success" disabled={loading} onClick={this.handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
