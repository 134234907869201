import {combineReducers} from 'redux';
import reduceReducers from 'airborne/utils/reduceReducers';
import pnr from './pnr';

function growuntil(slots, initItem, state) {
    return slots > state.length
        ? growuntil(slots, initItem, [...state, initItem()])
        : state;
}

const PNR_ACTION_TYPES = [
    'REUSE_SEARCH',
    'LOADING_DESTINATION_OPTIONS',
    'FAIL_DESTINATION_OPTIONS',
    'DESTINATION_OPTIONS',
    'TRAVELER_PROFILE_CHANGED',
    'COMPANY_PROFILE_CHANGED',
    'TRAVELER_PROFILE_IDS_CHANGED',
    'SHOW_PNR',
    'HIDE_PNR',
];

const ACTION_WITH_DEFAULT_LAST_INDEX = [
];

function shouldBeProceed(actionIndex, itemIndex) {
    return actionIndex === itemIndex
        || actionIndex === 'all'
        || Array.isArray(actionIndex) && actionIndex.includes(itemIndex);
}

export function multi(reducer, actionsWithIndex=null) {
    function initItem() {
        return reducer(undefined, {}); //eslint-disable-line no-undefined
    }

    return reduceReducers(
        function(state=[initItem()], {type, slots, index, update}) {
            if (type === 'LOADED_PNR' && index === state.length && !update) {
                return [...state, initItem()];
            }

            if (type === 'DISCARD_PNR') {
                return [initItem()];
            }
            if (type === 'GLOBAL_RESTORE') {
                return growuntil(slots, initItem, state);
            }
            return state;
        },
        function(state, {index, ...action})  {
            if (ACTION_WITH_DEFAULT_LAST_INDEX.includes(action.type) && typeof index === 'undefined') {
                index = state.length - 1;
            }
            if (Array.isArray(actionsWithIndex) && actionsWithIndex.includes(action.type) && typeof index === 'undefined') {
                throw new Error(`${action.type} should have index`);
            }

            return state.map((item, idx)=> shouldBeProceed(index, idx) ? reducer(item, action) : item);
        },
        function (state, {type, index}) {
            if (type === 'DELETE_PNR') {
                if (state.length === 1) {
                    return state;
                }
                return state.filter((item, idx) => idx !== index);
            }
            return state;
        }
    );
};

const formInitial = {
    pnr: null,
};

function form(state=formInitial, {type, value, profile, company}) {
    if (type === 'CHANGE_FORM_PNR') {
        return value;
    }

    if (type === 'REUSE_SEARCH') {
        return profile || formInitial;
    }

    if (type === 'DISCARD_PNR') {
        return formInitial;
    }

    if (type === 'SWITCH_COMPANY') {
        return {...state, company};
    }

    //TODO: GG-20119 Should separate company and pnr
    if (type === 'OPEN_ADD_PNR' || type === 'ADD_PNR_ON_SEARCH' || type === 'LOADED_PNR' || type === 'DISCARD_PNR_FORM') {
        return {...state, pnr: null};
    }

    return state;
}

function currentCompany(state=null, {type, company}) {
    if (type === 'DISCARD_PNR') {
        return null;
    }

    if (type === 'SELECT_COMPANY' || type === 'SWITCH_COMPANY') {
        return company;
    }

    return state;
}

function originalCompany(state=null, {type, original}) {
    if (type === 'DISCARD_PNR') {
        return null;
    }

    if (type === 'SWITCH_COMPANY') {
        return original;
    }

    return state;
}


function errors(state=null, {type, errors=null}) {
    if (type === 'DESTINATION_OPTIONS'
        || type === 'LOADED_PNR'
        || type === 'REUSE_SEARCH'
        || type === 'NO_PNR'
        || type === 'DISCARD_PNR_FORM'
    ) {
        return null;
    }

    if (type === 'CHANGE_FORM_PNR'
        || type === 'FAIL_DESTINATION_OPTIONS'
    ) {
        return errors;
    }

    return state;
}

function retrieveErrors(state=[], {type, errors}) {
    if (type === 'LOADING_PNR'
        || type === 'DISCARD_PNR'
        || type === 'CLOSE_ADD_PNR'
        || type === 'DISCARD_PNR_FORM'
    ) {
        return [];
    }
    if (type === 'FAIL_PNR') {
        return [...errors];
    }
    return state;
}

function loading(state=false, {type}) {
    if (type === 'LOADING_PNR' || type === 'LOADING_DESTINATION_OPTIONS') {
        return true;
    }

    if (type === 'FAIL_DESTINATION_OPTIONS'
        || type === 'FAIL_PNR'
        || type === 'LOADED_PNR'
        || type === 'DESTINATION_OPTIONS'
    ) {
        return false;
    }

    return state;
}

function searchPnrEditor(state=false, {type}) {
    if (type === 'OPEN_ADD_PNR_ON_SEARCH') {
        return true;
    }
    if (type === 'LOADED_PNR' || type === 'CLOSE_ADD_PNR_ON_SEARCH') {
        return false;
    }

    return state;
}

function oid(state=null, {type, value}) {
    if (type === 'DESTINATION_OPTIONS') {
        return value['gds_oid'] || null;
    }
    return state;
}

export default combineReducers({
    editPnr: function(state=null, {type, index}) {
        if (type === 'EDIT_ADD_PNR') {return index;}
        if (type === 'TO_PNR_LIST') {return null;}
        return state;
    },
    items: multi(pnr, PNR_ACTION_TYPES),
    form,
    currentCompany,
    originalCompany,
    oid,
    errors,
    loading,
    retrieveErrors,
    searchPnrEditor,
});
