import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hideMaintenance} from 'airborne/store/modules/maintenance/actions';
import gettext from 'airborne/gettext';
import moment from "moment";
import {
    isMaintenanceAtHiddenList
} from 'airborne/store/modules/maintenance/helpers/helpers';

function mapStateProps(state) {
    const maintenance = state?.maintenances?.currentMaintenance;
    const isAlreadyHidden = isMaintenanceAtHiddenList(maintenance?.id);
    return {
        maintenance,
        isAlreadyHidden,
    };
}

@connect(mapStateProps, {hideMaintenance})
export default class MaintenanceNotification extends React.Component {
    static propTypes =  {
        maintenance: PropTypes.object,
        hideMaintenance: PropTypes.func,
        isAlreadyHidden: PropTypes.bool,
    };

    handleHide = () => {
        const {maintenance, hideMaintenance} = this.props;
        hideMaintenance(maintenance.id);
    }

    maintenanceText() {
        const {maintenance} = this.props;
        const start = moment.unix(maintenance.start).toString();
        const end = moment.unix(maintenance.end).toString();
        const text = maintenance.isActive ? maintenance.active_maintenance_notification : maintenance.pre_maintenance_notification;

        if (!text) {
            return <div>
                <strong>{gettext('Important')}: </strong>
                {!maintenance.showPreNotification && gettext('The AgentSource Booking interface is temporary unavailable due to system maintenance. ')}
                {gettext(`AgentSource Booking is expected to be down from approximately ${start} until ${end}.` +
                    ' Please note that all reservations booked before the system maintenance should not be affected.')}
                </div>


        }
        return text.replace('[[start]]', start).replace('[[end]]', end);
    }

    render() {
        const {maintenance, isAlreadyHidden} = this.props;

        if (!maintenance?.id || isAlreadyHidden) {
            return null;
        }

        return (<div className="header-notification">
                    <div className="container">
                        <span className="notification__content">
                            <span className="notification__text">
                                {this.maintenanceText()}
                            </span>
                            <span className="notification__close" onClick={this.handleHide}>
                                &times;
                            </span>
                        </span>
                    </div>
                </div>
        );
    }

}
