function initial() {
    return {
        value: {},
        errors: null,
    };
}

export default function form(state, {type, value, errors}) {
    if (!state) {
        return initial();
    }

    if (type === 'FORM_CHANGED') {
        return {value, errors};
    }

    return state;
}
