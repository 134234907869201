import air from 'airborne/store/modules/search_hotels/actions/air';
import {IMMEDIATELY_RETRY, retry, RETRY} from 'airborne/checkout2/helpers/retry';
import StreamService, {MESSAGE_TYPES, ERRORS} from 'airborne/services/Stream';

const RETRY_TIMEOUT = 5000;
const FALLBACK_TIMEOUT = 30 * 1000;
const RETRY_TRIES = 10;

async function waitForReservation(id) {

    function action() {
        throw IMMEDIATELY_RETRY;
    }

    function conditionCheck(event) {
        const {type, message} = event;
        const {cancellationStatus, bookingId, product} = message;
        return type === MESSAGE_TYPES.BOOKING_STATUS && id === bookingId && product === 'hotels' && (cancellationStatus && cancellationStatus !== 'in-progress');
    }

    try {
        await StreamService.reactOnEvent(
            action,
            conditionCheck,
            FALLBACK_TIMEOUT,
        );
    }
    catch (error) {
        if (error === ERRORS.PUSH_STREAM_INIT_ERROR) {
            throw RETRY;
        }
        throw error;
    }
}

export const getPrevReservationDetals = retry(function getPrevReservationDetals(index, uid) {
    return async function getPrevReservationDetalsD(dispatch) {
        let response;
        try {
            response = await air('GET', '/hotels/get_reservation_details/', {
                'booking_uid': uid,
            });
            dispatch({type: 'BOOKING_DETAILS_PREV', data: response, index});
        }
        catch (error) {
            dispatch({type: 'BOOKING_DETAILS_PREV_FAIL', index});
            throw RETRY;
        }
        const cancelStatus = response['cancellation_status'];

        if (cancelStatus === 'in-progress' || !cancelStatus) {
            await waitForReservation(uid);
        }
    };
}, RETRY_TIMEOUT, RETRY_TRIES);
